import gql from 'graphql-tag';

import { PlanDetails_Fragment_plan } from 'app/components/organisms/PlanDetails/fragment';

export const ProductsList_Fragment_plan = gql`
  fragment ProductsList_Fragment_plan on PapiPlan {
    id
    billingPeriod
    deprecatedAt
    displayName
    joinCentersAllowed {
      id
      abbreviatedName
      isVirtual
    }
    numberOfBillingCycles
    paymentRateInCents
    ...PlanDetails_Fragment_plan
  }
  ${PlanDetails_Fragment_plan}
`;
// TODO PlanDetails should get plan info in its own query when getPlanByID is available in papi
