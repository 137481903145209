import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import React, { FC, useCallback } from 'react';
import styled from 'styled-components/macro';

import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { APadding } from 'app/components/atoms/APadding/APadding';
import { MEMBER_VISITS_QUERY } from 'app/components/pages/MemberVisitsPage/MemberVisitsPage';
import { theme } from 'app/styles/theme';
import {
  MemberProviders_Query_member_assignedCoach,
  MemberProviders_Query_member_assignedDoctor,
} from 'app/types/generated/MemberProviders_Query';
import {
  ProviderDelete_Mutation,
  ProviderDelete_MutationVariables,
} from 'app/types/generated/ProviderDelete_Mutation';
import { STATUS_MESSAGE } from 'constants/message';
import { PROVIDER_TYPE } from 'constants/provider';

import { PROVIDER_DELETE_MUTATION } from './query';

// Types & constants ////////////////////////////////
interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  personFirstName?: string;
  personID: string;
  provider?:
    | MemberProviders_Query_member_assignedCoach
    | MemberProviders_Query_member_assignedDoctor
    | null;
  providerType?: PROVIDER_TYPE;
}

const ProviderDeleteModal: FC<Props> = ({
  isVisible,
  onClose,
  onSuccess,
  personFirstName,
  personID,
  provider,
  providerType,
}) => {
  const [
    deleteProvider,
    { error: deleteProviderError, loading: mutationLoading },
  ] = useMutation<ProviderDelete_Mutation, ProviderDelete_MutationVariables>(
    PROVIDER_DELETE_MUTATION
  );

  const onDelete = useCallback(async () => {
    if (!provider) {
      return;
    }

    try {
      await deleteProvider({
        refetchQueries: [
          {
            query: MEMBER_VISITS_QUERY,
            variables: { personID },
          },
        ],
        variables: { personID, providerID: provider.id },
      });
      if (deleteProviderError) {
        message.error(STATUS_MESSAGE.providerDelete.error.general);
        return onClose();
      }
      message.success(STATUS_MESSAGE.providerDelete.success);
      return onSuccess();
    } catch (_) {
      message.error(STATUS_MESSAGE.providerDelete.error.general);
      return onClose();
    }
  }, [
    personID,
    provider,
    deleteProvider,
    deleteProviderError,
    onClose,
    onSuccess,
  ]);

  const providerFriendlyText =
    providerType === 'clinician' ? 'clinician' : 'health coach';

  return !isVisible ? null : (
    <Modal
      confirmLoading={mutationLoading}
      destroyOnClose
      okText="Delete"
      okType="danger"
      onCancel={onClose}
      onOk={onDelete}
      visible
    >
      {mutationLoading ? (
        <ALoadingStyled centered />
      ) : provider ? (
        <APadding type="modal">
          <Title>Are you sure you want to delete this provider?</Title>
          <Subtitle>
            {`When you click remove, ${provider.firstName} ${provider.lastName} will no longer be ${personFirstName}'s assigned
            ${providerFriendlyText}. Then, two things will happen:`}
          </Subtitle>
          <ul>
            <PaddedLi>
              {`${personFirstName} will be prompted to choose a new ${providerFriendlyText} when scheduling 
              for a new appointment.`}
            </PaddedLi>
            <PaddedLi>
              {`${personFirstName} will not be able to message a ${providerFriendlyText} until scheduling a
              new medical visit.`}
            </PaddedLi>
          </ul>
        </APadding>
      ) : (
        <APadding type="modal">
          <Title>Are you sure you want to delete this provider?</Title>
        </APadding>
      )}
    </Modal>
  );
};

// Styled components ///////////////////////////////
const ALoadingStyled = styled(ALoading)`
  &&& {
    padding: ${theme.space.xxl} 0 ${theme.space.xxxl} 0;
  }
`;

const Title = styled.h2`
  text-align: center;
`;

const Subtitle = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const PaddedLi = styled.li`
  padding-top: 8px;
`;

export { ProviderDeleteModal };
