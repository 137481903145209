import gql from 'graphql-tag';

export const MEMBER_NEW_INSURANCE_CARD_QUERY = gql`
  query MemberNewInsuranceCard_Query($personId: ID!) {
    subscriber: getInsuranceSubscriberByPersonId(
      personId: $personId
      active: true
    ) {
      id
      personId
      groupId
      firstName
      lastName
      relationshipToPatient
      addressLine1
      addressLine2
      sex
      city
      state
      zipCode
      dateOfBirth
      planId
      policyNumber
      planStartDate
      country
      copayAmountInCents
      payerDetails {
        id
        payerName
      }
    }
  }
`;

export const MEMBER_SUBSCRIBER_RELATIONSHIP_TYPES = gql`
  query MemberInsuranceRelationshipTypes {
    getRelationshipTypes {
      id
      description
    }
  }
`;
