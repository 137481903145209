import gql from 'graphql-tag';

const PlanPurchaseEndOrCancel_Fragment_extension = gql`
  fragment PlanPurchaseEndOrCancel_Fragment_extension on PapiPlanPurchase {
    id
    endTime
    startTime
  }
`;

export const PlanPurchaseEndOrCancel_Fragment_planPurchase = gql`
  fragment PlanPurchaseEndOrCancel_Fragment_planPurchase on PapiPlanPurchase {
    id
    center {
      id
      timezone
    }
    endDateIncludingExtensions
    endTime
    member: person {
      id
    }
    nextRenewalDate
    plan {
      id
      product {
        displayName
      }
    }
    startTime
    ...PlanPurchaseEndOrCancel_Fragment_extension
  }
  ${PlanPurchaseEndOrCancel_Fragment_extension}
`;
