import { useMutation } from '@apollo/react-hooks';
import { Modal, message } from 'antd';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { AButton } from 'app/components/atoms/AButton/AButton';
import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { AInputConfirmation } from 'app/components/atoms/AInputConfirmation/AInputConfirmation';
import { APadding } from 'app/components/atoms/APadding/APadding';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { MemberPaymentCard } from 'app/components/organisms/MemberPaymentCard/MemberPaymentCard';
import { theme } from 'app/styles/theme';
import { PaymentCardDelete_Fragment_card } from 'app/types/generated/PaymentCardDelete_Fragment_card';
import {
  PaymentCardDelete_Mutation,
  PaymentCardDelete_MutationVariables,
} from 'app/types/generated/PaymentCardDelete_Mutation';
import { PaymentCardDelete_UpdateQuery } from 'app/types/generated/PaymentCardDelete_UpdateQuery';
import { TYPENAMES } from 'app/types/papi';
import { displayErrors } from 'app/utils/app';
import { STATUS_MESSAGE } from 'constants/message';

import {
  PAYMENT_CARD_DELETE_MUTATION,
  PAYMENT_CARD_DELETE_UPDATE_QUERY,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  card: PaymentCardDelete_Fragment_card;
}

/** Form to delete a member's payment card */
const PaymentCardDelete: FC<Props> = ({ card }) => {
  const formHook = useForm();
  const [showForm, setShowForm] = useState(false);

  const [deletePaymentCard, { loading: mutationLoading }] = useMutation<
    PaymentCardDelete_Mutation,
    PaymentCardDelete_MutationVariables
  >(PAYMENT_CARD_DELETE_MUTATION, {
    update: (cache, mutationResult) => {
      const isSuccess = mutationResult.data;
      if (!isSuccess) {
        return;
      }

      const cacheData = cache.readQuery<PaymentCardDelete_UpdateQuery>({
        query: PAYMENT_CARD_DELETE_UPDATE_QUERY,
        variables: { personID: card.personID },
      });
      if (!cacheData || !cacheData.member) {
        return;
      }

      cache.writeQuery({
        data: {
          member: {
            ...cacheData.member,
            paymentCards: {
              __typename: TYPENAMES.PapiPaymentCardConnection,
              edges: cacheData.member.paymentCards.edges.filter(
                ({ node: currCard }) => currCard.id !== card.id
              ),
            },
          },
        },
        query: PAYMENT_CARD_DELETE_UPDATE_QUERY,
      });
    },
  });

  const onSave = async (): Promise<void> => {
    try {
      const result = await deletePaymentCard({
        variables: {
          id: card.id,
        },
      });
      if (result?.data) {
        setShowForm(false);
        message.success(STATUS_MESSAGE.paymentCardDelete.success);
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.paymentCardDelete.error.general);
    }
  };

  return (
    <>
      {card.deleted ? (
        // card.deleted = card has been removed as the default payment
        <div onClick={() => setShowForm(true)}>Delete card</div>
      ) : (
        <AButton
          buttonBorder={false}
          disabled={{
            message: STATUS_MESSAGE.paymentCardDelete.error.defaultCard,
            tooltipPlacement: 'right',
          }}
        >
          Delete card
        </AButton>
      )}

      <Modal
        confirmLoading={mutationLoading}
        destroyOnClose
        okText={mutationLoading ? 'Deleting...' : 'Delete'}
        onCancel={() => setShowForm(false)}
        onOk={formHook.handleSubmit(onSave)}
        title="Delete card"
        visible={card.deleted && showForm}
      >
        <APadding type="modal">
          <MemberPaymentCard card={card} defaultStyle editable={false} />

          <Description>
            This card will be deleted from the member's records, and card
            details will have to be re-entered to add the card back in the
            future.
          </Description>
          <Description>
            <ATextBold>This action cannot be undone.</ATextBold> Enter DELETE to
            continue.
          </Description>

          <AFormFieldRow>
            <AInputConfirmation
              confirmationText="delete"
              formHook={formHook}
              name={'delete' + card.id}
            />
          </AFormFieldRow>
        </APadding>
      </Modal>
    </>
  );
};

// Styled components ////////////////////////////////
const Description = styled.div`
  margin: ${theme.space.m} 0;
`;

export { PaymentCardDelete };
