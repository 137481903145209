import gql from 'graphql-tag';

import { ProvidersTable_Fragment_provider } from './fragment';

export const PROVIDERS_TABLE_QUERY = gql`
  query ProvidersTable_Query {
    centers: listCenters {
      id
      abbreviatedName
      isVirtual
    }
    providers: listProviders {
      ...ProvidersTable_Fragment_provider
    }
    products: listRegisteredProducts {
      abbreviation
      type
    }
  }
  ${ProvidersTable_Fragment_provider}
`;
