import 'antd/dist/antd.css';
import 'sanitize.css/sanitize.css';
import { createGlobalStyle } from 'styled-components/macro';

import { STATIC_FILES_URL } from 'constants/env';

import { overrides } from './antdOverrides';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  * {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    &::-webkit-scrollbar {
        display: none;
    }
  }

  @font-face {
    font-family: 'EuclidCircular';
    src: url('${STATIC_FILES_URL}/fonts/euclid-circular-b/euclid-circular-b-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: block;
    -webkit-font-smoothing: antialiased;
  }

  @font-face {
    font-family: 'EuclidCircular';
    src: url('${STATIC_FILES_URL}/fonts/euclid-circular-b/euclid-circular-b-medium.woff') format('woff'); 
    font-weight: 500;
    font-style: normal;
    font-display: block;
    -webkit-font-smoothing: antialiased;
  }

  html {
    height: 100%;
    font-size: 14px;

    ${theme.layout.breakpointMixin.phoneOnly} {
      font-size: 13px;
    }
  }

  body {
    background: white;
    color: ${theme.color.text};
    font-family: ${theme.font.family.base};
    font-weight: ${theme.font.weight.base};
    font-size: ${theme.font.size.base};
    height: 100%;
    margin: 0;
    min-height: 100%;
    line-height: ${theme.font.lineHeight.base};
  }

  div, p, h1, h2, h3, h4, h5, h6 {
    color: ${theme.color.text};
  }

  h1 {
    font-family: ${theme.font.family.heading} !important;
    font-size: ${theme.font.size.huge};
    font-weight: ${theme.font.weight.medium};
  }

  h2 {
    font-family: ${theme.font.family.heading} !important;
    font-size: ${theme.font.size.xxxxl};
    font-weight: ${theme.font.weight.medium};
  }

  h3 {
    font-family: ${theme.font.family.heading} !important;
    font-size: ${theme.font.size.xxxl};
    font-weight: ${theme.font.weight.medium};
  }

  h4 {
    font-family: ${theme.font.family.heading} !important;
    font-size: ${theme.font.size.xxl};
    font-weight: ${theme.font.weight.medium};
  }

  h5 {
    font-family: ${theme.font.family.heading} !important;
    font-size: ${theme.font.size.xl};
    font-weight: ${theme.font.weight.medium};
  }

  h6 {
    font-family: ${theme.font.family.heading} !important;
    font-size: ${theme.font.size.l};
  }

  button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }

  select::-ms-expand {
    display: none;
  }

  [aria-disabled*=false] {
    cursor: inherit;
  }

  #root {
    background: white;
    min-height: 100%;
    min-width: 100%;
    overflow-x: hidden;
  }

  ${overrides}
`;
