import gql from 'graphql-tag';

export const INVOICE_UPDATE_STATUS_MUTATION = gql`
  mutation InvoiceUpdateStatus_Mutation(
    $input: PapiSetInvoicePaymentStatusInput!
  ) {
    setInvoicePaymentStatus(input: $input) {
      id
      status
    }
  }
`;
