import { Card } from 'antd';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

import {
  APrepCardRow,
  APrepCardRowProps,
} from 'app/components/atoms/APrepCardRow/APrepCardRow';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  callToAction?: APrepCardRowProps;
  completed?: boolean;
  loading?: boolean;
  onClick?: () => void;
  selected?: boolean;
  title?: ReactNode;
}

/** Card template used for visit prep/document review to associate a member with documents */
const APrepCard: FC<Props> = ({
  callToAction,
  children,
  completed,
  loading,
  onClick,
  selected,
  title,
}) => {
  return (
    <CardStyled
      bodyStyle={{ padding: 0 }}
      data-completed={completed}
      loading={loading}
      onClick={onClick}
      selected={selected}
      size="small"
    >
      {title && <Title>{title}</Title>}
      {children && <Body>{children}</Body>}
      {callToAction && (
        <CallToAction>
          <APrepCardRow {...callToAction} />
        </CallToAction>
      )}
    </CardStyled>
  );
};

// Styled components ////////////////////////////////
const Body = styled.div`
  padding-bottom: ${theme.space.s};
`;

const CallToAction = styled.div`
  &&& {
    border-top: 1px solid ${theme.color.border};
    padding: ${theme.space.s} 0;
  }
`;

const CardStyled = styled(Card)<{
  ['data-completed']?: boolean;
  selected?: boolean;
}>`
  &&& {
    border-radius: ${theme.space.s};
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
    margin-bottom: ${theme.space.m};
    opacity: ${(props) =>
      props['data-completed'] ? theme.layer.opacity.prepCardCompleted : 1};
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%;

    ${({ selected }) =>
      selected
        ? `
          box-shadow: ${theme.layer.boxShadow.prepCard};
          transform: scale(${theme.animation.scale.prepCard.selected});
          `
        : ''};
  }
`;

const Title = styled.div`
  ${theme.font.mixins.ellipsisOverflow}
  font-size: ${theme.font.size.l};
  font-weight: ${theme.font.weight.medium};
  padding: ${theme.space.sm} ${theme.space.m} ${theme.space.xs} ${theme.space.m};
`;

export { APrepCard };
