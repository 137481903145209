import React, { FC } from 'react';

import { ASelect, ASelectProps } from 'app/components/atoms/ASelect/ASelect';
import { PapiAppointmentFormat } from 'app/types/generated/globalTypes';
import { compareLabel } from 'app/utils/sort';
import { formatVisitFormat } from 'app/utils/visit';

// Types & constants ////////////////////////////////
type Props = Omit<ASelectProps, 'selectOptions'>;

/** Select visit format (in person, online) */
const ASelectVisitFormat: FC<Props> = (props) => {
  return (
    <ASelect
      selectOptions={Object.values(PapiAppointmentFormat)
        .map((visitFormat) => ({
          label: formatVisitFormat(visitFormat),
          value: visitFormat,
        }))
        .sort(compareLabel)}
      {...props}
    />
  );
};

export { ASelectVisitFormat };
