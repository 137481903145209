import gql from 'graphql-tag';

const PlanPurchaseExtend_Fragment_extension = gql`
  fragment PlanPurchaseExtend_Fragment_extension on PapiPlanPurchase {
    id
    startTime
  }
`;

export const PlanPurchaseExtend_Fragment_planPurchase = gql`
  fragment PlanPurchaseExtend_Fragment_planPurchase on PapiPlanPurchase {
    id
    center {
      id
      timezone
    }
    endDateIncludingExtensions
    endTime
    member: person {
      id
    }
    latestPause {
      endDate
      startDate
    }
    payor {
      id
    }
    plan {
      id
      product {
        displayName
      }
    }
    startTime
    purchaseState
    ...PlanPurchaseExtend_Fragment_extension
  }
  ${PlanPurchaseExtend_Fragment_extension}
`;
