import React, { FC } from 'react';

import { ASelect, ASelectProps } from 'app/components/atoms/ASelect/ASelect';
import { formatProviderRole } from 'app/utils/provider';
import { compareLabel } from 'app/utils/sort';
import { PROVIDER_ROLES } from 'constants/provider';

// Types & constants ////////////////////////////////
type Props = Omit<ASelectProps, 'selectOptions'>;

/** Select provider role (doctor, health coach, etc) */
const ASelectProviderRole: FC<Props> = (props) => {
  return (
    <ASelect
      searchable
      selectOptions={PROVIDER_ROLES.map((role) => ({
        label: formatProviderRole(role),
        value: role,
      })).sort(compareLabel)}
      {...props}
    />
  );
};

export { ASelectProviderRole };
