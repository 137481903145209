import { useAuth } from '@parsleyhealth/front-end-utils';
import firebaseApp from 'firebase';
import * as launchDarkly from 'launchdarkly-js-client-sdk';
import React, { useEffect, useState } from 'react';

import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { LAUNCHDARKLY_CLIENT_ID } from 'constants/env';

let LDProvider: React.FC | undefined;

const LDWrapper: React.FC<{}> = (props) => {
  const [client, setClient] = useState<launchDarkly.LDClient | null>(null);
  const { firebaseUser } = useAuth();
  useEffect(() => {
    if (!LDProvider && firebaseUser) {
      const loadLD = async (
        user: firebaseApp.User
      ): Promise<launchDarkly.LDClient> => {
        const idTokenResult = await user.getIdTokenResult();
        const userRole = idTokenResult.claims.role;
        const launchDarklyClient = launchDarkly.initialize(
          LAUNCHDARKLY_CLIENT_ID,
          {
            custom: {
              role: userRole,
            },
            key: user.email || user.uid,
          }
        );

        const LDreadry = new Promise<launchDarkly.LDClient>((res) => {
          launchDarklyClient.on('ready', () => {
            res(launchDarklyClient);
          });
        });
        return LDreadry;
      };
      loadLD(firebaseUser).then((client) => {
        setClient(client);
      });
    }
  }, [firebaseUser]);

  if (client !== null) {
    return (
      <FeatureFlagContext.Provider value={client}>
        {props.children}
      </FeatureFlagContext.Provider>
    );
  }

  return null;
};

export default LDWrapper;
