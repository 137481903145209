import { DateTime } from 'luxon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AFlexbox } from 'app/components/atoms/AFlexbox/AFlexbox';
import { AIconInline } from 'app/components/atoms/AIconInline/AIconInline';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { theme } from 'app/styles/theme';
import { PlanDetails_Fragment_plan } from 'app/types/generated/PlanDetails_Fragment_plan';
import {
  getCenterDisplayName,
  getCenterDisplayNameWithIcon,
} from 'app/utils/center';
import {
  formatBillingPeriod,
  formatPlanAgeLimit,
  getPlanPaymentRate,
} from 'app/utils/plan';
import { compareLabel } from 'app/utils/sort';
import { PLAN_FIELD_HELP } from 'constants/plan';

// Types & constants ////////////////////////////////
interface Props {
  plan: PlanDetails_Fragment_plan;
}

/** Display a Parsley membership plan's details, e.g. billing, where it's available */
const PlanDetails: FC<Props> = ({ plan }) => {
  return (
    <>
      <Title>{plan.displayName}</Title>

      <ASectionBtmMargin>
        <StatusIcon
          data-active={!plan.deprecatedAt}
          type={plan.deprecatedAt ? 'stop' : 'check-circle'}
        />
        <ALabel inline>
          {plan.deprecatedAt
            ? `Deactivated ${DateTime.fromISO(plan.deprecatedAt).toLocaleString(
                DateTime.DATE_SHORT
              )}`
            : 'Active'}
        </ALabel>
      </ASectionBtmMargin>

      <ASectionBtmMargin>
        <ALabel help={PLAN_FIELD_HELP.product}>Product</ALabel>
        <div>{plan.product.displayName}</div>
      </ASectionBtmMargin>

      <ASectionBtmMargin>
        <BillingInfo>
          <BillingInfoLeft>
            <ALabel help={PLAN_FIELD_HELP.billingPeriod}>Billing period</ALabel>
            <div>{formatBillingPeriod(plan.billingPeriod)}</div>
          </BillingInfoLeft>
          <div>
            <ALabel help={PLAN_FIELD_HELP.numberOfBillingCycles}>
              Billing cycles
            </ALabel>
            <div>
              {
                plan.numberOfBillingCycles ||
                  String.fromCharCode(8734) /* Infinity */
              }
            </div>
          </div>
        </BillingInfo>
      </ASectionBtmMargin>
      <ASectionBtmMargin>
        <ALabel>Payment</ALabel>
        <div>{plan.paymentRateInCents ? getPlanPaymentRate(plan) : '$0'}</div>
      </ASectionBtmMargin>

      <ASectionBtmMargin>
        <ALabel help={PLAN_FIELD_HELP.joinCentersAllowed}>
          Joinable centers
        </ALabel>
        <AFlexbox>
          {plan.joinCentersAllowed.length === 0 ? (
            <ATextLight lighter>–</ATextLight>
          ) : (
            plan.joinCentersAllowed
              .map((center) => ({
                ...center,
                label: getCenterDisplayName(center),
              }))
              .sort(compareLabel)
              .map((center, i) => (
                <Center key={center.id}>
                  {getCenterDisplayNameWithIcon(center)}
                  {i < plan.joinCentersAllowed.length - 1 &&
                    i !== plan.joinCentersAllowed.length - 1 &&
                    ', '}
                </Center>
              ))
          )}
        </AFlexbox>
      </ASectionBtmMargin>

      <ASectionBtmMargin>
        <ALabel help={PLAN_FIELD_HELP.commitmentLength}>
          Commitment length
        </ALabel>
        <div>
          {plan.commitmentLength ? (
            `${plan.commitmentLength} month${
              plan.commitmentLength === 1 ? '' : 's'
            }`
          ) : (
            <ATextLight lighter>–</ATextLight>
          )}
        </div>
      </ASectionBtmMargin>

      <ASectionBtmMargin>
        <ALabel>Age limit</ALabel>
        <div>
          {formatPlanAgeLimit(plan) || <ATextLight lighter>–</ATextLight>}
        </div>
      </ASectionBtmMargin>

      <ASectionBtmMargin>
        <ALabel help={PLAN_FIELD_HELP.defaultFollowOnPlan}>
          Default follow-on plan
        </ALabel>
        <div>
          {plan.defaultFollowOnPlan ? (
            plan.defaultFollowOnPlan.displayName
          ) : (
            <ATextLight lighter>–</ATextLight>
          )}
        </div>
      </ASectionBtmMargin>
    </>
  );
};

// Styled components ////////////////////////////////
const BillingInfo = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;
const BillingInfoLeft = styled.div`
  margin-right: ${theme.space.xxxl};
`;

const Center = styled(AFlexbox)`
  &&& {
    margin-right: ${theme.space.xs};
  }
`;

const StatusIcon = styled(AIconInline)<{ ['data-active']: boolean }>`
  &&& {
    bottom: ${theme.space.xs};
    color: ${(props) =>
      props['data-active'] ? theme.color.primary : theme.color.error};
    margin-right: ${theme.space.s};
  }
`;

const Title = styled(ASectionBtmMargin)`
  &&& {
    font-size: ${theme.font.size.l};
    font-weight: ${theme.font.weight.medium};
    width: calc(${theme.layout.drawerSizeDefault}px - 4rem);
  }
`;

export { PlanDetails };
