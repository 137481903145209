import { TYPENAMES } from 'app/types/papi';

export const MOCK_CENTERS = [
  {
    __typename: TYPENAMES.PapiCenter,
    abbreviatedName: 'NYC',
    city: 'New York',
    id: '760ae573-24c3-4138-863d-1a60a92641ca',
    isVirtual: false,
    mdhqHost: 'https://parsleymedical.md-hq.com',
    name: 'Parsley Center NYC',
    state: 'NY',
    timezone: 'America/New_York',
  },
  {
    __typename: TYPENAMES.PapiCenter,
    abbreviatedName: 'LA',
    city: 'Los Angeles',
    id: '56ece908-227b-4541-b0fd-3e3b9707c32c',
    isVirtual: false,
    mdhqHost: 'https://parsleymedicalla.md-hq.com',
    name: 'Parsley Center LA',
    state: 'CA',
    timezone: 'America/Los_Angeles',
  },
  {
    __typename: TYPENAMES.PapiCenter,
    abbreviatedName: 'SF',
    city: 'San Francisco',
    id: '99c24117-7a43-4dc0-b9c6-e03aed9316b4',
    isVirtual: false,
    mdhqHost: 'https://parsleymedicalsf.md-hq.com',
    name: 'Parsley Center SF',
    state: 'CA',
    timezone: 'America/Los_Angeles',
  },
  {
    __typename: TYPENAMES.PapiCenter,
    abbreviatedName: 'NY',
    city: 'New York',
    id: 'ba132de8-8de5-4b20-a4d5-0511c1195a41',
    isVirtual: true,
    mdhqHost: null,
    name: 'Parsley Virtual Center NY',
    state: 'NY',
    timezone: 'America/New_York',
  },
  {
    __typename: TYPENAMES.PapiCenter,
    abbreviatedName: 'CA',
    city: 'New York',
    id: '6a5d047d-9c0b-43ae-ab54-f166cfb655f4',
    isVirtual: true,
    mdhqHost: null,
    name: 'Parsley Virtual Center CA',
    state: 'CA',
    timezone: 'America/Los_Angeles',
  },
  {
    __typename: TYPENAMES.PapiCenter,
    abbreviatedName: 'TX',
    city: 'New York',
    id: 'ba274d53-3f59-4418-a230-1ebeb0a97ca5',
    isVirtual: true,
    mdhqHost: null,
    name: 'Parsley Virtual Center TX',
    state: 'TX',
    timezone: 'America/Chicago',
  },
  {
    __typename: TYPENAMES.PapiCenter,
    abbreviatedName: 'FL',
    city: 'New York',
    id: 'a23ae785-32cc-4b06-bca7-bd9118c9d27b',
    isVirtual: true,
    mdhqHost: null,
    name: 'Parsley Virtual Center FL',
    state: 'FL',
    timezone: 'America/New_York',
  },
];
