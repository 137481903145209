import gql from 'graphql-tag';

export const MemberBasics_Fragment_member = gql`
  fragment MemberBasics_Fragment_member on PapiPerson {
    id
    addressCity
    addressLine1
    addressLine2
    addressPostalCode
    addressState
    biologicalSex
    center {
      id
      name
    }
    dateOfBirth
    email
    firstName
    genderIdentity
    lastName
    middleName
    phoneNumber
    enrolledBy
  }
`;
