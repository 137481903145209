import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

export const ATextLight = styled.span<{
  lighter?: boolean;
  lightest?: boolean;
}>`
  color: ${({ lighter, lightest }) =>
    lightest
      ? theme.color.textLightest
      : lighter
      ? theme.color.textLighter
      : theme.color.textLight};
  * {
    color: ${({ lighter, lightest }) =>
      lightest
        ? theme.color.textLightest
        : lighter
        ? theme.color.textLighter
        : theme.color.textLight};
  }
`;
