import { Breadcrumb, Layout } from 'antd';
import capitalize from 'lodash/capitalize';
import compact from 'lodash/compact';
import upperFirst from 'lodash/upperFirst';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import { MembersSearch } from 'app/components/organisms/MembersSearch/MembersSearch';
import {
  IBreadcrumb,
  IBreadcrumbOptions,
  createNavContext,
} from 'app/contexts/NavContext';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  navbarCollapsed?: boolean;
}

/** App header bar, including breadcrumbs and user profile button */
const AppHeader: FC<Props> = ({ navbarCollapsed = true }) => {
  const history = useHistory();
  const location = useLocation();

  const [paths, setPaths] = useState<IBreadcrumb[]>([]);
  const [breadcrumbOptions, setBreadcrumbOptions] = useState<
    IBreadcrumbOptions
  >({});
  const NavContext = createNavContext({ setBreadcrumbOptions });

  useEffect(() => {
    setPaths(
      compact(location.pathname.split('/'))
        .filter((path) => !breadcrumbOptions[path]?.hide)
        .map((path, i) => ({
          label:
            breadcrumbOptions[path]?.label ||
            path.split('-').map(capitalize).join(' '),
          private: breadcrumbOptions[path]?.private,
          url: location.pathname
            .split('/')
            .slice(0, i + 2)
            .join('/'),
        }))
    );
  }, [breadcrumbOptions, location.pathname]);

  return (
    <NavContext.Provider value={{ setBreadcrumbOptions }}>
      <LayoutHeaderStyled data-fullwidth={navbarCollapsed}>
        <Navigation>
          <BreadcrumbStyled>
            {paths.map((path, i) => (
              <BreadcrumbItemStyled
                data-private={path.private}
                data-testid="AppHeader_breadcrumb"
                key={`Breadcrumb_${path.label}`}
                onClick={
                  path.url && i < paths.length - 1
                    ? () => history.push(path.url!)
                    : undefined
                }
              >
                {upperFirst(path.label)}
              </BreadcrumbItemStyled>
            ))}
          </BreadcrumbStyled>
        </Navigation>

        <SearchContainer>
          <MembersSearch />
        </SearchContainer>
      </LayoutHeaderStyled>
    </NavContext.Provider>
  );
};

// Styled components ////////////////////////////////
const BreadcrumbStyled = styled(Breadcrumb)`
  &&& {
    opacity: 100;
    ${theme.layout.breakpointMixin.phoneOnly} {
      opacity: 0;
    }
  }
`;

const BreadcrumbItemStyled = styled(Breadcrumb.Item)<{
  onClick: (() => void) | undefined;
}>`
  &&& {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
    transition-duration: ${theme.animation.transitionDuration};
    transition-property: color;
    transition-timing-function: ${theme.animation.transitionTiming};
    :hover {
      color: ${({ onClick }) => (onClick ? theme.color.primary : 'inherit')};
    }
  }
`;

const LayoutHeaderStyled = styled(Layout.Header)<{
  ['data-fullwidth']: boolean;
}>`
  &&& {
    align-items: center;
    background: white;
    box-shadow: ${theme.layer.boxShadow.bottom};
    display: flex;
    height: ${theme.layout.headerSize};
    justify-content: space-between;
    margin-left: ${(props) =>
      props['data-fullwidth']
        ? theme.layout.navbarSizeCollapsed
        : theme.layout.navbarSize}px;
    padding: 0 ${theme.space.m} 0 0;
    position: fixed;
    transition-duration: ${theme.animation.transitionDuration};
    transition-property: all;
    transition-timing-function: ${theme.animation.transitionTiming};
    width: ${(props) =>
      props['data-fullwidth']
        ? `calc(100% - ${theme.layout.navbarSizeCollapsed}px)`
        : `calc(100% - ${theme.layout.navbarSize}px)`};
    z-index: ${theme.layer.zIndex.header};
    ${theme.layout.breakpointMixin.phoneOnly} {
      background: ${theme.color.navbar};
      box-shadow: 0;
      margin-left: 0;
      position: absolute;
      width: 100%;
    }
  }
`;

const Navigation = styled.div`
  align-items: center;
  display: flex;
  margin-left: ${theme.space.m};
`;

const SearchContainer = styled.div`
  position: absolute;
  right: 0;
`;

export { AppHeader };
