import gql from 'graphql-tag';

export const PlanCreateUpdate_Fragment_updatePlan = gql`
  fragment PlanCreateUpdate_Fragment_updatePlan on PapiPlan {
    id
    billingPeriod
    commitmentLength
    defaultFollowOnPlan {
      id
    }
    displayName
    joinCentersAllowed {
      id
    }
    maxPersonAgeYears
    minPersonAgeYears
    numberOfBillingCycles
    paymentRateInCents
    product {
      type
    }
  }
`;
