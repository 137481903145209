import gql from 'graphql-tag';

export const MEMBER_PAGE_QUERY = gql`
  query MemberPage_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      firstName
      lastName
      middleName
    }
  }
`;

export const MEMBER_PAGE_QUERY_V2 = gql`
  query MemberPage_Query_V2($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      firstName
      lastName
      dateOfBirth
      middleName
    }
  }
`;
