import gql from 'graphql-tag';

import { ProductDetails_Fragment_product } from 'app/components/organisms/ProductDetails/fragment';

import { ProductsList_Fragment_plan } from './fragment';

export const PRODUCTS_LIST_QUERY = gql`
  query ProductsList_Query {
    products: listRegisteredProducts {
      displayName
      plans {
        ...ProductsList_Fragment_plan
      }
      type
      ...ProductDetails_Fragment_product
    }
  }
  ${ProductDetails_Fragment_product}
  ${ProductsList_Fragment_plan}
`;
