import { ExecutionResult } from '@apollo/react-common';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import {
  AForm,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { AInputField } from 'app/components/deprecated/AInputField/AInputField';
import { APhoneField } from 'app/components/deprecated/APhoneField/APhoneField';
import { ASelectField } from 'app/components/deprecated/ASelectField/ASelectField';
import {
  CreatePersonEmergencyContact_Mutation,
  CreatePersonEmergencyContact_MutationVariables,
} from 'app/types/generated/CreatePersonEmergencyContact_Mutation';
import {
  GetPersonEmergencyContact_Query,
  GetPersonEmergencyContact_QueryVariables,
} from 'app/types/generated/GetPersonEmergencyContact_Query';
import { PapiPersonEmergencyContactInput } from 'app/types/generated/globalTypes';
import {
  UpdatePersonEmergencyContact_Mutation,
  UpdatePersonEmergencyContact_MutationVariables,
} from 'app/types/generated/UpdatePersonEmergencyContact_Mutation';
import { displayErrors } from 'app/utils/app';
import { RELATIONSHIPS } from 'constants/form';
import { STATUS_MESSAGE } from 'constants/message';

import {
  MEMBER_CREATE_EMERGENCY_CONTACT,
  MEMBER_GET_EMERGENCY_CONTACT,
  MEMBER_UPDATE_EMEGERENCY_CONTACT,
} from './query';

interface Props {
  onCancel: () => void;
  onSave: () => void;
  personID: string;
}

const MemberUpdateEmergencyContact: React.FC<Props> = ({
  onCancel,
  onSave,
  personID,
}) => {
  const {
    data: emergencyContact,
    error,
    loading: queryLoading,
    refetch,
  } = useQuery<
    GetPersonEmergencyContact_Query,
    GetPersonEmergencyContact_QueryVariables
  >(MEMBER_GET_EMERGENCY_CONTACT, { variables: { personID } });

  const [
    updateEmergencyContact,
    { loading: updateEemergencyContactLoading },
  ] = useMutation<
    UpdatePersonEmergencyContact_Mutation,
    UpdatePersonEmergencyContact_MutationVariables
  >(MEMBER_UPDATE_EMEGERENCY_CONTACT);

  const [
    createEmergencyContact,
    { loading: createEmergencyContactLoading },
  ] = useMutation<
    CreatePersonEmergencyContact_Mutation,
    CreatePersonEmergencyContact_MutationVariables
  >(MEMBER_CREATE_EMERGENCY_CONTACT);

  const [emergencyInfoExists, setEmergencyInfoExists] = useState(false);

  const handleSave = async (
    input: PapiPersonEmergencyContactInput,
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      let result: ExecutionResult<
        | UpdatePersonEmergencyContact_Mutation
        | CreatePersonEmergencyContact_Mutation
      >;
      if (emergencyInfoExists) {
        result = await updateEmergencyContact({
          variables: {
            input: {
              ...input,
              personId: personID,
              phoneNumber: input.phoneNumber.replace(/\s/g, ''),
            },
          },
        });
      } else {
        result = await createEmergencyContact({
          variables: {
            input: {
              ...input,
              personId: personID,
              phoneNumber: input.phoneNumber.replace(/\s/g, ''),
            },
          },
        });
      }

      if (result?.data) {
        message.success(STATUS_MESSAGE.memberUpdate.success);
        await refetch();
        onSave();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (e) {
      displayErrors(e, STATUS_MESSAGE.memberUpdate.error.general);
      shouldFinish(false);
    }
  };

  useEffect(() => {
    const fetchedEmergencyContactInformation =
      emergencyContact?.getPersonEmergencyContact;
    if (!queryLoading && !error && fetchedEmergencyContactInformation) {
      setEmergencyInfoExists(true);
    }
  }, [emergencyContact, queryLoading, refetch, error]);

  return (
    <AForm
      onClose={onCancel}
      onSave={handleSave}
      saving={updateEemergencyContactLoading || createEmergencyContactLoading}
    >
      {(form) => (
        <>
          {/* Emergency Contact info */}
          <ATextBold boldness="medium">Emergency Contact</ATextBold>

          {/* Emergency Contact name info */}
          <AFormFieldRow>
            <AInputField
              antdForm={form}
              customWidth={{ max: '200px', percent: 25 }}
              data-private
              disableAutoComplete
              initialValue={
                emergencyContact?.getPersonEmergencyContact?.firstName
              }
              label="First Name"
              name="firstName"
              required
            />
            <AInputField
              antdForm={form}
              customWidth={{ max: '200px', percent: 25 }}
              data-private
              disableAutoComplete
              initialValue={
                emergencyContact?.getPersonEmergencyContact?.lastName
              }
              label="Last Name"
              name="lastName"
              required
            />
          </AFormFieldRow>

          {/* Emergency Contact general info */}
          <AFormFieldRow>
            <ASelectField
              antdForm={form}
              customWidth={{ max: '200px', percent: 25 }}
              filterOption
              initialValue={
                emergencyContact?.getPersonEmergencyContact?.relationship
              }
              label="Relationship to member"
              name="relationship"
              required
              selectOptions={RELATIONSHIPS}
            />
            <APhoneField
              antdForm={form}
              initialValue={
                emergencyContact?.getPersonEmergencyContact?.phoneNumber
              }
              label="Phone"
              name="phoneNumber"
              required
            />
            <AInputField
              antdForm={form}
              customWidth={{ max: '300px', percent: 25 }}
              data-private
              disableAutoComplete
              email
              initialValue={emergencyContact?.getPersonEmergencyContact?.email}
              label="Email"
              name="email"
            />
          </AFormFieldRow>
        </>
      )}
    </AForm>
  );
};

export { MemberUpdateEmergencyContact };
