import { useQuery } from '@apollo/react-hooks';
import { Divider, Skeleton } from 'antd';
import capitalize from 'lodash/capitalize';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AFlexbox } from 'app/components/atoms/AFlexbox/AFlexbox';
import { MemberAdminDropdown } from 'app/components/organisms/MemberAdminDropdown/MemberAdminDropdown';
import {
  MemberLinks,
  MemberLinksProps,
} from 'app/components/organisms/MemberLinks/MemberLinks';
import { MemberStatusTags } from 'app/components/organisms/MemberStatusTags/MemberStatusTags';
import { theme } from 'app/styles/theme';
import {
  MemberHeader_Query,
  MemberHeader_QueryVariables,
} from 'app/types/generated/MemberHeader_Query';
import { getCenterDisplayNameWithIcon } from 'app/utils/center';
import { formatMemberName, getMemberAge } from 'app/utils/member';

import { MEMBER_HEADER_BIO_QUERY, MEMBER_HEADER_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  excludedLinks?: MemberLinksProps['excludedLinks'];
  excludeLinks?: boolean;
  hideAdminOptions?: boolean;
  personID: string;
  setLoading?: (loading: boolean) => void;
  shortForm?: boolean;
}

/** Quick member ID (name, age, sex, center) and external services links */
const MemberHeader: FC<Props> = ({
  excludedLinks,
  excludeLinks,
  hideAdminOptions,
  personID,
  setLoading,
  shortForm = false,
}) => {
  const { data, loading } = useQuery<
    MemberHeader_Query,
    MemberHeader_QueryVariables
  >(shortForm ? MEMBER_HEADER_BIO_QUERY : MEMBER_HEADER_QUERY, {
    variables: { personID },
  });
  const member = data?.member;

  /***
   * Rather than following our usual pattern of defining the entire loading state
   * (skeleton + admin dropdown) before defining our loaded state (member info +
   * admin dropdown), we put it all together so that <MemberAdminDropdown> doesn't
   * rerender and cause the user to lose work they've done within the dropdown.
   */
  return (
    <Header loading={loading || !member}>
      <InfoContainer>
        {loading || !member ? (
          <Skeleton active paragraph={false} title={{ width: '30%' }} />
        ) : (
          <Info>
            <MainInfo>
              <Name data-private>{formatMemberName(member)}</Name>
              <MemberStatusTags member={member} partner={data?.partner} />
            </MainInfo>

            <Details>
              <div>
                {capitalize(member.biologicalSex || '')}
                <Divider type="vertical" />
              </div>
              {member.dateOfBirth && (
                <div>
                  {getMemberAge(member.dateOfBirth)} yrs
                  <Divider type="vertical" />
                </div>
              )}
              {member.center && (
                <AFlexbox>
                  {getCenterDisplayNameWithIcon(member.center)}
                  {!excludeLinks && <Divider type="vertical" />}
                </AFlexbox>
              )}
              {!excludeLinks && (
                <MemberLinks excludedLinks={excludedLinks} member={member} />
              )}
            </Details>
          </Info>
        )}

        {!hideAdminOptions && (
          <AdminDropdown loading={loading || !member}>
            <MemberAdminDropdown
              personEmail={member?.email}
              personID={personID}
              setLoading={setLoading}
            />
          </AdminDropdown>
        )}
      </InfoContainer>
    </Header>
  );
};

// Styled components ////////////////////////////////
const AdminDropdown = styled.div<{ loading: boolean }>`
  padding: ${({ loading }) =>
    loading
      ? `calc(${theme.space.m} - ${theme.space.xxs}) ${theme.space.l} 0 0`
      : 0};
`;

const Details = styled.div`
  bottom: -${theme.space.xxs};
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

const Header = styled.div<{ loading: boolean }>`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ loading }) =>
    loading
      ? `${theme.space.xxs} 0 ${theme.space.s} ${theme.space.m}`
      : `${theme.space.m} ${theme.space.l} ${theme.space.m} ${theme.space.m}`};
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.space.xs};
`;

const Info = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${theme.layout.breakpointMixin.tabletPortraitDown} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const MainInfo = styled.div`
  align-items: center;
  display: flex;
  margin-right: ${theme.space.xs};
`;

const Name = styled.h5`
  margin: 0 ${theme.space.s} 0 0;
`;

export { MemberHeader };
