import lowerFirst from 'lodash/lowerFirst';

const botHandle = '@Parsley Engineering Bug Report';
const bugReport = `Message ${botHandle} on Slack to report this bug 🤖`;
const techSupport = `Message ${botHandle} on Slack for further support 🤖`;

export const STATUS_MESSAGE = {
  assignProvider: {
    error: {
      general: 'Unable to assign provider. Try again',
    },
    success: 'Provider assigned successfully',
  },
  bugReport,
  couponCreate: {
    error: {
      general: 'Coupon could not be created. Try again.',
    },
    success: 'Created coupon successfully 🙌',
  },
  couponUpdate: {
    error: {
      general: 'Coupon could not be updated. Try again.',
    },
    success: 'Updated coupon successfully 🙌',
  },
  documentReview: {
    error: {
      docTitleRequired: 'Please provide the document title',
      general: 'Not all documents were loaded. Try again.',
    },
    success: {
      saved: 'Document successfully sent to MDHQ',
      trashed: 'Trashed document sent to Archive tab',
    },
  },
  error: {
    catchAll: `Oops! Something went wrong. Try again, and if the problem persists, ${lowerFirst(
      bugReport
    )}`,
    graphqlInput: "Something's wrong with your input. Edit and try again.",
    graphqlLoad: "Oops! We couldn't load your data...",
    insufficientPermissions: "Oops! You don't have permission to see this.",
    noApiResponse:
      'No data received from our servers. Please refresh the page to see updates ☕️',
    notFound: "Oops! You shouldn't be seeing this...",
  },
  firebaseProviderDelete: {
    error: {
      general: 'Auth Provider did not unlink!',
    },
    success: 'Google Auth Provider was unlinked.',
  },
  insuranceSearch: {
    error: {
      general: 'Insurance search failed. Try again.',
    },
  },
  insuranceUpdate: {
    error: {
      general: 'Insurance info could not be saved. Try again.',
    },
    personDataInvalid: {
      general:
        'Unable to process this request. We need the member required fields to be filled out in order to proceed.',
    },
    success: 'Saved insurance info 🙌',
  },
  invoiceUpdate: {
    error: {
      general: 'Invoice edits could not be saved. Try again.',
      updateStatusNotLegacy:
        "Invoice status can't be updated because it was created in Stripe. Use Stripe to update this invoice's status.",
    },
    success: 'Saved invoice edits 🙌',
  },
  login: {
    error: {
      general: 'Login failed. Try again.',
      graphql: 'Login failed. Re-enter your email and/or password.',
    },
  },
  memberCreate: {
    error: {
      general: 'Member could not be added. Try again.',
    },
    success: 'Added member successfully 🙌',
  },
  memberDelete: {
    error: {
      general: 'Member could not be deleted. Try again.',
      get: 'Error getting member info. Try again.',
      hasPlan: "Members with a plan in progress can't be deleted.",
      noDataReturned:
        'Member deleted successfully, but no results were received from our servers.',
    },
    success: 'Member deleted.',
  },
  memberPasswordReset: {
    error: {
      general: 'Password reset could not be sent. Try again.',
    },
    success: "Password reset sent to member's email successfully 💌",
  },
  membersSearch: {
    error: {
      general: 'Search failed. Try again.',
    },
  },
  memberThirdPartyIDsUpdate: {
    error: {
      general: "Member's third party edits could not be saved. Try again.",
      noDataReturned:
        'Edits completed, but no results were received from our servers.',
    },
    success: 'Saved third party edits 🙌',
  },
  memberThirdPartyResync: {
    error: {
      general: 'Resync could not be completed. Try again.',
      noDataReturned:
        'Resync completed, but no results were received from our servers.',
    },
    success: 'Resync complete.',
  },
  memberUpdate: {
    error: {
      general: 'Member edits could not be saved. Try again.',
    },
    success: 'Saved member edits 🙌',
  },
  noteCreate: {
    error: {
      general: 'Note could not be created. Try again.',
    },
    success: 'Note created successfully 🙌',
  },
  paymentCardAdd: {
    error: {
      general: 'Card could not be added. Try again.',
    },
    success: 'Added card successfully 🙌',
  },
  paymentCardDelete: {
    error: {
      defaultCard: "The default payment method can't be deleted.",
      general: 'Card could not be deleted. Try again.',
    },
    success: 'Deleted card successfully 🙌',
  },
  paymentCardSetDefault: {
    error: {
      alreadyDefaultCard: 'This card is already the default payment method.',
      general: 'Card edits could not be saved. Try again.',
      invalidCard:
        "An invalid or expired card can't be set as the default payment method.",
    },
    success: 'Updated default card successfully 🙌',
  },
  planCreate: {
    error: {
      general: 'Plan could not be created. Try again.',
    },
    success: 'Created plan successfully 🙌',
  },
  planPurchaseCreate: {
    error: {
      general: 'Care plan could not be added. Try again.',
      graphqlLoad:
        'Member and plan information could not be loaded. Refresh to try again.',
      neverendingPlan:
        "A new care plan can't be added while the member has a care plan without an end date.",
      noCenter: 'Member must be associated with a center to add a care plan.',
    },
    success: 'Added care plan successfully 🙌',
    warning: {
      noMemberAge:
        "Member age can't be determined. Add a birthday to see age-specific care plans.",
      noValidPayment:
        'Member has no valid payment on file. Only showing non-paying care plans.',
    },
  },
  planPurchaseEndOrCancel: {
    error: {
      generalCancel: 'Care plan could not be canceled. Try again.',
      generalEnd: 'Care plan could not be ended. Try again.',
      planEnded: "Care plan can't be canceled because it has already ended.",
    },
    successCancel: 'Canceled care plan successfully.',
    successEnd: 'Ended care plan successfully.',
    successEndWithNewDate: 'Set new care plan end date successfully.',
    warning: {
      endDateAfterRenewal:
        'This end date is after the renewal date. The member will be charged on the renewal date. Is this intended?',
    },
  },
  planPurchaseExtend: {
    error: {
      general: 'Extension could not be added. Try again.',
      noEndTime: 'Care plan must have an end date to be extended.',
      notCurrentPlan: 'Care plan must be in progress to be extended.',
      paused: "Care plan can't be extended during a pause.",
    },
    success: 'Added extension successfully 🙌',
  },
  planPurchasePause: {
    error: {
      general: 'Care plan could not be paused. Try again.',
      generalCancel: 'Pause could not be canceled. Try again.',
      generalUpdate: 'Pause could not be updated. Try again.',
      invalidBillingPeriod: 'Care plans with daily billing may not be paused.',
      notCurrentPlan: 'Care plan must be in progress to be paused.',
      onExtension: "Care plan can't be paused while on extension.",
      waitlist: "Waitlist plans can't be paused.",
    },
    success: 'Paused care plan successfully 🙌',
    successCancel: 'Canceled pause successfully 🙌',
    successUpdate: 'Updated pause successfully 🙌',
  },
  planPurchaseSwitchInsurance: {
    error: {
      general: 'Purchase could not be switched. Try again.',
      noEndTime: 'Care plan must have an end date to be switched.',
      notCurrentPlan: 'Care plan must be in progress to be switched.',
      paused: "Care plan can't be switched during a pause.",
    },
    success: 'Switched plan successfully 🙌',
  },
  planPurchaseUpdate: {
    error: {
      general: 'Care plan could not be updated. Try again.',
      onExtension: "A care plan on extension can't be edited.",
      planEnded: "A care plan that has ended can't be edited.",
    },
    success: 'Updated care plan successfully 🙌',
  },
  planUpdate: {
    error: {
      general: 'Plan could not be edited. Try again.',
      missingPlanID: `Plan ID missing. ${bugReport}`,
    },
    success: 'Saved plan edits 🙌',
  },
  providerCalendarCacheRefresh: {
    error: {
      fail: 'Calendar availabilty refresh failed. Try again.',
      general: 'Calendar availabilty could not be refreshed. Try again.',
    },
    success: 'Refreshed calendar availability successfully 🙌',
  },
  providerCreate: {
    error: {
      general: 'Provider could not be added. Try again.',
    },
    success: 'Added provider successfully 🙌',
  },
  providerDelete: {
    error: {
      general: 'Provider could not be deleted. Try again.',
    },
    success: 'Deleted provider successfully 🙌',
  },
  providerPanelSizeUpdate: {
    error: {
      general: "Provider's target panel size could not be saved. Try again.",
    },
    success: 'Saved target panel size 🙌',
  },
  providerUpdate: {
    error: {
      general: 'Provider edits could not be saved. Try again.',
    },
    success: 'Saved provider edits 🙌',
  },
  stripe: {
    error: {
      noToken: 'Stripe could not process this payment card. Try again.',
    },
  },
  techSupport,
  transferCare: {
    error: {
      general: 'Transfer could not be completed. Try again.',
      noProvider: 'A providerID must be provided to transfer care.',
    },
    success: 'Transfer complete.',
  },
  visitCancel: {
    error: {
      general: 'Visit could not be cancelled. Try again.',
    },
    success: 'Visit cancelled.',
  },
  visitCreate: {
    error: {
      general: 'Visit could not be created. Try again.',
    },
    success: 'Visit created',
  },
  visitDelete: {
    error: {
      general: 'Visit could not be deleted. Try again.',
    },
    success: 'Visit deleted',
  },
  visitList: {
    error: {
      general: 'Visits could not be loaded. Try again.',
    },
  },
  visitPrep: {
    error: {
      general: 'Questionnaires could not be sent. Try again.',
    },
  },
  visitUpdate: {
    error: {
      general: 'Visit could not be updated. Try again.',
    },
    success: 'Visit updated',
  },
  waitlistEnroll: {
    error: {
      general: 'Enrollment could not be completed. Try again.',
      noCenter:
        'Member must be associated with a center to enroll in a care plan.',
      noValidPayment:
        'Member must have a valid payment on file to enroll from the waitlist.',
      noWaitlistFollowOn: `This waitlist doesn't have a default enrollment plan. ${bugReport}`,
    },
    success: 'Enrolled in care plan successfully 🙌',
  },
  warning: {
    beta: 'This page is under development and may contain bugs 🐛',
    underDevelopment:
      'This feature is under development and will be coming soon ☕️',
  },
};
