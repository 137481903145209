import React from 'react';

const LabIcon: React.FC = () => (
  <svg
    data-testid="labIcon"
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" fill="#F6C380" r="20" />
    <path
      d="M31.235 14.4663V12.4343C31.5699 12.3474 31.8181 12.0466 31.8181 11.6857C31.8181 11.2571 31.4692 10.9092 31.0393 10.9092H25.1086C24.6787 10.9092 24.3298 11.2571 24.3298 11.6857C24.3298 12.0461 24.578 12.3468 24.9119 12.4338V14.4379H23.1605V12.4343C23.4959 12.3479 23.7446 12.0466 23.7446 11.6857C23.7446 11.2571 23.3957 10.9092 22.9658 10.9092H17.0351C16.6052 10.9092 16.2563 11.2571 16.2563 11.6857C16.2563 12.0461 16.5045 12.3458 16.8384 12.4338V14.4379H15.088V12.4338C15.4235 12.3468 15.6711 12.0466 15.6711 11.6857C15.6711 11.2571 15.3222 10.9092 14.8923 10.9092H8.96061C8.53068 10.9092 8.18176 11.2571 8.18176 11.6857C8.18176 12.0466 8.43047 12.3463 8.76434 12.4338V14.4663C8.42892 14.5538 8.18176 14.8541 8.18176 15.2144C8.18176 15.5753 8.43047 15.8755 8.76434 15.963V26.8483C8.76434 28.5852 10.1829 30.0001 11.927 30.0001C13.6695 30.0001 15.088 28.5867 15.088 26.8483V15.991H16.8384V26.8483C16.8384 28.5852 18.2564 30.0001 20.0005 30.0001C21.7425 30.0001 23.1605 28.5867 23.1605 26.8483V15.991H24.9119V26.8483C24.9119 28.5852 26.3299 30.0001 28.0729 30.0001C29.816 30.0001 31.235 28.5867 31.235 26.8483V15.9635C31.5699 15.8766 31.8181 15.5758 31.8181 15.2149C31.8181 14.8541 31.5694 14.5533 31.235 14.4663ZM13.5303 14.4384H10.322V12.4623H13.5303V14.4384ZM11.927 28.4475C11.0417 28.4475 10.322 27.7305 10.322 26.8488V21.8022H13.5303V26.8488C13.5303 27.731 12.8107 28.4475 11.927 28.4475ZM13.5303 20.2491H10.322V15.9915H13.5303V20.2491ZM18.396 12.4623H21.6028V14.4384H18.396V12.4623ZM20.0005 28.4475C19.1152 28.4475 18.396 27.7305 18.396 26.8488V23.5878H21.6028V26.8488C21.6028 27.731 20.8837 28.4475 20.0005 28.4475ZM21.6028 22.0352H18.396V15.9915H21.6028V22.0352ZM26.4695 12.4623H29.6773V14.4384H26.4695V12.4623ZM29.6773 15.9915V18.463H26.4695V15.9915H29.6773ZM28.0729 28.4475C27.1892 28.4475 26.4695 27.7305 26.4695 26.8488V20.0172H29.6773V26.8493C29.6773 27.731 28.9572 28.4475 28.0729 28.4475Z"
      fill="white"
    />
  </svg>
);

export default LabIcon;
