import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import React, { FC } from 'react';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { AIconClickable } from 'app/components/atoms/AIconClickable/AIconClickable';
import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import {
  AForm,
  TFormFieldInfo,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { ARadioField } from 'app/components/deprecated/ARadioField/ARadioField';
import {
  PapiPaymentStatus,
  PapiSetInvoicePaymentStatusInput,
} from 'app/types/generated/globalTypes';
import { InvoiceUpdateStatus_Fragment_invoice } from 'app/types/generated/InvoiceUpdateStatus_Fragment_invoice';
import {
  InvoiceUpdateStatus_Mutation,
  InvoiceUpdateStatus_MutationVariables,
} from 'app/types/generated/InvoiceUpdateStatus_Mutation';
import { displayErrors } from 'app/utils/app';
import { STATUS_MESSAGE } from 'constants/message';

import { INVOICE_UPDATE_STATUS_MUTATION } from './query';

// Types & constants ////////////////////////////////
interface Props {
  button?: boolean;
  invoice: InvoiceUpdateStatus_Fragment_invoice;
}

/** Form to update a legacy invoice's status */
const InvoiceUpdateStatus: FC<Props> = ({ button, invoice }) => {
  const [updateInvoiceStatus, { loading: mutationLoading }] = useMutation<
    InvoiceUpdateStatus_Mutation,
    InvoiceUpdateStatus_MutationVariables
  >(INVOICE_UPDATE_STATUS_MUTATION);

  const onSave = async (
    input: PapiSetInvoicePaymentStatusInput,
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      const result = await updateInvoiceStatus({
        variables: {
          input: {
            invoiceID: invoice.id,
            newStatus: input.newStatus,
          },
        },
      });
      if (result?.data) {
        message.success(STATUS_MESSAGE.invoiceUpdate.success);
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.invoiceUpdate.error.general);
      shouldFinish(false);
    }
  };

  return (
    <AForm
      customButton={
        button ? (
          <AIconClickable data-testid="InvoiceUpdateStatus_edit" type="edit" />
        ) : undefined
      }
      onSave={onSave}
      openAs="modal"
      openText="Update status"
      saving={mutationLoading}
      title={`Update ${
        invoice.number
          ? `status of invoice ${invoice.number}`
          : 'invoice status'
      }`}
    >
      {(form) => (
        <>
          <ASectionBtmMargin>
            <div>
              {[
                'Invoice:',
                DateTime.fromISO(invoice.createdAt)
                  .toLocal()
                  .toLocaleString(DateTime.DATE_FULL),
                'for',
                new Intl.NumberFormat(undefined, {
                  currency: invoice.currency,
                  style: 'currency',
                }).format(invoice.total / 100),
              ].join(' ')}
            </div>
            <div>
              Current status: {capitalize(invoice.status).replace(/_/g, ' ')}
            </div>
          </ASectionBtmMargin>

          {invoice.isLegacy ? (
            <AFormFieldRow>
              <ARadioField
                antdForm={form}
                label={fields.newStatus.label}
                name={fields.newStatus.name}
                radioOptions={[
                  {
                    label: (
                      <>
                        <ATextBold>
                          {capitalize(PapiPaymentStatus.PAID)}
                        </ATextBold>
                        : Payment was collected manually
                      </>
                    ),
                    value: PapiPaymentStatus.PAID,
                  },
                  {
                    label: (
                      <>
                        <ATextBold>
                          {capitalize(PapiPaymentStatus.VOID)}
                        </ATextBold>
                        : Invoice was accidentally finalized or contains a
                        mistake
                      </>
                    ),
                    value: PapiPaymentStatus.VOID,
                  },
                  {
                    label: (
                      <>
                        <ATextBold>
                          {capitalize(PapiPaymentStatus.UNCOLLECTIBLE)}
                        </ATextBold>
                        : Payment is not expected. It is still possible to
                        collect payment should the member attempt to pay
                      </>
                    ),
                    value: PapiPaymentStatus.UNCOLLECTIBLE,
                  },
                ]}
                required={fields.newStatus.required}
                vertical
              />
            </AFormFieldRow>
          ) : (
            STATUS_MESSAGE.invoiceUpdate.error.updateStatusNotLegacy
          )}
        </>
      )}
    </AForm>
  );
};

/** Form field info */
const fields: Pick<
  TFormFieldInfo<PapiSetInvoicePaymentStatusInput>,
  'newStatus'
> = {
  newStatus: {
    label: 'Mark invoice as',
    name: 'newStatus',
    required: true,
  },
};

export { fields, InvoiceUpdateStatus };
