import gql from 'graphql-tag';

export const A_SELECT_CENTER_FIELD_QUERY = gql`
  query ASelectCenterField_Query {
    centers: listCenters {
      id
      abbreviatedName
      city
      isVirtual
      state
    }
  }
`;
