import React, { useContext, useMemo } from 'react';
import styled from 'styled-components/macro';

import { VisitCard } from 'app/components/organisms/VisitCard/VisitCard';
import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import {
  PapiAppointmentGroup,
  PapiAppointmentStatus,
} from 'app/types/generated/globalTypes';
import { MemberPageVisits_Query_member } from 'app/types/generated/MemberPageVisits_Query';
import { MemberVisits_Query_visits } from 'app/types/generated/MemberVisits_Query';

interface Props {
  member?: MemberPageVisits_Query_member;
  onReload?: () => void;
  sortType: 'displayOrder' | 'startAtAsc' | 'startAtDesc';
  visits: MemberVisits_Query_visits[];
  visitType?: string;
}

export const filterVisitByState = (
  emr709RemoveLabVisit: boolean,
  visits: MemberVisits_Query_visits[],
  visitType?: string,
  member?: MemberPageVisits_Query_member
): MemberVisits_Query_visits[] => {
  if (
    !emr709RemoveLabVisit ||
    !member ||
    visitType !== PapiAppointmentStatus.UNSCHEDULED
  ) {
    return visits;
  }

  return (visits || []).filter(
    (v) => v?.details?.group !== PapiAppointmentGroup.LAB
  );
};

export const MemberVisitsList: React.FC<Props> = ({
  member,
  onReload,
  sortType,
  visits,
  visitType,
}) => {
  const featureFlags = useContext(FeatureFlagContext);
  const emr709RemoveLabVisit = featureFlags.variation(
    'emr-709-remove-lab-visit'
  );
  const sortedVisits = useMemo(() => {
    if (sortType === 'startAtAsc' || sortType === 'startAtDesc') {
      const coef = sortType === 'startAtAsc' ? 1 : -1;
      return visits.sort((a, b) => {
        if (a.startTime && b.startTime) {
          if (a.startTime > b.startTime) {
            return coef;
          }
          if (a.startTime < b.startTime) {
            return -coef;
          }
        }
        return 0;
      });
    }
    if (sortType === 'displayOrder') {
      return visits.sort((a, b) => {
        if (a.displayOrder > b.displayOrder) {
          return 1;
        }
        if (a.displayOrder < b.displayOrder) {
          return -1;
        }

        return 0;
      });
    }
    return visits;
  }, [visits, sortType]);

  const filteredVisits = filterVisitByState(
    emr709RemoveLabVisit,
    sortedVisits,
    visitType,
    member
  );

  return (
    <div>
      {filteredVisits.map((visit) => {
        return (
          <ItemWrapper key={visit.id}>
            <VisitCard onReloadVisits={onReload} visit={visit} />
          </ItemWrapper>
        );
      })}
    </div>
  );
};

const ItemWrapper = styled.div`
  margin-bottom: 20px;
`;
