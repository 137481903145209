import { useMutation, useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import React, { FC, useContext } from 'react';
import styled from 'styled-components/macro';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { ADateField } from 'app/components/deprecated/ADateField/ADateField';
import {
  AForm,
  TFormFieldInfo,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { AInputField } from 'app/components/deprecated/AInputField/AInputField';
import { APhoneField } from 'app/components/deprecated/APhoneField/APhoneField';
import { ASelectCenterField } from 'app/components/deprecated/ASelectCenterField/ASelectCenterField';
import { ASelectField } from 'app/components/deprecated/ASelectField/ASelectField';
import { ViewerContext } from 'app/contexts/ViewerContext';
import { theme } from 'app/styles/theme';
import {
  PapiRole,
  PapiUpdatePersonInput,
} from 'app/types/generated/globalTypes';
import {
  MemberUpdate_Mutation,
  MemberUpdate_MutationVariables,
} from 'app/types/generated/MemberUpdate_Mutation';
import {
  MemberUpdate_Query,
  MemberUpdate_QueryVariables,
} from 'app/types/generated/MemberUpdate_Query';
import { displayErrors } from 'app/utils/app';
import { compareLabel } from 'app/utils/sort';
import { digitsOnly } from 'app/utils/string';
import { SEXES, US_STATES } from 'constants/form';
import { STATUS_MESSAGE } from 'constants/message';

import { MEMBER_UPDATE_MUTATION, MEMBER_UPDATE_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  onClose?: () => void;
  onSave?: () => void;
  personID: string;
}

/** Form to update a member's basic info */
const MemberUpdate: FC<Props> = ({ onClose, onSave, personID }) => {
  const viewer = useContext(ViewerContext);

  const { data, loading: queryLoading } = useQuery<
    MemberUpdate_Query,
    MemberUpdate_QueryVariables
  >(MEMBER_UPDATE_QUERY, { variables: { personID } });
  const [updateMember, { loading: mutationLoading }] = useMutation<
    MemberUpdate_Mutation,
    MemberUpdate_MutationVariables
  >(MEMBER_UPDATE_MUTATION);

  if (queryLoading || !data || !data.member) {
    return (
      <LoadingContainer>
        <ALoading />
      </LoadingContainer>
    );
  }

  const { member } = data;
  const handleSave = async (
    input: PapiUpdatePersonInput,
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      const result = await updateMember({
        variables: {
          input: {
            ...input,
            dateOfBirth: DateTime.fromJSDate(
              ((input.dateOfBirth as unknown) as Moment).toDate()
            ).toISODate(),
            id: personID,
            phoneNumber: input.phoneNumber
              ? `+${digitsOnly(input.phoneNumber)}`
              : null,
          },
        },
      });
      if (result?.data) {
        message.success(STATUS_MESSAGE.memberUpdate.success);
        onSave?.();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.memberUpdate.error.general);
      shouldFinish(false);
    }
  };

  return (
    <AForm onClose={onClose} onSave={handleSave} saving={mutationLoading}>
      {(form) => (
        <>
          {/* Name */}
          <AFormFieldRow>
            <AInputField
              antdForm={form}
              customWidth={{ max: '200px', percent: 25 }}
              data-private
              disableAutoComplete
              initialValue={member.firstName}
              label={fields.firstName.label}
              name={fields.firstName.name}
              required
            />
            <AInputField
              antdForm={form}
              customWidth={{ max: '200px', percent: 15 }}
              data-private
              disableAutoComplete
              initialValue={member.middleName || undefined}
              label={fields.middleName.label}
              name={fields.middleName.name}
            />
            <AInputField
              antdForm={form}
              customWidth={{ max: '200px', percent: 25 }}
              data-private
              disableAutoComplete
              initialValue={member.lastName}
              label={fields.lastName.label}
              name={fields.lastName.name}
              required
            />
          </AFormFieldRow>

          {/* Sex, gender, dob */}
          <AFormFieldRow>
            <ASelectField
              antdForm={form}
              customWidth={{ fixed: '100px' }}
              initialValue={member.biologicalSex}
              label={fields.biologicalSex.label}
              name={fields.biologicalSex.name}
              required
              selectOptions={SEXES}
            />
            <AInputField
              antdForm={form}
              disableAutoComplete
              initialValue={member.genderIdentity || undefined}
              label={fields.genderIdentity.label}
              name={fields.genderIdentity.name}
            />
            <ADateField
              antdForm={form}
              initialValue={member.dateOfBirth || undefined}
              label={fields.dateOfBirth.label}
              name={fields.dateOfBirth.name}
              required
            />
          </AFormFieldRow>

          {/* Address */}
          <AFormFieldRow breakpoint="tabletPortrait">
            <AFormFieldRow>
              <AInputField
                antdForm={form}
                customWidth={{ max: '320px', percent: 80 }}
                data-private
                disableAutoComplete
                initialValue={member.addressLine1 || undefined}
                label={fields.addressLine1.label}
                name={fields.addressLine1.name}
              />
              <AInputField
                antdForm={form}
                customWidth={{ fixed: '80px' }}
                data-private
                disableAutoComplete
                initialValue={member.addressLine2 || undefined}
                label={fields.addressLine2.label}
                name={fields.addressLine2.name}
              />
            </AFormFieldRow>
            <AFormFieldRow>
              <AInputField
                antdForm={form}
                data-private
                disableAutoComplete
                initialValue={member.addressCity || undefined}
                label={fields.addressCity.label}
                name={fields.addressCity.name}
              />
              <ASelectField
                antdForm={form}
                customWidth={{ fixed: '160px' }}
                filterOption
                initialValue={member.addressState || undefined}
                label={fields.addressState.label}
                name={fields.addressState.name}
                optionFilterProp="children"
                selectOptions={US_STATES}
                showSearch
              />
              <AInputField
                antdForm={form}
                customWidth={{ fixed: '80px' }}
                data-private
                disableAutoComplete
                initialValue={member.addressPostalCode || undefined}
                label={fields.addressPostalCode.label}
                maxLength={5}
                minLength={5}
                name={fields.addressPostalCode.name}
                required={!!member.addressPostalCode}
              />
            </AFormFieldRow>
          </AFormFieldRow>

          {/* Contact info */}
          <AFormFieldRow>
            <APhoneField
              antdForm={form}
              initialValue={member.phoneNumber || undefined}
              label={fields.phoneNumber.label}
              name={fields.phoneNumber.name}
              required={!!member.phoneNumber}
            />
            <AInputField
              antdForm={form}
              customWidth={{ fixed: '300px' }}
              data-private
              disableAutoComplete
              email
              initialValue={member.email}
              label={fields.email.label}
              name={fields.email.name}
              required
            />
          </AFormFieldRow>

          {/* Center & authorization forms */}
          <AFormFieldRow>
            <ASelectCenterField
              antdForm={form}
              customWidth={{ fixed: '150px' }}
              initialValue={member.center ? member.center.id : undefined}
              label={fields.centerID.label}
              name={fields.centerID.name}
              required={!!member.center}
            />
            <AInputField
              antdForm={form}
              customWidth={{ fixed: '300px' }}
              data-private
              disableAutoComplete
              initialValue={member.enrolledBy}
              label={fields.enrolledBy.label}
              name={fields.enrolledBy.name}
            />
            {/* Admin only */}
            {(viewer.role || '').toLowerCase().includes('admin') && (
              <ASelectField
                antdForm={form}
                customWidth={{ fixed: '160px' }}
                initialValue={member.role.toUpperCase() || undefined}
                label={fields.role.label}
                name={fields.role.name}
                required
                selectOptions={Object.values(PapiRole)
                  .map((role) => ({
                    label: capitalize(role.replace('PARSLEY_', '')),
                    value: role,
                  }))
                  .sort(compareLabel)}
              />
            )}
          </AFormFieldRow>
        </>
      )}
    </AForm>
  );
};

// Styled components ////////////////////////////////
const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 150px;
  justify-content: flex-start;
  margin-left: 100px;
  ${theme.layout.breakpointMixin.phoneOnly} {
    justify-content: center;
    margin-left: 0;
  }
`;

/** Form field info */
const fields: Pick<
  TFormFieldInfo<PapiUpdatePersonInput>,
  | 'addressCity'
  | 'addressLine1'
  | 'addressLine2'
  | 'addressPostalCode'
  | 'addressState'
  | 'biologicalSex'
  | 'centerID'
  | 'dateOfBirth'
  | 'email'
  | 'firstName'
  | 'genderIdentity'
  | 'lastName'
  | 'middleName'
  | 'newMDHQID'
  | 'phoneNumber'
  | 'role'
  | 'enrolledBy'
> = {
  addressCity: {
    label: 'City',
    name: 'addressCity',
  },
  addressLine1: {
    label: 'Address',
    name: 'addressLine1',
  },
  addressLine2: {
    label: 'Line 2',
    name: 'addressLine2',
  },
  addressPostalCode: {
    label: 'Zipcode',
    name: 'addressPostalCode',
  },
  addressState: {
    label: 'State',
    name: 'addressState',
  },
  biologicalSex: {
    label: 'Sex',
    name: 'biologicalSex',
  },
  centerID: {
    label: 'Center',
    name: 'centerID',
  },
  dateOfBirth: {
    label: 'Date of birth',
    name: 'dateOfBirth',
  },
  email: {
    label: 'Email',
    name: 'email',
  },
  enrolledBy: {
    label: 'Enrolled by',
    name: 'enrolledBy',
  },
  firstName: {
    label: 'First name',
    name: 'firstName',
  },
  genderIdentity: {
    label: 'Gender',
    name: 'genderIdentity',
  },
  lastName: {
    label: 'Last name',
    name: 'lastName',
  },
  middleName: {
    label: 'Middle name',
    name: 'middleName',
  },
  newMDHQID: {
    label: 'New MDHQ ID',
    name: 'newMDHQID',
  },
  phoneNumber: {
    label: 'Phone',
    name: 'phoneNumber',
  },
  role: {
    label: 'Auth role',
    name: 'role',
  },
};

export { fields, MemberUpdate };
