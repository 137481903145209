import gql from 'graphql-tag';

import { PlanCreateUpdate_Fragment_updatePlan } from 'app/components/organisms/PlanCreateUpdate/fragment';

export const PlanDetails_Fragment_plan = gql`
  fragment PlanDetails_Fragment_plan on PapiPlan {
    id
    billingPeriod
    commitmentLength
    defaultFollowOnPlan {
      id
      displayName
    }
    deprecatedAt
    displayName
    joinCentersAllowed {
      id
      abbreviatedName
      isVirtual
    }
    maxPersonAgeYears
    minPersonAgeYears
    numberOfBillingCycles
    paymentRateInCents
    product {
      displayName
    }
    ...PlanCreateUpdate_Fragment_updatePlan
  }
  ${PlanCreateUpdate_Fragment_updatePlan}
`;
