import { useQuery } from '@apollo/react-hooks';
import { Divider, Skeleton } from 'antd';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import React, { FC, useContext, useMemo } from 'react';
import styled from 'styled-components/macro';

import { AFlexbox } from 'app/components/atoms/AFlexbox/AFlexbox';
import AIconCareManager from 'app/components/atoms/AIconCareManager/AIconCareManager';
import AIconDoctor from 'app/components/atoms/AIconDoctor/AIconDoctor';
import AIconHealthCoach from 'app/components/atoms/AIconHealthCoach/AIconHealthCoach';
import { AIconProviderNewMembers } from 'app/components/atoms/AIconProviderNewMembers/AIconProviderNewMembers';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { getCareManagerLabel } from 'app/components/atoms/ASelectCareManager/ASelectCareManager';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { ProviderPageCard } from 'app/components/atoms/ProviderPageCard/ProviderPageCard';
import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { theme } from 'app/styles/theme';
import { PapiProviderType } from 'app/types/generated/globalTypes';
import {
  ProviderBasics_Query,
  ProviderBasics_QueryVariables,
} from 'app/types/generated/ProviderBasics_Query';
import { getCenterDisplayName } from 'app/utils/center';
import { getPayerCenterDisplayName } from 'app/utils/payerCenter';
import {
  formatProfessionalSuffix,
  formatProviderRole,
} from 'app/utils/provider';
import { compareLabel } from 'app/utils/sort';

import { PROVIDER_BASICS_QUERY } from './query';
import { DEFAULT_MAX_HOURS, DEFAULT_DAYS_PER_WEEK } from 'constants/provider';

// Types & constants ////////////////////////////////
interface Props {
  providerID: string;
}

/** Displays provider's info */
const ProviderBasics: FC<Props> = ({ providerID }) => {
  const featureFlags = useContext(FeatureFlagContext);
  const showInsuranceCreds = featureFlags.variation(
    'dr-p-provider-insurance-credentials'
  );
  const { data, loading } = useQuery<
    ProviderBasics_Query,
    ProviderBasics_QueryVariables
  >(PROVIDER_BASICS_QUERY, { variables: { providerID } });

  const provider = data?.provider;

  const providerIcon = useMemo(() => {
    if (provider && provider.sanityProfile && provider.sanityProfile.imageSrc) {
      return <img alt="Provider" src={provider.sanityProfile.imageSrc} />;
    }
    return provider?.type === PapiProviderType.DOCTOR ? (
      <AIconDoctor />
    ) : (
      <AIconHealthCoach />
    );
  }, [provider]);

  const cmIcon = useMemo(() => {
    if (provider?.assignedCareManager?.sanityProfile?.imageSrc) {
      return (
        <img
          alt="Care Manager"
          src={provider?.assignedCareManager?.sanityProfile?.imageSrc}
        />
      );
    }
    return <AIconCareManager />;
  }, [provider]);

  if (loading || !data || !data.provider) {
    return (
      <>
        <SkeletonStyled
          active
          paragraph={{
            rows: 4,
            width: ['30%', '50%', '20%', '70%'],
          }}
          title={false}
        />
        <SkeletonStyled
          active
          paragraph={{
            rows: 4,
            width: ['40%', '50%', '40%', '60%'],
          }}
          title={false}
        />
      </>
    );
  }

  const {
    centers: allCenters,
    payers: allPayers,
    provider: {
      acuityID,
      acuityInstance,
      assignedCareManager,
      biologicalSex,
      centers,
      email,
      firstName,
      helpScoutID,
      lastName,
      middleName,
      payerCredentials,
      pod,
      professionalSuffix,
      sanityID,
      timezone,
      type,
      maxHours,
      daysPerWeek,
    },
  } = data;

  return (
    <>
      <CardsContainer>
        <ProviderPageCard
          isReadOnly
          onAssignProviderClick={() => {}}
          onRemoveProviderClick={() => {}}
          pod={pod!}
          providerDisplayName={
            <>
              <div>
                {capitalize(type).replace(/_/g, ' ')}
                <Divider type="vertical" />
                {biologicalSex && capitalize(biologicalSex)}
              </div>
              <div>{email}</div>
            </>
          }
          providerIcon={providerIcon}
          title={`${firstName ?? ''} ${middleName ?? ''} ${lastName ?? ''} ${
            professionalSuffix
              ? `, ${formatProfessionalSuffix(professionalSuffix)}`
              : ''
          }`}
        />
        {assignedCareManager ? (
          <ProviderPageCard
            isReadOnly
            onAssignProviderClick={() => {}}
            onRemoveProviderClick={() => {}}
            providerDisplayName={
              <>
                <div>{formatProviderRole(assignedCareManager.type)}</div>
                <div>{assignedCareManager.email}</div>
              </>
            }
            providerIcon={cmIcon}
            title={getCareManagerLabel(assignedCareManager)}
          />
        ) : null}
      </CardsContainer>

      <ASectionBtmMargin>
        <ALabel>Centers</ALabel>
        <AFlexbox>
          {centers
            .map((providerCenter) => ({
              ...providerCenter,
              label: getCenterDisplayName(providerCenter.center),
            }))
            .sort(compareLabel)
            .map((center, i) => (
              <Center key={center.center.id}>
                {center.label}
                {!center.takingNewPatients && (
                  <AIconProviderNewMembers
                    margin="left"
                    takingNewMembers={false}
                  />
                )}
                {i < centers.length - 1 && ', '}
              </Center>
            ))}
        </AFlexbox>
      </ASectionBtmMargin>

      {showInsuranceCreds && (
        <ASectionBtmMargin>
          <ALabel>Insurance Credentials</ALabel>
          <InsuranceCredentials>
            {payerCredentials
              .map((providerPayerCred) => ({
                ...providerPayerCred,
                label: getPayerCenterDisplayName(
                  allCenters,
                  allPayers,
                  providerPayerCred.centerID,
                  providerPayerCred.payerID
                ),
              }))
              .map((center, i) => (
                <div key={`${center.payerID}-${center.centerID}`}>
                  {center.label}
                  {i < centers.length - 1 && <br />}
                </div>
              ))}
          </InsuranceCredentials>
        </ASectionBtmMargin>
      )}

      <ASectionBtmMargin>
        <div>
          <ALabel inline>Acuity center</ALabel>
          {acuityInstance.replace('ACUITY_', '')}
          <Divider type="vertical" />
          {acuityID ? (
            <>
              <ALabel inline>ID</ALabel>
              {acuityID}
            </>
          ) : (
            <ATextLight>No ID</ATextLight>
          )}
        </div>
        <div>
          <ALabel inline>Timezone</ALabel>
          {DateTime.fromObject({ zone: timezone }).toFormat('ZZZZ (ZZ) ZZZZZ')}
        </div>
      </ASectionBtmMargin>

      <ASectionBtmMargin>
        <div>
          {helpScoutID ? (
            <>
              <ALabel inline>HelpScout ID</ALabel>
              {helpScoutID}
            </>
          ) : (
            <ATextLight lighter>No HelpScout ID</ATextLight>
          )}
        </div>
        <div>
          {sanityID ? (
            <>
              <ALabel inline>Sanity ID</ALabel>
              {sanityID}
            </>
          ) : (
            <ATextLight lighter>No Sanity ID</ATextLight>
          )}
        </div>
      </ASectionBtmMargin>
      <ASectionBtmMargin>
        <div>
          <>
            <ALabel inline>Working Days per Week</ALabel>
            {daysPerWeek !== ''
              ? daysPerWeek
              : `USING DEFAULT: ${DEFAULT_DAYS_PER_WEEK}`}
          </>
        </div>
        <div>
          <>
            <ALabel inline>Max Working Hours per Week</ALabel>
            {maxHours !== ''
              ? maxHours
              : `USING DEFAULT: ${DEFAULT_MAX_HOURS}`}
          </>
        </div>
      </ASectionBtmMargin>
    </>
  );
};

// Styled components ////////////////////////////////
const Center = styled(AFlexbox)`
  &&& {
    margin-right: ${theme.space.xs};
  }
`;

const InsuranceCredentials = styled.div`
  &&& {
    margin-right: ${theme.space.xs};
  }
  align-items: start;
  flex-flow: column nowrap;
`;

const SkeletonStyled = styled(Skeleton)`
  &&& {
    margin: ${theme.space.s} 0 ${theme.space.xl} 0;
    :last-of-type {
      margin-bottom: ${theme.space.s};
    }
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export { ProviderBasics };
