import gql from 'graphql-tag';

export const VisitDetails_Fragment_visit = gql`
  fragment VisitDetails_Fragment_visit on PapiScheduledAppointment {
    id
    categoryDetails {
      category
      color
      name
    }
    center {
      id
      name
    }
    endTime
    format
    isComplimentary
    location
    member: person {
      id
      currentPlan {
        id
        extensionOf {
          id
          plan {
            id
            product {
              displayName
            }
          }
        }
        nextRenewalDate
        plan {
          id
          product {
            displayName
          }
        }
      }
      email
      joinedPracticeDate
      phoneNumber
    }
    metadata
    startTime
  }
`;
