import { Button, Select, Tooltip, Typography } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { PdfViewer } from 'app/components/arabella/PdfViewer/PdfViewer';
import { SearchSelect } from 'app/components/arabella/SearchSelect/SearchSelect';
import { ArabellaMemberType } from 'app/components/arabella/utils/types';
import { AIconLabeled } from 'app/components/atoms/AIconLabeled/AIconLabeled';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
const { Text, Title } = Typography;
const { Option } = Select;

export interface DocumentReviewDetailsProps {
  actionSection?: React.ReactNode;
  hideMemberInfo?: boolean;
  isMemberChangeable?: boolean;
  isMemberDisconnect?: boolean;
  isSaved?: boolean;
  isSaving?: boolean;
  member?: ArabellaMemberType;
  memberSearchResults?: ArabellaMemberType[] | [];
  onLoadPdfError?: (error: any) => void;
  onLoadPdfSuccess?: (success: any) => void;
  onMdhqClick?: () => void;
  onMemberDisconnectedFromSearch?: () => void;
  onMemberSelectedFromSearchResults: (memberId: any) => void;
  onReloadPdf?: () => void;
  onSearchCanceled: () => void;
  onSearchPhraseProvided: (val: string) => void;
  pdfUrl: string;
  uploadTypeIcon: string;
  uploadTypeLabel: string;
}

/** Display detailed view for Document Review, including interactive pdf */
const DocumentReviewDetails: FC<DocumentReviewDetailsProps> = ({
  actionSection,
  hideMemberInfo,
  isMemberChangeable = true,
  isMemberDisconnect = true,
  isSaved = false,
  isSaving = false,
  member,
  memberSearchResults,
  onLoadPdfError,
  onLoadPdfSuccess,
  onMdhqClick,
  onMemberDisconnectedFromSearch,
  onMemberSelectedFromSearchResults,
  onReloadPdf,
  onSearchCanceled,
  onSearchPhraseProvided,
  pdfUrl,
  uploadTypeIcon,
  uploadTypeLabel,
}) => {
  const [toggleWrongMember, setToggleWrongMember] = useState<boolean>(false);

  const handleWrongMemberClick = (): void => {
    const isWrongMember = !toggleWrongMember;

    setToggleWrongMember(isWrongMember);

    if (!isWrongMember) onSearchCanceled();
  };

  const handleMemberChange = (memberId: SelectValue): void => {
    setToggleWrongMember(false);
    onMemberSelectedFromSearchResults(memberId);
  };

  const handleDisconnectMember = (): void => {
    setToggleWrongMember(false);
    if (onMemberDisconnectedFromSearch) onMemberDisconnectedFromSearch();
  };

  return (
    <StyledContainer>
      {!hideMemberInfo && (
        <>
          <StyledDocsHeader>
            <StyledDocsHeaderItem
              hiddenItems={toggleWrongMember}
              isMember={Boolean(member)}
            >
              {member ? (
                <>
                  {' '}
                  <Title level={2}>{member.fullName}</Title>
                  <Text>{member.birthDay}</Text>
                </>
              ) : (
                <>
                  <Title level={2}>No member</Title>
                </>
              )}

              {toggleWrongMember && (
                <StyledMemberSearch
                  autoFocus
                  dropdownClassName="searchResults"
                  onChange={handleMemberChange}
                  onSearchPhraseProvide={onSearchPhraseProvided}
                  placeholder="Search a member"
                  renderNoData={
                    isMemberDisconnect ? (
                      <StyledNoMemberDisconnectOption
                        onClick={handleDisconnectMember}
                      >
                        Disconnect member
                      </StyledNoMemberDisconnectOption>
                    ) : null
                  }
                  renderOptions={(value) => (
                    <Option key={value.id} value={value.id}>
                      {value.fullName} ({value.birthDay})
                    </Option>
                  )}
                  searchResults={memberSearchResults}
                />
              )}
            </StyledDocsHeaderItem>
            {!isSaved && !isSaving && isMemberChangeable && (
              <StyledMemberButton icon="edit" onClick={handleWrongMemberClick}>
                {toggleWrongMember
                  ? 'Cancel'
                  : member
                  ? 'Change Member'
                  : 'Select Member'}
              </StyledMemberButton>
            )}
          </StyledDocsHeader>

          <StyledInboxInfo>
            <StyledSummaryItem>
              <AIconLabeled label={uploadTypeLabel} type={uploadTypeIcon} />

              {member && (
                <>
                  <AIconLabeled
                    label={`${member.product} (${member.center})`}
                    type="safety-certificate"
                  />
                  {Boolean(member.dateJoined) && (
                    <span>joined {member.dateJoined}</span>
                  )}

                  <StyledLink
                    onClick={() => {
                      if (onMdhqClick) onMdhqClick();
                    }}
                  >
                    <AIconLabeled label="MDHQ" type="contacts" />
                  </StyledLink>
                </>
              )}
            </StyledSummaryItem>

            {member?.provider && (
              <Tooltip placement="topLeft" title="Based on last visit">
                <div>
                  <AIconLabeled
                    fontSize="1.1em"
                    label={member.provider.fullName}
                    type="heart"
                  />
                </div>
              </Tooltip>
            )}
          </StyledInboxInfo>
        </>
      )}

      <StyledPdfContainer>
        <PdfViewer
          onLoadError={onLoadPdfError}
          onLoadSuccess={onLoadPdfSuccess}
          onReload={onReloadPdf}
          url={pdfUrl}
        />
      </StyledPdfContainer>

      {Boolean(actionSection) && actionSection}
    </StyledContainer>
  );
};

// Styled components ////////////////////////////////
const StyledContainer = styled.div`
  border: 1px solid ${theme.color.border};
  box-shadow: ${theme.layer.boxShadow.prepCard};
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-left: ${theme.space.l};
  padding: ${theme.space.l};
`;

const StyledItem = styled.div`
  padding-bottom: ${theme.space.sm};
  padding-top: ${theme.space.sm};
`;

const StyledDocsHeader = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
`;

const StyledDocsHeaderItem = styled.div<{
  hiddenItems: boolean;
  isMember: boolean;
}>`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  height: 42px;
  min-width: 362px;
  position: relative;

  & > h2,
  & > span,
  & > div {
    opacity: ${({ hiddenItems }) => (hiddenItems ? '0' : '1')};
  }

  &&& {
    h2 {
      color: ${({ isMember }) =>
        isMember ? 'inherit' : theme.color.middleGrey};
      margin: 0;
      padding: 0;
    }

    & > h2,
    & > span,
    & > div {
      margin-right: ${theme.space.m};
    }
  }
`;

const StyledMemberSearch = styled(SearchSelect)`
  &&& {
    left: 0;
    opacity: 1;
    padding-right: ${theme.space.m};
    position: absolute;
    top: 6px;
    width: 100%;
  }
`;

const StyledNoMemberOption = styled.div`
  padding: ${theme.space.s} ${theme.space.m};
`;

const StyledNoMemberDisconnectOption = styled(StyledNoMemberOption)`
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background-color: ${theme.color.aquaSqueeze};
  }
`;

const StyledMemberButton = styled(Button)`
  position: relative;
  top: -4px;
`;

const StyledInboxInfo = styled(StyledItem)`
  align-items: center;
  border-bottom: 1px solid ${theme.color.border};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 44px;
  & > div {
    display: flex;
    flex-direction: row;
  }
`;

const StyledLink = styled.div`
  color: ${theme.color.jade};
  cursor: pointer;
  font-weight: ${theme.font.weight.semibold};

  &:hover {
    text-decoration: underline;
  }
`;

const StyledPdfContainer = styled(StyledItem)`
  display: flex;
  flex: auto;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  position: relative;
  width: 100%;
`;

const StyledSummaryItem = styled.div`
  & > div,
  & > span {
    margin-right: ${theme.space.sm};
  }
`;

export { DocumentReviewDetails };
