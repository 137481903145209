import gql from 'graphql-tag';

import { InvoiceUpdateStatus_Fragment_invoice } from 'app/components/organisms/InvoiceUpdateStatus/fragment';

export const MEMBER_INVOICES_TABLE_QUERY = gql`
  query MemberInvoicesTable_Query($personID: ID!) {
    invoices: listInvoicesForPerson(personID: $personID) {
      id
      createdAt
      currency
      description
      hostedInvoiceURL
      isLegacy
      latestCharge {
        id
        amountRefunded
        failureCode
        refunded
      }
      number
      periodStart
      status
      total
      ...InvoiceUpdateStatus_Fragment_invoice
    }
  }
  ${InvoiceUpdateStatus_Fragment_invoice}
`;
