import { Card } from 'antd';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { theme } from 'app/styles/theme';
import {
  MemberProviders_Query_member_assignedDoctor_assignedCareManager_pod as Pod,
  MemberProviders_Query_member_assignedCoach_pod,
} from 'app/types/generated/MemberProviders_Query';

type ProviderPageCardProps = {
  hasCareManager?: boolean;
  isMemberAdvocate?: boolean;
  isReadOnly?: boolean;
  onAssignProviderClick: VoidFunction;
  onRemoveProviderClick: VoidFunction;
  pod?: Pod | MemberProviders_Query_member_assignedCoach_pod;
  providerDisplayName?: React.ReactNode | null;
  providerIcon: React.ReactNode;
  secondaryProviderIcon?: React.ReactNode;
  subTitle?: React.ReactNode;
  title?: string;
};

export const ProviderPageCard: FC<ProviderPageCardProps> = ({
  hasCareManager,
  isMemberAdvocate,
  isReadOnly,
  onAssignProviderClick,
  onRemoveProviderClick,
  pod,
  providerDisplayName,
  providerIcon,
  secondaryProviderIcon,
  subTitle,
  title,
}) => {
  return (
    <CardStyled>
      <CardContent>
        <div>
          {pod && !isMemberAdvocate && <PodLabel>{pod.name}</PodLabel>}
          <Title isMemberAdvocate={isMemberAdvocate}>{title}</Title>
          <RowContainer>
            <DisplayName>
              {providerDisplayName ? providerDisplayName : 'None assigned'}
            </DisplayName>
            {isReadOnly ? null : (
              <>
                <StyledLink onClick={onAssignProviderClick}>
                  {providerDisplayName ? 'Reassign' : 'Assign'}
                </StyledLink>
                {providerDisplayName && (
                  <>
                    <StyledSpan>|</StyledSpan>
                    <StyledLink onClick={onRemoveProviderClick}>
                      Remove
                    </StyledLink>
                  </>
                )}
              </>
            )}
          </RowContainer>
        </div>
        <AvatarsContainer>
          {hasCareManager && secondaryProviderIcon ? (
            <SecondAvatarContainer>
              <Avatar size={40}>
                <Wrapper>{secondaryProviderIcon}</Wrapper>
              </Avatar>
            </SecondAvatarContainer>
          ) : null}
          <FirstAvatarContainer hasCareManager={hasCareManager}>
            <Avatar size={40}>
              <Wrapper>{providerIcon}</Wrapper>
            </Avatar>
          </FirstAvatarContainer>
        </AvatarsContainer>
      </CardContent>
      {hasCareManager ? <CareManagerLabel>{subTitle}</CareManagerLabel> : null}
    </CardStyled>
  );
};

const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardStyled = styled(Card)`
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-sizing: border-box;
  flex: 0 0 425px;
  font-weight: 500;
  margin: 0 ${theme.space.l} ${theme.space.l} 0;
  max-width: 425px;
  padding: 0;
`;

const AvatarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const FirstAvatarContainer = styled.div<{ hasCareManager?: boolean }>`
  ${({ hasCareManager }) =>
    hasCareManager
      ? css`
          position: relative;
          right: 20%;
        `
      : ''}
  z-index: 10;
`;

const SecondAvatarContainer = styled.div`
  z-index: 9;
`;

const CareManagerLabel = styled.span`
  color: #808080;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

const Title = styled.div<{
  isMemberAdvocate?: boolean;
}>`
  font-size: ${theme.font.size.l};
  margin-bottom: 5px;
  ${({ isMemberAdvocate }) => isMemberAdvocate && `margin-top: 26px`}
`;

const RowContainer = styled.div`
  align-items: end;
  display: flex;
  font-size: ${theme.font.size.l};
  font-weight: ${theme.font.weight.base};
  justify-content: space-between;
  width: max-content;
`;

const DisplayName = styled.div`
  margin-right: ${theme.space.s};
`;

const StyledLink = styled.div`
  align-items: center;
  color: ${theme.color.jade};
  cursor: pointer;
  display: flex;
  font-size: ${theme.font.size.m};
  font-weight: ${theme.font.weight.medium};
  text-decoration: underline;
`;

const StyledSpan = styled.div`
  color: ${theme.color.jade};
  margin: 0 ${theme.space.s};
`;

const Avatar = styled.div<{ size: number | undefined }>`
  height: ${({ size }) => (size ? size + 'px' : 'auto')};
  position: relative;
  width: ${({ size }) => (size ? size + 'px' : 'auto')};
`;

const Wrapper = styled.div`
  align-items: center;
  background: 26px;
  border-radius: 100%;
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const PodLabel = styled.span`
  color: ${theme.color.aquaGreen};
  display: block;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.08em;
  line-height: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
`;
