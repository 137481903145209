import React, { FC, useState } from 'react';

import { AButton } from 'app/components/atoms/AButton/AButton';
import { ADrawer } from 'app/components/atoms/ADrawer/ADrawer';
import { ADrawerContainer } from 'app/components/atoms/ADrawerContainer/ADrawerContainer';
import { APadding } from 'app/components/atoms/APadding/APadding';
import { ProviderCreate } from 'app/components/organisms/ProviderCreate/ProviderCreate';
import { ProvidersTable } from 'app/components/organisms/ProvidersTable/ProvidersTable';
import { PROVIDERS_TABLE_QUERY } from 'app/components/organisms/ProvidersTable/query';
import { theme } from 'app/styles/theme';
import { ProvidersTable_Query } from 'app/types/generated/ProvidersTable_Query';

const isPhone = window.innerWidth < theme.layout.breakpoint.tabletPortrait;

/** Providers page with table of providers */
const ProvidersPage: FC = () => {
  const [isCreateFormOpen, setCreateFormOpen] = useState(false);

  return (
    <ADrawerContainer showDrawer={isCreateFormOpen || undefined}>
      <APadding type="page">
        <ProvidersTable
          toolbarExtension={
            <AButton
              iconType="plus"
              onClick={() => setCreateFormOpen(true)}
              type="primary"
            >
              {!isPhone && 'Add provider'}
            </AButton>
          }
        />
      </APadding>

      <ADrawer
        ignoreLayoutContext
        onClose={() => setCreateFormOpen(false)}
        title={isCreateFormOpen && 'New provider'}
        visible={isCreateFormOpen}
      >
        <APadding type="drawer">
          <ProviderCreate
            mutationCacheUpdater={(cache, mutationResult) => {
              const mutationData = mutationResult.data;
              if (!mutationData || !mutationData.addProvider) {
                return;
              }
              const cacheData = cache.readQuery<ProvidersTable_Query>({
                query: PROVIDERS_TABLE_QUERY,
              });
              cache.writeQuery({
                data: {
                  providers: [
                    ...(cacheData ? cacheData.providers : []),
                    mutationData.addProvider,
                  ],
                },
                query: PROVIDERS_TABLE_QUERY,
              });
            }}
            onCancel={() => setCreateFormOpen(false)}
          />
        </APadding>
      </ADrawer>
    </ADrawerContainer>
  );
};

export { ProvidersPage };
