import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React, { FC, ReactNode, useEffect } from 'react';
import {
  FieldError,
  FormContextValues,
  ValidationOptions,
} from 'react-hook-form';
import styled from 'styled-components/macro';

import { AFormFieldError } from 'app/components/atoms/AFormFieldError/AFormFieldError';
import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
export interface IASelectOption {
  disabled?: boolean;
  label: ReactNode;
  searchIndex?: string; // search will filter this field instead of label
  secondaryText?: string;
  value: any;
}

export interface ASelectProps extends SelectProps, AFormFieldWrapperProps {
  /** @param collapseError doesn't render space for error until there's an error */
  collapseError?: boolean;
  /** @param formHook react-hook-form useForm() */
  formHook?: FormContextValues<any>;
  label?: string;
  labelInfo?: string;
  name?: string;
  onBlur?: () => void;
  onChange?: (value: any) => void;
  /** @param rules with react-hook-form only */
  rules?: ValidationOptions;
  searchable?: boolean;
  selectOptions: Array<
    IASelectOption | { groupLabel: string; options: IASelectOption[] }
  >;
}

/** Select field that can be used alone or with react-hook-form */
const ASelect: FC<ASelectProps> = ({
  collapseError,
  customWidth,
  defaultValue,
  formHook,
  label,
  labelInfo,
  name,
  onBlur,
  onChange,
  rules,
  searchable,
  selectOptions,
  show,
  ...props
}) => {
  useEffect(() => {
    // Register form field & set default value once
    if (formHook && name) {
      formHook.register({ name }, rules);
      formHook.setValue(name, defaultValue);
      return () => formHook.unregister(name);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const errorMessage =
    name &&
    formHook?.errors[name] &&
    (formHook.errors[name] as FieldError).message;

  return (
    <AFormFieldWrapper customWidth={customWidth} show={show}>
      {label && (
        <ALabel
          black
          help={labelInfo}
          htmlFor={name}
          required={!!rules?.required}
        >
          {label}
        </ALabel>
      )}

      <SelectWrapper error={!!errorMessage}>
        <Select
          allowClear={!rules?.required}
          defaultValue={defaultValue}
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          id={name}
          onBlur={() => {
            if (formHook && name) {
              formHook.triggerValidation(name);
            }
            onBlur && onBlur();
          }}
          onChange={(value: any) => {
            if (formHook && name) {
              formHook.setValue(name, value);
              formHook.triggerValidation(name);
            }
            onChange && onChange(value);
          }}
          optionFilterProp="title"
          placeholder="Select"
          showSearch={searchable}
          {...props}
        >
          {selectOptions.map((optionOrGroup) =>
            'groupLabel' in optionOrGroup ? (
              <Select.OptGroup
                key={`ASelect_${optionOrGroup.groupLabel}`}
                label={optionOrGroup.groupLabel}
              >
                {optionOrGroup.options.map(renderSelectOption)}
              </Select.OptGroup>
            ) : (
              renderSelectOption(optionOrGroup)
            )
          )}
        </Select>
      </SelectWrapper>

      {(!collapseError || errorMessage) && (
        <AFormFieldError show={!!errorMessage}>{errorMessage}</AFormFieldError>
      )}
    </AFormFieldWrapper>
  );
};

// Styled components ////////////////////////////////
const SelectOptionStyled = styled(Select.Option)`
  &&& {
    max-width: 300px;
  }
`;

const SelectOptionContainer = styled.span`
  &&& {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 300px;
  }
`;

const SelectOptionMainText = styled.span``;

const SelectOptionSecondaryText = styled.span`
  color: ${theme.color.aquaGreen};
  font-weight: bold;
  margin: 0 5px;
`;

const SelectWrapper = styled.div<{ error: boolean }>`
  div[role='combobox']:not([disabled]) {
    border-color: ${({ error }) =>
      error ? theme.color.error : theme.color.border};
  }

  div[role='combobox']:not([disabled]):active,
  div[role='combobox']:not([disabled]):focus,
  div[role='combobox']:not([disabled]):hover {
    border-color: ${({ error }) =>
      error ? theme.color.error : theme.color.primary} !important;
    box-shadow: ${({ error }) =>
      error
        ? `${theme.layer.boxShadow.all(theme.color.error + '40')}`
        : ''} !important;
  }

  .ant-select {
    width: 100%;
  }
`;

// Helpers ////////////////////////////////
const renderSelectOption = ({
  disabled,
  label,
  searchIndex,
  secondaryText,
  value,
}: IASelectOption): ReactNode => (
  <SelectOptionStyled
    disabled={disabled}
    key={value}
    title={searchIndex || (typeof label === 'string' && label) || undefined}
    value={value}
  >
    <SelectOptionContainer>
      <SelectOptionMainText>{label} </SelectOptionMainText>{' '}
      <SelectOptionSecondaryText> {secondaryText}</SelectOptionSecondaryText>
    </SelectOptionContainer>
  </SelectOptionStyled>
);

export { ASelect };
