import gql from 'graphql-tag';

export const A_SELECT_CENTER_QUERY = gql`
  query ASelectCenter_Query {
    centers: listCenters {
      id
      abbreviatedName
      city
      isVirtual
      state
    }
  }
`;
