import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import compact from 'lodash/compact';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { ATextClickable } from 'app/components/atoms/ATextClickable/ATextClickable';
import { ADateField } from 'app/components/deprecated/ADateField/ADateField';
import {
  AForm,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { theme } from 'app/styles/theme';
import { PapiBillingPeriod } from 'app/types/generated/globalTypes';
import { PlanPurchasePause_Fragment_planPurchase } from 'app/types/generated/PlanPurchasePause_Fragment_planPurchase';
import {
  PlanPurchasePause_Mutation,
  PlanPurchasePause_MutationVariables,
} from 'app/types/generated/PlanPurchasePause_Mutation';
import { displayErrors } from 'app/utils/app';
import { getPlanPurchaseStatus, isCurrentPlan } from 'app/utils/plan';
import { DateFormat } from 'constants/datetime';
import { STATUS_MESSAGE } from 'constants/message';

import {
  PLAN_PURCHASE_PAUSE_MUTATION,
  PLAN_PURCHASE_PAUSE_REFETCH,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  planPurchase: PlanPurchasePause_Fragment_planPurchase;
}

const invalidBillingPeriods = [PapiBillingPeriod.DAY];

/** Form to pause a member's care plan by selecting a pause end date */
const PlanPurchasePause: FC<Props> = ({ planPurchase }) => {
  const isPaused = getPlanPurchaseStatus(planPurchase) === 'Paused';
  const hasUpcomingPause = planPurchase.latestPause
    ? DateTime.fromISO(planPurchase.latestPause.startDate) > DateTime.local()
    : false;

  const [showCancel, setShowCancel] = useState(false);
  const [pausePlanPurchase, { loading: mutationLoading }] = useMutation<
    PlanPurchasePause_Mutation,
    PlanPurchasePause_MutationVariables
  >(PLAN_PURCHASE_PAUSE_MUTATION, {
    refetchQueries: [
      {
        query: PLAN_PURCHASE_PAUSE_REFETCH,
        variables: { personID: planPurchase.member.id },
      },
    ],
  });

  const onSave = async (
    input: { [K in keyof typeof fields]: any },
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    const endDate = showCancel
      ? DateTime.fromObject({ zone: planPurchase.center.timezone })
      : DateTime.fromJSDate((input.endDate as Moment).toDate());
    const diffToEndDate = DateTime.fromObject({
      day: endDate.day,
      month: endDate.month,
      year: endDate.year,
      zone: planPurchase.center.timezone,
    })
      .startOf('day')
      .diff(
        // If a pause is being created, the difference in days should be
        // calculated from the start date (midnight tonight). If the plan is
        // already paused or the pause is being canceled, the diff should be
        // calculated from right now.
        DateTime.fromObject({ zone: planPurchase.center.timezone })
          .plus({ days: showCancel || isPaused ? 0 : 1 })
          .startOf('day'),
        'days'
      );

    try {
      const result = await pausePlanPurchase({
        variables: {
          input: {
            daysToPause: diffToEndDate.days,
            purchaseID: planPurchase.id,
            startTime:
              showCancel || isPaused
                ? undefined
                : DateTime.fromObject({
                    zone: planPurchase.center.timezone,
                  })
                    .plus({ days: 1 })
                    .startOf('day')
                    .toISO(),
          },
        },
      });
      if (result?.data) {
        message.success(
          showCancel
            ? STATUS_MESSAGE.planPurchasePause.successCancel
            : isPaused
            ? STATUS_MESSAGE.planPurchasePause.successUpdate
            : STATUS_MESSAGE.planPurchasePause.success
        );
        setShowCancel(false);
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(
        err,
        showCancel
          ? STATUS_MESSAGE.planPurchasePause.error.generalCancel
          : isPaused
          ? STATUS_MESSAGE.planPurchasePause.error.generalUpdate
          : STATUS_MESSAGE.planPurchasePause.error.general
      );
      shouldFinish(false);
    }
  };

  return (
    <AForm
      cancelText={showCancel ? 'Nevermind' : undefined}
      disableOpen={
        !isCurrentPlan(planPurchase)
          ? { message: STATUS_MESSAGE.planPurchasePause.error.notCurrentPlan }
          : planPurchase.plan.product.type.includes('WAITLIST')
          ? { message: STATUS_MESSAGE.planPurchasePause.error.waitlist }
          : invalidBillingPeriods.includes(planPurchase.plan.billingPeriod)
          ? {
              message:
                STATUS_MESSAGE.planPurchasePause.error.invalidBillingPeriod,
            }
          : getPlanPurchaseStatus(planPurchase) === 'On extension'
          ? { message: STATUS_MESSAGE.planPurchasePause.error.onExtension }
          : undefined
      }
      onClose={() => showCancel && setShowCancel(false)}
      onSave={onSave}
      openAs="modal"
      openText={isPaused || hasUpcomingPause ? 'Edit pause' : 'Pause care plan'}
      saveText={showCancel ? 'Confirm' : !isPaused ? 'Pause' : undefined}
      saving={mutationLoading}
      savingText={showCancel ? 'Confirming' : !isPaused ? 'Pausing' : undefined}
      title={
        showCancel
          ? 'Cancel pause'
          : `Pause ${planPurchase.plan.product.displayName}`
      }
    >
      {(form) => {
        if (showCancel) {
          return (
            <>
              {isPaused
                ? `Canceling will end the pause within the hour, and billing will
              resume within the hour. Are you sure you want to cancel the pause?`
                : hasUpcomingPause &&
                  planPurchase.plan.billingPeriod === PapiBillingPeriod.MONTH
                ? `It is currently not possible to cancel a pause before it starts. Instead, when the pause starts on ${DateTime.fromISO(
                    planPurchase.latestPause!.startDate,
                    { zone: planPurchase.center.timezone }
                  ).toLocaleString(
                    DateTime.DATETIME_FULL
                  )}, the member will receive a refund for the remainder of the month, and be billed for a new month immediately. This will essentially reset the member's billing cycle. ${
                    STATUS_MESSAGE.techSupport
                  }`
                : hasUpcomingPause
                ? `It is currently not possible to cancel a pause before it starts. Instead, the pause will start and end on ${DateTime.fromISO(
                    planPurchase.latestPause!.startDate,
                    { zone: planPurchase.center.timezone }
                  ).toLocaleString(
                    DateTime.DATETIME_FULL
                  )}. Billing will not be affected. ${
                    STATUS_MESSAGE.techSupport
                  }`
                : STATUS_MESSAGE.bugReport}
            </>
          );
        }

        return (
          <>
            {(isPaused || hasUpcomingPause) && planPurchase.latestPause && (
              <CurrentPauseDescription>
                {compact([
                  `Plan is currently ${isPaused ? 'paused' : 'set to pause'}`,
                  DateTime.fromISO(planPurchase.latestPause.startDate, {
                    zone: planPurchase.center.timezone,
                  }).toFormat(DateFormat.Shortest),
                  '–',
                  `${DateTime.fromISO(planPurchase.latestPause.endDate, {
                    zone: planPurchase.center.timezone,
                  }).toFormat(DateFormat.Shortest)}.`,
                ]).join(' ')}
              </CurrentPauseDescription>
            )}

            <AFormFieldRow>
              <ADateField
                antdForm={form}
                disableDate={{
                  filter: (moment) => {
                    if (!moment) {
                      return true;
                    }
                    const dt = DateTime.fromJSDate(moment.toDate());
                    const dateLocalized = DateTime.fromObject({
                      day: dt.day,
                      month: dt.month,
                      year: dt.year,
                      zone: planPurchase.center.timezone,
                    }).startOf('day');

                    const startDate = DateTime.fromObject({
                      zone: planPurchase.center.timezone,
                    })
                      .plus({ days: isPaused ? 0 : 1 })
                      .startOf('day');
                    const maxEndDate = startDate
                      .plus({ months: 6 })
                      .startOf('day');
                    return (
                      dateLocalized <= startDate || dateLocalized > maxEndDate
                    );
                  },
                }}
                label={
                  isPaused || hasUpcomingPause
                    ? `New ${fields.endDate.label.toLowerCase()}`
                    : fields.endDate.label
                }
                name={fields.endDate.name}
                required
              />
            </AFormFieldRow>

            {!isPaused && (
              <ChangesDescription>
                Pause will be effective starting midnight tonight,{' '}
                {DateTime.fromObject({
                  zone: planPurchase.center.timezone,
                })
                  .plus({ days: 1 })
                  .startOf('day')
                  .toLocaleString(DateTime.DATETIME_FULL)}
                .
                {form.getFieldValue(fields.endDate.name) &&
                  ` Billing will resume on ${DateTime.fromJSDate(
                    (form.getFieldValue(fields.endDate.name) as Moment).toDate()
                  )
                    .startOf('day')
                    .toLocaleString(DateTime.DATE_FULL)}.`}
                {planPurchase.plan.billingPeriod ===
                  PapiBillingPeriod.MONTH && (
                  <RefundDescription>
                    Member will automatically receive a refund for the remainder
                    of this month when the pause starts.
                  </RefundDescription>
                )}
              </ChangesDescription>
            )}

            {isPaused && form.getFieldValue(fields.endDate.name) && (
              <ChangesDescription>
                Changes will be effective immediately. Billing will resume on{' '}
                {DateTime.fromJSDate(
                  (form.getFieldValue(fields.endDate.name) as Moment).toDate()
                ).toFormat(DateFormat.Shortest)}
                .
              </ChangesDescription>
            )}
            {(isPaused || hasUpcomingPause) && (
              <ATextClickable onClick={() => setShowCancel(true)}>
                Cancel pause
              </ATextClickable>
            )}
          </>
        );
      }}
    </AForm>
  );
};

// Styled components ////////////////////////////////
const ChangesDescription = styled(ASectionBtmMargin)`
  &&& {
    width: 95%;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const CurrentPauseDescription = styled(ASectionBtmMargin)`
  &&& {
    width: 95%;
  }
`;

const RefundDescription = styled.div`
  margin-top: ${theme.space.s};
`;

/** Form field info */
const fields = {
  endDate: {
    label: 'End date',
    name: 'endDate',
  },
};

export { PlanPurchasePause, fields };
