import {
  ASelectProviderInsuranceCredentials_Query_centers,
  ASelectProviderInsuranceCredentials_Query_payers,
} from 'app/types/generated/ASelectProviderInsuranceCredentials_Query';

/** Shows insurance payer name and center name. Use for display and selection (in dropdown). */
export const getPayerCenterDisplayName = (
  allCenters: ASelectProviderInsuranceCredentials_Query_centers[],
  allPayers: ASelectProviderInsuranceCredentials_Query_payers[],
  centerID: string,
  payerID: string
): string =>
  `${allPayers.find((p) => p.id === payerID)?.payerName ?? '???'} - ${
    allCenters.find((c) => c.id === centerID)?.name ?? '???'
  }`;
