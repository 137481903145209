import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

/** Provides common styles for app content below header & beside navbar */
export const APadding = styled.div<{ type: 'drawer' | 'modal' | 'page' }>`
  padding: ${({ type }) =>
    type === 'drawer' || type === 'modal'
      ? `0 ${theme.space.s}`
      : theme.space.l};
  ${theme.layout.breakpointMixin.phoneOnly} {
    padding: ${({ type }) =>
      type === 'drawer' || type === 'modal' ? 0 : theme.space.m};
  }
`;
