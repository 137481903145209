import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

/** Bar used to indicate the current time on the calendar. 100% width */
const ACurrentTimeBar: FC = () => {
  return (
    <Styles>
      <Circle />
      <Line />
    </Styles>
  );
};

// Styled components ////////////////////////////////
const Circle = styled.div`
  background-color: ${theme.color.marigold};
  border-radius: 50%;
  height: 8px;
  position: relative;
  top: -3px;
  width: 8px;
`;

const Line = styled.div`
  border: 1px solid ${theme.color.marigold};
  height: 0;
  left: -1px;
  position: relative;
  width: calc(100% + 1px);
`;

const Styles = styled.div`
  display: flex;
  width: 100%;
  z-index: ${theme.layer.zIndex.calendar.currentTimeBar};
`;

export { ACurrentTimeBar };
