import capitalize from 'lodash/capitalize';
import compact from 'lodash/compact';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';

import {
  PapiBillingPeriod,
  PapiPlanPurchaseState,
  PapiProductType,
} from 'app/types/generated/globalTypes';
import { MemberPlanCard_Fragment_planPurchase } from 'app/types/generated/MemberPlanCard_Fragment_planPurchase';
import { PlanPurchaseCreate_Query_plans_edges_node } from 'app/types/generated/PlanPurchaseCreate_Query';
import { compareLabel } from 'app/utils/sort';

// Types & constants ////////////////////////////////
type PlanPurchase = MemberPlanCard_Fragment_planPurchase;
type Plan = PlanPurchaseCreate_Query_plans_edges_node;

type PlanPurchaseStatus =
  | 'Enrolled' // start date is in the future
  | 'In progress' // start date past
  | 'On extension' // end date past but extension end date not past
  | 'Paused'
  | 'Ended' // end date including extensions past
  | 'Switched to Cash Pay'
  | 'Switched to In-Network';

// Util functions ////////////////////////////////
export const formatBillingPeriod = (billingPeriod: PapiBillingPeriod): string =>
  capitalize(billingPeriod.replace(/_/g, '-'));

export const formatPlanAgeLimit = (plan: {
  maxPersonAgeYears?: number | null;
  minPersonAgeYears: number;
}): string | undefined =>
  plan.minPersonAgeYears && plan.maxPersonAgeYears
    ? `${plan.minPersonAgeYears} – ${plan.maxPersonAgeYears}`
    : plan.minPersonAgeYears
    ? `${plan.minPersonAgeYears}+`
    : plan.maxPersonAgeYears
    ? `Under ${plan.maxPersonAgeYears + 1}`
    : undefined;

export const getPlanPaymentOptions = (
  product: PapiProductType,
  plans: Array<
    Pick<
      Plan,
      | 'annualCostInCents'
      | 'billingPeriod'
      | 'displayName'
      | 'id'
      | 'paymentRateInCents'
      | 'product'
    >
  >
): Array<{ label: string; value: string }> => {
  if (product.includes('WAITLIST')) {
    return plans
      .filter((plan) => plan.product.type === product)
      .map((plan) => ({
        label: plan.displayName.split('-')[0]?.trim() ?? plan.displayName,
        value: plan.id,
      }))
      .sort(compareLabel);
  }

  const [complimentaryPlans, paidPlans] = partition(
    plans.filter((plan) => plan.product.type === product),
    (plan) => plan.annualCostInCents === 0
  );

  return [...sortBy(paidPlans, 'annualCostInCents'), ...complimentaryPlans].map(
    (plan) => ({
      label:
        plan.paymentRateInCents === 0
          ? plan.displayName.split(' - ')[1]?.trim() ?? plan.displayName
          : getPlanPaymentRate(plan, false, false, true),
      value: plan.id,
    })
  );
};

export const getPlanPaymentRate = (
  plan: Partial<
    Pick<Plan, 'annualCostInCents' | 'displayName' | 'numberOfBillingCycles'>
  > &
    Pick<
      PlanPurchase['plan'],
      'billingPeriod' | 'displayName' | 'paymentRateInCents'
    >,
  includePaymentDuration?: boolean,
  includeAnnualCost?: boolean,
  includeFullName?: boolean
): string => {
  const {
    annualCostInCents,
    billingPeriod,
    displayName,
    numberOfBillingCycles,
    paymentRateInCents,
  } = plan;
  if (displayName.includes('Limited Access')) {
    return 'Limited Access';
  }

  if (paymentRateInCents === 0) {
    return 'Complimentary';
  }

  const paymentDollars = paymentRateInCents / 100;
  const billingPeriodFormatted = formatBillingPeriod(
    billingPeriod
  ).toLowerCase();
  const rate = includeFullName
    ? `$${paymentDollars}`
    : numberOfBillingCycles === 1
    ? `$${paymentDollars} upfront `
    : `$${paymentDollars}/${billingPeriodFormatted}`;

  const duration =
    numberOfBillingCycles && numberOfBillingCycles > 1
      ? `for ${numberOfBillingCycles} ${billingPeriodFormatted}s`
      : '';

  const annualCost =
    annualCostInCents &&
    billingPeriod === PapiBillingPeriod.MONTH &&
    (!numberOfBillingCycles || numberOfBillingCycles === 12)
      ? `($${annualCostInCents / 100}/year)`
      : '';

  return compact([
    rate,
    includeFullName && plan.displayName,
    includePaymentDuration && duration,
    includeAnnualCost && annualCost,
  ])
    .join(' ')
    .trim();
};

export const getPlanPurchaseStatus = ({
  endDateIncludingExtensions,
  endTime,
  latestPause,
  purchaseState,
  startTime,
}: Pick<
  MemberPlanCard_Fragment_planPurchase,
  | 'endDateIncludingExtensions'
  | 'endTime'
  | 'latestPause'
  | 'startTime'
  | 'purchaseState'
>): PlanPurchaseStatus | undefined =>
  purchaseState === PapiPlanPurchaseState.TRANSITION_FROM_INSURANCE
    ? 'Switched to Cash Pay'
    : purchaseState === PapiPlanPurchaseState.TRANSITION_TO_INSURANCE
    ? 'Switched to In-Network'
    : endDateIncludingExtensions &&
      DateTime.fromISO(endDateIncludingExtensions) < DateTime.local()
    ? 'Ended'
    : latestPause &&
      DateTime.fromISO(latestPause.startDate) < DateTime.local() &&
      DateTime.local() < DateTime.fromISO(latestPause.endDate)
    ? 'Paused'
    : endTime && DateTime.fromISO(endTime) < DateTime.local()
    ? 'On extension'
    : DateTime.fromISO(startTime) < DateTime.local()
    ? 'In progress'
    : 'Enrolled';

export const isCurrentPlan = ({
  endDateIncludingExtensions,
  endTime,
  startTime,
}: {
  endDateIncludingExtensions?: PlanPurchase['endDateIncludingExtensions'];
  endTime?: PlanPurchase['endTime'];
  startTime: PlanPurchase['startTime'];
}): boolean =>
  DateTime.fromISO(startTime) < DateTime.local() &&
  ((!endDateIncludingExtensions && !endTime) || // Nonending plan
    (endDateIncludingExtensions
      ? DateTime.fromISO(endDateIncludingExtensions) > DateTime.local()
      : endTime
      ? DateTime.fromISO(endTime) > DateTime.local()
      : true));
