import { useQuery } from '@apollo/react-hooks';
import { Dropdown, Menu, Skeleton } from 'antd';
import React, { FC, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ADropdownMenuItem } from 'app/components/atoms/ADropdownMenuItem/ADropdownMenuItem';
import { AIconClickable } from 'app/components/atoms/AIconClickable/AIconClickable';
import { ProviderAtCapacityIcon } from 'app/components/atoms/AIconProviderAtCapacity/AIconProviderAtCapacity';
import { ProviderCalendarCacheRefresh } from 'app/components/organisms/ProviderCalendarCacheRefresh/ProviderCalendarCacheRefresh';
import { ProviderDeleteModalFromAdmin } from 'app/components/organisms/ProviderDeleteModalFromAdmin/ProviderDeleteModalFromAdmin';
import { ViewerContext } from 'app/contexts/ViewerContext';
import { theme } from 'app/styles/theme';
import {
  ProviderHeader_Query,
  ProviderHeader_QueryVariables,
} from 'app/types/generated/ProviderHeader_Query';
import { AppRoute } from 'constants/app';
import { Role } from 'constants/app';

import { PROVIDER_HEADER_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  providerID: string;
  setLoading?: (loading: boolean) => void;
}

/** Header for provider page */
const ProviderHeader: FC<Props> = ({ providerID, setLoading }) => {
  const history = useHistory();

  const { data, loading } = useQuery<
    ProviderHeader_Query,
    ProviderHeader_QueryVariables
  >(PROVIDER_HEADER_QUERY, { variables: { providerID } });
  const viewer = useContext(ViewerContext);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onDeleteProvider = () => {
    setOpenModalDelete(true);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSoftDeleteProvider = () => {
    setOpenModalDelete(false);
    history.push(AppRoute.Providers);
    window.location.reload();
  };

  if (loading || !data || !data.provider) {
    return (
      <Header>
        <NameLoading>
          <Skeleton active paragraph={false} title={{ width: '30%' }} />
        </NameLoading>
      </Header>
    );
  }

  const { provider } = data;
  return (
    <Header>
      <Row>
        <Name>{provider.displayName}</Name>
        {provider.currentPanelSize &&
        provider.targetPanelSize &&
        provider.currentPanelSize >= provider.targetPanelSize ? (
          <ProviderAtCapacityIcon />
        ) : null}
      </Row>

      <AdminDropdown
        overlay={
          <Menu>
            <ADropdownMenuItem>
              <ProviderCalendarCacheRefresh
                providerID={provider.id}
                setLoading={setLoading}
              />
            </ADropdownMenuItem>
            {(viewer?.role === Role.Admin.toUpperCase() ||
              viewer?.role === Role.Superadmin.toUpperCase()) && (
              <ADropdownMenuItem>
                <div onClick={onDeleteProvider}>Delete Provider</div>
              </ADropdownMenuItem>
            )}
          </Menu>
        }
        trigger={['click']}
      >
        <KebabIcon primary type="more" />
      </AdminDropdown>
      <ProviderDeleteModalFromAdmin
        isVisible={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        onSuccess={() => handleSoftDeleteProvider()}
        provider={provider}
      />
    </Header>
  );
};

// Styled components ////////////////////////////////
const AdminDropdown = styled(Dropdown)`
  &&& {
    margin-right: 0;
    ${theme.layout.breakpointMixin.tabletPortraitDown} {
      margin-right: ${theme.space.s};
    }
  }
`;

const Header = styled.div`
  background: white;
  display: flex;
  justify-content: space-between;
  padding: ${theme.space.m} ${theme.space.l} ${theme.space.m} ${theme.space.m};
`;

const KebabIcon = styled(AIconClickable)`
  &&& {
    font-size: ${theme.font.size.xl};
  }
`;

const Name = styled.h5`
  margin: 0 ${theme.space.s} 0 0;
`;
const NameLoading = styled.div`
  margin: -11px 0 -${theme.space.s} 0;
  width: 100%;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
`;

export { ProviderHeader };
