import gql from 'graphql-tag';

export const MemberUpdate_Fragment_member = gql`
  fragment MemberUpdate_Fragment_member on PapiPerson {
    id
    addressCity
    addressLine1
    addressLine2
    addressPostalCode
    addressState
    biologicalSex
    center {
      id
    }
    dateOfBirth
    email
    firstName
    genderIdentity
    lastName
    middleName
    phoneNumber
    role
  }
`;
