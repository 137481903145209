import gql from 'graphql-tag';

import { ASelectCouponField_Fragment_coupon } from 'app/components/deprecated/ASelectCouponField/fragment';
import { MemberPlanCard_Fragment_planPurchase } from 'app/components/organisms/MemberPlanCard/fragment';

export const PLAN_PURCHASE_UPDATE_QUERY = gql`
  query PlanPurchaseUpdate_Query {
    coupons: listCoupons(onlyRedeemable: true) {
      edges {
        node {
          ...ASelectCouponField_Fragment_coupon
        }
      }
    }
  }
  ${ASelectCouponField_Fragment_coupon}
`;

export const PLAN_PURCHASE_UPDATE_COUPON_MUTATION = gql`
  mutation PlanPurchaseUpdate_Coupon_Mutation(
    $input: PapiAddCouponToPurchaseInput!
  ) {
    addCouponToPurchase(input: $input) {
      id
      ...MemberPlanCard_Fragment_planPurchase
    }
  }
  ${MemberPlanCard_Fragment_planPurchase}
`;
