import gql from 'graphql-tag';

import { ProviderName_Fragment } from 'app/fragments/ProviderName';

export const MEMBER_AVAILABLE_PROVIDERS_QUERY = gql`
  query MemberAvailableProviders_Query($id: ID!) {
    member: getPerson(id: $id) {
      id
      availableProviders(onlyTakingNewPatients: false) {
        ...ProviderName_Fragment
        email
        acuityID
        pod {
          id
          name
        }
      }
    }
  }
  ${ProviderName_Fragment}
`;

export const A_SELECT_PROVIDER_QUERY = gql`
  query ASelectProvider_Query {
    providers: listProviders {
      id
      acuityID
      email
      centers {
        center {
          id
        }
      }
      pod {
        id
        name
      }
      ...ProviderName_Fragment
    }
  }
  ${ProviderName_Fragment}
`;
