import { Drawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import React, {
  FC,
  ReactElement,
  cloneElement,
  useContext,
  useEffect,
  useState,
} from 'react';

import { LayoutContext } from 'app/contexts/LayoutContext';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props extends DrawerProps {
  ignoreLayoutContext?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  /** @params openButton use AButton for consistency */
  openButton?: ReactElement;
}

/** Togglable drawer that handles LayoutContext */
const ADrawer: FC<Props> = ({
  ignoreLayoutContext,
  onClose,
  onOpen,
  openButton,
  visible,
  width = theme.layout.drawerSizeDefault,
  ...props
}) => {
  const { setDrawerOpen } = useContext(LayoutContext);
  const [show, setShow] = useState(visible || false);

  // isDrawerOpen is not getting set correctly, so content width is not changing onClose
  // This also seems to trigger a remount the first time it's set to true
  useEffect(() => {
    !ignoreLayoutContext &&
      setDrawerOpen((prevDrawerOpen) => visible || show || prevDrawerOpen);
  }, [ignoreLayoutContext, setDrawerOpen, show, visible]);

  const onButtonClick = (): void => {
    onOpen?.();
    setShow(true);
  };

  const handleClose = (): void => {
    onClose?.();
    setShow(false);
  };

  return (
    <>
      {openButton && cloneElement(openButton, { onClick: onButtonClick })}
      <Drawer
        destroyOnClose
        mask={false}
        onClose={handleClose}
        visible={visible !== undefined ? visible : show}
        width={width}
        {...props}
      />
    </>
  );
};

export { ADrawer };
