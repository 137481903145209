import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import React, { FC, useEffect } from 'react';
import {
  FieldError,
  FormContextValues,
  ValidationOptions,
} from 'react-hook-form';
import styled from 'styled-components/macro';

import { AFormFieldError } from 'app/components/atoms/AFormFieldError/AFormFieldError';
import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
export interface AInputProps extends InputProps, AFormFieldWrapperProps {
  disableAutoComplete?: boolean;
  /** @param formHook react-hook-form useForm() */
  formHook?: FormContextValues<any>;
  label?: string;
  labelInfo?: string;
  name?: string;
  /** @param rules with react-hook-form only */
  rules?: ValidationOptions;
}

/** Input field that can be used alone or with react-hook-form */
const AInput: FC<AInputProps> = ({
  customWidth,
  defaultValue,
  disableAutoComplete,
  formHook,
  label,
  labelInfo,
  name,
  rules,
  show,
  ...props
}) => {
  useEffect(() => {
    // Register form field & set default value once
    if (formHook && name) {
      formHook.register({ name }, rules);
      formHook.setValue(name, defaultValue);
      return () => formHook.unregister(name);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const errorMessage =
    name &&
    formHook?.errors[name] &&
    (formHook.errors[name] as FieldError).message;

  return (
    <AFormFieldWrapper customWidth={customWidth} show={show}>
      {label && (
        <ALabel
          black
          help={labelInfo}
          htmlFor={name}
          required={!!rules?.required}
        >
          {label}
        </ALabel>
      )}

      <InputWrapper error={!!errorMessage}>
        <Input
          autoComplete={disableAutoComplete ? `off_${name}` : undefined}
          defaultValue={defaultValue}
          id={name}
          onBlur={() => {
            if (formHook && name) {
              formHook.triggerValidation(name);
            }
          }}
          onChange={(e) => {
            if (formHook && name) {
              formHook.setValue(name, e.target.value);
              if (formHook.errors[name]) {
                formHook.triggerValidation(name);
              }
            }
          }}
          {...props}
        />
      </InputWrapper>

      <AFormFieldError show={!!errorMessage}>{errorMessage}</AFormFieldError>
    </AFormFieldWrapper>
  );
};

// Styled components ////////////////////////////////
const InputWrapper = styled.div<{ error: boolean }>`
  input:not([disabled]) {
    border-color: ${({ error }) =>
      error ? theme.color.error : theme.color.border};
  }

  input:not([disabled]):active,
  input:not([disabled]):focus,
  input:not([disabled]):hover {
    border-color: ${({ error }) =>
      error ? theme.color.error : theme.color.primary} !important;
    box-shadow: ${({ error }) =>
      error
        ? `${theme.layer.boxShadow.all(theme.color.error + '40')}`
        : ''} !important;
  }
`;

export { AInput };
