import { useMutation, useQuery } from '@apollo/react-hooks';
import { Skeleton, message } from 'antd';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { ACard } from 'app/components/atoms/ACard/ACard';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import {
  AForm,
  TFormFieldInfo,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { AInputField } from 'app/components/deprecated/AInputField/AInputField';
import { theme } from 'app/styles/theme';
import { PapiUpdatePersonInput } from 'app/types/generated/globalTypes';
import {
  MemberInsuranceCard_Mutation,
  MemberInsuranceCard_MutationVariables,
} from 'app/types/generated/MemberInsuranceCard_Mutation';
import {
  MemberInsuranceCard_Query,
  MemberInsuranceCard_QueryVariables,
} from 'app/types/generated/MemberInsuranceCard_Query';
import { displayErrors } from 'app/utils/app';
import { STATUS_MESSAGE } from 'constants/message';

import {
  MEMBER_INSURANCE_CARD_MUTATION,
  MEMBER_INSURANCE_CARD_QUERY,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  editable?: boolean;
  personID: string;
}

/** Member insurance card that allows edits in card */
const MemberInsuranceCard: FC<Props> = ({ personID }) => {
  const [editing, setEditing] = useState(false);
  const toggleEditing = (): void => setEditing((prev) => !prev);

  const { data, loading: queryLoading } = useQuery<
    MemberInsuranceCard_Query,
    MemberInsuranceCard_QueryVariables
  >(MEMBER_INSURANCE_CARD_QUERY, { variables: { personID } });

  const [updateInsurance, { loading: mutationLoading }] = useMutation<
    MemberInsuranceCard_Mutation,
    MemberInsuranceCard_MutationVariables
  >(MEMBER_INSURANCE_CARD_MUTATION);

  if (queryLoading || !data || !data.member) {
    return (
      <Styles>
        <ACard>
          <Skeleton
            active
            paragraph={{ rows: 3, width: ['70%', '100%', '100%'] }}
            title={false}
          />
        </ACard>
      </Styles>
    );
  }

  const onSave = async (
    input: PapiUpdatePersonInput,
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      const result = await updateInsurance({
        variables: { input: { ...input, id: personID } },
      });
      if (result?.data) {
        message.success(STATUS_MESSAGE.insuranceUpdate.success);
        toggleEditing();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.insuranceUpdate.error.general);
      shouldFinish(false);
    }
  };

  const { member } = data;
  return (
    <Styles>
      <ACard
        title={
          editing
            ? undefined
            : member.insurancePayer || (
                <ATextLight lighter>Insurance unknown</ATextLight>
              )
        }
        upperRightText="Legacy"
      >
        {editing ? (
          <AForm
            onClose={toggleEditing}
            onSave={onSave}
            saving={mutationLoading}
          >
            {(form) => (
              <>
                <AInputField
                  antdForm={form}
                  initialValue={member.insurancePayer || undefined}
                  label={fields.insurancePayer.label}
                  name={fields.insurancePayer.name}
                />
                <AInputField
                  antdForm={form}
                  initialValue={member.insuranceGroupID || undefined}
                  label={fields.insuranceGroupID.label}
                  name={fields.insuranceGroupID.name}
                />
                <AInputField
                  antdForm={form}
                  initialValue={member.insuranceMemberID || undefined}
                  label={fields.insuranceMemberID.label}
                  name={fields.insuranceMemberID.name}
                />
              </>
            )}
          </AForm>
        ) : (
          <InsuranceInfo>
            {!member.insuranceGroupID && !member.insuranceMemberID && (
              <ATextLight lighter>–</ATextLight>
            )}
            {member.insuranceGroupID && (
              <Info>
                <InfoLabel>
                  <ATextBold>GroupID</ATextBold>
                </InfoLabel>
                {member.insuranceGroupID}
              </Info>
            )}
            {member.insuranceMemberID && (
              <Info>
                <InfoLabel>
                  <ATextBold>MemberID</ATextBold>
                </InfoLabel>
                {member.insuranceMemberID}
              </Info>
            )}
          </InsuranceInfo>
        )}
      </ACard>
    </Styles>
  );
};

// Styled components ////////////////////////////////
const Info = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoLabel = styled.div`
  margin-right: ${theme.space.m};
`;

const InsuranceInfo = styled.div`
  font-size: ${theme.font.size.s};
`;

const Styles = styled.div`
  margin: 0 ${theme.space.m} ${theme.space.m} 0;
  width: 425px;
`;

/** Form field info */
const fields: Pick<
  TFormFieldInfo<PapiUpdatePersonInput>,
  'insuranceGroupID' | 'insuranceMemberID' | 'insurancePayer'
> = {
  insuranceGroupID: {
    label: 'Group ID',
    name: 'insuranceGroupID',
  },
  insuranceMemberID: {
    label: 'Member ID',
    name: 'insuranceMemberID',
  },
  insurancePayer: {
    label: 'Insurance payer',
    name: 'insurancePayer',
  },
};

export { fields, MemberInsuranceCard };
