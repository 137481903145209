import { API_URL, HUBSPOT_URL, STRIPE_URL } from './env';

export const GRAPHQL_URL = `${API_URL}/graphql`;

/** add Acuity calendar id to the end */
export const ACUITY_CALENDAR_URL =
  'https://secure.acuityscheduling.com/preferences.php?tab=schedule&calendar=';

const HELPSCOUT_URL = 'https://secure.helpscout.net';
export const HELPSCOUT_CONVERSATIONS_URL = (helpscoutID: string): string =>
  `${HELPSCOUT_URL}/customers/${helpscoutID}/conversations`;

/** add HubSpot id to the end */
export const HUBSPOT_PERSON_URL = `${HUBSPOT_URL}/contacts/`;

export const SLACK_TEAM_CHANNEL =
  'https://app.slack.com/client/T09FUSQ7R/CUH4RUHMG';

/** add stripe customer id to the end */
export const STRIPE_CUSTOMER_URL = `${STRIPE_URL}/customers/`;
