import { Tooltip } from 'antd';
import React from 'react';

const ProviderAtCapacityIcon: React.FC = () => (
  <Tooltip placement="bottomLeft" title="Not accepting new members">
    <svg
      aria-label="icon: provider is at or above panel capacity"
      data-testid="provider-at-capacity"
      fill="#fff"
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.6787 6.22687H5.32031V23.7047H24.6787V6.22687Z"
        fill="#fff"
        stroke="#AB373D"
        stroke-miterlimit="10"
        stroke-width="2"
      />
      <path
        d="M5.32031 9.47906H24.6797"
        stroke="#AB373D"
        stroke-miterlimit="10"
        stroke-width="2"
      />
      <path
        d="M11.1318 6.22688V3.72563"
        stroke="#AB373D"
        stroke-miterlimit="10"
        stroke-width="2"
      />
      <path
        d="M19.4681 6.22688V3.72563"
        stroke="#AB373D"
        stroke-miterlimit="10"
        stroke-width="2"
      />
      <line
        stroke="#AB373D"
        stroke-width="2"
        x1="11.7071"
        x2="19.0707"
        y1="12.2929"
        y2="19.6565"
      />
      <line
        stroke="#AB373D"
        stroke-width="2"
        x1="11.9294"
        x2="19.293"
        y1="19.6565"
        y2="12.2929"
      />
    </svg>
  </Tooltip>
);

export { ProviderAtCapacityIcon };
