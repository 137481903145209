import { Button } from 'antd';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  cancelRight?: boolean;
  cancelText?: ReactNode;
  hideCancel?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  saveDisabled?: boolean;
  saveText?: ReactNode;
  saving?: boolean;
  savingText?: string;
}

/** Save and cancel buttons for use in forms */
const AFormSaveButtonGroup: FC<Props> = ({
  cancelRight,
  cancelText = 'Cancel',
  hideCancel,
  onCancel,
  onSave,
  saveDisabled,
  saveText = 'Save',
  saving,
  savingText = 'Saving',
}) => {
  const CancelButton = (
    <CancelButtonContainer>
      <Button onClick={onCancel}>{cancelText}</Button>
    </CancelButtonContainer>
  );
  return (
    <div>
      {!saving && !hideCancel && !cancelRight && CancelButton}
      <Button
        disabled={saveDisabled}
        loading={saving}
        onClick={onSave}
        type="primary"
      >
        {saving ? `${savingText}...` : saveText}
      </Button>
      {cancelRight && !saving && !hideCancel && CancelButton}
    </div>
  );
};

// Styled components ////////////////////////////////
const CancelButtonContainer = styled.div<{ onRight?: boolean }>`
  display: inline-block;
  margin-left: ${({ onRight }) => (onRight ? theme.space.m : 0)};
  margin-right: ${({ onRight }) => (onRight ? 0 : theme.space.m)};
`;

export { AFormSaveButtonGroup };
