import gql from 'graphql-tag';

import { CouponsTable_Fragment_coupon } from 'app/components/organisms/CouponsTable/fragment';
import { CouponForm_Fragment_plansQuery } from 'app/fragments/CouponForm';

export const COUPON_UPDATE_QUERY = gql`
  query CouponUpdate_Query {
    ...CouponForm_Fragment_plansQuery
  }
  ${CouponForm_Fragment_plansQuery}
`;

export const COUPON_UPDATE_MUTATION = gql`
  mutation CouponUpdate_Mutation($input: PapiUpdateCouponInput!) {
    updateCoupon(input: $input) {
      ...CouponsTable_Fragment_coupon
    }
  }
  ${CouponsTable_Fragment_coupon}
`;
