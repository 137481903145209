import React, { FC } from 'react';

import { AIconLabeled } from 'app/components/atoms/AIconLabeled/AIconLabeled';
import {
  ASelect,
  ASelectProps,
  IASelectOption,
} from 'app/components/atoms/ASelect/ASelect';
import { PapiInboxItemCategory } from 'app/types/generated/globalTypes';
import { DOCUMENT_TYPE_ATTRS } from 'constants/documentReview';

// Types & constants ////////////////////////////////
interface Props extends Omit<ASelectProps, 'selectOptions'> {
  withTrash?: boolean;
}

const DOCUMENT_TYPES_ORDERED = [
  PapiInboxItemCategory.LAB_RESULTS,
  PapiInboxItemCategory.MEDICAL_HISTORY,
  PapiInboxItemCategory.RADIOLOGY,
  PapiInboxItemCategory.ADMINISTRATIVE,
  PapiInboxItemCategory.CONSULTS,
  PapiInboxItemCategory.INVOICES,
  PapiInboxItemCategory.MISC,
];

/** Select member's document type, e.g. lab results, medical history */
const ASelectDocumentType: FC<Props> = ({ withTrash, ...props }) => {
  const selectOptions: IASelectOption[] = DOCUMENT_TYPES_ORDERED.map(
    (docType) => ({
      label: (
        <AIconLabeled
          label={DOCUMENT_TYPE_ATTRS[docType].label}
          type={DOCUMENT_TYPE_ATTRS[docType].icon}
        />
      ),
      searchIndex: DOCUMENT_TYPE_ATTRS[docType].label,
      value: docType,
    })
  );

  if (withTrash)
    selectOptions.push({
      label: <AIconLabeled label="Mistake" type="delete" />,
      searchIndex: 'Mistake',
      value: 'trash',
    });

  return <ASelect searchable selectOptions={selectOptions} {...props} />;
};

export { ASelectDocumentType };
