import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props extends SpinProps {
  centered?: boolean;
  children?: ReactNode;
  color?: string;
  fullscreen?: boolean;
}

/** Loading spinner */
const ALoading: FC<Props> = ({ centered, color, fullscreen, ...props }) => (
  <Styles centered={centered} color={color} fullscreen={fullscreen}>
    <Spin size={fullscreen ? 'large' : 'default'} {...props} />
  </Styles>
);

// Styled components ////////////////////////////////
const Styles = styled.div<{
  centered?: boolean;
  color?: string;
  fullscreen?: boolean;
}>`
  &&& {
    align-items: ${({ fullscreen }) => fullscreen && 'center'};
    display: ${({ centered, fullscreen }) =>
      (centered || fullscreen) && 'flex'};
    height: ${({ fullscreen }) => fullscreen && '90vh'};
    justify-content: ${({ centered, fullscreen }) =>
      (centered || fullscreen) && 'center'};
    left: ${({ fullscreen }) => fullscreen && '0'};
    position: ${({ fullscreen }) => fullscreen && 'absolute'};
    top: ${({ fullscreen }) => fullscreen && '0'};
    width: ${({ fullscreen }) => fullscreen && '100vw'};

    .ant-spin {
      color: ${({ color }) => color || theme.color.primary};
    }
    .ant-spin-dot i {
      background-color: ${({ color }) => color || theme.color.primary};
    }
  }
`;

export { ALoading };
