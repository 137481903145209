import { ApolloProvider } from '@apollo/react-hooks';
import { storiesOf } from '@storybook/react';
import React from 'react';

import { PapiConsumerType } from 'app/types/generated/globalTypes';
import { MemberProviders_Query_member_assignedCoach } from 'app/types/generated/MemberProviders_Query';
import { MemberProviders_Query_member_assignedDoctor } from 'app/types/generated/MemberProviders_Query';
import { MemberProviders_Query_member_center } from 'app/types/generated/MemberProviders_Query';
import { MemberProviders_Query_member_currentPlan } from 'app/types/generated/MemberProviders_Query';
import { createMockApolloClient } from 'app/utils/testing';
import { MOCK_MEMBER } from 'constants/mocks/member';
import { MOCK_PROVIDERS } from 'constants/mocks/provider';

import { MemberProviders } from './MemberProviders';
import { MEMBER_PROVIDERS_QUERY } from './query';

export interface MemberProvidersProps {
  __typename: 'PapiPerson';
  assignedCoach: MemberProviders_Query_member_assignedCoach | null;
  assignedDoctor: MemberProviders_Query_member_assignedDoctor | null;
  assignedMemberAdvocate?: MemberProviders_Query_member_assignedDoctor | null;
  center: MemberProviders_Query_member_center | null;
  currentPlan: MemberProviders_Query_member_currentPlan | null;
  firstName: string;
  id: string;
}

export const memberProviders: MemberProvidersProps = {
  __typename: 'PapiPerson',
  assignedCoach: {
    __typename: MOCK_PROVIDERS[0].__typename,
    displayName: MOCK_PROVIDERS[0].displayName,
    firstName: MOCK_PROVIDERS[0].firstName,
    id: MOCK_PROVIDERS[0].id,
    lastName: MOCK_PROVIDERS[0].lastName,
    pod: {
      __typename: 'PapiPod',
      id: MOCK_PROVIDERS[0].pod!.id,
      name: MOCK_PROVIDERS[0].pod!.name,
    },
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc:
        'https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=80',
    },
  },
  assignedDoctor: {
    __typename: MOCK_PROVIDERS[1].__typename,
    assignedCareManager: null,
    displayName: MOCK_PROVIDERS[1].displayName,
    firstName: MOCK_PROVIDERS[0].firstName,
    id: MOCK_PROVIDERS[1].id,
    lastName: MOCK_PROVIDERS[0].lastName,
    pod: {
      __typename: 'PapiPod',
      id: MOCK_PROVIDERS[1].pod!.id,
      name: MOCK_PROVIDERS[1].pod!.name,
    },
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc:
        'https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=80',
    },
  },
  assignedMemberAdvocate: {
    __typename: MOCK_PROVIDERS[3].__typename,
    assignedCareManager: null,
    displayName: MOCK_PROVIDERS[3].displayName,
    firstName: MOCK_PROVIDERS[3].firstName,
    id: MOCK_PROVIDERS[3].id,
    lastName: MOCK_PROVIDERS[3].lastName,
    pod: {
      __typename: 'PapiPod',
      id: MOCK_PROVIDERS[1].pod!.id,
      name: MOCK_PROVIDERS[1].pod!.name,
    },
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc:
        'https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=80',
    },
  },
  center: {
    __typename: MOCK_MEMBER.center.__typename,
    id: MOCK_MEMBER.center.id,
  },
  currentPlan: {
    __typename: 'PapiPlanPurchase',
    plan: {
      __typename: 'PapiPlan',
      consumerType: PapiConsumerType.DIRECT_CONSUMER,
    },
  },
  firstName: MOCK_MEMBER.firstName,
  id: MOCK_MEMBER.id,
};

storiesOf('Member', module).add('Providers', () => {
  const mockApolloClient = createMockApolloClient();
  mockApolloClient.writeQuery({
    data: { member: memberProviders },
    query: MEMBER_PROVIDERS_QUERY,
    variables: { id: memberProviders.id },
  });
  return (
    <ApolloProvider client={mockApolloClient}>
      <MemberProviders personID={MOCK_MEMBER.id} />
    </ApolloProvider>
  );
});
