import gql from 'graphql-tag';

import { VisitCard_Fragment } from 'app/components/organisms/VisitCard/fragment';

export const MDHQ_URL_QUERY = gql`
  query MdhqUrl_Query($personID: ID!) {
    getPerson(id: $personID) {
      mdhqPatientURL
    }
  }
`;

export const NOTE_CREATE_MUTATION = gql`
  mutation NoteCreate_Mutation($appointmentID: ID!) {
    createEncounterNote(appointmentID: $appointmentID) {
      ...VisitCard_Fragment
    }
  }
  ${VisitCard_Fragment}
`;
