import { Empty } from 'antd';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

// Types & constants ////////////////////////////////
interface Props {
  columnWidth?: string;
  detailPane?: ReactNode;
  masterColumn: ReactNode;
  noContentDescription?: string;
}

/** Split view displaying master column and detail pane */
const ASplitView: FC<Props> = ({
  columnWidth,
  detailPane,
  masterColumn,
  noContentDescription = 'No data',
}) => (
  <Styles>
    <Column width={columnWidth}>{masterColumn}</Column>
    {detailPane ? (
      <DetailPane>{detailPane}</DetailPane>
    ) : (
      <EmptyContainer>
        <Empty
          description={noContentDescription}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      </EmptyContainer>
    )}
  </Styles>
);

// Styled components ////////////////////////////////
const Column = styled.div<{ width?: string }>`
  flex-shrink: 0;
  height: 100%;
  overflow: auto;
  width: ${({ width = '240px' }) => width};
`;

const DetailPane = styled.div`
  height: 100%;
  width: 100%;
`;

const EmptyContainer = styled(DetailPane)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Styles = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export { ASplitView };
