import { DatePicker, Form } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/lib/form/Form';
import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import React, { FC } from 'react';

import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { FIELD_VALIDATION } from 'constants/form';

// Types & constants ////////////////////////////////
interface Props extends DatePickerProps, AFormFieldWrapperProps {
  antdForm: WrappedFormUtils;
  /** @param disableDate.filter Return true if date should be disabled */
  disableDate?:
    | { filter: (moment: Moment | null) => boolean }
    | { past: boolean };
  initialValue?: string | number | Date;
  label?: string;
  labelInfo?: string;
  name: string;
  options?: GetFieldDecoratorOptions;
  required?: boolean;
}

/** Deprecated: Antd form field, being deprecated to use react-hook-form */
const ADateField: FC<Props> = ({
  antdForm,
  customWidth,
  disableDate,
  initialValue,
  label,
  labelInfo,
  name,
  options,
  required,
  show,
  ...props
}) => {
  return (
    <AFormFieldWrapper antd customWidth={customWidth} show={show}>
      <Form.Item
        colon={false}
        label={
          label && (
            <ALabel black help={labelInfo} inline>
              {label}
            </ALabel>
          )
        }
      >
        {antdForm.getFieldDecorator(name, {
          ...options,
          initialValue: initialValue ? moment(initialValue) : undefined,
          rules: [
            ...(disableDate && 'past' in disableDate
              ? [
                  {
                    validator: (
                      ruleIgnore: any,
                      value: Moment,
                      callback: any
                    ) => {
                      if (
                        value &&
                        DateTime.fromJSDate(value.toDate()) <
                          DateTime.local().startOf('day')
                      ) {
                        callback(`Date can't be in the past`);
                      }
                      callback();
                    },
                  },
                ]
              : []),
            ...(disableDate && 'filter' in disableDate
              ? [
                  {
                    validator: (
                      ruleIgnore: any,
                      value: Moment,
                      callback: any
                    ) => {
                      if ([value].filter(disableDate.filter).length > 0) {
                        callback(`Date is invalid`);
                      }
                      callback();
                    },
                  },
                ]
              : []),
            ...(options?.rules || []),
            ...(required ? [FIELD_VALIDATION.required] : []),
          ],
          // onBlur validation breaks component https://github.com/ant-design/ant-design/issues/10097
          // validateTrigger: ['onBlur', 'onChange'],
        })(
          <DatePicker
            allowClear={false}
            disabledDate={
              disableDate && 'past' in disableDate
                ? (date) =>
                    !date ||
                    DateTime.fromJSDate(date.toDate()) <
                      DateTime.local().startOf('day')
                : disableDate && 'filter' in disableDate
                ? disableDate.filter
                : undefined
            }
            format="M/D/YYYY"
            showToday={false}
            {...props}
          />
        )}
      </Form.Item>
    </AFormFieldWrapper>
  );
};

export { ADateField };
