import { useAuth } from '@parsleyhealth/front-end-utils';
import React, { useEffect, useState, useRef } from 'react';
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { LoginPage } from 'app/components/pages/LoginPage/LoginPage';

export const AuthRouter: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { isLoggedIn } = useAuth();
  const prevIsLoggedIn = useRef(isLoggedIn);
  const [redirectPath, setRedirectPath] = useState<string>(
    location.pathname === '/login' ? '/' : location.pathname
  );

  useEffect(() => {
    if (isLoggedIn !== prevIsLoggedIn.current) {
      if (!isLoggedIn) {
        // Logs out
        setRedirectPath(location.pathname);
        history.push('/login');
      }
    }
    prevIsLoggedIn.current = isLoggedIn;
  }, [history, isLoggedIn]); //eslint-disable-line

  return (
    <Switch>
      <Route path="/login">
        {isLoggedIn ? <Redirect to={redirectPath} /> : <LoginPage />}
      </Route>
      {isLoggedIn ? children : <Redirect to="/login" />}
    </Switch>
  );
};
