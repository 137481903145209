import { MockLink } from '@apollo/react-testing';
import { RenderResult, fireEvent } from '@testing-library/react';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { LDClient, LDFlagValue } from 'launchdarkly-js-client-sdk';
import compact from 'lodash/compact';
import React from 'react';

import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { ASelectProviderCenter_Query_centers } from 'app/types/generated/ASelectProviderCenter_Query';
import { ASelectProviderProduct_Query_products } from 'app/types/generated/ASelectProviderProduct_Query';
import fragmentTypes from 'app/types/generated/fragmentTypes';
import { getCenterSearchIndex } from 'app/utils/center';
/***
 * Apollo mocks
 * The most common method will be avoiding these test utils and using
 * <MockedProvider mocks={mocks}> where mocks = MockedResponse[].
 * createMockApolloClient when you need to write to the cache directly.
 * createMockApolloCache for complicated cases with fragments on interfaces or
 * waiting for a mutation to occur
 */

/**
 * Use to test components with fragments on interfaces such as
 * couponDiscount: {
 *   ... on FlatDiscount {
 *     amountOffInCents
 *   }
 *   ... on PercentDiscount {
 *     percentOff
 *   }
 * }
 * <MockedProvider cache={createMockApolloCache()}>
 * Useful for testing mutations, or where you don't want to write to
 * the cache directly
 */
export const createMockApolloCache = (): InMemoryCache =>
  new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData: fragmentTypes,
    }),
  });

/**
 * const mockApolloClient = createMockApolloClient();
 * mockApolloClient.writeQuery({ data, query, variables }).
 * Wrap the component with <ApolloProvider client={mockApolloClient}>
 * Use where you want to write to the cache directly (Storybook knobs)
 */
export const createMockApolloClient = (): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: fragmentTypes,
      }),
    }),
    link: new MockLink([]),
  });

export const makeFeatureFlagClientMock = (flags?: {
  [flagName: string]: boolean | string | number;
}): LDClient => {
  const featureFlagClientMock: LDClient = {
    allFlags: () => ({}),
    close: async () => {},
    flush: async () => {},
    getUser: () => ({}),
    identify: async () => ({}),
    off: () => {},
    on: () => {},
    setStreaming: () => {},
    track: () => {},
    variation: (key: string, defaultValue?: LDFlagValue) =>
      flags ? flags[key] : defaultValue,
    variationDetail: () => ({
      reason: { kind: '' },
      value: true,
    }),
    waitForInitialization: async () => {},
    waitUntilGoalsReady: async () => {},
    waitUntilReady: async () => {},
  };

  return featureFlagClientMock;
};

/**
 * Wrap test render components in this to test feature flags
 */
export const MockFeatureFlagContextProvider: React.FC<{
  flags?: {
    [flagName: string]: boolean | string | number;
  };
}> = ({ children, flags }) => {
  const featureFlagClientMock: LDClient = makeFeatureFlagClientMock(flags);
  return (
    <FeatureFlagContext.Provider value={featureFlagClientMock}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

/** Call this to mock the feature flag context */
/*
export const mockFeatureFlagContext = (flags?: {
  [flagName: string]: boolean | string | number;
}): LDClient => {
  const featureFlagClientMock: LDClient = {
    allFlags: () => ({}),
    close: async () => {},
    flush: async () => {},
    getUser: () => ({}),
    identify: async () => ({}),
    off: () => {},
    on: () => {},
    setStreaming: () => {},
    track: () => {},
    variation: (key: string, defaultValue?: LDFlagValue) =>
      flags ? flags[key] : defaultValue,
    variationDetail: () => ({
      reason: { kind: '' },
      value: true,
    }),
    waitForInitialization: async () => {},
    waitUntilGoalsReady: async () => {},
    waitUntilReady: async () => {},
  };
  return featureFlagClientMock;
};
*/

// Interaction testing ////////////////////////////////
export const pressEnterKey = (element: Element): void => {
  fireEvent.keyDown(element, {
    code: 13,
    key: 'Enter',
  });
};

export const triggerOnChange = (element: Element, newValue: string): void => {
  fireEvent.change(element, {
    target: { value: newValue },
  });
};

// Ant Design form fields ////////////////////////////////
export const changeDateByPlaceholder = (
  wrapper: RenderResult,
  newDate: string,
  placeholder = 'Select date'
): void => {
  // Click the readonly input field to open the date selector
  const inputField = wrapper
    .getAllByPlaceholderText(placeholder)
    .find((input) => input.getAttribute('readOnly') === '');
  if (inputField) {
    fireEvent.click(inputField);
  }

  // Change the writable input field to the provided date
  const dateInput = wrapper
    .getAllByPlaceholderText(placeholder)
    .find(
      (input) =>
        input.getAttribute('readOnly') === null ||
        input.getAttribute('readOnly') === 'false'
    );
  if (dateInput) {
    triggerOnChange(dateInput, newDate);
  }
};

/** <input id="$FORM-FIELD-NAME" type="checkbox" value checked /> */
export const getCheckboxByFormFieldName = (
  formFieldName: string
): HTMLElement | undefined =>
  document.getElementById(formFieldName) || undefined;

/**
 * Returns whether checkbox has "checked" attribute, determined by parent element
 * <span class="ant-checkbox ant-checkbox-checked">
 *   <input id="$FORM-FIELD-NAME" type="checkbox" value checked />
 * </span>
 */
export const getCheckboxValueByFormFieldName = (
  formFieldName: string
): boolean | undefined => {
  const checkbox = document.getElementById(formFieldName);
  return !checkbox
    ? undefined
    : !checkbox.parentElement
    ? undefined
    : checkbox.parentElement.className.includes('checked');
};

/**
 * <span class="ant-radio ant-radio-checked">
 *   <input type="radio" value="$RADIO-VALUE">
 * </span>
 */
export const getCheckedRadioValue = (): string | undefined => {
  const checkedWrapper = document.getElementsByClassName(
    'ant-radio-checked'
  )[0];
  return checkedWrapper?.firstElementChild?.getAttribute('value') || undefined;
};

export const getAllCheckedRadioValues = (): string[] => {
  const toReturn: Array<string | null> = [];
  const radios = document.getElementsByClassName('ant-radio-checked');
  for (let i = 0; i < radios.length; i++) {
    const radio = radios[i].firstElementChild;
    if (radio) {
      toReturn.push(radio.getAttribute('value'));
    }
  }
  return compact(toReturn);
};

/**
 * <div class="ant-select-selection-selected-value" title="$SELECT-LABEL">
 *   $SELECT-LABEL
 * </div>
 */
export const getSelectedDropdownLabel = (): string | undefined => {
  const selected = document.getElementsByClassName(
    'ant-select-selection-selected-value'
  )[0];
  return (selected && selected.getAttribute('title')) || undefined;
};

export const getAllSelectedDropdownLabels = (): string[] => {
  const toReturn: Array<string | null> = [];
  const selected = document.getElementsByClassName(
    'ant-select-selection-selected-value'
  );
  for (let i = 0; i < selected.length; i++) {
    toReturn.push(selected[i].getAttribute('title'));
  }
  return compact(toReturn);
};

/** <li class="ant-select-selection__choice" title="$SELECT-LABEL">...</li> */
export const getAllMultiselectSelectedDropdownLabels = (): string[] => {
  const toReturn: Array<string | null> = [];
  const selected = document.getElementsByClassName(
    'ant-select-selection__choice'
  );
  for (let i = 0; i < selected.length; i++) {
    toReturn.push(selected[i].getAttribute('title'));
  }
  return compact(toReturn);
};

/** <button id="$FORM-FIELD-NAME" role="switch" value aria-checked="true" /> */
export const getSwitchByFormFieldName = (
  formFieldName: string
): HTMLElement | undefined =>
  document.getElementById(formFieldName) || undefined;

export const getSwitchValueByFormFieldName = (
  formFieldName: string
): boolean | undefined => {
  const switchField = document.getElementById(formFieldName);
  if (switchField) {
    const switchValue = switchField.getAttribute('aria-checked');
    switch (switchValue) {
      case 'true':
        return true;
      case 'false':
        return false;
    }
  }
  return undefined;
};

/**
 * ASelectProviderCenter
 * <li tag={ReactNode} title="LA Los Angeles California">
 *   <div>
 *     <label>
 *       <span><input type="checkbox" value="" /></span>
 *       <span><div>LA</div></span>
 *     </label>
 *     <button role="switch" type="button">
 *       <span class="ant-switch-inner" />
 *     </button>
 *   </div>
 * </li>
 */
export const getProviderCenterCheckboxByCenter = (
  wrapper: RenderResult,
  center: Pick<
    ASelectProviderCenter_Query_centers,
    'abbreviatedName' | 'city' | 'isVirtual' | 'state'
  >
): Element => {
  const items = wrapper.getAllByTitle(getCenterSearchIndex(center));
  const selectOption =
    items.length > 1
      ? items.find((item) => item.hasAttribute('tag'))!
      : items[0];
  return selectOption.getElementsByTagName('input')[0];
};

export const getProviderCenterNewMemberSwitchByCenter = (
  wrapper: RenderResult,
  center: Pick<
    ASelectProviderCenter_Query_centers,
    'abbreviatedName' | 'city' | 'isVirtual' | 'state'
  >
): Element => {
  const items = wrapper.getAllByTitle(getCenterSearchIndex(center));
  const selectOption =
    items.length > 1
      ? items.find((item) => item.hasAttribute('tag'))!
      : items[0];
  return selectOption.getElementsByTagName('button')[0];
};

export const getProviderProductCheckboxByProduct = (
  wrapper: RenderResult,
  product: Pick<ASelectProviderProduct_Query_products, 'displayName' | 'type'>
): Element => {
  const items = wrapper.getAllByTitle(product.displayName);
  const selectOption =
    items.length > 1
      ? items.find((item) => item.hasAttribute('tag'))!
      : items[0];
  return selectOption.getElementsByTagName('input')[0];
};

// Ant Design general components ////////////////////////////////
export const getLoadingSkeletons = ():
  | HTMLCollectionOf<Element>
  | undefined => {
  const skeletons = document.getElementsByClassName('ant-skeleton');
  if (skeletons.length > 0) {
    return skeletons;
  }
  return undefined;
};

export const getLoadingSpinners = (): HTMLCollectionOf<Element> | undefined => {
  const spinners = document.getElementsByClassName('ant-spin-spinning');
  if (spinners.length > 0) {
    return spinners;
  }
  return undefined;
};
