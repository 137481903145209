import gql from 'graphql-tag';

import { MemberBasics_Fragment_member } from './fragment';

export const MEMBER_BASICS_QUERY = gql`
  query MemberBasics_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      ...MemberBasics_Fragment_member
    }
  }
  ${MemberBasics_Fragment_member}
`;
