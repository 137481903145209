import range from 'lodash/range';
import { DateTime, Interval } from 'luxon';

import { CellInfo, Grid } from 'app/types/calendar';
import {
  AVAILABLE_START_HOUR,
  MIN_APPOINTMENT_LENGTH,
  NUM_VERTICAL_CELLS,
  VERTICAL_PRECISION,
} from 'constants/calendar';

export const getTimeFromY = (y: number): number => y * VERTICAL_PRECISION;
export const getYFromTime = (mins: number): number => mins / VERTICAL_PRECISION;
export const getSpan = (x1: number, x2: number): number =>
  1 + Math.abs(x2 - x1);

export interface CellToDate {
  date: DateTime;
  hoursColumn?: boolean;
  startX: number;
  startY: number;
}

export type MapCellInfoToDateRange = (options: {
  date: DateTime;
}) => (cellInfo: CellInfo) => Interval[];

export const cellToDateTime = ({
  date,
  hoursColumn,
  startX,
  startY,
}: CellToDate): DateTime => {
  return date.plus({
    days: hoursColumn ? startX + 1 : startX,
    minutes: getTimeFromY(startY),
  });
};

// takes date and cell info from calendar cell and returns date range
export const createMapCellInfoToRecurringTimeRange: MapCellInfoToDateRange = ({
  date,
}) => ({ endX, hoursColumn, spanY, startX, startY }) => {
  // returns date ranges cell occupies based on cell info
  const result = range(startX, endX + 1)
    .map((xPosition) => {
      const startDate = cellToDateTime({
        date,
        hoursColumn,
        startX: xPosition,
        startY,
      });
      /** returns end date based spanY which is the minutes occupied by the cell
       returns end of day if minutes push datetime to new day */
      const endDate = DateTime.min(
        startDate.plus({ minutes: getTimeFromY(spanY) }),
        startDate.endOf('day')
      );

      const range: Interval =
        startDate < endDate
          ? Interval.fromDateTimes(startDate, endDate)
          : Interval.fromDateTimes(endDate, startDate);

      return range;
    })
    .sort((range1, range2) => (range1.start > range2.start ? 1 : -1));

  return result;
};

export const getAvailableHours = (
  numOfCellsList: number[],
  start: number,
  end: number
): number[] => {
  const startHour = start * 2;
  const endHour = end * 2;
  return numOfCellsList.slice(startHour, endHour);
};

// returns cells occupied by date range
export const createMapDateRangeToCells = (originDate: DateTime) => ({
  end,
  start,
}: Interval): CellInfo[] => {
  // get start of day based on what hour calendar start
  const originOfThisDay = start
    .startOf('day')
    .plus({ hours: AVAILABLE_START_HOUR });

  /** 
    SideNote: section below is the only logic that needs to change
    for provider view. Everything else remains the same after determining 
    x positions
  */

  /** Start section */
  const _startX = Math.floor(originOfThisDay.diff(originDate, ['days']).days);
  const _endX = Math.floor(end.diff(originDate, ['days']).days);
  /** End Section */

  const _startY = getYFromTime(
    start.diff(originOfThisDay, ['minutes']).minutes
  );
  const _endY =
    getYFromTime(
      end.diff(end.startOf('day').plus({ hours: AVAILABLE_START_HOUR }), [
        'minutes',
      ]).minutes
    ) - 1;

  // returns list of cells occupied by start and end date
  const cells = range(_startX, _endX + 1).map((i) => {
    /***
     * X and Y coords are based off blocks: X = 0 is the first day on the
     * calendar and X = 1 is the second day. Y = 0 is the first hour available
     * and Y = 1 is 30 minutes later (based off VERTICAL_PRECISION)
     */
    const startX = i;
    const endX = i;
    const atStart = i === _startX;
    const atEnd = i === _endX;
    const startY = !atStart ? 0 : _startY;
    const endY = !atEnd ? NUM_VERTICAL_CELLS - 1 : _endY;

    const interval = Interval.fromDateTimes(start, end);
    const lengthInMinutes = Math.ceil(interval.length('minutes'));

    const spanX = getSpan(startX, endX);
    const spanY =
      lengthInMinutes >= VERTICAL_PRECISION
        ? getSpan(startY, endY)
        : MIN_APPOINTMENT_LENGTH / VERTICAL_PRECISION;
    return {
      endX,
      endY,
      spanX,
      spanY,
      startX,
      startY,
    };
  });

  return cells;
};

/** returns grid info object based on overall size of calendar
    along with function to determine rect based on cell and grid info */
export const createGrid = ({
  numberOfColumns,
  totalHeight,
  totalWidth,
}: {
  numberOfColumns: number;
  totalHeight: number;
  totalWidth: number;
}): Grid => {
  const cellHeight = totalHeight / NUM_VERTICAL_CELLS;
  const cellWidth = totalWidth / numberOfColumns;

  return {
    cellHeight,
    cellWidth,
    getRectFromCell(data: CellInfo) {
      const { endX, endY, spanX, spanY, startX, startY } = data;
      const bottom = endY * this.cellHeight;
      const top = startY * this.cellHeight;
      const left = startX * this.cellWidth;
      const right = endX * this.cellWidth;
      const height = spanY * this.cellHeight;
      const width = spanX * this.cellWidth;

      return {
        bottom,
        height,
        left,
        right,
        top,
        width,
      };
    },
    totalHeight,
    totalWidth,
  };
};
