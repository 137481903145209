import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

/** Wrap a group of fields to group them into a responsive row */
export const AFormFieldRow = styled.div<{
  breakpoint?: 'phone' | 'tabletPortrait' | 'tabletLandscape';
  height?: string;
}>`
  align-items: center;
  display: flex;
  flex-direction: row;
  > *:not(:last-of-type) {
    margin-right: ${theme.space.m};
  }
  ${({ height = 'fit-content' }) => `height: ${height};`};
  ${({ breakpoint = 'phone' }) =>
    (breakpoint === 'tabletLandscape'
      ? theme.layout.breakpointMixin.tabletLandscapeDown
      : breakpoint === 'tabletPortrait'
      ? theme.layout.breakpointMixin.tabletPortraitDown
      : theme.layout.breakpointMixin.phoneOnly) +
    `{
      align-items: flex-start;
      flex-direction: column;
      > * {
        margin-right: 0;
        width: 100%;
      }
      > *:not(:last-of-type) {
        margin-right: 0;
      }
    }`}
`;
