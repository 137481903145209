import React, { FC, useEffect, useState } from 'react';

import {
  ACheckbox,
  ACheckboxProps,
} from 'app/components/atoms/ACheckbox/ACheckbox';
import { PapiEmploymentType } from 'app/types/generated/globalTypes';

export interface AnEmploymentTypeCheckboxProps extends ACheckboxProps {
  initialValue?: PapiEmploymentType;
}

export const AnEmploymentTypeCheckbox: FC<AnEmploymentTypeCheckboxProps> = ({
  formHook,
  initialValue,
  ...props
}) => {
  const [
    isEmploymentTypeInitialized,
    setIsEmploymentTypeInitialized,
  ] = useState(false);
  useEffect(() => {
    if (isEmploymentTypeInitialized || !formHook || !initialValue) {
      return;
    }
    formHook.setValue('employmentType', initialValue);
    setIsEmploymentTypeInitialized(true);
  }, [formHook, isEmploymentTypeInitialized, initialValue]);

  const checkboxProps = {
    ...props,
    formHook,
    onChange: (e) => {
      const value = e.target.checked
        ? PapiEmploymentType.CONTRACTOR
        : PapiEmploymentType.FULL_TIME;
      formHook?.setValue('employmentType', value);
    },
    setDefaultValue: false,
  };

  return <ACheckbox {...checkboxProps} />;
};
