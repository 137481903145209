import gql from 'graphql-tag';

import { ProviderBasics_Fragment_provider } from 'app/components/organisms/ProviderBasics/fragment';
import { ProviderHeader_Fragment_provider } from 'app/components/organisms/ProviderHeader/fragment';
import { ProviderPage_Fragment_provider } from 'app/components/pages/ProviderPage/fragment';

import {
  ProviderUpdate_Fragment_provider,
  ProviderUpdate_Fragment_providerCenter,
} from './fragment';

export const PROVIDER_UPDATE_QUERY = gql`
  query ProviderUpdate_Query($providerID: ID!) {
    provider: getProvider(id: $providerID) {
      assignedCareManager {
        ...ProviderUpdate_Fragment_provider
      }
      ...ProviderUpdate_Fragment_provider
    }
  }
  ${ProviderUpdate_Fragment_provider}
`;

export const PROVIDER_UPDATE_QUERY_V2 = gql`
  query ProviderUpdate_Query_v2($providerID: ID!) {
    provider: getProvider(id: $providerID) {
      assignedCareManager {
        ...ProviderUpdate_Fragment_provider
      }
      isMedicareOptedOut
      ...ProviderUpdate_Fragment_provider
      maxHours: attributeValue(type: MAX_PATIENT_FACING_HOURS)
      daysPerWeek: attributeValue(type: WORK_DAYS_PER_WEEK)
    }
  }
  ${ProviderUpdate_Fragment_provider}
`;

export const PROVIDER_UPDATE_MUTATION = gql`
  mutation ProviderUpdate_Mutation($input: PapiUpdateProviderInput!) {
    updateProvider(input: $input) {
      id
      ...ProviderBasics_Fragment_provider
      ...ProviderUpdate_Fragment_provider
      ...ProviderHeader_Fragment_provider
      ...ProviderPage_Fragment_provider
    }
  }
  ${ProviderBasics_Fragment_provider}
  ${ProviderUpdate_Fragment_provider}
  ${ProviderHeader_Fragment_provider}
  ${ProviderPage_Fragment_provider}
`;

export const PROVIDER_UPDATE_MUTATION_V2 = gql`
  mutation ProviderUpdate_Mutation_V2(
    $input: PapiUpdateProviderInput!
    $id: ID!
    $value: Boolean!
  ) {
    updateProvider(input: $input) {
      id
      ...ProviderBasics_Fragment_provider
      ...ProviderUpdate_Fragment_provider
      ...ProviderHeader_Fragment_provider
      ...ProviderPage_Fragment_provider
    }

    updateProviderIsMedicareOptedOut(id: $id, value: $value)
  }
  ${ProviderBasics_Fragment_provider}
  ${ProviderUpdate_Fragment_provider}
  ${ProviderHeader_Fragment_provider}
  ${ProviderPage_Fragment_provider}
`;

export const UPDATE_PROVIDER_IS_MEDICARE_OPTED_OUT_MUTATION = gql`
  mutation UpdateProviderIsMedicareOptedOut($id: ID!, $value: Boolean!) {
    updateProviderIsMedicareOptedOut(id: $id, value: $value)
  }
`;

export const UPDATE_PROVIDER_ATTR = gql`
  mutation UpdateProviderAttr($input: [PapiProviderAttrInput!]!) {
    updateProviderAttr(input: $input) {
      id
      providerID
      attr
      value
    }
  }
`;

export const PROVIDER_UPDATE_REFETCH = gql`
  query ProviderUpdate_Refetch($providerID: ID!) {
    provider: getProvider(id: $providerID) {
      id
      centers {
        ...ProviderUpdate_Fragment_providerCenter
      }
      payerCredentials {
        payerID
        centerID
      }
      products {
        displayName
        type
      }
    }
  }
  ${ProviderUpdate_Fragment_providerCenter}
`;

export const APP_PERSON_QUERY = gql`
  query App_Person_Query {
    viewer: currentPapiPerson {
      id
      email
      role
    }
  }
`;
