import { Icon } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  color?: string;
  fontSize?: string;
  label: string;
  onClick?: () => void;
  type: string;
}

/** Icon with label */
const AIconLabeled: FC<Props> = ({
  color,
  fontSize = theme.space.m,
  label,
  type,
  onClick,
}) => (
  <ClickableText
    clickable={onClick ? true : false}
    color={color}
    fontSize={fontSize}
    onClick={onClick}
  >
    <IconStyled type={type} />
    {label}
  </ClickableText>
);

// Styled components ////////////////////////////////
const IconStyled = styled(Icon)`
  &&& {
    margin-right: ${theme.space.s};
  }
`;

const ClickableText = styled.span<{
  clickable: boolean;
  color?: string;
  fontSize: string;
}>`
  align-items: center;
  color: ${({ color }) => color};
  display: flex;
  font-size: ${({ fontSize }) => fontSize};
  ${({ clickable }) =>
    clickable
      ? `
    color: ${theme.color.primary};
    cursor: pointer;
    transition-duration: ${theme.animation.transitionDuration};
    transition-property: color;
    transition-timing-function: ${theme.animation.transitionTiming};
    :hover {
      color: ${theme.color.primaryLight};
    }
    * {
      color: ${theme.color.primary};
      cursor: pointer;
      transition-duration: ${theme.animation.transitionDuration};
      transition-property: color;
      transition-timing-function: ${theme.animation.transitionTiming};
      :hover {
        color: ${theme.color.primaryLight};
      }
    }`
      : ''}
`;

export { AIconLabeled };
