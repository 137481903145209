import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient, { Resolvers } from 'apollo-client';
import gql from 'graphql-tag';

export const resolvers: Resolvers = {
  PapiAppointment: {
    questionnairesStatus: () => 'SUBMITTED',
  },
  Query: {
    mockedGetAppointmentsByStatus: async (
      queryIgnore,
      argsIgnore,
      { client }: { client: ApolloClient<InMemoryCache> }
    ) => {
      const resp = await client.query({
        query: gql`
          query personsForMocks {
            searchPersons(search: "a") {
              center {
                abbreviatedName
                id
                name
              }
              currentPlan {
                plan {
                  displayName
                  product {
                    displayName
                  }
                }
              }
              dateOfBirth
              firstName
              id
              lastName
              mdhqID
              membershipTermsAcceptedAt
              middleName
            }
          }
        `,
      });

      return [
        {
          __typename: 'PapiAppointment',
          category: 'MEDICAL_INITIAL',
          center: {
            __typename: 'PapiCenter',
            abbreviatedName: 'NYC',
            id: '1',
            name: 'New York',
          },
          endTime: new Date().toISOString(),
          id: '1',
          person: resp.data.searchPersons[0],
          provider: {
            __typename: 'PapiProvider',
            displayName: 'Jane Doe',
            id: '123',
          },
          startTime: new Date().toISOString(),
        },
      ];
    },
  },
};
