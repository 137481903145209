
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "PapiDiscount",
        "possibleTypes": [
          {
            "name": "PapiFlatDiscount"
          },
          {
            "name": "PapiPercentageDiscount"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PapiCouponDuration",
        "possibleTypes": [
          {
            "name": "PapiCouponApplies"
          },
          {
            "name": "PapiCouponRepeats"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PapiNudgeResource",
        "possibleTypes": [
          {
            "name": "PapiQuestionnaire"
          },
          {
            "name": "PapiAppointment"
          },
          {
            "name": "PapiNudgeMessage"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PapiTimelineGroup",
        "possibleTypes": [
          {
            "name": "PapiTimelineAppointmentGroup"
          },
          {
            "name": "PapiTimelineHealthyHomeworkGroup"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PapiTimelineStatus",
        "possibleTypes": [
          {
            "name": "PapiTimelineAppointmentStatus"
          },
          {
            "name": "PapiTimelineHealthyHomeworkStatus"
          }
        ]
      }
    ]
  }
};
      export default result;
    