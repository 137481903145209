import { FC } from 'react';

import { DocumentReviewPage } from 'app/components/arabella/DocumentReviewPage/DocumentReviewPage';
import { VisitsPage } from 'app/components/deprecated/VisitsPage/VisitsPage';
import { CalendarPage } from 'app/components/pages/CalendarPage/CalendarPage';
import { CouponsPage } from 'app/components/pages/CouponsPage/CouponsPage';
import { MemberPage } from 'app/components/pages/MemberPage/MemberPage';
import { MembersPage } from 'app/components/pages/MembersPage/MembersPage';
import { PlansPage } from 'app/components/pages/PlansPage/PlansPage';
import { ProviderPage } from 'app/components/pages/ProviderPage/ProviderPage';
import { ProvidersPage } from 'app/components/pages/ProvidersPage/ProvidersPage';
import { PapiRole } from 'app/types/generated/globalTypes';

// Legacy roles used in Firebase
export enum Role {
  Admin = 'parsley_admin',
  Anonymous = 'parsley_anonymous',
  Member = 'parsley_member',
  Staff = 'parsley_staff',
  Superadmin = 'parsley_superadmin',
  // Weakest permissions
  User = 'parsley_user', // Strongest permissions
}
export const ALLOWED_ROLES: string[] = [
  Role.Staff,
  Role.Admin,
  Role.Superadmin,
];

export const AUTO_LOGOUT_MINUTES = 90;

export enum AppRoute {
  Basename = '/',
  Calendar = '/calendar',
  Coupons = '/coupons',
  DocumentReview = '/document-review',
  Members = '/members',
  Mint = '/mint',
  Plans = '/plans',
  Providers = '/providers',
  VisitPrep = '/visit-prep',
  Visits = '/visits',
}

export interface IRouteConfig {
  component: FC<any>;
  exact?: boolean;
  featureFlag?: string;
  hideAppHeader?: boolean;
  icon?: string;
  label?: string; // Routes with labels show in navbar
  path: string;
  restrictedTo?: PapiRole[];
}
export const ROUTES: IRouteConfig[] = [
  {
    component: VisitsPage,
    exact: true,
    icon: 'home',
    label: 'Visits',
    path: AppRoute.Visits,
  },
  {
    component: CalendarPage,
    exact: true,
    hideAppHeader: true,
    icon: 'calendar',
    label: 'Calendar',
    path: AppRoute.Calendar,
  },
  // Commented out as this is no longer
  // maintained and is causing big
  // performance issues in production
  // TODO: figure out if we want to fix it or
  // completely remove it
  // {
  //   component: VisitPrepPage,
  //   exact: true,
  //   featureFlag: 'mint',
  //   icon: 'solution',
  //   label: 'Visit Prep',
  //   path: AppRoute.VisitPrep,
  // },
  {
    component: DocumentReviewPage,
    exact: true,
    featureFlag: 'mint-doc-review',
    path: AppRoute.DocumentReview,
  },
  {
    component: MembersPage,
    exact: true,
    icon: 'team',
    label: 'Members',
    path: AppRoute.Members,
  },
  {
    component: MemberPage,
    path: `${AppRoute.Members}/:personID`,
  },
  {
    component: ProvidersPage,
    exact: true,
    icon: 'heart',
    label: 'Providers',
    path: AppRoute.Providers,
  },
  {
    component: ProviderPage,
    path: `${AppRoute.Providers}/:providerID`,
  },
  {
    component: PlansPage,
    exact: true,
    icon: 'database',
    label: 'Plans',
    path: AppRoute.Plans,
  },
  {
    component: CouponsPage,
    exact: true,
    icon: 'tags',
    label: 'Coupons',
    path: AppRoute.Coupons,
  },
];
