import { Context, Dispatch, SetStateAction, createContext } from 'react';

import { theme } from 'app/styles/theme';

interface ILayoutContext {
  contentWidth: number;
  isDrawerOpen?: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean | undefined>>;
}

let LayoutContext: Context<ILayoutContext>;

const createLayoutContext = (
  initialValue?: ILayoutContext
): Context<ILayoutContext> => {
  LayoutContext = createContext<ILayoutContext>(
    initialValue || {
      contentWidth: window.innerWidth - theme.layout.navbarSizeCollapsed,
      setDrawerOpen: () => {},
    }
  );
  return LayoutContext;
};

export { createLayoutContext, LayoutContext };
