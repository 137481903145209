import { DateTime } from 'luxon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AIconInline } from 'app/components/atoms/AIconInline/AIconInline';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { theme } from 'app/styles/theme';
import { ProductDetails_Fragment_product } from 'app/types/generated/ProductDetails_Fragment_product';
import { formatBillingPeriod } from 'app/utils/plan';

// Types & constants ////////////////////////////////
interface Props {
  product: ProductDetails_Fragment_product;
}

/** Displays a Parsley product's details */
const ProductDetails: FC<Props> = ({
  product: {
    deprecatedAt,
    description,
    displayName,
    plans,
    renewalPeriod,
    shopSummary,
  },
}) => {
  return (
    <>
      <Title>{displayName}</Title>

      <ASectionBtmMargin>
        <ATextLight lighter>
          {plans.length} plan{plans.length !== 1 && 's'}
        </ATextLight>
      </ASectionBtmMargin>

      <ASectionBtmMargin>
        <StatusIcon
          data-active={!deprecatedAt}
          type={deprecatedAt ? 'stop' : 'check-circle'}
        />
        <ALabel inline>
          {deprecatedAt
            ? `Deactivated ${DateTime.fromISO(deprecatedAt).toLocaleString(
                DateTime.DATE_SHORT
              )}`
            : 'Active'}
        </ALabel>
      </ASectionBtmMargin>

      <ASectionBtmMargin>
        <ALabel>Description</ALabel>
        <div>{description}</div>
      </ASectionBtmMargin>

      <ASectionBtmMargin>
        <ALabel>Renewal period</ALabel>
        <div>
          {renewalPeriod ? (
            formatBillingPeriod(renewalPeriod)
          ) : (
            <ATextLight lighter>–</ATextLight>
          )}
        </div>
      </ASectionBtmMargin>

      <ASectionBtmMargin>
        <ALabel>Shop summary</ALabel>
        <div>{shopSummary || <ATextLight lighter>–</ATextLight>}</div>
      </ASectionBtmMargin>
    </>
  );
};

// Styled components ////////////////////////////////
const StatusIcon = styled(AIconInline)<{ ['data-active']: boolean }>`
  &&& {
    bottom: 1px;
    color: ${(props) =>
      props['data-active'] ? theme.color.primary : theme.color.error};
    margin-right: ${theme.space.s};
  }
`;

const Title = styled.div`
  &&& {
    font-size: ${theme.font.size.l};
    font-weight: ${theme.font.weight.medium};
    width: calc(${theme.layout.drawerSizeDefault}px - 4rem);
  }
`;

export { ProductDetails };
