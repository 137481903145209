import React, { FC } from 'react';

import { APadding } from 'app/components/atoms/APadding/APadding';
import { MemberCreate } from 'app/components/organisms/MemberCreate/MemberCreate';

const MembersPage: FC = () => (
  <APadding type="page">
    <MemberCreate />
  </APadding>
);

export { MembersPage };
