import { useMutation, useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import { MutationUpdaterFn } from 'apollo-client';
import capitalize from 'lodash/capitalize';
import React, { FC } from 'react';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { ACheckboxField } from 'app/components/deprecated/ACheckboxField/ACheckboxField';
import { ADateField } from 'app/components/deprecated/ADateField/ADateField';
import {
  AForm,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { AInputField } from 'app/components/deprecated/AInputField/AInputField';
import { ASelectCenterField } from 'app/components/deprecated/ASelectCenterField/ASelectCenterField';
import { ASelectField } from 'app/components/deprecated/ASelectField/ASelectField';
import {
  CouponCreate_Mutation,
  CouponCreate_MutationVariables,
} from 'app/types/generated/CouponCreate_Mutation';
import { CouponCreate_Query } from 'app/types/generated/CouponCreate_Query';
import { PapiCouponApplication } from 'app/types/generated/globalTypes';
import { displayErrors } from 'app/utils/app';
import {
  BillingRestriction,
  createPlanRestrictionsList,
  createProductRestrictionsList,
  createRedeemByTime,
  getProductOptionsFromPlans,
} from 'app/utils/coupon';
import { formatFloat } from 'app/utils/string';
import { STATUS_MESSAGE } from 'constants/message';

import { COUPON_CREATE_MUTATION, COUPON_CREATE_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  hasButtonText?: boolean;
  mutationCacheUpdater?: MutationUpdaterFn<CouponCreate_Mutation>;
}

enum DiscountType {
  FixedAmount = 'amountOffCents',
  Percentage = 'percentOff',
}

/** Form to create a coupon */
const CouponCreate: FC<Props> = ({
  hasButtonText = true,
  mutationCacheUpdater,
}) => {
  const { data, loading: queryLoading } = useQuery<CouponCreate_Query>(
    COUPON_CREATE_QUERY
  );
  const [createCoupon, { loading: mutationLoading }] = useMutation<
    CouponCreate_Mutation,
    CouponCreate_MutationVariables
  >(COUPON_CREATE_MUTATION, { update: mutationCacheUpdater });

  const currCouponCodes =
    data?.coupons?.edges.map(({ node: coupon }) => coupon.slug.toLowerCase()) ||
    [];
  const plans = data?.plans?.edges || [];

  const onSave = async (
    input: { [K in keyof typeof fields]: any },
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      const productRestrictions = createProductRestrictionsList(
        input,
        plans.map(({ node }) => node)
      );
      const planRestrictions = createPlanRestrictionsList(
        input,
        plans.map(({ node }) => node),
        productRestrictions
      );

      const response = await createCoupon({
        variables: {
          input: {
            centerIDs: input.centers,
            description: input.description,
            discount: {
              [input.discountType]:
                input.discountType === DiscountType.FixedAmount
                  ? parseFloat(formatFloat(input.discount)) * 100
                  : parseFloat(input.discount),
            },
            duration: Object.values(PapiCouponApplication).includes(
              input.durationType
            )
              ? { apply: input.durationType }
              : { months: parseInt(input.durationMonths) },
            expires: input.expires && createRedeemByTime(input.expires),
            maxRedemptions: input.maxRedemptions
              ? parseInt(input.maxRedemptions)
              : undefined,
            planIDs: planRestrictions,
            products: productRestrictions,
            slug: input.slug.toUpperCase(),
          },
        },
      });
      if (response?.data) {
        message.success(STATUS_MESSAGE.couponCreate.success);
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.couponCreate.error.general);
      shouldFinish(false);
    }
  };

  return (
    <AForm
      button={{ icon: 'plus', type: 'primary' }}
      loading={queryLoading}
      onSave={onSave}
      openAs="modal"
      openText={hasButtonText && 'Create coupon'}
      saveText="Create"
      saving={mutationLoading}
      savingText="Creating"
      title="New coupon"
    >
      {(form) => (
        <>
          {/* Code & description */}
          <AFormFieldRow>
            <AInputField
              antdForm={form}
              label={fields.slug.label}
              name={fields.slug.name}
              options={{
                rules: [
                  {
                    validator: (ruleIgnore, value, callback) => {
                      if (
                        value &&
                        currCouponCodes.includes(value.trim().toLowerCase())
                      ) {
                        callback('Code already exists');
                      }
                      callback();
                    },
                  },
                ],
              }}
              required={fields.slug.required}
            />
          </AFormFieldRow>
          <AFormFieldRow>
            <AInputField
              antdForm={form}
              customWidth={{ percent: 70 }}
              label={fields.description.label}
              labelInfo={fields.description.labelInfo}
              name={fields.description.name}
              required={fields.description.required}
            />
          </AFormFieldRow>

          {/* Discount */}
          <AFormFieldRow>
            <ASelectField
              antdForm={form}
              customWidth={{ fixed: '140px' }}
              initialValue={DiscountType.FixedAmount}
              label={fields.discountType.label}
              name={fields.discountType.name}
              required={fields.discountType.required}
              selectOptions={[
                {
                  label: 'Fixed amount',
                  value: DiscountType.FixedAmount,
                },
                {
                  label: 'Percentage',
                  value: DiscountType.Percentage,
                },
              ]}
            />
            <AInputField
              antdForm={form}
              customWidth={{ fixed: '100px' }}
              label={fields.discount.label}
              name={fields.discount.name}
              number={
                form.getFieldValue(fields.discountType.name) ===
                DiscountType.Percentage
                  ? 'percent'
                  : 'dollar'
              }
              required={fields.discount.required}
            />
          </AFormFieldRow>

          {/* Duration */}
          <AFormFieldRow>
            <ASelectField
              antdForm={form}
              customWidth={{ fixed: '190px' }}
              label={fields.durationType.label}
              labelInfo={fields.durationType.labelInfo}
              name={fields.durationType.name}
              required={fields.durationType.required}
              selectOptions={[
                {
                  label: 'Once',
                  value: PapiCouponApplication.ONCE,
                },
                {
                  label: 'Plan duration',
                  value: PapiCouponApplication.FOREVER,
                },
                {
                  label: 'X number of months',
                  value: 'MONTHS',
                },
              ]}
            />
            {form.getFieldValue(fields.durationType.name) === 'MONTHS' && (
              <AInputField
                antdForm={form}
                customWidth={{ fixed: '80px' }}
                label={fields.durationMonths.label}
                name={fields.durationMonths.name}
                number="integer"
                required={fields.durationMonths.required}
              />
            )}
          </AFormFieldRow>

          {/* Expiration & max redemptions */}
          <AFormFieldRow>
            <ADateField
              antdForm={form}
              disableDate={{ past: true }}
              label={fields.expires.label}
              labelInfo={fields.expires.labelInfo}
              name={fields.expires.name}
              required={fields.expires.required}
            />
            <AInputField
              antdForm={form}
              label={fields.maxRedemptions.label}
              labelInfo={fields.maxRedemptions.labelInfo}
              name={fields.maxRedemptions.name}
              number="integer"
              required={fields.maxRedemptions.required}
            />
          </AFormFieldRow>

          {/* Center, product, plan restrictions */}
          <AFormFieldRow>
            <ASelectCenterField
              antdForm={form}
              customWidth={{ percent: 60 }}
              label={fields.centers.label}
              labelInfo={fields.centers.labelInfo}
              mode="multiple"
              name={fields.centers.name}
              placeholder="Select if restrictions apply"
              required={fields.centers.required}
            />
          </AFormFieldRow>
          <AFormFieldRow>
            <ASelectField
              antdForm={form}
              customWidth={{ percent: 90 }}
              label={fields.products.label}
              labelInfo={fields.products.labelInfo}
              mode="multiple"
              name={fields.products.name}
              placeholder="Select if restrictions apply"
              required={fields.products.required}
              selectOptions={getProductOptionsFromPlans(
                plans.map(({ node }) => node)
              )}
            />
          </AFormFieldRow>
          <AFormFieldRow>
            <ASelectField
              allowClear
              antdForm={form}
              customWidth={{ percent: 50 }}
              label={fields.billing.label}
              labelInfo={fields.billing.labelInfo}
              name={fields.billing.name}
              placeholder="Select if restrictions apply"
              required={fields.billing.required}
              selectOptions={Object.values(BillingRestriction).map(
                (billing) => ({
                  label: capitalize(billing).replace(/_/g, ' '),
                  value: billing,
                })
              )}
            />
          </AFormFieldRow>
          <AFormFieldRow>
            <ACheckboxField
              antdForm={form}
              initialValue={true}
              label={fields.waitlist.label}
              labelInfo={fields.waitlist.labelInfo}
              name={fields.waitlist.name}
              required={fields.waitlist.required}
              show={
                !!form.getFieldValue(fields.billing.name) ||
                (!!form.getFieldValue(fields.products.name) &&
                  form.getFieldValue(fields.products.name).length > 0)
              }
            />
          </AFormFieldRow>
        </>
      )}
    </AForm>
  );
};

/** Form field info */
const fields = {
  billing: {
    label: 'Billing period',
    labelInfo:
      'The coupon will only apply to plans billed either monthly or upfront/yearly. Leave blank if the coupon applies to all billing periods.',
    name: 'billing',
    required: false,
  },
  centers: {
    label: 'Centers',
    labelInfo:
      'The coupon will only apply to the centers listed. Leave blank if the coupon applies to all centers.',
    name: 'centers',
    required: false,
  },
  description: {
    label: 'Name/Description',
    labelInfo:
      "The coupon name/description will appear on members' invoices and receipts.",
    name: 'description',
    required: true,
  },
  discount: {
    label: 'Discount',
    name: 'discount',
    required: true,
  },
  discountType: {
    label: 'Discount type',
    name: 'discountType',
    required: true,
  },
  durationMonths: {
    label: 'Months',
    name: 'durationMonths',
    required: true,
  },
  durationType: {
    label: 'Duration',
    labelInfo:
      'The coupon can be applied once, every time the member is billed during their plan, or for X number of months.',
    name: 'durationType',
    required: true,
  },
  expires: {
    label: 'Redeem by',
    labelInfo: 'Coupon must be redeemed by the end of this date',
    name: 'expires',
    required: false,
  },
  maxRedemptions: {
    label: 'Max redemptions',
    labelInfo:
      'The total number of people that can redeem the coupon. The default is unlimited.',
    name: 'maxRedemptions',
    required: false,
  },
  products: {
    label: 'Products',
    labelInfo:
      'The coupon will only apply to the products listed. Leave blank if the coupon applies to all products.',
    name: 'products',
    required: false,
  },
  slug: {
    label: 'Coupon code',
    name: 'slug',
    required: true,
  },
  waitlist: {
    label: 'Applies to waitlist enrollment',
    labelInfo:
      'Whether the coupon can be used when joining a waitlist. The coupon will be applied when the member enrolls off the waitlist.',
    name: 'waitlist',
    required: false,
  },
};

export { CouponCreate, fields };
