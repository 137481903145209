import { Context, createContext } from 'react';

import { App_Query_viewer } from 'app/types/generated/App_Query';

export type IViewerContext = Partial<Omit<App_Query_viewer, '__typename'>>;

let ViewerContext: Context<IViewerContext>;

const createViewerContext = (
  initialValue?: IViewerContext
): Context<IViewerContext> => {
  ViewerContext = createContext<IViewerContext>(initialValue || {});
  return ViewerContext;
};

export { createViewerContext, ViewerContext };
