import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { startCase } from 'lodash';
import React, { FC } from 'react';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { AForm } from 'app/components/deprecated/AForm/AForm';
import { GET_AVAILABLE_INSURANCE_CHANGES_FOR_PERSON_QUERY } from 'app/components/organisms/MemberPlanCard/query';
import { PapiInsuranceType } from 'app/types/generated/globalTypes';
import { ListAvailableInsuranceChanges_Query_listAvailableInsuranceChanges } from 'app/types/generated/ListAvailableInsuranceChanges_Query';
import { PlanPurchaseExtend_Fragment_planPurchase } from 'app/types/generated/PlanPurchaseExtend_Fragment_planPurchase';
import { TransitionFrom_Mutation } from 'app/types/generated/TransitionFrom_Mutation';
import { TransitionTo_MutationVariables } from 'app/types/generated/TransitionTo_Mutation';
import { TransitionTo_Mutation } from 'app/types/generated/TransitionTo_Mutation';
import { displayErrors } from 'app/utils/app';
import { isCurrentPlan } from 'app/utils/plan';
import { STATUS_MESSAGE } from 'constants/message';

import {
  PLAN_PURCHASE_SWITCH_REFETCH,
  TRANSITION_FROM_INSURANCE_MUTATION,
  TRANSITION_TO_INSURANCE_MUTATION,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  plan: ListAvailableInsuranceChanges_Query_listAvailableInsuranceChanges;
  planPurchase: PlanPurchaseExtend_Fragment_planPurchase;
}

/** Form to extend a member's care plan by X number of months */
const PlanPurchaseSwitchInsurance: FC<Props> = ({ plan, planPurchase }) => {
  const mutation =
    plan.insuranceType === PapiInsuranceType.CASH_PAY
      ? TRANSITION_FROM_INSURANCE_MUTATION
      : TRANSITION_TO_INSURANCE_MUTATION;
  const [switchInsurance, { loading: mutationLoading }] = useMutation<
    TransitionTo_Mutation | TransitionFrom_Mutation,
    TransitionTo_MutationVariables
  >(mutation, {
    refetchQueries: [
      {
        query: PLAN_PURCHASE_SWITCH_REFETCH,
        variables: { personID: planPurchase.member.id },
      },
      {
        query: GET_AVAILABLE_INSURANCE_CHANGES_FOR_PERSON_QUERY,
        variables: { personID: planPurchase.member.id },
      },
    ],
  });

  const onSave = async (): Promise<void> => {
    try {
      const result = await switchInsurance({
        variables: {
          input: {
            personID: planPurchase.member.id,
            planID: plan.id,
            purchaseID: planPurchase.id,
          },
        },
      });
      if (result?.data) {
        message.success(STATUS_MESSAGE.planPurchaseSwitchInsurance.success);
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(
        err as any,
        STATUS_MESSAGE.planPurchaseExtend.error.general
      );
    }
  };

  return (
    <AForm
      cancelText="Cancel"
      disableOpen={
        !isCurrentPlan(planPurchase)
          ? { message: STATUS_MESSAGE.planPurchaseExtend.error.notCurrentPlan }
          : undefined
      }
      onSave={onSave}
      openAs="modal"
      openText={`Switch to ${startCase(plan.insuranceType).toLowerCase()}`}
      saveText="Confirm"
      saving={mutationLoading}
      title="Are you sure you want to change this plan?"
    >
      {() => {
        return (
          <AFormFieldRow height={'150px'}>
            When you change to/from the insurance plan, the membership price
            will change and there will be an adjustment made through Stripe.
            <br />
            <br /> Note: If the member is on a pause or an extension, please
            make sure the change goes through.
          </AFormFieldRow>
        );
      }}
    </AForm>
  );
};

export { PlanPurchaseSwitchInsurance };
