import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AIconInline } from 'app/components/atoms/AIconInline/AIconInline';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
export type APrepCardRowProps = {
  color?: string;
  href?: string;
  iconColor?: string;
  iconType: string;
  onClick?: () => void;
  text: string;
};

/** Displays icon + text */
const APrepCardRow: FC<APrepCardRowProps> = ({
  color,
  href,
  iconColor,
  iconType,
  onClick,
  text,
}) => {
  return (
    <Styles
      clickable={!!onClick || !!href}
      color={color}
      onClick={
        onClick
          ? (e) => {
              onClick();
              e.stopPropagation();
            }
          : undefined
      }
    >
      <AIconInline
        color={iconColor}
        marginPosition="right"
        marginSize="s"
        type={iconType}
      />
      {href ? (
        <a href={href} rel="noopener noreferrer" target="_blank">
          {text}
        </a>
      ) : (
        text
      )}
    </Styles>
  );
};

// Styled components ////////////////////////////////
const Styles = styled.div<{
  clickable?: boolean;
  color?: string;
}>`
  align-items: center;
  color: ${({ color }) => color};
  display: flex;
  padding: ${theme.space.xs} ${theme.space.m};

  ${({ clickable }) =>
    clickable &&
    `
    color: ${theme.color.jade};
    cursor: pointer;
    font-weight: ${theme.font.weight.semibold};
    &:hover {
      text-decoration: underline;
    }
    * {
      color: ${theme.color.jade} !important;
      &:hover {
        color: ${theme.color.jade} !important;
      }
    }
  `}
`;

export { APrepCardRow };
