/* eslint-disable */
import { colorTheme } from './color';

const zIndex = {
  navbar: 110,
  header: 100,
  drawer: 99,
  content: 90,
  calendar: {
    currentTimeBar: 5,
    cardMask: 4,
    days: 3,
    hours: 2,
    schedule: 2,
    container: 1,
  },
};

const boxShadow = {
  // General box shadows
  all: (color: string): string => `0 0 0 2px ${color}`,
  bottom: `0 1px 4px ${colorTheme.boxShadow}`,
  left: `-1px 0 4px ${colorTheme.boxShadow}`,
  right: `1px 0 4px ${colorTheme.boxShadow}`,
  // Domain specific
  calendar: '0px 4px 12px rgba(0, 0, 0, 0.25)',
  prepCard: '0 3px 6px rgba(89,89,89,0.08),0 3px 6px rgba(89,89,89,0.12);',
};

const opacity = {
  prepCardCompleted: 0.666,
};

export const layerTheme = {
  boxShadow,
  opacity,
  zIndex,
};
