import gql from 'graphql-tag';

import { ProviderHeader_Fragment_provider } from './fragment';

export const PROVIDER_HEADER_QUERY = gql`
  query ProviderHeader_Query($providerID: ID!) {
    provider: getProvider(id: $providerID) {
      ...ProviderHeader_Fragment_provider
    }
  }
  ${ProviderHeader_Fragment_provider}
`;
