import gql from 'graphql-tag';

export const PAYMENT_CARD_DELETE_MUTATION = gql`
  mutation PaymentCardDelete_Mutation($id: ID!) {
    deletePaymentCard(id: $id)
  }
`;

export const PAYMENT_CARD_DELETE_UPDATE_QUERY = gql`
  query PaymentCardDelete_UpdateQuery($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      paymentCards {
        edges {
          node {
            id
            deleted
          }
        }
      }
    }
  }
`;
