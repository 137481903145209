import { Checkbox, Form } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { theme } from 'app/styles/theme';
import { FIELD_VALIDATION } from 'constants/form';

// Types & constants ////////////////////////////////
interface Props extends CheckboxProps, AFormFieldWrapperProps {
  antdForm: WrappedFormUtils;
  initialValue?: boolean;
  label: string;
  labelInfo?: string;
  name: string;
  options?: GetFieldDecoratorOptions;
  required?: boolean;
}

/** Deprecated: Antd form field, being deprecated to use react-hook-form */
const ACheckboxField: FC<Props> = ({
  antdForm,
  customWidth,
  initialValue,
  label,
  labelInfo,
  name,
  options,
  required,
  show,
  ...props
}) => {
  return (
    <AFormFieldWrapper antd customWidth={customWidth} show={show}>
      <FormItemStyled>
        {antdForm.getFieldDecorator(name, {
          ...options,
          initialValue,
          rules: [
            ...(options?.rules || []),
            ...(required ? [FIELD_VALIDATION.required] : []),
          ],
          valuePropName: 'checked',
        })(
          <Checkbox {...props}>
            <Label>
              <ALabel black help={labelInfo} inline>
                {label}
              </ALabel>
            </Label>
          </Checkbox>
        )}
      </FormItemStyled>
    </AFormFieldWrapper>
  );
};

// Styled components ////////////////////////////////
const FormItemStyled = styled(Form.Item)`
  &&& {
    margin-bottom: 0;
    ${theme.layout.breakpointMixin.phoneOnly} {
      margin-bottom: ${theme.space.m};
    }
  }
`;

const Label = styled.div`
  bottom: ${theme.space.xxs};
  line-height: ${theme.font.lineHeight.base};
  position: relative;
`;

export { ACheckboxField };
