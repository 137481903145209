import React, { FC } from 'react';

import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  color?: string;
  height?: string;
  width?: string;
}

/** Icon representing a url/link */
const AIconEdit: FC<Props> = ({
  color = theme.color.jade,
  width = '16px',
  height = '14px',
}) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 14 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clip-rule="evenodd"
        d="M9.0068 0.180934C8.55439 -0.135844 7.93084 -0.0258959 7.61407 0.426509L0.264838 10.9223C0.104753 11.1509 0.0474975 11.436 0.106909 11.7087L0.838038 15.0646C0.949009 15.574 1.43267 15.9127 1.94927 15.8427L5.35284 15.382C5.62942 15.3445 5.87776 15.1932 6.03785 14.9646L13.3871 4.46881C13.7039 4.01641 13.5939 3.39286 13.1415 3.07608L9.0068 0.180934ZM7.83685 3.59523L8.67879 2.39281L11.1752 4.14081L10.3333 5.34323L7.83685 3.59523ZM6.6897 5.23354L2.15427 11.7108L2.5957 13.737L4.65067 13.4588L9.1861 6.98153L6.6897 5.23354Z"
        fill={color}
        fill-rule="evenodd"
      />
    </svg>
  );
};

export { AIconEdit };
