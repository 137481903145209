import gql from 'graphql-tag';

import { ProviderPage_Fragment_provider } from 'app/components/pages/ProviderPage/fragment';

export const ProviderUpdate_Fragment_providerCenter = gql`
  fragment ProviderUpdate_Fragment_providerCenter on PapiProviderCenter {
    center {
      id
    }
    takingNewPatients
  }
`;

export const ProviderUpdate_Fragment_provider = gql`
  fragment ProviderUpdate_Fragment_provider on PapiProvider {
    id
    acuityID
    acuityInstance
    biologicalSex
    mdhqId
    centers {
      ...ProviderUpdate_Fragment_providerCenter
    }
    payerCredentials {
      payerID
      centerID
    }
    products {
      displayName
      type
    }
    email
    firstName
    helpScoutID
    lastName
    middleName
    professionalSuffix
    sanityID
    type
    employmentType
    ...ProviderPage_Fragment_provider
  }
  ${ProviderPage_Fragment_provider}
  ${ProviderUpdate_Fragment_providerCenter}
`;
