import gql from 'graphql-tag';

import { VisitCard_Fragment } from 'app/components/organisms/VisitCard/fragment';

export const UNBLOCK_VISIT_MUTATION = gql`
  mutation UnblockVisit_Mutation($appointmentID: ID!) {
    unblockAppointment(appointmentID: $appointmentID) {
      ...VisitCard_Fragment
    }
  }
  ${VisitCard_Fragment}
`;
