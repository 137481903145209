import React, { FC } from 'react';
import styled from 'styled-components/macro';

// Types & constants ////////////////////////////////
interface Props {
  color?: string;
  onClick?: () => void;
  position?: 'left' | 'right' | 'up' | 'down';
}

/** Icon for carets ">", "<", facing right, left, up, and down */
const AIconCaret: FC<Props> = ({
  color = 'black',
  onClick,
  position = 'right',
}) => {
  return (
    <Container onClick={onClick} position={position}>
      {position === 'right' && (
        <svg
          aria-label="icon: caret_right"
          fill="none"
          height="12"
          viewBox="0 0 7 12"
          width="12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            d="M0.300097 2.1C-0.0865019 1.7134 -0.0865018 1.0866 0.300098 0.7C0.686697 0.313401 1.3135 0.313401 1.7001 0.7L7.0001 6L1.7001 11.3C1.3135 11.6866 0.686697 11.6866 0.300098 11.3C-0.0865017 10.9134 -0.0865015 10.2866 0.300098 9.9L4.2001 6L0.300097 2.1Z"
            fill={color}
            fillRule="evenodd"
          />
        </svg>
      )}

      {position === 'left' && (
        <svg
          aria-label="icon: caret_left"
          fill="none"
          height="12"
          viewBox="0 0 7 12"
          width="12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            d="M6.7 2.1C7.0866 1.7134 7.0866 1.0866 6.7 0.7C6.3134 0.313401 5.6866 0.313401 5.3 0.7L0 6L5.3 11.3C5.6866 11.6866 6.3134 11.6866 6.7 11.3C7.0866 10.9134 7.0866 10.2866 6.7 9.9L2.8 6L6.7 2.1Z"
            fill={color}
            fillRule="evenodd"
          />
        </svg>
      )}

      {(position === 'down' || position === 'up') && (
        <svg
          aria-label={`icon: caret_${position}`}
          fill="none"
          height="12"
          viewBox="0 0 12 7"
          width="12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            d="M11.3 0.300098C10.9134 -0.0865017 10.2866 -0.0865015 9.9 0.300098L6 4.2001L2.1 0.300097C1.7134 -0.0865019 1.0866 -0.0865018 0.7 0.300098C0.313401 0.686697 0.313401 1.3135 0.7 1.7001L6 7.0001L11.3 1.7001C11.6866 1.3135 11.6866 0.686697 11.3 0.300098Z"
            fill={color}
            fillRule="evenodd"
          />
        </svg>
      )}
    </Container>
  );
};

// Styled components ////////////////////////////////
const Container = styled.div<Pick<Props, 'onClick' | 'position'>>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  transform: ${({ position }) =>
    position === 'up' ? 'rotate(180deg)' : 'initial'};
`;

export { AIconCaret };
