import { Form, Input } from 'antd';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/lib/form/Form';
import { InputProps } from 'antd/lib/input';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { FC } from 'react';

import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';
import { FIELD_VALIDATION } from 'constants/form';

// Types & constants ////////////////////////////////
interface Props extends InputProps, AFormFieldWrapperProps {
  antdForm: WrappedFormUtils;
  initialValue?: string;
  label?: string;
  name: string;
  options?: GetFieldDecoratorOptions;
}

/** Deprecated: Antd form field, being deprecated to use react-hook-form */
const APhoneField: FC<Props> = ({
  antdForm,
  customWidth,
  initialValue,
  label,
  name,
  options,
  required,
  show,
  ...props
}) => {
  const parsedPhone = initialValue && parsePhoneNumberFromString(initialValue);

  return (
    <AFormFieldWrapper antd customWidth={customWidth} show={show}>
      <Form.Item colon={false} label={label}>
        {antdForm.getFieldDecorator(name, {
          ...options,
          getValueFromEvent: (e) => new AsYouType().input(e.target.value),
          initialValue: parsedPhone
            ? parsedPhone.formatInternational()
            : initialValue,
          rules: [
            ...(options?.rules || []),
            ...(required ? [FIELD_VALIDATION.required] : []),
            {
              validator: (ruleIgnore, value, callback) => {
                if (
                  value &&
                  (!parsePhoneNumberFromString(value) ||
                    !parsePhoneNumberFromString(value)!.isValid())
                ) {
                  callback('Enter a valid phone number with country code');
                }
                callback();
              },
            },
          ],
          validateTrigger: [
            'onBlur',
            // only validate onChange if there is already an error
            ...(antdForm.getFieldError(name) ? ['onChange'] : []),
          ],
        })(
          <Input
            onClick={() =>
              !antdForm.getFieldValue(name) &&
              antdForm.setFieldsValue({ [name]: '+1 ' })
            }
            {...props}
          />
        )}
      </Form.Item>
    </AFormFieldWrapper>
  );
};

export { APhoneField };
