import gql from 'graphql-tag';

import { MemberPlanCard_Fragment_planPurchase } from 'app/components/organisms/MemberPlanCard/fragment';

export const MEMBER_MEMBERSHIP_QUERY = gql`
  query MemberMembership_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      center {
        id
        timezone
      }
      allPlans {
        edges {
          node {
            id
            endDateIncludingExtensions
            endTime
            plan {
              id
              insuranceType
              product {
                type
              }
            }
            startTime
            ...MemberPlanCard_Fragment_planPurchase
          }
        }
      }
      joinedPracticeDate
    }
  }
  ${MemberPlanCard_Fragment_planPurchase}
`;
