import gql from 'graphql-tag';

import { PlanDetails_Fragment_plan } from 'app/components/organisms/PlanDetails/fragment';
import { ProductsList_Fragment_plan } from 'app/components/organisms/ProductsList/fragment';

export const PLAN_CREATE_MUTATION = gql`
  mutation PlanCreate_Mutation($input: PapiCreatePlanInput!) {
    createPlan(input: $input) {
      product {
        type
      }
      ...ProductsList_Fragment_plan
    }
  }
  ${ProductsList_Fragment_plan}
`;

export const PLAN_UPDATE_MUTATION = gql`
  mutation PlanUpdate_Mutation($input: PapiUpdatePlanJoinableCentersInput!) {
    updatePlanJoinableCenters(input: $input) {
      id
      joinCentersAllowed {
        id
        abbreviatedName
        isVirtual
      }
      ...PlanDetails_Fragment_plan
      ...ProductsList_Fragment_plan
    }
  }
  ${PlanDetails_Fragment_plan}
  ${ProductsList_Fragment_plan}
`;
