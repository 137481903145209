import camelCase from 'lodash.camelcase';
import startCase from 'lodash.startcase';

export const toTitleCase = (str: string): string => startCase(camelCase(str));
export const toCamelCase = (str: string): string => camelCase(str);
export const toPascalCase = (str: string): string =>
  startCase(camelCase(str)).replace(/ /g, '');

export const digitsOnly = (str?: string | null): string =>
  str ? str.replace(/\D+/g, '') : '';

export const formatFloat = (str?: string | null): string => {
  if (!str) {
    return '';
  }
  const split = str.split('.');
  const [beforeFirstDecimal, ...afterFirstDecimal] = split;
  if (split.length > 1) {
    return `${
      beforeFirstDecimal.replace(/[^\d.]/g, '') || 0
    }.${afterFirstDecimal.join('').replace(/[^\d.]/g, '')}`;
  }
  return str.replace(/[^\d.]/g, '');
};

export const formatPhone = (
  currPhone?: string | null,
  prevPhone?: string | null
): string => {
  let formattedPhone = '';
  const rawPhone = digitsOnly(currPhone);
  const phoneLength = rawPhone.length;
  const backspace =
    !currPhone || (prevPhone && currPhone.length < prevPhone.length);

  if (phoneLength) {
    formattedPhone += `(${rawPhone.slice(0, 3)}`;
  }
  if (phoneLength >= 3) {
    formattedPhone += `) ${rawPhone.slice(3, 6)}`;
  }
  if (phoneLength >= 6) {
    formattedPhone += `-${rawPhone.slice(6, 10)}`;
  }
  // If user deleted a non-digit character, erase the non-digit character
  if (backspace && phoneLength === digitsOnly(prevPhone).length) {
    formattedPhone = formattedPhone.trim().slice(0, -1);
  }
  return formattedPhone;
};

/**
 * Returns whether every input word is part of some word in the test string, e.g.
 * input "comp care vir" tested against string "Complete Care Y1 Virtual" => true
 * Useful for antd Select.filterOption
 */
export const isEveryWordIncluded = (
  input: string,
  testString: string,
  testStringSeparator = ' '
): boolean => {
  const testWords = testString.toLowerCase().split(testStringSeparator);
  return input
    .toLowerCase()
    .split(' ')
    .every(
      (inputWord) =>
        testWords.find((testWord) => testWord.includes(inputWord)) !== undefined
    );
};
