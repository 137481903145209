import { DateTime, Interval } from 'luxon';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { ACurrentTimeBar } from 'app/components/atoms/ACurrentTimeBar/ACurrentTimeBar';
import { CalendarCard } from 'app/components/organisms/CalendarCard/CalendarCard';
import { theme } from 'app/styles/theme';
import { CellInfo, Grid } from 'app/types/calendar';
import { CalendarPage_Visits_Query_provider_visits } from 'app/types/generated/CalendarPage_Visits_Query';
import { AVAILABLE_END_HOUR, AVAILABLE_START_HOUR } from 'constants/calendar';

// Types & constants ////////////////////////////////
interface Props {
  dateRangeToCells(range: Interval): CellInfo[];
  grid: Grid;
  onVisitClick?: (visitCardPos: ClientRect) => (visitId: string) => void;
  selectedVisitId?: string;
  /** @param timeMarkerRefresh how often the current time marker position is refreshed (minutes) */
  timeMarkerRefresh?: number;
  timezone: string;
  visits: CalendarPage_Visits_Query_provider_visits[];
}

/** schedule component that places card on calendar */
const CalendarSchedule: FC<Props> = ({
  dateRangeToCells,
  grid,
  onVisitClick,
  selectedVisitId,
  timeMarkerRefresh = 5,
  timezone,
  visits,
}) => {
  const [timeMarker, setTimeMarker] = useState(
    DateTime.local().setZone(timezone)
  );

  useEffect(() => {
    // Rerender current time indicator every 5 minutes (or when component rerenders)
    const refreshTimeMarkerTimer = setTimeout(
      () => setTimeMarker(DateTime.local().setZone(timezone)),
      timeMarkerRefresh * 60 * 1000
    );
    return () => {
      clearTimeout(refreshTimeMarkerTimer);
    };
    /***
     * IMPORTANT: timeMarker must be in the dependency array so that when the marker
     * position is updated, the timer resets and the position will be updated again
     */
  }, [timeMarker, timeMarkerRefresh, timezone]);

  useEffect(() => {
    setTimeMarker(DateTime.local().setZone(timezone));
  }, [timezone]);

  return (
    <div>
      {visits.map((visit, visitIndex) => {
        return (
          <span key={`CalendarSchedule_visit${visitIndex}`}>
            {dateRangeToCells(
              Interval.fromDateTimes(
                DateTime.fromISO(visit.startTime, { zone: timezone }),
                DateTime.fromISO(visit.endTime, { zone: timezone })
              )
            ).map((cell, cellIndex) => {
              const visitCardPos = grid.getRectFromCell(cell);
              const { height, left, top, width } = visitCardPos;
              return (
                <CellContainer
                  key={`CalendarSchedule_card${cellIndex}`}
                  left={left}
                  top={top}
                >
                  <CalendarCard
                    height={height - 1}
                    inactive={
                      DateTime.fromISO(visit.endTime) < DateTime.local()
                    }
                    isSelected={visit.id === selectedVisitId}
                    onClick={onVisitClick?.(visitCardPos)}
                    timezone={timezone}
                    visit={visit}
                    width={width - 1}
                  />
                </CellContainer>
              );
            })}
          </span>
        );
      })}

      {timeMarker.hour >= AVAILABLE_START_HOUR &&
        timeMarker.hour < AVAILABLE_END_HOUR &&
        dateRangeToCells(Interval.fromDateTimes(timeMarker, timeMarker)).map(
          (cell) => {
            const { left, top, width } = grid.getRectFromCell(cell);
            return (
              <CurrentTimeContainer
                data-testid="CalendarSchedule_now"
                key="CalendarSchedule_now"
                left={left}
                top={top}
                width={width}
              >
                <CurrentTimeBar>
                  <ACurrentTimeBar />
                </CurrentTimeBar>
              </CurrentTimeContainer>
            );
          }
        )}
    </div>
  );
};

// Styled components ////////////////////////////////
const CellContainer = styled.div<{ left: number; top: number }>`
  left: ${({ left }) => left}px;
  position: absolute;
  top: ${({ top }) => top + 1}px;
  z-index: ${theme.layer.zIndex.calendar.schedule};
`;

const CurrentTimeBar = styled.div`
  left: -5px;
  position: relative;
`;
const CurrentTimeContainer = styled(CellContainer)<{ width: number }>`
  pointer-events: none;
  position: relative;
  width: ${({ width }) => width + 5}px;
`;

export { CalendarSchedule };
