import gql from 'graphql-tag';

import { MemberPaymentCard_Fragment_card } from 'app/components/organisms/MemberPaymentCard/fragment';

export const MEMBER_PAYMENT_QUERY = gql`
  query MemberPayment_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      paymentCards {
        edges {
          node {
            id
            deleted
            ...MemberPaymentCard_Fragment_card
          }
        }
      }
    }
  }
  ${MemberPaymentCard_Fragment_card}
`;
