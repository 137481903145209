import { useQuery } from '@apollo/react-hooks';
import { Dropdown, Menu } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import capitalize from 'lodash/capitalize';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { ADropdownMenuItem } from 'app/components/atoms/ADropdownMenuItem/ADropdownMenuItem';
import { AIconClickable } from 'app/components/atoms/AIconClickable/AIconClickable';
import { AIconThirdParty } from 'app/components/atoms/AIconThirdParty/AIconThirdParty';
import { ATable } from 'app/components/atoms/ATable/ATable';
import { ATag } from 'app/components/atoms/ATag/ATag';
import { InvoiceUpdateStatus } from 'app/components/organisms/InvoiceUpdateStatus/InvoiceUpdateStatus';
import { theme } from 'app/styles/theme';
import { PapiPaymentStatus } from 'app/types/generated/globalTypes';
import {
  MemberInvoicesTable_Query,
  MemberInvoicesTable_QueryVariables,
  MemberInvoicesTable_Query_invoices,
} from 'app/types/generated/MemberInvoicesTable_Query';
import { DateFormat } from 'constants/datetime';
import { STRIPE_URL } from 'constants/env';

import { MEMBER_INVOICES_TABLE_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  personID: string;
}

interface InvoiceItem extends MemberInvoicesTable_Query_invoices {
  date: DateTime;
  statuses: Array<PapiPaymentStatus | CustomStatus>;
}

enum CustomStatus {
  PartialRefund = 'PARTIAL_REFUND',
  PaymentFailed = 'PAYMENT_FAILED',
  Refunded = 'REFUNDED',
}

// https://github.com/parsleyhealth/sage/blob/d0a0ab5d6ab2bba6c986403ab55c7f0ff225b5c1/data-api/proto/src/main/proto/com/parsleyhealth/data/proto/data/StripeStatuses.proto#L8
const statusFilters = [
  PapiPaymentStatus.DRAFT,
  PapiPaymentStatus.OPEN,
  PapiPaymentStatus.PAID,
  PapiPaymentStatus.UNCOLLECTIBLE,
  PapiPaymentStatus.VOID,
  PapiPaymentStatus.IN_ARREARS,
  CustomStatus.PartialRefund,
  CustomStatus.Refunded,
  CustomStatus.PaymentFailed,
];

const statusInfo: InvoiceItem['statuses'] = [
  CustomStatus.PartialRefund,
  CustomStatus.Refunded,
];
const statusWarnings: InvoiceItem['statuses'] = [
  PapiPaymentStatus.OPEN,
  PapiPaymentStatus.PENDING,
  PapiPaymentStatus.UNKNOWN,
];
const statusErrors: InvoiceItem['statuses'] = [
  CustomStatus.PaymentFailed,
  PapiPaymentStatus.IN_ARREARS,
  PapiPaymentStatus.UNCOLLECTIBLE,
];
const statusVoid: InvoiceItem['statuses'] = [PapiPaymentStatus.VOID];

const isDesktop = window.innerWidth > theme.layout.breakpoint.tabletLandscape;
const isPhone = window.innerWidth < theme.layout.breakpoint.tabletPortrait;

const columns: Array<ColumnProps<InvoiceItem>> = compact([
  {
    dataIndex: 'date',
    defaultSortOrder: 'descend' as const,
    render: (date: InvoiceItem['date']) =>
      isPhone
        ? date.toLocal().toFormat(DateFormat.Shortest)
        : date.toLocal().toLocaleString(DateTime.DATE_MED),
    sorter: (a: InvoiceItem, b: InvoiceItem) =>
      a.date.valueOf() - b.date.valueOf(),
    title: 'Date',
  },
  !isPhone && {
    dataIndex: 'number',
    render: (number: InvoiceItem['number']) => number,
    title: 'Number',
  },
  {
    key: 'amount',
    render: (invoice: InvoiceItem) =>
      new Intl.NumberFormat(undefined, {
        currency: invoice.currency,
        style: 'currency',
      }).format(invoice.total / 100),
    sorter: (a: InvoiceItem, b: InvoiceItem) => a.total - b.total,
    title: 'Amount',
  },
  {
    filters: sortBy(
      statusFilters.map((status) => ({
        text:
          status === PapiPaymentStatus.IN_ARREARS
            ? 'Past due' // Match Stripe statuses so it's understandable to MX
            : capitalize(status).replace(/_/g, ' '),
        value: status,
      })),
      'text'
    ),
    key: 'statuses',
    onFilter: (value: PapiPaymentStatus, invoice: InvoiceItem) =>
      invoice.statuses.includes(value),
    render: (invoice: InvoiceItem) => (
      <>
        {invoice.statuses.map((status) => (
          <StatusTag
            color={
              statusErrors.includes(status)
                ? theme.color.error
                : statusWarnings.includes(status)
                ? theme.color.warning
                : statusInfo.includes(status)
                ? theme.color.textLighter
                : statusVoid.includes(status)
                ? theme.color.darkGrey
                : undefined
            }
            key={`Invoice${invoice.id}_status${status}`}
          >
            {status === CustomStatus.PaymentFailed
              ? invoice
                  .latestCharge!.failureCode!.replace(/_/g, ' ')
                  .toUpperCase()
              : status === PapiPaymentStatus.IN_ARREARS
              ? 'PAST DUE' // Match Stripe statuses so it's understandable to MX
              : status.replace(/_/g, ' ')}
          </StatusTag>
        ))}
      </>
    ),
    title: 'Status',
  },
  isDesktop && {
    dataIndex: 'description',
    render: (description: InvoiceItem['description']) => description,
    title: 'Notes',
    width: '20%',
  },
  {
    key: 'actions',
    render: (invoice: InvoiceItem) =>
      !isPhone ? (
        <>
          {invoice.isLegacy ? (
            <UpdateLegacyInvoiceIcon data-testid="MemberInvoicesTable_updateInvoiceStatus">
              <InvoiceUpdateStatus button invoice={invoice} />
            </UpdateLegacyInvoiceIcon>
          ) : (
            <StripeLink
              data-testid="MemberInvoicesTable_stripeLink"
              href={`${STRIPE_URL}/invoices/${invoice.id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <AIconThirdParty alt="Open in Stripe" service="stripe" />
            </StripeLink>
          )}
          {invoice.hostedInvoiceURL && (
            <a
              href={invoice.hostedInvoiceURL}
              rel="noopener noreferrer"
              target="_blank"
            >
              <PdfIcon
                data-testid="MemberInvoicesTable_pdfLink"
                type="file-pdf"
              />
            </a>
          )}
        </>
      ) : (
        <Dropdown
          overlay={
            <Menu>
              {invoice.isLegacy ? (
                <ADropdownMenuItem>
                  <InvoiceUpdateStatus invoice={invoice} />
                </ADropdownMenuItem>
              ) : (
                <ADropdownMenuItem>
                  <DropdownLink
                    href={`${STRIPE_URL}/invoices/${invoice.id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Open in Stripe
                  </DropdownLink>
                </ADropdownMenuItem>
              )}
              {invoice.hostedInvoiceURL && (
                <ADropdownMenuItem>
                  <DropdownLink
                    href={invoice.hostedInvoiceURL}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    View PDF
                  </DropdownLink>
                </ADropdownMenuItem>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          <DropdownIcon
            data-isphone={isPhone}
            type={isPhone ? 'more' : 'setting'}
          />
        </Dropdown>
      ),
    title: isPhone ? '' : 'Actions',
  },
]);

/** Table of member's invoices with links */
const MemberInvoicesTable: FC<Props> = ({ personID }) => {
  const { data, loading } = useQuery<
    MemberInvoicesTable_Query,
    MemberInvoicesTable_QueryVariables
  >(MEMBER_INVOICES_TABLE_QUERY, { variables: { personID } });
  return (
    <ATable<InvoiceItem>
      columns={columns}
      dataSource={(data?.invoices || []).map((invoice) => ({
        ...invoice,
        date: DateTime.fromISO(invoice.createdAt || invoice.periodStart),
        statuses: compact([
          invoice.status,
          invoice.latestCharge &&
            (invoice.latestCharge.refunded
              ? CustomStatus.Refunded
              : invoice.latestCharge.amountRefunded &&
                invoice.latestCharge.amountRefunded > 0 &&
                CustomStatus.PartialRefund),
          invoice.latestCharge?.failureCode && CustomStatus.PaymentFailed,
        ]),
      }))}
      loading={loading}
      pagination={{
        hideOnSinglePage: true,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} invoices`,
      }}
      rowKey={(invoice) => invoice.id}
    />
  );
};

// Styled components ////////////////////////////////
const DropdownIcon = styled(AIconClickable)<{ ['data-isphone']: boolean }>`
  &&& {
    color: ${(props) =>
      props['data-isphone'] ? theme.color.primary : 'inherit'};
    font-size: ${(props) =>
      props['data-isphone'] ? theme.font.size.xl : theme.font.size.l};
    margin-right: ${(props) => (props['data-isphone'] ? 0 : '0.85rem')};
    vertical-align: text-bottom;
  }
`;

const DropdownLink = styled.a`
  color: inherit !important;
  :hover {
    color: inherit !important;
  }
`;

const PdfIcon = styled(AIconClickable)`
  &&& {
    font-size: ${theme.font.size.l};
  }
`;

const StatusTag = styled(ATag)`
  &&& {
    margin: ${theme.space.xxs} ${theme.space.s} ${theme.space.xxs} 0;
  }
`;

const StripeLink = styled.a`
  margin-right: ${theme.space.m};
  vertical-align: sub;
`;

const UpdateLegacyInvoiceIcon = styled.div`
  display: inline-block;
  margin-right: 0.9rem;
`;
export { MemberInvoicesTable };
