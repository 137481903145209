export const API_URL = process.env.REACT_APP_API_URL!;
export const ENVIRONMENT = process.env.NODE_ENV!;
export const NAME = process.env.REACT_APP_NAME!;
export const VERSION = process.env.REACT_APP_VERSION!;
export const COMMIT_SHA = process.env.REACT_APP_COMMIT_SHA!;
export const MY_PARSLEY_URL = process.env.REACT_APP_MY_PARSLEY_URL!;
export const DD_CLIENT_TOKEN = process.env.REACT_APP_DD_CLIENT_TOKEN!;
export const DD_APPLICATION_ID = process.env.REACT_APP_DD_APPLICATION_ID!;
export const DD_APPLICATION_NAME = process.env.REACT_APP_NAME!;

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY!;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID!;

export const LOGROCKET_MY_PARSLEY_URL = process.env
  .REACT_APP_LOGROCKET_MY_PARSLEY_URL!;
export const LOGROCKET_PROJECT_ID = process.env.REACT_APP_LOGROCKET_PROJECT_ID;
export const LAUNCHDARKLY_CLIENT_ID = process.env
  .REACT_APP_LAUNCHDARKLY_CLIENT_ID!;

export const STATIC_FILES_URL = process.env.REACT_APP_STATIC_FILES_URL!;

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY!;
export const STRIPE_URL = process.env.REACT_APP_STRIPE_URL!;

export const HUBSPOT_URL = process.env.REACT_APP_HUBSPOT_URL!;

export const DEMO_USER_EMAIL = process.env.REACT_APP_DEMO_USER_EMAIL!;
