import { Divider, Icon, List, Tooltip } from 'antd';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AFlexbox } from 'app/components/atoms/AFlexbox/AFlexbox';
import { ATextError } from 'app/components/atoms/ATextError/ATextError';
import { MemberStatusTags } from 'app/components/organisms/MemberStatusTags/MemberStatusTags';
import { theme } from 'app/styles/theme';
import {
  PapiAppointmentCategory,
  PapiAppointmentFormat,
} from 'app/types/generated/globalTypes';
import { VisitsList_Query_visits } from 'app/types/generated/VisitsList_Query';
import { getCenterDisplayNameWithIcon } from 'app/utils/center';
import { formatMemberName } from 'app/utils/member';
import { AppRoute } from 'constants/app';

// Types & constants ////////////////////////////////
interface Props {
  onMemberClick?: (memberID: string) => void;
  routeToProfile?: boolean;
  visit: VisitsList_Query_visits;
}

/** Visit info, including member, provider, time, etc */
const VisitListing: FC<Props> = ({ onMemberClick, routeToProfile, visit }) => {
  const {
    category,
    center,
    endTime,
    format,
    member,
    provider,
    startTime,
  } = visit;
  const timezone = center ? center.timezone : undefined;

  const handleMemberOnClick = (): void => {
    if (member && onMemberClick) {
      onMemberClick(member.id);
    }
  };

  return (
    <List.Item.Meta
      description={
        <Details>
          {center ? (
            getCenterDisplayNameWithIcon(center)
          ) : (
            <ATextError>No center info</ATextError>
          )}
          <Divider type="vertical" />
          {/* Wed. 2/28 */}
          {DateTime.fromISO(startTime, { zone: timezone }).toFormat('ccc. M/d')}
          <Divider type="vertical" />
          <Tooltip
            title={
              !center || center.timezone === DateTime.local().zoneName
                ? undefined
                : removeRepeatMeridiem(
                    `${DateTime.fromISO(startTime).toFormat(
                      'ccc. M/d'
                    )} | ${formatTime(startTime)} – ${formatTime(
                      endTime
                    )} ${DateTime.fromISO(startTime).toFormat('ZZZZ')}`
                  )
            }
          >
            {removeRepeatMeridiem(
              `${formatTime(startTime, timezone)} – ${formatTime(
                endTime,
                timezone
              )}`
            )}{' '}
            {!center && DateTime.fromISO(startTime).toFormat('ZZZZ')}
          </Tooltip>
          <Divider type="vertical" />
          {provider ? (
            provider.displayName
          ) : (
            <ATextError>No provider info</ATextError>
          )}
        </Details>
      }
      title={
        <>
          {member ? (
            <MemberName data-private onClick={handleMemberOnClick}>
              {routeToProfile ? (
                <Link to={`${AppRoute.Members}/${member.id}`}>
                  {formatMemberName(member)}
                </Link>
              ) : (
                formatMemberName(member)
              )}
            </MemberName>
          ) : (
            <ATextError>No member info </ATextError>
          )}

          {category!.toLowerCase().includes('initial') && (
            <Tooltip title="Initial visit">
              <VisitIcon data-testid="VisitListing_initialVisit" type="smile" />
            </Tooltip>
          )}
          {category === PapiAppointmentCategory.CONSULTATION && (
            <Tooltip title="Consult">
              <VisitIcon data-testid="VisitListing_consult" type="message" />
            </Tooltip>
          )}
          {(category === PapiAppointmentCategory.LAB ||
            category === PapiAppointmentCategory.LAB_EXTERNAL) && (
            <Tooltip title="Lab">
              <VisitIcon data-testid="VisitListing_lab" type="medicine-box" />
            </Tooltip>
          )}
          {format === PapiAppointmentFormat.VIRTUAL && (
            <Tooltip title="Virtual visit">
              <VisitIcon
                data-testid="VisitListing_virtualVisit"
                type="laptop"
              />
            </Tooltip>
          )}
          {member && <MemberStatusTags member={member} />}
        </>
      }
    />
  );
};

// Styled components ////////////////////////////////
const Details = styled(AFlexbox)`
  &&& {
    color: ${theme.color.textLight};
    * {
      color: ${theme.color.textLight};
    }
  }
`;

const MemberName = styled.span`
  cursor: pointer;
  margin-right: ${theme.space.s};
  transition-duration: ${theme.animation.transitionDuration};
  transition-property: color;
  transition-timing-function: ${theme.animation.transitionTiming};
  :hover {
    color: ${theme.color.primary};
  }
`;

const VisitIcon = styled(Icon)`
  &&& {
    bottom: ${theme.space.xs};
    margin-right: ${theme.space.s};
    position: relative;
  }
`;

// Helpers ////////////////////////////////
/** Returns time with meridiem and without ":00", e.g. 8am */
const formatTime = (time: string, timezone?: string): string =>
  DateTime.fromISO(time, {
    zone: timezone,
  })
    .toFormat('h:mma') // 8:00AM
    .replace(':00', '')
    .toLowerCase();

const removeRepeatMeridiem = (time: string): string =>
  time.replace(/am(.*)am/, '$1am').replace(/pm(.*)pm/, '$1pm');

export { VisitListing };
