import gql from 'graphql-tag';

export const ProvidersTable_Fragment_provider = gql`
  fragment ProvidersTable_Fragment_provider on PapiProvider {
    id
    centers {
      center {
        id
        abbreviatedName
        isVirtual
      }
      takingNewPatients
    }
    products {
      abbreviation
      displayName
      type
    }
    pod {
      id
      name
    }
    deleted
    displayName
    type
    currentPanelSize
    targetPanelSize
  }
`;
