import { useQuery } from '@apollo/react-hooks';
import { Skeleton } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AFlexRowToColumn } from 'app/components/atoms/AFlexRowToColumn/AFlexRowToColumn';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { theme } from 'app/styles/theme';
import {
  MemberThirdPartyIDsUpdate_Query,
  MemberThirdPartyIDsUpdate_QueryVariables,
} from 'app/types/generated/MemberThirdPartyIDsUpdate_Query';

import { MEMBER_THIRD_PARTY_IDS_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  personID: string;
}

/** Show member third party service IDs */
const MemberThirdPartyIDs: FC<Props> = ({ personID }) => {
  const { data, loading } = useQuery<
    MemberThirdPartyIDsUpdate_Query,
    MemberThirdPartyIDsUpdate_QueryVariables
  >(MEMBER_THIRD_PARTY_IDS_QUERY, { variables: { personID } });

  if (loading || !data || !data.member) {
    return (
      <SkeletonStyled
        active
        paragraph={{
          rows: 4,
          width: ['30%', '60%'],
        }}
        title={false}
      />
    );
  }

  const {
    member: { helpScoutID },
  } = data;

  return (
    <AFlexRowToColumn>
      {helpScoutID && (
        <>
          <Label>Helpscout ID:</Label>
          {helpScoutID ? (
            <span>{helpScoutID}</span>
          ) : (
            <ATextLight>No ID</ATextLight>
          )}
        </>
      )}
    </AFlexRowToColumn>
  );
};

// Styled components ////////////////////////////////
const SkeletonStyled = styled(Skeleton)`
  &&& {
    margin: ${theme.space.s} 0 ${theme.space.l} 0;
  }
`;

const Label = styled.span`
  margin-right: ${theme.space.xs};
`;

export { MemberThirdPartyIDs };
