import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

/** Wrap a section to get consistent spacing */
export const ASectionBtmMargin = styled.div<{
  margin?: keyof typeof theme.space;
}>`
  margin-bottom: ${({ margin }) => theme.space[margin || 'm']};
`;
