import { useQuery } from '@apollo/react-hooks';
import { Select, Typography } from 'antd';
import groupBy from 'lodash/groupBy';
import { DateTime } from 'luxon';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { DocumentReviewCardGroups } from 'app/components/arabella/DocumentReviewCardGroups/DocumentReviewCardGroups';
import { DOCUMENT_REVIEW_LIST_QUERY_PERSONS } from 'app/components/arabella/DocumentReviewList/query';
import { SearchSelect } from 'app/components/arabella/SearchSelect/SearchSelect';
import { DocumentReviewByDateGroupType } from 'app/components/arabella/utils/types';
import { AIconLabeled } from 'app/components/atoms/AIconLabeled/AIconLabeled';
import { APrepCard } from 'app/components/atoms/APrepCard/APrepCard';
import { APrepCardGroupHeader } from 'app/components/atoms/APrepCardGroupHeader/APrepCardGroupHeader';
import { ASelectCenter } from 'app/components/atoms/ASelectCenter/ASelectCenter';
import { DocumentReviewList_Query_GetDocs_docs } from 'app/types/generated/DocumentReviewList_Query_GetDocs';
import {
  DocumentReviewList_Query_Persons,
  DocumentReviewList_Query_PersonsVariables,
} from 'app/types/generated/DocumentReviewList_Query_Persons';
import { PapiInboxItemSourceCategory } from 'app/types/generated/globalTypes';
import { DateFormat } from 'constants/datetime';
import { DOCUMENT_SOURCE_ATTRS } from 'constants/documentReview';

import { mapDocToDocReviewType, mapPersonToMember } from './utils';

// Types & constants ////////////////////////////////
export interface DocumentReviewListProps {
  docs?: DocumentReviewList_Query_GetDocs_docs[];
  initialLoading: boolean;
  loading: boolean;
  onCentersChange: (value: string[]) => void;
  onDocSelect: (value: DocumentReviewList_Query_GetDocs_docs) => void;
  onMemberSelect: (value: string) => void;
  onSourceChange: (value?: PapiInboxItemSourceCategory) => void;
  selectedCenters?: string[];
  selectedDoc?: DocumentReviewList_Query_GetDocs_docs;
  selectedMember?: string;
  selectedSource?: PapiInboxItemSourceCategory;
}

const DOCUMENT_SOURCES = [
  PapiInboxItemSourceCategory.EFAX,
  PapiInboxItemSourceCategory.LABORATORY,
  PapiInboxItemSourceCategory.UPLOAD,
];

const DATE_GROUP_TODAY = 'Today';
const DATE_GROUP_YESTERDAY = 'Yesterday';
const DATE_GROUP_2_DAYS_AGO = '2 days ago';

/** List of documents that need review + filters */
export const DocumentReviewList: FC<DocumentReviewListProps> = ({
  docs,
  initialLoading,
  loading,
  onCentersChange,
  onDocSelect,
  onMemberSelect,
  onSourceChange,
  selectedCenters,
  selectedDoc,
  selectedMember,
  selectedSource,
}) => {
  const [membersSearch, setMembersSearch] = useState('');

  const { data: membersData } = useQuery<
    DocumentReviewList_Query_Persons,
    DocumentReviewList_Query_PersonsVariables
  >(DOCUMENT_REVIEW_LIST_QUERY_PERSONS, {
    errorPolicy: 'all',
    skip: membersSearch === '',
    variables: { search: membersSearch },
  });
  const searchedMembers = membersData ? membersData.persons : [];

  let docGroups: DocumentReviewByDateGroupType[] = [];
  if (docs) {
    const docsByDateGroup = groupBy(docs, (doc) => toDaysAgo(doc.createdAt));
    const daysArray = Object.keys(docsByDateGroup).sort(
      (a, b) => parseInt(b) - parseInt(a)
    );

    docGroups = daysArray.map((days) => ({
      count: docsByDateGroup[days].length,
      date: DateTime.local()
        .minus({ days: parseInt(days) })
        .toFormat(DateFormat.DocumentReviewGroup),
      dateGroup: toDaysAgoLabel(parseInt(days)),
      docs: docsByDateGroup[days].map(mapDocToDocReviewType),
    }));
  }

  const MemberSelect = (
    <StyledSearchSelect
      allowClear
      disabled={initialLoading}
      dropdownClassName="searchResults"
      onChange={(value) => onMemberSelect(value as string)}
      onSearchPhraseProvide={setMembersSearch}
      placeholder="Filter members"
      renderOptions={(person) => {
        const member = mapPersonToMember(person);
        return (
          <Select.Option key={member.id} value={member.id}>
            {member.fullName} ({member.birthDay})
          </Select.Option>
        );
      }}
      searchResults={searchedMembers}
      value={selectedMember}
    />
  );

  const SourceSelect = (
    <StyledSelect
      defaultValue={selectedSource || ''}
      disabled={initialLoading}
      onChange={(value) =>
        onSourceChange((value as PapiInboxItemSourceCategory) || undefined)
      }
    >
      <Select.Option key={0} value="">
        <AIconLabeled label="All Sources" type="pull-request" />
      </Select.Option>
      {DOCUMENT_SOURCES.map((value) => (
        <Select.Option key={value} value={value}>
          <AIconLabeled
            label={DOCUMENT_SOURCE_ATTRS[value].label}
            type={DOCUMENT_SOURCE_ATTRS[value].icon}
          />
        </Select.Option>
      ))}
    </StyledSelect>
  );

  const DocumentReviewCards = (
    <StyledCardsContainer>
      {loading ? (
        <LoadingCards />
      ) : (
        <DocumentReviewCardGroups
          docsByDateGroup={docGroups}
          onCardClick={(docId: string) => {
            if (!docs) return;
            const doc = docs.find((doc) => doc?.id === docId);
            if (!doc) return;
            onDocSelect(doc);
          }}
          selectedDocId={selectedDoc?.id}
        />
      )}
    </StyledCardsContainer>
  );

  return (
    <div>
      {MemberSelect}
      <StyledFilterTextSeparator>
        <Typography.Text>or</Typography.Text>
      </StyledFilterTextSeparator>
      <ASelectCenter
        disabled={initialLoading}
        mode="multiple"
        onChange={onCentersChange}
        placeholder="Filter centers"
        value={selectedCenters}
      />
      <StyledTextWrapper>
        <Typography.Text>Filter Source</Typography.Text>
      </StyledTextWrapper>
      {SourceSelect}
      {DocumentReviewCards}
    </div>
  );
};

// Styled components ////////////////////////////////
const StyledSelect = styled(Select)`
  &&& {
    margin-bottom: 10px;
    width: 100%;
  }
`;

const StyledTextWrapper = styled.div`
  margin-bottom: 10px;
`;

const StyledCardsContainer = styled.div`
  margin-top: 10px;
`;

const StyledLoadingCard = styled(APrepCard)`
  &&& {
    padding: 20px;
  }
`;
const StyledFilterTextSeparator = styled.div`
  margin-bottom: 5px;
  margin-top: -5px;
`;

const StyledSearchSelect = styled(SearchSelect)`
  &&& {
    margin-bottom: 10px;
    width: 100%;
  }
`;

// Helpers ////////////////////////////////
const LoadingCards = (): JSX.Element => (
  <>
    <APrepCardGroupHeader title={DATE_GROUP_TODAY} />
    <StyledLoadingCard loading />
    <APrepCardGroupHeader title={DATE_GROUP_YESTERDAY} />
    <StyledLoadingCard loading />
    <APrepCardGroupHeader title={DATE_GROUP_2_DAYS_AGO} />
    <StyledLoadingCard loading />
  </>
);

const toDaysAgo = (s: string): number => {
  const diffNow = DateTime.fromISO(s).diffNow('days').toObject();
  return Math.floor(diffNow?.days ? diffNow.days * -1 : 0);
};

const toDaysAgoLabel = (days: number): string => {
  switch (days) {
    case 0:
      return 'Today';
    case 1:
      return 'Yesterday';
    default:
      return `${days} days ago`;
  }
};
