import gql from 'graphql-tag';

import { MemberPlanCard_Fragment_planPurchase } from 'app/components/organisms/MemberPlanCard/fragment';
import { MemberStatusTags_Fragment_member } from 'app/components/organisms/MemberStatusTags/fragment';

export const PLAN_PURCHASE_SWITCH_REFETCH = gql`
  query PlanPurchase_Switch_Refetch($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      allPlans {
        edges {
          node {
            ...MemberPlanCard_Fragment_planPurchase
          }
        }
      }
      ...MemberStatusTags_Fragment_member
    }
  }
  ${MemberPlanCard_Fragment_planPurchase}
  ${MemberStatusTags_Fragment_member}
`;

export const TRANSITION_TO_INSURANCE_MUTATION = gql`
  mutation TransitionTo_Mutation($input: PapiTransitionPlanPurchaseInput!) {
    transitionToInsurancePurchase(input: $input) {
      id
    }
  }
`;

export const TRANSITION_FROM_INSURANCE_MUTATION = gql`
  mutation TransitionFrom_Mutation($input: PapiTransitionPlanPurchaseInput!) {
    transitionFromInsurancePurchase(input: $input) {
      id
    }
  }
`;
