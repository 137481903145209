import { List as AntdList } from 'antd';
import React, { Component, ReactNode } from 'react';
import styled from 'styled-components/macro';

// Types & constants ////////////////////////////////
interface Props<T> {
  data: T[] | readonly T[] | null;
  header?: ReactNode;
  loading?: boolean;
  renderItem: (item: T) => ReactNode;
  title?: string;
}

/** General list */
class AList<T> extends Component<Props<T>> {
  render = (): ReactNode => {
    const { data, header, loading, renderItem, title } = this.props;
    return (
      <Styles>
        <AntdList<T>
          bordered
          dataSource={data ? [...data] : []}
          header={
            (title || header) && (
              <>
                {title && <Title>{title}</Title>}
                {header}
              </>
            )
          }
          loading={loading}
          renderItem={(item) =>
            item && <AntdList.Item>{renderItem(item)}</AntdList.Item>
          }
        />
      </Styles>
    );
  };
}

// Styled components ////////////////////////////////
const Styles = styled.div`
  background: white;
  width: 100%;
  .ant-list {
    border: none;
    min-width: fit-content;
    width: 100%;
  }
`;

const Title = styled.h4`
  margin-bottom: 0;
`;

export { AList };
