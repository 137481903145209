import gql from 'graphql-tag';

import { MemberProviders_Fragment_provider } from './fragment';

export const MEMBER_PROVIDERS_QUERY = gql`
  query MemberProviders_Query($id: ID!) {
    member: getPerson(id: $id) {
      id
      firstName
      center {
        id
      }
      currentPlan {
        plan {
          consumerType
        }
      }
      assignedCoach {
        ...MemberProviders_Fragment_provider
      }
      assignedDoctor {
        assignedCareManager {
          ...MemberProviders_Fragment_provider
        }
        ...MemberProviders_Fragment_provider
      }
    }
  }
  ${MemberProviders_Fragment_provider}
`;

export const GET_PERSON_PARTNER_POD_ID = gql`
  query getPersonPartnerPodId($id: ID!) {
    getPersonV2(id: $id) {
      partner {
        clinicalPod {
          podID
        }
      }
    }
  }
`;

export const ASSIGN_PROVIDER_MUTATION = gql`
  mutation AssignProvider_Mutation($input: PapiUpdatePersonInput!) {
    updatePapiPerson(input: $input) {
      id
    }
  }
`;
