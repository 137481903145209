import { Modal } from 'antd';
import firebase from 'firebase/app';
import 'firebase/auth';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { AUTO_LOGOUT_MINUTES } from 'constants/app';

// Types & constants ////////////////////////////////
interface Props {
  /** @param autoLogoutTime user will be logged out X minutes after popup appears if no response. Default = 1 */
  autoLogoutTime?: number;
  onConfirmActive?: () => void;
  show: boolean;
}

/** Modal asks user to confirm they're still using the app. Logs them out if no response */
const LogoutReminder: FC<Props> = ({
  autoLogoutTime = 1,
  onConfirmActive,
  show,
}) => {
  const [userActive, setUserActive] = useState(false);
  const logout = (): Promise<void> => firebase.auth().signOut();

  useEffect(() => {
    let logoutTimer: NodeJS.Timeout | undefined;
    if (show && !userActive) {
      // Set timer when modal is shown. Don't set if user confirmed activity
      logoutTimer = setTimeout(() => logout(), autoLogoutTime * 60 * 1000);
    }
    return () => {
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
    };
  }, [autoLogoutTime, show, userActive]);

  return (
    <Modal
      cancelText="Log out"
      closable={false}
      okText="I'm still here!"
      onCancel={logout}
      onOk={() => {
        setUserActive(true);
        onConfirmActive?.();
      }}
      visible={show}
    >
      <Title>Are you still using Dr. Parsley?</Title>
      To protect our members' health information, Dr. Parsley users are{' '}
      <ATextBold>automatically logged out</ATextBold> after{' '}
      {AUTO_LOGOUT_MINUTES} minutes of inactivity. If you're still using the
      app, let us know you're here.
    </Modal>
  );
};

// Styled components ////////////////////////////////
const Title = styled.h5``;

// Helpers ////////////////////////////////

export { LogoutReminder };
