import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Icon, Modal } from 'antd';
import capitalize from 'lodash/capitalize';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { AIconInline } from 'app/components/atoms/AIconInline/AIconInline';
import { AInputConfirmation } from 'app/components/atoms/AInputConfirmation/AInputConfirmation';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { APadding } from 'app/components/atoms/APadding/APadding';
import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { theme } from 'app/styles/theme';
import {
  DeletePapiPersonFirebaseProvider_Mutation,
  DeletePapiPersonFirebaseProvider_MutationVariables,
} from 'app/types/generated/DeletePapiPersonFirebaseProvider_Mutation';
import {
  DeletePapiPersonFirebaseProvider_Query,
  DeletePapiPersonFirebaseProvider_QueryVariables,
} from 'app/types/generated/DeletePapiPersonFirebaseProvider_Query';
import { displayErrors } from 'app/utils/app';
import { formatMemberName } from 'app/utils/member';
import { STATUS_MESSAGE } from 'constants/message';

import {
  FIREBASE_PROVIDER_DELETE_MUTATION,
  FIREBASE_PROVIDER_DELETE_QUERY,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  personID: string;
}

/** Delete a member's Firebase Identity Provider ("google.com") */
const FirebaseProviderDelete: FC<Props> = ({ personID }) => {
  const formHook = useForm();
  const [showForm, setShowForm] = useState(false);

  const [getMember, { data: queryData, loading: queryLoading }] = useLazyQuery<
    DeletePapiPersonFirebaseProvider_Query,
    DeletePapiPersonFirebaseProvider_QueryVariables
  >(FIREBASE_PROVIDER_DELETE_QUERY);
  const member = queryData?.member;

  const [deleteFirebaseProvider, { loading: mutationLoading }] = useMutation<
    DeletePapiPersonFirebaseProvider_Mutation,
    DeletePapiPersonFirebaseProvider_MutationVariables
  >(FIREBASE_PROVIDER_DELETE_MUTATION);

  const onOpen = (): void => {
    try {
      getMember({ variables: { personID } });
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.memberDelete.error.get);
    }
    setShowForm(true);
  };

  const onSave = async (): Promise<void> => {
    try {
      const { data } = await deleteFirebaseProvider({
        variables: { personID },
      });
      setShowForm(false);
      Modal.success({
        content: data?.deletePapiPersonFirebaseProvider
          ? () => {
              const result = data.deletePapiPersonFirebaseProvider;
              return (
                result === personID && (
                  <DeleteResult key={result}>
                    <AIconInline
                      color={result ? theme.color.success : theme.color.error}
                      marginPosition="right"
                      type={result ? 'check-circle' : 'close-circle'}
                    />
                    <ATextBold>
                      {capitalize('Successfully deleted Firebase Provider')}
                    </ATextBold>
                    {!result &&
                      `Error detected when attempting to delete Firebase Provider. See PAPI logs`}
                  </DeleteResult>
                )
              );
            }
          : STATUS_MESSAGE.firebaseProviderDelete.error.general,
        icon: <DeleteCompleteIcon type="user-delete" />,
        okText: 'Ok',
        title: STATUS_MESSAGE.firebaseProviderDelete.success,
      });
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.firebaseProviderDelete.error.general);
    }
  };

  return (
    <>
      <div onClick={onOpen}>Delete member's Google Auth Provider</div>

      <Modal
        confirmLoading={mutationLoading}
        footer={queryLoading || !member ? null : undefined}
        okText={mutationLoading ? 'Deleting...' : 'Delete'}
        onCancel={() => setShowForm(false)}
        onOk={formHook.handleSubmit(onSave)}
        title="Delete Google.com Firebase Auth Provider"
        visible={showForm}
      >
        {queryLoading || !member ? (
          <ALoadingStyled centered />
        ) : (
          <APadding type="modal">
            <ASectionBtmMargin>
              Google Authentication Provider for the Firebase account of{' '}
              <ATextBold>
                {formatMemberName(member)} ( {member.email} )
              </ATextBold>{' '}
              will be unlinked!
            </ASectionBtmMargin>
            <ASectionBtmMargin>
              <ATextBold>This action cannot be undone.</ATextBold> Enter DELETE
              to continue.
            </ASectionBtmMargin>
            <AFormFieldRow>
              <AInputConfirmation
                confirmationText="delete"
                formHook={formHook}
                name={'delete' + personID}
              />
            </AFormFieldRow>
          </APadding>
        )}
      </Modal>
    </>
  );
};

// Styled components ///////////////////////////////
const ALoadingStyled = styled(ALoading)`
  &&& {
    padding: ${theme.space.xxl} 0 ${theme.space.xxxl} 0;
  }
`;

const DeleteCompleteIcon = styled(Icon)`
  &&& {
    color: ${theme.color.success} !important;
    margin-right: ${theme.space.m};
  }
`;

const DeleteResult = styled.div`
  margin-top: ${theme.space.m};
`;

export { FirebaseProviderDelete };
