import { useQuery } from '@apollo/react-hooks';
import partition from 'lodash/partition';
import React, { FC } from 'react';

import {
  ASelectField,
  ASelectFieldProps,
} from 'app/components/deprecated/ASelectField/ASelectField';
import { ASelectProductField_Query } from 'app/types/generated/ASelectProductField_Query';
import { PapiProductType } from 'app/types/generated/globalTypes';
import { compareLabel } from 'app/utils/sort';

import { A_SELECT_PRODUCT_FIELD_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props extends Omit<ASelectFieldProps, 'selectOptions'> {
  /** @param enabledProducts Only enable included product types. Ineligible types will be disabled */
  enabledProducts?: PapiProductType[];
  initialValue?: PapiProductType;
}

/** Deprecated: Antd form field, being deprecated to use react-hook-form */
const ASelectProductField: FC<Props> = ({
  enabledProducts,
  label = 'Product',
  ...props
}) => {
  const { data, loading } = useQuery<ASelectProductField_Query>(
    A_SELECT_PRODUCT_FIELD_QUERY
  );

  const [enabled, disabled] = partition(
    data?.products || [],
    (product) => !enabledProducts || enabledProducts.includes(product.type)
  );

  return (
    <ASelectField
      filterOption={(input, option) => {
        const productWords = option.props.title?.toLowerCase().split('_');
        if (!productWords) {
          return false;
        }

        // Check that every input word is part of some word in the product name,
        // e.g. "comp care vir" would include the result "Complete Care Y1 Virtual"
        return input
          .toLowerCase()
          .split(' ')
          .every((inputWord) =>
            productWords.find((productWord) => productWord.includes(inputWord))
          );
      }}
      label={label}
      loading={loading}
      placeholder="Select or search"
      selectOptions={[
        ...enabled
          .map((product) => ({
            label: product.displayName,
            title: product.displayName,
            value: product.type,
          }))
          .sort(compareLabel),
        ...disabled
          .map((product) => ({
            disabled: true,
            label: product.displayName,
            title: product.displayName,
            value: product.type,
          }))
          .sort(compareLabel),
      ]}
      showSearch
      {...props}
    />
  );
};

export { ASelectProductField };
