import { useQuery } from '@apollo/react-hooks';
import { Checkbox, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import fromPairs from 'lodash/fromPairs';
import React, {
  FC,
  ReactNode,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  FieldError,
  FormContextValues,
  ValidationOptions,
} from 'react-hook-form';
import styled from 'styled-components/macro';

import { AFlexbox } from 'app/components/atoms/AFlexbox/AFlexbox';
import { AFormFieldError } from 'app/components/atoms/AFormFieldError/AFormFieldError';
import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { theme } from 'app/styles/theme';
import {
  ASelectProviderInsuranceCredentials_Query,
  ASelectProviderInsuranceCredentials_Query_payer_centers,
} from 'app/types/generated/ASelectProviderInsuranceCredentials_Query';
import { getPayerCenterDisplayName } from 'app/utils/payerCenter';
import { compareLabel } from 'app/utils/sort';
import { FORM_VALIDATION } from 'constants/form';

import { A_SELECT_INSURANCE_PAYER_CENTERS_QUERY } from './query';

export interface IASelectProviderInsuranceCredentialsValue {
  centerID: string;
  id?: string;
  label?: string;
  payerID: string;
}

export interface ASelectProviderInsuranceCredentialsProps
  extends Omit<SelectProps<string[]>, 'defaultValue' | 'value'>,
    AFormFieldWrapperProps {
  defaultValue?: IASelectProviderInsuranceCredentialsValue[];
  formHook?: FormContextValues<any>;
  label?: string;
  labelInfo?: string;
  name?: string;
  required?: boolean;
  /** @param rules with react-hook-form only */
  rules?: ValidationOptions;
}

const ASelectProviderInsuranceCredentials: FC<ASelectProviderInsuranceCredentialsProps> = ({
  customWidth,
  defaultValue,
  formHook,
  label,
  labelInfo,
  name,
  required,
  rules,
  show,
  ...props
}) => {
  const [selectedPayerCenters, setSelectedPayerCenters] = useState<
    IASelectProviderInsuranceCredentialsValue[]
  >(defaultValue || []);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const errorMessage =
    name &&
    formHook?.errors[name] &&
    (formHook?.errors[name] as FieldError).message;

  const { data, loading } = useQuery<ASelectProviderInsuranceCredentials_Query>(
    A_SELECT_INSURANCE_PAYER_CENTERS_QUERY,
    { variables: { filterAcceptedPayers: false } }
  );

  const allCenters = useMemo(() => data?.centers || [], [data]);
  const allPayers = useMemo(() => data?.payers || [], [data]);

  const payerCentersWithLabel = useMemo(() => {
    if (allCenters && allPayers && data) {
      return fromPairs(
        (data?.payer_centers || []).map((payerCenter) => [
          `${payerCenter.insurancePayerID}-${payerCenter.centerID}`,
          {
            ...payerCenter,
            label: getPayerCenterDisplayName(
              allCenters,
              allPayers,
              payerCenter.centerID,
              payerCenter.insurancePayerID
            ),
          },
        ])
      );
    }
  }, [allCenters, allPayers, data]);

  useEffect(() => {
    // Register form field once
    if (formHook && name) {
      formHook.register(
        { name },
        { ...(required ? FORM_VALIDATION.requiredArray : {}), ...rules }
      );
      return () => formHook.unregister(name);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Add payer center labels to value in state once api call finishes
  useEffect(() => {
    if (payerCentersWithLabel) {
      setSelectedPayerCenters((currSelectedPayerCenters) =>
        currSelectedPayerCenters.map((payerCenter) => ({
          ...payerCenter,
          id: `${payerCenter.payerID}-${payerCenter.centerID}`,
          label:
            payerCentersWithLabel[
              `${payerCenter.payerID}-${payerCenter.centerID}`
            ]?.label || '????',
        }))
      );
    }
  }, [payerCentersWithLabel]);

  // Update form value if selected provides change
  useEffect(() => {
    if (!formHook || !name) {
      return;
    }
    const valueInForm = formHook.getValues()[name];
    const valueInState = selectedPayerCenters;
    formHook.setValue(name, selectedPayerCenters);
    // Don't validate before user interacts with field, i.e. while we're setting up states
    if (valueInForm && valueInForm.length !== valueInState.length) {
      formHook.triggerValidation(name);
    }
  }, [selectedPayerCenters]); // eslint-disable-line react-hooks/exhaustive-deps

  // displays the options in the dropdown when it is opened
  const renderSelectOption = useCallback(
    (
      payerCenter: ASelectProviderInsuranceCredentials_Query_payer_centers & {
        label: string;
      }
    ): ReactNode => {
      const { centerID, insurancePayerID: payerID, label } = payerCenter;
      const id = `${payerID}-${centerID}`;
      const payerCenterCurrentlySelected = selectedPayerCenters.find(
        (p) => p.id === id
      );

      return (
        <SelectOptionStyled
          key={id}
          // @ts-ignore Arbitrary props allowed on <Select.Option> so that <Select optionLabelProp> works, but this isn't reflected in Antd typedefs
          tag={<SelectTag>{label}</SelectTag>}
          title={label} // Used for searching
        >
          <OptionContainer>
            <CheckboxStyled
              checked={!!payerCenterCurrentlySelected}
              onClick={() =>
                setSelectedPayerCenters((currSelectedPayerCenters) =>
                  currSelectedPayerCenters.find(
                    (selectedPC) => selectedPC.id === id
                  )
                    ? currSelectedPayerCenters.filter(
                        (selectedPC) => selectedPC.id !== id
                      )
                    : [
                        ...currSelectedPayerCenters,
                        {
                          centerID: payerCenter.centerID,
                          id,
                          label,
                          payerID: payerCenter.insurancePayerID,
                        },
                      ]
                )
              }
            >
              <PayerCenterLabel>{label}</PayerCenterLabel>
            </CheckboxStyled>
          </OptionContainer>
        </SelectOptionStyled>
      );
    },
    [selectedPayerCenters]
  );

  return (
    <AFormFieldWrapper customWidth={customWidth} show={show}>
      {label && (
        <ALabel black help={labelInfo} htmlFor={name} required={required}>
          {label}
        </ALabel>
      )}

      <SelectWrapper error={!!errorMessage}>
        <Select<string[]> // The select field's value is an array of selected payer centers
          autoClearSearchValue
          dropdownClassName="ASelectProviderCenter"
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          id={name}
          loading={loading || props.loading}
          menuItemSelectedIcon={<></>}
          mode="multiple"
          onBlur={() => {
            setDropdownOpen(false);
            if (formHook && name) {
              formHook.triggerValidation(name);
            }
          }}
          onFocus={() => setDropdownOpen(true)}
          open={dropdownOpen}
          optionFilterProp="title"
          optionLabelProp="tag"
          placeholder="Select"
          showSearch
          value={
            // only display values once the labels have been computed
            selectedPayerCenters.find((e) => e.label) === undefined
              ? undefined
              : selectedPayerCenters
                  .sort(compareLabel)
                  .map((payerCenter) => payerCenter.label || '???')
          }
          {...props}
        >
          {payerCentersWithLabel &&
            Object.values(payerCentersWithLabel)
              .sort(compareLabel)
              .map((payerCenter) => renderSelectOption(payerCenter))}
        </Select>
      </SelectWrapper>

      <AFormFieldError show={!!errorMessage}>{errorMessage}</AFormFieldError>
    </AFormFieldWrapper>
  );
};

// Styled components ////////////////////////////////
const PayerCenterLabel = styled.div`
  bottom: 1px;
  position: relative;
`;

const CheckboxStyled = styled(Checkbox)`
  &&& {
    flex-grow: 1;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectOptionStyled = styled(Select.Option)`
  &&& {
    width: 100%;
  }
`;

const SelectTag = styled(AFlexbox)`
  &&& {
    i {
      top: 1px;
    }
  }
`;

const SelectWrapper = styled.div<{ error: boolean }>`
  div[role='combobox']:not([disabled]) {
    border-color: ${({ error }) =>
      error ? theme.color.error : theme.color.border};
  }

  div[role='combobox']:not([disabled]):active,
  div[role='combobox']:not([disabled]):focus,
  div[role='combobox']:not([disabled]):hover {
    border-color: ${({ error }) =>
      error ? theme.color.error : theme.color.primary} !important;
    box-shadow: ${({ error }) =>
      error
        ? `${theme.layer.boxShadow.all(theme.color.error + '40')}`
        : ''} !important;
  }

  .ant-select {
    width: 100%;
  }
  .ant-select-selection__choice__remove {
    display: none;
  }
  .ant-select-selection--multiple .ant-select-selection__choice {
    padding: 0 ${theme.space.s};
  }
`;

export { ASelectProviderInsuranceCredentials };
