import React, { ReactNode } from 'react';

import { AFlexbox } from 'app/components/atoms/AFlexbox/AFlexbox';
import { AIconInline } from 'app/components/atoms/AIconInline/AIconInline';
import { ASelectProviderCenter_Query_centers } from 'app/types/generated/ASelectProviderCenter_Query';
import { CenterName_Fragment } from 'app/types/generated/CenterName_Fragment';
import { US_STATES } from 'constants/form';

/** Shows center's abbreviated display name + 'Virtual' prefix. Use for selection */
export const getCenterDisplayName = (
  center: Omit<CenterName_Fragment, '__typename' | 'id'>
): string => `${center.isVirtual ? 'Virtual ' : ''}${center.abbreviatedName}`;

/** Shows center's abbreviated display name + virtual icon. Use for short display */
export const getCenterDisplayNameWithIcon = (
  center: Omit<CenterName_Fragment, '__typename' | 'id'>
): ReactNode => (
  <AFlexbox>
    {center.abbreviatedName}
    {center.isVirtual && <AIconInline marginPosition="left" type="laptop" />}
  </AFlexbox>
);

/**
 * Use a center's search index for filtering since it contains more information
 * than the label, e.g. SF's label = 'SF', while search index = 'SF San Francisco
 * California'. A user might search by 'san fran'. Add to <Select.Option title>
 */
export const getCenterSearchIndex = (
  center: Pick<
    ASelectProviderCenter_Query_centers,
    'abbreviatedName' | 'city' | 'isVirtual' | 'state'
  >
): string =>
  `${getCenterDisplayName(center)} ${
    center.isVirtual ? '' : center.city + ' ' // Virtual center city = New York--don't add to search index
  }${US_STATES.find((state) => state.value === center.state)!.label}`;
