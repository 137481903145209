import { Card as AntdCard, Dropdown, Icon, Menu } from 'antd';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

import { ADropdownMenuItem } from 'app/components/atoms/ADropdownMenuItem/ADropdownMenuItem';
import { AIconClickable } from 'app/components/atoms/AIconClickable/AIconClickable';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  borderColor?: string;
  children: ReactNode;
  dropdownItems?: ReactNode[];
  error?: boolean;
  /** @param status Renders opposite title */
  status?: string;
  title?: ReactNode;
  upperRightText?: string;
}

/* General card with kebab dropdown */
const ACard: FC<Props> = ({
  borderColor,
  children,
  dropdownItems,
  error,
  status,
  title,
  upperRightText,
}) => (
  <CardStyled data-bordercolor={borderColor}>
    {title && (
      <Header>
        <Title error={error} hasStatus={!!status}>
          {error && <ErrorIcon data-testid="ACard_error" type="warning" />}
          {title}
        </Title>
        {status && <Status>{status}</Status>}
      </Header>
    )}

    {dropdownItems && dropdownItems.length > 0 && (
      <Dropdown
        overlay={
          <Menu>
            {dropdownItems.map((item, i) => (
              <ADropdownMenuItem key={i}>{item}</ADropdownMenuItem>
            ))}
          </Menu>
        }
        placement="topCenter"
        trigger={['click']}
      >
        <DropdownIcon data-testid="ACard_dropdown" primary type="more" />
      </Dropdown>
    )}

    {upperRightText && <UpperRightText>{upperRightText}</UpperRightText>}

    {children}
  </CardStyled>
);

// Styled components ////////////////////////////////
const CardStyled = styled(AntdCard)<{ ['data-bordercolor']?: string }>`
  &&& {
    border-color: ${(props) => props['data-bordercolor']};
    border-radius: ${theme.space.s};
    padding: 0 ${theme.space.xs};
    .ant-card-body {
      padding: ${theme.space.m};
    }
  }
`;

const DropdownIcon = styled(AIconClickable)`
  &&& {
    font-size: ${theme.font.size.l};
    position: absolute;
    right: ${theme.space.xxs};
    top: ${theme.space.s};
  }
`;

const ErrorIcon = styled(Icon)`
  &&& {
    bottom: ${theme.space.xxs};
    margin-right: ${theme.space.xs};
    position: relative;
    vertical-align: baseline;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: ${theme.space.s};
  ${theme.layout.breakpointMixin.phoneOnly} {
    flex-direction: column;
  }
`;

const Status = styled.div`
  color: inherit;
  font-weight: ${theme.font.weight.medium};
`;

const Title = styled.div<{ error?: boolean; hasStatus: boolean }>`
  ${theme.font.mixins.ellipsisOverflow}
  color: ${({ error }) => (error ? theme.color.error : 'inherit')};
  font-weight: ${theme.font.weight.medium};
  width: ${({ hasStatus }) => (hasStatus ? '60' : '100')}%;
  ${theme.layout.breakpointMixin.phoneOnly} {
    width: 100%;
  }
`;

const UpperRightText = styled(ATextBold)`
  position: absolute;
  right: 14px;
  top: 14px;
`;

export { ACard };
