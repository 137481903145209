import gql from 'graphql-tag';

export const InvoiceUpdateStatus_Fragment_invoice = gql`
  fragment InvoiceUpdateStatus_Fragment_invoice on PapiInvoice {
    id
    createdAt
    currency
    isLegacy
    number
    status
    total
  }
`;
