import { useQuery } from '@apollo/react-hooks';
import { Checkbox, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import fromPairs from 'lodash/fromPairs';
import React, {
  FC,
  ReactNode,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  FieldError,
  FormContextValues,
  ValidationOptions,
} from 'react-hook-form';
import styled from 'styled-components/macro';

import { AFlexbox } from 'app/components/atoms/AFlexbox/AFlexbox';
import { AFormFieldError } from 'app/components/atoms/AFormFieldError/AFormFieldError';
import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { theme } from 'app/styles/theme';
import {
  ASelectProviderProduct_Query,
  ASelectProviderProduct_Query_products,
} from 'app/types/generated/ASelectProviderProduct_Query';
import { PapiProductType } from 'app/types/generated/globalTypes';
import { FORM_VALIDATION } from 'constants/form';

import { A_SELECT_PROVIDER_PRODUCT_QUERY } from './query';

export interface IASelectProviderProductValue {
  displayName: string;
  type: PapiProductType;
}

export interface ASelectProviderProductProps
  extends Omit<SelectProps<string[]>, 'defaultValue' | 'value'>,
    AFormFieldWrapperProps {
  defaultValue?: IASelectProviderProductValue[];
  formHook?: FormContextValues<any>;
  label?: string;
  labelInfo?: string;
  name?: string;
  required?: boolean;
  /** @param rules with react-hook-form only */
  rules?: ValidationOptions;
}

const ASelectProviderProduct: FC<ASelectProviderProductProps> = ({
  customWidth,
  defaultValue,
  formHook,
  label,
  labelInfo,
  name,
  required,
  rules,
  show,
  ...props
}) => {
  const [selectedProducts, setSelectedProducts] = useState<
    IASelectProviderProductValue[]
  >(defaultValue || []);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const errorMessage =
    name &&
    formHook?.errors[name] &&
    (formHook?.errors[name] as FieldError).message;

  const { data, loading } = useQuery<ASelectProviderProduct_Query>(
    A_SELECT_PROVIDER_PRODUCT_QUERY
  );

  const products = useMemo(
    () =>
      fromPairs(
        (data?.products || []).map((product) => [
          product.displayName,
          { ...product, label: product.displayName },
        ])
      ),
    [data]
  );

  useEffect(() => {
    // Register form field once
    if (formHook && name) {
      formHook.register(
        { name },
        { ...(required ? FORM_VALIDATION.requiredArray : {}), ...rules }
      );
      return () => formHook.unregister(name);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Update form value if selected provides change
  useEffect(() => {
    if (!formHook || !name) {
      return;
    }
    const valueInForm = formHook.getValues()[name];
    const valueInState = selectedProducts;
    formHook.setValue(name, selectedProducts);
    // Don't validate before user interacts with field, i.e. while we're setting up states
    if (valueInForm && valueInForm.length !== valueInState.length) {
      formHook.triggerValidation(name);
    }
  }, [selectedProducts]); // eslint-disable-line react-hooks/exhaustive-deps

  const compareDisplayName = useCallback(
    (
      a: { displayName?: string | null } | null,
      b: { displayName?: string | null } | null
    ): number => {
      if (!a || !a.displayName) {
        if (b?.displayName) {
          return 1;
        }
        return 0;
      }
      if (!b || !b.displayName) {
        return -1;
      }
      return a.displayName > b.displayName
        ? 1
        : b.displayName > a.displayName
        ? -1
        : 0;
    },
    []
  );

  const renderSelectOption = useCallback(
    (product: ASelectProviderProduct_Query_products): ReactNode => {
      const { displayName, type } = product;
      const selectedProduct = selectedProducts.find((p) => p.type === type);

      return (
        <SelectOptionStyled
          key={type}
          // @ts-ignore Arbitrary props allowed on <Select.Option> so that <Select optionLabelProp> works, but this isn't reflected in Antd typedefs
          tag={<SelectTag>{displayName}</SelectTag>}
          title={displayName} // Used for searching
        >
          <OptionContainer>
            <CheckboxStyled
              checked={!!selectedProduct}
              onClick={() =>
                setSelectedProducts((currSelectedProducts) =>
                  currSelectedProducts.find((product) => product.type === type)
                    ? currSelectedProducts.filter(
                        (product) => product.type !== type
                      )
                    : [...currSelectedProducts, { displayName, type }]
                )
              }
            >
              <CenterLabel>{displayName}</CenterLabel>
            </CheckboxStyled>
          </OptionContainer>
        </SelectOptionStyled>
      );
    },
    [selectedProducts]
  );

  return (
    <AFormFieldWrapper customWidth={customWidth} show={show}>
      {label && (
        <ALabel black help={labelInfo} htmlFor={name} required={required}>
          {label}
        </ALabel>
      )}

      <SelectWrapper error={!!errorMessage}>
        <Select<string[]> // The select field's value is an array of selected center ids
          autoClearSearchValue
          dropdownClassName="ASelectProviderCenter"
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          id={name}
          loading={loading || props.loading}
          menuItemSelectedIcon={<></>}
          mode="multiple"
          onBlur={() => {
            setDropdownOpen(false);
            if (formHook && name) {
              formHook.triggerValidation(name);
            }
          }}
          onFocus={() => setDropdownOpen(true)}
          open={dropdownOpen}
          optionFilterProp="title"
          optionLabelProp="tag"
          placeholder="Select"
          showSearch
          value={selectedProducts
            .sort(compareDisplayName)
            .map((product) => product.displayName)}
          {...props}
        >
          {Object.values(products)
            .sort(compareDisplayName)
            .map((product) => renderSelectOption(product))}
        </Select>
      </SelectWrapper>

      <AFormFieldError show={!!errorMessage}>{errorMessage}</AFormFieldError>
    </AFormFieldWrapper>
  );
};

// Styled components ////////////////////////////////
const CenterLabel = styled.div`
  bottom: 1px;
  position: relative;
`;

const CheckboxStyled = styled(Checkbox)`
  &&& {
    flex-grow: 1;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectOptionStyled = styled(Select.Option)`
  &&& {
    width: 100%;
  }
`;

const SelectTag = styled(AFlexbox)`
  &&& {
    i {
      top: 1px;
    }
  }
`;

const SelectWrapper = styled.div<{ error: boolean }>`
  div[role='combobox']:not([disabled]) {
    border-color: ${({ error }) =>
      error ? theme.color.error : theme.color.border};
  }

  div[role='combobox']:not([disabled]):active,
  div[role='combobox']:not([disabled]):focus,
  div[role='combobox']:not([disabled]):hover {
    border-color: ${({ error }) =>
      error ? theme.color.error : theme.color.primary} !important;
    box-shadow: ${({ error }) =>
      error
        ? `${theme.layer.boxShadow.all(theme.color.error + '40')}`
        : ''} !important;
  }

  .ant-select {
    width: 100%;
  }
  .ant-select-selection__choice__remove {
    display: none;
  }
  .ant-select-selection--multiple .ant-select-selection__choice {
    padding: 0 ${theme.space.s};
  }
`;

export { ASelectProviderProduct };
