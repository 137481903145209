import gql from 'graphql-tag';

export const A_SELECT_PRODUCT_FIELD_QUERY = gql`
  query ASelectProductField_Query {
    products: listRegisteredProducts {
      displayName
      type
    }
  }
`;
