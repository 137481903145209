import React, { FC, useState } from 'react';

import { AButton } from 'app/components/atoms/AButton/AButton';
import { ADrawer } from 'app/components/atoms/ADrawer/ADrawer';
import { ADrawerContainer } from 'app/components/atoms/ADrawerContainer/ADrawerContainer';
import { APadding } from 'app/components/atoms/APadding/APadding';
import { PlanCreateUpdate } from 'app/components/organisms/PlanCreateUpdate/PlanCreateUpdate';
import { PlanDetails } from 'app/components/organisms/PlanDetails/PlanDetails';
import { ProductDetails } from 'app/components/organisms/ProductDetails/ProductDetails';
import { ProductsList } from 'app/components/organisms/ProductsList/ProductsList';
import { PRODUCTS_LIST_QUERY } from 'app/components/organisms/ProductsList/query';
import { theme } from 'app/styles/theme';
import { PlanDetails_Fragment_plan } from 'app/types/generated/PlanDetails_Fragment_plan';
import { ProductDetails_Fragment_product } from 'app/types/generated/ProductDetails_Fragment_product';
import { ProductsList_Query } from 'app/types/generated/ProductsList_Query';

const isPhone = window.innerWidth <= theme.layout.breakpoint.phone;

/** Products and plans page */
const PlansPage: FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<
    PlanDetails_Fragment_plan | undefined
  >();
  const [selectedProduct, setSelectedProduct] = useState<
    ProductDetails_Fragment_product | undefined
  >();
  const [isCreateFormOpen, setCreateFormOpen] = useState(false);
  const [isUpdateFormOpen, setUpdateFormOpen] = useState(false);

  return (
    <ADrawerContainer
      showDrawer={
        !!selectedPlan ||
        !!selectedProduct ||
        isCreateFormOpen ||
        isUpdateFormOpen ||
        undefined
      }
    >
      <APadding type="page">
        <ProductsList
          onPlanClick={(plan) => {
            setUpdateFormOpen(false); // Set this first so update form doesn't appear
            setSelectedPlan(plan);
            setCreateFormOpen(false);
            setSelectedProduct(undefined);
          }}
          onProductDetailsClick={(product) => {
            setSelectedProduct(product);
            setSelectedPlan(undefined);
            setUpdateFormOpen(false);
            setCreateFormOpen(false);
          }}
          toolbarExtension={
            <PlanCreateUpdate
              mode="create"
              mutationCacheUpdater={(cache, mutationResult) => {
                const mutationData = mutationResult.data;
                if (!mutationData || !mutationData.createPlan) {
                  return;
                }
                const cacheData = cache.readQuery<ProductsList_Query>({
                  query: PRODUCTS_LIST_QUERY,
                });
                cache.writeQuery({
                  data: {
                    products: [
                      ...(cacheData
                        ? cacheData.products.map((product) =>
                            product.type ===
                            mutationData.createPlan.product.type
                              ? {
                                  ...product,
                                  plans: [
                                    ...product.plans,
                                    mutationData.createPlan,
                                  ],
                                }
                              : product
                          )
                        : []),
                    ],
                  },
                  query: PRODUCTS_LIST_QUERY,
                });
              }}
              onClose={() => setCreateFormOpen(false)}
              onCreateCompleted={(mutationData) =>
                setSelectedPlan(mutationData.createPlan)
              }
              onOpen={() => {
                setCreateFormOpen(true);
                setSelectedPlan(undefined);
                setUpdateFormOpen(false);
                setSelectedProduct(undefined);
              }}
              openText={isPhone ? '' : undefined}
              showOverride={isCreateFormOpen}
            />
          }
        />
      </APadding>

      <ADrawer
        ignoreLayoutContext
        onClose={() => {
          setSelectedPlan(undefined);
          setUpdateFormOpen(false);
          setSelectedProduct(undefined);
        }}
        title={isUpdateFormOpen ? 'Edit plan' : undefined}
        visible={!!selectedPlan || !!selectedProduct}
      >
        <APadding type="drawer">
          {selectedPlan && isUpdateFormOpen ? (
            <PlanCreateUpdate
              initialValues={selectedPlan}
              mode="update"
              onClose={() => setUpdateFormOpen(false)}
              onUpdateCompleted={(mutationData) =>
                setSelectedPlan(mutationData.updatePlanJoinableCenters)
              }
            />
          ) : selectedPlan ? (
            <>
              <PlanDetails plan={selectedPlan} />
              <AButton onClick={() => setUpdateFormOpen(true)} type="primary">
                Edit
              </AButton>
            </>
          ) : selectedProduct ? (
            <ProductDetails product={selectedProduct} />
          ) : null}
        </APadding>
      </ADrawer>
    </ADrawerContainer>
  );
};

export { PlansPage };
