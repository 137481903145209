import { times } from 'lodash';
import { Interval } from 'luxon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { CalendarCell } from 'app/components/organisms/CalendarCell/CalendarCell';
import {
  CalendarCellHeaderCSS,
  CalendarCellsContainerCSS,
  CalendarGridCSS,
  CalendarHeaderCSS,
} from 'app/styles/calendar';
import { theme } from 'app/styles/theme';
import { CellInfo } from 'app/types/calendar';
import { getAvailableHours } from 'app/utils/calendar';
import {
  AVAILABLE_END_HOUR,
  AVAILABLE_START_HOUR,
  NUM_VISUAL_VERTICAL_CELLS,
} from 'constants/calendar';

// Types & constants ////////////////////////////
interface Props {
  getDateRangeForVisualGrid: (cell: CellInfo) => Interval[];
}

/** Calendar column for hours/time */
const CalendarHours: FC<Props> = ({ getDateRangeForVisualGrid }) => {
  return (
    <Wrapper>
      <HeaderRow>
        <Column>
          <Timezone>_</Timezone>
        </Column>
      </HeaderRow>
      <Grid>
        <Column>
          <CellsContainer>
            {getAvailableHours(
              times(NUM_VISUAL_VERTICAL_CELLS),
              AVAILABLE_START_HOUR,
              AVAILABLE_END_HOUR
            ).map((timeIndex) => {
              return (
                <CalendarCell
                  getDateRangeForVisualGrid={getDateRangeForVisualGrid}
                  hoursColumn={true}
                  key={`CalendarHours_${timeIndex}`}
                  timeIndex={timeIndex}
                >
                  {({ isHourStart, start }) =>
                    isHourStart ? <Time>{start.toFormat('h a')}</Time> : null
                  }
                </CalendarCell>
              );
            })}
          </CellsContainer>
        </Column>
      </Grid>
    </Wrapper>
  );
};

// Styled components ////////////////////////////////
const CellsContainer = styled.div`
  ${CalendarCellsContainerCSS}
`;

const Column = styled.div`
  background-color: ${theme.color.alabaster};
  flex: 0 0 auto;
  min-width: fill-available;
`;

const Timezone = styled.div`
  ${CalendarCellHeaderCSS}
  border-top: none;
`;

const Grid = styled.div`
  ${CalendarGridCSS}
`;

const HeaderRow = styled.div`
  ${CalendarHeaderCSS}
`;

const Time = styled.div`
  color: ${theme.color.calendar.text};
  font-size: ${theme.font.size.s};
  padding: 0 ${theme.space.m} 0 ${theme.space.s};
`;

const Wrapper = styled.div`
  display: table-cell;
  left: 0;
  position: sticky;
  text-align: end;
  width: ${theme.layout.calendar.timeColumnWidth}px;
  z-index: ${theme.layer.zIndex.calendar.hours};
`;

export { CalendarHours };
