import React, { FC } from 'react';
import { FormContextValues } from 'react-hook-form';

import { AInput, AInputProps } from 'app/components/atoms/AInput/AInput';

// Types & constants ////////////////////////////////
interface Props extends AInputProps {
  confirmationText?: string;
  formHook: FormContextValues<any>;
  name: string;
}

/**
 * Input field that requires user to enter CONFIRM (or provided confirmation
 * text) to proceed, e.g. for confirming deletion. Can be used alone or with
 * react-hook-form
 */
const AInputConfirmation: FC<Props> = ({
  confirmationText = 'confirm',
  ...props
}) => (
  <AInput
    placeholder={`Enter ${confirmationText.toUpperCase()}`}
    rules={{
      validate: (value?: string) =>
        value?.trim().toLowerCase() === confirmationText.toLowerCase() ||
        `Enter ${confirmationText.toUpperCase()} to proceed`,
    }}
    {...props}
  />
);

export { AInputConfirmation };
