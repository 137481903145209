import { useMutation, useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { AFormSaveButtonGroup } from 'app/components/atoms/AFormSaveButtonGroup/AFormSaveButtonGroup';
import { AInput } from 'app/components/atoms/AInput/AInput';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { theme } from 'app/styles/theme';
import { FormFieldOptions } from 'app/types/form';
import { PapiUpdatePersonInput } from 'app/types/generated/globalTypes';
import {
  MemberThirdPartyIDsUpdate_Mutation,
  MemberThirdPartyIDsUpdate_MutationVariables,
} from 'app/types/generated/MemberThirdPartyIDsUpdate_Mutation';
import {
  MemberThirdPartyIDsUpdate_Query,
  MemberThirdPartyIDsUpdate_QueryVariables,
} from 'app/types/generated/MemberThirdPartyIDsUpdate_Query';
import { displayErrors } from 'app/utils/app';
import { STATUS_MESSAGE } from 'constants/message';

import {
  MEMBER_THIRD_PARTY_IDS_UPDATE_MUTATION,
  MEMBER_THIRD_PARTY_IDS_UPDATE_QUERY,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  onClose?: () => void;
  onSave?: () => void;
  personID: string;
}

/** Form to update a member's third party services IDs */
const MemberThirdPartyIDsUpdate: FC<Props> = ({
  onClose,
  onSave,
  personID,
}) => {
  const { data, loading: queryLoading } = useQuery<
    MemberThirdPartyIDsUpdate_Query,
    MemberThirdPartyIDsUpdate_QueryVariables
  >(MEMBER_THIRD_PARTY_IDS_UPDATE_QUERY, { variables: { personID } });
  const [updateMember, { loading: mutationLoading }] = useMutation<
    MemberThirdPartyIDsUpdate_Mutation,
    MemberThirdPartyIDsUpdate_MutationVariables
  >(MEMBER_THIRD_PARTY_IDS_UPDATE_MUTATION);
  const formHook = useForm();

  if (queryLoading || !data || !data.member) {
    return (
      <LoadingContainer>
        <ALoading />
      </LoadingContainer>
    );
  }

  const handleSave = async (
    input: Pick<PapiUpdatePersonInput, 'helpScoutID'>
  ): Promise<void> => {
    try {
      const result = await updateMember({
        variables: {
          input: {
            ...input,
            id: personID,
          },
        },
      });
      if (result?.data) {
        message.success(STATUS_MESSAGE.memberThirdPartyIDsUpdate.success);
        onSave?.();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(
        err,
        STATUS_MESSAGE.memberThirdPartyIDsUpdate.error.general
      );
    }
  };

  return (
    <>
      <AFormFieldRow>
        <AInput
          customWidth={{ percent: 20 }}
          defaultValue={data?.member?.helpScoutID || undefined}
          formHook={formHook}
          {...fields.helpScoutID}
        />
      </AFormFieldRow>
      <AFormSaveButtonGroup
        onCancel={onClose}
        onSave={formHook.handleSubmit(handleSave)}
        saving={mutationLoading}
      />
    </>
  );
};

// Styled components ////////////////////////////////
const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 150px;
  justify-content: flex-start;
  margin-left: 100px;
  ${theme.layout.breakpointMixin.phoneOnly} {
    justify-content: center;
    margin-left: 0;
  }
`;

/** Form field info */
const fields: Pick<FormFieldOptions<PapiUpdatePersonInput>, 'helpScoutID'> = {
  helpScoutID: {
    label: 'Helpscout ID',
    name: 'helpScoutID',
  },
};

export { fields, MemberThirdPartyIDsUpdate };
