import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import React, { FC, ReactNode } from 'react';
import {
  CardElement,
  Elements,
  ReactStripeElements,
  injectStripe,
} from 'react-stripe-elements';
import styled from 'styled-components/macro';

import {
  AForm,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { theme } from 'app/styles/theme';
import {
  PaymentCardAdd_Mutation,
  PaymentCardAdd_MutationVariables,
} from 'app/types/generated/PaymentCardAdd_Mutation';
import { displayErrors } from 'app/utils/app';
import { STATIC_FILES_URL } from 'constants/env';
import { STATUS_MESSAGE } from 'constants/message';

import { PAYMENT_CARD_ADD_MUTATION, PAYMENT_CARD_ADD_REFETCH } from './query';

// Types & constants ////////////////////////////////
interface Props {
  openText?: ReactNode;
  personID: string;
}

const fonts = [
  {
    display: 'block',
    family: 'EuclidCircular',
    src: `url(${STATIC_FILES_URL}/fonts/euclid-circular-b/euclid-circular-b-regular.woff)`,
    style: 'normal',
    weight: '400',
  },
];

/** Form to add a member's payment card */
const PaymentCardAdd: FC<Props & ReactStripeElements.InjectedStripeProps> = ({
  openText = 'Add payment card',
  personID,
  stripe,
}) => {
  const [addPaymentCard, { loading: mutationLoading }] = useMutation<
    PaymentCardAdd_Mutation,
    PaymentCardAdd_MutationVariables
  >(PAYMENT_CARD_ADD_MUTATION, {
    refetchQueries: [
      {
        query: PAYMENT_CARD_ADD_REFETCH,
        variables: { personID },
      },
    ],
  });
  const onSave = async (
    inputIgnore: any,
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    const stripeResponse = stripe && (await stripe.createToken());
    if (!stripeResponse?.token?.card) {
      message.error(STATUS_MESSAGE.stripe.error.noToken);
      shouldFinish(false);
      return;
    }

    try {
      const result = await addPaymentCard({
        variables: {
          input: {
            expiryMonth: stripeResponse.token.card.exp_month,
            expiryYear: stripeResponse.token.card.exp_year,
            last4Digits: stripeResponse.token.card.last4,
            name: `${stripeResponse.token.card.brand} - ${stripeResponse.token.card.funding}`,
            personID,
            stripeSourceToken: stripeResponse.token.id,
          },
        },
      });
      if (result?.data) {
        message.success(STATUS_MESSAGE.paymentCardAdd.success);
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.paymentCardAdd.error.general);
      shouldFinish(false);
    }
  };

  return (
    <AForm
      onSave={onSave}
      openAs="modal"
      openText={openText}
      saveText="Add"
      saving={mutationLoading}
      savingText="Adding"
      title="New payment card"
    >
      {() => (
        <>
          The new card will be set as the default payment method, and it will be
          charged automatically for membership fees.
          <CardElementStyled
            className="ant-input"
            style={{ base: { fontFamily: '"EuclidCircular", sans-serif' } }}
          />
        </>
      )}
    </AForm>
  );
};

// Styled components ////////////////////////////////
const CardElementStyled = styled(CardElement)`
  &&& {
    display: inherit;
    height: inherit;
    margin: ${theme.space.m} 0;
    padding: ${theme.space.m};
  }
`;

const WithStripe = injectStripe(PaymentCardAdd);
const PaymentCardAddStripeContainer: FC<Props> = (props) => (
  <Elements fonts={fonts}>
    <WithStripe {...props} />
  </Elements>
);

export { PaymentCardAddStripeContainer as PaymentCardAdd };
