import gql from 'graphql-tag';

import { MemberEmergencyContact_Fragment_member } from './fragment';

export const MEMBER_GET_EMERGENCY_CONTACT = gql`
  query GetMemberPersonEmergencyContact_Query($personID: ID!) {
    getPersonEmergencyContact(personId: $personID) {
      ...MemberEmergencyContact_Fragment_member
    }
  }
  ${MemberEmergencyContact_Fragment_member}
`;
