import { Icon } from 'antd';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

export const AIconClickable = styled(Icon)<{
  color?: string;
  primary?: boolean;
}>`
  &&& {
    color: ${theme.color.text};
    cursor: pointer;
    position: relative;
    transition-duration: ${theme.animation.transitionDuration};
    transition-property: color;
    transition-timing-function: ${theme.animation.transitionTiming};
    :hover {
      color: ${theme.color.primary};
    }
    ${({ color, primary }) =>
      primary
        ? `svg {
      fill: ${color || theme.color.primary};
      transition-duration: ${theme.animation.transitionDuration};
      transition-property: fill;
      transition-timing-function: ${theme.animation.transitionTiming};
      :hover {
        fill: ${theme.color.primaryLight};
      }
    }`
        : ''}
  }
`;
