import { PapiBillingPeriod } from 'app/types/generated/globalTypes';
import { PlanCreateUpdate_Fragment_updatePlan } from 'app/types/generated/PlanCreateUpdate_Fragment_updatePlan';
import { PlanDetails_Fragment_plan } from 'app/types/generated/PlanDetails_Fragment_plan';

export enum PlanAgeLimit {
  Adult = 'adult',
  Pediatrics = 'pediatrics',
}

export const PLAN_AGE_LIMITS: Array<{
  maxPersonAgeYears: number | null;
  minPersonAgeYears: number;
  value: PlanAgeLimit;
}> = [
  {
    maxPersonAgeYears: 17,
    minPersonAgeYears: 0,
    value: PlanAgeLimit.Pediatrics,
  },
  {
    maxPersonAgeYears: null,
    minPersonAgeYears: 18,
    value: PlanAgeLimit.Adult,
  },
];

export const PLAN_COMMITMENT_LENGTH_SELECTION = [
  {
    label: '1 month',
    value: PapiBillingPeriod.MONTH,
  },
  {
    label: '3 months',
    value: PapiBillingPeriod.QUARTER,
  },
  {
    label: '6 months',
    value: PapiBillingPeriod.HALF_YEAR,
  },
  {
    label: '12 months',
    value: PapiBillingPeriod.YEAR,
  },
];

export const PLAN_COMMITMENT_PERIOD_BY_MONTHS: {
  [numMonths: number]: PapiBillingPeriod;
} = {
  1: PapiBillingPeriod.MONTH,
  3: PapiBillingPeriod.QUARTER,
  6: PapiBillingPeriod.HALF_YEAR,
  12: PapiBillingPeriod.YEAR,
};

type Plan = PlanDetails_Fragment_plan & PlanCreateUpdate_Fragment_updatePlan;
export const PLAN_FIELD_HELP: {
  [K in keyof Plan]?: string;
} = {
  billingPeriod:
    'Frequency at which a member is billed for the plan. The plan length = bill period x cycles.',
  commitmentLength:
    "Period over which an active member is required to stay on the plan (canceling during this period will result in a fee). Member must finish out their plan before changing plans. NOTE: this is not the plan length, it's the commitment period.",
  displayName: 'Name used internally and not shown to members',
  joinCentersAllowed:
    'Prospective members can sign up for the plan at these centers through the Join flow.',
  numberOfBillingCycles:
    'Number of times a member is billed for the plan. If blank, the plan goes on forever, and the member is billed each bill period in perpetuity.',
  paymentRateInCents: 'Amount paid each bill period',
};
