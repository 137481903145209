import gql from 'graphql-tag';

import { MemberPlanCard_Fragment_planPurchase } from 'app/components/organisms/MemberPlanCard/fragment';
import { MemberStatusTags_Fragment_member } from 'app/components/organisms/MemberStatusTags/fragment';

import { PlanPurchasePause_Fragment_planPurchase } from './fragment';

export const PLAN_PURCHASE_PAUSE_MUTATION = gql`
  mutation PlanPurchasePause_Mutation($input: PapiPausePurchaseInput!) {
    pausePurchase(input: $input) {
      id
      ...PlanPurchasePause_Fragment_planPurchase
      ...MemberPlanCard_Fragment_planPurchase
    }
  }
  ${PlanPurchasePause_Fragment_planPurchase}
  ${MemberPlanCard_Fragment_planPurchase}
`;

export const PLAN_PURCHASE_PAUSE_REFETCH = gql`
  query PlanPurchasePause_Refetch($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      allPlans {
        edges {
          node {
            ...MemberPlanCard_Fragment_planPurchase
          }
        }
      }
      ...MemberStatusTags_Fragment_member
    }
  }
  ${MemberStatusTags_Fragment_member}
  ${MemberPlanCard_Fragment_planPurchase}
`;
