import { Menu } from 'antd';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

export const ADropdownMenuItem = styled(Menu.Item)`
  &&& {
    font-size: ${theme.font.size.s};
    line-height: ${theme.font.lineHeight.s};
  }
`;
