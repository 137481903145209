import {
  PapiAppointmentCategory,
  PapiAppointmentGroup,
  PapiAppointmentStatus,
} from 'app/types/generated/globalTypes';

export const VISIT_GROUPS_FOR_PREP = [
  PapiAppointmentGroup.COACHING,
  PapiAppointmentGroup.MEDICAL,
];

export const VISIT_CATEGORIES_FOR_PREP = Object.values(
  PapiAppointmentCategory
).filter(
  (category) => category.includes('MEDICAL') || category.includes('COACHING')
);

export const VISIT_STATUSES_SCHEDULED = [
  PapiAppointmentStatus.SCHEDULED,
  PapiAppointmentStatus.COMPLETED,
];
