import gql from 'graphql-tag';

export const typeDefs = gql`
  "General source categorization"
  enum PapiInboxItemSourceCategory {
    LABORATORY
    EFAX
    UPLOAD
  }

  "Specific source categorization - which integration generated an item."
  enum PapiInboxItemSource {
    HEALTH_GORILLA_DIAGNOSTIC_REPORT
    HEALTH_GORILLA_EFAX
    PERSON_UPLOAD
    # SOME_OTHER_PROVIDER_EFAX
    # ...
  }

  "How an item is categorized after triage"
  enum PapiInboxItemCategory {
    LAB_RESULTS
    MEDICAL_HISTORY
    RADIOLOGY
    ADMINISTRATIVE
    CONSULTS
    INVOICES
    MISC
  }

  "Where an item is in the triage process"
  enum PapiInboxItemTriageStatus {
    NEW
    ARCHIVED
    TRASHED
    DONE
  }

  type PapiInboxItem {
    id: ID!
    personID: ID
    person: PapiPerson
    "General information on where this item is coming from"
    sourceCategory: PapiInboxItemSourceCategory!
    "Specific integration on where this item is coming from - integration name"
    source: PapiInboxItemSource!
    "Document name provided from the integration"
    documentName: String!
    "URL of the generated/associated PDF file"
    url: String!
    "ID of this item in the 3rd party service (DAPI, HG, etc.)"
    foreignID: ID!
    "Original representation of this item in the 3rd party service"
    originalData: JSON

    "Status of the triage process"
    triageStatus: PapiInboxItemTriageStatus!
    triagedByID: ID
    # triagedBy: Person
    triagedAt: String

    "Categorization after triage"
    category: PapiInboxItemCategory

    isUploadedToMDHQ: Boolean!
    createdAt: String!
    updatedAt: String!
  }

  input PapiCategorizeInboxItemInput {
    id: ID!
    # documentName: String!
    category: PapiInboxItemCategory!

    "If present or null, the personID will be updated"
    personID: ID

    "If present creates a review task for the given provider"
    reviewerID: ID
  }

  input PapiTrashInboxItemInput {
    id: ID!
    # documentName: String!

    "If present or null, the personID will be updated"
    personID: ID
  }

  input PapiArchiveInboxItemInput {
    id: ID!
    # documentName: String!
    category: PapiInboxItemCategory!
  }

  extend type Mutation {
    "Sets the triage status to DONE"
    categorizeInboxItem(input: PapiCategorizeInboxItemInput!): PapiInboxItem!

    "Sets the triage status to TRASHED"
    trashInboxItem(input: PapiTrashInboxItemInput!): PapiInboxItem!

    "Sets the triage status to ARCHIVED"
    archiveInboxItem(input: PapiArchiveInboxItemInput!): PapiInboxItem!
  }

  extend type Query {
    listInboxItems(
      centerIDs: [ID!]
      sourceCategory: PapiInboxItemSourceCategory
      triageStatuses: [PapiInboxItemTriageStatus!]
      personID: ID
    ): [PapiInboxItem!]!
  }
`;
