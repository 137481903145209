import React, { FC } from 'react';

import { APadding } from 'app/components/atoms/APadding/APadding';
import { CouponsTable } from 'app/components/organisms/CouponsTable/CouponsTable';

const CouponsPage: FC = () => {
  return (
    <APadding type="page">
      <CouponsTable />
    </APadding>
  );
};

export { CouponsPage };
