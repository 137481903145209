import { Form as AntdForm, Button, Drawer, Modal, Tooltip } from 'antd';
import { ButtonType, NativeButtonProps } from 'antd/lib/button/button';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, {
  FC,
  ReactElement,
  ReactNode,
  cloneElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components/macro';

import { AIconClickable } from 'app/components/atoms/AIconClickable/AIconClickable';
import { AIconUnclickable } from 'app/components/atoms/AIconUnclickable/AIconUnclickable';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { APadding } from 'app/components/atoms/APadding/APadding';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { LayoutContext } from 'app/contexts/LayoutContext';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
export interface AFormProps {
  button?: true | { icon?: string; type?: ButtonType };
  cancelText?: string;
  children: (form: WrappedFormUtils) => ReactNode;
  customButton?: ReactElement;
  disableOpen?: true | { message: ReactNode };
  form: WrappedFormUtils;
  icon?: { tooltipText: string; type: string };
  ignoreLayoutContext?: boolean;
  loading?: boolean;
  onClose?: () => any;
  onOpen?: () => void;
  onSave: (input: any, shouldFinish: TFormShouldFinish) => any;
  openAs?: 'drawer' | 'modal';
  openText?: ReactNode;
  saveText?: string;
  saving?: boolean;
  savingText?: string;
  showOverride?: boolean;
  title?: string;
}

export type TFormFieldInfo<TFormInput = { [inputKey: string]: any }> = {
  [K in keyof TFormInput]-?: {
    label: string;
    labelInfo?: string;
    name: K;
    required?: boolean;
  };
};

export type TFormShouldFinish = (close?: boolean) => void;

/**
 * Deprecated: Antd form, being deprecated to use react-hook-form.
 * Passes form state to wrapped <FormField>s through render props
 */
const AForm: FC<AFormProps> = ({
  button,
  cancelText = 'Cancel',
  children,
  customButton,
  disableOpen,
  form,
  icon,
  ignoreLayoutContext,
  loading,
  onClose,
  onOpen,
  onSave,
  openAs,
  openText = 'Open form',
  saveText = 'Save',
  saving: savingOverride = false,
  savingText = 'Saving',
  showOverride,
  title,
}) => {
  const { isDrawerOpen, setDrawerOpen } = useContext(LayoutContext);

  const [saving, setSaving] = useState(false);
  useEffect(() => setSaving(savingOverride), [savingOverride]);

  const [showForm, setShowForm] = useState(
    openAs !== 'modal' && (openAs !== 'drawer' || isDrawerOpen)
  );

  const handleOpen = (): void => {
    onOpen?.();
    setShowForm(true);
    if (openAs === 'drawer' && !ignoreLayoutContext) {
      setDrawerOpen(true);
    }
  };
  const handleClose = (): void => {
    onClose?.();
    setShowForm(false);
    if (openAs === 'drawer' && !ignoreLayoutContext) {
      setDrawerOpen(false);
    }
    form.resetFields();
  };

  const handleSave = (): void => {
    form.validateFields(
      async (errors: { [fieldName: string]: { errors: string[] } }, values) => {
        if (!errors) {
          setSaving(true);
          await onSave(values, (close?: boolean): void => {
            // shouldFinish callback to allow save to finish based on the result,
            // e.g. if the save errors you could use shouldFinish(false) to
            // finish saving but leave the form open
            setSaving(false);
            if (close !== false) {
              handleClose();
            }
          });
        }
      }
    );
  };

  const hasError = (): boolean => {
    const errors = form.getFieldsError();
    return Object.keys(errors).some((field) => errors[field]);
  };
  const saveButtonDisabled = hasError();

  return openAs ? (
    <>
      {customButton ? (
        cloneElement(customButton, { onClick: handleOpen })
      ) : typeof disableOpen === 'object' ? (
        <Tooltip placement="right" title={disableOpen.message}>
          {button ? (
            <Button
              disabled
              icon={typeof button === 'object' ? button.icon : undefined}
              type={typeof button === 'object' ? button.type : undefined}
            >
              {openText}
            </Button>
          ) : icon ? (
            <AIconUnclickable type={icon.type} />
          ) : (
            <ATextLight lighter>{openText}</ATextLight>
          )}
        </Tooltip>
      ) : button ? (
        <Button
          disabled={disableOpen}
          icon={typeof button === 'object' ? button.icon : undefined}
          onClick={disableOpen ? undefined : handleOpen}
          type={typeof button === 'object' ? button.type : undefined}
        >
          {openText}
        </Button>
      ) : icon ? (
        <Tooltip title={icon.tooltipText}>
          {disableOpen ? (
            <AIconUnclickable type={icon.type} />
          ) : (
            <AIconClickable onClick={handleOpen} primary type={icon.type} />
          )}
        </Tooltip>
      ) : disableOpen === true ? (
        <ATextLight>{openText}</ATextLight>
      ) : (
        <span onClick={handleOpen}>{openText}</span>
      )}

      {openAs === 'drawer' && (
        <Drawer
          destroyOnClose
          mask={false}
          onClose={handleClose}
          title={title}
          visible={showOverride !== undefined ? showOverride : showForm}
          width={theme.layout.drawerSizeDefault}
        >
          {loading ? (
            <ALoading centered />
          ) : (
            <>
              <APadding type="drawer">
                <FormFields drawer>{children(form)}</FormFields>
              </APadding>
              <DrawerButtons>
                {!saving && (
                  <CancelButton onClick={handleClose}>
                    {cancelText}
                  </CancelButton>
                )}
                <Button
                  disabled={saveButtonDisabled}
                  loading={saving}
                  onClick={handleSave}
                  type="primary"
                >
                  {saving ? `${savingText}...` : saveText}
                </Button>
              </DrawerButtons>
            </>
          )}
        </Drawer>
      )}

      {openAs === 'modal' && (
        <Modal
          cancelButtonProps={{
            style: { display: saving ? 'none' : 'inline-block' },
          }}
          cancelText={cancelText}
          confirmLoading={saving}
          destroyOnClose
          maskClosable={false}
          okButtonProps={{ disabled: saveButtonDisabled }}
          okText={saving ? `${savingText}...` : saveText}
          onCancel={handleClose}
          onOk={handleSave}
          title={title}
          visible={showOverride !== undefined ? showOverride : showForm}
        >
          <APadding type="modal">
            {loading ? (
              <ALoading centered />
            ) : (
              <FormFields>{children(form)}</FormFields>
            )}
          </APadding>
        </Modal>
      )}
    </>
  ) : (showForm && showOverride === undefined) || showOverride ? (
    <AntdForm>
      <FormFields>{children(form)}</FormFields>
      {!saving && (
        <CancelButton onClick={handleClose}>{cancelText}</CancelButton>
      )}
      <Button
        disabled={saveButtonDisabled}
        loading={saving}
        onClick={handleSave}
        type="primary"
      >
        {saving ? `${savingText}...` : saveText}
      </Button>
    </AntdForm>
  ) : null;
};

// Styled components ////////////////////////////////
const CancelButton = styled((props: NativeButtonProps) => (
  <Button {...props} />
))`
  margin-right: ${theme.space.m};
`;

const DrawerButtons = styled.div`
  background: white;
  border-top: 1px solid ${theme.color.border};
  bottom: 0;
  left: 0;
  padding: 10px ${theme.space.m};
  position: absolute;
  text-align: right;
  width: 100%;
`;

const FormFields = styled.div<{ drawer?: boolean }>`
  margin-bottom: ${({ drawer }) => (drawer ? theme.space.xxl : theme.space.s)};
`;

// Wrapped form. See https://ant.design/components/form/
const AFormContainer = AntdForm.create<AFormProps>()(AForm);

export { AFormContainer as AForm };
