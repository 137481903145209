import gql from 'graphql-tag';

import { MemberPaymentCard_Fragment_card } from 'app/components/organisms/MemberPaymentCard/fragment';

export const PAYMENT_CARD_ADD_MUTATION = gql`
  mutation PaymentCardAdd_Mutation(
    $input: PapiRegisterPaymentCardWithPersonInput!
  ) {
    registerPaymentCardWithPerson(input: $input) {
      ...MemberPaymentCard_Fragment_card
    }
  }
  ${MemberPaymentCard_Fragment_card}
`;

export const PAYMENT_CARD_ADD_REFETCH = gql`
  query PaymentCardAdd_Refetch($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      paymentCards {
        edges {
          node {
            id
            deleted
            ...MemberPaymentCard_Fragment_card
          }
        }
      }
      stripeCustomerID
    }
  }
  ${MemberPaymentCard_Fragment_card}
`;
