import { useQuery } from '@apollo/react-hooks';
import { Divider, Icon, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import debounce from 'lodash/debounce';
import { DateTime } from 'luxon';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AFlexbox } from 'app/components/atoms/AFlexbox/AFlexbox';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { AProfileLinkClipboardCopy } from 'app/components/atoms/AProfileLinkClipboardCopy/AProfileLinkClipboardCopy';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { theme } from 'app/styles/theme';
import {
  MembersSearch_Query,
  MembersSearch_QueryVariables,
} from 'app/types/generated/MembersSearch_Query';
import { displayErrors } from 'app/utils/app';
import { getCenterDisplayNameWithIcon } from 'app/utils/center';
import { formatMemberName, getMemberAge } from 'app/utils/member';
import { AppRoute } from 'constants/app';
import { STATUS_MESSAGE } from 'constants/message';

import { MEMBER_SEARCH_QUERY } from './query';

// Types & constants ////////////////////////////////
const DEBOUNCE_WAIT = 250;
const MIN_QUERY_LENGTH = 3;

/** Search members by name or email */
const MembersSearch: FC = () => {
  const history = useHistory();

  let selectField: Select<unknown> | null = null;
  const [input, setInput] = useState<string | undefined>(undefined);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (focus && selectField) {
      selectField.focus();
    }
  }, [focus, selectField]);

  const onBlur = (): void => {
    setInput(undefined);
    setFocus(false);
  };

  const onSelect = (personID: SelectValue | unknown): void => {
    setInput(undefined);
    setFocus(false);
    history.push(`${AppRoute.Members}/${personID}`);
  };

  const { data, loading, refetch } = useQuery<
    MembersSearch_Query,
    MembersSearch_QueryVariables
  >(MEMBER_SEARCH_QUERY, {
    skip: !input || input.length < MIN_QUERY_LENGTH,
    variables: { search: input || '' },
  });

  const onSearch = debounce(async (newInput) => {
    setInput(newInput);
    if (newInput.length >= MIN_QUERY_LENGTH) {
      try {
        await refetch({ search: newInput });
      } catch (err) {
        displayErrors(err, STATUS_MESSAGE.membersSearch.error.general);
      }
    }
  }, DEBOUNCE_WAIT);

  return (
    <>
      <SelectStyled
        data-private
        data-testid="MembersSearch_input"
        defaultActiveFirstOption={false}
        filterOption={false}
        notFoundContent={
          loading ? (
            <ALoading size="small" />
          ) : data ? (
            'No members found'
          ) : (
            'Search by name or email'
          )
        }
        onBlur={onBlur}
        onSearch={onSearch}
        onSelect={onSelect}
        placeholder="Search members"
        ref={(field) => (selectField = field)}
        showArrow={false}
        showSearch
        value={input}
      >
        {!input || input.length < MIN_QUERY_LENGTH
          ? null // Resets the results on new searches
          : data?.searchPersons?.map((member) => (
              <Select.Option key={member.id}>
                <div>
                  <span data-private>{formatMemberName(member)}</span>
                  {member.dateOfBirth && (
                    <ATextLight lighter>
                      , {getMemberAge(member.dateOfBirth)}
                    </ATextLight>
                  )}
                </div>
                <Details>
                  {member.biologicalSex === 'FEMALE' && (
                    <GenderIcon type="woman" />
                  )}
                  {member.biologicalSex === 'MALE' && <GenderIcon type="man" />}
                  {(member.biologicalSex === 'FEMALE' ||
                    member.biologicalSex === 'MALE') && (
                    <Divider type="vertical" />
                  )}
                  {member.dateOfBirth &&
                    DateTime.fromISO(member.dateOfBirth).toLocaleString(
                      DateTime.DATE_SHORT
                    )}
                  {member.center && (
                    <>
                      <Divider type="vertical" />
                      {getCenterDisplayNameWithIcon(member.center)}
                    </>
                  )}
                  <AProfileLinkClipboardCopyWrapper
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Divider type="vertical" />
                    <AProfileLinkClipboardCopy
                      personID={member.id}
                      showText={false}
                    />
                  </AProfileLinkClipboardCopyWrapper>
                </Details>
                <ATextLight lighter>
                  <Email data-private>{member.email}</Email>
                </ATextLight>
              </Select.Option>
            ))}
      </SelectStyled>
      <SearchIcon
        onClick={() => selectField?.focus()}
        theme="outlined"
        type="search"
      />
    </>
  );
};

// Styled components ////////////////////////////////
const AProfileLinkClipboardCopyWrapper = styled.div`
  margin-left: ${theme.space.xxs};
`;

const Details = styled(AFlexbox)`
  &&& {
    color: ${theme.color.textLighter};
    * {
      color: ${theme.color.textLighter};
    }
  }
`;

const Email = styled.div`
  ${theme.font.mixins.ellipsisOverflow}
  width: 200px - ${theme.space.m};
`;

const GenderIcon = styled(Icon)`
  &&& {
    vertical-align: baseline;
  }
`;

const SearchIcon = styled(Icon)`
  &&& {
    color: ${theme.color.textLight};
    cursor: pointer;
    position: relative;
    right: ${theme.space.l};
    vertical-align: text-top;
    :hover {
      color: ${theme.color.heavyMetal};
      transition: color;
      transition-duration: ${theme.animation.transitionDuration};
      transition-timing-function: ${theme.animation.transitionTiming};
    }
  }
`;

const SelectStyled = styled(Select)`
  &&& {
    width: 250px;
  }
`;

export { MembersSearch };
