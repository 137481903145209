import { Row } from 'antd';
import { DateTime, Interval } from 'luxon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AIconLocation } from 'app/components/atoms/AIconLocation/AIconLocation';
import { theme } from 'app/styles/theme';
import { CalendarPage_Visits_Query_provider_visits } from 'app/types/generated/CalendarPage_Visits_Query';
import { PapiAppointmentFormat } from 'app/types/generated/globalTypes';
import { VERTICAL_PRECISION } from 'constants/calendar';

// Types & constants ////////////////////////////////
interface Props {
  height?: number;
  inactive?: boolean;
  isSelected?: boolean;
  onClick?: (visitId: string) => void;
  timezone: string;
  visit: CalendarPage_Visits_Query_provider_visits;
  width?: number;
}

/** Visit card for the calendar, the time length of PapiAppointment sets the height */
const CalendarCard: FC<Props> = ({
  height,
  inactive,
  isSelected,
  onClick,
  timezone,
  visit,
  width,
}) => {
  const {
    displayColor,
    displayName,
    endTime,
    format,
    patientName,
    productAbbreviation,
    startTime,
  } = visit;
  const interval = Interval.fromDateTimes(
    DateTime.fromISO(startTime, { zone: timezone }),
    DateTime.fromISO(endTime, { zone: timezone })
  );
  const lengthInMinutes = Math.ceil(interval.length('minutes'));
  const cardHeight = height || lengthInMinutes;
  const isShortestTimeFrame = lengthInMinutes < VERTICAL_PRECISION;

  const isInPerson = format === PapiAppointmentFormat.IN_PERSON;
  const iconLocationSize = isShortestTimeFrame
    ? theme.font.size.xs
    : theme.font.size.s;

  return (
    <Styles
      data-testid={`CalendarCard-${visit.id}`}
      height={cardHeight}
      isSelected={isSelected}
      onClick={onClick && (() => onClick(visit.id))}
      width={width}
    >
      {inactive && <InactiveMask data-testid="CalendarCard_inactive-mask" />}
      <ContentContainer isShortestHeight={isShortestTimeFrame}>
        <MemberNameContainer>
          <NameText data-private isShortestHeight={isShortestTimeFrame}>
            {patientName}
          </NameText>
          {isInPerson ? (
            <AIconLocation
              color={inactive ? theme.color.white : theme.color.darkGrey}
              height={iconLocationSize}
              width={iconLocationSize}
              zIndex="4"
            />
          ) : (
            <ProductAbbr>{productAbbreviation}</ProductAbbr>
          )}
        </MemberNameContainer>
        {lengthInMinutes > 30 && (
          <AppointmentTimeContainer>
            <TimeText>
              {`${interval.start.toFormat('h:mm a')} – ${interval.end.toFormat(
                'h:mm a'
              )}`.toLowerCase()}
            </TimeText>
          </AppointmentTimeContainer>
        )}
      </ContentContainer>

      <ColorBar color={displayColor || theme.color.red}>
        {lengthInMinutes > 60 && (
          <>
            <ColorBarText>{displayName || 'Unknown'}</ColorBarText>
          </>
        )}
      </ColorBar>
    </Styles>
  );
};

// Styled components ////////////////////////////////
const AppointmentTimeContainer = styled(Row)`
  &&& {
    line-height: ${theme.font.size.xl};
  }
`;

const ColorBar = styled.div<{ color: string }>`
  align-items: center;
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: space-between;
  padding: ${theme.space.xxs} ${theme.space.sm} ${theme.space.xxs}
    ${theme.space.sm};
`;

const ColorBarText = styled.div`
  ${theme.font.mixins.ellipsisOverflow}
  color: ${theme.color.white};
  font-size: ${theme.font.size.s};
  font-weight: ${theme.font.weight.semibold};
  letter-spacing: 0.02rem;
  line-height: ${theme.font.size.xl};
`;

const ContentContainer = styled.div<{
  isShortestHeight: boolean;
}>`
  flex: 1;
  flex-direction: column;
  padding: ${({ isShortestHeight }) =>
    isShortestHeight
      ? `1px ${theme.space.sm} 0 ${theme.space.sm}`
      : `${theme.space.s} ${theme.space.sm} 0 ${theme.space.sm}`};
`;

const InactiveMask = styled.div`
  background-color: ${theme.color.calendar.mask};
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: ${theme.layer.zIndex.calendar.cardMask};
`;

const MemberNameContainer = styled(Row)`
  line-height: 0;
  &&& {
    align-items: center;
    display: flex;
  }
`;

const NameText = styled.span<{
  isShortestHeight: boolean;
}>`
  ${theme.font.mixins.ellipsisOverflow}
  flex: 1;
  font-size: ${theme.font.size.s};
  font-weight: ${theme.font.weight.semibold};
  letter-spacing: 0.02rem;
  line-height: ${({ isShortestHeight }) =>
    isShortestHeight ? theme.space.sm : theme.space.m};
`;

const ProductAbbr = styled.div`
  font-size: ${theme.font.size.s};
`;

const Styles = styled.div<{
  height: number;
  isSelected?: boolean;
  onClick?: Props['onClick'];
  width?: number;
}>`
  background-color: ${theme.color.white};
  border-radius: ${theme.space.xs};
  ${({ isSelected }) =>
    isSelected ? `box-shadow: ${theme.layer.boxShadow.calendar};` : ''}
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height}px;
  overflow: hidden;
  position: relative;
  width: ${({ width }) => (width ? `${width}px;` : '100%')};
`;

const TimeText = styled.span`
  font-size: ${theme.font.size.s};
  font-weight: ${theme.font.weight.regular};
  letter-spacing: 0.02rem;
`;

export { CalendarCard };
