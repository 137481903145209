import gql from 'graphql-tag';

import { CouponUpdate_Fragment_coupon } from 'app/components/organisms/CouponUpdate/fragment';

import { CouponsTable_Fragment_coupon } from './fragment';

export const COUPONS_TABLE_QUERY = gql`
  query CouponsTable_Query {
    coupons: listCoupons(onlyRedeemable: false) {
      edges {
        node {
          ...CouponsTable_Fragment_coupon
          ...CouponUpdate_Fragment_coupon
        }
      }
    }
  }
  ${CouponsTable_Fragment_coupon}
  ${CouponUpdate_Fragment_coupon}
`;
