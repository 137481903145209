import { useMutation, useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import compact from 'lodash/compact';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { ADateField } from 'app/components/deprecated/ADateField/ADateField';
import {
  AForm,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { ARadioField } from 'app/components/deprecated/ARadioField/ARadioField';
import { ASelectCouponField } from 'app/components/deprecated/ASelectCouponField/ASelectCouponField';
import { ASelectField } from 'app/components/deprecated/ASelectField/ASelectField';
import { theme } from 'app/styles/theme';
import {
  PlanPurchaseUpdate_Coupon_Mutation,
  PlanPurchaseUpdate_Coupon_MutationVariables,
} from 'app/types/generated/PlanPurchaseUpdate_Coupon_Mutation';
import { PlanPurchaseUpdate_Fragment_planPurchase } from 'app/types/generated/PlanPurchaseUpdate_Fragment_planPurchase';
import { PlanPurchaseUpdate_Query } from 'app/types/generated/PlanPurchaseUpdate_Query';
import { displayErrors } from 'app/utils/app';
import { getPlanPaymentOptions } from 'app/utils/plan';
import { getPlanPurchaseAmountDue } from 'app/utils/planPurchase';
import { STATUS_MESSAGE } from 'constants/message';

import {
  PLAN_PURCHASE_UPDATE_COUPON_MUTATION,
  PLAN_PURCHASE_UPDATE_QUERY,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  planPurchase: PlanPurchaseUpdate_Fragment_planPurchase;
}

/** Form to update a member's care plan if it hasn't started yet */
const PlanPurchaseUpdate: FC<Props> = ({ planPurchase }) => {
  const { data, loading: queryLoading } = useQuery<PlanPurchaseUpdate_Query>(
    PLAN_PURCHASE_UPDATE_QUERY
  );
  const [updatePlanPurchaseCoupon, { loading: mutationLoading }] = useMutation<
    PlanPurchaseUpdate_Coupon_Mutation,
    PlanPurchaseUpdate_Coupon_MutationVariables
  >(PLAN_PURCHASE_UPDATE_COUPON_MUTATION);

  const onSave = async (
    input: { [K in keyof typeof fields]: any },
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      const result = await updatePlanPurchaseCoupon({
        variables: {
          input: {
            couponSlug: input[fields.couponSlug.name],
            purchaseID: planPurchase.id,
          },
        },
      });
      if (result?.data) {
        message.success(STATUS_MESSAGE.planPurchaseUpdate.success);
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.planPurchaseUpdate.error.general);
      shouldFinish(false);
    }
  };

  return (
    <AForm
      disableOpen={
        planPurchase.endDateIncludingExtensions &&
        DateTime.fromISO(planPurchase.endDateIncludingExtensions) <
          DateTime.local()
          ? { message: STATUS_MESSAGE.planPurchaseUpdate.error.planEnded }
          : planPurchase.endTime &&
            DateTime.fromISO(planPurchase.endTime) < DateTime.local()
          ? { message: STATUS_MESSAGE.planPurchaseUpdate.error.onExtension }
          : undefined
      }
      loading={queryLoading || !data || !data.coupons}
      onSave={onSave}
      openAs="modal"
      openText="Edit care plan"
      saving={mutationLoading}
      title={`Edit ${planPurchase.plan.product.displayName}`}
    >
      {(form) => {
        const coupons = data?.coupons?.edges || [];
        const selectedCoupon = form.isFieldTouched(fields.couponSlug.name)
          ? coupons
              .map(({ node }) => node)
              .find(
                (coupon) =>
                  coupon.slug === form.getFieldValue(fields.couponSlug.name)
              )
          : planPurchase.coupon;
        const couponChanged = planPurchase.coupon
          ? !selectedCoupon || selectedCoupon.slug !== planPurchase.coupon.slug
          : !!selectedCoupon;

        return (
          <>
            {/* Product */}
            <AFormFieldRow>
              <ASelectField
                antdForm={form}
                customWidth={{ percent: 70 }}
                disabled
                initialValue={planPurchase.plan.product.type}
                label={fields.product.label}
                name={fields.product.name}
                selectOptions={[
                  {
                    label: planPurchase.plan.product.displayName,
                    value: planPurchase.plan.product.type,
                  },
                ]}
              />
            </AFormFieldRow>

            {/* Date */}
            <AFormFieldRow>
              <ADateField
                antdForm={form}
                disabled
                initialValue={DateTime.fromISO(
                  planPurchase.startTime
                ).toISODate()}
                label={fields.startTime.label}
                name={fields.startTime.name}
              />
            </AFormFieldRow>

            {/* Payment plan */}
            <AFormFieldRow>
              <ARadioField
                antdForm={form}
                disabled
                initialValue={planPurchase.plan.id}
                label={
                  planPurchase.plan.product.type.includes('WAITLIST')
                    ? 'Plan'
                    : fields.planID.label
                }
                name={fields.planID.name}
                radioOptions={getPlanPaymentOptions(
                  planPurchase.plan.product.type,
                  [planPurchase.plan]
                )}
                vertical
              />
            </AFormFieldRow>

            {/* Coupon */}
            <AFormFieldRow>
              <ASelectCouponField
                antdForm={form}
                coupons={coupons.map(({ node }) => node)}
                couponsToAlwaysAllow={compact([planPurchase.coupon])}
                customWidth={{ percent: 80 }}
                filterValues={{
                  centerID: planPurchase.center.id,
                  planID: planPurchase.plan.id,
                  product: planPurchase.plan.product.type,
                }}
                initialValue={
                  planPurchase.coupon ? planPurchase.coupon.slug : undefined
                }
                label={fields.couponSlug.label}
                name={fields.couponSlug.name}
              />
            </AFormFieldRow>

            {couponChanged && (
              <Summary>
                <SummaryTitle>Summary</SummaryTitle>
                <ATextLight>
                  {planPurchase.plan.product.type.includes('WAITLIST')
                    ? 'Coupon discounts will be applied when the member is enrolled off the waitlist.'
                    : getPlanPurchaseAmountDue(
                        planPurchase.plan,
                        selectedCoupon
                      ).replace('first invoice', 'next invoice')}
                </ATextLight>
              </Summary>
            )}
          </>
        );
      }}
    </AForm>
  );
};

// Styled components ////////////////////////////////
const Summary = styled.div`
  margin-right: ${theme.space.s};
  width: 100%;
`;

const SummaryTitle = styled.div`
  font-weight: ${theme.font.weight.medium};
`;

/** Form field info */
const fields = {
  couponSlug: {
    label: 'Coupon code',
    name: 'couponSlug',
  },
  planID: {
    label: 'Payment',
    name: 'planID',
  },
  product: {
    label: 'Product',
    name: 'product',
  },
  startTime: {
    label: 'Start date',
    name: 'startTime',
  },
};

export { fields, PlanPurchaseUpdate };
