import {
  PapiAcuityInstance,
  PapiEmploymentType,
  PapiProfessionalSuffix,
  PapiProviderType,
  PapiSex,
  PapiProductType,
} from 'app/types/generated/globalTypes';
import { ProviderBasics_Query_provider } from 'app/types/generated/ProviderBasics_Query';
import { ProvidersTable_Query_providers } from 'app/types/generated/ProvidersTable_Query';
import { ProviderUpdate_Fragment_provider } from 'app/types/generated/ProviderUpdate_Fragment_provider';
import { TYPENAMES } from 'app/types/papi';

import { MOCK_CENTERS } from './center';

type Provider = ProvidersTable_Query_providers &
  ProviderBasics_Query_provider &
  Partial<ProviderUpdate_Fragment_provider>;

export const MOCK_PROVIDER_FOR_CALENDAR: Provider = {
  __typename: 'PapiProvider',
  acuityID: 'acuitylilli123',
  acuityInstance: PapiAcuityInstance.ACUITY_NYC,
  assignedCareManager: null,
  biologicalSex: PapiSex.FEMALE,
  centers: [
    {
      __typename: TYPENAMES.PapiProviderCenter,
      center: MOCK_CENTERS[0],
      takingNewPatients: false,
    },
  ],
  currentPanelSize: 10,
  daysPerWeek: '5',
  deleted: false,
  displayName: 'Lilli Link, M.D.',
  email: 'lilli@parsleyhealth.com',
  employmentType: PapiEmploymentType.FULL_TIME,
  firstName: 'Lilli',
  helpScoutID: 'helpscoutlilli123',
  id: 'test-1234',
  lastName: 'Link',
  maxHours: '24',
  mdhqId: '123',
  middleName: null,
  payerCredentials: [
    {
      __typename: 'PapiProviderPayerCredentials',
      centerID: '12345',
      payerID: '1234',
    },
  ],
  pod: {
    __typename: 'PapiPod',
    id: '1',
    name: 'Poppy',
  },
  products: [
    {
      __typename: 'PapiProduct',
      abbreviation: 'CC',
      displayName: 'Founding Membership',
      type: PapiProductType.COMPLETE_CARE_Y1,
    },
  ],
  professionalSuffix: PapiProfessionalSuffix.MD,
  sanityID: 'sanityid123',
  sanityProfile: {
    __typename: 'SanityPerson',
    imageSrc: '',
  },
  targetPanelSize: 15,
  timezone: 'America/New_York',
  type: PapiProviderType.DOCTOR,
};

export const MOCK_PROVIDERS: Provider[] = [
  {
    __typename: 'PapiProvider',
    acuityID: 'acuitylilli123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: false,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: '5',
    deleted: false,
    displayName: 'Lilli Link, M.D.',
    email: 'lilli@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Lilli',
    helpScoutID: 'helpscoutlilli123',
    id: '6abe3329-770d-49fc-8fe9-646f95276d7b',
    lastName: 'Link',
    maxHours: '24',
    mdhqId: '123',
    middleName: null,
    payerCredentials: [
      {
        __typename: 'PapiProviderPayerCredentials',
        centerID: '12345',
        payerID: '1234',
      },
    ],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.MD,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',
    type: PapiProviderType.DOCTOR,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuitykelly123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: false,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'Kelly Johnston',
    email: 'kelly@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Kelly',
    helpScoutID: 'helpscoutkelly123',
    id: 'a84ac284-0acd-423d-9bc3-cbeaf0af9fbc',
    lastName: 'Johnston',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [
      {
        __typename: 'PapiProviderPayerCredentials',
        centerID: '12345',
        payerID: '1234',
      },
    ],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 5,
    timezone: 'America/New_York',
    type: PapiProviderType.HEALTH_COACH,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuitymichael123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.MALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: '4',
    deleted: false,
    displayName: 'Michael Chen, M.D.',
    email: 'michaelchen@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Michael',
    helpScoutID: 'helpscoutmichael123',
    id: '2fd17fa9-2a7a-41de-8192-9ad28b77c0d9',
    lastName: 'Chen',
    maxHours: '16',
    mdhqId: '123',
    middleName: null,
    payerCredentials: [
      {
        __typename: 'PapiProviderPayerCredentials',
        centerID: '12345',
        payerID: '1234',
      },
    ],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.MD,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.DOCTOR,
  },
  {
    __typename: 'PapiProvider',
    acuityID: null,
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.OTHER,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: false,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'Wednesday Adams',
    email: 'mxnyc@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Membership',
    helpScoutID: 'helpscoutmxnyc123',
    id: '3d4e422a-7685-4d86-b1a0-f11b3ad2ccf2',
    lastName: 'Experience',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [
      {
        __typename: 'PapiProviderPayerCredentials',
        centerID: '12345',
        payerID: '1234',
      },
    ],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.MX_PROVIDER_GROUP,
  },
  {
    __typename: 'PapiProvider',
    acuityID: null,
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.OTHER,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[2],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'SF Care Managers',
    email: 'cmsf@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'SF',
    helpScoutID: 'helpscoutcmsf123',
    id: '19830f01-f795-4ac7-856f-0d48bb04b676',
    lastName: 'Care Managers',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [
      {
        __typename: 'PapiProviderPayerCredentials',
        centerID: '12345',
        payerID: '1234',
      },
    ],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/Los_Angeles',

    type: PapiProviderType.CARE_MANAGER_PROVIDER_GROUP,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuitykerri123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[2],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: '3',
    deleted: false,
    displayName: 'Kerri Masutto, M.D.',
    email: 'kerri@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Kerri',
    helpScoutID: 'helpscoutkerri123',
    id: '8881f402-216f-4cac-b69e-b052e0f61501',
    lastName: 'Masutto',
    maxHours: '21',
    mdhqId: '123',
    middleName: null,
    payerCredentials: [
      {
        __typename: 'PapiProviderPayerCredentials',
        centerID: '12345',
        payerID: '1234',
      },
    ],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.MD,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/Los_Angeles',

    type: PapiProviderType.DOCTOR,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuityrachael123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[1],
        takingNewPatients: false,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: '5',
    deleted: false,
    displayName: 'Rachael Gonzalez, M.D.',
    email: 'rachael@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Rachael',
    helpScoutID: 'helpscoutrachael123',
    id: '6df89557-5aae-40af-8ec3-161e899bb307',
    lastName: 'Gonzalez',
    maxHours: '24',
    mdhqId: '123',
    middleName: null,
    payerCredentials: [
      {
        __typename: 'PapiProviderPayerCredentials',
        centerID: '12345',
        payerID: '1234',
      },
    ],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.MD,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/Los_Angeles',

    type: PapiProviderType.DOCTOR,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuityjaclyn123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[1],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: '5',
    deleted: false,
    displayName: 'Jaclyn Tolentino, D.O.',
    email: 'jaclyn@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Jaclyn',
    helpScoutID: 'helpscoutjaclyn123',
    id: '6e4af9ba-934d-4915-814a-f9f0bd740857',
    lastName: 'Tolentino',
    maxHours: '24',
    mdhqId: '123',
    middleName: null,
    payerCredentials: [
      {
        __typename: 'PapiProviderPayerCredentials',
        centerID: '12345',
        payerID: '1234',
      },
    ],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.DO,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/Los_Angeles',

    type: PapiProviderType.DOCTOR,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuitygaby123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: '5',
    deleted: false,
    displayName: 'Gaby Safdieh, M.D.',
    email: 'gaby@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Gaby',
    helpScoutID: 'helpscoutgaby123',
    id: 'ff4b25b4-d3df-4507-b243-8304de1d3a99',
    lastName: 'Safdieh',
    maxHours: '24',
    mdhqId: '123',
    middleName: null,
    payerCredentials: [
      {
        __typename: 'PapiProviderPayerCredentials',
        centerID: '12345',
        payerID: '1234',
      },
    ],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.MD,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.DOCTOR,
  },
  {
    __typename: 'PapiProvider',
    acuityID: null,
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.OTHER,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[1],
        takingNewPatients: false,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'Membership Experience',
    email: 'mxla@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Member',
    helpScoutID: 'helpscoutmxla123',
    id: 'a739aa4e-fb7e-4f82-8271-42ea94eb083f',
    lastName: 'Experience',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [
      {
        __typename: 'PapiProviderPayerCredentials',
        centerID: '12345',
        payerID: '1234',
      },
    ],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/Los_Angeles',

    type: PapiProviderType.MX_PROVIDER_GROUP,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuitylabtechny123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'NYC Lab Technician',
    email: 'labtechny@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'NYC',
    helpScoutID: 'helpscoutlabtechny123',
    id: '4127f974-19e1-43cc-8caf-4701475d694f',
    lastName: 'Lab Technician',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.PHLEBOTOMIST,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuitymobilelabtechla123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.OTHER,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[1],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'Mobile Lab Technician',
    email: 'mobilelabtechla@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Mobile',
    helpScoutID: 'helpscoutmobilelabtechla123',
    id: '2dced543-92e2-4fc9-9ef1-6543bdc1ad34',
    lastName: 'Lab Technician',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/Los_Angeles',

    type: PapiProviderType.PHLEBOTOMIST,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuityrobin123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: false,
      },
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[4],
        takingNewPatients: false,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: '3',
    deleted: false,
    displayName: 'Robin Berzin, M.D.',
    email: 'robin@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Robin',
    helpScoutID: 'helpscoutrobin123',
    id: '1b95a120-cb63-4534-9faf-e044977bd6fe',
    lastName: 'Berzin',
    maxHours: '18',
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.MD,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.DOCTOR,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuityonsitelabtechla123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[1],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'On-site Lab Technician',
    email: 'onsitelabtechla@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Onsite',
    helpScoutID: 'helpscoutonsitelabtechla123',
    id: 'c6ec1bab-952a-43bc-a086-e7f209e866e0',
    lastName: 'Lab Technician',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/Los_Angeles',

    type: PapiProviderType.PHLEBOTOMIST,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuitytracy123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: false,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'Tracy Scott',
    email: 'tracy@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Tracy',
    helpScoutID: 'helpscouttracy123',
    id: 'f93f8d1b-39f2-49b9-b78f-ebbe73f36251',
    lastName: 'Scott',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.HEALTH_COACH,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuitytiffany123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[2],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: '5',
    deleted: false,
    displayName: 'Tiffany Lester, M.D.',
    email: 'tiffany@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Tiffany',
    helpScoutID: 'helpscouttiffany123',
    id: '5d3cb9ba-adc8-4aba-a3ab-21caf5d462e5',
    lastName: 'Lester',
    maxHours: '24',
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.MD,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/Los_Angeles',

    type: PapiProviderType.DOCTOR,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuityzandra123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: false,
      },
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[1],
        takingNewPatients: true,
      },
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[3],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: '5',
    deleted: false,
    displayName: 'Alexandra Palma, M.D.',
    email: 'zandra@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Alexandra',
    helpScoutID: 'helpscoutzandra123',
    id: 'ee902b99-bf1b-417e-a4ce-6b5bd05c6980',
    lastName: 'Palma',
    maxHours: '24',
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.MD,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.DOCTOR,
  },
  {
    __typename: 'PapiProvider',
    acuityID: null,
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: true,
    displayName: 'Kayla Menendez',
    email: 'kayla@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Kayla',
    helpScoutID: 'helpscoutkayla123',
    id: 'e9e21025-5ba1-433b-94ed-e09fd4f3aea6',
    lastName: 'Menendez',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.CARE_MANAGER,
  },
  {
    __typename: 'PapiProvider',
    acuityID: null,
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.OTHER,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'NYC Care Managers',
    email: 'cmny@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'NYC',
    helpScoutID: 'helpscoutcmny123',
    id: 'bf47796f-6734-4ead-8219-133790ea586a',
    lastName: 'Care Managers',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.CARE_MANAGER_PROVIDER_GROUP,
  },
  {
    __typename: 'PapiProvider',
    acuityID: null,
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.MALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'Michael Edward Yudelevich, M.D.',
    email: 'michaelyudelevich@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Michael',
    helpScoutID: 'helpscoutmichaelyudelevich123',
    id: '814ac26a-7eca-4c6f-982e-7f6d6c7a826d',
    lastName: 'Yudelevich',
    maxHours: null,
    middleName: 'Edward',
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.MD,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.CARE_MANAGER,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuitydarcy123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: '5',
    deleted: false,
    displayName: 'Darcy Parish McConnell, M.D.',
    email: 'darcy@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Darcy',
    helpScoutID: 'helpscoutdarcy123',
    id: '426361b0-3c63-4859-b952-29724b4b93bb',
    lastName: 'McConnell',
    maxHours: '24',
    middleName: 'Parish',
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.MD,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.DOCTOR,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuityhelaine123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'Helaine Schonfeld',
    email: 'helaine@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Helaine',
    helpScoutID: 'helpscouthelaine123',
    id: '427ac2f5-832a-4933-98e8-9c56571d8459',
    lastName: 'Schonfeld',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.HEALTH_COACH,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuityharika123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: '5',
    deleted: false,
    displayName: 'Harika Pal, M.D.',
    email: 'harika@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Harika',
    helpScoutID: 'helpscoutharika123',
    id: 'cafc80b4-c691-403a-a5b2-9e5ab08503b1',
    lastName: 'Pal',
    maxHours: '24',
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.MD,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.DOCTOR,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuityjulie123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: '5',
    deleted: false,
    displayName: 'Julie Taw, M.D.',
    email: 'julie@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Julie',
    helpScoutID: 'helpscoutjulie123',
    id: 'ab9fe44b-3586-4f67-8ada-bb9804525373',
    lastName: 'Taw',
    maxHours: '24',
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.MD,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.DOCTOR,
  },
  {
    __typename: 'PapiProvider',
    acuityID: null,
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.MALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'Dan Pappo',
    email: 'dan@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Dan',
    helpScoutID: 'helpscoutdan123',
    id: 'fa75b124-e58e-4594-a66f-dee1a4c790af',
    lastName: 'Pappo',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.CARE_MANAGER,
  },
  {
    __typename: 'PapiProvider',
    acuityID: null,
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.OTHER,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[2],
        takingNewPatients: false,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'Membership Experience',
    email: 'mxsf@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Member',
    helpScoutID: 'helpscoutmxsf123',
    id: '6b1c2dab-5f57-4492-a181-8873d568ed58',
    lastName: 'Experience',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/Los_Angeles',

    type: PapiProviderType.MX_PROVIDER_GROUP,
  },
  {
    __typename: 'PapiProvider',
    acuityID: null,
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[1],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'Christina Wakefield',
    email: 'christina@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Christina',
    helpScoutID: 'helpscoutchristina123',
    id: 'b2f0ab1e-9bc2-4835-9149-fbbe8206b692',
    lastName: 'Wakefield',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/Los_Angeles',

    type: PapiProviderType.CARE_MANAGER,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuitychristinav123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[1],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'Christina Vittas',
    email: 'christinav@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Christina',
    helpScoutID: 'helpscoutchristinav123',
    id: '48cdaed0-b682-46b3-8474-e3e72c451622',
    lastName: 'Vittas',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/Los_Angeles',

    type: PapiProviderType.HEALTH_COACH,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuityjackie123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: false,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'Jackie Damboragian',
    email: 'jackie@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Jackie',
    helpScoutID: 'helpscoutjackie123',
    id: 'fa6fc49a-4072-48be-a822-bc16ac701614',
    lastName: 'Damboragian',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.HEALTH_COACH,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuityjamie123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.FEMALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[0],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'Jamie Kyei, N.P.',
    email: 'jamie@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'Jamie',
    helpScoutID: 'helpscoutjamie123',
    id: '11fb89c3-c10a-44b9-8938-40c17c0c6fb2',
    lastName: 'Kyei',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.NP,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/New_York',

    type: PapiProviderType.NURSE,
  },
  {
    __typename: 'PapiProvider',
    acuityID: null,
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.OTHER,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[1],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: null,
    deleted: false,
    displayName: 'LA Care Managers',
    email: 'cmla@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'LA',
    helpScoutID: 'helpscoutcmla123',
    id: 'cb3c4b99-bb57-4100-8267-96686b58c91a',
    lastName: 'Care Managers',
    maxHours: null,
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: null,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/Los_Angeles',

    type: PapiProviderType.CARE_MANAGER_PROVIDER_GROUP,
  },
  {
    __typename: 'PapiProvider',
    acuityID: 'acuityjohn123',
    acuityInstance: PapiAcuityInstance.ACUITY_NYC,
    assignedCareManager: null,
    biologicalSex: PapiSex.MALE,
    centers: [
      {
        __typename: TYPENAMES.PapiProviderCenter,
        center: MOCK_CENTERS[3],
        takingNewPatients: true,
      },
    ],
    currentPanelSize: 10,
    daysPerWeek: '5',
    deleted: false,
    displayName: 'John Zoidberg, M.D.',
    email: 'john@parsleyhealth.com',
    employmentType: PapiEmploymentType.FULL_TIME,
    firstName: 'John',
    helpScoutID: 'helpscoutjohn123',
    id: '6abe3329-770d-49fc-8fe9-646f9527zoid',
    lastName: 'Zoidberg',
    maxHours: '24',
    mdhqId: '123',
    middleName: null,
    payerCredentials: [],
    pod: {
      __typename: 'PapiPod',
      id: '1',
      name: 'Poppy',
    },
    products: [
      {
        __typename: 'PapiProduct',
        abbreviation: 'CC',
        displayName: 'Founding Membership',
        type: PapiProductType['FOUNDING_MEMBERSHIP'],
      },
    ],
    professionalSuffix: PapiProfessionalSuffix.MD,
    sanityID: 'sanityid123',
    sanityProfile: {
      __typename: 'SanityPerson',
      imageSrc: '',
    },
    targetPanelSize: 15,
    timezone: 'America/Los_Angeles',

    type: PapiProviderType.DOCTOR,
  },
];
