import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import React, { FC, useCallback } from 'react';
import styled from 'styled-components/macro';

import { APadding } from 'app/components/atoms/APadding/APadding';
import { UnblockVisit_Mutation } from 'app/types/generated/UnblockVisit_Mutation';
import { STATUS_MESSAGE } from 'constants/message';

import { UNBLOCK_VISIT_MUTATION } from './query';

interface Props {
  appointmentID: string;
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const UnblockVisitModal: FC<Props> = ({
  appointmentID,
  isVisible,
  onClose,
  onSuccess,
}) => {
  const [unblockVisit, { error: unblockVisitError }] = useMutation<
    UnblockVisit_Mutation
  >(UNBLOCK_VISIT_MUTATION);

  const onUnblockVisit = useCallback(async () => {
    try {
      await unblockVisit({
        variables: {
          appointmentID,
        },
      });
      if (unblockVisitError) {
        message.error(STATUS_MESSAGE.visitUpdate.error.general);
        return onClose();
      }
      message.success(STATUS_MESSAGE.visitUpdate.success);
      return onSuccess();
    } catch (_) {
      message.error(STATUS_MESSAGE.visitUpdate.error.general);
      return onClose();
    }
  }, [appointmentID, onClose, onSuccess, unblockVisit, unblockVisitError]);

  return (
    <Modal
      cancelText="Close"
      destroyOnClose
      okText="Unblock"
      okType="default"
      onCancel={onClose}
      onOk={onUnblockVisit}
      visible={isVisible}
    >
      <APadding type="modal">
        <Title>Are you sure you want to unblock this visit?</Title>
        <Subtitle>
          By unblocking this visit we will remove any scheduling restrictions.
          This action cannot be undone.
        </Subtitle>
      </APadding>
    </Modal>
  );
};

// Styled components ///////////////////////////////
const Title = styled.h2`
  text-align: center;
`;

const Subtitle = styled.div`
  text-align: center;
`;
export { UnblockVisitModal };
