import gql from 'graphql-tag';

export const CouponsTable_Fragment_coupon = gql`
  fragment CouponsTable_Fragment_coupon on PapiCoupon {
    centers {
      id
      abbreviatedName
      isVirtual
    }
    description
    discount {
      ... on PapiFlatDiscount {
        amountOffCents
      }
      ... on PapiPercentageDiscount {
        percentOff
      }
    }
    duration {
      ... on PapiCouponApplies {
        apply
      }
      ... on PapiCouponRepeats {
        months
      }
    }
    maxRedemptions
    plans {
      id
      billingPeriod
      product {
        displayName
        type
      }
    }
    products {
      displayName
      type
    }
    redeemBy
    slug
  }
`;
