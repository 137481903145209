declare global {
  interface Window {
    analytics?: {
      page: (
        category?: string,
        name?: string,
        properties?: {},
        options?: {},
        callback?: () => void
      ) => void;
      track: (
        event: string,
        properties?: {},
        options?: {},
        callback?: () => {}
      ) => void;
    };
    heap: any;
  }
}

type AnalyticsEvent = 'Logged In' | 'Logged Out' | 'Session Started';

export const trackEvent = (event: AnalyticsEvent, properties?: {}): void => {
  window.analytics?.track(event, properties);
};

export const trackNavigation = (): void => {
  window.analytics?.page();
};
