import React from 'react';

const MemberAdvocateIcon: React.FC = () => {
  return (
    <svg
      fill="none"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" fill="#4F7F71" r="18" />
      <path
        clipRule="evenodd"
        d="M18.6009 12.4901V8.25H17.6009V12.4901H18.6009ZM18.5134 14.8834L18.1012 14.2828L17.6889 14.8834L9.30012 27.1048L8.7627 27.8878H9.71235H26.49H27.4396L26.9022 27.1048L18.5134 14.8834ZM10.662 26.8878L18.1012 16.0499L25.5403 26.8878H10.662ZM23.2487 11.9068L20.2212 13.9943L19.6535 13.171L22.681 11.0835L23.2487 11.9068ZM16.6499 13.1664L13.5137 11.0789L12.9596 11.9114L16.0958 13.9989L16.6499 13.1664Z"
        fill="#F8F6F2"
        fillRule="evenodd"
      />
    </svg>
  );
};

export { MemberAdvocateIcon };
