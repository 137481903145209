import capitalize from 'lodash/capitalize';

import { PapiSex, PapiRelationship } from 'app/types/generated/globalTypes';

/** Deprecated Antd validation, use FORM_VALIDATION with react-hook-form */
export const FIELD_VALIDATION = {
  dollar: {
    validator: (
      ruleIgnore: any,
      value: string | null | undefined,
      callback: any
    ) => {
      const decimalSplit = value && value.split('.');
      const cents =
        decimalSplit && decimalSplit.length === 2 ? decimalSplit[1] : undefined;

      if (
        value &&
        (Number.isNaN(parseFloat(value!)) ||
          (decimalSplit && decimalSplit.length > 2) ||
          (cents !== undefined && cents.length !== 2))
      ) {
        callback(`Enter a valid dollar amount`);
      }
      callback();
    },
  },
  email: {
    message: 'Enter a valid email',
    type: 'email',
  },
  maxLength: (maxLength: number) => ({
    max: maxLength,
    message: `Enter at most ${maxLength} character${
      maxLength === 1 ? '' : 's'
    }`,
  }),
  minLength: (minLength: number) => ({
    message: `Enter at least ${minLength} character${
      minLength === 1 ? '' : 's'
    }`,
    min: minLength,
  }),
  number: {
    validator: (
      ruleIgnore: any,
      value: string | null | undefined,
      callback: any
    ) => {
      if (
        value &&
        (Number.isNaN(parseFloat(value)) || value.split('.').length > 2)
      ) {
        callback(`Enter a valid number`);
      }
      callback();
    },
  },
  required: {
    message: 'Required',
    required: true,
  },
};

export const FORM_VALIDATION = {
  email: {
    pattern: {
      message: 'Enter a valid email',
      value: /^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
    },
  },
  required: { required: 'Required' },
  requiredArray: {
    validate: (value?: any[] | null): true | string =>
      (!!value && value.length > 0) || 'Required',
  },
  numeric: {
    validate: (value?: any | null): true | string =>
      (!!value && Number(value) === Math.floor(value)) ||
      'Must be a valid number',
  },
};

export const SEXES = Object.values(PapiSex).map((sex) => ({
  label: capitalize(sex),
  value: sex,
}));

export const US_STATES = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

export const RELATIONSHIPS = [
  { label: 'Other', value: PapiRelationship.OTHER_RELATIONSHIP },
  { label: 'Spouse / Partner', value: PapiRelationship.PARTNER },
  { label: 'Parent / Guardian', value: PapiRelationship.PARENT },
  { label: 'Relative', value: PapiRelationship.RELATIVE },
  { label: 'Friend', value: PapiRelationship.FRIEND },
  { label: 'Sibling', value: PapiRelationship.SIBLING },
  { label: 'Coworker', value: PapiRelationship.COWORKER },
];
