import { useMutation, useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { ADateField } from 'app/components/deprecated/ADateField/ADateField';
import {
  AForm,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { ARadioField } from 'app/components/deprecated/ARadioField/ARadioField';
import { ASelectPartnerField } from 'app/components/deprecated/ASelectPartnerField/ASelectPartnerField';
import { ASelectProductField } from 'app/components/deprecated/ASelectProductField/ASelectProductField';
import { theme } from 'app/styles/theme';
import {
  EnterprisePlanCreate_Mutation,
  EnterprisePlanCreate_MutationVariables,
} from 'app/types/generated/EnterprisePlanCreate_Mutation';
import { GetEnterprisePartnerPlans_Query } from 'app/types/generated/GetEnterprisePartnerPlans_Query';
import { PapiProductType } from 'app/types/generated/globalTypes';
import {
  PlanPurchaseCreate_Query,
  PlanPurchaseCreate_QueryVariables,
} from 'app/types/generated/PlanPurchaseCreate_Query';
import { displayErrors } from 'app/utils/app';
import { disablePastStartDates } from 'app/utils/datetime';
import { isValidPaymentCard } from 'app/utils/member';
import { getPlanPaymentOptions } from 'app/utils/plan';
import {
  getEligiblePlansForPurchase,
  getPlanPurchaseSummary,
} from 'app/utils/planPurchase';
import { STATUS_MESSAGE } from 'constants/message';

import {
  PLAN_PURCHASE_CREATE_QUERY,
  GET_ENTERPRISE_PARTNER_PLANS,
  ENTERPRISE_PLAN_CREATE_MUTATION,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  icon?: boolean;
  openText?: ReactNode;
  personID: string;
}

/** Form to enroll a member in a care plan ("plan purchase" in the db) */
export const EnterprisePlanCreate: FC<Props> = ({
  icon,
  openText = 'Add enterprise care plan',
  personID,
}) => {
  const { data: partnerPlanData } = useQuery<GetEnterprisePartnerPlans_Query>(
    GET_ENTERPRISE_PARTNER_PLANS,
    {
      variables: {
        slug: 'partner-a',
      },
    }
  );

  const { data, loading: queryLoading } = useQuery<
    PlanPurchaseCreate_Query,
    PlanPurchaseCreate_QueryVariables
  >(PLAN_PURCHASE_CREATE_QUERY, { variables: { personID } });

  const [
    createEnterprisePlan,
    { loading: createEnterprisePlanLoading },
  ] = useMutation<
    EnterprisePlanCreate_Mutation,
    EnterprisePlanCreate_MutationVariables
  >(ENTERPRISE_PLAN_CREATE_MUTATION);

  const assignedCenter = data?.member?.center;
  const memberPlans = data?.member?.allPlans?.edges || [];

  const hasNonEndingPlan =
    memberPlans.filter(({ node }) => node.endTime === null).length > 0;

  const onOpen = (): void => {
    if (!data || !data.member || hasNonEndingPlan || !assignedCenter) {
      return;
    }
    if (!data.member.dateOfBirth) {
      message.warn(STATUS_MESSAGE.planPurchaseCreate.warning.noMemberAge, 5);
    }
    if (
      data.member.paymentCards.edges.filter(({ node }) =>
        isValidPaymentCard(node)
      ).length < 1
    ) {
      message.warn(STATUS_MESSAGE.planPurchaseCreate.warning.noValidPayment, 5);
    }
  };

  const onSave = async (
    input: { [K in keyof typeof fields]: any },
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      if (!assignedCenter) {
        throw new Error(STATUS_MESSAGE.planPurchaseCreate.error.noCenter);
      }

      const response = await createEnterprisePlan({
        variables: {
          input: {
            centerID: assignedCenter.id,
            partnerSlug: input.partnerID,
            payorID: personID,
            personID,
            planID: input.planID,
          },
        },
      });

      if (response?.data) {
        message.success(STATUS_MESSAGE.planPurchaseCreate.success);
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.planPurchaseCreate.error.general);
      shouldFinish(false);
    }
  };

  return (
    <AForm
      disableOpen={
        !data || !data.member
          ? { message: <ALoading color="white" size="small" /> }
          : !assignedCenter
          ? { message: STATUS_MESSAGE.planPurchaseCreate.error.noCenter }
          : hasNonEndingPlan
          ? { message: STATUS_MESSAGE.planPurchaseCreate.error.neverendingPlan }
          : undefined
      }
      icon={
        icon
          ? { tooltipText: 'Add enterprise care plan', type: 'plus' }
          : undefined
      }
      loading={queryLoading || !data || !data.member || !data.plans}
      onOpen={onOpen}
      onSave={onSave}
      openAs="modal"
      openText={openText}
      saveText="Add"
      saving={createEnterprisePlanLoading}
      savingText="Adding"
      title="New enterprise care plan"
    >
      {(form) => {
        const plans = data?.plans?.edges || [];
        const eligiblePlans =
          (
            data?.member &&
            getEligiblePlansForPurchase(
              data.member,
              plans.map(({ node }) => node)
            ).filter((plan) => plan.product.type !== PapiProductType.EXTENSION)
          )?.filter((plan) => {
            const partnerPlan = partnerPlanData?.getPartnerPlans;
            return partnerPlan ? plan.id === partnerPlan[0].planID : true;
          }) || [];

        // Clear all plan selection fields when original product changes
        const selectedProduct = form.getFieldValue(fields.product.name);
        const onProductChange = (): void =>
          form.setFieldsValue({
            [fields.planID.name]: undefined,
          });

        // Set default follow-on or clear follow-on fields when plan payment changes
        const selectedPlan = eligiblePlans.find(
          (plan) => plan.id === form.getFieldValue(fields.planID.name)
        );

        return (
          <>
            {/* Partner */}
            <AFormFieldRow>
              <ASelectPartnerField
                antdForm={form}
                customWidth={{ percent: 40 }}
                label={fields.partnerID.label}
                name={fields.partnerID.name}
                required={true}
              />
            </AFormFieldRow>
            {/* Product */}
            <AFormFieldRow>
              <ASelectProductField
                antdForm={form}
                customWidth={{ percent: 70 }}
                enabledProducts={uniq(
                  eligiblePlans.map((plan) => plan.product.type)
                )}
                label={fields.product.label}
                name={fields.product.name}
                onChange={onProductChange}
                required
              />
            </AFormFieldRow>

            {/* Date */}
            <AFormFieldRow>
              <ADateField
                antdForm={form}
                disableDate={
                  assignedCenter
                    ? {
                        filter: disablePastStartDates(assignedCenter.timezone),
                      }
                    : undefined
                }
                initialValue={
                  !assignedCenter
                    ? undefined
                    : DateTime.fromObject({
                        zone: assignedCenter.timezone,
                      }).toISODate()
                }
                label={fields.startTime.label}
                name={fields.startTime.name}
                required={true}
              />
            </AFormFieldRow>

            {/* Payment plan */}
            <AFormFieldRow>
              <ARadioField
                antdForm={form}
                disabled={!selectedProduct}
                label={
                  selectedProduct && selectedProduct.includes('WAITLIST')
                    ? 'Plan'
                    : fields.planID.label
                }
                name={fields.planID.name}
                radioOptions={
                  selectedProduct
                    ? getPlanPaymentOptions(selectedProduct, eligiblePlans)
                    : [
                        {
                          label: 'Select a care plan',
                          value: 'productDisabled',
                        },
                      ]
                }
                required
                vertical
              />
            </AFormFieldRow>

            {selectedPlan && !selectedProduct.includes('WAITLIST') && (
              <Summary>
                <SummaryTitle>Summary</SummaryTitle>
                <ATextLight>
                  {getPlanPurchaseSummary(
                    selectedPlan,
                    DateTime.fromJSDate(
                      (form.getFieldValue(
                        fields.startTime.name
                      ) as Moment).toDate()
                    ),
                    true,
                    true
                  )}
                </ATextLight>
              </Summary>
            )}
          </>
        );
      }}
    </AForm>
  );
};

// Styled components ////////////////////////////////
const Summary = styled.div`
  margin-right: ${theme.space.s};
  width: 100%;
`;

const SummaryTitle = styled.div`
  font-weight: ${theme.font.weight.medium};
`;

/** Form field info */
const fields = {
  partnerID: {
    label: 'Enterprise Partner',
    name: 'partnerID',
  },
  planID: {
    label: 'Payment',
    name: 'planID',
  },
  product: {
    label: 'Product',
    name: 'product',
  },
  startTime: {
    label: 'Start date',
    name: 'startTime',
  },
};
