import gql from 'graphql-tag';

export const VISIT_DELETE_MUTATION = gql`
  mutation VisitDelete_Mutation($appointmentID: ID!) {
    deleteAppointment(appointmentID: $appointmentID) {
      id
    }
  }
`;

export const VISIT_CANCEL_MUTATION = gql`
  mutation VisitCancel_Mutation($appointmentID: ID!) {
    cancelAppointment(appointmentID: $appointmentID) {
      id
    }
  }
`;
