import gql from 'graphql-tag';

export const A_SELECT_PROVIDER_CENTER_QUERY = gql`
  query ASelectProviderCenter_Query {
    centers: listCenters {
      id
      abbreviatedName
      city
      isVirtual
      state
    }
  }
`;
