import {
  PapiInboxItemCategory,
  PapiInboxItemSourceCategory,
} from 'app/types/generated/globalTypes';

import { STATUS_MESSAGE } from './message';

export const DOCUMENT_SOURCE_ATTRS: Record<
  PapiInboxItemSourceCategory,
  Record<'label' | 'icon', string>
> = {
  [PapiInboxItemSourceCategory.EFAX]: {
    icon: 'pull-request',
    label: 'eFax',
  },
  [PapiInboxItemSourceCategory.LABORATORY]: {
    icon: 'pull-request',
    label: 'Laboratory',
  },
  [PapiInboxItemSourceCategory.UPLOAD]: {
    icon: 'pull-request',
    label: 'Member Upload',
  },
};

export const DOCUMENT_TYPE_ATTRS: Record<
  PapiInboxItemCategory,
  Record<'label' | 'icon', string>
> = {
  [PapiInboxItemCategory.LAB_RESULTS]: {
    icon: 'experiment',
    label: 'Lab Results',
  },
  [PapiInboxItemCategory.MEDICAL_HISTORY]: {
    icon: 'medicine-box',
    label: 'Medical History',
  },
  [PapiInboxItemCategory.RADIOLOGY]: {
    icon: 'wifi',
    label: 'Radiology',
  },
  [PapiInboxItemCategory.ADMINISTRATIVE]: {
    icon: 'file-protect',
    label: 'Administrative Document',
  },
  [PapiInboxItemCategory.CONSULTS]: {
    icon: 'message',
    label: 'Consults',
  },
  [PapiInboxItemCategory.INVOICES]: {
    icon: 'dollar',
    label: 'Invoices',
  },
  [PapiInboxItemCategory.MISC]: {
    icon: 'folder-open',
    label: 'Miscellaneous',
  },
};

/* NO MEMBER */
export type NoMemberAction = 'archive' | 'trash';
export const NO_MEMBER_ACTION_VALUES: NoMemberAction[] = ['archive', 'trash'];

export const NO_MEMBER_ACTION_ATTRS: Record<
  NoMemberAction,
  Record<'label' | 'icon', string>
> = {
  archive: {
    icon: 'folder-open',
    label: 'Archive',
  },
  trash: {
    icon: 'delete',
    label: 'Put into trash',
  },
};

export const DOCUMENT_ACTION_FIELDS = {
  docTitle: {
    componentProps: {
      customWidth: { percent: 100 },
      name: 'docTitle',
      rules: {
        required: STATUS_MESSAGE.documentReview.error.docTitleRequired,
      },
    },
    icon: 'file-text',
    label: 'Document title',
  },
  docType: {
    componentProps: {
      customWidth: { fixed: '240px' },
      name: 'docType',
      rules: { required: true },
    },
    icon: 'file',
    label: 'Document type',
  },
  noMemberAction: {
    componentProps: {
      defaultValue: 'archive',
      name: 'noMemberAction',
      rules: { required: true },
    },
    icon: 'file-text',
    label: 'What to do',
  },
  providerID: {
    componentProps: {
      name: 'providerID',
      rules: { required: true },
    },
    label: 'for',
  },
  task: {
    componentProps: {
      defaultValue: 'create',
      name: 'task',
      rules: { required: true },
    },
    icon: 'schedule',
  },
};
