import sortBy from 'lodash/sortBy';

import { PapiProviderType } from 'app/types/generated/globalTypes';
import { formatProviderRole } from 'app/utils/provider';

export const PROVIDER_ROLES = sortBy(
  Object.values(PapiProviderType).filter(
    (role) =>
      role !== PapiProviderType.MX_PROVIDER_GROUP &&
      role !== PapiProviderType.CARE_MANAGER_PROVIDER_GROUP
  ),
  formatProviderRole
);

export const NON_CM_ASSIGNABLE_PROVIDER_TYPES = [
  PapiProviderType.CARE_MANAGER,
  PapiProviderType.HEALTH_COACH,
] as Array<PapiProviderType | undefined>;

export const PROVIDER_TYPES_WITH_VISITS = [
  PapiProviderType.DOCTOR,
  PapiProviderType.CARE_MANAGER,
  PapiProviderType.HEALTH_COACH,
  PapiProviderType.NURSE,
  PapiProviderType.PHLEBOTOMIST,
  PapiProviderType.PEDIATRICIAN,
];

export const PROVIDER_TYPE_CLINICIANS = [
  PapiProviderType.DOCTOR,
  PapiProviderType.PEDIATRICIAN,
];

export type PROVIDER_TYPE = 'clinician' | 'health-coach' | 'member-advocate';

export const DEFAULT_MAX_HOURS = 24;
export const DEFAULT_DAYS_PER_WEEK = 5;
