import { Form, Input } from 'antd';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/lib/form/Form';
import { InputProps } from 'antd/lib/input';
import React, { FC } from 'react';

import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';

// Types & constants ////////////////////////////////
interface Props extends InputProps, AFormFieldWrapperProps {
  antdForm: WrappedFormUtils;
  confirmationText?: string;
  label?: string;
  name: string;
  options?: GetFieldDecoratorOptions;
}

/**
 * Deprecated: Antd form field, being deprecated to use react-hook-form.
 * User must enter confirmation text to proceed, e.g. for confirming deletion
 */
const AConfirmationField: FC<Props> = ({
  antdForm,
  confirmationText = 'confirm',
  customWidth,
  label,
  name,
  options,
  placeholder,
  show,
  ...props
}) => {
  return (
    <AFormFieldWrapper antd customWidth={customWidth} show={show}>
      <Form.Item colon={false} label={label}>
        {antdForm.getFieldDecorator(name, {
          ...options,
          rules: [
            ...(options?.rules || []),
            {
              validator: (ruleIgnore, value, callback) => {
                if (
                  !value ||
                  value.trim().toLowerCase() !== confirmationText.toLowerCase()
                ) {
                  callback(
                    `Enter ${confirmationText.toUpperCase()} to continue`
                  );
                }
                callback();
              },
            },
          ],
          validateTrigger: [
            'onBlur',
            // only validate onChange if there is already an error
            ...(antdForm.getFieldError(name) ? ['onChange'] : []),
          ],
        })(
          <Input
            placeholder={
              placeholder || `Enter ${confirmationText.toUpperCase()}`
            }
            {...props}
          />
        )}
      </Form.Item>
    </AFormFieldWrapper>
  );
};

export { AConfirmationField };
