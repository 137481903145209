import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import React, { FC, useEffect } from 'react';
import {
  FieldError,
  FormContextValues,
  ValidationOptions,
} from 'react-hook-form';
import styled from 'styled-components/macro';

import { AFormFieldError } from 'app/components/atoms/AFormFieldError/AFormFieldError';
import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
export interface ACheckboxProps extends CheckboxProps, AFormFieldWrapperProps {
  disableAutoComplete?: boolean;
  /** @param formHook react-hook-form useForm() */
  formHook?: FormContextValues<any>;
  label?: string;
  labelInfo?: string;
  name?: string;
  /** @param rules with react-hook-form only */
  rules?: ValidationOptions;
  setDefaultValue?: boolean;
}

/** Input field that can be used alone or with react-hook-form */
const ACheckbox: FC<ACheckboxProps> = ({
  customWidth,
  defaultChecked,
  formHook,
  label,
  labelInfo,
  name,
  rules,
  setDefaultValue = true,
  show,
  ...props
}) => {
  useEffect(() => {
    // Register form field & set default value once
    if (formHook && name) {
      formHook.register({ name }, rules);
      setDefaultValue && formHook.setValue(name, Boolean(defaultChecked));
      return () => formHook.unregister(name);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const errorMessage =
    name &&
    formHook?.errors[name] &&
    (formHook.errors[name] as FieldError).message;

  return (
    <AFormFieldWrapper customWidth={customWidth} show={show}>
      <CheckboxWrapper error={!!errorMessage}>
        <Checkbox
          data-testid={name}
          defaultChecked={defaultChecked}
          id={name}
          onChange={(e) => {
            if (formHook && name) {
              formHook.setValue(name, e.target.checked);
              if (formHook.errors[name]) {
                formHook.triggerValidation(name);
              }
            }
          }}
          {...props}
        >
          {label && (
            <ALabel
              black
              help={labelInfo}
              htmlFor={name}
              required={!!rules?.required}
            >
              {label}
            </ALabel>
          )}
        </Checkbox>
      </CheckboxWrapper>

      <AFormFieldError show={!!errorMessage}>{errorMessage}</AFormFieldError>
    </AFormFieldWrapper>
  );
};

// Styled components ////////////////////////////////
const CheckboxWrapper = styled.div<{ error: boolean }>`
  .ant-checkbox-inner {
    border-color: ${({ error }) =>
      error ? theme.color.error : theme.color.border} !important;
  }
`;

export { ACheckbox };
