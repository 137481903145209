import { DateTime } from 'luxon';
import { Moment } from 'moment';

/**
 * Use with <ADateField>. Given a timezone, disables (returns true for) dates
 * that are in the past in that timezone
 */
export const disablePastStartDates = (
  timezone: string
): ((moment: Moment | null) => boolean) => (moment?: Moment | null) => {
  if (!moment) {
    return true;
  }
  const dt = DateTime.fromJSDate(moment.toDate());
  const dateLocalized = DateTime.fromObject({
    day: dt.day,
    month: dt.month,
    year: dt.year,
    zone: timezone,
  }).endOf('day');

  if (dateLocalized < DateTime.local()) {
    return true;
  }
  return false;
};

/** Use to check if a datetime is within a range */
export const isInDateRange = (
  testDate: DateTime,
  startDateInclusive: DateTime,
  endDateExclusive: DateTime
): boolean => testDate >= startDateInclusive && testDate < endDateExclusive;

/** Get start of week, where the start of a week is a sunday. */
export const getStartOfWeek = (date: DateTime): DateTime => {
  if (date.weekday === 7) {
    return date.startOf('day');
  } else {
    return date.startOf('week').minus({ day: 1 });
  }
};

/** Given a datetime returns a string describing how far in the future or long ago in the past it is
 * For Example: last week, next month, today, yesterday etc. */
export const timeDiffText = (
  diffDate: DateTime,
  now = DateTime.local()
): string => {
  if (diffDate > now) {
    const diff = diffDate.diff(now, ['months', 'weeks', 'days', 'hours']);

    if (diff.months === 1) {
      return 'next month';
    }
    if (diff.months > 1) {
      return `in ${diff.months} months`;
    }

    if (diff.weeks === 1) {
      return 'next week';
    }
    if (diff.weeks >= 2 && diff.weeks <= 3) {
      return `in ${diff.weeks} weeks`;
    }
    if (diff.weeks > 3 && diff.months < 1) {
      if (diffDate.month === now.month) {
        return `in ${diff.weeks} weeks`;
      }
      return 'next month';
    }

    if (diff.days === 0) {
      return 'today';
    }
    if (diff.days === 1) {
      return `tomorrow`;
    }
    if (diff.days > 1 && diff.days <= 6) {
      return `in ${diff.days} days`;
    }
    return 'unknown';
  }

  const diff = now.diff(diffDate, ['months', 'weeks', 'days', 'hours']);

  if (diff.months === 1) {
    return 'last month';
  }

  if (diff.months > 1) {
    return `${diff.months} months ago`;
  }

  if (diff.weeks === 1) {
    return 'last week';
  }
  if (diff.weeks >= 2 && diff.weeks <= 3) {
    return `${diff.weeks} weeks ago`;
  }
  if (diff.weeks > 3 && diff.months < 1) {
    if (diffDate.month === now.month) {
      return `${diff.weeks} weeks ago`;
    }
    return 'last month';
  }

  if (diff.days === 0) {
    return 'today';
  }
  if (diff.days === 1) {
    return `yesterday`;
  }
  if (diff.days > 1 && diff.days <= 6) {
    return `${diff.days} days ago`;
  }
  return 'unknown';
};
