import useComponentSize from '@rehooks/component-size';
import { times } from 'lodash';
import { DateTime, Interval } from 'luxon';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { CalendarCell } from 'app/components/organisms/CalendarCell/CalendarCell';
import { CalendarDays } from 'app/components/organisms/CalendarDays/CalendarDays';
import { CalendarHours } from 'app/components/organisms/CalendarHours/CalendarHours';
import { CalendarSchedule } from 'app/components/organisms/CalendarSchedule/CalendarSchedule';
import {
  CalendarCellsContainerCSS,
  CalendarColumnCSS,
  CalendarGridCSS,
} from 'app/styles/calendar';
import { theme } from 'app/styles/theme';
import { Grid as GridType } from 'app/types/calendar';
import { CalendarPage_Visits_Query_provider_visits } from 'app/types/generated/CalendarPage_Visits_Query';
import {
  createGrid,
  createMapCellInfoToRecurringTimeRange,
  createMapDateRangeToCells,
  getAvailableHours,
} from 'app/utils/calendar';
import {
  AVAILABLE_END_HOUR,
  AVAILABLE_START_HOUR,
  NUM_VISUAL_VERTICAL_CELLS,
} from 'constants/calendar';

// Types & constants ////////////////////////////
type Props = {
  interval: Interval;
  loading?: boolean;
  onVisitClick?: (visitCardPos: ClientRect) => (visitId: string) => void;
  selectedVisitId?: string;
  timezone: string;
  visits: CalendarPage_Visits_Query_provider_visits[];
};

/** Calendar displays a date bar and time grid */
const Calendar: FC<Props> = ({
  interval,
  loading,
  onVisitClick,
  selectedVisitId,
  timezone,
  visits,
}) => {
  const startofDayDate = interval.start;
  const numberOfColumns = interval.count('days');
  const parent = useRef<HTMLDivElement | null>(null);
  const size = useComponentSize(parent);
  const [[totalHeight, totalWidth], setDimensions] = useState([0, 0]);

  useEffect(() => {
    // find current hour and scroll into view
    const item = document.getElementById(
      `CalendarCell_${DateTime.local().get('hour') * 2}`
    );
    const root = document.getElementById('Calendar_wrapper');
    if (root && item) {
      const yPosition = item.getBoundingClientRect().top * 0.8;
      root.scroll(0, yPosition);
    }
  }, []);

  /** whenever the size of the calendar/grid changes we need to set the new
   dimensions in order to refetch correct grid info need to place the
   cards in the correct position */
  useEffect(() => {
    if (!parent.current) {
      setDimensions([0, 0]);
      return;
    }

    setDimensions([parent.current.scrollHeight, parent.current.scrollWidth]);
  }, [size]);

  /** returns an object with information needed to place cards on grid.
  grid info is refetched anytime props in array changes in order to
  reposition the cards based on new info returned */

  const grid = useMemo<GridType>(() => {
    return createGrid({
      numberOfColumns,
      totalHeight,
      totalWidth,
    });
  }, [totalHeight, totalWidth, numberOfColumns]);

  /** takes the original date and cell info passed from calendar cell
    and returns a date range */
  const getDateRangeForVisualGrid = createMapCellInfoToRecurringTimeRange({
    date: startofDayDate,
  });

  // takes original date and date range and returns cell info
  const dateRangeToCells = createMapDateRangeToCells(startofDayDate);

  return (
    <Wrapper id="Calendar_wrapper" numberOfColumns={numberOfColumns}>
      <ALoading spinning={loading || false}>
        <Table>
          <CalendarHours
            getDateRangeForVisualGrid={getDateRangeForVisualGrid}
          />
          <DatesRow>
            <TimezoneHeaderCell>
              {DateTime.local().setZone(timezone).toFormat('ZZZZ')}
            </TimezoneHeaderCell>
            <CalendarDays
              date={startofDayDate}
              numberOfDays={numberOfColumns}
            />
          </DatesRow>
          <GridContainer>
            {visits && (
              <CalendarSchedule
                dateRangeToCells={dateRangeToCells}
                grid={grid}
                onVisitClick={onVisitClick}
                selectedVisitId={selectedVisitId}
                timezone={timezone}
                visits={visits}
              />
            )}
            <Grid ref={parent}>
              {times(numberOfColumns).map((dayIndex) => {
                return (
                  <DayColumn key={`calendar_day_column_${dayIndex}`}>
                    <CellsContainer>
                      {getAvailableHours(
                        times(NUM_VISUAL_VERTICAL_CELLS),
                        AVAILABLE_START_HOUR,
                        AVAILABLE_END_HOUR
                      ).map((timeIndex) => {
                        return (
                          <CalendarCell
                            getDateRangeForVisualGrid={
                              getDateRangeForVisualGrid
                            }
                            key={`CalendarDayHours_${timeIndex}`}
                            timeIndex={timeIndex}
                          />
                        );
                      })}
                    </CellsContainer>
                  </DayColumn>
                );
              })}
            </Grid>
          </GridContainer>
        </Table>
      </ALoading>
    </Wrapper>
  );
};

// Styled components ////////////////////////////////
const TimezoneHeaderCell = styled.div`
  align-items: flex-end;
  background-color: ${theme.color.background};
  border-right: 1px solid ${theme.color.calendar.border};
  color: ${theme.color.calendar.text};
  display: flex;
  font-size: ${theme.font.size.s};
  height: calc(${theme.layout.calendar.cellHeight}px + ${theme.space.s});
  left: -${theme.layout.calendar.timeColumnWidth + 1}px;
  line-height: ${theme.space.sm};
  padding: 0 ${theme.space.m} ${theme.space.s} ${theme.space.s};
  position: absolute;
  width: ${theme.layout.calendar.timeColumnWidth + 1}px;
  z-index: ${theme.layer.zIndex.calendar.days};
`;

const CellsContainer = styled.div`
  ${CalendarCellsContainerCSS}
`;

const DayColumn = styled.div`
  ${CalendarColumnCSS}
`;

const DatesRow = styled.div`
  background-color: ${theme.color.background};
  position: sticky;
  top: 0;
  z-index: ${theme.layer.zIndex.calendar.days};
`;

const Grid = styled.div`
  ${CalendarGridCSS}
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const GridContainer = styled.div`
  height: 100%;
  position: relative;
  z-index: ${theme.layer.zIndex.calendar.container};
`;

const Table = styled.div`
  display: table;
  overflow: auto;
  position: relative;
  width: 100%;
`;

const Wrapper = styled.div<{
  numberOfColumns: number;
}>`
  contain: strict;
  height: calc(100vh - ${theme.layout.calendar.headerHeight}px);
  max-width: 100vw;
  overflow: auto;
  width: calc(
    ${({ numberOfColumns }) =>
      theme.layout.calendar.cellWidth * numberOfColumns}
  );
`;

export { Calendar };
