import React, { FC, useRef } from 'react';
import styled from 'styled-components/macro';

import { AIconLink } from 'app/components/atoms/AIconLink/AIconLink';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  personID: string;
  showText?: boolean;
}

const memberUrl = 'https://dr.parsleyhealth.com/members/';

/** Component used to copy member profile's url to the clipboard */
const AProfileLinkClipboardCopy: FC<Props> = ({
  personID,
  showText = true,
}) => {
  const clipboardRef = useRef<HTMLInputElement | null>(null);

  const copyToClipboard = (): void => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(`${memberUrl}${personID}`);
    } else {
      clipboardRef.current?.select();
      document.execCommand('copy');
    }
  };

  return (
    <Clipboard onClick={copyToClipboard}>
      <AIconLink
        color={theme.color.jade}
        height="16"
        width={showText ? '16' : '14'}
      />
      {showText && <ClipboardText>Copy profile link</ClipboardText>}
      <HiddenInput
        data-testid="AProfileLinkClipboardCopy_hidden-input"
        readOnly
        ref={clipboardRef}
        type="text"
        value={`${memberUrl}${personID}`}
      />
    </Clipboard>
  );
};

// Styled components ////////////////////////////////
const ClipboardText = styled.span`
  color: ${theme.color.jade};
  margin-left: ${theme.space.sm};
`;

const Clipboard = styled.span`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  vertical-align: text-top;
  width: fit-content;
  &:active > svg > path {
    fill: ${theme.color.parsleyGreen};
  }
  &:active > ${ClipboardText} {
    color: ${theme.color.parsleyGreen};
  }
`;

const HiddenInput = styled.input`
  border: 0 !important;
  height: 1px !important;
  margin: 0 !important;
  opacity: 0 !important;
  outline: none !important;
  overflow: hidden !important;
  padding: 0 !important;
  width: 1px !important;
`;

export { AProfileLinkClipboardCopy };
