import gql from 'graphql-tag';

export const VISITS_LIST_QUERY = gql`
  query VisitsList_Query(
    $centerIDs: [ID!]!
    $maxTime: String!
    $minTime: String!
  ) {
    visits: getAppointmentsByStatus(
      centerIds: $centerIDs
      maxTime: $maxTime
      minTime: $minTime
      statuses: [SCHEDULED]
    ) {
      category
      center {
        abbreviatedName
        id
        isVirtual
        timezone
      }
      endTime
      format
      id
      format
      member: person {
        id
        firstName
        lastName
        middleName
        membershipActivityStatus
        membershipPaymentStatus
      }
      provider {
        displayName
        id
      }
      startTime
    }
  }
`;

export const VISITS_LIST_QUERY_FILTERS = gql`
  query VisitsList_Query_Filters {
    centers: listCenters {
      id
      abbreviatedName
      city
      isVirtual
      state
      timezone
    }
    providers: listProviders {
      id
      displayName
      email
      type
    }
  }
`;
