import gql from 'graphql-tag';

export const ProviderProducts_Fragment_provider = gql`
  fragment ProviderProducts_Fragment_provider on PapiProvider {
    id
    products {
      displayName
    }
  }
`;
