import { Icon } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AButton } from 'app/components/atoms/AButton/AButton';
import { theme } from 'app/styles/theme';
import { AppRoute } from 'constants/app';
import { STATUS_MESSAGE } from 'constants/message';

// Types & constants ////////////////////////////////
interface Props {
  buttonOnClick?: () => void;
  buttonText?: string;
  message?: string;
}

/** Screen for when things go wrong, e.g. 404s, account doesn't have permissions */
const AErrorScreen: FC<Props> = ({
  buttonOnClick,
  buttonText,
  message = STATUS_MESSAGE.error.notFound,
}) => (
  <Styles>
    <IconStyled spin type="smile" />
    <Message>{message}</Message>
    {buttonOnClick ? (
      <AButton onClick={buttonOnClick} type="primary">
        {buttonText || 'Go home'}
      </AButton>
    ) : (
      <Link to={AppRoute.Basename}>
        <AButton type="primary">{buttonText || 'Go home'}</AButton>
      </Link>
    )}
  </Styles>
);

// Styled components ////////////////////////////////
const IconStyled = styled(Icon)`
  &&& {
    display: block;
    font-size: 3rem;
  }
`;

const Message = styled.div`
  font-size: ${theme.font.size.huge};
  font-weight: ${theme.font.weight.medium};
  margin: ${theme.space.l} 0;
`;

const Styles = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30vh ${theme.space.xxl} 0 ${theme.space.xxl};
  text-align: center;
`;

export { AErrorScreen };
