import gql from 'graphql-tag';

import { MemberEmergencyContact_Fragment_member } from './fragment';

export const MEMBER_CREATE_EMERGENCY_CONTACT = gql`
  mutation CreatePersonEmergencyContact_Mutation(
    $input: PapiPersonEmergencyContactInput!
  ) {
    createPersonEmergencyContact(input: $input) {
      ...MemberEmergencyContact_Fragment_member
    }
  }
  ${MemberEmergencyContact_Fragment_member}
`;

export const MEMBER_UPDATE_EMEGERENCY_CONTACT = gql`
  mutation UpdatePersonEmergencyContact_Mutation(
    $input: PapiPersonEmergencyContactInput!
  ) {
    updatePersonEmergencyContact(input: $input) {
      ...MemberEmergencyContact_Fragment_member
    }
  }
  ${MemberEmergencyContact_Fragment_member}
`;

export const MEMBER_GET_EMERGENCY_CONTACT = gql`
  query GetPersonEmergencyContact_Query($personID: ID!) {
    getPersonEmergencyContact(personId: $personID) {
      ...MemberEmergencyContact_Fragment_member
    }
  }
  ${MemberEmergencyContact_Fragment_member}
`;
