import { useQuery } from '@apollo/react-hooks';
import React from 'react';

import { ASelect } from 'app/components/atoms/ASelect/ASelect';
import { PapiProviderType } from 'app/types/generated/globalTypes';
import { formatProviderName } from 'app/utils/provider';

import { LIST_PROVIDERS_BY_TYPE_QUERY } from './query';

export const providersByTypeDropdownTestId = 'providers-by-type-dropdown';

interface ProvidersByTypeDropdownProps {
  disabled: boolean;
  onChange: (id: string) => void;
  providerType: PapiProviderType;
  value?: string;
}
export const ProvidersByTypeDropdown: React.FC<ProvidersByTypeDropdownProps> = ({
  disabled,
  onChange,
  providerType,
  value,
}: ProvidersByTypeDropdownProps) => {
  const { data, error, loading } = useQuery(LIST_PROVIDERS_BY_TYPE_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      providerType,
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const handleSelectChange = (value: any): void => {
    onChange(value);
  };

  const providerOptions = data?.listProvidersByType?.map((provider) => {
    const label = formatProviderName(provider, true);
    return {
      label,
      value: provider.id,
    };
  });

  return (
    <ASelect
      data-testid={providersByTypeDropdownTestId}
      disabled={disabled}
      onChange={handleSelectChange}
      selectOptions={providerOptions}
      value={value}
    />
  );
};
