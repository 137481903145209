// The visual grid increments in minutes.
export const VERTICAL_PRECISION = 30;

export const MIN_APPOINTMENT_LENGTH = 15;

// what time(hour) should calendar start
export const AVAILABLE_START_HOUR = 6;

// what time(hour) should calendar end
export const AVAILABLE_END_HOUR = 21;

export const AVAILABLE_HOURS = AVAILABLE_END_HOUR - AVAILABLE_START_HOUR;
export const AVAILABLE_MINS_IN_DAY = AVAILABLE_HOURS * 60;
export const NUM_VERTICAL_CELLS = AVAILABLE_MINS_IN_DAY / VERTICAL_PRECISION;

export const MINS_IN_DAY = 24 * 60;
export const NUM_VISUAL_VERTICAL_CELLS = MINS_IN_DAY / VERTICAL_PRECISION;

export const CALENDAR_PX_PER_MIN = 2;
