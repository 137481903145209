const transitionDuration = '0.3s';
const transitionTiming = 'ease';

const scale = {
  prepCard: {
    selected: 1.028,
  },
};

export const animationTheme = {
  scale,
  transitionDuration,
  transitionTiming,
};
