import { theme } from './theme';

export const overrides = `
  * {
    font-family: ${theme.font.family.base} !important;
    --antd-wave-shadow-color: ${theme.color.primary};
  }

  a {
    color: ${theme.color.primary} !important;
    :hover {
      color: ${theme.color.primaryLight} !important;
    }
  }
  a.dark {
    color: ${theme.color.text} !important;
    :hover {
      color: ${theme.color.primary} !important;
    }
  }

  li[aria-disabled] {
    cursor: pointer;
  }
  li[aria-disabled="true"] {
    cursor: not-allowed;
  }

  .ant-btn .anticon {
    bottom: ${theme.space.xs} !important;
    position: relative !important;
  }
  .ant-btn:not([disabled]) {
    :focus, :hover {
      border-color: ${theme.color.primary} !important;
      color: ${theme.color.primary} !important;
    }
  }
  .ant-btn-primary:not([disabled]) {
    background: ${theme.color.primary} !important;
    border-color: ${theme.color.primary} !important;
    color: white !important;
    :focus, :hover {
      background: ${theme.color.primaryLight} !important;
      border-color: ${theme.color.primaryLight} !important;
      color: white !important;
    }
  }

  .ant-btn-danger:not([disabled]) {
    color: white !important;
    :focus, :hover {
      border-color: ${theme.color.error} !important;
      color: white !important;
    }
  }

  .ant-calendar-date:hover,
  .ant-calendar-month-panel-month:hover,
  .ant-calendar-year-panel-year:hover,
  .ant-calendar-decade-panel-decade:hover {
    background: ${theme.color.backgroundHover} !important;
  }
  .ant-calendar-selected-date .ant-calendar-date,
  .ant-calendar-month-panel-selected-cell a.ant-calendar-month-panel-month,
  .ant-calendar-year-panel-selected-cell a.ant-calendar-year-panel-year,
  .ant-calendar-decade-panel-selected-cell a.ant-calendar-decade-panel-decade {
    background: ${theme.color.primary} !important;
    color: white !important;
  }
  .ant-calendar-selected-day:not(.ant-calendar-selected-date) .ant-calendar-date:not(:hover) {
    background: white !important;
  }
  .ant-calendar-today .ant-calendar-date {
    border-color: ${theme.color.primary};
  }
  .ant-calendar-today:not(.ant-calendar-selected-date) .ant-calendar-date {
    color: ${theme.color.primary} !important;
    :hover {
      background: ${theme.color.backgroundHover} !important;
    }
  }
  .ant-calendar-today:not(.ant-calendar-selected-date).ant-calendar-disabled-cell .ant-calendar-date {
    background: #f5f5f5 !important;
    border: none !important;
    color: rgba(0, 0, 0, 0.25) !important;
    font-weight: ${theme.font.weight.base};
    :hover {
      background: ${theme.color.backgroundHover} !important;
    }
  }
  .ant-calendar-today:not(.ant-calendar-selected-date).ant-calendar-disabled-cell .ant-calendar-date::before {
    display: none;
  }
  .ant-calendar-header a:hover {
    color: ${theme.color.primaryLight} !important;
  }
  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: ${theme.color.primaryLight} !important;
  }
  .ant-calendar-picker-icon {
    margin-top: -0.6rem !important;
  }
  .ant-calendar-week-number-cell, .ant-calendar-week-number-header {
    display: none;
  }
  .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
    background: ${theme.color.parsleyLightGreen};
  }
  .ant-calendar-week-number .ant-calendar-body tr:hover {
    background: ${theme.color.backgroundHover};
  }
  .ant-calendar-week-number .ant-calendar-active-week .ant-calendar-selected-day .ant-calendar-date {
    background: ${theme.color.parsleyLightGreen} !important;
    color: inherit !important;
  }
  .ant-calendar-week-number .ant-calendar-active-week:hover .ant-calendar-selected-day .ant-calendar-date {
    background: ${theme.color.backgroundHover} !important;
  }
  .ant-calendar-week-number .ant-calendar-today .ant-calendar-date {
    border-color: transparent;
    color: inherit !important;
  }
  .ant-calendar-week-number tr:not(.ant-calendar-active-week) .ant-calendar-today .ant-calendar-date {
    font-weight: normal;
  }

  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${theme.color.primary} !important;
  }
  .ant-checkbox-checked::after {
    border: 1px solid ${theme.color.primary} !important;
    visibility: hidden;
  }
  .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background: ${theme.color.primary} !important;
    border-color: ${theme.color.primary} !important;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${theme.color.primary} !important;
  }
  .ant-checkbox-input {
    :focus,
    :hover {
      + .ant-checkbox-inner {
        border-color: ${theme.color.primary} !important;
      }
    }
  }
  .ant-checkbox-wrapper {
    align-items: center !important;
    display: flex !important;
  }

  .ant-drawer-close .anticon-close {
    vertical-align: ${theme.space.xxs};
  }
  .ant-drawer-content-wrapper {
    height: calc(100% - ${theme.layout.headerSize}) !important;
  }
  .ant-drawer-header {
    height: calc(${theme.layout.headerSize} + 1px);
  }
  .ant-drawer-right {
    top: ${theme.layout.headerSize} !important;
    z-index: ${theme.layer.zIndex.drawer} !important;
  }
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: ${theme.layer.boxShadow.left} !important;
  }
  .ant-drawer-title {
    padding-right: ${theme.space.m};
  }

  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-item-selected {
    background: ${theme.color.backgroundHover} !important;
  }
  .ant-dropdown-menu-item-selected {
    color: ${theme.color.primary} !important;
  }

  .ant-form-item-control {
    line-height: normal !important;
  }
  .ant-form-item-control.has-error input.ant-input:not([disabled]) {
    :active, :focus, :hover {
      border-color: ${theme.color.error} !important;
      box-shadow: 0 0 0 2px ${theme.color.error}40 !important;
    }
  }
  .ant-form-item-label {
    line-height: inherit !important;
    padding: 0 !important;
  }

  .ant-input, .ant-select-selection {
    font-family: ${theme.font.family.base} !important;
    :active, :focus, :hover {
      border-color: ${theme.color.primary} !important;
    }
    :active, :focus {
      box-shadow: 0 0 0 2px ${theme.color.boxShadowGreen} !important;
    }
  }
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: ${theme.color.primary} !important;
  }
  .ant-input-group-addon .ant-select-focused .ant-select-selection {
    color: ${theme.color.primary} !important;
  }

  .ant-menu-inline .ant-menu-item::after {
    border-color: ${theme.color.primary} !important;
  }
  .ant-menu-item > a:hover {
    color: ${theme.color.primary} !important;
  }
  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: ${theme.color.primary} !important;
  }

  .ant-message-notice-content .anticon {
    top: 0 !important;
    vertical-align: baseline !important;
  }

  .ant-modal-close-x .ant-modal-close-icon {
    vertical-align: text-top;
  }

  .ant-pagination-item-active {
    border-color: ${theme.color.primary} !important;
  }
  .ant-pagination-item:focus,
  .ant-pagination-item:hover,
  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover,
  .ant-pagination-prev:hover a,
  .ant-pagination-next:hover a,
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: ${theme.color.primaryLight} !important;
  }
  .ant-pagination-item-link .anticon {
    bottom: ${theme.space.xxs};
    position: relative;
    vertical-align: baseline;
  }
  .ant-pagination-item-link-icon {
    color: ${theme.color.primary} !important;
  }
  
  .ant-popover-inner-content {
    padding: ${theme.space.s} !important;
  }

  .ant-select-arrow .anticon-loading {
    vertical-align: text-bottom;
  }
  .ant-select-disabled .ant-select-selection:hover {
    border-color: #d9d9d9 !important;
  }
  .ant-select-dropdown-menu-item-active, .ant-select-dropdown-menu-item:hover {
    background: ${theme.color.backgroundHover} !important;
  }
  .ant-select-dropdown-menu-item-disabled {
    margin-left: ${theme.space.m};
    :hover {
      background: inherit !important;
    }
  }
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon, .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
    color: ${theme.color.primary} !important;
  }
  .ant-select-focused .ant-select-selection {
    border-color: ${theme.color.primary} !important;
    box-shadow: 0 0 0 2px ${theme.color.boxShadowGreen} !important;
  }
  .ant-select-open .ant-select-selection {
    border-color: ${theme.color.primary} !important;
    box-shadow: 0 0 0 2px ${theme.color.boxShadowGreen} !important;
  }
  .ant-select-selection__clear {
    top: 45% !important;
  }

  .ant-skeleton-paragraph {
    margin-bottom: 0 !important;
  }
  .ant-skeleton-paragraph li {
    height: 1rem !important;
  }
  .ant-skeleton-paragraph > li + li {
    margin-top: 1rem !important;
  }

  .ant-spin {
    color: ${theme.color.primary};
  }
  .ant-spin-dot i {
    background-color: ${theme.color.primary};
  }

  .ant-switch:focus {
    box-shadow: 0 0 0 2px ${theme.color.boxShadowGreen} !important;
    -webkit-box-shadow: 0 0 0 2px ${theme.color.boxShadowGreen} !important;
  }
  .ant-switch-checked {
    background-color: ${theme.color.primary} !important;
  }
  .ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
    color: ${theme.color.primary} !important;
  }

  .ant-table-column-sorter-up.on, .ant-table-column-sorter-down.on {
    svg {
      fill: ${theme.color.primary} !important;
    }
  }
  .ant-table-filter-dropdown .ant-dropdown-menu-item {
    display: flex;
  }
  .ant-table-filter-dropdown-btns {
    display: none !important;
  }
  .ant-table-filter-dropdown-link, .ant-table-filter-selected {
    color: ${theme.color.primary} !important;
  }
  .ant-table-filter-dropdown-link:hover {
    color: ${theme.color.primaryLight} !important;
  }

  .ant-tabs-ink-bar {
    background: ${theme.color.primary} !important;
  }
  .ant-tabs-tab {
    :active, :hover {
      color: ${theme.color.primary} !important;
    }
  }
  .ant-tabs-tab-active {
    color: ${theme.color.primary} !important;
  }

  .ant-tree li .ant-tree-node-content-wrapper:hover {
    background-color: ${theme.color.backgroundHover} !important;
  }
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
    border-color: ${theme.color.primary} !important;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: ${theme.color.primary} !important;
    border-color: ${theme.color.primary} !important;
  }
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
    background-color: ${theme.color.primary} !important;
  }
  .ant-tree-switcher {
    bottom: ${theme.space.xs} !important;
  }

  .has-error .ant-form-explain  {
    overflow: visible !important;
    white-space: nowrap !important;
  }

  .ASelectProviderCenter li.ant-select-dropdown-menu-item {
    cursor: pointer;
    padding-right: ${theme.space.m} !important;
    margin-left: 0;
    :hover {
      background: ${theme.color.backgroundHover} !important;
    }
  }
`;
