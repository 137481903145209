import { Form, Input } from 'antd';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/lib/form/Form';
import { InputProps } from 'antd/lib/input';
import React, { FC } from 'react';

import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { digitsOnly, formatFloat } from 'app/utils/string';
import { FIELD_VALIDATION } from 'constants/form';

// Types & constants ////////////////////////////////
interface Props extends InputProps, AFormFieldWrapperProps {
  antdForm: WrappedFormUtils;
  disableAutoComplete?: boolean;
  email?: boolean;
  initialValue?: string | null;
  label?: string;
  labelInfo?: string;
  maxLength?: number;
  minLength?: number;
  name: string;
  number?: true | 'dollar' | 'integer' | 'percent';
  options?: GetFieldDecoratorOptions;
}

/** Deprecated: Antd form field, being deprecated to use react-hook-form */
const AInputField: FC<Props> = ({
  antdForm,
  customWidth,
  disableAutoComplete,
  email,
  initialValue,
  label,
  labelInfo,
  maxLength,
  minLength,
  name,
  number,
  options,
  required,
  show,
  ...props
}) => {
  const isPlainNumber = number === true;
  const isDollar = number === 'dollar';
  const isInteger = number === 'integer';
  const isPercent = number === 'percent';

  return (
    <AFormFieldWrapper antd customWidth={customWidth} show={show}>
      <Form.Item
        colon={false}
        label={
          label && (
            <ALabel black help={labelInfo} inline>
              {label}
            </ALabel>
          )
        }
      >
        {antdForm.getFieldDecorator(name, {
          ...options,
          getValueFromEvent: (e) =>
            !e.target.value || e.target.value.trim() === ''
              ? null
              : isPlainNumber || isPercent
              ? formatFloat(e.target.value)
              : isInteger
              ? digitsOnly(e.target.value)
              : isDollar
              ? formatDollarInput(e.target.value)
              : e.target.value,
          initialValue:
            isPlainNumber || isPercent
              ? formatFloat(initialValue)
              : isInteger
              ? digitsOnly(initialValue)
              : isDollar
              ? formatDollarInput(initialValue)
              : initialValue,
          rules: [
            ...(isPlainNumber || isInteger || isPercent
              ? [FIELD_VALIDATION.number]
              : []),
            ...(isDollar ? [FIELD_VALIDATION.dollar] : []),
            ...(email ? [FIELD_VALIDATION.email] : []),
            ...(maxLength ? [FIELD_VALIDATION.maxLength(maxLength)] : []),
            ...(minLength ? [FIELD_VALIDATION.minLength(minLength)] : []),
            ...(options?.rules || []),
            ...(required ? [FIELD_VALIDATION.required] : []),
          ],
          validateTrigger: [
            'onBlur',
            // only validate onChange if there is already an error
            ...(antdForm.getFieldError(name) ? ['onChange'] : []),
          ],
        })(
          <Input
            autoComplete={disableAutoComplete ? `off_${name}` : undefined}
            prefix={isDollar ? '$' : undefined}
            suffix={isPercent ? '%' : undefined}
            {...props}
          />
        )}
      </Form.Item>
    </AFormFieldWrapper>
  );
};

// Helpers ////////////////////////////////
const formatDollarInput = (str?: string | null): string => {
  if (!str) {
    return '';
  }
  if (str.split('.').length > 1) {
    const [beforeFirstDecimal, ...afterFirstDecimal] = str.split('.');
    return formatFloat(
      `${beforeFirstDecimal}.${afterFirstDecimal.join('').slice(0, 2)}`
    ).replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add comma every 3 digits
  }
  return formatFloat(str).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export { AInputField };
