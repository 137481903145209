import { css } from 'styled-components/macro';

import { theme } from './theme';

/***
 * Calendar style mixins
 * Since the calendar has complex CSS, we use these mixins to compose styles.
 * To use, create a styled component normally, then add the desired mixin(s):
 * const StyledComponent = styled.div`
 *   ${CalendarCellsContainerCSS}
 *   ...additional CSS & overrides
 * `;
 */

export const CalendarCellsContainerCSS = css`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CalendarCellCSS = css`
  align-items: flex-start;
  background-color: ${theme.color.alabaster};
  border-top: 1px solid ${theme.color.calendar.border};
  display: flex;
  flex: 1 1 ${theme.layout.calendar.cellHeight}px;
  justify-content: flex-start;
`;

export const CalendarCellHeaderCSS = css`
  ${CalendarCellCSS}
  border-radius: 4px;
  padding: ${theme.space.xs} ${theme.space.m};
  text-transform: uppercase;
`;

export const CalendarColumnCSS = css`
  flex: 1 1 ${theme.layout.calendar.cellWidth}px;
  min-width: ${theme.layout.calendar.cellMinWidth}px;
`;

export const CalendarHeaderCSS = css`
  padding: 0;
`;

export const CalendarGridCSS = css`
  border-collapse: collapse;
  display: flex;
  position: relative;
  text-align: center;
  user-select: none;
`;
