import { useMutation, useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { DocumentReviewDetails } from 'app/components/arabella/DocumentReviewDetails/DocumentReviewDetails';
import { DocumentReviewList } from 'app/components/arabella/DocumentReviewList/DocumentReviewList';
import {
  DOCUMENT_REVIEW_LIST_MUTATION_ARCHIVE_DOC,
  DOCUMENT_REVIEW_LIST_MUTATION_CATEGORIZE_DOC,
  DOCUMENT_REVIEW_LIST_MUTATION_TRASH_DOC,
  DOCUMENT_REVIEW_LIST_QUERY_GET_DOCS,
  DOCUMENT_REVIEW_LIST_QUERY_PERSONS,
} from 'app/components/arabella/DocumentReviewList/query';
import { mapPersonToMember } from 'app/components/arabella/DocumentReviewList/utils';
import {
  DocumentReviewSaveData,
  DocumentReviewToolbar,
} from 'app/components/arabella/DocumentReviewToolbar/DocumentReviewToolbar';
import { ASplitView } from 'app/components/atoms/ASplitView/ASplitView';
import { theme } from 'app/styles/theme';
import {
  DocumentReviewList_Mutation_ArchiveDoc,
  DocumentReviewList_Mutation_ArchiveDocVariables,
} from 'app/types/generated/DocumentReviewList_Mutation_ArchiveDoc';
import {
  DocumentReviewList_Mutation_CategorizeDoc,
  DocumentReviewList_Mutation_CategorizeDocVariables,
} from 'app/types/generated/DocumentReviewList_Mutation_CategorizeDoc';
import {
  DocumentReviewList_Mutation_TrashDoc,
  DocumentReviewList_Mutation_TrashDocVariables,
} from 'app/types/generated/DocumentReviewList_Mutation_TrashDoc';
import {
  DocumentReviewList_Query_GetDocs,
  DocumentReviewList_Query_GetDocsVariables,
  DocumentReviewList_Query_GetDocs_docs,
  DocumentReviewList_Query_GetDocs_docs_person,
} from 'app/types/generated/DocumentReviewList_Query_GetDocs';
import {
  DocumentReviewList_Query_Persons,
  DocumentReviewList_Query_PersonsVariables,
} from 'app/types/generated/DocumentReviewList_Query_Persons';
import {
  PapiInboxItemSource,
  PapiInboxItemSourceCategory,
  PapiInboxItemTriageStatus,
} from 'app/types/generated/globalTypes';
import { displayErrors } from 'app/utils/app';
import { DOCUMENT_SOURCE_ATTRS } from 'constants/documentReview';
import { STATUS_MESSAGE } from 'constants/message';

/** Mint: Inbox queue page */
export const DocumentReviewPage: FC = () => {
  const [selectedCenters, setCenters] = useState<string[]>([]);
  const [selectedDoc, setDoc] = useState<
    DocumentReviewList_Query_GetDocs_docs
  >();
  const [selectedMemberFilter, setMemberFilter] = useState<string>();
  const [selectedSource, setSource] = useState<PapiInboxItemSourceCategory>();
  const [selectedMember, setSelectedMember] = useState<
    DocumentReviewList_Query_GetDocs_docs_person
  >();

  const [membersSearch, setMembersSearch] = useState('');
  const [errorSeen, setErrorSeen] = useState(false);

  const docReviewListQueryVariables = {
    centerIDs: selectedCenters,
    personID: selectedMemberFilter,
    sourceCategory: selectedSource,
    triageStatuses: [PapiInboxItemTriageStatus.NEW],
  };
  const { data: docReviewListData, error, loading } = useQuery<
    DocumentReviewList_Query_GetDocs,
    DocumentReviewList_Query_GetDocsVariables
  >(DOCUMENT_REVIEW_LIST_QUERY_GET_DOCS, {
    errorPolicy: 'all',
    variables: docReviewListQueryVariables,
  });

  if (error && !errorSeen) {
    message.error({
      content: STATUS_MESSAGE.documentReview.error.general,
      onClose: () => setErrorSeen(true),
    });
  }

  const { data: membersData } = useQuery<
    DocumentReviewList_Query_Persons,
    DocumentReviewList_Query_PersonsVariables
  >(DOCUMENT_REVIEW_LIST_QUERY_PERSONS, {
    errorPolicy: 'all',
    skip: membersSearch === '',
    variables: { search: membersSearch },
  });
  const searchedMembers = membersData ? membersData.persons : [];

  const updateDoc = (action) => (cache, returnedData) => {
    const updatedDoc = returnedData.data[action];
    const { docs } = cache.readQuery({
      query: DOCUMENT_REVIEW_LIST_QUERY_GET_DOCS,
      variables: docReviewListQueryVariables,
    });
    const updatedDocs = docs.map((doc) =>
      doc.id === updatedDoc.id ? updatedDoc : doc
    );
    cache.writeQuery({
      data: { docs: updatedDocs },
      query: DOCUMENT_REVIEW_LIST_QUERY_GET_DOCS,
      variables: docReviewListQueryVariables,
    });
    setDoc(updatedDoc);
  };

  const [archiveDoc, { loading: archiveLoading }] = useMutation<
    DocumentReviewList_Mutation_ArchiveDoc,
    DocumentReviewList_Mutation_ArchiveDocVariables
  >(DOCUMENT_REVIEW_LIST_MUTATION_ARCHIVE_DOC, {
    onError: (error) => displayErrors(error),
    update: updateDoc('archiveDoc'),
  });
  const [categorizeDoc, { loading: categorizeLoading }] = useMutation<
    DocumentReviewList_Mutation_CategorizeDoc,
    DocumentReviewList_Mutation_CategorizeDocVariables
  >(DOCUMENT_REVIEW_LIST_MUTATION_CATEGORIZE_DOC, {
    onError: (error) => displayErrors(error),
    update: updateDoc('categorizeDoc'),
  });
  const [trashDoc, { loading: trashLoading }] = useMutation<
    DocumentReviewList_Mutation_TrashDoc,
    DocumentReviewList_Mutation_TrashDocVariables
  >(DOCUMENT_REVIEW_LIST_MUTATION_TRASH_DOC, {
    onError: (error) => displayErrors(error),
    update: updateDoc('trashDoc'),
  });

  const isSaving = archiveLoading || categorizeLoading || trashLoading;
  const isSaved = selectedDoc ? selectedDoc.triageStatus !== 'NEW' : false;

  const archive = (data: DocumentReviewSaveData): void => {
    if (!selectedDoc) return;

    const input = {
      category: data.docType!,
      documentName: data.docTitle,
      id: selectedDoc.id,
    };
    archiveDoc({ variables: { input } });
  };

  const categorize = (data: DocumentReviewSaveData): void => {
    if (!selectedDoc || !selectedMember) return;

    const input = {
      category: data.docType!,
      documentName: data.docTitle,
      id: selectedDoc.id,
      personID: selectedMember.id,
      reviewerID: data.createTask ? data.providerID : null,
    };
    categorizeDoc({ variables: { input } });
  };

  const handleMemberChange = (memberId: string): void => {
    const member = searchedMembers.find((member) => member.id === memberId);
    if (member) setSelectedMember(member);
  };

  const onDocSave = (data: DocumentReviewSaveData): void => {
    if (selectedMember) data.trash ? trash(data) : categorize(data);
    else data.trash ? trash(data) : archive(data);
  };

  const onDocSelect = (doc: DocumentReviewList_Query_GetDocs_docs): void => {
    setDoc(doc);
    setSelectedMember(doc.person || undefined);
  };

  const trash = (data: DocumentReviewSaveData): void => {
    if (!selectedDoc) return;

    const input = {
      documentName: data.docTitle,
      id: selectedDoc.id,
      personID: selectedMember ? selectedMember.id : null,
    };
    trashDoc({ variables: { input } });
  };

  return (
    <SplitViewWrapper>
      <ASplitView
        detailPane={
          selectedDoc ? (
            <DetailPane>
              <DocumentReviewDetails
                actionSection={
                  <DocumentReviewToolbar
                    isSaved={isSaved}
                    isSaving={isSaving}
                    member={selectedMember}
                    onDocSave={onDocSave}
                    providers={docReviewListData?.providers || []}
                    selectedDoc={selectedDoc}
                  />
                }
                isMemberChangeable={
                  selectedDoc.source !== PapiInboxItemSource.PERSON_UPLOAD
                }
                isSaved={isSaved}
                isSaving={isSaving}
                key={selectedDoc.id}
                member={selectedMember && mapPersonToMember(selectedMember)}
                memberSearchResults={searchedMembers.map(mapPersonToMember)}
                onMemberDisconnectedFromSearch={() =>
                  setSelectedMember(undefined)
                }
                onMemberSelectedFromSearchResults={handleMemberChange}
                onSearchCanceled={() => setMembersSearch('')}
                onSearchPhraseProvided={setMembersSearch}
                pdfUrl={selectedDoc.url}
                uploadTypeIcon={
                  DOCUMENT_SOURCE_ATTRS[selectedDoc.sourceCategory].icon
                }
                uploadTypeLabel={
                  DOCUMENT_SOURCE_ATTRS[selectedDoc.sourceCategory].label
                }
              />
            </DetailPane>
          ) : null
        }
        masterColumn={
          <Column>
            <DocumentReviewList
              docs={docReviewListData?.docs}
              initialLoading={!docReviewListData}
              loading={loading}
              onCentersChange={(centerIDs) => {
                setCenters(centerIDs);
                setMemberFilter(undefined);
              }}
              onDocSelect={onDocSelect}
              onMemberSelect={(memberID) => {
                setMemberFilter(memberID);
                setCenters([]);
              }}
              onSourceChange={setSource}
              selectedCenters={selectedCenters}
              selectedDoc={selectedDoc}
              selectedMember={selectedMemberFilter}
              selectedSource={selectedSource}
            />
          </Column>
        }
        noContentDescription="Select Item"
      />
    </SplitViewWrapper>
  );
};

// Styled components ////////////////////////////////
const Column = styled.div`
  padding: 0 ${theme.space.s};
`;

const DetailPane = styled.div`
  display: flex;
  height: 100%;
  padding-bottom: ${theme.space.xl};
`;

const SplitViewWrapper = styled.div`
  background-color: white;
  height: calc(100vh - ${theme.layout.headerSize});
  padding: ${theme.space.xl} ${theme.space.l} 0 ${theme.space.l};
`;
