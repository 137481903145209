import { useQuery } from '@apollo/react-hooks';
import { Skeleton } from 'antd';
import { capitalize } from 'lodash';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { ACard } from 'app/components/atoms/ACard/ACard';
import { AFlexDivider } from 'app/components/atoms/AFlexDivider/AFlexDivider';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { EditInsuranceForm } from 'app/components/organisms/EditInsuranceForm/EditInsuranceForm';
import { theme } from 'app/styles/theme';
import { MemberInsuranceRelationshipTypes } from 'app/types/generated/MemberInsuranceRelationshipTypes';
import {
  MemberNewInsuranceCard_Query,
  MemberNewInsuranceCard_QueryVariables,
} from 'app/types/generated/MemberNewInsuranceCard_Query';

import {
  MEMBER_NEW_INSURANCE_CARD_QUERY,
  MEMBER_SUBSCRIBER_RELATIONSHIP_TYPES,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  personId: string;
}

/** Member insurance card that allows edits in card */
const MemberNewInsuranceCard: FC<Props> = ({ personId }) => {
  const { data, error, loading: queryLoading, refetch } = useQuery<
    MemberNewInsuranceCard_Query,
    MemberNewInsuranceCard_QueryVariables
  >(MEMBER_NEW_INSURANCE_CARD_QUERY, { variables: { personId } });

  const { data: relationships, error: relationShipsError } = useQuery<
    MemberInsuranceRelationshipTypes
  >(MEMBER_SUBSCRIBER_RELATIONSHIP_TYPES);

  if (queryLoading && (!data || !data.subscriber)) {
    return (
      <Styles>
        <ACard>
          <Skeleton
            active
            paragraph={{ rows: 3, width: ['70%', '100%', '100%'] }}
            title={false}
          />
        </ACard>
      </Styles>
    );
  }

  if (!data || !data.subscriber || error || relationShipsError) {
    return null;
  }

  const { subscriber } = data;
  return (
    <Styles>
      <ACard
        dropdownItems={[
          <EditInsuranceForm
            onSuccess={() => refetch()}
            personID={subscriber.personId}
            subscriber={subscriber}
          />,
        ]}
        title={
          subscriber.payerDetails?.payerName || (
            <ATextLight lighter>Insurance unknown</ATextLight>
          )
        }
      >
        <InsuranceInfo>
          {!subscriber.groupId && !subscriber.personId && (
            <ATextLight lighter>–</ATextLight>
          )}
          {subscriber.groupId && (
            <Info>
              <InfoLabel>
                <ATextBold>GroupID: </ATextBold>
              </InfoLabel>
              {subscriber.groupId}
            </Info>
          )}
          {subscriber.personId && (
            <Info>
              <InfoLabel>
                <ATextBold>MemberID: </ATextBold>
              </InfoLabel>
              {subscriber.planId}
            </Info>
          )}
          {subscriber.copayAmountInCents ? (
            <Info>
              <InfoLabel>
                <ATextBold>Co-pay: </ATextBold>
              </InfoLabel>
              ${subscriber.copayAmountInCents / 100}
            </Info>
          ) : null}
          <AFlexDivider />
          <InfoLabel>
            <ATextBold>Subscriber info</ATextBold>
          </InfoLabel>
          {subscriber.firstName} {subscriber.lastName} (
          {
            relationships?.getRelationshipTypes.find(
              (relation) => relation.id === subscriber.relationshipToPatient
            )?.description
          }
          )
          <br />
          {subscriber.addressLine1}, {subscriber.state} {subscriber.zipCode}
          <br />
          {capitalize(subscriber.sex)}
          <br />
          DOB: {subscriber.dateOfBirth}
        </InsuranceInfo>
      </ACard>
    </Styles>
  );
};

// Styled components ////////////////////////////////
const Info = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoLabel = styled.div`
  margin-right: ${theme.space.m};
`;

const InsuranceInfo = styled.div`
  font-size: ${theme.font.size.s};
`;

const Styles = styled.div`
  margin: 0 ${theme.space.m} ${theme.space.m} 0;
  width: 425px;
`;

export { MemberNewInsuranceCard };
