import gql from 'graphql-tag';

export const A_SELECT_CARE_MANAGER = gql`
  query ASelectCareManager_Query($providerType: PapiProviderType!) {
    listProvidersByType(providerType: $providerType) {
      id
      type
      email
      firstName
      middleName
      lastName
      professionalSuffix
      photoURL
      displayName
    }
  }
`;
