import { BaseMutationOptions } from '@apollo/react-common';
import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { MutationUpdaterFn } from 'apollo-client';
import React, { FC } from 'react';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import {
  AForm,
  AFormProps,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { AInputField } from 'app/components/deprecated/AInputField/AInputField';
import { ASelectCenterField } from 'app/components/deprecated/ASelectCenterField/ASelectCenterField';
import { ASelectField } from 'app/components/deprecated/ASelectField/ASelectField';
import { ASelectPlanField } from 'app/components/deprecated/ASelectPlanField/ASelectPlanField';
import { ASelectProductField } from 'app/components/deprecated/ASelectProductField/ASelectProductField';
import {
  PapiBillingPeriod,
  PapiProductType,
} from 'app/types/generated/globalTypes';
import {
  PlanCreate_Mutation,
  PlanCreate_MutationVariables,
} from 'app/types/generated/PlanCreate_Mutation';
import { PlanCreateUpdate_Fragment_updatePlan } from 'app/types/generated/PlanCreateUpdate_Fragment_updatePlan';
import {
  PlanUpdate_Mutation,
  PlanUpdate_MutationVariables,
} from 'app/types/generated/PlanUpdate_Mutation';
import { displayErrors } from 'app/utils/app';
import { formatBillingPeriod, formatPlanAgeLimit } from 'app/utils/plan';
import { compareLabel } from 'app/utils/sort';
import { STATUS_MESSAGE } from 'constants/message';
import {
  PLAN_AGE_LIMITS,
  PLAN_COMMITMENT_LENGTH_SELECTION,
  PLAN_COMMITMENT_PERIOD_BY_MONTHS,
  PLAN_FIELD_HELP,
  PlanAgeLimit,
} from 'constants/plan';

import { PLAN_CREATE_MUTATION, PLAN_UPDATE_MUTATION } from './query';

// Types & constants ////////////////////////////////
interface Props
  extends Pick<AFormProps, 'onClose' | 'onOpen' | 'showOverride'> {
  initialValues?: PlanCreateUpdate_Fragment_updatePlan;
  mode: 'create' | 'update';
  mutationCacheUpdater?: MutationUpdaterFn<PlanCreate_Mutation>;
  onCreateCompleted?: BaseMutationOptions<
    PlanCreate_Mutation,
    PlanCreate_MutationVariables
  >['onCompleted'];
  onUpdateCompleted?: BaseMutationOptions<
    PlanUpdate_Mutation,
    PlanUpdate_MutationVariables
  >['onCompleted'];
  openText?: AFormProps['openText'];
}

interface Fields {
  ageLimit?: PlanAgeLimit;
  billingPeriod: PapiBillingPeriod;
  commitmentLength?: PapiBillingPeriod;
  defaultFollowOnPlanID?: string;
  displayName: string;
  joinPurchaseAllowedCenterIDs?: string[];
  numberOfBillingCycles?: string;
  paymentRate: number;
  productType: PapiProductType;
}

/** Form to create or update a Parsley membership plan */
const PlanCreateUpdate: FC<Props> = ({
  initialValues,
  mode,
  mutationCacheUpdater,
  onCreateCompleted,
  onUpdateCompleted,
  openText,
  ...props
}) => {
  const [createPlan, { loading: createPlanLoading }] = useMutation<
    PlanCreate_Mutation,
    PlanCreate_MutationVariables
  >(PLAN_CREATE_MUTATION, {
    onCompleted: onCreateCompleted,
    update: mutationCacheUpdater,
  });
  const [updatePlan, { loading: updatePlanLoading }] = useMutation<
    PlanUpdate_Mutation,
    PlanUpdate_MutationVariables
  >(PLAN_UPDATE_MUTATION, {
    onCompleted: onUpdateCompleted,
  });

  const onSaveCreate = async (
    input: Fields,
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      const {
        ageLimit,
        joinPurchaseAllowedCenterIDs,
        numberOfBillingCycles,
        paymentRate,
        ...restInput
      } = input;
      const ageLimitValues = PLAN_AGE_LIMITS.find(
        (planAgeLimit) => ageLimit === planAgeLimit.value
      );
      const minPersonAgeYears = ageLimitValues?.minPersonAgeYears || 0;
      const maxPersonAgeYears = ageLimitValues?.maxPersonAgeYears;

      const response = await createPlan({
        variables: {
          input: {
            ...restInput,
            joinPurchaseAllowedCenterIDs: joinPurchaseAllowedCenterIDs || [],
            maxPersonAgeYears,
            minPersonAgeYears,
            numberOfBillingCycles: numberOfBillingCycles
              ? parseInt(numberOfBillingCycles)
              : null,
            paymentRateInCents: paymentRate * 100,
          },
        },
      });
      if (response?.data) {
        message.success(STATUS_MESSAGE.planCreate.success);
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.planCreate.error.general);
      shouldFinish(false);
    }
  };

  const onSaveUpdate = async (
    input: Fields,
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      if (!initialValues) {
        throw new Error(STATUS_MESSAGE.planUpdate.error.missingPlanID);
      }

      const response = await updatePlan({
        variables: {
          input: {
            ID: initialValues.id,
            joinPurchaseAllowedCenterIDs:
              input.joinPurchaseAllowedCenterIDs || [],
          },
        },
      });
      if (response?.data) {
        message.success(STATUS_MESSAGE.planUpdate.success);
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.planUpdate.error.general);
      shouldFinish(false);
    }
  };

  const isCreate = mode === 'create';
  const isUpdate = mode === 'update';
  const initialAgeLimit =
    initialValues &&
    PLAN_AGE_LIMITS.find(
      (ageLimit) =>
        ageLimit.maxPersonAgeYears === initialValues.maxPersonAgeYears &&
        ageLimit.minPersonAgeYears === initialValues.minPersonAgeYears
    );

  return (
    <AForm
      button={{ icon: isCreate ? 'plus' : undefined, type: 'primary' }}
      ignoreLayoutContext
      onSave={isCreate ? onSaveCreate : onSaveUpdate}
      openAs={isCreate ? 'drawer' : undefined}
      openText={openText ?? (isCreate ? 'Create plan' : 'Edit')}
      saveText={isCreate ? 'Create' : 'Save'}
      saving={createPlanLoading || updatePlanLoading}
      savingText={isCreate ? 'Creating' : 'Saving'}
      title={isCreate ? 'New plan' : 'Edit plan'}
      {...props}
    >
      {(form) => (
        <>
          {/* Product & plan name */}
          <AFormFieldRow>
            <ASelectProductField
              antdForm={form}
              customWidth={{ percent: 80 }}
              disabled={isUpdate}
              initialValue={initialValues?.product.type}
              {...fields.productType}
            />
          </AFormFieldRow>
          <AFormFieldRow>
            <AInputField
              antdForm={form}
              customWidth={{ percent: 100 }}
              disabled={isUpdate}
              initialValue={initialValues?.displayName}
              {...fields.displayName}
            />
          </AFormFieldRow>

          {/* Billing */}
          <AFormFieldRow>
            <ASelectField
              antdForm={form}
              disabled={isUpdate}
              initialValue={initialValues?.billingPeriod}
              selectOptions={Object.values(PapiBillingPeriod)
                .map((billingPeriod) => ({
                  label: formatBillingPeriod(billingPeriod),
                  value: billingPeriod,
                }))
                .sort(compareLabel)}
              {...fields.billingPeriod}
            />
            <AInputField
              antdForm={form}
              customWidth={{ percent: 30 }}
              disabled={isUpdate}
              initialValue={
                initialValues &&
                typeof initialValues.numberOfBillingCycles === 'number'
                  ? initialValues.numberOfBillingCycles.toString()
                  : null
              }
              number="integer"
              {...fields.numberOfBillingCycles}
            />
            <AInputField
              antdForm={form}
              customWidth={{ percent: 40 }}
              disabled={isUpdate}
              initialValue={
                initialValues &&
                typeof initialValues.paymentRateInCents === 'number'
                  ? (initialValues.paymentRateInCents / 100).toString()
                  : null
              }
              number="dollar"
              {...fields.paymentRate}
            />
          </AFormFieldRow>

          {/* Centers */}
          <AFormFieldRow>
            <ASelectCenterField
              antdForm={form}
              customWidth={{ percent: 100 }}
              initialValue={initialValues?.joinCentersAllowed.map(
                (center) => center.id
              )}
              mode="multiple"
              {...fields.joinPurchaseAllowedCenterIDs}
            />
          </AFormFieldRow>

          {/* Commitment length, age limit, & follow-on */}
          <AFormFieldRow>
            <ASelectField
              allowClear
              antdForm={form}
              disabled={isUpdate}
              initialValue={
                initialValues?.commitmentLength &&
                PLAN_COMMITMENT_PERIOD_BY_MONTHS[initialValues.commitmentLength]
              }
              selectOptions={PLAN_COMMITMENT_LENGTH_SELECTION}
              {...fields.commitmentLength}
            />
            <ASelectField
              allowClear
              antdForm={form}
              customWidth={{ percent: 40 }}
              disabled={isUpdate}
              initialValue={initialValues && (initialAgeLimit?.value || null)}
              selectOptions={PLAN_AGE_LIMITS.map((ageLimit) => ({
                label: formatPlanAgeLimit(ageLimit),
                value: ageLimit.value,
              }))}
              {...fields.ageLimit}
            />
          </AFormFieldRow>

          <AFormFieldRow>
            <ASelectPlanField
              antdForm={form}
              customWidth={{ percent: 100 }}
              disabled={isUpdate}
              initialValue={initialValues?.defaultFollowOnPlan?.id}
              {...fields.defaultFollowOnPlanID}
            />
          </AFormFieldRow>
        </>
      )}
    </AForm>
  );
};

/** Form field info */
const fields = {
  ageLimit: {
    label: 'Age limit',
    name: 'ageLimit',
    required: false,
  },
  billingPeriod: {
    label: 'Bill period',
    labelInfo: PLAN_FIELD_HELP.billingPeriod,
    name: 'billingPeriod',
    required: true,
  },
  commitmentLength: {
    label: 'Commitment length',
    labelInfo: PLAN_FIELD_HELP.commitmentLength,
    name: 'commitmentLength',
    required: false,
  },
  defaultFollowOnPlanID: {
    label: 'Default follow-on plan',
    labelInfo: PLAN_FIELD_HELP.defaultFollowOnPlan,
    name: 'defaultFollowOnPlanID',
    required: false,
  },
  displayName: {
    label: 'Plan name',
    labelInfo: PLAN_FIELD_HELP.displayName,
    name: 'displayName',
    required: true,
  },
  joinPurchaseAllowedCenterIDs: {
    label: 'Joinable centers',
    labelInfo: PLAN_FIELD_HELP.joinCentersAllowed,
    name: 'joinPurchaseAllowedCenterIDs',
    required: false,
  },
  numberOfBillingCycles: {
    label: 'Cycles',
    labelInfo: PLAN_FIELD_HELP.numberOfBillingCycles,
    name: 'numberOfBillingCycles',
    required: false,
  },
  paymentRate: {
    label: 'Payment',
    labelInfo: PLAN_FIELD_HELP.paymentRateInCents,
    name: 'paymentRate',
    required: true,
  },
  productType: {
    label: 'Product',
    labelInfo: PLAN_FIELD_HELP.product,
    name: 'productType',
    required: true,
  },
};

export { PlanCreateUpdate, fields };
