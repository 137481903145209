import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { theme } from 'app/styles/theme';

export interface ISegmentedControlProps {
  fieldName: string;
  leftRadioProps?: Omit<JSX.IntrinsicElements['input'], 'type' | 'ref'>;
  onIndexSelected?: (index: number) => void;
  options: Array<{
    label: string;
    value: string;
  }>;
  rightRadioProps?: Omit<JSX.IntrinsicElements['input'], 'type' | 'ref'>;
  selectedIndex: number;
}

export const ASegmentedControl: FC<ISegmentedControlProps> = ({
  fieldName = 'SegmentedControl',
  leftRadioProps,
  onIndexSelected,
  options,
  rightRadioProps,
  selectedIndex = 0,
}) => {
  const [leftItem, rightItem] = options;
  return (
    <Wrapper>
      <LeftItem
        isSelected={selectedIndex === 0}
        onClick={() => onIndexSelected?.(0)}
      >
        {leftItem.label}
        <HiddenRadio
          name={fieldName}
          value={leftItem.value}
          {...leftRadioProps}
        />
      </LeftItem>
      <RightItem
        isSelected={selectedIndex === 1}
        onClick={() => onIndexSelected?.(1)}
      >
        {rightItem.label}
        <HiddenRadio
          name={fieldName}
          value={rightItem.value}
          {...rightRadioProps}
        />
      </RightItem>
    </Wrapper>
  );
};

const PrimaryColor = theme.color.jade; // Change to right color
const BackgroundColor = `white` as const;
const PillHeight = `50px` as const;
const BorderWidth = `3px` as const;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: ${theme.font.size.base};
  font-weight: ${theme.font.weight.base};
  width: 250px;
`;

type Selectable = {
  isSelected?: boolean;
};

const LeftItem = styled.label<Selectable>`
  align-items: center;
  border-bottom: ${BorderWidth} solid ${PrimaryColor};
  border-bottom-left-radius: 5px;
  border-left: ${BorderWidth} solid ${PrimaryColor};
  border-top: ${BorderWidth} solid ${PrimaryColor};
  border-top-left-radius: 5px;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: ${PillHeight};
  justify-content: center;
  position: relative;
  transition: background-color 300ms linear, color 300ms linear;
  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: ${PrimaryColor};
          color: ${BackgroundColor};
        `
      : css`
          background-color: ${BackgroundColor};
          color: ${PrimaryColor};
        `}
`;

const RightItem = styled.label<Selectable>`
  align-items: center;
  border-bottom: ${BorderWidth} solid ${PrimaryColor};
  border-bottom-right-radius: 5px;
  border-right: ${BorderWidth} solid ${PrimaryColor};
  border-top: ${BorderWidth} solid ${PrimaryColor};
  border-top-right-radius: 5px;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: ${PillHeight};
  justify-content: center;
  position: relative;
  transition: background-color 300ms linear, color 300ms linear;
  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: ${PrimaryColor};
          color: ${BackgroundColor};
        `
      : css`
          background-color: ${BackgroundColor};
          color: ${PrimaryColor};
        `}
`;

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
`;
