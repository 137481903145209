import { useLazyQuery } from '@apollo/react-hooks';
import { Divider, Modal } from 'antd';
import { DateTime, Interval } from 'luxon';
import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { AButton } from 'app/components/atoms/AButton/AButton';
import { AIconClose } from 'app/components/atoms/AIconClose/AIconClose';
import { ASelectCenter } from 'app/components/atoms/ASelectCenter/ASelectCenter';
import { ASelectProduct } from 'app/components/atoms/ASelectProduct/ASelectProduct';
import { ASelectProvider } from 'app/components/atoms/ASelectProvider/ASelectProvider';
import { ASelectVisitFormat } from 'app/components/atoms/ASelectVisitFormat/ASelectVisitFormat';
import { Calendar } from 'app/components/organisms/Calendar/Calendar';
import { CalendarControls } from 'app/components/organisms/CalendarControls/CalendarControls';
import { MemberLinks } from 'app/components/organisms/MemberLinks/MemberLinks';
import { VisitDetails } from 'app/components/organisms/VisitDetails/VisitDetails';
import { ViewerContext } from 'app/contexts/ViewerContext';
import { theme } from 'app/styles/theme';
import {
  CalendarPage_Visits_Query,
  CalendarPage_Visits_QueryVariables,
  CalendarPage_Visits_Query_provider_visits,
} from 'app/types/generated/CalendarPage_Visits_Query';
import {
  PapiAppointmentFormat,
  PapiProductType,
} from 'app/types/generated/globalTypes';
import { PROVIDER_TYPES_WITH_VISITS } from 'constants/provider';

import { CALENDAR_PAGE_QUERY } from './query';

// Types & constants ////////////////////////////////
interface VisitPos {
  column?: number;
  left?: number;
  width?: number;
}

/** Calendar page with date range & filters */
const CalendarPage: FC = () => {
  const viewer = useContext(ViewerContext);

  /***
   * Date range state
   */
  const [interval, setInterval] = useState(
    Interval.fromDateTimes(
      DateTime.local().startOf('week').minus({ days: 1 }),
      DateTime.local().endOf('week').minus({ days: 1 })
    )
  );

  const goToToday = (): void => {
    const thisWeek = Interval.fromDateTimes(
      DateTime.local().startOf('week').minus({ days: 1 }),
      DateTime.local().endOf('week').minus({ days: 1 })
    );
    setInterval(thisWeek);
  };

  /***
   * Filters
   */
  const [selectedCenterID, setSelectedCenterID] = useState<
    string | undefined
  >();
  const [selectedProduct, setSelectedProduct] = useState<
    PapiProductType | undefined
  >();
  const [selectedVisitFormat, setSelectedVisitFormat] = useState<
    PapiAppointmentFormat | undefined
  >();

  /***
   * Provider & visits
   */
  const [selectedProviderID, setSelectedProviderID] = useState<
    string | undefined
  >();
  const [getVisits, { data, loading: loadingVisits }] = useLazyQuery<
    CalendarPage_Visits_Query,
    CalendarPage_Visits_QueryVariables
  >(CALENDAR_PAGE_QUERY, {
    fetchPolicy: 'network-only',
    pollInterval: 60000,
  });

  useEffect(() => {
    if (!selectedProviderID) {
      return;
    }

    getVisits({
      variables: {
        centerID: selectedCenterID,
        format: selectedVisitFormat,
        maxTime: interval.end.toISO(),
        minTime: interval.start.toISO(),
        productType: selectedProduct,
        providerID: selectedProviderID,
      },
    });
  }, [
    getVisits,
    interval,
    selectedCenterID,
    selectedProduct,
    selectedProviderID,
    selectedVisitFormat,
  ]);

  /***
   * Selected visit
   */
  const [selectedVisit, setSelectedVisit] = useState<
    CalendarPage_Visits_Query_provider_visits | undefined
  >();
  const [modalPos, setModalPos] = useState<ClientRect>(); // To align modal

  const onVisitClick = (visitCardPos: ClientRect) => (
    visitId: string
  ): void => {
    const visit = data!.provider!.visits.find((visit) => visit.id === visitId);
    setSelectedVisit(visit);
    setModalPos(visitCardPos);
  };
  const onVisitDetailsClose = async (): Promise<void> => {
    setSelectedVisit(undefined);
    setModalPos(undefined);
  };

  const selectedVisitColumn =
    selectedVisit &&
    Math.floor(
      DateTime.fromISO(selectedVisit.startTime).diff(interval.start, 'days')
        .days
    );
  const selectedVisitPos = {
    column: selectedVisitColumn,
    left: modalPos?.left,
    width: modalPos?.width,
  };

  return (
    <>
      <CalendarHeaderContainer>
        <HeaderControls>
          <AButton data-testid="Calendar_today-button" onClick={goToToday}>
            Today
          </AButton>
          <CalendarControls
            interval={interval}
            onIntervalChange={setInterval}
          />
        </HeaderControls>

        <HeaderControls>
          <ASelectProvider
            allowClear={false}
            collapseError
            customWidth={{ fixed: '160px' }}
            defaultViewer={viewer}
            dropdownMatchSelectWidth={false}
            filter={(provider) =>
              PROVIDER_TYPES_WITH_VISITS.includes(provider.type) &&
              !!provider.acuityID
            }
            onChange={setSelectedProviderID}
            placeholder="Provider"
            value={selectedProviderID}
          />
          <ASelectCenter
            collapseError
            customWidth={{ fixed: '120px' }}
            data-testid="CalendarPage_selectCenter"
            dropdownMatchSelectWidth={false}
            onChange={setSelectedCenterID}
            placeholder="Center"
            value={selectedCenterID}
          />
          <ASelectProduct
            collapseError
            customWidth={{ fixed: '120px' }}
            data-testid="CalendarPage_selectProduct"
            dropdownMatchSelectWidth={false}
            onChange={setSelectedProduct}
            placeholder="Product"
            value={selectedProduct}
          />
          <ASelectVisitFormat
            collapseError
            customWidth={{ fixed: '120px' }}
            data-testid="CalendarPage_selectVisitFormat"
            dropdownMatchSelectWidth={false}
            onChange={setSelectedVisitFormat}
            placeholder="Format"
            value={selectedVisitFormat}
          />
        </HeaderControls>
      </CalendarHeaderContainer>

      <Calendar
        interval={interval}
        loading={loadingVisits}
        onVisitClick={onVisitClick}
        selectedVisitId={selectedVisit?.id}
        timezone={data?.provider?.timezone || DateTime.local().zoneName}
        visits={
          data?.provider?.visits ||
          ([] as CalendarPage_Visits_Query_provider_visits[])
        }
      />

      <VisitDetailsModal
        closeIcon={<AIconClose />}
        destroyOnClose
        footer={null}
        mask={false}
        numColumns={Math.ceil(interval.end.diff(interval.start, 'days').days)}
        onCancel={onVisitDetailsClose}
        visible={!!selectedVisit}
        visitPos={selectedVisitPos}
        width={theme.layout.drawerSizeDefault}
      >
        {selectedVisit?.patientID && (
          <VisitDetailsHeader>
            <VisitDetailsLinks>
              <MemberLinks
                excludedLinks={['hubspot', 'stripe']}
                memberID={selectedVisit.patientID}
              />
            </VisitDetailsLinks>
            <VisitDetailsHeaderDivider />
          </VisitDetailsHeader>
        )}
        {selectedVisit && (
          <VisitDetailsBody>
            <VisitDetails visitId={selectedVisit.id} />
          </VisitDetailsBody>
        )}
      </VisitDetailsModal>
    </>
  );
};

// Styled components ////////////////////////////////
const CalendarHeaderContainer = styled.div`
  align-content: center;
  background-color: ${theme.color.white};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${theme.space.m} ${theme.space.l} 0 ${theme.space.l};
`;

const HeaderControls = styled.div`
  display: flex;
  margin-bottom: ${theme.space.m};
  > * {
    :not(:last-child) {
      margin-right: ${theme.space.m};
    }
  }
`;

const VisitDetailsBody = styled.div`
  padding: ${theme.space.s} ${theme.space.l} ${theme.space.s} ${theme.space.m};
`;

const VisitDetailsHeader = styled.div`
  background-color: white;
  border-radius: ${theme.space.s};
  position: sticky;
  top: 0;
  z-index: 1;
`;

const VisitDetailsHeaderDivider = styled(Divider)`
  &&& {
    margin: 0;
  }
`;

const VisitDetailsLinks = styled.div`
  padding: ${theme.space.l} 0 ${theme.space.m} ${theme.space.l};
`;

const VisitDetailsModal = styled(Modal)<{
  numColumns: number;
  visitPos: VisitPos;
}>`
  &&& {
    @media (min-width: ${({ numColumns }) =>
        theme.layout.navbarSizeCollapsed +
        theme.layout.calendar.timeColumnWidth +
        theme.layout.calendar.cellMinWidth * numColumns}px) {
      margin: 0;
      ${({ numColumns, visitPos: { column = 0, left = 0, width = 0 } }) =>
        column < 3
          ? `
            margin-left: calc(
              ${theme.layout.navbarSizeCollapsed}px +
                ${theme.layout.calendar.timeColumnWidth}px
            );
            left: ${left + width}px;`
          : `
            margin-left: auto;
            right: ${(numColumns - column) * width + 2}px`};
    }

    top: calc(${theme.layout.calendar.headerHeight}px + ${theme.space.l});
    .ant-modal-body {
      height: 100%;
      overflow-y: scroll;
      padding: 0;
    }
    .ant-modal-content {
      height: 85vh;
    }
  }
`;

export { CalendarPage };
