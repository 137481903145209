import { useMutation, useQuery } from '@apollo/react-hooks';
import { Progress, Divider, Button, message } from 'antd';
import { range } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { AIconClickable } from 'app/components/atoms/AIconClickable/AIconClickable';
import { ASelect } from 'app/components/atoms/ASelect/ASelect';
import { ATextClickable } from 'app/components/atoms/ATextClickable/ATextClickable';
import { theme } from 'app/styles/theme';
import {
  ProviderPanel_Mutation,
  ProviderPanel_MutationVariables,
} from 'app/types/generated/ProviderPanel_Mutation';
import {
  ProviderPanel_Query,
  ProviderPanel_QueryVariables,
} from 'app/types/generated/ProviderPanel_Query';
import { STATUS_MESSAGE } from 'constants/message';

import { PROVIDER_PANEL_MUTATION, PROVIDER_PANEL_QUERY } from './query';

interface PanelManagementProps {
  providerID: string;
}

export const ProviderPanel: FC<PanelManagementProps> = ({ providerID }) => {
  const { data, loading: queryLoading } = useQuery<
    ProviderPanel_Query,
    ProviderPanel_QueryVariables
  >(PROVIDER_PANEL_QUERY, {
    variables: { providerID },
  });

  const [updateProvider, { loading }] = useMutation<
    ProviderPanel_Mutation,
    ProviderPanel_MutationVariables
  >(PROVIDER_PANEL_MUTATION);

  const [edit, setEdit] = useState<boolean>(false);
  const [targetPanelSize, setTargetPanelSize] = useState<number>(0);

  const targetSizeOptions = range(0, 525, 25);

  const provider = data?.provider;
  const currentPanelSize = data?.provider?.currentPanelSize || 0;
  const percent = (currentPanelSize / targetPanelSize) * 100;

  const handleEditClick = (): void => {
    setEdit(true);
  };

  useEffect(() => {
    if (provider && provider.targetPanelSize) {
      setTargetPanelSize(provider?.targetPanelSize);
    }
  }, [provider]);

  const onSave = useCallback(async () => {
    try {
      const result = await updateProvider({
        variables: {
          input: {
            careManagerID: provider?.assignedCareManager?.id,
            id: provider?.id!,
            targetPanelSize,
          },
        },
      });

      if (result?.data) {
        message.success(STATUS_MESSAGE.providerPanelSizeUpdate.success);
        setEdit(false);
      } else {
        message.error(STATUS_MESSAGE.providerPanelSizeUpdate.error.general);
      }
      setEdit(false);
    } catch (err) {
      message.error(STATUS_MESSAGE.providerPanelSizeUpdate.error.general);
    }
  }, [provider, targetPanelSize, updateProvider]);

  if (queryLoading) {
    return null;
  }

  return (
    <PanelManagementContainer color={!edit ? '#FFF' : '#F9FCFE'}>
      {!edit ? (
        <>
          <PanelRow>
            <PanelTextWrapper>
              <PanelText>Current Size</PanelText>
              <PanelNumber>{currentPanelSize}</PanelNumber>
            </PanelTextWrapper>

            <PanelTextWrapper>
              <PanelText>Target Size</PanelText>
              <PanelNumber>{targetPanelSize}</PanelNumber>
            </PanelTextWrapper>
          </PanelRow>
          <ProgressBar
            percent={percent}
            showInfo={false}
            strokeColor={percent < 100 ? theme.color.jade : theme.color.crimson}
          />
          <Divider />
          <EditTextWrapper>
            <AIconClickable onClick={handleEditClick} primary type="edit" />
            <ATextClickable
              data-testid="edit-target-size-clickable-text"
              onClick={handleEditClick}
            >
              Edit Target Size
            </ATextClickable>
          </EditTextWrapper>
        </>
      ) : (
        <>
          <EditText>Edit Target Size</EditText>
          <PanelSizeSelect
            customWidth={{ fixed: '498px' }}
            data-testid="target-panel-size-select"
            onChange={(value) => setTargetPanelSize(value)}
            selectOptions={targetSizeOptions.map((number) => ({
              label: number,
              value: number,
            }))}
          />

          <ButtonsContainer>
            <VistCardButton onClick={() => setEdit(false)}>
              Cancel
            </VistCardButton>
            <VistCardButton loading={loading} onClick={onSave} type="primary">
              Save
            </VistCardButton>
          </ButtonsContainer>
        </>
      )}
    </PanelManagementContainer>
  );
};

const PanelManagementContainer = styled.div<{
  color: string;
}>`
  background-color: ${({ color }) => color};
  border: 1px solid #dfdfe0;
  width: 538px;
`;

const ProgressBar = styled(Progress)`
  margin: 0 40px;
  width: 458px;
`;

const PanelTextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const PanelText = styled.div`
  color: ${theme.color.darkGrey};
  font-size: 14px;
`;

const PanelNumber = styled.div`
  color: ${theme.color.darkGrey};
  font-size: 24px;
  margin-top: 10px;
`;

const PanelRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 30px 60px 20px 40px;
`;

const EditTextWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0px auto 20px auto;
  width: 125px;
`;

const EditText = styled.p`
  color: ${theme.color.darkGrey};
  font-size: 14px;
  margin: 20px 20px 10px 20px;
`;

const PanelSizeSelect = styled(ASelect)`
  margin: 0 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 auto 31px auto;
  padding: ${theme.space.m} 0 0 0;
  width: 314px;
`;

const VistCardButton = styled(Button)`
  height: 40px;
  margin-right: 10px;
  width: 152px;
`;
