import { useQuery } from '@apollo/react-hooks';
import React, { FC } from 'react';

import { ASelect, ASelectProps } from 'app/components/atoms/ASelect/ASelect';
import {
  ASelectProduct_Query,
  ASelectProduct_Query_products,
} from 'app/types/generated/ASelectProduct_Query';
import { compareLabel } from 'app/utils/sort';

import { A_SELECT_PRODUCT_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props extends Omit<ASelectProps, 'selectOptions'> {
  filter?: (provider: ASelectProduct_Query_products) => boolean;
}

/** Product select component. Allows filter function */
const ASelectProduct: FC<Props> = ({
  filter: filterFunction,
  loading,
  ...props
}) => {
  const { data, loading: productsLoading } = useQuery<ASelectProduct_Query>(
    A_SELECT_PRODUCT_QUERY
  );

  const filteredProducts = (data?.products || []).filter((product) =>
    filterFunction ? filterFunction(product) : true
  );

  const selectOptions = filteredProducts
    .map((prod) => ({
      label: prod.displayName,
      value: prod.type,
    }))
    .sort(compareLabel);

  return (
    <ASelect
      filterOption={(input, option) => {
        const productWords = option.props.title?.toLowerCase().split('_');
        if (!productWords) {
          return false;
        }

        // Check that every input word is part of some word in the product name,
        // e.g. "comp care vir" would include the result "Complete Care Y1 Virtual"
        return input
          .toLowerCase()
          .split(' ')
          .every((inputWord) =>
            productWords.find((productWord) => productWord.includes(inputWord))
          );
      }}
      loading={loading || productsLoading}
      searchable
      selectOptions={selectOptions}
      {...props}
    />
  );
};

export { ASelectProduct };
