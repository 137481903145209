import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { message } from 'antd';
import debounce from 'lodash/debounce';
import { DateTime } from 'luxon';
import React, { FC, useState, useMemo, useCallback } from 'react';
import Select from 'react-select';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { ASegmentedControl } from 'app/components/atoms/ASegmentedControl/ASegmentedControl';
import { ACheckboxField } from 'app/components/deprecated/ACheckboxField/ACheckboxField';
import { ADateField } from 'app/components/deprecated/ADateField/ADateField';
import {
  AForm,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { AInputField } from 'app/components/deprecated/AInputField/AInputField';
import {
  ASelectField,
  ISelectFieldOption,
} from 'app/components/deprecated/ASelectField/ASelectField';
import {
  addInsuranceSubscriber as addInsuranceSubscriberMutation,
  addInsuranceSubscriberVariables,
} from 'app/types/generated/addInsuranceSubscriber';
import {
  getInsuranceSubscriberByPersonId,
  getInsuranceSubscriberByPersonIdVariables,
} from 'app/types/generated/getInsuranceSubscriberByPersonId';
import {
  getPersonFieldsById,
  getPersonFieldsByIdVariables,
} from 'app/types/generated/getPersonFieldsById';
import { getSubscriberRelationshipTypes } from 'app/types/generated/getSubscriberRelationshipTypes';
import { PapiSex } from 'app/types/generated/globalTypes';
import { MemberNewInsuranceCard_Query_subscriber as Subscriber } from 'app/types/generated/MemberNewInsuranceCard_Query';
import {
  searchInsuranceCompanyVariables,
  searchInsuranceCompany as searchInsuranceCompanyQuery,
  searchInsuranceCompany_searchInsuranceCompany,
} from 'app/types/generated/searchInsuranceCompany';
import {
  updateInsuranceSubscriber as updateInsuranceSubscriberMutation,
  updateInsuranceSubscriberVariables,
} from 'app/types/generated/updateInsuranceSubscriber';
import { displayErrors } from 'app/utils/app';
import { toTitleCase } from 'app/utils/string';
import { US_STATES } from 'constants/form';
import { STATUS_MESSAGE } from 'constants/message';

import {
  ADD_INSURANCE_SUBSCRIBER,
  GET_INSURANCE_SUBSCRIBER_BY_PERSON_ID,
  GET_SUBSCRIBER_RELATIONSHIP,
  SEARCH_INSURANCE_COMPANY,
  UPDATE_INSURANCE_SUBSCRIBER,
  GET_PERSON_FIELDS_BY_ID,
} from './Query';

interface SelectOption {
  label: string;
  value: string;
}

const SEGMENTED_CONTROL_OPTIONS = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];
const DEBOUNCE_WAIT = 250;

const getMaybeString = (value: string | null | undefined): string => {
  return value ?? '';
};

const normalizeDate = (value: string | null | undefined): string => {
  if (value === undefined || value === null) {
    return '';
  }

  return DateTime.fromFormat(value, 'D').toISODate();
};

const EditInsuranceForm: FC<{
  onSuccess?: () => void;
  personID: string;
  subscriber?: Subscriber;
}> = ({ onSuccess, personID, subscriber }) => {
  const [input, setInput] = useState('');

  const personFields = useQuery<
    getPersonFieldsById,
    getPersonFieldsByIdVariables
  >(GET_PERSON_FIELDS_BY_ID, {
    variables: {
      id: personID,
    },
  });

  const insuranceSubscriberByPersondId = useQuery<
    getInsuranceSubscriberByPersonId,
    getInsuranceSubscriberByPersonIdVariables
  >(GET_INSURANCE_SUBSCRIBER_BY_PERSON_ID, {
    variables: {
      active: true,
      personId: personID,
    },
  });

  const previousSubscriber =
    insuranceSubscriberByPersondId.data?.getInsuranceSubscriberByPersonId;

  const [
    insuranceCompany,
    setInsuranceCompany,
  ] = useState<searchInsuranceCompany_searchInsuranceCompany | null>(
    previousSubscriber?.payerDetails
      ? {
          __typename: 'PapiInsurancePayer',
          accepted: true,
          id: previousSubscriber.payerDetails.id,
          nationalPayerId: '',
          payerName: previousSubscriber.payerDetails.payerName,
        }
      : null
  );

  const currentPerson = personFields.data?.getPerson;

  const isPersonDataInvalid = useMemo(() => {
    return (
      !currentPerson?.addressLine1 ||
      !currentPerson?.addressCity ||
      !currentPerson?.dateOfBirth ||
      !currentPerson?.firstName ||
      !currentPerson?.lastName ||
      !currentPerson?.biologicalSex ||
      !currentPerson?.addressState ||
      !currentPerson?.addressPostalCode
    );
  }, [currentPerson]);

  const checkIfAddressIsSameAsMember = useCallback(() => {
    if (previousSubscriber) {
      return (
        previousSubscriber.addressLine1 === currentPerson?.addressLine1 &&
        previousSubscriber.addressLine2 === currentPerson?.addressLine2 &&
        previousSubscriber.city === currentPerson?.addressCity &&
        previousSubscriber.state === currentPerson?.addressState &&
        previousSubscriber.zipCode === currentPerson?.addressPostalCode
      );
    }

    return false;
  }, [currentPerson, previousSubscriber]);

  const [
    selectedMainSubscriberIndex,
    setSelectedMainSubscriberIndex,
  ] = useState(
    previousSubscriber?.relationshipToPatient &&
      !isPersonDataInvalid &&
      previousSubscriber?.relationshipToPatient === 'SEL'
      ? 0
      : 1
  );
  const isMainSubscriberSelection =
    SEGMENTED_CONTROL_OPTIONS[selectedMainSubscriberIndex].value === 'yes';

  const [
    isSubscriberAddressSameAsMemberAddress,
    setIsSubscriberAddressSameAsMemberAddress,
  ] = useState(!isPersonDataInvalid ? checkIfAddressIsSameAsMember() : false);

  const subscriberRelationship = useQuery<getSubscriberRelationshipTypes>(
    GET_SUBSCRIBER_RELATIONSHIP
  );

  const [addInsuranceSubscriber, addInsuranceSubscriberState] = useMutation<
    addInsuranceSubscriberMutation,
    addInsuranceSubscriberVariables
  >(ADD_INSURANCE_SUBSCRIBER);

  const [
    updateInsuranceSubscriber,
    updateInsuranceSubscriberState,
  ] = useMutation<
    updateInsuranceSubscriberMutation,
    updateInsuranceSubscriberVariables
  >(UPDATE_INSURANCE_SUBSCRIBER);

  const [searchInsurance, { data }] = useLazyQuery<
    searchInsuranceCompanyQuery,
    searchInsuranceCompanyVariables
  >(SEARCH_INSURANCE_COMPANY);

  const defaultSubscriber = useMemo(() => {
    if (previousSubscriber) {
      return {
        addressCity: previousSubscriber.city ?? '',
        addressLine1: previousSubscriber.addressLine1 ?? '',
        addressLine2: previousSubscriber.addressLine2 ?? '',
        addressPostalCode: previousSubscriber.zipCode ?? '',
        addressState: previousSubscriber.state ?? '',
        biologicalSex: previousSubscriber.sex ?? PapiSex.OTHER,
        dateOfBirth: previousSubscriber.dateOfBirth ?? '',
        firstName: previousSubscriber.firstName ?? '',
        lastName: previousSubscriber.lastName ?? '',
        subscriberRelationship:
          previousSubscriber.relationshipToPatient !== ''
            ? previousSubscriber.relationshipToPatient
            : 'SEL',
      };
    }

    return {
      addressCity: currentPerson?.addressCity ?? '',
      addressLine1: currentPerson?.addressLine1 ?? '',
      addressLine2: currentPerson?.addressLine2 ?? '',
      addressPostalCode: currentPerson?.addressPostalCode ?? '',
      addressState: currentPerson?.addressState ?? '',
      biologicalSex: currentPerson?.biologicalSex ?? PapiSex.OTHER,
      dateOfBirth: currentPerson?.dateOfBirth ?? '',
      firstName: currentPerson?.firstName ?? '',
      lastName: currentPerson?.lastName ?? '',
      subscriberRelationship: 'SEL',
    };
  }, [currentPerson, previousSubscriber]);

  const handleChange = (newValue: string): void => {
    if (!input || input.length < 3) {
      setInput(newValue);
      return;
    }

    setInput(newValue);

    searchInsuranceCompany(input);
  };

  const searchInsuranceCompany = debounce((search: string) => {
    try {
      searchInsurance({
        variables: {
          search: search || '',
        },
      });
    } catch (err) {
      console.error(err);
    }
  }, DEBOUNCE_WAIT);

  const onSelect = (value: SelectOption | null): void => {
    const selectedCompany = value
      ? data?.searchInsuranceCompany.find(
          (company) => company.id === value.value
        )
      : value;

    if (selectedCompany !== undefined) {
      setInsuranceCompany(selectedCompany);
    }
  };

  const onSave = async (
    input: { [K in keyof typeof fields]: any },
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      if (isPersonDataInvalid && isMainSubscriberSelection) {
        message.warning(
          STATUS_MESSAGE.insuranceUpdate.personDataInvalid.general,
          7
        );
        return;
      }

      const copay =
        input.copay || input.copay === 0
          ? Number(input.copay.replaceAll(',', '')) * 100
          : null;

      if (
        !previousSubscriber ||
        previousSubscriber.payerId !== insuranceCompany?.id
      ) {
        const result = await addInsuranceSubscriber({
          variables: {
            input: {
              copayAmountInCents: copay,
              country: 'US',
              groupId: getMaybeString(input.groupId),
              payerId: getMaybeString(insuranceCompany?.id),
              personId: personID,
              planId: getMaybeString(input.memberId),
              policyNumber: input.memberId,
              ...(isMainSubscriberSelection
                ? {
                    dateOfBirth: currentPerson?.dateOfBirth ?? '',
                    firstName: currentPerson?.firstName ?? '',
                    lastName: currentPerson?.lastName ?? '',
                    relationshipToPatient: 'SEL',
                    sex: currentPerson?.biologicalSex ?? PapiSex.OTHER,
                  }
                : {
                    dateOfBirth: input.dateOfBirth
                      ? normalizeDate(input.dateOfBirth.format('l'))
                      : defaultSubscriber.dateOfBirth,
                    firstName: input.name ?? defaultSubscriber.firstName,
                    lastName: input.lastName ?? defaultSubscriber.lastName,
                    relationshipToPatient:
                      getMaybeString(input.relationShipToMainSubscriber) || '',
                    sex: input.sex ?? PapiSex.OTHER,
                  }),
              ...(isSubscriberAddressSameAsMemberAddress
                ? {
                    addressLine1: currentPerson?.addressLine1 ?? '',
                    addressLine2: currentPerson?.addressLine2 ?? '',
                    city: currentPerson?.addressCity ?? '',
                    state: currentPerson?.addressState ?? '',
                    zipcode: currentPerson?.addressPostalCode ?? '',
                  }
                : {
                    addressLine1:
                      input.streetAddress ?? defaultSubscriber.addressLine1,
                    addressLine2: input.addressLine2,
                    city: input.city ?? defaultSubscriber.addressCity,
                    state: input.state ?? defaultSubscriber.addressState,
                    zipcode: input.zip ?? defaultSubscriber.addressPostalCode,
                  }),
            },
          },
        });
        if (result?.data) {
          message.success(STATUS_MESSAGE.insuranceUpdate.success);
          if (onSuccess) {
            onSuccess();
          }
          shouldFinish();
        } else {
          message.warning(STATUS_MESSAGE.insuranceUpdate.error, 7);
        }
      } else {
        const result = await updateInsuranceSubscriber({
          variables: {
            input: {
              copayAmountInCents: copay,
              country: 'US',
              groupId: getMaybeString(input.groupId),
              id: previousSubscriber.id,
              payerId: getMaybeString(insuranceCompany?.id),
              personId: personID,
              planId: getMaybeString(input.memberId),
              policyNumber: input.memberId,
              ...(isMainSubscriberSelection
                ? {
                    dateOfBirth: currentPerson?.dateOfBirth ?? '',
                    firstName: currentPerson?.firstName ?? '',
                    lastName: currentPerson?.lastName ?? '',
                    relationshipToPatient: 'SEL',
                    sex: currentPerson?.biologicalSex ?? PapiSex.OTHER,
                  }
                : {
                    dateOfBirth: input.dateOfBirth
                      ? normalizeDate(input.dateOfBirth.format('l'))
                      : defaultSubscriber.dateOfBirth,
                    firstName: input.name ?? defaultSubscriber.firstName,
                    lastName: input.lastName ?? defaultSubscriber.lastName,
                    relationshipToPatient: getMaybeString(
                      input.relationShipToMainSubscriber
                    ),
                    sex: input.sex ?? PapiSex.OTHER,
                  }),

              ...(isSubscriberAddressSameAsMemberAddress
                ? {
                    addressLine1: currentPerson?.addressLine1 ?? '',
                    addressLine2: currentPerson?.addressLine2 ?? '',
                    city: currentPerson?.addressCity ?? '',
                    state: currentPerson?.addressState ?? '',
                    zipcode: currentPerson?.addressPostalCode ?? '',
                  }
                : {
                    addressLine1:
                      input.streetAddress ?? defaultSubscriber.addressLine1,
                    addressLine2: input.addressLine2,
                    city: input.city ?? defaultSubscriber.addressCity,
                    state: input.state ?? defaultSubscriber.addressState,
                    zipcode: input.zip ?? defaultSubscriber.addressPostalCode,
                  }),
            },
          },
        });
        if (result?.data) {
          message.success(STATUS_MESSAGE.insuranceUpdate.success);
          if (onSuccess) {
            onSuccess();
          }
          shouldFinish();
        } else {
          message.warning(STATUS_MESSAGE.insuranceUpdate.error, 7);
        }
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.planPurchaseUpdate.error.general);
      shouldFinish(false);
    }
  };

  const sexOptions: ISelectFieldOption[] = Object.keys(PapiSex).map((key) => ({
    label: toTitleCase(PapiSex[key] as string),
    value: PapiSex[key] as string,
  }));

  const relationshipOptions: ISelectFieldOption[] = subscriberRelationship?.data
    ? subscriberRelationship.data.getRelationshipTypes.map((relationship) => ({
        label: relationship.description,
        value: relationship.id,
      }))
    : [];

  const existingSubscriberData = subscriber;

  const title = useMemo(() => {
    if (insuranceSubscriberByPersondId.data?.getInsuranceSubscriberByPersonId) {
      return 'Edit Insurance';
    }

    return 'Add Insurance';
  }, [insuranceSubscriberByPersondId.data]);

  return (
    <AForm
      loading={
        subscriberRelationship.loading || insuranceSubscriberByPersondId.loading
      }
      onSave={onSave}
      openAs="modal"
      openText={title}
      saving={
        addInsuranceSubscriberState.loading ||
        updateInsuranceSubscriberState.loading
      }
      title={title}
    >
      {(form) => {
        return (
          <>
            <AFormFieldRow>
              <StyledSelect
                defaultValue={
                  insuranceCompany
                    ? {
                        label: insuranceCompany?.payerName,
                        value: insuranceCompany?.id,
                      }
                    : insuranceCompany
                }
                isClearable
                noOptionsMessage={({ inputValue }) =>
                  !inputValue
                    ? 'Please type the name of your Insurance Company'
                    : 'No results found'
                }
                onChange={(newValue) => onSelect(newValue as SelectOption)}
                onInputChange={handleChange}
                options={
                  data?.searchInsuranceCompany.map((company) => ({
                    label: company.payerName,
                    value: company.id,
                  })) ?? []
                }
                placeholder="Search insurance company*"
                selected={!!input}
                value={
                  insuranceCompany
                    ? {
                        label: insuranceCompany?.payerName,
                        value: insuranceCompany?.id,
                      }
                    : {
                        label: 'Search insurance company*',
                        value: '',
                      }
                }
              />
            </AFormFieldRow>
            <AFormFieldRow>
              <AInputField
                antdForm={form}
                customWidth={{ percent: 33 }}
                initialValue={existingSubscriberData?.groupId}
                label={fields.groupId.label}
                name={fields.groupId.name}
                required
              />
              <AInputField
                antdForm={form}
                customWidth={{ percent: 33 }}
                initialValue={existingSubscriberData?.planId}
                label={fields.memberId.label}
                name={fields.memberId.name}
                required
              />
              <AInputField
                antdForm={form}
                customWidth={{ percent: 33 }}
                initialValue={
                  existingSubscriberData?.copayAmountInCents ||
                  existingSubscriberData?.copayAmountInCents === 0
                    ? `${existingSubscriberData.copayAmountInCents / 100}`
                    : undefined
                }
                label={fields.copay.label}
                name={fields.copay.name}
                number="dollar"
              />
            </AFormFieldRow>
            {!isPersonDataInvalid && (
              <AFormFieldRow
                style={{ marginBottom: '0.5em', marginTop: '0.5em' }}
              >
                <ALabel black inline>
                  Is this member the main subscriber of this health insurance
                  plan?
                  <div style={{ marginTop: '0.5em' }}>
                    <ASegmentedControl
                      fieldName={fields.mainSubscriber.name}
                      onIndexSelected={setSelectedMainSubscriberIndex}
                      options={SEGMENTED_CONTROL_OPTIONS}
                      selectedIndex={selectedMainSubscriberIndex}
                    />
                  </div>
                </ALabel>
              </AFormFieldRow>
            )}
            {isMainSubscriberSelection ? null : (
              <>
                <AFormFieldRow>
                  <ASelectField
                    antdForm={form}
                    customWidth={{ percent: 100 }}
                    initialValue={defaultSubscriber?.subscriberRelationship}
                    label={fields.relationShipToMainSubscriber.label}
                    loading={subscriberRelationship?.loading}
                    name={fields.relationShipToMainSubscriber.name}
                    required
                    selectOptions={relationshipOptions}
                  />
                </AFormFieldRow>
                <AFormFieldRow>
                  <AInputField
                    antdForm={form}
                    customWidth={{ percent: 50 }}
                    initialValue={defaultSubscriber?.firstName}
                    label={fields.name.label}
                    name={fields.name.name}
                    required
                  />
                  <AInputField
                    antdForm={form}
                    customWidth={{ percent: 50 }}
                    initialValue={defaultSubscriber?.lastName}
                    label={fields.lastName.label}
                    name={fields.lastName.name}
                    required
                  />
                </AFormFieldRow>
                <AFormFieldRow>
                  <ASelectField
                    antdForm={form}
                    customWidth={{ percent: 50 }}
                    initialValue={defaultSubscriber?.biologicalSex}
                    label={fields.sex.label}
                    name={fields.sex.name}
                    required
                    selectOptions={sexOptions}
                  />
                  <ADateField
                    antdForm={form}
                    customWidth={{ percent: 50 }}
                    initialValue={defaultSubscriber.dateOfBirth}
                    label={fields.dateOfBirth.label}
                    name={fields.dateOfBirth.name}
                    required
                  />
                </AFormFieldRow>
                {isSubscriberAddressSameAsMemberAddress ? null : (
                  <>
                    <AFormFieldRow>
                      <AInputField
                        antdForm={form}
                        customWidth={{ percent: 100 }}
                        initialValue={defaultSubscriber?.addressLine1}
                        label={fields.streetAddress.label}
                        name={fields.streetAddress.name}
                        required={!isSubscriberAddressSameAsMemberAddress}
                      />
                    </AFormFieldRow>
                    <AFormFieldRow>
                      <AInputField
                        antdForm={form}
                        customWidth={{ percent: 100 }}
                        initialValue={defaultSubscriber?.addressLine2}
                        label={fields.addressLine2.label}
                        name={fields.addressLine2.name}
                      />
                    </AFormFieldRow>
                    <AFormFieldRow>
                      <AInputField
                        antdForm={form}
                        customWidth={{ percent: 50 }}
                        initialValue={defaultSubscriber?.addressCity}
                        label={fields.city.label}
                        name={fields.city.name}
                        required={!isSubscriberAddressSameAsMemberAddress}
                      />
                      <ASelectField
                        antdForm={form}
                        customWidth={{ percent: 50 }}
                        initialValue={defaultSubscriber?.addressState}
                        label={fields.state.label}
                        name={fields.state.name}
                        required={!isSubscriberAddressSameAsMemberAddress}
                        selectOptions={US_STATES}
                      />
                    </AFormFieldRow>
                    <AFormFieldRow>
                      <AInputField
                        antdForm={form}
                        customWidth={{ percent: 50 }}
                        initialValue={defaultSubscriber?.addressPostalCode}
                        label={fields.zip.label}
                        name={fields.zip.name}
                        required={!isSubscriberAddressSameAsMemberAddress}
                      />
                    </AFormFieldRow>
                  </>
                )}
                {!isPersonDataInvalid && (
                  <AFormFieldRow>
                    <ACheckboxField
                      antdForm={form}
                      customWidth={{ percent: 100 }}
                      initialValue={isSubscriberAddressSameAsMemberAddress}
                      label={fields.subscriberAddressSameAsMemberAddress.label}
                      name={fields.subscriberAddressSameAsMemberAddress.name}
                      onChange={(e) =>
                        setIsSubscriberAddressSameAsMemberAddress(
                          e.target.checked
                        )
                      }
                    />
                  </AFormFieldRow>
                )}
              </>
            )}
          </>
        );
      }}
    </AForm>
  );
};

const StyledSelect = styled(Select)<{ selected?: boolean }>`
  margin-bottom: 24px;
  width: 100%;

  div {
    border-radius: 4px;

    div {
      div: first-child {
        font-size: 16px;
        height: 32px;
        line-height: 1.5;
        padding: 4px 11px;
        z-index: 999;
      }
    }
  }
`;

/** Form field info */
const fields = {
  addressLine2: {
    label: 'Address Line 2',
    name: 'addressLine2',
  },
  city: {
    label: 'City',
    name: 'city',
  },
  copay: {
    label: 'Copay',
    name: 'copay',
  },
  dateOfBirth: {
    label: 'Date of Birth',
    name: 'dateOfBirth',
  },
  groupId: {
    label: 'Group ID',
    name: 'groupId',
  },
  lastName: {
    label: 'Last Name',
    name: 'lastName',
  },
  mainSubscriber: {
    label: 'Main Subscriber',
    name: 'mainSubscriber',
  },
  memberId: {
    label: 'Member ID',
    name: 'memberId',
  },
  name: {
    label: 'Name',
    name: 'name',
  },
  relationShipToMainSubscriber: {
    label: 'Relationship to main subscriber',
    name: 'relationShipToMainSubscriber',
  },
  sex: {
    label: 'Sex',
    name: 'sex',
  },
  state: {
    label: 'State',
    name: 'state',
  },
  streetAddress: {
    label: 'Street Address',
    name: 'streetAddress',
  },
  subscriberAddressSameAsMemberAddress: {
    label: `Subscriber's address same as Member's address`,
    name: 'subscriberAddressSameAsMemberAddress',
  },
  zip: {
    label: 'Zip',
    name: 'zip',
  },
};

export { fields, EditInsuranceForm };
