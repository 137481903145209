import { Divider } from 'antd';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import React, { FC } from 'react';

import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import {
  ASelectField,
  ASelectFieldProps,
} from 'app/components/deprecated/ASelectField/ASelectField';
import { ASelectCouponField_Fragment_coupon } from 'app/types/generated/ASelectCouponField_Fragment_coupon';
import { PapiProductType } from 'app/types/generated/globalTypes';
import { getCouponDescription } from 'app/utils/coupon';

// Types & constants ////////////////////////////////
interface Props extends Omit<ASelectFieldProps, 'selectOptions'> {
  coupons: ASelectCouponField_Fragment_coupon[] | null;
  couponsToAlwaysAllow?: ASelectCouponField_Fragment_coupon[] | null;
  filterValues?: CouponFilters;
  initialValue?: string;
}

interface CouponFilters {
  centerID?: string;
  planID?: string;
  product?: PapiProductType;
}

interface CouponOption {
  disabled: boolean;
  label: string;
  subLabel: string;
  value: string;
}

/** Deprecated: Antd form field, being deprecated to use react-hook-form */
const ASelectCouponField: FC<Props> = ({
  coupons,
  couponsToAlwaysAllow,
  filterValues,
  label = 'Coupon',
  ...props
}) => {
  return (
    <ASelectField
      allowClear
      filterOption={(input, option) =>
        (option.props.value! as string) // value = coupon slug
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      label={label}
      placeholder="Select or search"
      selectOptions={getCouponOptions(
        coupons,
        filterValues,
        couponsToAlwaysAllow
      ).map((option) => ({
        ...option,
        label: (
          <>
            {option.label}
            <Divider type="vertical" />
            {option.disabled ? (
              option.subLabel
            ) : (
              <ATextLight lighter>{option.subLabel}</ATextLight>
            )}
          </>
        ),
      }))}
      showSearch
      {...props}
    />
  );
};

// Helpers ////////////////////////////////
const getCouponOptions = (
  coupons: ASelectCouponField_Fragment_coupon[] | null,
  filterValues?: CouponFilters,
  couponsToAlwaysAllow?: ASelectCouponField_Fragment_coupon[] | null
): CouponOption[] => {
  const allCoupons: ASelectCouponField_Fragment_coupon[] = coupons || [];
  (couponsToAlwaysAllow || []).forEach((allowedCoupon) => {
    if (!allCoupons.find((coupon) => coupon.slug === allowedCoupon.slug)) {
      allCoupons.push(allowedCoupon);
    }
  });

  const couponOptions = allCoupons.map((coupon) => {
    const { centers, plans, products, slug } = coupon;
    let invalidField: string | null = null;
    if (
      centers.length > 0 &&
      (!filterValues ||
        !centers.find((center) => center.id === filterValues.centerID))
    ) {
      invalidField = 'Center';
    } else if (
      plans.length > 0 &&
      (!filterValues || !plans.find((plan) => plan.id === filterValues.planID))
    ) {
      invalidField = 'Plan or payment';
    } else if (
      products.length > 0 &&
      (!filterValues ||
        !products.find((product) => product.type === filterValues.product))
    ) {
      invalidField = 'Plan';
    }

    return {
      disabled: !!invalidField,
      label: slug,
      subLabel: invalidField
        ? `${invalidField} not eligible`
        : getCouponDescription(coupon),
      value: slug,
    };
  });

  const [ineligibleCoupons, eligibleCoupons] = partition(
    couponOptions,
    (coupon) => coupon.disabled === true
  );
  return [
    ...sortBy(eligibleCoupons, (coupon) => coupon.value.toLowerCase()),
    ...sortBy(ineligibleCoupons, (coupon) => coupon.value.toLowerCase()),
  ];
};

export { ASelectCouponField, getCouponOptions };
