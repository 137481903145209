import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { ATextError } from 'app/components/atoms/ATextError/ATextError';
import { ACheckboxField } from 'app/components/deprecated/ACheckboxField/ACheckboxField';
import { AConfirmationField } from 'app/components/deprecated/AConfirmationField/AConfirmationField';
import { ADateField } from 'app/components/deprecated/ADateField/ADateField';
import {
  AForm,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { theme } from 'app/styles/theme';
import { PlanPurchaseEndOrCancel_Fragment_extension } from 'app/types/generated/PlanPurchaseEndOrCancel_Fragment_extension';
import { PlanPurchaseEndOrCancel_Fragment_planPurchase } from 'app/types/generated/PlanPurchaseEndOrCancel_Fragment_planPurchase';
import {
  PlanPurchaseEndOrCancel_Mutation,
  PlanPurchaseEndOrCancel_MutationVariables,
} from 'app/types/generated/PlanPurchaseEndOrCancel_Mutation';
import { displayErrors } from 'app/utils/app';
import { isCurrentPlan } from 'app/utils/plan';
import { STATUS_MESSAGE } from 'constants/message';

import {
  PLAN_PURCHASE_END_OR_CANCEL_MUTATION,
  PLAN_PURCHASE_END_OR_CANCEL_REFETCH,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  extensions?: PlanPurchaseEndOrCancel_Fragment_extension[];
  planPurchase: PlanPurchaseEndOrCancel_Fragment_planPurchase;
}

/** Form to cancel or end early a member's care plan */
const PlanPurchaseEndOrCancel: FC<Props> = ({ extensions, planPurchase }) => {
  const [endOrCancelPlanPurchase, { loading: mutationLoading }] = useMutation<
    PlanPurchaseEndOrCancel_Mutation,
    PlanPurchaseEndOrCancel_MutationVariables
  >(PLAN_PURCHASE_END_OR_CANCEL_MUTATION, {
    refetchQueries: [
      {
        query: PLAN_PURCHASE_END_OR_CANCEL_REFETCH,
        variables: { personID: planPurchase.member.id },
      },
    ],
  });

  const isCurrent = isCurrentPlan(planPurchase);
  const onSave = async (
    input: { [K in keyof typeof fields]: any },
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    const selectedEndDate = input.endImmediately
      ? undefined // Sending undefined will cancel the plan immediately
      : input.endDate &&
        DateTime.fromJSDate(((input.endDate as unknown) as Moment).toDate());

    const endTime = selectedEndDate
      ? DateTime.fromObject({
          day: selectedEndDate.day,
          month: selectedEndDate.month,
          year: selectedEndDate.year,
          zone: planPurchase.center.timezone,
        }).endOf('day')
      : DateTime.fromObject({ zone: planPurchase.center.timezone });

    // Find plan that is getting canceled (might be an extension)
    const planPurchaseContainingEndDate =
      (extensions || []).find(
        (extension) =>
          endTime >= DateTime.fromISO(extension.startTime) &&
          endTime <= DateTime.fromISO(extension.endTime!)
      ) || planPurchase;

    try {
      const result = await endOrCancelPlanPurchase({
        variables: {
          input: {
            cancelFollowingPurchases: true,
            cancelUpcomingAppointments: input.cancelUpcomingVisits,
            endDate: selectedEndDate ? endTime.toUTC().toISO() : undefined,
            personID: planPurchase.member.id,
            purchaseID: planPurchaseContainingEndDate.id,
          },
        },
      });
      if (result?.data) {
        message.success(
          !isCurrent
            ? STATUS_MESSAGE.planPurchaseEndOrCancel.successCancel
            : selectedEndDate
            ? STATUS_MESSAGE.planPurchaseEndOrCancel.successEndWithNewDate
            : STATUS_MESSAGE.planPurchaseEndOrCancel.successEnd
        );
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(
        err,
        isCurrent
          ? STATUS_MESSAGE.planPurchaseEndOrCancel.error.generalEnd
          : STATUS_MESSAGE.planPurchaseEndOrCancel.error.generalCancel
      );
      shouldFinish(false);
    }
  };

  return (
    <AForm
      disableOpen={
        !isCurrent &&
        DateTime.fromISO(planPurchase.startTime) < DateTime.local()
          ? { message: STATUS_MESSAGE.planPurchaseEndOrCancel.error.planEnded }
          : undefined
      }
      onSave={onSave}
      openAs="modal"
      openText={`${isCurrent ? 'End' : 'Cancel'} care plan`}
      saving={mutationLoading}
      title={`${isCurrent ? 'End' : 'Cancel'} ${
        planPurchase.plan.product.displayName
      }`}
    >
      {(form) => {
        const endImmediatelyChecked =
          form.getFieldValue(fields.endImmediately.name) ||
          // Checkbox is undefined on render, then becomes a boolean
          form.getFieldValue(fields.endImmediately.name) === undefined;

        const isAutoRenewing =
          !planPurchase.endDateIncludingExtensions &&
          planPurchase.nextRenewalDate;
        const selectedEndDate = form.isFieldTouched(fields.endDate.name)
          ? (form.getFieldValue(fields.endDate.name) as Moment | undefined)
          : undefined;
        const selectedEndDateDT =
          selectedEndDate && DateTime.fromJSDate(selectedEndDate.toDate());
        const datetimeFormat = 'L/d/yy, h:mma ZZZZ';

        return (
          <>
            {isCurrent && (
              <EndDateFields>
                <ADateField
                  antdForm={form}
                  disabled={endImmediatelyChecked}
                  disableDate={{
                    filter: disableOutOfRangeEndDates(
                      planPurchase.endDateIncludingExtensions,
                      planPurchase.center.timezone
                    ),
                  }}
                  initialValue={
                    endImmediatelyChecked
                      ? DateTime.local()
                          .setZone(planPurchase.center.timezone)
                          .toISODate()
                      : undefined
                  }
                  label={fields.endDate.label}
                  name={fields.endDate.name}
                  required
                />
                <CancelSelectContainer>
                  <CheckboxFieldStyled
                    antdForm={form}
                    initialValue={true}
                    label={fields.endImmediately.label}
                    name={fields.endImmediately.name}
                  />
                  <CheckboxFieldStyled
                    antdForm={form}
                    initialValue={true}
                    label={fields.cancelUpcomingVisits.label}
                    name={fields.cancelUpcomingVisits.name}
                  />
                </CancelSelectContainer>
              </EndDateFields>
            )}
            {isCurrent &&
              !endImmediatelyChecked &&
              !isAutoRenewing &&
              'This care plan will end at 11:59pm on the selected date.'}

            {isCurrent && !endImmediatelyChecked && isAutoRenewing && (
              <ASectionBtmMargin>
                <div>
                  This care plan renews on{' '}
                  {DateTime.fromISO(planPurchase.nextRenewalDate!)
                    .setZone(planPurchase.center.timezone)
                    .toFormat(datetimeFormat)}
                  .
                </div>
                <div>
                  {selectedEndDateDT
                    ? // The end date is calculated in onSave()
                      `You are setting the end date to ${DateTime.fromObject({
                        day: selectedEndDateDT.day,
                        month: selectedEndDateDT.month,
                        year: selectedEndDateDT.year,
                        zone: planPurchase.center.timezone,
                      })
                        .endOf('day')
                        .toFormat(datetimeFormat)}.`
                    : 'It will end at 11:59PM on the selected date.'}
                </div>
                {selectedEndDateDT &&
                  selectedEndDateDT >
                    DateTime.fromISO(planPurchase.nextRenewalDate!) && (
                    <ATextError>
                      {
                        STATUS_MESSAGE.planPurchaseEndOrCancel.warning
                          .endDateAfterRenewal
                      }
                    </ATextError>
                  )}
              </ASectionBtmMargin>
            )}

            {!isCurrent && (
              <ASectionBtmMargin>
                This care plan will be canceled and removed from the member's
                records immediately.
              </ASectionBtmMargin>
            )}

            <Warning>
              <ATextBold>
                All future plans will be removed from the member's records. This
                action cannot be undone.
              </ATextBold>{' '}
              Enter CONFIRM to continue.
            </Warning>

            <AFormFieldRow>
              <AConfirmationField antdForm={form} name="confirmation" />
            </AFormFieldRow>
          </>
        );
      }}
    </AForm>
  );
};

// Styled components ////////////////////////////////
const CheckboxFieldStyled = styled(ACheckboxField)`
  &&& {
    margin-left: ${theme.space.l};
    ${theme.layout.breakpointMixin.phoneOnly} {
      margin-left: 0;
    }
  }
`;

const CancelSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EndDateFields = styled(AFormFieldRow)`
  &&& {
    margin-bottom: ${theme.space.s};
  }
`;

const Warning = styled.div`
  margin-bottom: ${theme.space.s};
`;

// Helpers ////////////////////////////////
/**
 * Disables (returns true for) dates that are in the past in the given timezone
 * or are after the plan's end date
 */
const disableOutOfRangeEndDates = (
  endDateIncludingExtensions: PlanPurchaseEndOrCancel_Fragment_planPurchase['endDateIncludingExtensions'],
  timezone: string
): ((moment: Moment | null) => boolean) => (moment?: Moment | null) => {
  if (!moment) {
    return true;
  }
  const dt = DateTime.fromJSDate(moment.toDate());
  const dateLocalized = DateTime.fromObject({
    day: dt.day,
    month: dt.month,
    year: dt.year,
    zone: timezone,
  }).endOf('day');

  const maxEndDate = endDateIncludingExtensions
    ? DateTime.fromISO(endDateIncludingExtensions)
    : null;
  if (
    dateLocalized < DateTime.local() ||
    (maxEndDate && dateLocalized > maxEndDate)
  ) {
    return true;
  }
  return false;
};

/** Form field info */
const fields = {
  cancelUpcomingVisits: {
    label: 'Cancel upcoming visits',
    name: 'cancelUpcomingVisits',
  },
  endDate: {
    label: 'End date',
    name: 'endDate',
  },
  endImmediately: {
    label: 'End immediately',
    name: 'endImmediately',
  },
};

export { disableOutOfRangeEndDates, PlanPurchaseEndOrCancel, fields };
