import { useMutation, useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import { MutationUpdaterFn } from 'apollo-client';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { ACheckboxField } from 'app/components/deprecated/ACheckboxField/ACheckboxField';
import { ADateField } from 'app/components/deprecated/ADateField/ADateField';
import {
  AForm,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { ASelectCenterField } from 'app/components/deprecated/ASelectCenterField/ASelectCenterField';
import { ASelectField } from 'app/components/deprecated/ASelectField/ASelectField';
import { ASwitchField } from 'app/components/deprecated/ASwitchField/ASwitchField';
import { theme } from 'app/styles/theme';
import { CouponUpdate_Fragment_coupon } from 'app/types/generated/CouponUpdate_Fragment_coupon';
import {
  CouponUpdate_Mutation,
  CouponUpdate_MutationVariables,
} from 'app/types/generated/CouponUpdate_Mutation';
import { CouponUpdate_Query } from 'app/types/generated/CouponUpdate_Query';
import { PapiBillingPeriod } from 'app/types/generated/globalTypes';
import { displayErrors } from 'app/utils/app';
import {
  BillingRestriction,
  createPlanRestrictionsList,
  createProductRestrictionsList,
  createRedeemByTime,
  getCouponDescription,
  getProductOptionsFromPlans,
} from 'app/utils/coupon';
import { COUPON_TIMEZONE } from 'constants/datetime';
import { STATUS_MESSAGE } from 'constants/message';

import { COUPON_UPDATE_MUTATION, COUPON_UPDATE_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  coupon: CouponUpdate_Fragment_coupon;
  mutationCacheUpdater?: MutationUpdaterFn<CouponUpdate_Mutation>;
}

/** Form to update a coupon */
const CouponUpdate: FC<Props> = ({ coupon, mutationCacheUpdater }) => {
  const { data, loading: queryLoading } = useQuery<CouponUpdate_Query>(
    COUPON_UPDATE_QUERY
  );
  const [updateCoupon, { loading: mutationLoading }] = useMutation<
    CouponUpdate_Mutation,
    CouponUpdate_MutationVariables
  >(COUPON_UPDATE_MUTATION, { update: mutationCacheUpdater });

  const plans = data?.plans?.edges || [];
  const productOptions = getProductOptionsFromPlans(
    plans.map(({ node }) => node)
  );

  const couponActive =
    !coupon.redeemBy || DateTime.fromISO(coupon.redeemBy) > DateTime.local();
  const initialValues = {
    billing:
      coupon.plans.length === 0
        ? undefined
        : coupon.plans[0].billingPeriod === PapiBillingPeriod.MONTH
        ? BillingRestriction.Monthly
        : BillingRestriction.UpfrontOrYearly,
    centers: coupon.centers.map((center) => center.id),
    products: coupon.products
      .filter((product) => !product.type.includes('WAITLIST'))
      .map((product) => product.type)
      .sort(),
    redeemBy:
      coupon.redeemBy &&
      DateTime.fromISO(coupon.redeemBy, {
        zone: COUPON_TIMEZONE,
      }).toISODate(),
    waitlist:
      (coupon.plans.length === 0 && coupon.products.length === 0) ||
      (coupon.plans.length > 0 &&
        !!coupon.plans.find((plan) =>
          plan.product.type.includes('WAITLIST')
        )) ||
      (coupon.products.length > 0 &&
        !!coupon.products.find((product) => product.type.includes('WAITLIST'))),
  };

  const onSave = async (
    input: { [K in keyof typeof fields]: any },
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      const productRestrictions = createProductRestrictionsList(
        input,
        plans.map(({ node }) => node)
      );
      const planRestrictions = createPlanRestrictionsList(
        input,
        plans.map(({ node }) => node),
        productRestrictions
      );

      const response = await updateCoupon({
        variables: {
          input: input[fields.active.name]
            ? {
                centerIDs: input.centers,
                planIDs: planRestrictions,
                productTypes: productRestrictions,
                redeemBy: input.redeemBy && createRedeemByTime(input.redeemBy),
                slug: coupon.slug,
              }
            : {
                redeemBy: createRedeemByTime(),
                slug: coupon.slug,
              },
        },
      });
      if (response?.data) {
        message.success(STATUS_MESSAGE.couponUpdate.success);
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.couponUpdate.error.general);
      shouldFinish(false);
    }
  };

  return (
    <AForm
      loading={queryLoading}
      onSave={onSave}
      openAs="modal"
      openText="Update coupon"
      saveText="Update"
      saving={mutationLoading}
      savingText="Updating"
      title={`Update coupon ${coupon.slug}`}
    >
      {(form) => {
        const activeToggle =
          form.getFieldValue(fields.active.name) === undefined
            ? couponActive
            : form.getFieldValue(fields.active.name);

        const onActiveToggleChange = (active: boolean): void => {
          form.setFieldsValue({
            [fields.redeemBy.name]:
              initialValues.redeemBy && (active || (!active && !couponActive))
                ? moment(initialValues.redeemBy)
                : !active && couponActive
                ? moment()
                : undefined,
            [fields.centers.name]: initialValues.centers,
            [fields.products.name]: initialValues.products,
            [fields.billing.name]: initialValues.billing,
            [fields.waitlist.name]: initialValues.waitlist,
          });
          active && form.validateFields();
        };

        return (
          <>
            <ASectionBtmMargin>
              <div>
                <ATextBold>{coupon.slug}</ATextBold>: {coupon.description}
              </div>
              <div>{getCouponDescription(coupon)}</div>
            </ASectionBtmMargin>

            {/* Expiration */}
            <ExpirationFields>
              <ADateField
                antdForm={form}
                disabled={!activeToggle}
                disableDate={{ past: true }}
                initialValue={initialValues.redeemBy || undefined}
                label={fields.redeemBy.label}
                labelInfo={fields.redeemBy.labelInfo}
                name={fields.redeemBy.name}
                required={fields.redeemBy.required}
              />
              <ASwitchField
                antdForm={form}
                initialValue={couponActive}
                label={fields.active.label}
                labelInfo={fields.active.labelInfo}
                name={fields.active.name}
                onChange={onActiveToggleChange}
              />
            </ExpirationFields>

            {/* Center, product, plan restrictions */}
            <AFormFieldRow>
              <ASelectCenterField
                antdForm={form}
                customWidth={{ percent: 60 }}
                disabled={!activeToggle}
                initialValue={initialValues.centers}
                label={fields.centers.label}
                labelInfo={fields.centers.labelInfo}
                mode="multiple"
                name={fields.centers.name}
                placeholder="Select if restrictions apply"
                required={fields.centers.required}
              />
            </AFormFieldRow>
            <AFormFieldRow>
              <ASelectField
                antdForm={form}
                customWidth={{ percent: 90 }}
                disabled={!activeToggle}
                initialValue={initialValues.products}
                label={fields.products.label}
                labelInfo={fields.products.labelInfo}
                mode="multiple"
                name={fields.products.name}
                placeholder="Select if restrictions apply"
                required={fields.products.required}
                selectOptions={productOptions}
              />
            </AFormFieldRow>
            <AFormFieldRow>
              <ASelectField
                allowClear
                antdForm={form}
                customWidth={{ percent: 50 }}
                disabled={!activeToggle}
                initialValue={initialValues.billing}
                label={fields.billing.label}
                labelInfo={fields.billing.labelInfo}
                name={fields.billing.name}
                placeholder="Select if restrictions apply"
                required={fields.billing.required}
                selectOptions={Object.values(BillingRestriction).map(
                  (billing) => ({
                    label: capitalize(billing).replace(/_/g, ' '),
                    value: billing,
                  })
                )}
              />
            </AFormFieldRow>
            <AFormFieldRow>
              <ACheckboxField
                antdForm={form}
                disabled={!activeToggle}
                initialValue={initialValues.waitlist}
                label={fields.waitlist.label}
                labelInfo={fields.waitlist.labelInfo}
                name={fields.waitlist.name}
                required={fields.waitlist.required}
                show={
                  form.getFieldValue(fields.billing.name) === undefined &&
                  form.getFieldValue(fields.products.name) === undefined
                    ? coupon.products.length > 0 || coupon.plans.length > 0
                    : !!form.getFieldValue(fields.billing.name) ||
                      (!!form.getFieldValue(fields.products.name) &&
                        form.getFieldValue(fields.products.name).length > 0)
                }
              />
            </AFormFieldRow>
          </>
        );
      }}
    </AForm>
  );
};

// Styled components ////////////////////////////////
const ExpirationFields = styled(AFormFieldRow)`
  &&& {
    ${theme.layout.breakpointMixin.phoneOnly} {
      flex-direction: column-reverse;
    }
  }
`;

/** Form field info */
const fields = {
  active: {
    label: 'Active',
    labelInfo:
      'Deactivating will remove any unsaved edits and set the coupon to expire within the hour.',
    name: 'active',
  },
  billing: {
    label: 'Billing period',
    labelInfo:
      'The coupon will only apply to plans billed either monthly or upfront/yearly. Leave blank if the coupon applies to all billing periods.',
    name: 'billing',
    required: false,
  },
  centers: {
    label: 'Centers',
    labelInfo:
      'The coupon will only apply to the centers listed. Leave blank if the coupon applies to all centers.',
    name: 'centers',
    required: false,
  },
  products: {
    label: 'Products',
    labelInfo:
      'The coupon will only apply to the products listed. Leave blank if the coupon applies to all products.',
    name: 'products',
    required: false,
  },
  redeemBy: {
    label: 'Redeem by',
    labelInfo: 'Coupon must be redeemed by the end of this date',
    name: 'redeemBy',
    required: false,
  },
  waitlist: {
    label: 'Applies to waitlist enrollment',
    labelInfo:
      'Whether the coupon can be used when joining a waitlist. The coupon will be applied when the member enrolls off the waitlist.',
    name: 'waitlist',
    required: false,
  },
};

export { CouponUpdate, fields };
