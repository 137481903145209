import { useMutation, useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import React, { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { ACheckbox } from 'app/components/atoms/ACheckbox/ACheckbox';
import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { AFormSaveButtonGroup } from 'app/components/atoms/AFormSaveButtonGroup/AFormSaveButtonGroup';
import { AInput } from 'app/components/atoms/AInput/AInput';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { AnEmploymentTypeCheckbox } from 'app/components/atoms/AnEmploymentTypeCheckbox/AnEmploymentTypeCheckbox';
import { ASelectAcuityInstance } from 'app/components/atoms/ASelectAcuityInstance/ASelectAcuityInstance';
import { ASelectCareManager } from 'app/components/atoms/ASelectCareManager/ASelectCareManager';
import {
  ASelectProviderInsuranceCredentials,
  IASelectProviderInsuranceCredentialsValue,
} from 'app/components/atoms/ASelectInsuranceCredentials/ASelectInsuranceCredentials';
import {
  ASelectProviderCenter,
  IASelectProviderCenterValue,
} from 'app/components/atoms/ASelectProviderCenter/ASelectProviderCenter';
import {
  ASelectProviderProduct,
  IASelectProviderProductValue,
} from 'app/components/atoms/ASelectProviderProduct/ASelectProviderProduct';
import { ASelectProviderRole } from 'app/components/atoms/ASelectProviderRole/ASelectProviderRole';
import { ASelectProviderSuffix } from 'app/components/atoms/ASelectProviderSuffix/ASelectProviderSuffix';
import { ASelectSex } from 'app/components/atoms/ASelectSex/ASelectSex';
import { PROVIDER_BASICS_QUERY } from 'app/components/organisms/ProviderBasics/query';
import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { theme } from 'app/styles/theme';
import { FormFieldOptions } from 'app/types/form';
import { PapiProviderAttrType } from 'app/types/generated/allTypes';
import { App_Query } from 'app/types/generated/App_Query';
import {
  PapiAcuityInstance,
  PapiEmploymentType,
  PapiProfessionalSuffix,
  PapiProviderType,
  PapiSex,
  PapiUpdateProviderInput,
} from 'app/types/generated/globalTypes';
import { PapiRole } from 'app/types/generated/globalTypes';
import {
  ProviderUpdate_Mutation_V2,
  ProviderUpdate_Mutation_V2Variables,
} from 'app/types/generated/ProviderUpdate_Mutation_V2';
import {
  ProviderUpdate_Query_v2,
  ProviderUpdate_Query_v2Variables,
} from 'app/types/generated/ProviderUpdate_Query_v2';
import { displayErrors } from 'app/utils/app';
import { FORM_VALIDATION } from 'constants/form';
import { STATUS_MESSAGE } from 'constants/message';
import {
  DEFAULT_MAX_HOURS,
  DEFAULT_DAYS_PER_WEEK,
  NON_CM_ASSIGNABLE_PROVIDER_TYPES,
} from 'constants/provider';

import {
  PROVIDER_UPDATE_REFETCH,
  APP_PERSON_QUERY,
  PROVIDER_UPDATE_QUERY_V2,
  PROVIDER_UPDATE_MUTATION_V2,
  UPDATE_PROVIDER_ATTR,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  onCancel?: () => void;
  onSave?: () => void;
  providerID: string;
}

/** Form to update a provider's info */
const ProviderUpdate: FC<Props> = ({ onCancel, onSave, providerID }) => {
  const formHook = useForm<FieldValueTypes>();
  const featureFlags = useContext(FeatureFlagContext);
  const showInsuranceCreds = featureFlags.variation(
    'dr-p-provider-insurance-credentials'
  );

  const { data: person, loading: personLoading } = useQuery<App_Query>(
    APP_PERSON_QUERY
  );

  const { data, loading: queryLoading } = useQuery<
    ProviderUpdate_Query_v2,
    ProviderUpdate_Query_v2Variables
  >(PROVIDER_UPDATE_QUERY_V2, {
    variables: { providerID },
  });

  const [
    updateProviderMedicareOptedOut,
    { loading: providerMedicareOptedOutLoading },
  ] = useMutation<
    ProviderUpdate_Mutation_V2,
    ProviderUpdate_Mutation_V2Variables
  >(PROVIDER_UPDATE_MUTATION_V2, {
    refetchQueries: [
      {
        // Refetch since mutation result may not have correct centers on the provider
        query: PROVIDER_UPDATE_REFETCH,
        variables: { providerID },
      },
      {
        query: PROVIDER_BASICS_QUERY,
        variables: { providerID },
      },
      {
        query: PROVIDER_UPDATE_QUERY_V2,
        variables: { providerID },
      },
    ],
  });
  const [updateProviderAttr] = useMutation(UPDATE_PROVIDER_ATTR);

  if (queryLoading || personLoading || !data?.provider || !person?.viewer) {
    return (
      <LoadingContainer>
        <ALoading />
      </LoadingContainer>
    );
  }

  const { provider } = data;
  const handleSave = async (values: FieldValueTypes): Promise<void> => {
    try {
      const { daysPerWeek, isMedicareOptedOut, maxHours, ...input } = values;
      const variables = {
        id: providerID,
        input: {
          ...input,
          centers: input.centers?.map(
            (center: IASelectProviderCenterValue) => ({
              centerID: center.id,
              takingNewPatients: center.takingNewMembers,
            })
          ),
          id: providerID,
          payerCredentials: showInsuranceCreds
            ? input.payerCredentials?.map(
                (payerCred: IASelectProviderInsuranceCredentialsValue) => ({
                  centerID: payerCred.centerID,
                  payerID: payerCred.payerID,
                })
              )
            : undefined,
          products:
            input.products && input.products?.length > 0
              ? input.products?.map(
                  ({ type }: IASelectProviderProductValue) => type
                )
              : [],
        },
      };

      let result;

      result = await updateProviderMedicareOptedOut({
        variables: {
          ...variables,
          value: Boolean(isMedicareOptedOut),
        },
      });
      result = await updateProviderAttr({
        variables: {
          input: [
            {
              attr: PapiProviderAttrType.MAX_PATIENT_FACING_HOURS,
              providerID,
              value: String(maxHours),
            },
            {
              attr: PapiProviderAttrType.WORK_DAYS_PER_WEEK,
              providerID,
              value: String(daysPerWeek),
            },
          ],
        },
      });

      if (result?.data) {
        message.success(STATUS_MESSAGE.providerUpdate.success);
        onSave?.();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err as any, STATUS_MESSAGE.providerUpdate.error.general);
    }
  };

  return (
    <div>
      {/* Name */}
      <AFormFieldRow>
        <AInput
          customWidth={{ percent: 30 }}
          defaultValue={provider.firstName}
          formHook={formHook}
          {...fields.firstName}
        />
        <AInput
          customWidth={{ percent: 20 }}
          defaultValue={provider.middleName || undefined}
          formHook={formHook}
          {...fields.middleName}
        />
        <AInput
          customWidth={{ percent: 30 }}
          defaultValue={provider.lastName}
          formHook={formHook}
          {...fields.lastName}
        />
        <ASelectProviderSuffix
          customWidth={{ percent: 15 }}
          defaultValue={provider.professionalSuffix || undefined}
          formHook={formHook}
          {...fields.professionalSuffix}
        />
      </AFormFieldRow>

      {/* Role, biological sex & email */}
      <AFormFieldRow>
        <ASelectProviderRole
          customWidth={{ percent: 30 }}
          defaultValue={provider.type}
          formHook={formHook}
          {...fields.type}
        />
        <ASelectSex
          customWidth={{ percent: 20 }}
          defaultValue={provider.biologicalSex}
          formHook={formHook}
          {...fields.biologicalSex}
        />
        <AInput
          customWidth={{ percent: 45 }}
          defaultValue={provider.email}
          formHook={formHook}
          {...fields.email}
        />
      </AFormFieldRow>

      {/* Care Manager */}
      {NON_CM_ASSIGNABLE_PROVIDER_TYPES.includes(
        data?.provider?.type
      ) ? null : (
        <AFormFieldRow>
          <ASelectCareManager
            customWidth={{ percent: 80 }}
            data-testid="careManagerID"
            defaultValue={provider?.assignedCareManager?.id ?? undefined}
            formHook={formHook}
            {...fields.careManagerID}
          />
        </AFormFieldRow>
      )}
      {/* Care Manager */}

      {/* Centers */}
      <AFormFieldRow>
        <ASelectProviderCenter
          customWidth={{ percent: 80 }}
          defaultValue={provider.centers.map((providerCenter) => ({
            id: providerCenter.center.id,
            takingNewMembers: providerCenter.takingNewPatients,
          }))}
          formHook={formHook}
          required
          {...fields.centers}
        />
      </AFormFieldRow>

      {/* Insurance Credentials */}
      {showInsuranceCreds && (
        <AFormFieldRow>
          <ASelectProviderInsuranceCredentials
            customWidth={{ percent: 80 }}
            data-testid="payerCredentials"
            defaultValue={provider.payerCredentials.map((cred) => ({
              centerID: cred.centerID,
              payerID: cred.payerID,
            }))}
            formHook={formHook}
            {...fields.payerCredentials}
          />
        </AFormFieldRow>
      )}

      {/* Products */}
      <AFormFieldRow>
        <ASelectProviderProduct
          customWidth={{ percent: 80 }}
          defaultValue={provider.products.map((providerProduct) => ({
            displayName: providerProduct.displayName,
            type: providerProduct.type,
          }))}
          formHook={formHook}
          {...fields.products}
        />
      </AFormFieldRow>

      {/* 3rd party */}
      <AFormFieldRow>
        <ASelectAcuityInstance
          customWidth={{ percent: 20 }}
          defaultValue={provider.acuityInstance || undefined}
          formHook={formHook}
          {...fields.acuityInstance}
        />
        <AInput
          customWidth={{ percent: 20 }}
          defaultValue={provider.acuityID || undefined}
          formHook={formHook}
          {...fields.acuityID}
        />
        <AInput
          customWidth={{ percent: 20 }}
          defaultValue={provider.helpScoutID || undefined}
          formHook={formHook}
          {...fields.helpScoutID}
        />
      </AFormFieldRow>
      <AFormFieldRow>
        <AInput
          customWidth={{ percent: 50 }}
          defaultValue={provider.sanityID || undefined}
          formHook={formHook}
          {...fields.sanityID}
        />
        <AInput
          customWidth={{ percent: 30 }}
          defaultValue={provider.mdhqId || undefined}
          disabled={person.viewer.role !== PapiRole.PARSLEY_SUPERADMIN}
          formHook={formHook}
          {...fields.mdhqId}
        />
      </AFormFieldRow>
      <>
        <AFormFieldRow>
          <ACheckbox
            defaultChecked={provider.isMedicareOptedOut}
            formHook={formHook}
            {...fields.isMedicareOptedOut}
          />
        </AFormFieldRow>
        <AFormFieldRow>
          <AnEmploymentTypeCheckbox
            formHook={formHook}
            {...fields.employmentType}
            defaultChecked={
              provider.employmentType === PapiEmploymentType.CONTRACTOR
            }
            initialValue={provider.employmentType}
          />
        </AFormFieldRow>
        <AFormFieldRow>
          <AInput
            customWidth={{ percent: 20 }}
            defaultValue={provider.daysPerWeek || DEFAULT_DAYS_PER_WEEK}
            formHook={formHook}
            {...fields.daysPerWeek}
          />
          <AInput
            customWidth={{ percent: 20 }}
            defaultValue={provider.maxHours || DEFAULT_MAX_HOURS}
            formHook={formHook}
            {...fields.maxHours}
          />
        </AFormFieldRow>
      </>
      <AFormSaveButtonGroup
        onCancel={onCancel}
        onSave={formHook.handleSubmit(handleSave)}
        saving={providerMedicareOptedOutLoading}
      />
    </div>
  );
};

// Styled components ////////////////////////////////
const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 150px;
  justify-content: flex-start;
  margin-left: 100px;
  ${theme.layout.breakpointMixin.phoneOnly} {
    justify-content: center;
    margin-left: 0;
  }
`;

/** Form field info */
interface FieldValueTypes {
  acuityID?: string | null;
  acuityInstance: PapiAcuityInstance;
  biologicalSex: PapiSex;
  careManagerID: string | null;
  centers: IASelectProviderCenterValue[];
  daysPerWeek?: string | null;
  email: string;
  employmentType: PapiEmploymentType | null;
  firstName: string;
  helpScoutID?: string | null;
  isMedicareOptedOut?: boolean | null;
  lastName: string;
  maxHours?: string | null;
  mdhqId: string;
  middleName?: string | null;
  payerCredentials?: IASelectProviderInsuranceCredentialsValue[];
  products?: IASelectProviderProductValue[];
  professionalSuffix?: PapiProfessionalSuffix | null;
  sanityID?: string | null;
  type: PapiProviderType;
}

interface PapiUpdateProviderInputTypes extends PapiUpdateProviderInput {
  daysPerWeek?: string | null;
  isMedicareOptedOut?: boolean | null;
  maxHours?: string | null;
}

const fields: Pick<
  FormFieldOptions<PapiUpdateProviderInputTypes>,
  keyof FieldValueTypes
> = {
  acuityID: {
    label: 'Acuity ID',
    name: 'acuityID',
  },
  acuityInstance: {
    label: 'Acuity center',
    name: 'acuityInstance',
    rules: FORM_VALIDATION.required,
  },
  biologicalSex: {
    label: 'Sex',
    name: 'biologicalSex',
    rules: FORM_VALIDATION.required,
  },
  careManagerID: {
    label: 'Care Manager',
    name: 'careManagerID',
  },
  centers: {
    label: 'Centers',
    name: 'centers',
    // This field is required by setting the required prop in JSX
  },
  daysPerWeek: {
    label: 'Working Days per Week',
    name: 'daysPerWeek',
    rules: FORM_VALIDATION.numeric,
  },
  email: {
    label: 'Email',
    name: 'email',
    rules: { ...FORM_VALIDATION.email, ...FORM_VALIDATION.required },
  },
  employmentType: {
    label: '1099 Contractor',
    name: 'employmentType',
  },
  firstName: {
    label: 'First name',
    name: 'firstName',
    rules: FORM_VALIDATION.required,
  },
  helpScoutID: {
    label: 'HelpScout ID',
    name: 'helpScoutID',
  },
  isMedicareOptedOut: {
    label: 'Is Medicare Opted Out',
    labelInfo:
      'Please check the box if the Clinician is already opted out for Medicare and is allowed to see Medicare patients. Otherwise, leave it unchecked.',
    name: 'isMedicareOptedOut',
  },
  lastName: {
    label: 'Last name',
    name: 'lastName',
    rules: FORM_VALIDATION.required,
  },
  maxHours: {
    label: 'Max Working Hours per Week',
    name: 'maxHours',
    rules: FORM_VALIDATION.numeric,
  },
  mdhqId: {
    label: 'MDHQ Provider ID',
    name: 'mdhqId',
  },
  middleName: {
    label: 'Middle name',
    name: 'middleName',
  },
  payerCredentials: {
    label: 'Insurance Credentials',
    name: 'payerCredentials',
  },
  products: {
    label: 'Products',
    name: 'products',
  },
  professionalSuffix: {
    label: 'Suffix',
    name: 'professionalSuffix',
  },
  sanityID: {
    label: 'Sanity ID',
    name: 'sanityID',
  },
  type: {
    label: 'Role',
    name: 'type',
    rules: FORM_VALIDATION.required,
  },
};

export { fields, ProviderUpdate };
