import { useAuth } from '@parsleyhealth/front-end-utils';
import { Icon, Layout, Menu } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AIconInline } from 'app/components/atoms/AIconInline/AIconInline';
import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { ViewerContext } from 'app/contexts/ViewerContext';
import { theme } from 'app/styles/theme';
import { trackEvent } from 'app/utils/analytics';
import { isStaging, shouldShowRoute } from 'app/utils/app';
import { compareLabel } from 'app/utils/sort';
import { ROUTES } from 'constants/app';

// Types & constants ////////////////////////////////
interface Props {
  collapsed?: boolean;
  expandable?: boolean;
  toggleCollapsed?: () => void;
}

/** App navbar including mobile ui */
const AppNavbar: FC<Props> = ({
  collapsed = true,
  expandable = true,
  toggleCollapsed,
}) => {
  const viewer = useContext(ViewerContext);
  const featureFlags = useContext(FeatureFlagContext);
  const { logout } = useAuth();

  const [showMobileNavbar, setShowMobileNavbar] = useState(false);
  const toggleMobileNavbar = (): void => setShowMobileNavbar((prev) => !prev);

  const onLogout = (): void => {
    trackEvent('Logged Out');
    logout();
  };

  const validNavItems = ROUTES.filter(
    (route) => shouldShowRoute(route, viewer, featureFlags) && !!route.label
  );

  return (
    <>
      <LayoutSiderStyled collapsed={collapsed} collapsible trigger={null}>
        <Title>
          {collapsed ? 'Dr.' : 'Dr. Parsley'}
          {isStaging() && <TestEnvIcon type="bug" />}
        </Title>

        <MenuStyled mode="inline" selectable={false}>
          {validNavItems.map(({ icon, label, path }) => (
            <MenuItemStyled key={path} title={collapsed ? label : undefined}>
              <LinkStyled to={path}>
                <NavIcon type={icon} />
                <span>{label}</span>
              </LinkStyled>
            </MenuItemStyled>
          ))}
        </MenuStyled>

        <BottomMenu mode="inline" selectable={false}>
          <MenuItemStyled
            onClick={onLogout}
            title={collapsed ? 'Log out' : undefined}
          >
            <NavIcon type="logout" />
            <span>Log out</span>
          </MenuItemStyled>
          {expandable && (
            <MenuItemStyled
              onClick={toggleCollapsed}
              title={collapsed ? 'Expand menu' : undefined}
            >
              <NavIcon
                data-testid="AppNavbar_toggleCollapsed"
                type={collapsed ? 'menu-unfold' : 'menu-fold'}
              />
              <span>{collapsed ? 'Expand menu' : 'Collapse'}</span>
            </MenuItemStyled>
          )}
        </BottomMenu>
      </LayoutSiderStyled>

      <MobileNav>
        <MobileNavToggle onClick={toggleMobileNavbar} type="menu" />
        {showMobileNavbar && (
          <MobileMenu mode="inline" selectable={false}>
            {validNavItems.sort(compareLabel).map(({ icon, label, path }) => (
              <MenuItemStyled key={path} onClick={() => toggleMobileNavbar()}>
                <LinkStyled className="dark" to={path}>
                  <Icon type={icon} />
                  <span>{label}</span>
                </LinkStyled>
              </MenuItemStyled>
            ))}
            <MenuItemStyled onClick={onLogout}>
              <Icon type="logout" />
              <span>Log out</span>
            </MenuItemStyled>
            <MenuItemStyled onClick={toggleMobileNavbar}>
              <Icon type="menu-fold" />
              <span>Close menu</span>
            </MenuItemStyled>
          </MobileMenu>
        )}
      </MobileNav>
    </>
  );
};

// Styled components ////////////////////////////////
const LayoutSiderStyled = styled(Layout.Sider)`
  &&& {
    background: ${theme.color.navbar};
    border-right: 0;
    display: initial;
    height: 100vh;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    z-index: ${theme.layer.zIndex.navbar};
    ${theme.layout.breakpointMixin.phoneOnly} {
      display: none;
    }
  }
`;

const LinkStyled = styled(Link)`
  &&& {
    align-items: center;
    color: ${theme.color.text};
    display: flex;
  }
`;

const MenuStyled = styled(Menu)`
  &&& {
    background: ${theme.color.navbar};
    border-right: 0;
    * {
      color: white;
    }
  }
`;
const BottomMenu = styled(MenuStyled)`
  &&& {
    bottom: ${theme.space.s};
    position: absolute;
  }
`;

const MenuItemStyled = styled(Menu.Item)`
  &&& {
    align-items: center;
    display: flex;
  }
`;

const MobileMenu = styled(Menu)`
  &&& {
    border-right: 0;
    box-shadow: ${theme.layer.boxShadow.bottom};
    left: 0;
    position: fixed;
    top: ${theme.layout.headerSize};
    width: 150px;
  }
`;

const MobileNav = styled.div`
  display: none;
  padding: ${theme.space.sm};
  position: absolute;
  z-index: ${theme.layer.zIndex.navbar};
  ${theme.layout.breakpointMixin.phoneOnly} {
    display: initial;
  }
`;

const MobileNavToggle = styled(Icon)`
  &&& {
    color: white;
    font-size: ${theme.font.size.xxxl};
  }
`;

const NavIcon = styled(Icon)`
  &&& {
    transition-duration: ${theme.animation.transitionDuration};
    transition-property: all;
    transition-timing-function: ${theme.animation.transitionTiming};
    ${MenuItemStyled}:hover & {
      transform: scale(1.2);
    }
    ${MenuItemStyled}:not (:hover) & {
      transform: scale(1);
    }
  }
`;

const TestEnvIcon = styled(AIconInline)`
  &&& {
    bottom: ${theme.space.xxs};
    margin-left: ${theme.space.xxs};
  }
`;

const Title = styled.h3`
  color: white;
  height: ${theme.layout.headerSize};
  line-height: ${theme.layout.headerSize};
  margin-bottom: 0;
  overflow: hidden;
  text-align: center;
  width: 100%;
`;

export { AppNavbar };
