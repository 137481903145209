import { Divider } from 'antd';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

/**
 * Use with AFlexRowToColumn for a divider that disappears when the row's elements
 * break out into their own rows (flex-direction goes from row to column)
 */
export const AFlexDivider = styled(Divider)`
  &&& {
    ${theme.layout.breakpointMixin.phoneOnly} {
      display: none;
    }
  }
`;
