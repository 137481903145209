import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { ADrawer } from 'app/components/atoms/ADrawer/ADrawer';
import { ADrawerContainer } from 'app/components/atoms/ADrawerContainer/ADrawerContainer';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { APadding } from 'app/components/atoms/APadding/APadding';
import { ASectionEditable } from 'app/components/atoms/ASectionEditable/ASectionEditable';
import { VisitsList } from 'app/components/deprecated/VisitsList/VisitsList';
import { MemberBasics } from 'app/components/organisms/MemberBasics/MemberBasics';
import { MemberHeader } from 'app/components/organisms/MemberHeader/MemberHeader';
import { MemberMembership } from 'app/components/organisms/MemberMembership/MemberMembership';
import { MemberPayment } from 'app/components/organisms/MemberPayment/MemberPayment';
import { theme } from 'app/styles/theme';

const MEMBER_PREVIEW_SIZE = 650; // px

const VisitsPage: FC = () => {
  const [selectedMemberID, setSelectedMemberID] = useState<
    string | undefined
  >();
  const [memberLoading, setMemberLoading] = useState(false);

  // Opens member preview on desktop. Otherwise routes to member profile
  const isDesktop = window.innerWidth > theme.layout.breakpoint.tabletLandscape;

  return (
    <>
      <ADrawerContainerStyled
        drawerSizePx={MEMBER_PREVIEW_SIZE}
        showDrawer={
          selectedMemberID === undefined ? undefined : !!selectedMemberID
        }
      >
        <APadding type="page">
          <VisitsList
            onVisitClick={isDesktop ? setSelectedMemberID : undefined}
            routeToProfile={!isDesktop}
          />
        </APadding>
      </ADrawerContainerStyled>

      <ADrawer
        ignoreLayoutContext
        onClose={() => setSelectedMemberID(undefined)}
        visible={!!selectedMemberID}
        width={MEMBER_PREVIEW_SIZE}
      >
        {selectedMemberID && (
          <ALoading size="large" spinning={memberLoading}>
            <HeaderContainer>
              <MemberHeader
                excludedLinks={['stripe', 'hubspot']}
                personID={selectedMemberID}
                setLoading={setMemberLoading}
              />
            </HeaderContainer>
            <ASectionEditable title="Basics">
              <MemberBasics personID={selectedMemberID} />
            </ASectionEditable>
            <ASectionEditable title="Membership">
              <MemberMembership editable={false} personID={selectedMemberID} />
            </ASectionEditable>
            <ASectionEditable title="Payment & Insurance">
              <MemberPayment editable={false} personID={selectedMemberID} />
            </ASectionEditable>
          </ALoading>
        )}
      </ADrawer>
    </>
  );
};

// Styled components ////////////////////////////////
const ADrawerContainerStyled = styled(ADrawerContainer)`
  ${theme.layout.breakpointMixin.tabletPortraitDown} {
    width: 100%;
  }
`;

const HeaderContainer = styled.div`
  margin: -${theme.space.s} 0;
`;

export { VisitsPage };
