import gql from 'graphql-tag';

import { ASelectCouponField_Fragment_coupon } from 'app/components/deprecated/ASelectCouponField/fragment';

export const PlanPurchaseUpdate_Fragment_planPurchase = gql`
  fragment PlanPurchaseUpdate_Fragment_planPurchase on PapiPlanPurchase {
    id
    center {
      id
    }
    coupon {
      ...ASelectCouponField_Fragment_coupon
    }
    endDateIncludingExtensions
    endTime
    plan {
      id
      annualCostInCents
      billingPeriod
      displayName
      paymentRateInCents
      product {
        displayName
        type
      }
    }
    startTime
  }
  ${ASelectCouponField_Fragment_coupon}
`;
