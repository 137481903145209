import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
import debounce from 'lodash.debounce';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

let blurTimeout;

// Types & constants ////////////////////////////////
export interface SearchSelectProps extends SelectProps {
  onSearchPhraseProvide: (value: string) => void;
  renderNoData?: React.ReactNode;
  renderOptions: (props: any) => React.ReactNode;
  searchResults?: any[];
  timeout?: number;
}

/** Asynchronous search for items in text input */
const SearchSelect: FC<SearchSelectProps> = ({
  onSearchPhraseProvide,
  searchResults = [],
  renderOptions,
  renderNoData,
  timeout = 400,
  ...props
}) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [dropdownOpenNoData, setDropodownOpenNoData] = useState<boolean>(false);
  const selectRef = useRef(null);

  const searchDebounce = debounce((debounceVal) => {
    if (!debounceVal.length) {
      setData([]);
      setLoading(false);

      return;
    }

    setLoading(true);

    if (debounceVal.length >= 2) onSearchPhraseProvide(debounceVal);
  }, timeout);

  useEffect(() => {
    return () => clearTimeout(blurTimeout);
  }, []);

  useEffect(() => {
    setData(searchResults);
    setLoading(false);
  }, [searchResults]);

  const handleOnFocus = (): void => {
    const selectNode: any = selectRef.current;
    if (!renderNoData) {
      return;
    }

    if (selectNode.focus) {
      selectNode.focus();
    }

    setDropodownOpenNoData(true);
  };

  const handleOnBlur = (): void => {
    setData([]);
    setLoading(false);
    blurTimeout = setTimeout(() => setDropodownOpenNoData(false), 100);
  };

  let additionalProps: any = {};

  if (renderNoData && !data.length && !isLoading)
    additionalProps = {
      ...additionalProps,
      dropdownRender: () => renderNoData,
      open: dropdownOpenNoData,
    };

  return (
    <div>
      <StyledSelect
        filterOption={false}
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onSearch={searchDebounce}
        ref={selectRef}
        showArrow={false}
        showSearch
        {...additionalProps}
        {...props}
      >
        {!isLoading && data.map((value: any) => renderOptions(value))}
      </StyledSelect>
    </div>
  );
};

// Styled components ////////////////////////////////
const StyledSelect = styled(Select)`
  width: 100%;
`;

export { SearchSelect };
