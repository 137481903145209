import gql from 'graphql-tag';

/** Get all plans to create coupon's plan restrictions field */
export const CouponForm_Fragment_plansQuery = gql`
  fragment CouponForm_Fragment_plansQuery on Query {
    plans: listAllPlans {
      edges {
        node {
          id
          billingPeriod
          defaultFollowOnPlan {
            id
            billingPeriod
            numberOfBillingCycles
            product {
              type
            }
          }
          numberOfBillingCycles
          paymentRateInCents
          product {
            displayName
            type
          }
        }
      }
    }
  }
`;
