import gql from 'graphql-tag';

import { ProviderPage_Fragment_provider } from './fragment';

export const PROVIDER_PAGE_QUERY = gql`
  query ProviderPage_Query($providerID: ID!) {
    provider: getProvider(id: $providerID) {
      ...ProviderPage_Fragment_provider
    }
  }
  ${ProviderPage_Fragment_provider}
`;
