import gql from 'graphql-tag';

import { MemberHeader_Fragment_member } from 'app/components/organisms/MemberHeader/fragment';

export const MEMBER_THIRD_PARTY_RESYNC_MUTATION = gql`
  mutation MemberThirdPartyResync_Mutation($personID: ID!) {
    rectifyThirdParties(personID: $personID) {
      member: person {
        id
        ...MemberHeader_Fragment_member
      }
      firebaseResponse
      helpScoutResponse
      hubspotResponse
      mdhqResponse
      storeResponse
    }
  }
  ${MemberHeader_Fragment_member}
`;
