import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import { Moment } from 'moment';

import {
  CouponForm_Fragment_plansQuery_plans_edges_node,
  CouponForm_Fragment_plansQuery_plans_edges_node_product,
} from 'app/types/generated/CouponForm_Fragment_plansQuery';
import {
  PapiBillingPeriod,
  PapiCouponApplication,
  PapiProductType,
} from 'app/types/generated/globalTypes';
import { PlanPurchaseCreate_Query_coupons_edges_node } from 'app/types/generated/PlanPurchaseCreate_Query';
import { compareLabel } from 'app/utils/sort';
import { COUPON_TIMEZONE } from 'constants/datetime';

// Types & constants ////////////////////////////////
type Plan = CouponForm_Fragment_plansQuery_plans_edges_node;
type Product = CouponForm_Fragment_plansQuery_plans_edges_node_product;

export enum BillingRestriction {
  Monthly = 'monthly',
  UpfrontOrYearly = 'upfront_or_yearly',
}

// Util functions ////////////////////////////////
export const createPlanRestrictionsList = (
  input: { billing?: BillingRestriction; waitlist?: boolean },
  plans: CouponForm_Fragment_plansQuery_plans_edges_node[],
  productRestrictions?: PapiProductType[]
): string[] => {
  if (!input.billing) {
    return [];
  }
  return plans
    .filter(
      (plan) =>
        // Filter out plans with invalid products if restrictions apply
        (productRestrictions && productRestrictions.length > 0
          ? productRestrictions.includes(plan.product.type) ||
            (plan.product.type.includes('WAITLIST') &&
              plan.defaultFollowOnPlan &&
              productRestrictions.includes(
                plan.defaultFollowOnPlan.product.type
              ))
          : true) &&
        // Filter out plans with invalid billing
        (input.billing === BillingRestriction.Monthly
          ? isBilledMonthly(plan)
          : true) &&
        (input.billing === BillingRestriction.UpfrontOrYearly
          ? isBilledUpfrontOrYearly(plan)
          : true) &&
        // Filter out waitlist plans if not applicable
        (input.waitlist ? true : !plan.product.type.includes('WAITLIST')) &&
        // Filter out complimentary non-waitlist plans (extensions, etc)
        (plan.paymentRateInCents > 0 || plan.product.type.includes('WAITLIST'))
    )
    .map((plan) => plan.id);
};

export const createProductRestrictionsList = (
  input: {
    products?: PapiProductType[];
    waitlist?: boolean;
  },
  plans: Array<{
    defaultFollowOnPlan: { product: { type: PapiProductType } } | null;
    product: { type: PapiProductType };
  }>
): PapiProductType[] =>
  uniq([
    ...(input.products || []),
    ...(input.waitlist && input.products && input.products.length > 0
      ? plans
          .filter(
            (plan) =>
              plan.product.type.includes('WAITLIST') &&
              plan.defaultFollowOnPlan &&
              input.products!.includes(plan.defaultFollowOnPlan.product.type)
          )
          .map((plan) => plan.product.type)
      : []),
  ]);

/**
 * Convert redeem by date to furthest Parsley center timezone (currently LA).
 * Redeem by date will be the end of the day provided.
 * If no date is provided, it will return the end of the hour
 */
export const createRedeemByTime = (redeemBy?: Moment): string => {
  if (redeemBy) {
    const dt = DateTime.fromJSDate(redeemBy.toDate());
    return DateTime.fromObject({
      day: dt.day,
      month: dt.month,
      year: dt.year,
      zone: COUPON_TIMEZONE,
    })
      .endOf('day')
      .toISO();
  }
  return DateTime.local().endOf('hour').toISO();
};

export const getCouponDescription = (
  coupon: Pick<
    PlanPurchaseCreate_Query_coupons_edges_node,
    'discount' | 'duration'
  >
): string => {
  const { discount, duration } = coupon;
  return (
    ('percentOff' in discount
      ? `${discount.percentOff}% off `
      : `$${discount.amountOffCents / 100} off `) +
    ('months' in duration
      ? (duration.months === 1 && 'once') || `for ${duration.months} months`
      : duration.apply === PapiCouponApplication.FOREVER
      ? 'for plan duration'
      : duration.apply.toLowerCase())
  );
};

export const getProductOptionsFromPlans = (
  plans: Array<{
    product: Pick<Product, 'displayName' | 'type'>;
  }>
): Array<{ label: string; value: PapiProductType }> =>
  uniqBy(
    plans.map((plan) => plan.product),
    'type'
  )
    .filter(
      (product) =>
        product.type !== PapiProductType.EXTENSION &&
        !product.type.includes('WAITLIST')
    )
    .map((product) => ({
      label: product.displayName,
      value: product.type,
    }))
    .sort(compareLabel);

export const isBilledMonthly = (
  plan: Pick<Plan, 'billingPeriod'> & {
    defaultFollowOnPlan: Pick<Plan, 'billingPeriod'> | null;
    product: Pick<Product, 'type'>;
  }
): boolean =>
  plan.billingPeriod === PapiBillingPeriod.MONTH ||
  (plan.product.type.includes('WAITLIST') &&
    !!plan.defaultFollowOnPlan &&
    plan.defaultFollowOnPlan.billingPeriod === PapiBillingPeriod.MONTH);

export const isBilledUpfrontOrYearly = (
  plan: Pick<Plan, 'billingPeriod' | 'numberOfBillingCycles'> & {
    defaultFollowOnPlan: Pick<
      Plan,
      'billingPeriod' | 'numberOfBillingCycles'
    > | null;
    product: Pick<Product, 'type'>;
  }
): boolean =>
  plan.numberOfBillingCycles === 1 ||
  plan.billingPeriod === PapiBillingPeriod.YEAR ||
  (plan.product.type.includes('WAITLIST') &&
    !!plan.defaultFollowOnPlan &&
    plan.defaultFollowOnPlan.numberOfBillingCycles === 1) ||
  (plan.product.type.includes('WAITLIST') &&
    !!plan.defaultFollowOnPlan &&
    plan.defaultFollowOnPlan.billingPeriod === PapiBillingPeriod.YEAR);
