import gql from 'graphql-tag';

import { PlanPurchaseEndOrCancel_Fragment_planPurchase } from 'app/components/organisms/PlanPurchaseEndOrCancel/fragment';
import { PlanPurchaseEnrollFromWaitlist_Fragment_planPurchase } from 'app/components/organisms/PlanPurchaseEnrollFromWaitlist/fragment';
import { PlanPurchaseExtend_Fragment_planPurchase } from 'app/components/organisms/PlanPurchaseExtend/fragment';
import { PlanPurchasePause_Fragment_planPurchase } from 'app/components/organisms/PlanPurchasePause/fragment';
import { PlanPurchaseUpdate_Fragment_planPurchase } from 'app/components/organisms/PlanPurchaseUpdate/fragment';

export const MemberPlanCard_Fragment_planPurchase = gql`
  fragment MemberPlanCard_Fragment_planPurchase on PapiPlanPurchase {
    id
    center {
      id
      abbreviatedName
      isVirtual
      timezone
    }
    coupon {
      discount {
        ... on PapiFlatDiscount {
          amountOffCents
        }
        ... on PapiPercentageDiscount {
          percentOff
        }
      }
      duration {
        ... on PapiCouponApplies {
          apply
        }
        ... on PapiCouponRepeats {
          months
        }
      }
      slug
    }
    endDateIncludingExtensions
    endTime
    legacyHintMembershipID
    nextBillDate
    nextRenewalDate
    latestPause {
      endDate
      startDate
    }
    paymentStatus
    plan {
      id
      billingPeriod
      consumerType
      displayName
      numberOfBillingCycles
      paymentRateInCents
      insuranceType
      product {
        displayName
        type
      }
    }
    renewalPeriods {
      endDate
      startDate
    }
    startTime
    stripeSubscriptionStatus
    ...PlanPurchaseEndOrCancel_Fragment_planPurchase
    ...PlanPurchaseEnrollFromWaitlist_Fragment_planPurchase
    ...PlanPurchaseExtend_Fragment_planPurchase
    ...PlanPurchasePause_Fragment_planPurchase
    ...PlanPurchaseUpdate_Fragment_planPurchase
  }
  ${PlanPurchaseEndOrCancel_Fragment_planPurchase}
  ${PlanPurchaseEnrollFromWaitlist_Fragment_planPurchase}
  ${PlanPurchaseExtend_Fragment_planPurchase}
  ${PlanPurchasePause_Fragment_planPurchase}
  ${PlanPurchaseUpdate_Fragment_planPurchase}
`;
