import { useQuery } from '@apollo/react-hooks';
import { Skeleton } from 'antd';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AFlexDivider } from 'app/components/atoms/AFlexDivider/AFlexDivider';
import { AFlexRowToColumn } from 'app/components/atoms/AFlexRowToColumn/AFlexRowToColumn';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { theme } from 'app/styles/theme';
import {
  MemberBasics_Query,
  MemberBasics_QueryVariables,
} from 'app/types/generated/MemberBasics_Query';
import { getMemberAge } from 'app/utils/member';

import { MEMBER_BASICS_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  personID: string;
}

/** Show basic member info, e.g. name, demographic data, contact info */
const MemberBasics: FC<Props> = ({ personID }) => {
  const { data, loading } = useQuery<
    MemberBasics_Query,
    MemberBasics_QueryVariables
  >(MEMBER_BASICS_QUERY, { variables: { personID } });

  if (loading || !data || !data.member) {
    return (
      <SkeletonStyled
        active
        paragraph={{
          rows: 4,
          width: ['30%', '60%', '60%', '30%'],
        }}
        title={false}
      />
    );
  }

  const {
    member: {
      addressCity,
      addressLine1,
      addressLine2,
      addressPostalCode,
      addressState,
      biologicalSex,
      center,
      dateOfBirth,
      email,
      enrolledBy,
      firstName,
      genderIdentity,
      lastName,
      middleName,
      phoneNumber,
    },
  } = data;
  const parsedPhone = phoneNumber && parsePhoneNumberFromString(phoneNumber);

  return (
    <>
      <div data-private>
        {firstName} {middleName} {lastName}
      </div>
      <AFlexRowToColumn>
        <div>
          {biologicalSex && capitalize(biologicalSex)}
          {genderIdentity && `, ${capitalize(genderIdentity)}`}
        </div>
        {dateOfBirth && (
          <div>
            <AFlexDivider type="vertical" />
            {DateTime.fromISO(dateOfBirth).toLocaleString(
              DateTime.DATE_SHORT
            )}{' '}
            ({getMemberAge(dateOfBirth)} yrs)
          </div>
        )}
      </AFlexRowToColumn>
      {addressLine1 ? (
        <AFlexRowToColumn data-private>
          <Address>
            {addressLine1}
            {addressLine2 ? ` ${addressLine2}, ` : ', '}
          </Address>
          <div>
            {addressCity && `${addressCity}, `}
            {addressState} {addressPostalCode}
          </div>
        </AFlexRowToColumn>
      ) : addressPostalCode ? (
        <div data-private>Zipcode {addressPostalCode}</div>
      ) : (
        <div>
          <ATextLight>No address</ATextLight>
        </div>
      )}
      <AFlexRowToColumn data-private>
        <div>
          {parsedPhone ? (
            parsedPhone.countryCallingCode === '1' ? (
              parsedPhone.formatNational()
            ) : (
              parsedPhone.formatInternational()
            )
          ) : (
            <ATextLight>No phone</ATextLight>
          )}
        </div>
        <AFlexDivider type="vertical" />
        <div data-private>{email}</div>
      </AFlexRowToColumn>
      <div>
        {center ? (
          center.name
        ) : (
          <ATextLight>No Parsley Center designated</ATextLight>
        )}
      </div>
      {enrolledBy && (
        <AFlexRowToColumn data-private>
          <div data-private>{`Enrolled by: ${enrolledBy}`}</div>
        </AFlexRowToColumn>
      )}
    </>
  );
};

// Styled components ////////////////////////////////
const Address = styled.div`
  margin-right: ${theme.space.xs};
`;

const SkeletonStyled = styled(Skeleton)`
  &&& {
    margin: ${theme.space.s} 0 ${theme.space.l} 0;
  }
`;

export { MemberBasics };
