import gql from 'graphql-tag';

export const ProviderHeader_Fragment_provider = gql`
  fragment ProviderHeader_Fragment_provider on PapiProvider {
    id
    displayName
    currentPanelSize
    targetPanelSize
  }
`;
