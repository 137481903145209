import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Button, Checkbox, Input, message } from 'antd';
import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import styled from 'styled-components/macro';

import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { ASelectAppointmentTypes } from 'app/components/atoms/ASelectAppointmentTypes/ASelectAppointmentTypes';
import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { theme } from 'app/styles/theme';
import { CreateAppointment_Mutation } from 'app/types/generated/CreateAppointment_Mutation';
import {
  PapiAppointmentGroup,
  PapiProviderType,
  PapiTransferOfCareTypeInput,
} from 'app/types/generated/globalTypes';
import { MemberPageVisits_Query_member } from 'app/types/generated/MemberPageVisits_Query';
import { TransferCare_Mutation } from 'app/types/generated/TransferCare_Mutation';
import {
  VisitCardListAppointmentTypesQuery,
  VisitCardListAppointmentTypesQueryVariables,
  VisitCardListAppointmentTypesQuery_listAppointmentTypes,
} from 'app/types/generated/VisitCardListAppointmentTypesQuery';
import { STATUS_MESSAGE } from 'constants/message';

import { getTOCProviderId } from './CreateVisitCardUtils';
import { ProvidersByTypeDropdown } from './ProvidersByTypeDropdown';
import {
  CREATE_APPOINTMENT_MUTATION,
  VISIT_CARD_LIST_APPOINTMENT_TYPES_QUERY,
  TRANSFER_CARE_MUTATION,
} from './query';

interface CreateVisitCardProps {
  member: MemberPageVisits_Query_member;
  onClose: () => void;
  reloadVisits: () => void;
}

const { TextArea } = Input;

const TOC_PLANS = ['COACHING_TRANSFER_OF_CARE', 'MEDICAL_TRANSFER_OF_CARE'];

export const visitTypeSelectTestId = 'visit-type-select';
export const noProviderCheckboxTestId = 'no-provider-checkbox';
export const complimentaryCheckboxTestId = 'complimentary-checkbox';

export const CreateVisitCard: React.FC<CreateVisitCardProps> = ({
  member,
  onClose,
  reloadVisits,
}) => {
  const { currentPurchase, id: personID } = member;
  const productType = currentPurchase?.productType || '';
  const planPurchaseID = currentPurchase?.id;
  const centerID = currentPurchase?.center?.id;
  const [notes, setNotes] = useState<string>('');
  const featureFlags = useContext(FeatureFlagContext);
  const showNotes = featureFlags.variation('dr-p-edit-appointment-notes');
  const emr709RemoveLabVisit = featureFlags.variation(
    'emr-709-remove-lab-visit'
  );
  const [isComplimentary, setIsComplimentary] = useState<boolean>(false);
  const [noProvider, setNoProvider] = useState<boolean>(false);
  const [providerType, setProviderType] = useState<
    PapiProviderType | undefined
  >(undefined);
  const [providerID, setProviderID] = useState<string | undefined>(undefined);
  const [transferType, setTransferType] = useState<
    PapiTransferOfCareTypeInput | undefined
  >();

  const [getAppointmentTypes, { data, loading }] = useLazyQuery<
    VisitCardListAppointmentTypesQuery,
    VisitCardListAppointmentTypesQueryVariables
  >(VISIT_CARD_LIST_APPOINTMENT_TYPES_QUERY);

  useEffect(() => {
    if (!data && productType) {
      getAppointmentTypes({
        variables: { productType },
      });
    }
  }, [data, getAppointmentTypes, productType]);

  const [
    createAppointment,
    { error: createAppointmentError, loading: createAppointmentLoading },
  ] = useMutation<CreateAppointment_Mutation>(CREATE_APPOINTMENT_MUTATION);

  const [
    transferCare,
    { error: transferCareError, loading: transferCareLoading },
  ] = useMutation<TransferCare_Mutation>(TRANSFER_CARE_MUTATION);

  const appointmentTypesList = useMemo(() => {
    if (emr709RemoveLabVisit) {
      return data?.listAppointmentTypes?.filter(
        (d) => d.group !== PapiAppointmentGroup.LAB
      );
    }
    return data?.listAppointmentTypes;
  }, [data, emr709RemoveLabVisit]);

  const [currentAppointmentTypes, setCurrentAppointmentTypes] = useState<
    VisitCardListAppointmentTypesQuery_listAppointmentTypes | undefined
  >(undefined);

  const handleSelectedProviderChange = (id: string): void => {
    setProviderID(id);
  };

  const resetProviderId = (value: boolean): void => {
    setNoProvider(value);
    if (value) {
      setProviderID(undefined);
    }
  };

  const changeSelectedAppointmentTypes = (appointmentTypeId: string): void => {
    const selected = appointmentTypesList?.find(
      (item) => item.typeID === appointmentTypeId
    );
    setCurrentAppointmentTypes(selected);
    if (selected?.name === 'COACHING_TRANSFER_OF_CARE') {
      setProviderType(PapiProviderType.HEALTH_COACH);
      setProviderID(undefined);
      setTransferType(PapiTransferOfCareTypeInput.COACHING);
    }
    if (selected?.name === 'MEDICAL_TRANSFER_OF_CARE') {
      setProviderType(PapiProviderType.DOCTOR);
      setProviderID(undefined);
      setTransferType(PapiTransferOfCareTypeInput.CLINICAL);
    }
  };

  const transferCareAppointment = useCallback(async () => {
    const currentProviderID = getTOCProviderId(
      member,
      currentAppointmentTypes?.name
    );

    try {
      await transferCare({
        variables: {
          input: {
            currentProviderID,
            personID,
            providerID,
            transferType,
          },
        },
      });

      if (transferCareError) {
        message.error(STATUS_MESSAGE.transferCare.error.general);
        return onClose();
      }

      message.success(STATUS_MESSAGE.transferCare.success);
      reloadVisits();
      return onClose();
    } catch (_) {
      message.error(STATUS_MESSAGE.transferCare.error.general);
      return onClose();
    }
  }, [
    member,
    currentAppointmentTypes,
    onClose,
    transferCare,
    personID,
    providerID,
    transferType,
    transferCareError,
    reloadVisits,
  ]);

  const saveAppointment = useCallback(async () => {
    try {
      await createAppointment({
        variables: {
          input: {
            centerID,
            duration: currentAppointmentTypes?.duration,
            isComplimentary,
            notes,
            personID,
            planPurchaseID,
            typeID: currentAppointmentTypes?.typeID,
          },
        },
      });
      if (createAppointmentError) {
        message.error(STATUS_MESSAGE.visitCreate.error.general);
        return onClose();
      }

      message.success(STATUS_MESSAGE.visitCreate.success);
      reloadVisits();
      return onClose();
    } catch (_) {
      message.error(STATUS_MESSAGE.visitCreate.error.general);
      return onClose();
    }
  }, [
    createAppointment,
    centerID,
    currentAppointmentTypes,
    isComplimentary,
    notes,
    personID,
    planPurchaseID,
    createAppointmentError,
    reloadVisits,
    onClose,
  ]);

  const isTransferOfCare: boolean = currentAppointmentTypes?.name
    ? TOC_PLANS.includes(currentAppointmentTypes?.name)
    : false;

  return (
    <ItemWrapper>
      <Ribbon color={theme.color.grayGreen} />
      <CardBody>
        <Heading>
          <div>Add a new visit</div>
        </Heading>
        <div>
          <ASectionBtmMargin>
            <VisitTypeContainer>
              <ASelectAppointmentTypes
                appointmentTypesList={appointmentTypesList}
                collapseError
                data-testid={visitTypeSelectTestId}
                label="Visit Type"
                loading={loading}
                onChange={changeSelectedAppointmentTypes}
                placeholder="Visit type"
                selectedAppointmentTypes={currentAppointmentTypes}
              />
            </VisitTypeContainer>

            {isTransferOfCare && providerType ? (
              <ProvidersByTypeDropdown
                disabled={noProvider}
                onChange={handleSelectedProviderChange}
                providerType={providerType}
                value={providerID}
              />
            ) : null}

            {showNotes ? (
              <NotesContainer>
                <ALabel black>Notes</ALabel>
                <TextArea
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Add notes here"
                  style={{ height: '120px' }}
                  value={notes}
                />
              </NotesContainer>
            ) : null}

            {isTransferOfCare ? (
              <ComplimentaryContainer>
                <Checkbox
                  checked={noProvider}
                  data-testid={noProviderCheckboxTestId}
                  onChange={(e) => resetProviderId(e.target.checked)}
                >
                  Do not assign new provider for member
                </Checkbox>
              </ComplimentaryContainer>
            ) : (
              <ComplimentaryContainer>
                <Checkbox
                  checked={isComplimentary}
                  data-testid={complimentaryCheckboxTestId}
                  onChange={(e) => setIsComplimentary(e.target.checked)}
                >
                  Mark as Complimentary
                </Checkbox>
                <ComplimentaryText>
                  By marking this as a complimentary, this visit is not counted
                  toward the visit sequence.
                </ComplimentaryText>
              </ComplimentaryContainer>
            )}

            {createAppointmentError && (
              <RedText>
                Sorry, something went wrong and we are unable to update this
                profile visit right now.
              </RedText>
            )}
          </ASectionBtmMargin>
          <ButtonsContainer>
            <VistCardButton data-testid="close-visit-button" onClick={onClose}>
              Cancel
            </VistCardButton>
            <VistCardButton
              data-testid="save-button"
              loading={
                isTransferOfCare
                  ? transferCareLoading
                  : createAppointmentLoading
              }
              onClick={
                isTransferOfCare ? transferCareAppointment : saveAppointment
              }
              type="primary"
            >
              Save
            </VistCardButton>
          </ButtonsContainer>
        </div>
      </CardBody>
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div`
  margin-bottom: 25px;
`;

const Ribbon = styled.div<{ color: string }>`
  background-color: ${() => theme.color.parsleyGreen};
  height: 5px;
`;

const CardBody = styled.div`
  background: ${theme.color.lightCloud};
  border-bottom: 2px solid ${theme.color.parsleyGreen};
  border-left: 2px solid ${theme.color.parsleyGreen};
  border-right: 2px solid ${theme.color.parsleyGreen};
  padding: ${theme.space.l};
`;

const Heading = styled.div`
  align-items: center;
  display: flex;
  font-weight: ${theme.font.weight.medium};
  margin-bottom: 20px;
  position: relative;
`;

const RedText = styled.div`
  color: ${theme.color.crimson7};
`;

const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

const VisitTypeContainer = styled.div`
  margin-bottom: 20px;
`;

const ComplimentaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ComplimentaryText = styled.span`
  padding-top: 5px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${theme.space.m} 0 0 0;
`;

const VistCardButton = styled(Button)`
  height: 40px;
  margin-right: 10px;
  width: 152px;
`;
