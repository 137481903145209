import gql from 'graphql-tag';

import { VisitCard_Fragment } from './fragment';

export const VISIT_UPDATE_MUTATION = gql`
  mutation VisitUpdate_Mutation($input: PapiUpdateAppointmentInput!) {
    updateAppointment(input: $input) {
      ...VisitCard_Fragment
    }
  }
  ${VisitCard_Fragment}
`;

export const MARK_VISIT_AS_NO_SHOW_MUTATION = gql`
  mutation MarkVisitAsNoShow_Mutation($appointmentID: ID!) {
    markAppointmentAsNoShow(appointmentID: $appointmentID) {
      ...VisitCard_Fragment
    }
  }
  ${VisitCard_Fragment}
`;
