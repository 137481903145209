/* eslint-disable */
const fontSizeBaseREM = 1;
const fontSizeBase = fontSizeBaseREM * 14;

const family = {
  base: `"EuclidCircular", "Helvetica Neue", Arial, sans-serif`,
  heading: `"EuclidCircular", "Helvetica Neue", Arial, sans-serif`,
};

const letterSpacing = {
  regular: '0',
  loose: '0.5px',
  looser: '1px',
  loosest: '2px',
  mega: '3px',
};

const lineHeight = {
  base: 1.5,
  xl: 2.5,
  l: 2,
  m: 1.5,
  s: 1,
};

const size = {
  base: `${fontSizeBaseREM}rem`,
  xxs: '0.65rem',
  xs: '0.75rem',
  s: '0.875rem',
  m: '1rem',
  l: '1.125rem',
  xl: '1.25rem',
  xxl: '1.5rem',
  xxxl: '1.75rem',
  xxxxl: '2rem',
  huge: '2.5rem',
};

/** Only weights 300-600 are downloaded from Google fonts */
const weight = {
  base: 400,
  thin: 100,
  extralight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

const mixins = {
  ellipsisOverflow: `
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
};

export const fontTheme = {
  family,
  fontSizeBase,
  letterSpacing,
  lineHeight,
  mixins,
  size,
  weight,
};
