import gql from 'graphql-tag';

import { MemberName_Fragment } from 'app/fragments/MemberName';

export const CalendarCard_Fragment_visit = gql`
  fragment CalendarCard_Fragment_visit on PapiScheduledAppointment {
    id
    categoryDetails {
      category
      color
      name
    }
    endTime
    format
    member: person {
      id
      currentPlan {
        id
        extensionOf {
          id
          plan {
            id
            product {
              abbreviation
            }
          }
        }
        plan {
          id
          product {
            abbreviation
          }
        }
      }
      ...MemberName_Fragment
    }
    startTime
  }
  ${MemberName_Fragment}
`;
