import React from 'react';

import AIconCareManager from 'app/components/atoms/AIconCareManager/AIconCareManager';
import AIconDoctor from 'app/components/atoms/AIconDoctor/AIconDoctor';
import AIconHealthCoach from 'app/components/atoms/AIconHealthCoach/AIconHealthCoach';
import AIconLab from 'app/components/atoms/AIconLab/AIconLab';
import { PapiAppointmentGroup } from 'app/types/generated/globalTypes';

interface Props {
  appointmentGroup: PapiAppointmentGroup;
}

export const AIconVisit: React.FC<Props> = ({ appointmentGroup }) => {
  switch (appointmentGroup) {
    case PapiAppointmentGroup.CAREMANAGER:
      return <AIconCareManager />;
    case PapiAppointmentGroup.COACHING:
      return <AIconHealthCoach />;
    case PapiAppointmentGroup.LAB:
      return <AIconLab />;
    default:
      return <AIconDoctor />;
  }
};
