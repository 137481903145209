import gql from 'graphql-tag';

export const PaymentCardDelete_Fragment_card = gql`
  fragment PaymentCardDelete_Fragment_card on PapiPaymentCard {
    id
    deleted
    expiryMonth
    expiryYear
    last4Digits
    name
    personID
  }
`;
