import { useQuery } from '@apollo/react-hooks';
import { Icon, Popover, Select, Tooltip } from 'antd';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { AIconClickable } from 'app/components/atoms/AIconClickable/AIconClickable';
import { AIconInline } from 'app/components/atoms/AIconInline/AIconInline';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { AList } from 'app/components/atoms/AList/AList';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { VisitListing } from 'app/components/deprecated/VisitListing/VisitListing';
import { ViewerContext } from 'app/contexts/ViewerContext';
import { theme } from 'app/styles/theme';
import { App_Query_viewer_center } from 'app/types/generated/App_Query';
import { PapiProviderType } from 'app/types/generated/globalTypes';
import {
  VisitsList_Query,
  VisitsList_QueryVariables,
  VisitsList_Query_visits,
} from 'app/types/generated/VisitsList_Query';
import {
  VisitsList_Query_Filters,
  VisitsList_Query_Filters_centers,
} from 'app/types/generated/VisitsList_Query_Filters';
import { displayErrors } from 'app/utils/app';
import { getCenterDisplayName } from 'app/utils/center';
import { compareLabel } from 'app/utils/sort';
import { US_STATES } from 'constants/form';
import { STATUS_MESSAGE } from 'constants/message';

import { VISITS_LIST_QUERY, VISITS_LIST_QUERY_FILTERS } from './query';

// Types & constants ////////////////////////////////
interface Props {
  onVisitClick?: (personID: string) => void;
  routeToProfile?: boolean;
}

const DEFAULT_DATE = DateTime.local();

/**
 * Displays a center's visits for a particular day. The "day" is based on the center's
 * local time, e.g. if the selected center is in NY, the list will show visits that start
 * from 00:00 NY time to 23:59:59.999 NY time. Only one center may be selected at a time
 */
const VisitsList: FC<Props> = ({ onVisitClick, routeToProfile = true }) => {
  const viewer = useContext(ViewerContext);

  // Filters
  const { data: dataFilters, loading: loadingFilters } = useQuery<
    VisitsList_Query_Filters
  >(VISITS_LIST_QUERY_FILTERS);

  const [selectedDate, setSelectedDate] = useState(DEFAULT_DATE);
  const [centerFilter, setCenterFilter] = useState<
    VisitsList_Query_Filters_centers | App_Query_viewer_center | undefined
  >(viewer.center || undefined);
  const [showCenterFilter, setShowCenterFilter] = useState(false);

  const [providerFilter, setProviderFilter] = useState<string[]>([]);
  const loggedInProvider =
    viewer &&
    dataFilters?.providers?.find(
      (provider) =>
        provider.email === viewer.email &&
        (provider.type === PapiProviderType.DOCTOR ||
          provider.type === PapiProviderType.HEALTH_COACH)
    );
  const loggedInProviderID = loggedInProvider?.id;
  useEffect(() => {
    if (loggedInProviderID) {
      setProviderFilter((providers) => [...providers, loggedInProviderID]);
    }
  }, [loggedInProviderID]);

  // Visit data
  const [visits, setVisits] = useState<VisitsList_Query_visits[] | undefined>();
  const { data, loading, networkStatus, refetch } = useQuery<
    VisitsList_Query,
    VisitsList_QueryVariables
  >(VISITS_LIST_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: createVisitQueryVariables(DEFAULT_DATE, centerFilter),
  });

  // Update visits when data first loads. See handleRefetch for why we render visits from state
  useEffect(() => {
    if (visits === undefined && data) {
      setVisits(data.visits);
    }
  }, [data, visits]);

  // Refetch visit data
  useEffect(() => {
    const handleRefetch = async (newDate: DateTime): Promise<void> => {
      try {
        const refetched = await refetch(
          createVisitQueryVariables(newDate, centerFilter)
        );
        if (refetched?.data?.visits) {
          // Store visits in state because Apollo clears the 'data' field due to a
          // refetch/ID matching bug https://github.com/apollographql/react-apollo/issues/2114
          setVisits(refetched.data.visits);
        }
      } catch (err) {
        displayErrors(err, STATUS_MESSAGE.visitList.error.general);
      }
    };
    handleRefetch(selectedDate);
  }, [centerFilter, refetch, selectedDate]);

  return (
    <AList<VisitsList_Query_visits>
      data={sortBy(
        (visits || []).filter(
          (visit) =>
            (!centerFilter || visit.center?.id === centerFilter.id) &&
            (providerFilter.length === 0 ||
              (visit.provider && providerFilter.includes(visit.provider.id)))
        ),
        'startTime'
      )}
      header={
        <>
          <Header>
            <Tooltip
              title={selectedDate.minus({ days: 1 }).toFormat('EEE. MMM d')}
            >
              <AIconClickable
                data-testid="VisitsList_prevDay"
                onClick={() =>
                  setSelectedDate((date) => date.minus({ days: 1 }))
                }
                primary
                type="left"
              />
            </Tooltip>
            <HeaderStyled>
              <Title>{selectedDate.toFormat('EEE. MMM d')} Visits</Title>
              {!selectedDate.hasSame(DEFAULT_DATE, 'day') && (
                <Tooltip title="Back to today">
                  <AIconClickable
                    data-testid="VisitsList_today"
                    onClick={() => setSelectedDate(DEFAULT_DATE)}
                    primary
                    type="calendar"
                  />
                </Tooltip>
              )}
            </HeaderStyled>
            <Tooltip
              title={selectedDate.plus({ days: 1 }).toFormat('EEE. MMM d')}
            >
              <AIconClickable
                data-testid="VisitsList_nextDay"
                onClick={() =>
                  setSelectedDate((date) => date.plus({ days: 1 }))
                }
                primary
                type="right"
              />
            </Tooltip>
          </Header>

          <Filters>
            <ALabel inline>Filter</ALabel>
            <Popover
              content={
                dataFilters ? (
                  <SelectStyled
                    filterOption={(input, option) =>
                      option.props.title
                        ? option.props.title
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false
                    }
                    getPopupContainer={(trigger) =>
                      trigger.parentNode as HTMLElement
                    }
                    onChange={(value) => {
                      setCenterFilter(
                        dataFilters!.centers!.find(
                          (center) => center.id === value
                        )!
                      );
                      setShowCenterFilter(false);
                    }}
                    placeholder="Select center"
                    showSearch
                    value={undefined}
                  >
                    {dataFilters.centers
                      .map((center) => ({
                        label: getCenterDisplayName(center),
                        title: `${getCenterDisplayName(center)} ${
                          center.isVirtual
                            ? US_STATES.find(
                                (state) => state.value === center.state
                              )!.label
                            : center.city
                        }`,
                        value: center.id,
                      }))
                      .sort(compareLabel)
                      .map(({ label, title, value }) => (
                        <SelectOptionStyled
                          key={value}
                          title={title}
                          value={value}
                        >
                          {label}
                        </SelectOptionStyled>
                      ))}
                  </SelectStyled>
                ) : (
                  <ALoadingStyled centered />
                )
              }
              placement="bottom"
              trigger="click"
              visible={showCenterFilter}
            >
              <FilterLabel
                active={!!centerFilter}
                onClick={() => setShowCenterFilter((currShow) => !currShow)}
              >
                {centerFilter ? getCenterDisplayName(centerFilter) : 'Center'}{' '}
                <FilterIcon theme="filled" type="filter" />
              </FilterLabel>
            </Popover>

            <Popover
              content={
                <ProviderFilterContainer>
                  <Select<string[]>
                    allowClear
                    clearIcon={
                      <Icon
                        onClick={() => setProviderFilter([])}
                        theme="filled"
                        type="close-circle"
                      />
                    }
                    filterOption={(input, option) =>
                      option.props
                        .title!.toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={loadingFilters}
                    mode="multiple"
                    notFoundContent="No providers found"
                    onDeselect={(id) =>
                      setProviderFilter((selectedIDs) =>
                        selectedIDs.filter((selectedID) => selectedID !== id)
                      )
                    }
                    onSelect={(id) =>
                      setProviderFilter((selectedIDs) => [...selectedIDs, id])
                    }
                    placeholder="Search providers"
                    value={providerFilter}
                  >
                    {sortBy(dataFilters?.providers || [], 'displayName').map(
                      (provider) =>
                        (provider.type === PapiProviderType.DOCTOR ||
                          provider.type === PapiProviderType.HEALTH_COACH) && (
                          <Select.Option
                            key={provider.id}
                            title={provider.displayName}
                            value={provider.id}
                          >
                            {provider.displayName}
                          </Select.Option>
                        )
                    )}
                  </Select>
                </ProviderFilterContainer>
              }
              placement="bottom"
              trigger="click"
            >
              <FilterLabel active={providerFilter.length > 0}>
                Providers <FilterIcon theme="filled" type="filter" />
              </FilterLabel>
            </Popover>
          </Filters>
        </>
      }
      loading={loading || networkStatus === 4}
      renderItem={(visit) => (
        <VisitListing
          onMemberClick={onVisitClick}
          routeToProfile={routeToProfile}
          visit={visit}
        />
      )}
    />
  );
};

// Styled components ////////////////////////////////
const ALoadingStyled = styled(ALoading)`
  &&& {
    padding: ${theme.space.s} ${theme.space.s} 0 ${theme.space.s};
  }
`;

const FilterIcon = styled(AIconInline)`
  &&& {
    bottom: ${theme.space.xs};
  }
`;
const FilterLabel = styled.div<{ active: boolean }>`
  cursor: pointer;
  margin-left: ${theme.space.m};
  i {
    color: ${({ active }) =>
      active ? theme.color.primary : theme.color.textLighter};
  }
`;
const Filters = styled.div`
  display: flex;
  padding-top: ${theme.space.s};
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const ProviderFilterContainer = styled.div`
  max-width: 375px;
  min-width: 200px;
  .ant-select {
    width: 100%;
  }
  .ant-select-selection__choice__remove {
    bottom: ${theme.space.xxs};
  }
`;

const SelectOptionStyled = styled(Select.Option)`
  &&& {
    width: 100%;
  }
`;

const SelectStyled = styled(Select)`
  &&& {
    padding: ${theme.space.xs} 0 0 ${theme.space.xs};
    width: 160px;
  }
`;

const Title = styled.h4`
  margin: 0 ${theme.space.m};
`;

const HeaderStyled = styled(Header)`
  width: 240px;
`;

// Helpers ////////////////////////////////
const createVisitQueryVariables = (
  date: DateTime,
  center?: Pick<VisitsList_Query_Filters_centers, 'id' | 'timezone'>
): VisitsList_QueryVariables => ({
  centerIDs: center ? [center.id] : [],
  maxTime: center
    ? date
        .setZone(center.timezone, { keepLocalTime: true })
        .endOf('day')
        .toISO()
    : date.endOf('day').toISO(),
  minTime: center
    ? date
        .setZone(center.timezone, { keepLocalTime: true })
        .startOf('day')
        .toISO()
    : date.startOf('day').toISO(),
});

export { VisitsList };
