import gql from 'graphql-tag';

/** Get fields to display a provider's name using formatProviderName */
export const ProviderName_Fragment = gql`
  fragment ProviderName_Fragment on PapiProvider {
    id
    firstName
    lastName
    middleName
    displayName
    type
  }
`;
