import { MemberPageVisits_Query_member } from 'app/types/generated/MemberPageVisits_Query';
import { VisitCardListAppointmentTypesQuery_listAppointmentTypes } from 'app/types/generated/VisitCardListAppointmentTypesQuery';

export const getTOCProviderId = (
  member: MemberPageVisits_Query_member,
  currentAppointmentType?: VisitCardListAppointmentTypesQuery_listAppointmentTypes['name']
): string | undefined => {
  if (!currentAppointmentType) return undefined;

  const providerID =
    currentAppointmentType === 'COACHING_TRANSFER_OF_CARE'
      ? member.assignedCoach?.id
      : member.assignedDoctor?.id;

  return providerID;
};
