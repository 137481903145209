import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import React, { FC, useCallback } from 'react';
import styled from 'styled-components/macro';

import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { APadding } from 'app/components/atoms/APadding/APadding';
import { theme } from 'app/styles/theme';
import {
  VisitCancel_Mutation,
  VisitCancel_MutationVariables,
} from 'app/types/generated/VisitCancel_Mutation';
import {
  VisitDelete_Mutation,
  VisitDelete_MutationVariables,
} from 'app/types/generated/VisitDelete_Mutation';
import { STATUS_MESSAGE } from 'constants/message';

import { VISIT_CANCEL_MUTATION, VISIT_DELETE_MUTATION } from './query';

export enum Operation {
  'cancel',
  'delete',
}

// Types & constants ////////////////////////////////
interface Props {
  appointmentID: string;
  isVisible: Operation | null;
  onClose: () => void;
  onSuccess: () => void;
}

interface InternalProps {
  okText: string;
  onOk: (() => Promise<void>) | undefined;
  subtitleText: string;
  titleText: string;
  visible: boolean;
}

const VisitDeleteModal: FC<Props> = ({
  appointmentID,
  isVisible,
  onClose,
  onSuccess,
}) => {
  const [
    deleteVisit,
    { error: deleteVisitError, loading: mutationLoading },
  ] = useMutation<VisitDelete_Mutation, VisitDelete_MutationVariables>(
    VISIT_DELETE_MUTATION
  );

  const [
    cancelVisit,
    { error: cancelVisitError, loading: cancelVisitLoading },
  ] = useMutation<VisitCancel_Mutation, VisitCancel_MutationVariables>(
    VISIT_CANCEL_MUTATION
  );

  const onDelete = useCallback(async () => {
    try {
      await deleteVisit({ variables: { appointmentID } });
      if (deleteVisitError) {
        message.error(STATUS_MESSAGE.visitDelete.error.general);
        return onClose();
      }
      message.success(STATUS_MESSAGE.visitDelete.success);
      return onSuccess();
    } catch (_) {
      message.error(STATUS_MESSAGE.visitDelete.error.general);
      return onClose();
    }
  }, [appointmentID, deleteVisit, deleteVisitError, onClose, onSuccess]);

  const onCancel = useCallback(async () => {
    try {
      await cancelVisit({ variables: { appointmentID } });
      if (cancelVisitError) {
        message.error(STATUS_MESSAGE.visitCancel.error.general);
        return onClose();
      }
      message.success(STATUS_MESSAGE.visitCancel.success);
      return onSuccess();
    } catch (_) {
      message.error(STATUS_MESSAGE.visitCancel.error.general);
    }
  }, [appointmentID, cancelVisit, cancelVisitError, onClose, onSuccess]);

  const getModalProps = (): InternalProps => {
    switch (isVisible) {
      case Operation.cancel:
        return {
          okText: 'Yes, Cancel',
          onOk: onCancel,
          subtitleText: 'cancelled',
          titleText: 'cancel',
          visible: true,
        };
      case Operation.delete:
        return {
          okText: 'Delete',
          onOk: onDelete,
          subtitleText: 'deleted',
          titleText: 'delete',
          visible: true,
        };
      default:
        return {
          okText: '',
          onOk: undefined,
          subtitleText: '',
          titleText: '',
          visible: false,
        };
    }
  };

  const modalProps = getModalProps();

  return !modalProps.visible ? null : (
    <Modal
      cancelText="Close"
      confirmLoading={mutationLoading || cancelVisitLoading}
      destroyOnClose
      okText={modalProps.okText}
      okType="danger"
      onCancel={onClose}
      onOk={modalProps.onOk}
      visible={modalProps.visible}
    >
      {mutationLoading || cancelVisitLoading ? (
        <ALoadingStyled centered />
      ) : (
        <APadding type="modal">
          <Title>
            Are you sure you want to {modalProps.titleText} this visit?
          </Title>
          <Subtitle>
            This visit will be {modalProps.subtitleText} permanently
          </Subtitle>
        </APadding>
      )}
    </Modal>
  );
};

// Styled components ///////////////////////////////
const ALoadingStyled = styled(ALoading)`
  &&& {
    padding: ${theme.space.xxl} 0 ${theme.space.xxxl} 0;
  }
`;

const Title = styled.h2`
  text-align: center;
`;

const Subtitle = styled.div`
  text-align: center;
`;
export { VisitDeleteModal };
