import gql from 'graphql-tag';

export const ProductDetails_Fragment_product = gql`
  fragment ProductDetails_Fragment_product on PapiProduct {
    deprecatedAt
    description
    displayName
    plans {
      id
    }
    renewalPeriod
    shopSummary
  }
`;
