import gql from 'graphql-tag';

export const MemberProviders_Fragment_provider = gql`
  fragment MemberProviders_Fragment_provider on PapiProvider {
    id
    sanityProfile {
      imageSrc
    }
    displayName
    firstName
    lastName
    pod {
      id
      name
    }
  }
`;
