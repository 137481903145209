import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { ATag } from 'app/components/atoms/ATag/ATag';
import { theme } from 'app/styles/theme';
import {
  PapiConsumerType,
  PapiMembershipActivityStatus,
  PapiMembershipPaymentStatus,
} from 'app/types/generated/globalTypes';
import { PapiPlanPurchaseState } from 'app/types/generated/globalTypes';
import {
  MemberHeader_Query_member_currentPlan,
  MemberHeader_Query_member_currentPlan_plan,
  MemberHeader_Query_partner,
} from 'app/types/generated/MemberHeader_Query';
import { MemberStatusTags_Fragment_member } from 'app/types/generated/MemberStatusTags_Fragment_member';
// Types & constants ////////////////////////////////
interface MemberStatusTagsProps extends MemberStatusTags_Fragment_member {
  currentPlan?: MemberHeader_Query_member_currentPlan | null;
}
interface Props {
  member: MemberStatusTagsProps;
  partner?: MemberHeader_Query_partner;
}

/** Member's notable statuses, e.g. unpaid, inactive */
const MemberStatusTags: FC<Props> = ({
  member: { currentPlan, membershipActivityStatus, membershipPaymentStatus },
  partner,
}) => {
  // Don't show the 'Complimentary' tag for B2B users (their services are paid for through contracts)
  // The 'Complimentary' tag should only be used for members that are receiving complimentary PH services (e.g., PH employees, influencers, etc.)
  const hideComplimentaryTag =
    currentPlan?.plan?.consumerType === PapiConsumerType.ENTERPRISE_PARTNER &&
    membershipActivityStatus ===
      PapiMembershipActivityStatus.MEMBERSHIP_COMPLIMENTARY;

  return (
    <>
      {currentPlan &&
      (currentPlan.purchaseState === PapiPlanPurchaseState.ENDED_CANCELLED ||
        currentPlan.purchaseState === PapiPlanPurchaseState.ENDED_NATURALLY) ? (
        <StatusTag color={theme.color.middleGrey}>ENDED</StatusTag>
      ) : (
        <>
          {membershipPaymentStatus ===
            PapiMembershipPaymentStatus.MEMBERSHIP_UNPAID && (
            <StatusTag
              color={getMembershipStatusColor(membershipPaymentStatus)}
            >
              UNPAID
            </StatusTag>
          )}
          {membershipActivityStatus &&
            membershipActivityStatus !==
              PapiMembershipActivityStatus.MEMBERSHIP_ACTIVE &&
            !hideComplimentaryTag && (
              <StatusTag
                color={getMembershipStatusColor(membershipActivityStatus)}
              >
                {membershipActivityStatus
                  .replace('MEMBERSHIP_', '')
                  .replace(/_/g, ' ')}
              </StatusTag>
            )}
          {currentPlan?.plan && (
            <StatusTag color={getConsumerTypeColor(currentPlan?.plan)}>
              {getConsumerTypeLabel(currentPlan?.plan)}
            </StatusTag>
          )}
          {partner?.partner?.displayName && (
            <StatusTag color={getConsumerTypeColor(currentPlan?.plan)}>
              {partner?.partner?.displayName}
            </StatusTag>
          )}
        </>
      )}
    </>
  );
};

// Styled components ////////////////////////////////
const StatusTag = styled(ATag)`
  &&& {
    margin-right: ${theme.space.s};
  }
`;

// Helpers ////////////////////////////////
const getMembershipStatusColor = (
  status: PapiMembershipActivityStatus | PapiMembershipPaymentStatus
): string => {
  switch (status) {
    case PapiMembershipPaymentStatus.MEMBERSHIP_UNPAID:
      return theme.color.red;
    case PapiMembershipActivityStatus.MEMBERSHIP_INACTIVE:
    case PapiMembershipActivityStatus.MEMBERSHIP_PROSPECTIVE:
      return theme.color.textLight;
    case PapiMembershipActivityStatus.MEMBERSHIP_COMPLIMENTARY:
    case PapiMembershipActivityStatus.MEMBERSHIP_ON_EXTENSION:
    case PapiMembershipActivityStatus.MEMBERSHIP_PAUSED:
    case PapiMembershipActivityStatus.MEMBERSHIP_PENDING:
    case PapiMembershipActivityStatus.MEMBERSHIP_WAITLISTED:
      return theme.color.warning;
    default:
      return theme.color.textLightest;
  }
};

// Temp pending translations
const getConsumerTypeLabel = (
  plan: MemberHeader_Query_member_currentPlan_plan | undefined
): string => {
  if (plan) {
    switch (plan.consumerType) {
      case PapiConsumerType.DIRECT_CONSUMER:
        return 'Direct to Consumer (D2C)';
      case PapiConsumerType.ENTERPRISE_PARTNER:
        return 'Enterprise Partner (B2B)';
      default:
        return 'Unspecified';
    }
  }
  return 'Unspecified';
};

const getConsumerTypeColor = (
  plan: MemberHeader_Query_member_currentPlan_plan | undefined
): string => {
  if (plan) {
    switch (plan.consumerType) {
      case PapiConsumerType.DIRECT_CONSUMER:
        return theme.color.blue;
      case PapiConsumerType.ENTERPRISE_PARTNER:
        return theme.color.parsleyGreen;
      default:
        return theme.color.red;
    }
  }
  return theme.color.red;
};

export { MemberStatusTags };
