import { Icon } from 'antd';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

export const AIconUnclickable = styled(Icon)`
  &&& {
    color: ${theme.color.textLighter};
    cursor: not-allowed;
  }
`;
