import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  Checkbox,
  Input,
  message,
  Menu,
  Dropdown,
  Tooltip,
} from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, {
  useMemo,
  useRef,
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import styled from 'styled-components/macro';

import { ACard } from 'app/components/atoms/ACard/ACard';
import { AIconEdit } from 'app/components/atoms/AIconEdit/AIconEdit';
import { AIconLink } from 'app/components/atoms/AIconLink/AIconLink';
import { AIconVisit } from 'app/components/atoms/AIconVisit/AIconVisit';
import { NoteCreate } from 'app/components/organisms/NoteCreate/NoteCreate';
import {
  Operation,
  VisitDeleteModal,
} from 'app/components/organisms/RemoveVisitModal/RemoveVisitModal';
import { UnblockVisitModal } from 'app/components/organisms/UnblockVisitModal/UnblockVisitModal';
import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { theme } from 'app/styles/theme';
import {
  PapiAppointmentFormat,
  PapiAppointmentGroup,
  PapiAppointmentStatus,
} from 'app/types/generated/globalTypes';
import { MarkVisitAsNoShow_Mutation } from 'app/types/generated/MarkVisitAsNoShow_Mutation';
import { MemberVisits_Query_visits } from 'app/types/generated/MemberVisits_Query';
import { VisitUpdate_Mutation } from 'app/types/generated/VisitUpdate_Mutation';
import { MY_PARSLEY_URL } from 'constants/env';
import { STATUS_MESSAGE } from 'constants/message';
import {
  cancelledIcon,
  completedIcon,
  noShowIcon,
  blockedIcon,
} from 'static/images/icons';

import { VISIT_UPDATE_MUTATION, MARK_VISIT_AS_NO_SHOW_MUTATION } from './query';

const { TextArea } = Input;

export const VisitCard: React.FC<{
  onReloadVisits?: () => void;
  visit: MemberVisits_Query_visits;
}> = ({ onReloadVisits, visit }) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <div>
      <Ribbon color={visit.details.displayColor} />
      <CardBody editMode={editMode}>
        <VisitCardHeader
          editMode={editMode}
          onReloadVisits={onReloadVisits}
          setEditMode={setEditMode}
          visit={visit}
        />
        {editMode && (
          <VisitCardEdit
            onReloadVisits={onReloadVisits}
            setEditMode={setEditMode}
            visit={visit}
          />
        )}
        {!editMode && <VisitCardDetails visit={visit} />}
        {!editMode && visit.metadata && <VisitCardNotes visit={visit} />}
      </CardBody>
      {!editMode && (
        <VisitCardFooter
          editMode={editMode}
          onReloadVisits={onReloadVisits}
          visit={visit}
        />
      )}
    </div>
  );
};

const VisitCardNotes: React.FC<{
  visit: MemberVisits_Query_visits;
}> = ({ visit }) => {
  const [showNotes, setShowNotes] = useState<boolean>(false);
  return (
    <div>
      <ClickableText onClick={() => setShowNotes(!showNotes)}>
        {showNotes ? 'Hide Notes' : 'See Notes'}
      </ClickableText>
      {showNotes && (
        <TextAreaWithLineBreaks>{visit.metadata}</TextAreaWithLineBreaks>
      )}
    </div>
  );
};

const VisitCardEdit: React.FC<{
  onReloadVisits?: () => void;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  // setVisit: React.Dispatch<React.SetStateAction<MemberVisits_Query_visits>>;
  visit: MemberVisits_Query_visits;
}> = ({ onReloadVisits, setEditMode, visit }) => {
  const [notes, setNotes] = useState<string>(visit.metadata ?? '');
  const [isComplimentary, setIsComplimentary] = useState<boolean>(
    visit.isComplimentary
  );
  const [isNoShow, setIsNoShow] = useState<boolean>(false);
  const featureFlags = useContext(FeatureFlagContext);
  const showNotes = featureFlags.variation('dr-p-edit-appointment-notes');
  const [
    updateVisit,
    { error: updateVisitError, loading: updateVisitLoading },
  ] = useMutation<VisitUpdate_Mutation>(VISIT_UPDATE_MUTATION);
  const [
    markVisitAsNoShow,
    { error: markVisitAsNoShowError, loading: markVisitAsNoShowLoading },
  ] = useMutation<MarkVisitAsNoShow_Mutation>(MARK_VISIT_AS_NO_SHOW_MUTATION);

  const saveVisit = async (): Promise<void> => {
    try {
      if (isNoShow) {
        await markVisitAsNoShow({
          variables: {
            appointmentID: visit.id,
          },
        });
        if (markVisitAsNoShowError) {
          message.error(STATUS_MESSAGE.visitUpdate.error.general);
          return;
        }
        message.success(STATUS_MESSAGE.visitUpdate.success);
      }

      if (
        visit.status === PapiAppointmentStatus.UNSCHEDULED ||
        visit.status === PapiAppointmentStatus.SCHEDULED
      ) {
        await updateVisit({
          variables: {
            input: {
              appointmentID: visit.id,
              isComplimentary,
              notes,
            },
          },
        });
        if (updateVisitError) {
          message.error(STATUS_MESSAGE.visitUpdate.error.general);
          return;
        }
        message.success(STATUS_MESSAGE.visitUpdate.success);
      }
      setEditMode(false);
      onReloadVisits && onReloadVisits();
    } catch (_) {
      message.error(STATUS_MESSAGE.visitUpdate.error.general);
    }
  };

  return (
    <div>
      {showNotes &&
        (visit.status === PapiAppointmentStatus.UNSCHEDULED ||
          visit.status === PapiAppointmentStatus.SCHEDULED) && (
          <NotesContainer>
            <DurationText>Notes</DurationText>
            <TextArea
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Add notes here"
              style={{ height: '120px' }}
              value={notes}
            />
          </NotesContainer>
        )}
      {(visit.status === PapiAppointmentStatus.UNSCHEDULED ||
        visit.status === PapiAppointmentStatus.SCHEDULED) && (
        <CheckContainer>
          <Checkbox
            checked={isComplimentary}
            onChange={(e) => setIsComplimentary(e.target.checked)}
          >
            Mark as Complimentary
          </Checkbox>
          <ComplimentaryText>
            By marking this as a complimentary, this visit is not counted toward
            the visit sequence.
          </ComplimentaryText>
        </CheckContainer>
      )}
      {visit.status === PapiAppointmentStatus.COMPLETED ||
      visit.status === PapiAppointmentStatus.SCHEDULED ? (
        <CheckContainer>
          <Checkbox
            checked={isNoShow}
            onChange={(e) => setIsNoShow(e.target.checked)}
          >
            Mark as no show
          </Checkbox>
          <ComplimentaryText>
            By marking this as no show, the visit will be cancelled in Acuity,
            and a new unscheduled visit will be issued to the member.
          </ComplimentaryText>
        </CheckContainer>
      ) : null}
      {(updateVisitError || markVisitAsNoShowError) && (
        <RedText>
          Sorry, something went wrong and we are unable to update this profile
          visit right now.
        </RedText>
      )}
      <ButtonsContainer>
        <VistCardButton onClick={() => setEditMode(false)} type="default">
          Close
        </VistCardButton>
        <VistCardButton
          loading={markVisitAsNoShowLoading || updateVisitLoading}
          onClick={saveVisit}
          type="primary"
        >
          Save
        </VistCardButton>
      </ButtonsContainer>
    </div>
  );
};

const VisitCardFooter: React.FC<{
  editMode: boolean;
  onReloadVisits?: () => void;
  visit: MemberVisits_Query_visits;
}> = ({ onReloadVisits, visit }) => {
  const featureFlags = useContext(FeatureFlagContext);
  const emr709RemoveLabVisit = featureFlags.variation(
    'emr-709-remove-lab-visit'
  );
  const [unblockModalVisible, setUnblockModalVisible] = useState(false);

  const incompleteVisitStates = [
    PapiAppointmentStatus.SCHEDULED,
    PapiAppointmentStatus.UNSCHEDULED,
    PapiAppointmentStatus.UNSCHEDULED,
  ];
  const formattedStartTime = useMemo(() => {
    const startDateTime = DateTime.fromISO(visit.startTime!, {
      zone: visit?.provider?.timezone || DateTime.local().zoneName,
    });

    return startDateTime.toFormat('f ZZZZ');
  }, [visit]);

  const formattedCompletedTime = useMemo(() => {
    const cancelledTime = DateTime.fromISO(visit!.completedAt!, {
      zone: visit?.provider?.timezone || DateTime.local().zoneName,
    });

    return cancelledTime.toFormat('f ZZZZ');
  }, [visit]);

  const isVisitBlocked = useMemo(() => {
    if (visit.status !== PapiAppointmentStatus.UNSCHEDULED) {
      return false;
    }
    return !visit.availableWindows.some((w) =>
      w.end ? moment.utc().isBefore(moment.utc(w.end)) : true
    );
  }, [visit]);

  let statusMsg = '';
  let statusIcon = '';
  let tooltip = '';

  switch (visit.status) {
    case PapiAppointmentStatus.UNSCHEDULED:
      statusMsg = 'Status: Unscheduled';
      if (isVisitBlocked) {
        statusMsg = 'Status: Blocked';
        statusIcon = blockedIcon;
        tooltip = 'Member unable to schedule due to a schedule restriction';
      }
      break;
    case PapiAppointmentStatus.COMPLETED:
      statusMsg = `Status: Completed on ${formattedStartTime}`;
      statusIcon = completedIcon;
      break;
    case PapiAppointmentStatus.SCHEDULED:
      statusMsg = `Status: Scheduled for ${formattedStartTime}`;
      break;
    case PapiAppointmentStatus.NO_SHOW:
      statusMsg = `Status: No Show on ${formattedStartTime}`;
      statusIcon = noShowIcon;
      break;
    case PapiAppointmentStatus.CANCELLED:
      statusMsg = `Status: Scheduled for ${formattedStartTime} & Cancelled on ${formattedCompletedTime}`;
      statusIcon = cancelledIcon;
      break;
    default:
      break;
  }

  const isDisabledScheduleBtn =
    emr709RemoveLabVisit &&
    visit.details?.group === PapiAppointmentGroup.LAB &&
    visit.status === PapiAppointmentStatus.SCHEDULED;

  if (statusMsg || incompleteVisitStates.includes(visit.status)) {
    return (
      <>
        <Footer mode={visit.status}>
          <div>
            {statusIcon && !tooltip && (
              <Icon
                alt={`visit-status-${visit.status.toLowerCase()}`}
                aria-label={`visit status ${visit.status.toLowerCase()}`}
                src={statusIcon}
              />
            )}
            {statusIcon && tooltip && (
              <Tooltip placement="bottom" title={tooltip}>
                <Icon
                  alt={`visit-status-${visit.status.toLowerCase()}`}
                  aria-label={`visit status ${visit.status.toLowerCase()}`}
                  src={statusIcon}
                />
              </Tooltip>
            )}
            {statusMsg}
          </div>
          <FooterButtonDiv isBlocked={isVisitBlocked}>
            {isVisitBlocked && (
              <Button
                onClick={() => setUnblockModalVisible(true)}
                size="small"
                type="primary"
              >
                Unblock
              </Button>
            )}
            {incompleteVisitStates.includes(visit.status) && (
              <PaddedButton
                disabled={isDisabledScheduleBtn}
                href={
                  isDisabledScheduleBtn
                    ? undefined
                    : `${MY_PARSLEY_URL}${visit.portalScheduleURL}`
                }
                size="small"
                target="_blank"
                type="primary"
              >
                {visit.status === PapiAppointmentStatus.UNSCHEDULED
                  ? 'Schedule'
                  : 'Reschedule'}
              </PaddedButton>
            )}
            {visit.status === PapiAppointmentStatus.SCHEDULED && (
              <NoteCreate
                appointmentID={visit.id}
                encounterID={visit.encounterID}
                noteCreated={typeof visit.encounterID === 'string'}
              />
            )}
          </FooterButtonDiv>
        </Footer>
        <UnblockVisitModal
          appointmentID={visit.id}
          isVisible={unblockModalVisible}
          onClose={() => setUnblockModalVisible(false)}
          onSuccess={() => {
            onReloadVisits && onReloadVisits();
            setUnblockModalVisible(false);
          }}
        />
      </>
    );
  }

  return null;
};

const VisitCardEditMenu: React.FC<{
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<Operation | null>>;
  shouldShowCancel: boolean;
  shouldShowDelete: boolean;
}> = ({
  editMode,
  setEditMode,
  setIsDeleteModalOpen,
  shouldShowCancel,
  shouldShowDelete,
}) => {
  const handleEditClick = (): void => {
    setEditMode(!editMode);
  };

  return (
    <Menu>
      <EditText onClick={handleEditClick}>Edit</EditText>
      {shouldShowDelete && (
        <DeleteText
          id="action-button"
          onClick={() => setIsDeleteModalOpen(Operation.delete)}
        >
          Delete
        </DeleteText>
      )}
      {shouldShowCancel && (
        <DeleteText
          id="action-button"
          onClick={() => setIsDeleteModalOpen(Operation.cancel)}
        >
          Cancel
        </DeleteText>
      )}
    </Menu>
  );
};

const VisitCardHeader: React.FC<{
  editMode: boolean;
  onReloadVisits?: () => void;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  visit: MemberVisits_Query_visits;
}> = ({ editMode, onReloadVisits, setEditMode, visit }) => {
  const scheduleLinkText = useRef<Input | null>(null);

  const [scheduleLinkOpen, setScheduleLinkOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<Operation | null>(
    null
  );
  const featureFlags = useContext(FeatureFlagContext);
  const showEditIcon = featureFlags.variation('dr-p-edit-visit');

  const copySchedulingLink = useCallback(() => {
    if (scheduleLinkText.current) {
      scheduleLinkText.current.select();
      document.execCommand('copy');
      alert('Scheduling link copied!');
      setScheduleLinkOpen(false);
    }
  }, []);

  const visitCompletedTime = useMemo(
    () =>
      visit.startTime
        ? moment(Date.parse(visit.startTime)).add(visit.duration, 'minutes')
        : null,
    [visit]
  );

  const shouldShowCancel = useMemo(() => {
    const wasCompletedInLast24hours = visitCompletedTime
      ? moment().diff(visitCompletedTime, 'hours') < 24
      : false;
    if (
      visit.status === PapiAppointmentStatus.SCHEDULED ||
      (visit.status === PapiAppointmentStatus.COMPLETED &&
        wasCompletedInLast24hours)
    ) {
      return true;
    }
    return false;
  }, [visit, visitCompletedTime]);

  const shouldShowDropdown = useMemo(() => {
    if (
      visit.status === PapiAppointmentStatus.SCHEDULED ||
      visit.status === PapiAppointmentStatus.UNSCHEDULED ||
      visit.status === PapiAppointmentStatus.COMPLETED
    ) {
      return true;
    }

    return false;
  }, [visit]);

  return (
    <Heading>
      <Row>
        <VisitIcon>
          {visit.provider?.sanityProfile?.imageSrc ? (
            <>
              <img alt="Provider" src={visit.provider.sanityProfile.imageSrc} />
              <SmallVisitIcon>
                <AIconVisit appointmentGroup={visit.details.group} />
              </SmallVisitIcon>
            </>
          ) : (
            <AIconVisit appointmentGroup={visit.details.group} />
          )}
        </VisitIcon>
        <span>
          {visit.details.name}
          {visit.provider
            ? ` with ${visit.provider.firstName} ${visit.provider.lastName}`
            : ''}
        </span>
      </Row>

      <Row>
        {(visit.status === PapiAppointmentStatus.UNSCHEDULED ||
          visit.status === PapiAppointmentStatus.SCHEDULED) &&
        !editMode ? (
          <>
            <ScheduleLinkButton onClick={() => setScheduleLinkOpen((v) => !v)}>
              <AIconLink />
            </ScheduleLinkButton>
            {scheduleLinkOpen ? (
              <ScheduleLink>
                <ScheduleLinkCard>
                  <ScheduleLinkeading>
                    My Parsley{' '}
                    {visit.status === PapiAppointmentStatus.UNSCHEDULED
                      ? 'scheduling'
                      : 'rescheduling'}{' '}
                    link:
                  </ScheduleLinkeading>
                  <ScheduleLinkForm>
                    <div>
                      <Input
                        ref={scheduleLinkText}
                        value={`${MY_PARSLEY_URL}${visit.portalScheduleURL}`}
                      />
                    </div>
                    <Button onClick={copySchedulingLink} type="primary">
                      Copy link
                    </Button>
                  </ScheduleLinkForm>
                </ScheduleLinkCard>
              </ScheduleLink>
            ) : null}
          </>
        ) : null}
        {showEditIcon && (
          <>
            {!editMode && shouldShowDropdown && (
              <Dropdown
                overlay={
                  <VisitCardEditMenu
                    editMode={editMode}
                    setEditMode={setEditMode}
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                    shouldShowCancel={shouldShowCancel}
                    shouldShowDelete={
                      visit.status === PapiAppointmentStatus.UNSCHEDULED
                    }
                  />
                }
                overlayStyle={{ zIndex: 999 }}
                placement="bottomRight"
                trigger={['click']}
              >
                <EditVisitButton>
                  <AIconEdit />
                </EditVisitButton>
              </Dropdown>
            )}
          </>
        )}
      </Row>

      <VisitDeleteModal
        appointmentID={visit.id}
        isVisible={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(null)}
        onSuccess={() => {
          onReloadVisits && onReloadVisits();
          setIsDeleteModalOpen(null);
          setEditMode(false);
        }}
      />
    </Heading>
  );
};

const VisitCardDetails: React.FC<{
  visit: MemberVisits_Query_visits;
}> = ({ visit }) => {
  return (
    <Details>
      <Detail>Duration: {visit.duration} minutes</Detail>
      {visit.format === PapiAppointmentFormat.IN_PERSON ? (
        <Detail>Location: In center ({visit.center?.name})</Detail>
      ) : visit.format === PapiAppointmentFormat.VIRTUAL ? (
        <Detail>Location: Online</Detail>
      ) : null}
      {visit.status === PapiAppointmentStatus.SCHEDULED ? (
        <Detail>
          Scheduled by:{' '}
          {visit.scheduledBy
            ? `${visit.scheduledBy.firstName} ${visit.scheduledBy.lastName}`
            : `From acuity`}{' '}
        </Detail>
      ) : null}
      {visit.isComplimentary ? <Detail>Complimentary</Detail> : null}
    </Details>
  );
};

const Icon = styled.img`
  margin-right: 10px;
`;

const Ribbon = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  height: 5px;
`;

const TextAreaWithLineBreaks = styled.p`
  white-space: pre-line;
`;

const CardBody = styled.div<{ editMode: boolean }>`
  background: ${(props) =>
    props.editMode ? theme.color.aliceBlue : theme.color.white};
  border-bottom: 1px solid ${theme.color.tableSectionGrey};
  border-left: 1px solid ${theme.color.tableSectionGrey};
  border-right: 1px solid ${theme.color.tableSectionGrey};
  padding: ${theme.space.l};
`;

const Heading = styled.div`
  align-items: center;
  display: flex;
  font-weight: ${theme.font.weight.medium};
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
`;

const RedText = styled.div`
  color: ${theme.color.crimson7};
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 7px;
`;

const DeleteText = styled(Menu.Item)`
  color: ${theme.color.crimson7};
  cursor: pointer;

  &#action-button: hover {
    background-color: ${theme.color.aquaSqueeze};
    color: ${theme.color.error} !important;
  }
`;

const EditText = styled(Menu.Item)`
  &:hover {
    background-color: ${theme.color.aquaSqueeze};
  }
`;

const ScheduleLinkButton = styled.div`
  cursor: pointer;
`;

const EditVisitButton = styled.div`
  cursor: pointer;
`;

const ScheduleLink = styled.div`
  border-radius: 10px;
  box-shadow: ${theme.layer.boxShadow.prepCard};
  position: absolute;
  right: 0px;
  top: 30px;
  width: 400px;
  z-index: 100;
`;

const ScheduleLinkCard = styled(ACard)``;

const ScheduleLinkeading = styled.div`
  font-weight: ${theme.font.weight.medium};
  margin-bottom: 5px;
`;

const PaddedButton = styled(Button)`
  margin-left: 5px;
`;

const FooterButtonDiv = styled.div<{ isBlocked: boolean }>`
  display: flex;
  justify-content: flex-end;

  ${({ isBlocked }) =>
    isBlocked &&
    `
    min-width:  153px;
  `}
`;

const ScheduleLinkForm = styled.div`
  display: flex;
  justify-content: space-between;
  > :first-child {
    flex: 0 0 70%;
    width: 70%;
  }
`;

const VisitIcon = styled.div`
  height: 40px;
  margin-right: 10px;
  min-width: 40px;
  position: relative;
  width: 40px;
  img {
    border-radius: 20px;
    display: block;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 1;
  }

  svg {
    position: absolute;
    z-index: 2;
  }
  &:after {
    background: #eeeeee;
    border-radius: 20px;
    content: '';
    display: block;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
  }
`;

const SmallVisitIcon = styled.div`
  bottom: -5px;
  height: 15px;
  position: absolute;
  right: -5px;
  width: 15px;
  svg {
    height: 100%;
    width: 100%;
  }
`;
const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;
const CheckContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: auto;
`;

const ComplimentaryText = styled.span`
  padding-top: 5px;
`;

const DurationText = styled.span`
  font-weight: ${theme.font.weight.medium};
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: -${theme.space.m};
  padding: ${theme.space.m} 0 0 0;
`;

const VistCardButton = styled(Button)`
  height: 40px;
  margin-right: 10px;
  width: 152px;
`;
const Details = styled.ul`
  margin-bottom: 0;
  padding-left: 18px;
`;

const Detail = styled.li`
  padding: 5px 0px;
`;

const ClickableText = styled.p`
  color: ${theme.color.parsleyGreen};
  cursor: pointer;
  margin: 0px;
  padding: 5px 0px;
`;

const Footer = styled.div<{ mode: PapiAppointmentStatus }>`
  background: ${theme.color.tableSectionGrey};
  color: ${(props) =>
    props.mode === PapiAppointmentStatus.COMPLETED
      ? theme.color.jade
      : theme.color.text};
  display: flex;
  font-weight: ${(props) =>
    props.mode === PapiAppointmentStatus.UNSCHEDULED
      ? theme.font.weight.regular
      : theme.font.weight.medium};
  justify-content: space-between;
  padding: ${theme.space.l};
`;
