import { useQuery } from '@apollo/react-hooks';
import { Skeleton } from 'antd';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { ATextClickable } from 'app/components/atoms/ATextClickable/ATextClickable';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { MemberPlanCard } from 'app/components/organisms/MemberPlanCard/MemberPlanCard';
import { PlanPurchaseCreate } from 'app/components/organisms/PlanPurchaseCreate/PlanPurchaseCreate';
import { theme } from 'app/styles/theme';
import {
  PapiPlanPurchaseState,
  PapiProductType,
} from 'app/types/generated/globalTypes';
import {
  MemberMembership_Query,
  MemberMembership_QueryVariables,
  MemberMembership_Query_member_allPlans_edges_node,
} from 'app/types/generated/MemberMembership_Query';

import { MEMBER_MEMBERSHIP_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  editable?: boolean;
  personID: string;
}

type PlanPurchaseWithExtensions = MemberMembership_Query_member_allPlans_edges_node & {
  extensions?: MemberMembership_Query_member_allPlans_edges_node[];
};

/** Displays a member's membership plans */
const MemberMembership: FC<Props> = ({ editable = true, personID }) => {
  const [showAllPlans, setShowAllPlans] = useState(false);
  const toggleAllPlans = (): void => setShowAllPlans((prev) => !prev);

  const { data, loading } = useQuery<
    MemberMembership_Query,
    MemberMembership_QueryVariables
  >(MEMBER_MEMBERSHIP_QUERY, { variables: { personID } });

  if (loading || !data || !data.member) {
    return (
      <LoadingStyles>
        <SkeletonStyled
          active
          paragraph={{ rows: 1, width: '40%' }}
          title={false}
        />
        <PlanPurchase>
          <MemberPlanCard loading />
        </PlanPurchase>
        <MemberPlanCard loading />
      </LoadingStyles>
    );
  }

  const { member } = data;
  const { allPlans, center, joinedPracticeDate } = member;
  const mergedPlans = allPlans
    ? mergeExtensions(allPlans.edges.map(({ node }) => node)) // HEAD = future
    : [];
  const [pastPlans, currAndFuturePlans] = partition(
    mergedPlans,
    (plan) =>
      plan.endDateIncludingExtensions &&
      DateTime.fromISO(plan.endDateIncludingExtensions) < DateTime.local()
  );

  const previousPlan = pastPlans[0];
  const isInsurancePreviousPlan =
    previousPlan?.purchaseState &&
    [
      PapiPlanPurchaseState.TRANSITION_FROM_INSURANCE,
      PapiPlanPurchaseState.TRANSITION_TO_INSURANCE,
    ].includes(previousPlan.purchaseState);

  if (isInsurancePreviousPlan) {
    pastPlans.shift();
  }

  return (
    <Styles>
      {joinedPracticeDate && (
        <ASectionBtmMargin>
          Member since{' '}
          {DateTime.fromISO(
            joinedPracticeDate,
            center ? { zone: center.timezone } : undefined
          ).toLocaleString(DateTime.DATE_FULL)}
        </ASectionBtmMargin>
      )}
      {isInsurancePreviousPlan && (
        <PlanPurchase key={previousPlan.id}>
          <MemberPlanCard
            editable={editable}
            extensions={previousPlan.extensions}
            planPurchase={previousPlan}
          />
        </PlanPurchase>
      )}

      {currAndFuturePlans.length > 0 && (
        <>
          {currAndFuturePlans.reverse().map((planPurchase) => (
            <PlanPurchase key={planPurchase.id}>
              <MemberPlanCard
                editable={editable}
                extensions={planPurchase.extensions}
                personID={member.id}
                planPurchase={planPurchase}
              />
            </PlanPurchase>
          ))}
          {pastPlans.length > 0 && (
            <PlanPurchasesToggle onClick={toggleAllPlans}>
              <ATextClickable>
                {showAllPlans ? 'Hide' : 'Show'} past care plans
              </ATextClickable>
            </PlanPurchasesToggle>
          )}
          {showAllPlans &&
            pastPlans.map((planPurchase) => (
              <PlanPurchase key={planPurchase.id}>
                <MemberPlanCard
                  editable={editable}
                  extensions={planPurchase.extensions}
                  planPurchase={planPurchase}
                />
              </PlanPurchase>
            ))}
        </>
      )}

      {currAndFuturePlans.length === 0 && pastPlans.length > 0 && (
        <>
          <PlanPurchase>
            <MemberPlanCard
              editable={editable}
              extensions={pastPlans[0].extensions}
              planPurchase={pastPlans[0]}
            />
          </PlanPurchase>
          {pastPlans.length > 1 && (
            <PlanPurchasesToggle onClick={toggleAllPlans}>
              <ATextClickable>
                {showAllPlans ? 'Hide' : 'Show'} all past care plans
              </ATextClickable>
            </PlanPurchasesToggle>
          )}
          {showAllPlans &&
            pastPlans.slice(1).map((planPurchase) => (
              <PlanPurchase key={planPurchase.id}>
                <MemberPlanCard
                  editable={editable}
                  extensions={planPurchase.extensions}
                  planPurchase={planPurchase}
                />
              </PlanPurchase>
            ))}
        </>
      )}

      {(!allPlans || allPlans.edges.length === 0) && (
        <>
          <ATextLight>No care plans, </ATextLight>
          <PlanPurchaseCreate
            openText={<ATextClickable>add one.</ATextClickable>}
            personID={member.id}
          />
        </>
      )}
    </Styles>
  );
};

// Styled components ////////////////////////////////
const PlanPurchase = styled.div`
  margin-bottom: ${theme.space.m};
  :last-child {
    margin-bottom: ${theme.space.l};
  }
`;

const PlanPurchasesToggle = styled.div`
  margin: -${theme.space.s} 0 ${theme.space.s} 0;
  text-align: right;
`;

const SkeletonStyled = styled(Skeleton)`
  &&& {
    margin-bottom: ${theme.space.m};
  }
`;

const Styles = styled.div`
  max-width: 500px;
`;
const LoadingStyles = styled(Styles)`
  margin-bottom: ${theme.space.xxl};
`;

// Helpers ////////////////////////////////
/**
 * Returns plan purchases from most recent to oldest, with extensions merged into
 * their corresponding plan purchases. Extensions are in order of oldest to most recent
 */
const mergeExtensions = (
  planPurchases: MemberMembership_Query_member_allPlans_edges_node[]
): PlanPurchaseWithExtensions[] =>
  sortBy(planPurchases, (plan) => DateTime.fromISO(plan.startTime)).reduce<
    PlanPurchaseWithExtensions[]
  >((accPlanPurchases, curr) => {
    // After sorting old -> new, stack more recent plans on top of older plans
    if (
      curr.plan.product.type !== PapiProductType.EXTENSION ||
      accPlanPurchases.length === 0
    ) {
      return [curr, ...accPlanPurchases];
    }

    // If curr plan is an extension, merge extension info into preceding plan
    const [lastPlanPurchase, ...restPlanPurchases] = accPlanPurchases;
    const lastPlanPurchaseWithExtensions = {
      ...lastPlanPurchase,
      extensions: (lastPlanPurchase.extensions || []).concat([curr]),
    };
    return [lastPlanPurchaseWithExtensions, ...restPlanPurchases];
  }, []);

export { MemberMembership, mergeExtensions };
