import gql from 'graphql-tag';

export const PaymentCardSetDefault_Fragment_card = gql`
  fragment PaymentCardSetDefault_Fragment_card on PapiPaymentCard {
    id
    deleted
    expiryMonth
    expiryYear
    last4Digits
    name
    personID
  }
`;
