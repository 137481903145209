import gql from 'graphql-tag';

export const VisitCard_Fragment = gql`
  fragment VisitCard_Fragment on PapiAppointment {
    id
    duration
    status
    format
    metadata
    displayOrder
    portalScheduleURL
    isComplimentary
    scheduledBy {
      id
      firstName
      lastName
    }
    center {
      id
      name
    }
    purchasePeriodID
    planPurchase {
      id
    }
    startTime
    location
    completedAt
    details {
      name
      description
      group
      displayColor
      group
    }
    provider {
      id
      firstName
      lastName
      type
      timezone
      sanityProfile {
        imageSrc
      }
    }
    availableWindows {
      end
    }
    encounterID
  }
`;
