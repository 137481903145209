import capitalize from 'lodash/capitalize';

import {
  PapiProfessionalSuffix,
  PapiProviderType,
} from 'app/types/generated/globalTypes';
import { ProviderName_Fragment } from 'app/types/generated/ProviderName_Fragment';

const MEDICARE_AGE = 65;
const NUMBER_OF_MONTH_TO_BUFFER = 6;
export const formatProfessionalSuffix = (suffix: string): string => {
  switch (suffix) {
    case PapiProfessionalSuffix.PHD:
      return 'PhD';
    case PapiProfessionalSuffix.PAC:
      return 'PA-C';
    default:
      return suffix;
  }
};

export const formatProviderName = (
  provider: Omit<ProviderName_Fragment, '__typename' | 'id'>,
  includePrefix: boolean
): string =>
  `${includePrefix && provider.type === PapiProviderType.DOCTOR ? 'Dr. ' : ''}${
    provider.firstName
  } ${provider.middleName ? provider.middleName[0].toUpperCase() + '. ' : ''}${
    provider.lastName
  }`;

export const formatProviderRole = (role: PapiProviderType): string => {
  switch (role) {
    case PapiProviderType.MXA:
      return role;

    case PapiProviderType.DOCTOR:
      return 'Clinician';

    case PapiProviderType.NURSE:
      return 'Nurse (RN)';

    default:
      return capitalize(role.replace('_', ' '));
  }
};

export const calculateAge = (dob: Date): number => {
  const diff_ms = Date.now() - dob.getTime();
  const age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

export const panelPermittedProviderTypes = (
  providerType: PapiProviderType | undefined
): boolean =>
  providerType === PapiProviderType.DOCTOR ||
  providerType === PapiProviderType.NURSE ||
  providerType === PapiProviderType.PEDIATRICIAN ||
  providerType === PapiProviderType.HEALTH_COACH;

const ageFromDOBWithBufferMonth = (
  dateOfBirth: string,
  bufferMonths: number | null
): number => {
  const birthDate = new Date(dateOfBirth);
  // It allows to calculate the age with buffer month
  if (bufferMonths) {
    birthDate.setMonth(birthDate.getMonth() - bufferMonths);
  }
  return calculateAge(birthDate);
};

const GREATER_THAN_RESTRICTED_AGE_TYPES = [
  PapiProviderType.DOCTOR as string,
  PapiProviderType.PEDIATRICIAN as string,
];

export const isMedicareOptedOut = (
  currentUserDateOfBirth: string | null | undefined,
  isMedicareOptedOutAttr: boolean,
  providerType: string | null
): boolean => {
  if (!currentUserDateOfBirth) {
    return false;
  }

  const age = ageFromDOBWithBufferMonth(
    currentUserDateOfBirth,
    NUMBER_OF_MONTH_TO_BUFFER
  );

  if (!providerType) {
    return false;
  }

  if (
    age >= MEDICARE_AGE &&
    (!isMedicareOptedOutAttr ||
      !GREATER_THAN_RESTRICTED_AGE_TYPES.includes(providerType))
  ) {
    return false;
  }

  return true;
};
