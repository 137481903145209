import React from 'react';

const DoctorIcon: React.FC = () => (
  <svg
    data-testid="doctorIcon"
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" fill="#7D9791" r="20" />
    <path
      d="M28.9841 21.1088C28.4311 21.1088 27.9826 21.5567 27.9826 22.1096C27.9826 22.6625 28.4311 23.1098 28.9841 23.1098C29.5377 23.1098 29.9851 22.6625 29.9851 22.1096C29.9851 21.5567 29.5377 21.1088 28.9841 21.1088ZM28.9841 18.3678C26.9205 18.3678 25.2405 20.047 25.2405 22.1113C25.2405 23.8735 26.4676 25.3519 28.1125 25.7452C27.7553 28.7426 25.2008 31.0765 22.1076 31.0765C18.9599 31.0765 16.3686 28.659 16.0857 25.5851C17.889 24.8616 23.1912 22.4128 23.1912 18.65V12.0362C23.1912 11.9834 23.1845 11.9328 23.1757 11.8844C23.0464 10.4242 21.9899 9.2292 20.5998 8.88643C20.542 8.48754 20.2014 8.18164 19.7865 8.18164C19.3303 8.18164 18.961 8.55137 18.961 9.00692V10.2124C18.961 10.6679 19.3303 11.0377 19.7865 11.0377C20.1029 11.0377 20.3742 10.8577 20.5129 10.597C21.1171 10.8737 21.5397 11.4795 21.5397 12.187V18.2396C20.394 19.1386 17.9925 19.7058 15.2531 19.7058H15.2184C12.4725 19.7009 10.066 19.1314 8.92415 18.239V12.187C8.92415 11.4811 9.34732 10.8737 9.95045 10.597C10.0891 10.8577 10.3604 11.0377 10.6768 11.0377C11.1325 11.0377 11.5023 10.6679 11.5023 10.2124V9.00692C11.5023 8.55137 11.1325 8.18164 10.6768 8.18164C10.2619 8.18164 9.92183 8.48919 9.86295 8.88643C8.47455 9.2292 7.41798 10.4247 7.28811 11.8844C7.27876 11.9339 7.27271 11.9851 7.27271 12.0362V18.65C7.27271 22.4562 12.6931 24.9167 14.4354 25.6082C14.7347 29.5833 18.0574 32.7271 22.1071 32.7271C26.1044 32.7271 29.3974 29.6664 29.7716 25.7672C31.4583 25.4041 32.7272 23.9027 32.7272 22.1107C32.7278 20.047 31.0488 18.3678 28.9841 18.3678ZM9.60651 20.4612C11.0863 21.0318 13.0332 21.352 15.214 21.3558C15.2272 21.3564 15.2405 21.3564 15.2542 21.3564C17.4306 21.3564 19.3726 21.0378 20.8529 20.4656C19.5542 22.2163 16.7296 23.5863 15.2306 24.1491C13.7315 23.5847 10.9041 22.2125 9.60651 20.4612ZM28.9841 24.202C27.8302 24.202 26.8913 23.265 26.8913 22.1113C26.8913 20.957 27.8302 20.0183 28.9841 20.0183C30.1386 20.0183 31.0769 20.957 31.0769 22.1113C31.0769 23.2633 30.1375 24.202 28.9841 24.202Z"
      fill="white"
    />
  </svg>
);

export default DoctorIcon;
