import { useQuery } from '@apollo/react-hooks';
import React, { FC } from 'react';

import {
  ASelect,
  ASelectProps,
  IASelectOption,
} from 'app/components/atoms/ASelect/ASelect';
import {
  ASelectCareManager_Query,
  ASelectCareManager_QueryVariables,
} from 'app/types/generated/ASelectCareManager_Query';
import { PapiProviderType } from 'app/types/generated/globalTypes';

import { A_SELECT_CARE_MANAGER } from './query';

export const getCareManagerLabel = (i: {
  firstName?: string | null;
  lastName?: string | null;
  professionalSuffix?: string | null;
}): string =>
  `${i.professionalSuffix ?? ''} ${i.firstName ?? ''} ${i.lastName ?? ''}`;

export const ASelectCareManager: FC<Omit<ASelectProps, 'selectOptions'>> = ({
  ...props
}) => {
  const careManagerList = useQuery<
    ASelectCareManager_Query,
    ASelectCareManager_QueryVariables
  >(A_SELECT_CARE_MANAGER, {
    fetchPolicy: 'network-only',
    variables: {
      providerType: PapiProviderType.CARE_MANAGER,
    },
  });
  
  const nureList = useQuery<
    ASelectCareManager_Query,
    ASelectCareManager_QueryVariables
  >(A_SELECT_CARE_MANAGER, {
    fetchPolicy: 'network-only',
    variables: {
      providerType: PapiProviderType.NURSE,
    },
  });

  const listCM = careManagerList.data?.listProvidersByType ?? [];
  const listNurse = nureList.data?.listProvidersByType ?? [];

  let listSeletionOptions = [...listCM, ...listNurse];
  
  return (
    <ASelect
      {...props}
      label="Assign a Care Manager"
      loading={careManagerList.loading || nureList.loading}
      selectOptions={[
        {
          label: 'None',
          value: null,
        },
        ...(listSeletionOptions?.map(
          (i) =>
            ({
              label: getCareManagerLabel(i),
              value: i.id,
            } as IASelectOption)
        ) ?? []),
      ]}
    />
  );
};
