import { useQuery } from '@apollo/react-hooks';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { ATextClickable } from 'app/components/atoms/ATextClickable/ATextClickable';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { MemberNewInsuranceCard } from 'app/components/organisms/InsuranceCard/InsuranceCard';
import { MemberInsuranceCard } from 'app/components/organisms/MemberInsuranceCard/MemberInsuranceCard';
import { MemberPaymentCard } from 'app/components/organisms/MemberPaymentCard/MemberPaymentCard';
import { PaymentCardAdd } from 'app/components/organisms/PaymentCardAdd/PaymentCardAdd';
import { theme } from 'app/styles/theme';
import {
  MemberPayment_Query,
  MemberPayment_QueryVariables,
} from 'app/types/generated/MemberPayment_Query';

import { MEMBER_PAYMENT_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  editable?: boolean;
  personID: string;
}

/** Displays a member's payment methods and insurance */
const MemberPayment: FC<Props> = ({ editable = true, personID }) => {
  const { data, loading } = useQuery<
    MemberPayment_Query,
    MemberPayment_QueryVariables
  >(MEMBER_PAYMENT_QUERY, { variables: { personID } });

  if (loading || !data || !data.member) {
    return (
      <Styles>
        <PaymentCards>
          <MemberPaymentCard loading />
        </PaymentCards>
        <MemberInsuranceCard personID={personID} />
      </Styles>
    );
  }

  return (
    <Styles>
      {data.member.paymentCards.edges.length > 0 ? (
        <PaymentCards>
          {data.member.paymentCards.edges
            .sort((cardA, cardB) =>
              cardA.node.deleted && cardB.node.deleted
                ? 0
                : cardA.node.deleted
                ? 1
                : -1
            )
            .map(({ node }) => (
              <PaymentCardContainer key={node.id}>
                <MemberPaymentCard card={node} editable={editable} />
              </PaymentCardContainer>
            ))}
        </PaymentCards>
      ) : (
        <ASectionBtmMarginStyled>
          <ATextLight>No payment cards on file, </ATextLight>
          <PaymentCardAdd
            openText={<ATextClickable>add one.</ATextClickable>}
            personID={personID}
          />
        </ASectionBtmMarginStyled>
      )}
      <MemberInsuranceCard editable={editable} personID={personID} />
      <MemberNewInsuranceCard personId={personID} />
    </Styles>
  );
};

// Styled components ////////////////////////////////
const ASectionBtmMarginStyled = styled(ASectionBtmMargin)`
  &&& {
    width: 100%;
  }
`;

const PaymentCardContainer = styled.div`
  :not(:last-child) {
    margin-bottom: ${theme.space.s};
  }
`;

const PaymentCards = styled.div`
  margin: 0 ${theme.space.m} ${theme.space.m} 0;
  ${theme.layout.breakpointMixin.tabletPortraitDown} {
    flex-direction: column;
  }
`;

const Styles = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: ${theme.space.s};
  ${theme.layout.breakpointMixin.tabletPortraitDown} {
    flex-flow: column wrap;
  }
`;

export { MemberPayment };
