import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

export const ATextError = styled.span`
  color: ${theme.color.error};
  * {
    color: ${theme.color.error};
  }
`;
