import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

// Types ////////////////////////////////
export interface AFormFieldWrapperProps {
  antd?: boolean;
  customWidth?: {
    fixed?: string;
    max?: string;
    percent?: number;
  };
  show?: boolean;
}

/** Wrap form fields and use percentWidth to alter width */
export const AFormFieldWrapper = styled.div<AFormFieldWrapperProps>`
  display: ${({ show = true }) => (show ? 'block' : 'none')};
  margin-bottom: ${({ antd }) => (antd ? -theme.space.xxs : theme.space.xxs)};
  max-width: ${({ customWidth }) => customWidth?.max};
  width: ${({ customWidth }) => {
    if (!customWidth || (!customWidth.fixed && !customWidth.percent)) {
      return 'auto';
    }
    return customWidth.fixed || `${customWidth.percent}%`;
  }};
  ${theme.layout.breakpointMixin.phoneOnly} {
    max-width: 100%;
  }
`;
