import { animationTheme as animation } from './animation';
import { colorTheme as color } from './color';
import { fontTheme as font } from './font';
import { layerTheme as layer } from './layer';
import { layoutTheme as layout } from './layout';
import { spaceTheme as space } from './space';

export const theme = {
  animation,
  color,
  font,
  layer,
  layout,
  space,
};
