import { message } from 'antd';
import { ApolloError } from 'apollo-client';
import { ErrorResponse } from 'apollo-link-error';

import { IFeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { IViewerContext } from 'app/contexts/ViewerContext';
import { IRouteConfig } from 'constants/app';
import { API_URL } from 'constants/env';
import { STATUS_MESSAGE } from 'constants/message';

export const displayErrors = (
  error: ApolloError | ErrorResponse,
  generalMessage?: string
): void => {
  if (error.networkError) {
    message.error(generalMessage || STATUS_MESSAGE.error.catchAll);
  }
  if (error.graphQLErrors) {
    error.graphQLErrors.forEach((err) => message.error(err.message));
  }
  if ('message' in error && !error.networkError && !error.graphQLErrors) {
    message.error(error.message);
  }
};

export const displayAndLogErrors = (
  error: ApolloError | ErrorResponse,
  generalMessage?: string
): void => {
  logErrors(error);
  displayErrors(error, generalMessage);
};

export const isStaging = (): boolean => API_URL.includes('staging');

export const logErrors = (error: ApolloError | ErrorResponse): void =>
  console.error({ error });

/** Return true if route is not role-restricted nor hidden by a feature flag, or viewer passes all restrictions */
export const shouldShowRoute = (
  route: IRouteConfig,
  viewer: Pick<IViewerContext, 'role'>,
  featureFlags: IFeatureFlagContext
): boolean =>
  (!route.restrictedTo ||
    (!!viewer.role && route.restrictedTo.includes(viewer.role))) &&
  (!route.featureFlag || !!featureFlags.variation(route.featureFlag, false));

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
