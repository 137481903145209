import { Resolvers } from 'apollo-client';

export const resolvers: Resolvers = {
  Query: {
    listInboxItems: () => {
      return [
        {
          __typename: 'PapiInboxItem',
          category: null,
          createdAt: '',
          documentName: 'sth',
          foreignID: '123',
          id: '1',
          isUploadedToMDHQ: false,
          originalData: '{}',
          person: null,
          personID: null,
          source: 'HEALTH_GORILLA_DIAGNOSTIC_REPORT',
          sourceCategory: 'LABORATORY',
          triagedAt: null,
          triagedByID: null,
          triageStatus: 'NEW',
          updatedAt: '',
          url: 'http://example.com',
        },
      ];
    },
  },
};
