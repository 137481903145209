import gql from 'graphql-tag';

import { MemberHeader_Fragment_member } from './fragment';

export const MEMBER_HEADER_QUERY = gql`
  query MemberHeader_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      ...MemberHeader_Fragment_member
      currentPlan {
        purchaseState
        plan {
          consumerType
        }
      }
      nextPlan {
        purchaseState
        plan {
          consumerType
        }
      }
    }
    partner: getPersonV2(id: $personID) {
      partner {
        displayName
      }
    }
  }
  ${MemberHeader_Fragment_member}
`;

export const MEMBER_HEADER_BIO_QUERY = gql`
  query MemberHeader_Bio_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      ...MemberHeader_Fragment_member
    }
    partner: getPersonV2(id: $personID) {
      partner {
        displayName
      }
    }
  }
  ${MemberHeader_Fragment_member}
`;
