import gql from 'graphql-tag';

export const GET_PERSON_FIELDS_BY_ID = gql`
  query getPersonFieldsById($id: ID!) {
    getPerson(id: $id) {
      firstName
      lastName
      dateOfBirth
      addressLine1
      addressLine2
      addressState
      addressCity
      addressPostalCode
      biologicalSex
    }
  }
`;

export const GET_SUBSCRIBER_RELATIONSHIP = gql`
  query getSubscriberRelationshipTypes {
    getRelationshipTypes {
      id
      description
    }
  }
`;

export const SEARCH_INSURANCE_COMPANY = gql`
  query searchInsuranceCompany($search: String!) {
    searchInsuranceCompany(search: $search) {
      id
      nationalPayerId
      payerName
      accepted
    }
  }
`;

export const ADD_INSURANCE_SUBSCRIBER = gql`
  mutation addInsuranceSubscriber($input: PapiAddInsuranceSubscriberInput!) {
    addInsuranceSubscriber(input: $input) {
      id
    }
  }
`;

export const UPDATE_INSURANCE_SUBSCRIBER = gql`
  mutation updateInsuranceSubscriber(
    $input: PapiUpdateInsuranceSubscriberInput!
  ) {
    updateInsuranceSubscriber(input: $input) {
      id
    }
  }
`;

export const GET_INSURANCE_SUBSCRIBER_BY_ID = gql`
  query getInsuranceSubscriberById($id: ID!) {
    getInsuranceSubscriberById(id: $id) {
      id
      personId
      relationshipToPatient
      policyNumber
      payerId
      groupId
      planId
      planStartDate
      planEndDate
      firstName
      middleName
      lastName
      dateOfBirth
      addressLine1
      addressLine2
      city
      state
      zipCode
      sex
      country
      copayAmountInCents
    }
  }
`;

export const GET_INSURANCE_SUBSCRIBER_BY_PERSON_ID = gql`
  query getInsuranceSubscriberByPersonId($personId: ID!, $active: Boolean) {
    getInsuranceSubscriberByPersonId(personId: $personId, active: $active) {
      id
      personId
      relationshipToPatient
      policyNumber
      payerId
      groupId
      planId
      planStartDate
      planEndDate
      firstName
      middleName
      lastName
      dateOfBirth
      addressLine1
      addressLine2
      city
      state
      zipCode
      sex
      country
      copayAmountInCents
      payerDetails {
        __typename
        payerName
        id
      }
    }
  }
`;
