import gql from 'graphql-tag';

export const A_SELECT_PROVIDER_PRODUCT_QUERY = gql`
  query ASelectProviderProduct_Query {
    products: listRegisteredProducts {
      displayName
      type
    }
  }
`;
