import { ApolloProvider } from '@apollo/react-hooks';
import { Firebase, useFirebase } from '@parsleyhealth/front-end-utils';
import { uuid4 } from '@sentry/utils';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import React, { useMemo } from 'react';

import fragmentTypes from 'app/types/generated/fragmentTypes';
import { logErrors } from 'app/utils/app';
import { NAME, VERSION } from 'constants/env';
import { GRAPHQL_URL } from 'constants/url';
import { resolvers } from 'schema-mocks/resolvers';
import { typeDefs } from 'schema-mocks/typeDefs';

function makeApolloClient(firebase: Firebase): ApolloClient<any> {
  // Initialize Apollo GraphQL client
  const transactionID = uuid4();

  const httpLink = createHttpLink({
    headers: {
      'X-Transaction-ID': transactionID,
    },
    uri: GRAPHQL_URL,
  });

  const authLink = setContext(async () => {
    if (firebase.auth.currentUser) {
      const idToken = await firebase.auth.currentUser.getIdToken();
      return { headers: { Authorization: idToken && `Bearer ${idToken}` } };
    }
    return {};
  });

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: fragmentTypes,
      }),
    }),
    link: onError(logErrors).concat(authLink).concat(httpLink),
    name: NAME,
    resolvers,
    typeDefs,
    version: VERSION,
  });

  return apolloClient;
}

const GqlProvider: React.FC = ({ children }) => {
  const firebase = useFirebase();
  const client = useMemo(() => makeApolloClient(firebase), [firebase]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GqlProvider;
