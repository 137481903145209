/* eslint-disable */

import { isStaging } from 'app/utils/app';

const color = {
  // Parsley
  parsleyGreen: '#64bcae',
  parsleyMediumGreen: '#91d8cb',
  parsleyLightGreen: '#b4ded6',
  aquaSqueeze: '#eff8f6',
  pearLusta: '#fcf8e3',
  aliceBlue: '#F9FCFE',
  crimson7: '#A3070F',

  // Whites
  whiteGreen: '#E2EEEC',

  // Brand Spring 2019
  darkGrey: '#4d4d4f',
  middleGrey: '#808080',
  lightGrey: '#dcddde',
  jade: '#488174',
  night: '#024266',
  indigo: '#2f3578',
  lapis: '#2d60c6',
  quartz: '#b7d1de',
  ruby: '#7c2d35',
  plum: '#9a526a',
  pink: '#c18c9c',
  crimson: '#c65053',
  amber: '#b56236',
  marigold: '#f4a721',
  cloud: '#f1f6ff',
  lightCloud: '#F9FCFE',

  // Grays
  heavyMetal: '#212220',
  silverChalice: '#a6a6a6',
  gainsboro: '#d9d9d9',
  gallery: '#f0f0f0',
  alabaster: '#fafafa',
  tableSectionGrey: '#EFEFEF',

  // Greens
  grayGreen: '#7D9791',
  aquaGreen: '#4f7f71',

  // Blues
  blue1: '#6483a0',

  // Primary colors
  red: '#c65053',
  lightRed: '#FDEAE7',
  orange: '#fd7e14',
  yellow: '#ffc107',
  lightYellow: '#FFDDAF',
  green: '#28a745',
  teal: '#20c997',
  cyan: '#17a2b8',
  blue: '#007bff',
  purple: '#6f42c1',
  white: '#FFFFFF',
  errorRed: '#f5222d',
};

const componentColor = {
  background: color.alabaster,
  backgroundHover: color.aquaSqueeze,
  border: color.gainsboro,
  boxShadow: `${color.lightGrey}80`,
  boxShadowGreen: `${color.parsleyLightGreen}40`,
  error: `${color.errorRed}`, // antd error color
  navbar: isStaging() ? color.marigold : color.jade,
  primary: color.parsleyGreen,
  primaryLight: color.parsleyMediumGreen,
  success: color.parsleyGreen,
  text: color.darkGrey,
  textLight: color.middleGrey,
  textLighter: color.silverChalice,
  textLightest: color.lightGrey,
  warning: color.marigold,
};

const calendar = {
  border: componentColor.border,
  mask: `rgb(0, 0, 0, 0.2)`,
  text: componentColor.textLight,
};

export const colorTheme = { ...color, ...componentColor, calendar };
