import { useLazyQuery } from '@apollo/react-hooks';
import { Icon, Progress, Skeleton, Tooltip } from 'antd';
import compact from 'lodash/compact';
import { DateTime } from 'luxon';
import React, { FC, useState, useEffect, useContext } from 'react';
import styled from 'styled-components/macro';

import { ACard } from 'app/components/atoms/ACard/ACard';
import { AFlexDivider } from 'app/components/atoms/AFlexDivider/AFlexDivider';
import { AFlexRowToColumn } from 'app/components/atoms/AFlexRowToColumn/AFlexRowToColumn';
import { AIconClickable } from 'app/components/atoms/AIconClickable/AIconClickable';
import { AIconThirdParty } from 'app/components/atoms/AIconThirdParty/AIconThirdParty';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { PlanPurchaseEndOrCancel } from 'app/components/organisms/PlanPurchaseEndOrCancel/PlanPurchaseEndOrCancel';
import { PlanPurchaseEnrollFromWaitlist } from 'app/components/organisms/PlanPurchaseEnrollFromWaitlist/PlanPurchaseEnrollFromWaitlist';
import { PlanPurchaseExtend } from 'app/components/organisms/PlanPurchaseExtend/PlanPurchaseExtend';
import { PlanPurchasePause } from 'app/components/organisms/PlanPurchasePause/PlanPurchasePause';
import { PlanPurchaseSwitchInsurance } from 'app/components/organisms/PlanPurchaseSwitchInsurance/PlanPurchaseSwitchInsurance';
import { PlanPurchaseUpdate } from 'app/components/organisms/PlanPurchaseUpdate/PlanPurchaseUpdate';
import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { theme } from 'app/styles/theme';
import {
  CurrentPurchase_Query,
  CurrentPurchase_QueryVariables,
} from 'app/types/generated/CurrentPurchase_Query';
import { GetAvailableInsuranceChangesForPerson_Query } from 'app/types/generated/GetAvailableInsuranceChangesForPerson_Query';
import { GetIsMemberEligibleForInsuranceFlow_Query } from 'app/types/generated/GetIsMemberEligibleForInsuranceFlow_Query';
import {
  PapiConsumerType,
  PapiInsuranceType,
  PapiPreEligibilityStatus,
  PapiStripeSubscriptionStatus,
} from 'app/types/generated/globalTypes';
import { ListAvailableInsuranceChanges_Query } from 'app/types/generated/ListAvailableInsuranceChanges_Query';
import { MemberPlanCard_Fragment_planPurchase } from 'app/types/generated/MemberPlanCard_Fragment_planPurchase';
import { PlanPurchaseEndOrCancel_Fragment_extension } from 'app/types/generated/PlanPurchaseEndOrCancel_Fragment_extension';
import { PlanPurchaseExtend_Fragment_extension } from 'app/types/generated/PlanPurchaseExtend_Fragment_extension';
import { getCenterDisplayNameWithIcon } from 'app/utils/center';
import { getCouponDescription } from 'app/utils/coupon';
import { getPlanPaymentRate, getPlanPurchaseStatus } from 'app/utils/plan';
import { DateFormat } from 'constants/datetime';

import {
  CURRENT_PURCHASE_QUERY,
  LIST_AVAILABLE_INSURANCE_CHANGES_QUERY,
  GET_AVAILABLE_INSURANCE_CHANGES_FOR_PERSON_QUERY,
  GET_IS_MEMBER_ELIGIBLE_FOR_INSURANCE_FLOW,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  editable?: boolean;
  extensions?: Array<
    PlanPurchaseExtend_Fragment_extension &
      PlanPurchaseEndOrCancel_Fragment_extension
  >;
  loading?: boolean;
  personID?: string;
  planPurchase?: MemberPlanCard_Fragment_planPurchase;
}

/** Display member's plan with editing options in dropdown */
const MemberPlanCard: FC<Props> = ({
  editable = true,
  extensions,
  loading,
  personID,
  planPurchase,
}) => {
  const featureFlags = useContext(FeatureFlagContext);
  const useNewInsuranceQuery = featureFlags.variation(
    'dr-p-cash-pay-switch-in-network-cc'
  );
  const [showRenewalPeriods, setShowRenewalPeriods] = useState(false);
  // remove this query when removing Launch Darkly flag dr-p-cash-pay-switch-in-network-cc
  const [listAvailableInsuranceChanges, { data }] = useLazyQuery<
    ListAvailableInsuranceChanges_Query
  >(LIST_AVAILABLE_INSURANCE_CHANGES_QUERY);
  const [
    availableInsuranceChangesForPerson,
    { data: availableInsuranceChangesForPersonData },
  ] = useLazyQuery<GetAvailableInsuranceChangesForPerson_Query>(
    GET_AVAILABLE_INSURANCE_CHANGES_FOR_PERSON_QUERY
  );
  const [listCurrentPurchase, { data: currentPurchase }] = useLazyQuery<
    CurrentPurchase_Query,
    CurrentPurchase_QueryVariables
  >(CURRENT_PURCHASE_QUERY);

  const [
    isMemberEligibleForInsuranceFlow,
    { data: isMemberEligibleData },
  ] = useLazyQuery<GetIsMemberEligibleForInsuranceFlow_Query>(
    GET_IS_MEMBER_ELIGIBLE_FOR_INSURANCE_FLOW
  );

  const toggleRenewalPeriods = (): void =>
    setShowRenewalPeriods((prev) => !prev);

  useEffect(() => {
    if (useNewInsuranceQuery && personID) {
      isMemberEligibleForInsuranceFlow({ variables: { personID } });
    }
  }, [isMemberEligibleForInsuranceFlow, personID, useNewInsuranceQuery]);

  useEffect(() => {
    if (planPurchase) {
      const planID = planPurchase?.plan.id;
      const planPurchaseStatus = getPlanPurchaseStatus(planPurchase);
      const isCurrent =
        planPurchaseStatus === 'In progress' ||
        planPurchaseStatus === 'On extension' ||
        planPurchaseStatus === 'Paused';

      if (planID && isCurrent) {
        if (useNewInsuranceQuery) {
          if (personID) {
            availableInsuranceChangesForPerson({ variables: { personID } });
          }
        } else {
          listAvailableInsuranceChanges({ variables: { planID } });
        }

        if (personID) {
          listCurrentPurchase({ variables: { personID } });
        }
      }
    }
  }, [
    listCurrentPurchase,
    listAvailableInsuranceChanges,
    availableInsuranceChangesForPerson,
    personID,
    planPurchase,
    useNewInsuranceQuery,
  ]);

  if (loading || !planPurchase) {
    return (
      <ACard>
        <SkeletonStyled
          active
          paragraph={{ rows: 4, width: ['30%', '100%', '50%', '100%'] }}
          title={false}
        />
      </ACard>
    );
  }

  const {
    center,
    coupon,
    endDateIncludingExtensions,
    endTime,
    latestPause,
    legacyHintMembershipID,
    nextBillDate,
    nextRenewalDate,
    paymentStatus,
    plan,
    renewalPeriods, // sorted by DAPI with HEAD = most recent
    startTime,
    stripeSubscriptionStatus,
  } = planPurchase;

  let availablePlan;
  let isMemberEligible = true;
  if (useNewInsuranceQuery) {
    availablePlan =
      availableInsuranceChangesForPersonData?.getAvailableInsuranceChangesForPerson;
    isMemberEligible =
      isMemberEligibleData?.getIsMemberEligibleForInsuranceFlow ===
        PapiPreEligibilityStatus.ELIGIBLE ||
      availablePlan?.insuranceType === PapiInsuranceType.CASH_PAY;
  } else {
    availablePlan = data?.listAvailableInsuranceChanges.find(
      (p) => p.insuranceType !== planPurchase.plan.insuranceType
    );
  }

  const planPurchaseStatus = getPlanPurchaseStatus(planPurchase);
  const isCurrent =
    planPurchaseStatus === 'In progress' ||
    planPurchaseStatus === 'On extension' ||
    planPurchaseStatus === 'Paused';
  const isPaused = planPurchaseStatus === 'Paused';
  const hasUpcomingPause = latestPause
    ? DateTime.fromISO(latestPause.startDate) > DateTime.local()
    : false;
  const isEnded =
    planPurchaseStatus === 'Ended' ||
    planPurchaseStatus === 'Switched to Cash Pay' ||
    planPurchaseStatus === 'Switched to In-Network';
  const isComplimentary = planPurchase.plan.paymentRateInCents === 0;

  // Don't show the 'Complimentary' detail for B2B users (their services are paid for through contracts)
  // The 'Complimentary' detail should only be used for members that are receiving complimentary PH services (e.g., PH employees, influencers, etc.)
  const hideComplimentaryDetail =
    planPurchase?.plan?.consumerType === PapiConsumerType.ENTERPRISE_PARTNER &&
    isComplimentary;

  const isWaitlist = plan.product.type.includes('WAITLIST');

  // Stripe creates next bills even if the plan will end before the next bill.
  // Don't show a next bill if it occurs within a day of/after the plan end
  const diffEndTimeToNextBill =
    !!endTime &&
    !!nextBillDate &&
    DateTime.fromISO(nextBillDate!).diff(DateTime.fromISO(endTime!), 'days');
  const nextBillInvalid =
    diffEndTimeToNextBill && diffEndTimeToNextBill.days > -1;
  const unpaidStatus =
    stripeSubscriptionStatus === PapiStripeSubscriptionStatus.PAST_DUE
      ? 'Payment past due'
      : (stripeSubscriptionStatus || '').includes('INCOMPLETE')
      ? 'Initial payment failed'
      : undefined;

  const items = [
    editable && !isEnded && <PlanPurchaseUpdate planPurchase={planPurchase} />,
    editable && isMemberEligible && availablePlan && (
      <PlanPurchaseSwitchInsurance
        plan={availablePlan}
        planPurchase={planPurchase}
      />
    ),
    editable && isCurrent && isWaitlist && (
      <PlanPurchaseEnrollFromWaitlist waitlistPlanPurchase={planPurchase} />
    ),
    editable && isCurrent && !isWaitlist && (
      <PlanPurchaseExtend extensions={extensions} planPurchase={planPurchase} />
    ),
    editable && isCurrent && !isWaitlist && (
      <PlanPurchasePause planPurchase={planPurchase} />
    ),
    editable && !isEnded && (
      <PlanPurchaseEndOrCancel
        extensions={extensions}
        planPurchase={planPurchase}
      />
    ),
  ];

  const dropdownItems = compact(items);

  return (
    <ACard
      dropdownItems={dropdownItems}
      error={!!unpaidStatus}
      status={planPurchaseStatus}
      title={
        <>
          {legacyHintMembershipID && (
            <HintIcon data-testid="MemberPlanCard_legacy">
              <AIconThirdParty alt="Hint care plan" service="hint" />
            </HintIcon>
          )}
          {plan.product.type.includes('WAITLIST')
            ? plan.displayName.split('-')[0]?.trim() ?? plan.displayName
            : plan.product.displayName}
          {planPurchase.plan.insuranceType === PapiInsuranceType.CASH_PAY
            ? ' (Cash Pay)'
            : ' (In-Network)'}
        </>
      }
    >
      <Center>{getCenterDisplayNameWithIcon(center)}</Center>
      <Progress
        percent={getProgress(planPurchase)}
        showInfo={false}
        size="small"
        status={isCurrent && !isPaused ? 'active' : 'normal'}
        strokeColor={
          isEnded
            ? theme.color.textLight
            : planPurchaseStatus === 'On extension'
            ? theme.color.primary
            : isPaused
            ? theme.color.textLightest
            : theme.color.primaryLight
        }
      />
      <DateDetails>
        <div>
          {planPurchaseStatus === 'Enrolled'
            ? 'Starts '
            : !endDateIncludingExtensions && 'Started '}
          {DateTime.fromISO(
            renewalPeriods.length > 1 ? renewalPeriods[0].startDate : startTime,
            { zone: center.timezone }
          ).toFormat(DateFormat.Shortest)}
          {endTime &&
            ` – ${DateTime.fromISO(endTime, { zone: center.timezone }).toFormat(
              DateFormat.Shortest
            )}`}

          {endDateIncludingExtensions &&
            endDateIncludingExtensions !== endTime &&
            ` (extended to ${DateTime.fromISO(endDateIncludingExtensions, {
              zone: center.timezone,
            }).toFormat(DateFormat.Shortest)})`}
          {(isPaused || hasUpcomingPause) &&
            ` (pause${isPaused ? 'd' : 's'} ${DateTime.fromISO(
              planPurchase.latestPause!.startDate,
              {
                zone: center.timezone,
              }
            ).toFormat(DateFormat.Shortest)} – ${DateTime.fromISO(
              planPurchase.latestPause!.endDate,
              {
                zone: center.timezone,
              }
            ).toFormat(DateFormat.Shortest)})`}
        </div>

        {!endDateIncludingExtensions && nextRenewalDate && (
          <div>
            Renews{' '}
            {DateTime.fromISO(
              currentPurchase?.memberCurrentPurchase?.currentPurchase
                ?.purchasePeriodEndTime ?? nextRenewalDate,
              {
                zone: center.timezone,
              }
            ).toFormat(DateFormat.Shortest)}
          </div>
        )}
      </DateDetails>

      <Details>
        <div>
          {!hideComplimentaryDetail && getPlanPaymentRate(plan)}
          {coupon && (
            <Tooltip
              placement="bottomLeft"
              title={`${coupon.slug}: ${getCouponDescription(coupon)}`}
            >
              <CouponIcon data-testid="MemberPlanCard_coupon" type="tag" />
            </Tooltip>
          )}
        </div>
        {!isEnded && !isComplimentary && (
          <>
            <AFlexDivider type="vertical" />
            <div>
              Next bill:{' '}
              {nextBillDate && !nextBillInvalid ? (
                `${DateTime.fromISO(nextBillDate, {
                  zone: center.timezone,
                }).toFormat(DateFormat.Shortest)}`
              ) : (
                <ATextLight>None</ATextLight>
              )}
            </div>
            <AFlexDivider type="vertical" />
          </>
        )}
        <Status error={!!unpaidStatus}>
          {unpaidStatus ||
            (isEnded || isComplimentary
              ? 'Payment complete'
              : `Payment ${paymentStatus.replace(/_/g, ' ').toLowerCase()}`)}
        </Status>
      </Details>

      {renewalPeriods.length > 1 && (
        <RenewalDetails show={showRenewalPeriods}>
          <RenewalPeriodsToggleContainer>
            <RenewalPeriodsToggle
              data-testid="MemberPlanCard_toggleRenewalPeriods"
              isOpen={showRenewalPeriods}
              onClick={toggleRenewalPeriods}
              type="double-right"
            />
          </RenewalPeriodsToggleContainer>
          {showRenewalPeriods && (
            <RenewalPeriods>
              {renewalPeriods.slice(1).map((period) => (
                <div>
                  <Progress
                    percent={100}
                    showInfo={false}
                    size="small"
                    strokeColor={
                      isEnded ? theme.color.textLight : theme.color.primary
                    }
                  />
                  {DateTime.fromISO(period.startDate, {
                    zone: center.timezone,
                  }).toFormat(DateFormat.Shortest)}{' '}
                  –{' '}
                  {DateTime.fromISO(period.endDate, {
                    zone: center.timezone,
                  }).toFormat(DateFormat.Shortest)}
                </div>
              ))}
            </RenewalPeriods>
          )}
        </RenewalDetails>
      )}
    </ACard>
  );
};

// Styled components ////////////////////////////////
const Center = styled.div`
  font-size: ${theme.font.size.s};
  margin-bottom: -${theme.space.xs};
`;

const Details = styled(AFlexRowToColumn)`
  &&& {
    font-size: ${theme.font.size.s};
    justify-content: space-between;
    margin-top: ${theme.space.xs};
  }
`;
const DateDetails = styled(Details)`
  &&& {
    margin-top: -${theme.space.xs};
  }
`;

const IconStyled = styled(Icon)`
  &&& {
    bottom: ${theme.space.xxs};
    position: relative;
    vertical-align: baseline;
  }
`;
const CouponIcon = styled(IconStyled)`
  margin-left: ${theme.space.xs};
`;
const HintIcon = styled.span`
  margin-right: ${theme.space.s};
`;

const RenewalDetails = styled.div<{ show: boolean }>`
  display: flex;
  flex-direction: ${({ show }) => (show ? 'column-reverse' : 'column')};
`;
const RenewalPeriods = styled.div`
  font-size: ${theme.font.size.s};
  margin-top: ${theme.space.m};
  opacity: 0.6;
`;
const RenewalPeriodsToggle = styled(AIconClickable)<{ isOpen: boolean }>`
  &&& {
    color: ${theme.color.textLighter};
    transform: rotate(${({ isOpen }) => (isOpen ? '-90deg' : '90deg')});
    i {
      width: ${theme.space.m};
    }
  }
`;
const RenewalPeriodsToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${theme.space.s} 0 -${theme.space.s} 0;
`;

const SkeletonStyled = styled(Skeleton)`
  margin: ${theme.space.xs} 0 ${theme.space.xxs} 0;
  &&& {
    li:not(:last-of-type) {
      margin-bottom: -${theme.space.xs};
    }
  }
`;

const Status = styled.div<{ error: boolean }>`
  color: ${({ error }) => (error ? theme.color.error : 'inherit')};
`;

// Helpers ////////////////////////////////
const getProgress = (
  planPurchase: Pick<
    MemberPlanCard_Fragment_planPurchase,
    | 'endDateIncludingExtensions'
    | 'endTime'
    | 'latestPause'
    | 'startTime'
    | 'purchaseState'
  >
): number => {
  const startTime = DateTime.fromISO(planPurchase.startTime);
  const endTime = planPurchase.endTime
    ? DateTime.fromISO(planPurchase.endTime)
    : startTime.plus({ years: 1 }); // Show progress to a full year if no end date
  const status = getPlanPurchaseStatus(planPurchase);

  if (
    status === 'Ended' ||
    status === 'On extension' ||
    startTime.hasSame(endTime, 'day')
  ) {
    return 100;
  }

  return (
    (startTime.diffNow('days').days / startTime.diff(endTime, 'days').days) *
    100
  );
};

export { MemberPlanCard, getPlanPurchaseStatus, getProgress };
