import { Form, Radio } from 'antd';
import { AbstractCheckboxProps } from 'antd/lib/checkbox/Checkbox';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/lib/form/Form';
import { RadioChangeEvent, RadioGroupProps } from 'antd/lib/radio';
import React, { ComponentType, FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { theme } from 'app/styles/theme';
import { FIELD_VALIDATION } from 'constants/form';

// Types & constants ////////////////////////////////
interface Props extends AFormFieldWrapperProps {
  antdForm: WrappedFormUtils;
  disabled?: boolean;
  initialValue?: string | number | boolean;
  label?: string;
  labelInfo?: string;
  name: string;
  onChange?: (e: RadioChangeEvent) => void;
  options?: GetFieldDecoratorOptions;
  radioOptions: Array<{ label: ReactNode; value: string | number | boolean }>;
  required?: boolean;
  size?: RadioGroupProps['size'];
  vertical?: boolean;
}

/** Deprecated: Antd form field, being deprecated to use react-hook-form */
const ARadioField: FC<Props> = ({
  antdForm,
  customWidth,
  initialValue,
  label,
  labelInfo,
  name,
  onChange,
  options,
  radioOptions,
  required,
  show,
  vertical,
  ...props
}) => {
  return (
    <AFormFieldWrapper antd customWidth={customWidth} show={show}>
      <FormItemStyled
        colon={false}
        label={
          label && (
            <ALabel black help={labelInfo} inline>
              {label}
            </ALabel>
          )
        }
        required={required}
      >
        {antdForm.getFieldDecorator(name, {
          ...options,
          initialValue,
          rules: [
            ...(options?.rules || []),
            ...(required
              ? [
                  {
                    validator: (ruleIgnore: any, value: any, callback: any) => {
                      if (value === undefined) {
                        callback(FIELD_VALIDATION.required.message);
                      }
                      callback();
                    },
                  },
                ]
              : []),
          ],
          validateTrigger: ['onBlur', 'onChange'],
        })(
          <Radio.Group onChange={onChange} {...props}>
            {radioOptions.map((option) => (
              <RadioContainer key={option.value.toString()} vertical={vertical}>
                <RadioStyled
                  key={option.value.toString()}
                  value={option.value}
                  vertical={vertical}
                >
                  {!vertical && option.label}
                </RadioStyled>
                {vertical && <RadioLabel>{option.label}</RadioLabel>}
              </RadioContainer>
            ))}
          </Radio.Group>
        )}
      </FormItemStyled>
    </AFormFieldWrapper>
  );
};

// Styled components ////////////////////////////////
const FormItemStyled = styled(Form.Item)`
  &&& {
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner,
    .ant-radio-wrapper:hover .ant-radio {
      border-color: ${theme.color.primary};
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: ${theme.color.primary};
    }
    .ant-radio-checked::after {
      border: 1px solid ${theme.color.primary};
      visibility: hidden;
    }
    .ant-radio-inner::after {
      background-color: ${theme.color.primary};
    }
    .ant-radio-input:focus + .ant-radio-inner {
      -webkit-box-shadow: 0 0 0 3px ${theme.color.boxShadowGreen};
      box-shadow: 0 0 0 3px ${theme.color.boxShadowGreen};
    }

    .ant-radio-button-wrapper:focus-within {
      outline: 3px solid ${theme.color.boxShadowGreen};
    }
    .ant-radio-button-wrapper:hover {
      color: ${theme.color.primary};
    }
    .ant-radio-button-wrapper-checked {
      color: ${theme.color.primary};
      border-color: ${theme.color.primary};
      -webkit-box-shadow: -1px 0 0 0 ${theme.color.primary};
      box-shadow: -1px 0 0 0 ${theme.color.primary};
    }
    .ant-radio-button-wrapper-checked:active {
      color: ${theme.color.jade};
      border-color: ${theme.color.jade};
      -webkit-box-shadow: -1px 0 0 0 ${theme.color.jade};
      box-shadow: -1px 0 0 0 ${theme.color.jade};
    }
    .ant-radio-button-wrapper-checked:first-child {
      border-color: ${theme.color.primary};
    }
    .ant-radio-button-wrapper-checked:focus-within {
      outline: 3px solid ${theme.color.boxShadowGreen};
    }
    .ant-radio-button-wrapper-checked:hover {
      color: ${theme.color.primaryLight};
      border-color: ${theme.color.primaryLight};
      -webkit-box-shadow: -1px 0 0 0 ${theme.color.primaryLight};
      box-shadow: -1px 0 0 0 ${theme.color.primaryLight};
    }
    .ant-radio-button-wrapper-checked::before {
      background-color: ${theme.color.primary};
    }

    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: ${theme.color.primary};
      border-color: ${theme.color.primary};
    }
    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
      background: ${theme.color.jade};
      border-color: ${theme.color.jade};
    }
    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
      outline: 3px solid ${theme.color.boxShadowGreen};
    }
    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
      background: ${theme.color.primaryLight};
      border-color: ${theme.color.primaryLight};
    }
  }
`;

const RadioContainer = styled.div<{ vertical?: boolean }>`
  align-items: center;
  display: ${({ vertical }) => (vertical ? 'flex' : 'inline-block')};
  margin: ${({ vertical }) => (vertical ? theme.space.s : 0)};
`;

const RadioLabel = styled.div`
  margin-left: -${theme.space.s};
`;

const RadioStyled = styled(
  Radio as ComponentType<AbstractCheckboxProps<RadioChangeEvent>>
)<{ vertical?: boolean }>`
  &&& {
    display: ${({ vertical }) => (vertical ? 'block' : 'initial')};
    margin: ${({ vertical }) => (vertical ? '0' : `${theme.space.s} 0`)};
    :last-of-type {
      margin-bottom: 0;
    }
  }
`;

export { ARadioField };
