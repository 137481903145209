import { useQuery } from '@apollo/react-hooks';
import { Tabs } from 'antd';
import capitalize from 'lodash/capitalize';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { APadding } from 'app/components/atoms/APadding/APadding';
import { ASectionEditable } from 'app/components/atoms/ASectionEditable/ASectionEditable';
import { ProviderBasics } from 'app/components/organisms/ProviderBasics/ProviderBasics';
import { ProviderHeader } from 'app/components/organisms/ProviderHeader/ProviderHeader';
import { ProviderPanel } from 'app/components/organisms/ProviderPanel/ProviderPanel';
import { ProviderProducts } from 'app/components/organisms/ProviderProducts/ProviderProducts';
import { ProviderUpdate } from 'app/components/organisms/ProviderUpdate/ProviderUpdate';
import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { NavContext } from 'app/contexts/NavContext';
import { theme } from 'app/styles/theme';
import {
  ProviderPage_Query,
  ProviderPage_QueryVariables,
} from 'app/types/generated/ProviderPage_Query';
import { panelPermittedProviderTypes } from 'app/utils/provider';

import { PROVIDER_PAGE_QUERY } from './query';

// Types & constants ////////////////////////////////
interface PageParams {
  providerID: string;
}

const ProviderPage: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<PageParams>();
  const featureFlags = useContext(FeatureFlagContext);
  const enabledPanelManagement = featureFlags.variation(
    'dr-p-panel-management'
  );
  const enabledPanelSize = featureFlags.variation('panel-size-management');

  const [pageLoading, setPageLoading] = useState(false);

  const providerID = match.params.providerID;
  const { data } = useQuery<ProviderPage_Query, ProviderPage_QueryVariables>(
    PROVIDER_PAGE_QUERY,
    { variables: { providerID } }
  );

  const tabRegex = new RegExp(`/${providerID}/(\\w*)`);
  const tabRegexMatch = location.pathname.match(tabRegex);
  const activeTab = tabRegexMatch?.[1];

  const nav = useContext(NavContext);

  const providerType = data?.provider?.type;

  const shouldShowPanelManagement =
    panelPermittedProviderTypes(providerType) &&
    enabledPanelSize &&
    enabledPanelManagement;

  useEffect(() => {
    if (data?.provider) {
      nav.setBreadcrumbOptions({
        [providerID]: {
          label: data.provider.displayName,
        },
        ...(activeTab
          ? { [activeTab]: { hide: true, label: capitalize(activeTab) } }
          : {}),
      });
      return () => nav.setBreadcrumbOptions({});
    }
  }, [activeTab, data, providerID, nav]);

  return (
    <ALoading size="large" spinning={pageLoading}>
      <ProviderHeader providerID={providerID} setLoading={setPageLoading} />

      <TabsStyled
        activeKey={activeTab || 'basics'}
        onChange={(tabKey) =>
          history.push(
            `${location.pathname.split(providerID)[0]}${providerID}/${tabKey}`
          )
        }
      >
        <Tabs.TabPane key="basics" tab="Basics">
          <APadding type="page">
            <Body>
              <ASectionEditable editable title="Basics">
                {({ editing, toggleEditing }) =>
                  editing ? (
                    <SectionForm>
                      <ProviderUpdate
                        onCancel={toggleEditing}
                        onSave={toggleEditing}
                        providerID={providerID}
                      />
                    </SectionForm>
                  ) : (
                    <SectionContent>
                      <ProviderBasics providerID={providerID} />
                    </SectionContent>
                  )
                }
              </ASectionEditable>
              <ASectionEditable title="Products">
                <ProviderProducts providerID={providerID} />
              </ASectionEditable>

              {shouldShowPanelManagement && (
                <ASectionEditable
                  data-testid="panel-management"
                  title="Panel Management"
                >
                  <ProviderPanel providerID={providerID} />
                </ASectionEditable>
              )}
            </Body>
          </APadding>
        </Tabs.TabPane>
      </TabsStyled>
    </ALoading>
  );
};

// Styled components ////////////////////////////////
const Body = styled.div`
  background: white;
  padding: ${theme.space.s} 0;
  width: 100%;
`;

const SectionContent = styled.div`
  min-width: 300px;
  /* width: 50%; */
  ${theme.layout.breakpointMixin.tabletPortraitDown} {
    width: 100%;
  }
`;

const SectionForm = styled.div`
  width: 60%;
  ${theme.layout.breakpointMixin.tabletPortraitDown} {
    width: 100%;
  }
`;

const TabsStyled = styled(Tabs)`
  &&& {
    margin-top: -${theme.space.m};
    .ant-tabs-bar {
      background: white;
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
`;

export { ProviderPage };
