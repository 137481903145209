import gql from 'graphql-tag';

import { MemberPlanCard_Fragment_planPurchase } from 'app/components/organisms/MemberPlanCard/fragment';
import { MemberStatusTags_Fragment_member } from 'app/components/organisms/MemberStatusTags/fragment';

export const PLAN_PURCHASE_EXTEND_QUERY = gql`
  query PlanPurchaseExtend_Query {
    plans: listAllPlans {
      edges {
        node {
          id
          monthsDuration
          product {
            type
          }
        }
      }
    }
  }
`;

export const PLAN_PURCHASE_EXTEND_MUTATION = gql`
  mutation PlanPurchaseExtend_Mutation($input: PapiExtendPurchaseInput!) {
    extendPurchase(input: $input) {
      id
    }
  }
`;

export const PLAN_PURCHASE_EXTEND_REFETCH = gql`
  query PlanPurchaseExtend_Refetch($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      allPlans {
        edges {
          node {
            ...MemberPlanCard_Fragment_planPurchase
          }
        }
      }
      ...MemberStatusTags_Fragment_member
    }
  }
  ${MemberPlanCard_Fragment_planPurchase}
  ${MemberStatusTags_Fragment_member}
`;
