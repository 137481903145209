import gql from 'graphql-tag';

import { ProviderProducts_Fragment_provider } from './fragment';

export const PROVIDER_PRODUCTS_QUERY = gql`
  query ProviderProducts_Query($providerID: ID!) {
    provider: getProvider(id: $providerID) {
      ...ProviderProducts_Fragment_provider
    }
  }
  ${ProviderProducts_Fragment_provider}
`;
