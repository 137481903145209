import gql from 'graphql-tag';

// remove this query when removing Launch Darkly flag dr-p-cash-pay-switch-in-network-cc
export const LIST_AVAILABLE_INSURANCE_CHANGES_QUERY = gql`
  query ListAvailableInsuranceChanges_Query($planID: ID!) {
    listAvailableInsuranceChanges(planID: $planID) {
      id
      insuranceType
      product {
        type
      }
    }
  }
`;

export const GET_AVAILABLE_INSURANCE_CHANGES_FOR_PERSON_QUERY = gql`
  query GetAvailableInsuranceChangesForPerson_Query($personID: ID!) {
    getAvailableInsuranceChangesForPerson(personID: $personID) {
      id
      insuranceType
      product {
        type
      }
    }
  }
`;

export const CURRENT_PURCHASE_QUERY = gql`
  query CurrentPurchase_Query($personID: ID!) {
    memberCurrentPurchase: getPerson(id: $personID) {
      id
      currentPurchase {
        id
        purchasePeriodEndTime
      }
    }
  }
`;

export const GET_IS_MEMBER_ELIGIBLE_FOR_INSURANCE_FLOW = gql`
  query GetIsMemberEligibleForInsuranceFlow_Query($personID: ID!) {
    getIsMemberEligibleForInsuranceFlow(personID: $personID)
  }
`;
