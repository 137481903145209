import gql from 'graphql-tag';

export const DocumentReviewList_Fragment_person = gql`
  fragment DocumentReviewList_Fragment_person on PapiPerson {
    center {
      abbreviatedName
      id
      name
    }
    currentPlan {
      plan {
        displayName
        product {
          displayName
        }
      }
    }
    dateOfBirth
    firstName
    id
    lastName
    mdhqID
    membershipTermsAcceptedAt
    middleName
    mostRecentDoctor {
      displayName
      id
      type
    }
  }
`;

export const DocumentReviewList_Fragment_doc = gql`
  fragment DocumentReviewList_Fragment_doc on PapiInboxItem {
    category
    createdAt
    documentName
    id
    isUploadedToMDHQ
    person {
      ...DocumentReviewList_Fragment_person
    }
    source
    sourceCategory
    triagedAt
    triagedByID
    triageStatus
    url
  }
`;
