import capitalize from 'lodash/capitalize';
import React, { FC } from 'react';

import { ASelect, ASelectProps } from 'app/components/atoms/ASelect/ASelect';
import { PapiSex } from 'app/types/generated/globalTypes';

// Types & constants ////////////////////////////////
type Props = Omit<ASelectProps, 'selectOptions'>;

/** Select biological sex */
const ASelectSex: FC<Props> = (props) => {
  return (
    <ASelect
      selectOptions={Object.values(PapiSex).map((sex) => ({
        label: capitalize(sex),
        value: sex,
      }))}
      {...props}
    />
  );
};

export { ASelectSex };
