import gql from 'graphql-tag';

import { MemberLinks_Fragment_member } from 'app/components/organisms/MemberLinks/fragment';
import { MemberStatusTags_Fragment_member } from 'app/components/organisms/MemberStatusTags/fragment';

export const MemberHeader_Fragment_member = gql`
  fragment MemberHeader_Fragment_member on PapiPerson {
    id
    biologicalSex
    center {
      id
      abbreviatedName
      isVirtual
    }
    dateOfBirth
    email
    firstName
    lastName
    middleName
    ...MemberLinks_Fragment_member
    ...MemberStatusTags_Fragment_member
  }
  ${MemberLinks_Fragment_member}
  ${MemberStatusTags_Fragment_member}
`;
