import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Icon, Modal } from 'antd';
import capitalize from 'lodash/capitalize';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { AIconInline } from 'app/components/atoms/AIconInline/AIconInline';
import { AInputConfirmation } from 'app/components/atoms/AInputConfirmation/AInputConfirmation';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { APadding } from 'app/components/atoms/APadding/APadding';
import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { theme } from 'app/styles/theme';
import {
  MemberDelete_Mutation,
  MemberDelete_MutationVariables,
} from 'app/types/generated/MemberDelete_Mutation';
import {
  MemberDelete_Query,
  MemberDelete_QueryVariables,
} from 'app/types/generated/MemberDelete_Query';
import { displayErrors } from 'app/utils/app';
import { formatMemberName } from 'app/utils/member';
import { AppRoute } from 'constants/app';
import { STATUS_MESSAGE } from 'constants/message';

import { MEMBER_DELETE_MUTATION, MEMBER_DELETE_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  personID: string;
}

const FAILURE_STRING = 'FAILURE:';

/** Delete a member's Firebase, MDHQ, HubSpot, and HelpScout profiles */
const MemberDelete: FC<Props> = ({ personID }) => {
  const history = useHistory();
  const formHook = useForm();
  const [showForm, setShowForm] = useState(false);

  const [getMember, { data: queryData, loading: queryLoading }] = useLazyQuery<
    MemberDelete_Query,
    MemberDelete_QueryVariables
  >(MEMBER_DELETE_QUERY);
  const member = queryData?.member;

  const [deleteMember, { loading: mutationLoading }] = useMutation<
    MemberDelete_Mutation,
    MemberDelete_MutationVariables
  >(MEMBER_DELETE_MUTATION);

  const onOpen = (): void => {
    try {
      getMember({ variables: { personID } });
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.memberDelete.error.get);
    }
    setShowForm(true);
  };

  const onSave = async (): Promise<void> => {
    try {
      const { data } = await deleteMember({ variables: { personID } });
      setShowForm(false);
      Modal.success({
        content: data?.deletePapiPerson
          ? Object.keys(data.deletePapiPerson)
              .sort()
              .map((key) => {
                const result = data.deletePapiPerson![key];
                return (
                  key.includes('Response') && (
                    <DeleteResult key={key}>
                      <AIconInline
                        color={
                          result.includes(FAILURE_STRING)
                            ? theme.color.error
                            : theme.color.success
                        }
                        marginPosition="right"
                        type={
                          result.includes(FAILURE_STRING)
                            ? 'close-circle'
                            : 'check-circle'
                        }
                      />
                      <ATextBold>
                        {capitalize(key.replace('Response', ''))}
                      </ATextBold>
                      {result.includes(FAILURE_STRING) &&
                        `: ${result.replace(FAILURE_STRING, '').trim()}`}
                    </DeleteResult>
                  )
                );
              })
          : STATUS_MESSAGE.memberDelete.error.noDataReturned,
        icon: <DeleteCompleteIcon type="user-delete" />,
        okText: 'Ok',
        title: STATUS_MESSAGE.memberDelete.success,
      });
      history.push(AppRoute.Members);
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.memberDelete.error.general);
    }
  };

  return (
    <>
      <div onClick={onOpen}>Delete member</div>

      <Modal
        confirmLoading={mutationLoading}
        destroyOnClose
        footer={
          queryLoading || !member || member.currentPlan ? null : undefined
        }
        okText={mutationLoading ? 'Deleting...' : 'Delete'}
        onCancel={() => setShowForm(false)}
        onOk={formHook.handleSubmit(onSave)}
        title="Delete member"
        visible={showForm}
      >
        {queryLoading || !member ? (
          <ALoadingStyled centered />
        ) : member.currentPlan ? (
          STATUS_MESSAGE.memberDelete.error.hasPlan
        ) : (
          <APadding type="modal">
            <ASectionBtmMargin>
              All data associated with{' '}
              <ATextBold>
                {formatMemberName(member)} ({member.email})
              </ATextBold>{' '}
              will be permanently deleted, including data in MDHQ, HubSpot, and
              HelpScout.
            </ASectionBtmMargin>
            <ASectionBtmMargin>
              If this is a duplicate profile and the member has sent emails from{' '}
              <ATextBold>{member.email}</ATextBold>, add this email to the
              correct HelpScout profile before deleting.
            </ASectionBtmMargin>
            <ASectionBtmMargin>
              <ATextBold>This action cannot be undone.</ATextBold> Enter DELETE
              to continue.
            </ASectionBtmMargin>
            <AFormFieldRow>
              <AInputConfirmation
                confirmationText="delete"
                formHook={formHook}
                name={'delete' + personID}
              />
            </AFormFieldRow>
          </APadding>
        )}
      </Modal>
    </>
  );
};

// Styled components ///////////////////////////////
const ALoadingStyled = styled(ALoading)`
  &&& {
    padding: ${theme.space.xxl} 0 ${theme.space.xxxl} 0;
  }
`;

const DeleteCompleteIcon = styled(Icon)`
  &&& {
    color: ${theme.color.success} !important;
    margin-right: ${theme.space.m};
  }
`;

const DeleteResult = styled.div`
  margin-top: ${theme.space.m};
`;

export { MemberDelete };
