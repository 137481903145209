import { Form, Switch } from 'antd';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/lib/form/Form';
import { SwitchProps } from 'antd/lib/switch';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props extends SwitchProps, AFormFieldWrapperProps {
  antdForm: WrappedFormUtils;
  initialValue?: boolean;
  label: string;
  labelInfo?: string;
  name: string;
  options?: GetFieldDecoratorOptions;
}

/** Deprecated: Antd form field, being deprecated to use react-hook-form */
const ASwitchField: FC<Props> = ({
  antdForm,
  customWidth,
  initialValue,
  label,
  labelInfo,
  name,
  options,
  show,
  ...props
}) => {
  return (
    <AFormFieldWrapper antd customWidth={customWidth} show={show}>
      <Styles>
        <FormItemStyled>
          {antdForm.getFieldDecorator(name, {
            ...options,
            initialValue,
            rules: [...(options?.rules || [])],
            valuePropName: 'checked',
          })(<Switch {...props} />)}
        </FormItemStyled>
        <Label>
          <ALabel black help={labelInfo} inline>
            {label}
          </ALabel>
        </Label>
      </Styles>
    </AFormFieldWrapper>
  );
};

// Styled components ////////////////////////////////
const FormItemStyled = styled(Form.Item)`
  &&& {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  bottom: ${theme.space.xxs};
  margin-left: ${theme.space.s};
  position: relative;
`;

const Styles = styled.div`
  &&& {
    align-items: center;
    display: flex;
    margin-bottom: 0;
    ${theme.layout.breakpointMixin.phoneOnly} {
      margin-bottom: ${theme.space.m};
    }
  }
`;

export { ASwitchField };
