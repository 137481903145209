import { useQuery } from '@apollo/react-hooks';
import { Dropdown, Icon, Input, Menu, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import capitalize from 'lodash/capitalize';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import React, { FC, ReactNode, useState } from 'react';
import styled from 'styled-components/macro';

import { ADropdownMenuItem } from 'app/components/atoms/ADropdownMenuItem/ADropdownMenuItem';
import { AFlexbox } from 'app/components/atoms/AFlexbox/AFlexbox';
import { AIconClickable } from 'app/components/atoms/AIconClickable/AIconClickable';
import { AIconInline } from 'app/components/atoms/AIconInline/AIconInline';
import { ATable } from 'app/components/atoms/ATable/ATable';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { CouponCreate } from 'app/components/organisms/CouponCreate/CouponCreate';
import { CouponUpdate } from 'app/components/organisms/CouponUpdate/CouponUpdate';
import { theme } from 'app/styles/theme';
import {
  CouponsTable_Query,
  CouponsTable_Query_coupons_edges_node,
  CouponsTable_Query_coupons_edges_node_plans,
  CouponsTable_Query_coupons_edges_node_products,
} from 'app/types/generated/CouponsTable_Query';
import { PapiBillingPeriod } from 'app/types/generated/globalTypes';
import { TYPENAMES } from 'app/types/papi';
import { getCenterDisplayNameWithIcon } from 'app/utils/center';
import { BillingRestriction, getCouponDescription } from 'app/utils/coupon';
import { COUPON_TIMEZONE } from 'constants/datetime';

import { COUPONS_TABLE_QUERY } from './query';

// Types & constants ////////////////////////////////
type CouponItem = CouponsTable_Query_coupons_edges_node;
type Plan = CouponsTable_Query_coupons_edges_node_plans;
type Product = CouponsTable_Query_coupons_edges_node_products;

const isDesktop = window.innerWidth > theme.layout.breakpoint.tabletLandscape;
const isPhone = window.innerWidth < theme.layout.breakpoint.tabletPortrait;

const MAX_CENTERS_SHOWN = 3;
const MAX_PRODUCTS_SHOWN = 3;

const columns: Array<ColumnProps<CouponItem>> = compact([
  {
    dataIndex: 'slug',
    defaultSortOrder: isPhone ? ('ascend' as const) : undefined,
    sorter: (a: CouponItem, b: CouponItem) =>
      a.slug.toLowerCase() > b.slug.toLowerCase()
        ? 1
        : b.slug.toLowerCase() > a.slug.toLowerCase()
        ? -1
        : 0,
    title: 'Code',
  },
  {
    key: 'terms',
    render: (coupon: CouponItem) =>
      getCouponDescription(coupon).replace('off', ''),
    title: 'Terms',
  },
  !isPhone && {
    dataIndex: 'redeemBy',
    defaultSortOrder: 'descend' as const,
    filters: [
      { text: 'Expired', value: 'EXPIRED' },
      { text: 'Not expired yet', value: 'NOT_EXPIRED_YET' },
      { text: 'Never expires', value: 'NEVER_EXPIRES' },
    ],
    onFilter: (
      expired: 'EXPIRED' | 'NOT_EXPIRED_YET' | 'NEVER_EXPIRES',
      coupon: CouponItem
    ) =>
      expired === 'NEVER_EXPIRES'
        ? !coupon.redeemBy
        : expired === 'NOT_EXPIRED_YET'
        ? !!coupon.redeemBy &&
          DateTime.fromISO(coupon.redeemBy) > DateTime.local()
        : !!coupon.redeemBy &&
          DateTime.fromISO(coupon.redeemBy) < DateTime.local(),
    render: (redeemBy: CouponItem['redeemBy']) =>
      redeemBy && (
        <>
          {/* Show expiration TIME if coupon hasn't expired yet */}
          {DateTime.fromISO(redeemBy) > DateTime.local() ? (
            <Tooltip
              title={`Expires ${DateTime.fromISO(redeemBy, {
                zone: COUPON_TIMEZONE,
              }).toFormat('h:mma ZZZZ')}`}
            >
              {DateTime.fromISO(redeemBy, {
                zone: COUPON_TIMEZONE,
              }).toLocaleString(DateTime.DATE_SHORT)}
            </Tooltip>
          ) : (
            DateTime.fromISO(redeemBy, {
              zone: COUPON_TIMEZONE,
            }).toLocaleString(DateTime.DATE_SHORT)
          )}
          {DateTime.fromISO(redeemBy) < DateTime.local() && (
            <ExpiredIcon type="stop" />
          )}
        </>
      ),
    sorter: (a: CouponItem, b: CouponItem) => {
      if (!a.redeemBy && !b.redeemBy) {
        return a.slug.toLowerCase() > b.slug.toLowerCase() ? 1 : -1;
      }
      const redeemBySort = !a.redeemBy
        ? -1
        : !b.redeemBy
        ? 1
        : DateTime.fromISO(a.redeemBy).valueOf() -
          DateTime.fromISO(b.redeemBy).valueOf();
      if (redeemBySort === 0) {
        return a.slug.toLowerCase() < b.slug.toLowerCase() ? 1 : -1;
      }
      return redeemBySort;
    },
    title: 'Redeem by',
  },
  isDesktop && {
    dataIndex: 'maxRedemptions',
    title: (
      <Tooltip title="Maximum number of people that can redeem the coupon">
        Max
      </Tooltip>
    ),
  },
  isDesktop && {
    key: 'centers',
    render: ({ centers, slug }: CouponItem) => (
      <>
        {sortBy(centers, 'abbreviatedName')
          .slice(0, MAX_CENTERS_SHOWN)
          .map((center) => (
            <div key={`${slug}_${center.id}`}>
              {getCenterDisplayNameWithIcon(center)}
            </div>
          ))}
        {centers.length > MAX_CENTERS_SHOWN && (
          <ATextLight lighter>
            {`+${centers.length - MAX_CENTERS_SHOWN} more`}
          </ATextLight>
        )}
      </>
    ),
    title: 'Center',
  },
  isDesktop && {
    key: 'Care plan',
    render: ({ plans, products, slug }: CouponItem) => {
      const allowedProducts = getProductRestrictions(products);
      const showMore = allowedProducts.length > MAX_PRODUCTS_SHOWN;
      return (
        <>
          {allowedProducts.slice(0, MAX_PRODUCTS_SHOWN).map((product, i) => (
            <div key={`${slug}_${product}`}>
              {product}
              {i === MAX_PRODUCTS_SHOWN - 1 && showMore && (
                <ATextLight lighter>
                  {` +${allowedProducts.length - MAX_PRODUCTS_SHOWN} more`}
                </ATextLight>
              )}
            </div>
          ))}
          {getBillingRestrictions(plans)}
          {getWaitlistRestriction(products, plans)}
        </>
      );
    },
    title: 'Care plan',
  },
  !isDesktop &&
    !isPhone && {
      key: 'restrictions',
      render: (coupon: CouponItem) =>
        (coupon.maxRedemptions ||
          coupon.centers.length > 0 ||
          coupon.plans.length > 0 ||
          coupon.products.length > 0) && <AIconInline type="check" />,
      title: 'Restrictions',
    },
  {
    key: 'more',
    render: (coupon: CouponItem) => (
      <Dropdown
        overlay={
          <Menu>
            <ADropdownMenuItem>
              <CouponUpdate
                coupon={coupon}
                mutationCacheUpdater={(cache, mutationResult) => {
                  // Manually update because Apollo expects the id field to be
                  // the primary key, while coupons use "slug" as their key
                  const mutationData = mutationResult.data;
                  if (!mutationData || !mutationData.updateCoupon) {
                    return;
                  }
                  const cacheData = cache.readQuery<CouponsTable_Query>({
                    query: COUPONS_TABLE_QUERY,
                  });
                  cache.writeQuery({
                    data: {
                      coupons: {
                        __typename: TYPENAMES.PapiCouponConnection,
                        edges: [
                          ...(cacheData?.coupons
                            ? cacheData.coupons.edges.map((coupon) =>
                                coupon.node.slug ===
                                mutationData.updateCoupon.slug
                                  ? {
                                      __typename: TYPENAMES.PapiCouponEdge,
                                      node: {
                                        ...mutationData.updateCoupon,
                                      },
                                    }
                                  : coupon
                              )
                            : []),
                        ],
                      },
                    },
                    query: COUPONS_TABLE_QUERY,
                  });
                }}
              />
            </ADropdownMenuItem>
          </Menu>
        }
        trigger={['click']}
      >
        <DropdownIcon
          data-testid="CouponsTable_couponMenu"
          primary
          type="more"
        />
      </Dropdown>
    ),
  },
]);

/** Table showing all coupons */
const CouponsTable: FC = () => {
  const [searchInput, setSearchInput] = useState('');
  const { data, loading } = useQuery<CouponsTable_Query>(COUPONS_TABLE_QUERY);

  return (
    <Styles>
      <InputSearchStyled
        onChange={(e) => setSearchInput(e.currentTarget.value)}
        placeholder="Search coupon codes"
        value={searchInput}
      />
      <CouponCreate
        hasButtonText={!isPhone}
        mutationCacheUpdater={(cache, mutationResult) => {
          const mutationData = mutationResult.data;
          if (!mutationData || !mutationData.createCoupon) {
            return;
          }
          const cacheData = cache.readQuery<CouponsTable_Query>({
            query: COUPONS_TABLE_QUERY,
          });
          cache.writeQuery({
            data: {
              coupons: {
                __typename: TYPENAMES.PapiCouponConnection,
                edges: [
                  ...(cacheData?.coupons?.edges || []),
                  {
                    __typename: TYPENAMES.PapiCouponEdge,
                    node: {
                      ...mutationData.createCoupon,
                    },
                  },
                ],
              },
            },
            query: COUPONS_TABLE_QUERY,
          });
        }}
      />

      <ATable<CouponItem>
        columns={columns}
        dataSource={(data?.coupons?.edges || [])
          .filter(({ node: coupon }) =>
            coupon.slug.toLowerCase().includes(searchInput.toLowerCase())
          )
          .map(({ node: coupon }) => coupon)}
        expandable
        expandedRowRender={({
          centers,
          description,
          maxRedemptions,
          plans,
          products,
          redeemBy,
        }) => (
          <>
            <div>{description}</div>
            {!isDesktop && (
              <>
                {redeemBy && isPhone && (
                  <div>
                    {DateTime.fromISO(redeemBy) > DateTime.local()
                      ? 'Redeem by'
                      : 'Expired'}{' '}
                    {DateTime.fromISO(redeemBy, {
                      zone: COUPON_TIMEZONE,
                    }).toLocaleString(DateTime.DATE_SHORT)}{' '}
                    {DateTime.fromISO(redeemBy) > DateTime.local() &&
                      DateTime.fromISO(redeemBy, {
                        zone: COUPON_TIMEZONE,
                      }).toFormat('h:mma ZZZZ')}
                  </div>
                )}
                {maxRedemptions && <div>Max {maxRedemptions} redemptions</div>}
              </>
            )}

            {(isDesktop
              ? centers.length > MAX_CENTERS_SHOWN
              : centers.length > 0) && (
              <AFlexbox>
                {sortBy(centers, 'abbreviatedName').map((center, i) => (
                  <CenterNonDesktop>
                    {getCenterDisplayNameWithIcon(center)}
                    {i + 1 < centers.length ? ', ' : ' '}
                  </CenterNonDesktop>
                ))}
                center{centers.length !== 1 && 's'} only
              </AFlexbox>
            )}

            {(isDesktop
              ? getProductRestrictions(products).length > MAX_PRODUCTS_SHOWN
              : products.length > 0) && (
              <div>
                Applies to {getProductRestrictions(products).join(', ')}
              </div>
            )}

            {getBillingRestrictions(plans)}
            {getWaitlistRestriction(products, plans)}
          </>
        )}
        loading={loading}
        pagination={{
          pageSize: 15,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} coupons`,
        }}
        rowKey={(coupon) => coupon.slug}
        size="small"
      />
    </Styles>
  );
};

// Styled components ////////////////////////////////
const CenterNonDesktop = styled.div`
  display: flex;
  margin-right: ${theme.space.xs};
`;

const DropdownIcon = styled(AIconClickable)`
  &&& {
    float: right;
    font-size: ${theme.font.size.l};
  }
`;

const ExpiredIcon = styled(Icon)`
  &&& {
    bottom: ${theme.space.xxs};
    margin-left: ${theme.space.xs};
    position: relative;
    vertical-align: baseline;
  }
`;

const InputSearchStyled = styled(Input.Search)`
  &&& {
    margin: 0 ${theme.space.m} ${theme.space.m} 0;
    width: 250px;
  }
`;

const Styles = styled.div`
  td:last-of-type {
    padding: 0;
  }
`;

// Helpers ////////////////////////////////
const getBillingRestrictions = (
  plans: Array<Pick<Plan, 'billingPeriod'>>
): ReactNode =>
  plans.length > 0 ? (
    <AFlexbox>
      <AIconInline marginPosition="right" type="dollar" />
      {capitalize(
        uniq(
          plans.map((plan) =>
            plan.billingPeriod === PapiBillingPeriod.MONTH
              ? BillingRestriction.Monthly
              : BillingRestriction.UpfrontOrYearly.replace(/_/g, ' ')
          )
        )
          .sort()
          .join('/')
      )}
    </AFlexbox>
  ) : null;

const getProductRestrictions = (
  products: Array<Pick<Product, 'displayName' | 'type'>>
): string[] =>
  products
    .filter((product) => !product.type.includes('WAITLIST'))
    .map((product) => product.displayName)
    .sort();

const getWaitlistRestriction = (
  products: Array<Pick<Product, 'type'>>,
  plans: Array<Pick<Plan, 'product'>>
): ReactNode =>
  (plans.length > 0 &&
    !plans.find((plan) => plan.product.type.includes('WAITLIST'))) ||
  (products.length > 0 &&
    !products.find((product) => product.type.includes('WAITLIST'))) ? (
    <AFlexbox>
      <AIconInline marginPosition="right" type="stop" /> Waitlist
    </AFlexbox>
  ) : null;

export { CouponsTable };
