import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import React, { FC } from 'react';

import {
  ProviderCalendarCacheRefresh_Mutation,
  ProviderCalendarCacheRefresh_MutationVariables,
} from 'app/types/generated/ProviderCalendarCacheRefresh_Mutation';
import { displayErrors } from 'app/utils/app';
import { STATUS_MESSAGE } from 'constants/message';

import { PROVIDER_CALENDAR_CACHE_REFRESH } from './query';

// Types & constants ////////////////////////////////
interface Props {
  providerID: string;
  setLoading?: (loading: boolean) => void;
}

/**
 * Link to refresh a provider's availability cache in case it gets out of sync.
 * Usually used after manual Acuity changes
 */
const ProviderCalendarCacheRefresh: FC<Props> = ({
  providerID,
  setLoading,
}) => {
  const [refreshCalendar] = useMutation<
    ProviderCalendarCacheRefresh_Mutation,
    ProviderCalendarCacheRefresh_MutationVariables
  >(PROVIDER_CALENDAR_CACHE_REFRESH);

  const onClick = async (): Promise<void> => {
    setLoading?.(true);
    try {
      const result = await refreshCalendar({ variables: { providerID } });
      if (result?.data?.clearCacheForProvider) {
        setLoading?.(false);
        message.success(STATUS_MESSAGE.providerCalendarCacheRefresh.success);
      } else {
        throw new Error(STATUS_MESSAGE.providerCalendarCacheRefresh.error.fail);
      }
    } catch (err) {
      setLoading?.(false);
      displayErrors(
        err,
        STATUS_MESSAGE.providerCalendarCacheRefresh.error.general
      );
    }
  };

  return <div onClick={onClick}>Refresh calendar availability</div>;
};

export { ProviderCalendarCacheRefresh };
