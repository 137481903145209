import { uniqBy } from 'lodash';
import React, { FC } from 'react';

import { ASelect, ASelectProps } from 'app/components/atoms/ASelect/ASelect';
import { VisitCardListAppointmentTypesQuery_listAppointmentTypes } from 'app/types/generated/VisitCardListAppointmentTypesQuery';
import { compareLabel } from 'app/utils/sort';
import { formatVisitAppointmentTypes } from 'app/utils/visit';

interface Props extends Omit<ASelectProps, 'selectOptions'> {
  appointmentTypesList?: VisitCardListAppointmentTypesQuery_listAppointmentTypes[];
  selectedAppointmentTypes?: VisitCardListAppointmentTypesQuery_listAppointmentTypes;
}

const ASelectAppointmentTypes: FC<Props> = ({
  appointmentTypesList = [],
  selectedAppointmentTypes,
  ...props
}) => {
  const filteredAppointmentTypess = uniqBy(appointmentTypesList, 'typeID');

  return (
    <ASelect
      selectOptions={filteredAppointmentTypess
        .map((appointmentTypes) => ({
          label: formatVisitAppointmentTypes(appointmentTypes),
          value: appointmentTypes.typeID,
        }))
        .sort(compareLabel)}
      {...props}
      value={
        selectedAppointmentTypes ? selectedAppointmentTypes.typeID : undefined
      }
    />
  );
};

export { ASelectAppointmentTypes };
