/**
 * Use toLocaleString() with date format presets where possible
 * https://moment.github.io/luxon/docs/manual/formatting.html#presets
 * If the desired format is not in the presets, define formats to use with toFormat()
 * https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
 */
export enum DateFormat {
  DocumentReviewGroup = 'LLL. dd', // Apr. 21
  Shortest = 'M/d/yy', // 4/21/19
}

/**
 * Display/set coupon redeemBy time to LA time since it's the center with the
 * "farthest back" time. This way members in LA can be guaranteed access to coupons
 */
export const COUPON_TIMEZONE = 'America/Los_Angeles';
