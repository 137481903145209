import { Dropdown, Menu } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { ADropdownMenuItem } from 'app/components/atoms/ADropdownMenuItem/ADropdownMenuItem';
import { AIconClickable } from 'app/components/atoms/AIconClickable/AIconClickable';
import { FirebaseProviderDelete } from 'app/components/organisms/FirebaseProviderDelete/FirebaseProviderDelete';
import { MemberDelete } from 'app/components/organisms/MemberDelete/MemberDelete';
import { MemberPasswordReset } from 'app/components/organisms/MemberPasswordReset/MemberPasswordReset';
import { MemberThirdPartyResync } from 'app/components/organisms/MemberThirdPartyResync/MemberThirdPartyResync';
import { theme } from 'app/styles/theme';
import { LOGROCKET_MY_PARSLEY_URL } from 'constants/env';

// Types & constants ////////////////////////////////
interface Props {
  personEmail?: string;
  personID: string;
  setLoading?: (loading: boolean) => void;
}

/** Kebab dropdown with admin actions, e.g. resync third parties, delete */
const MemberAdminDropdown: FC<Props> = ({
  personEmail,
  personID,
  setLoading,
}) => {
  const logrocketURI =
    `${LOGROCKET_MY_PARSLEY_URL}?filters=` +
    encodeURI(
      JSON.stringify([
        {
          operator: {
            hasStrings: true,
            name: 'is',
            type: 'IS',
          },
          strings: [personID],
          type: 'userID',
        },
      ])
    );

  return (
    <DropdownStyled
      overlay={
        <Menu>
          <ADropdownMenuItem>
            {personEmail && (
              <MemberPasswordReset
                email={personEmail}
                setLoading={setLoading}
              />
            )}
          </ADropdownMenuItem>
          <ADropdownMenuItem>
            <MemberThirdPartyResync
              personID={personID}
              setLoading={setLoading}
            />
          </ADropdownMenuItem>
          <ADropdownMenuItem>
            <LogRocketLink
              href={logrocketURI}
              rel="noopener noreferrer"
              target="_blank"
            >
              View LogRocket sessions
            </LogRocketLink>
          </ADropdownMenuItem>
          <ADropdownMenuItem>
            <FirebaseProviderDelete personID={personID} />
          </ADropdownMenuItem>
          <ADropdownMenuItem>
            <MemberDelete personID={personID} />
          </ADropdownMenuItem>
        </Menu>
      }
      trigger={['click']}
    >
      <KebabIcon data-testid="MemberAdminDropdown_kebab" primary type="more" />
    </DropdownStyled>
  );
};

// Styled components ////////////////////////////////
const DropdownStyled = styled(Dropdown)`
  &&& {
    margin-right: 0;
    ${theme.layout.breakpointMixin.tabletPortraitDown} {
      margin-right: ${theme.space.s};
    }
  }
`;

const KebabIcon = styled(AIconClickable)`
  &&& {
    font-size: ${theme.font.size.xl};
  }
`;

const LogRocketLink = styled.a`
  color: ${theme.color.text} !important;
  :hover {
    color: ${theme.color.text} !important;
  }
`;

export { MemberAdminDropdown };
