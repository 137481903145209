import { Divider } from 'antd';
import React, { FC, ReactElement, ReactNode, useState } from 'react';
import styled from 'styled-components/macro';

import { AIconClickable } from 'app/components/atoms/AIconClickable/AIconClickable';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  actions?: ReactNode;
  children:
    | ReactElement
    | ((editProps: {
        editing: boolean;
        toggleEditing: () => void;
      }) => ReactElement);
  editable?: boolean;
  padding?: keyof typeof theme.space;
  title: string;
}

/**
 * Section with title and edit button in same line. Uses render props to share
 * editing state and toggleEditing function
 */
const ASectionEditable: FC<Props> = ({
  actions,
  children,
  editable,
  padding,
  title,
}) => {
  const [editing, setEditing] = useState(false);
  const toggleEditing = (): void => setEditing((prev) => !prev);

  return (
    <>
      <Divider orientation="left">
        <DividerLabel>
          <Title>{title}</Title>
          {editable && !editing && (
            <EditIcon
              data-testid={`ASectionEditable_edit_${title}`}
              onClick={toggleEditing}
              primary
              type="edit"
            />
          )}
          {actions && <Actions>{actions}</Actions>}
        </DividerLabel>
      </Divider>
      <Section padding={padding}>
        {'props' in children ? children : children({ editing, toggleEditing })}
      </Section>
    </>
  );
};

// Styled components ////////////////////////////////
const Actions = styled.div`
  bottom: ${theme.space.xs};
  margin-left: ${theme.space.s};
  position: relative;
`;

const DividerLabel = styled.div`
  align-items: center;
  display: flex;
`;

const EditIcon = styled(AIconClickable)`
  &&& {
    margin-left: ${theme.space.s};
  }
`;

const Section = styled.div<{ padding: Props['padding'] }>`
  margin-top: ${theme.space.m};
  padding: 0 ${({ padding }) => theme.space[padding || 'xxl']};
  :last-of-type {
    padding-bottom: ${theme.space.l};
  }
`;

const Title = styled.h6`
  margin: 0;
`;

export { ASectionEditable };
