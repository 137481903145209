import { Table as AntdTable, Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';
import { TableProps } from 'antd/lib/table';
import React, { Component, ReactNode } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

const isPhone = window.innerWidth < theme.layout.breakpoint.tabletPortrait;

// Types & constants ////////////////////////////////
interface Props<T> extends TableProps<T> {
  expandable?: boolean;
}

/** General table */
class ATable<T> extends Component<Props<T>> {
  render = (): ReactNode => (
    <Styles
      expandable={this.props.expandable}
      size={this.props.size || isPhone ? 'small' : 'middle'}
    >
      <AntdTable<T>
        expandIcon={({ expanded, onExpand }) =>
          this.props.expandable && (
            <ExpandRowIcon
              data-testid="Table_expandRow"
              onClick={(onExpand as unknown) as IconProps['onClick']}
              type={expanded ? 'caret-down' : 'caret-right'}
            />
          )
        }
        size={isPhone ? 'small' : 'middle'}
        {...this.props}
      />
    </Styles>
  );
}

// Styled components ////////////////////////////////
const ExpandRowIcon = styled(Icon)`
  &&& {
    margin-right: ${theme.space.s};
    vertical-align: text-bottom;
  }
`;

const Styles = styled.div<{
  expandable?: boolean;
  size?: 'small' | 'middle';
}>`
  .ant-table {
    background: white;
    border-radius: 4px;
  }
  .ant-table-expand-icon-th,
  .ant-table-row-expand-icon-cell {
    min-width: ${theme.space.s};
    padding: 0 0 0 ${theme.space.xs} !important;
    width: ${theme.space.s};
  }
  .ant-table-row-expand-icon-cell {
    cursor: pointer;
  }
  .ant-table-pagination.ant-pagination {
    margin: ${theme.space.s};
  }
  .ant-table-row:hover,
  .ant-table-row:hover td {
    background: ${({ size }) =>
      size === 'small'
        ? theme.color.backgroundHover
        : theme.color.cloud + '60'} !important;
  }
  .ant-table-tbody > tr > td.ant-table-column-sort {
    background: white;
  }
  .ant-table-tbody > tr:last-child > td {
    border-bottom: 0;
  }
  .ant-table-tbody > tr:last-child > td:first-child {
    border-radius: 0 0 0 4px;
  }
  .ant-table-tbody > tr > td:first-child,
  .ant-table-thead > tr:first-child > th:first-child {
    padding-left: ${({ size }) =>
      size === 'small' ? theme.space.s : theme.space.m} !important;
  }
  .ant-table-thead > tr > th .anticon-filter,
  .ant-table-thead > tr > th .ant-table-filter-icon {
    bottom: ${theme.space.s};
    position: relative;
    top: unset;
  }
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-filters {
    padding-right: ${theme.space.m} !important;
  }
  .ant-table-thead > tr > th,
  .ant-table-thead > tr > th.ant-table-column-sort,
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
  .ant-table-thead
    > tr
    > th
    .ant-table-header-column
    .ant-table-column-sorters:hover::before {
    background: ${({ size }) =>
      size === 'small' ? 'white' : theme.color.cloud} !important;
  }
`;

export { ATable };
