export const compareLabel = (
  a: { label?: string | null } | null,
  b: { label?: string | null } | null
): number => {
  if (!a || !a.label) {
    if (b?.label) {
      return 1;
    }
    return 0;
  }
  if (!b || !b.label) {
    return -1;
  }
  return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
};
