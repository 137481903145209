export const TYPENAMES = {
  Clinic: 'Clinic' as const,
  PapiAppointment: 'PapiAppointment' as const,
  PapiAppointmentDetails: 'PapiAppointmentDetails' as const,
  PapiCenter: 'PapiCenter' as const,
  PapiCharge: 'PapiCharge' as const,
  PapiCoupon: 'PapiCoupon' as const,
  PapiCouponApplies: 'PapiCouponApplies' as const,
  PapiCouponConnection: 'PapiCouponConnection' as const,
  PapiCouponEdge: 'PapiCouponEdge' as const,
  PapiCouponRepeats: 'PapiCouponRepeats' as const,
  PapiFlatDiscount: 'PapiFlatDiscount' as const,
  PapiInsurancePayer: 'PapiInsurancePayer' as const,
  PapiInsurancePayerCenters: 'PapiInsurancePayerCenters' as const,
  PapiInvoice: 'PapiInvoice' as const,
  PapiPaymentCard: 'PapiPaymentCard' as const,
  PapiPaymentCardConnection: 'PapiPaymentCardConnection' as const,
  PapiPaymentCardEdge: 'PapiPaymentCardEdge' as const,
  PapiPercentageDiscount: 'PapiPercentageDiscount' as const,
  PapiPeriod: 'PapiPeriod' as const,
  PapiPerson: 'PapiPerson' as const,
  PapiPlan: 'PapiPlan' as const,
  PapiPlanConnection: 'PapiPlanConnection' as const,
  PapiPlanEdge: 'PapiPlanEdge' as const,
  PapiPlanPurchase: 'PapiPlanPurchase' as const,
  PapiPlanPurchaseConnection: 'PapiPlanPurchaseConnection' as const,
  PapiPlanPurchaseEdge: 'PapiPlanPurchaseEdge' as const,
  PapiProduct: 'PapiProduct' as const,
  PapiProvider: 'PapiProvider' as const,
  PapiProviderCenter: 'PapiProviderCenter' as const,
  PapiScheduledAppointment: 'PapiScheduledAppointment' as const,
  Person: 'Person' as const,
  ProviderConnection: 'ProviderConnection' as const,
  Query: 'Query' as const,
};
