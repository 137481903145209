import gql from 'graphql-tag';

export const GET_ENTERPRISE_PARTNERS_QUERY = gql`
  query GetEnterprisePartners_Query($filters: PartnerFilter!) {
    getPartners(filters: $filters) {
      pagination {
        totalCount
        nextCursor
      }
      result {
        id
        displayName
        slug
        logoUrl
        createdAt
      }
    }
  }
`;
