import gql from 'graphql-tag';

import { ASelectCouponField_Fragment_coupon } from 'app/components/deprecated/ASelectCouponField/fragment';
import { MemberPlanCard_Fragment_planPurchase } from 'app/components/organisms/MemberPlanCard/fragment';
import { MemberStatusTags_Fragment_member } from 'app/components/organisms/MemberStatusTags/fragment';

export const PLAN_PURCHASE_CREATE_QUERY = gql`
  query PlanPurchaseCreate_Query($personID: ID!) {
    coupons: listCoupons(onlyRedeemable: true) {
      edges {
        node {
          ...ASelectCouponField_Fragment_coupon
        }
      }
    }
    member: getPerson(id: $personID) {
      id
      allPlans {
        edges {
          node {
            id
            center {
              id
            }
            coupon {
              ...ASelectCouponField_Fragment_coupon
            }
            endTime
            plan {
              id
              product {
                type
              }
            }
            startTime
          }
        }
      }
      center {
        id
        timezone
      }
      dateOfBirth
      paymentCards {
        edges {
          node {
            id
            deleted
            expiryMonth
            expiryYear
          }
        }
      }
    }
    plans: listAllPlans {
      edges {
        node {
          id
          annualCostInCents
          billingPeriod
          consumerType
          defaultFollowOnPlan {
            id
            product {
              displayName
              type
            }
          }
          deprecatedAt
          displayName
          insuranceType
          maxPersonAgeYears
          minPersonAgeYears
          monthsDuration
          numberOfBillingCycles
          paymentRateInCents
          product {
            displayName
            type
          }
        }
      }
    }
  }
  ${ASelectCouponField_Fragment_coupon}
`;

export const PLAN_PURCHASE_CREATE_MUTATION = gql`
  mutation PlanPurchaseCreate_Mutation($input: PapiMakeNewPurchaseInput!) {
    makeNewPurchase(input: $input) {
      followOnPurchase {
        ...MemberPlanCard_Fragment_planPurchase
      }
      planPurchase {
        member: person {
          id
        }
        ...MemberPlanCard_Fragment_planPurchase
      }
    }
  }
  ${MemberPlanCard_Fragment_planPurchase}
`;

export const PLAN_PURCHASE_CREATE_MUTATION_FOLLOW_ON = gql`
  mutation PlanPurchaseCreate_Mutation_FollowOn(
    $input: PapiAddFollowOnPlanInput!
  ) {
    addFollowOnPlan(input: $input) {
      member: person {
        id
      }
      ...MemberPlanCard_Fragment_planPurchase
    }
  }
  ${MemberPlanCard_Fragment_planPurchase}
`;

export const PLAN_PURCHASE_CREATE_REFETCH = gql`
  query PlanPurchaseCreate_Refetch($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      allPlans {
        edges {
          node {
            ...MemberPlanCard_Fragment_planPurchase
          }
        }
      }
      ...MemberStatusTags_Fragment_member
    }
  }
  ${MemberPlanCard_Fragment_planPurchase}
  ${MemberStatusTags_Fragment_member}
`;
