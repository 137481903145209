import { useQuery } from '@apollo/react-hooks';
import { Input } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import React, { FC, ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AFlexbox } from 'app/components/atoms/AFlexbox/AFlexbox';
import { ProviderAtCapacityIcon } from 'app/components/atoms/AIconProviderAtCapacity/AIconProviderAtCapacity';
import { AIconProviderNewMembers } from 'app/components/atoms/AIconProviderNewMembers/AIconProviderNewMembers';
import { ATable } from 'app/components/atoms/ATable/ATable';
import { theme } from 'app/styles/theme';
import {
  PapiProductType,
  PapiProviderType,
} from 'app/types/generated/globalTypes';
import {
  ProvidersTable_Query,
  ProvidersTable_Query_providers_centers,
  ProvidersTable_Query_providers_products,
} from 'app/types/generated/ProvidersTable_Query';
import {
  getCenterDisplayName,
  getCenterDisplayNameWithIcon,
} from 'app/utils/center';
import { formatProviderRole } from 'app/utils/provider';
import { compareLabel } from 'app/utils/sort';
import { AppRoute } from 'constants/app';
import { PROVIDER_ROLES } from 'constants/provider';

import { PROVIDERS_TABLE_QUERY } from './query';

// Types & constants ////////////////////////////////
interface ProviderItem {
  centers: ProvidersTable_Query_providers_centers[];
  currentPanelSize: number | null;
  displayName: string;
  id: string;
  pod: any;
  products: ProvidersTable_Query_providers_products[];
  targetPanelSize: number | null;
  type: PapiProviderType;
}

interface Props {
  toolbarExtension?: ReactNode;
}

const MAX_CENTERS_SHOWN = 3;

/** Table of providers */
const ProvidersTable: FC<Props> = ({ toolbarExtension }) => {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState('');
  const { data, loading } = useQuery<ProvidersTable_Query>(
    PROVIDERS_TABLE_QUERY
  );

  const centers = data?.centers || [];
  const providers = data?.providers || [];
  const products = data?.products || [];
  const pods = providers.map((provider) => provider.pod).filter((pod) => pod);

  const columns: Array<ColumnProps<ProviderItem>> = compact([
    {
      defaultSortOrder: 'ascend' as const,
      key: 'displayName',
      render: (provider: ProviderItem) => (
        <DisplayNameContainer>
          <ProviderDisplayName>{provider.displayName}</ProviderDisplayName>
          {provider.currentPanelSize &&
          provider.targetPanelSize &&
          provider.currentPanelSize >= provider.targetPanelSize ? (
            <ProviderAtCapacityIcon />
          ) : null}
        </DisplayNameContainer>
      ),
      sorter: (a: ProviderItem, b: ProviderItem) =>
        a.displayName > b.displayName
          ? 1
          : b.displayName > a.displayName
          ? -1
          : 0,
      title: 'Name',
    },
    {
      dataIndex: 'pod',
      filters: [...new Set(pods)].map((pod: any) => ({
        text: pod.name,
        value: pod.id,
      })),
      onFilter: (value: string, provider: ProviderItem) =>
        provider.pod && provider.pod.id === value,
      render: (pod) => {
        if (pod) {
          return pod.name;
        }
      },
      title: 'Pod',
    },
    {
      dataIndex: 'type',
      filters: PROVIDER_ROLES.map((type) => ({
        text: formatProviderRole(type),
        value: type,
      })),
      onFilter: (value: PapiProviderType, provider: ProviderItem) =>
        provider.type === value,
      render: formatProviderRole,
      title: 'Role',
    },
    {
      dataIndex: 'centers',
      filters: sortBy(
        centers.map((center) => ({
          text: getCenterDisplayName(center),
          value: center.id,
        })),
        'text'
      ),
      onFilter: (centerID: string, provider: ProviderItem) =>
        !!provider.centers.find(
          (providerCenter) => providerCenter.center.id === centerID
        ),
      render: (providerCenters: ProviderItem['centers']) =>
        providerCenters
          .map((providerCenter) => ({
            ...providerCenter,
            label: getCenterDisplayName(providerCenter.center),
          }))
          .sort(compareLabel)
          .slice(0, MAX_CENTERS_SHOWN)
          .map((providerCenter, i) => (
            <Center key={providerCenter.center.id}>
              <NewMembersStatusIcon>
                {!providerCenter.takingNewPatients && (
                  <AIconProviderNewMembers
                    margin="right"
                    takingNewMembers={false}
                  />
                )}
              </NewMembersStatusIcon>
              {getCenterDisplayNameWithIcon(providerCenter.center)}
              {i === MAX_CENTERS_SHOWN - 1 &&
                providerCenters.length > MAX_CENTERS_SHOWN && (
                  <CentersMore>
                    {` +${providerCenters.length - MAX_CENTERS_SHOWN} more`}
                  </CentersMore>
                )}
            </Center>
          )),
      title: 'Centers',
    },
    {
      dataIndex: 'products',
      filters: sortBy(
        products.map((product) => ({
          text: product.abbreviation,
          value: product.type,
        })),
        'value'
      ),
      onFilter: (type: PapiProductType, provider: ProviderItem) =>
        !!provider.products.find((product) => product.type === type),
      render: (providerProducts: ProviderItem['products']) =>
        providerProducts
          .map((product) => ({
            ...product,
            label: product.abbreviation,
          }))
          .sort(compareLabel)
          .slice(0, MAX_CENTERS_SHOWN)
          .map((product, i) => (
            <Center key={`${product.abbreviation}-${i}`}>
              {product.abbreviation}
              {i === MAX_CENTERS_SHOWN - 1 &&
                providerProducts.length > MAX_CENTERS_SHOWN && (
                  <CentersMore>
                    {` +${providerProducts.length - MAX_CENTERS_SHOWN} more`}
                  </CentersMore>
                )}
            </Center>
          )),
      title: 'Products',
    },
  ]);

  return (
    <>
      <Toolbar>
        <InputSearchStyled
          onChange={(e) => setSearchInput(e.currentTarget.value)}
          placeholder="Search providers"
          value={searchInput}
        />
        <ToolbarExtension>{toolbarExtension}</ToolbarExtension>
      </Toolbar>

      <ProviderTable>
        <ATable<ProviderItem>
          columns={columns}
          dataSource={providers
            .filter(
              (provider) =>
                PROVIDER_ROLES.includes(provider.type) &&
                !provider.deleted &&
                provider.displayName
                  .toLowerCase()
                  .includes(searchInput.toLowerCase())
            )
            .map((provider) => provider)}
          loading={loading}
          onRow={(provider) => ({
            onClick: (eIgnore) =>
              history.push(`${AppRoute.Providers}/${provider.id}`),
          })}
          pagination={false}
          rowKey={(provider) => provider.id}
        />
      </ProviderTable>
    </>
  );
};

// Styled components ////////////////////////////////
const Center = styled(AFlexbox)`
  &&& {
    margin-right: ${theme.space.xs};
  }
`;
const CentersMore = styled.div`
  color: ${theme.color.textLighter};
  margin-left: ${theme.space.s};
`;

const InputSearchStyled = styled(Input.Search)`
  &&& {
    margin: 0 ${theme.space.m} ${theme.space.m} 0;
    width: 250px;
  }
`;

const NewMembersStatusIcon = styled.div`
  bottom: ${theme.space.xxs};
  position: relative;
  width: 18px;
`;

const ProviderTable = styled.div`
  tr {
    cursor: pointer;
  }
`;

const Toolbar = styled.div`
  align-items: flex-start;
  display: flex;
`;
const ToolbarExtension = styled.div`
  margin-bottom: ${theme.space.m};
`;

const DisplayNameContainer = styled.div`
  align-items: center;
  display: flex;
`;

const ProviderDisplayName = styled.p`
  margin-right: 10px;
  padding-top: 14px;
`;

export { ProvidersTable };
