import gql from 'graphql-tag';

import { ASelectCouponField_Fragment_coupon } from 'app/components/deprecated/ASelectCouponField/fragment';

export const PlanPurchaseEnrollFromWaitlist_Fragment_planPurchase = gql`
  fragment PlanPurchaseEnrollFromWaitlist_Fragment_planPurchase on PapiPlanPurchase {
    id
    coupon {
      ...ASelectCouponField_Fragment_coupon
    }
    member: person {
      id
    }
    plan {
      id
      defaultFollowOnPlan {
        id
        product {
          displayName
          type
        }
      }
      product {
        type
      }
    }
  }
  ${ASelectCouponField_Fragment_coupon}
`;
