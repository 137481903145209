import { useQuery } from '@apollo/react-hooks';
import React, { FC, useCallback, useEffect } from 'react';

import { ASelect, ASelectProps } from 'app/components/atoms/ASelect/ASelect';
import { IViewerContext } from 'app/contexts/ViewerContext';
import {
  ASelectProvider_Query,
  ASelectProvider_Query_providers,
} from 'app/types/generated/ASelectProvider_Query';
import { PapiProviderType } from 'app/types/generated/globalTypes';
import { formatProviderRole } from 'app/utils/provider';
import { compareLabel } from 'app/utils/sort';
import { PROVIDER_ROLES, PROVIDER_TYPE_CLINICIANS } from 'constants/provider';

import {
  A_SELECT_PROVIDER_QUERY,
  MEMBER_AVAILABLE_PROVIDERS_QUERY,
} from './query';
import {
  MemberAvailableProviders_Query,
  MemberAvailableProviders_Query_member_availableProviders as AvailableProviders,
} from 'app/types/generated/MemberAvailableProviders_Query';

// Types & constants ////////////////////////////////

interface Props extends Omit<ASelectProps, 'selectOptions'> {
  /**
   * @param defaultViewer Pass in viewer if field should autoselect a logged in
   * provider. Only providers that are not filtered out will be autoselected. If no
   * provider is autoselected, the first provider in the list will be selected.
   */
  defaultViewer?: IViewerContext;
  filter?: (provider: ASelectProvider_Query_providers) => boolean;
  memberId?: string | null;
  onChange?: (providerID?: string) => void;
  onSelectProvider?: (provider?: ASelectProvider_Query_providers) => void;
  providerType?: string | null;
}

interface AvailableProviderProps extends Omit<ASelectProps, 'selectOptions'> {
  /**
   * @param defaultViewer Pass in viewer if field should autoselect a logged in
   * provider. Only providers that are not filtered out will be autoselected. If no
   * provider is autoselected, the first provider in the list will be selected.
   */
  defaultViewer?: IViewerContext;
  filter?: (provider: AvailableProviders) => boolean;
  memberId?: string | null;
  onChange?: (providerID?: string) => void;
  onSelectProvider?: (provider?: AvailableProviders) => void;
  providerType?: string | null;
  refresh: number | undefined;
}

const ROLES_ORDERED: PapiProviderType[] = [
  PapiProviderType.DOCTOR,
  PapiProviderType.HEALTH_COACH,
  ...PROVIDER_ROLES.filter(
    (role) =>
      role !== PapiProviderType.DOCTOR && role !== PapiProviderType.HEALTH_COACH
  ),
];

/** Select provider field that can be used alone or with react-hook-form */
const ASelectProvider: FC<Props> = ({
  defaultViewer,
  filter,
  loading,
  onChange,
  onSelectProvider,
  ...props
}) => {
  const { data, loading: providersLoading } = useQuery<ASelectProvider_Query>(
    A_SELECT_PROVIDER_QUERY
  );

  const filteredProviders = (data?.providers || []).filter(
    (provider) =>
      (filter ? filter(provider) : true) &&
      PROVIDER_ROLES.includes(provider.type)
  );
  const providersByRole: Partial<Record<
    PapiProviderType,
    ASelectProvider_Query_providers[]
  >> = filteredProviders.reduce(
    (accGroups, currProvider) => ({
      ...accGroups,
      [currProvider.type]: [
        ...(accGroups[currProvider.type] || []),
        currProvider,
      ],
    }),
    {}
  );
  const selectGroups = ROLES_ORDERED.map((role) => ({
    groupLabel: formatProviderRole(role as PapiProviderType),
    options:
      providersByRole[role as PapiProviderType]
        ?.map((provider) => ({
          label: provider.displayName,
          secondaryText: provider.pod?.name?.toUpperCase(),
          value: provider.id,
        }))
        .sort(compareLabel) || [],
  }));

  const loggedInProvider =
    defaultViewer &&
    filteredProviders.find(
      (provider) => provider.email === defaultViewer.email
    );
  const defaultValue = !!defaultViewer
    ? loggedInProvider?.id || selectGroups[0].options[0]?.value
    : undefined;

  const onChangeCalled = useCallback(
    (providerID) => {
      onChange && onChange(providerID);
      const selectedProvider = filteredProviders.find(
        (p) => p.id === providerID
      );
      onSelectProvider && onSelectProvider(selectedProvider);
    },
    [filteredProviders, onChange, onSelectProvider]
  );
  useEffect(() => {
    onChange?.(defaultValue);
  }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ASelect
      defaultValue={defaultValue}
      // Use key so default value updates after loading providers
      key={!!defaultViewer ? defaultValue : undefined}
      loading={loading || providersLoading}
      onChange={onChangeCalled}
      searchable
      selectOptions={selectGroups}
      {...props}
    />
  );
};

/** Select available providers for a member  */
const ASelectAvailableProvider: FC<AvailableProviderProps> = ({
  defaultViewer,
  filter,
  loading,
  memberId,
  providerType,
  onChange,
  onSelectProvider,
  refresh,
  ...props
}) => {
  const { data, loading: providersLoading, refetch } = useQuery<
    MemberAvailableProviders_Query
  >(MEMBER_AVAILABLE_PROVIDERS_QUERY, {
    variables: { id: memberId },
  });

  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh, refetch]);

  const filteredProviders = (
    data?.member?.availableProviders || []
  ).filter((provider) =>
    (filter ? filter(provider) : true) &&
    PROVIDER_ROLES.includes(provider.type) &&
    providerType === 'clinician'
      ? PROVIDER_TYPE_CLINICIANS.includes(provider.type)
      : provider.type === PapiProviderType.HEALTH_COACH
  );

  const providersByRole: Partial<Record<
    PapiProviderType,
    AvailableProviders[]
  >> = filteredProviders.reduce(
    (accGroups, currProvider) => ({
      ...accGroups,
      [currProvider.type]: [
        ...(accGroups[currProvider.type] || []),
        currProvider,
      ],
    }),
    {}
  );

  const selectGroups = ROLES_ORDERED.map((role) => ({
    groupLabel: formatProviderRole(role as PapiProviderType),
    options:
      providersByRole[role as PapiProviderType]
        ?.map((provider) => ({
          label: provider.displayName,
          secondaryText: provider.pod?.name?.toUpperCase(),
          value: provider.id,
        }))
        .sort(compareLabel) || [],
  }));

  const loggedInProvider =
    defaultViewer &&
    filteredProviders.find(
      (provider) => provider.email === defaultViewer.email
    );

  const defaultValue = !!defaultViewer
    ? loggedInProvider?.id || selectGroups[0].options[0]?.value
    : undefined;

  const onChangeCalled = useCallback(
    (providerID) => {
      onChange && onChange(providerID);
      const selectedProvider = filteredProviders.find(
        (p) => p.id === providerID
      );
      onSelectProvider && onSelectProvider(selectedProvider);
    },
    [filteredProviders, onChange, onSelectProvider]
  );
  useEffect(() => {
    onChange?.(defaultValue);
  }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ASelect
      defaultValue={defaultValue}
      // Use key so default value updates after loading providers
      key={!!defaultViewer ? defaultValue : undefined}
      loading={loading || providersLoading}
      onChange={onChangeCalled}
      searchable
      selectOptions={selectGroups}
      {...props}
    />
  );
};

export { ASelectProvider, ASelectAvailableProvider };
