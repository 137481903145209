import { useQuery } from '@apollo/react-hooks';
import { Statistic, Card, Icon } from 'antd';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
  VictoryScatter,
  VictoryZoomContainer,
  VictoryVoronoiContainer,
} from 'victory';

import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { ASectionEditable } from 'app/components/atoms/ASectionEditable/ASectionEditable';
import { ASelect } from 'app/components/atoms/ASelect/ASelect';
import { ATable } from 'app/components/atoms/ATable/ATable';
import { theme } from 'app/styles/theme';
import {
  MemberStatisticsPage_Query,
  MemberStatisticsPage_QueryVariables,
} from 'app/types/generated/MemberStatisticsPage_Query';
import { toTitleCase } from 'app/utils/string';

import { MEMBER_STATISTICS_PAGE_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  personID: string;
}

interface CategoryScore {
  date: number;
  score: number;
}

interface ScoreCollection {
  category?: string;
  label: string;
  name: string;
  scores: Array<CategoryScore | undefined>;
}

interface Score {
  date: number;
  score: number;
  scores: number[];
}

/** Insert description of component */
const MemberStatisticsPage: FC<Props> = ({ personID }) => {
  const { data, loading } = useQuery<
    MemberStatisticsPage_Query,
    MemberStatisticsPage_QueryVariables
  >(MEMBER_STATISTICS_PAGE_QUERY, { variables: { personID: personID } });

  const [selectedSymptom, setSelectedSymptom] = useState<
    ScoreCollection | undefined
  >(undefined);
  const [selectedCategory, setSelectedCategory] = useState<
    ScoreCollection | undefined
  >(undefined);

  const [selectedDatum, setSelectedDatum] = useState<CategoryScore | undefined>(
    undefined
  );

  const psiScores =
    data?.member?.psiScores
      ?.filter((a) => a && a.date)
      .sort((a, b) => {
        if (!a || !a.date) {
          return -1;
        }
        if (!b || !b.date) {
          return -1;
        }
        return (
          DateTime.fromISO(b.date).toSeconds() -
          DateTime.fromISO(a.date).toSeconds()
        );
      }) || [];

  const scoresByCategory: ScoreCollection[] = [];
  const scoresBySymptom: ScoreCollection[] = [];

  const lastPSI = psiScores[0];

  lastPSI?.categories.forEach((category): void => {
    scoresByCategory.push({
      label: category.label || '',
      name: category.name,
      scores: psiScores.map((psiScore): CategoryScore | undefined => {
        if (psiScore) {
          const { categories, date } = psiScore;

          if (date) {
            const score =
              categories.find((c): boolean => category.name === c.name)
                ?.score || 0;
            const parsedDate = DateTime.fromISO(date).toMillis();
            return { date: parsedDate, score };
          }
        }
        return undefined;
      }),
    });
  });

  lastPSI?.categories.forEach((category): void => {
    category.symptoms.forEach((symptom) => {
      scoresBySymptom.push({
        category: category.name,
        label: symptom.label || '',
        name: symptom.name,
        scores: psiScores.map((psiScore): CategoryScore | undefined => {
          if (psiScore) {
            const { categories, date } = psiScore;

            if (date) {
              const score =
                categories
                  .find((c) => category.name === c.name)
                  ?.symptoms.find((s): boolean => symptom.name === s.name)
                  ?.score || 0;
              const parsedDate = DateTime.fromISO(date).toMillis();
              return { date: parsedDate, score };
            }
          }
          return undefined;
        }),
      });
    });
  });

  const showNewPSI = true;

  const psiCollection: Score[] = [];
  psiScores.reverse().map((d) => {
    if (d && d.date) {
      const date = DateTime.fromISO(d.date).toMillis();
      const index = psiCollection.findIndex((i) => i.date === date);
      if (index >= 0) {
        const scores = [...psiCollection[index].scores, d.score];
        const score = scores.reduce((a, b) => a + b) / scores.length;
        return (psiCollection[index] = { date, score, scores });
      } else {
        return psiCollection.push({ date, score: d.score, scores: [d.score] });
      }
    }
    return null;
  });

  const getChartData = useCallback(():
    | Array<CategoryScore | undefined>
    | Score[] => {
    if (selectedSymptom) {
      return selectedSymptom.scores;
    } else if (selectedCategory) {
      return selectedCategory.scores;
    } else {
      return psiCollection;
    }
  }, [selectedSymptom, selectedCategory, psiCollection]);

  const getChartLabel = (): string => {
    if (selectedSymptom) {
      return selectedSymptom.name;
    } else if (selectedCategory) {
      return selectedCategory.name;
    } else {
      return 'PSI';
    }
  };

  const getSymptomList = (): ScoreCollection[] => {
    if (!selectedCategory) {
      return scoresBySymptom;
    }

    return scoresBySymptom.filter(
      (symptom) => symptom.category === selectedCategory.name
    );
  };

  const columns = [
    {
      dataIndex: 'date',
      key: 'date',
      render: (date) =>
        DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT),
      title: 'Date',
    },
    {
      dataIndex: 'score',
      key: 'score',
      title: 'Score',
    },
  ];

  const symptomColumns = [
    {
      dataIndex: 'label',
      key: 'label',
      title: 'Symptom',
    },
    {
      dataIndex: 'score',
      key: 'score',
      title: 'Score',
    },
  ];

  const avgPSI =
    psiCollection.length > 0
      ? psiCollection.map((p) => p.score).reduce((a, b) => a + b) /
        psiCollection.length
      : 0;

  interface TableData {
    label: string;
    score: number;
  }

  const getTableSymptoms = useCallback((): Array<TableData | undefined> => {
    if (selectedDatum) {
      if (selectedSymptom) {
        return scoresBySymptom
          .filter((symptom): boolean => symptom.name === selectedSymptom.name)
          .map((symptom): TableData | undefined => {
            const score = symptom.scores.find(
              (score): boolean => score?.date === selectedDatum.date
            );
            if (score && score.score > 0) {
              return {
                label: symptom.label,
                score: score.score,
              };
            }
            return undefined;
          })
          .filter((value): boolean => value !== undefined);
      } else if (selectedCategory) {
        return scoresBySymptom
          .filter(
            (symptom): boolean => symptom.category === selectedCategory.name
          )
          .map((symptom): TableData | undefined => {
            const score = symptom.scores.find(
              (score): boolean => score?.date === selectedDatum.date
            );
            if (score && score.score > 0) {
              return {
                label: symptom.label,
                score: score.score,
              };
            }
            return undefined;
          })
          .filter((value): boolean => value !== undefined);
      } else {
        return scoresBySymptom
          .map((symptom): TableData | undefined => {
            const score = symptom.scores.find(
              (score): boolean => score?.date === selectedDatum.date
            );
            if (score && score.score > 0) {
              return {
                label: symptom.label,
                score: score.score,
              };
            }
            return undefined;
          })
          .filter((value): boolean => value !== undefined);
      }
    }
    return [];
  }, [scoresBySymptom, selectedCategory, selectedDatum, selectedSymptom]);

  const getTableTitle = useCallback((): string => {
    if (selectedDatum) {
      const date = DateTime.fromMillis(selectedDatum.date).toFormat('MM/dd/yy');
      if (selectedCategory) {
        return `${selectedCategory.label} - ${date}`;
      }

      return `All Symptoms - ${date}`;
    }
    return 'Select data point from chart';
  }, [selectedCategory, selectedDatum]);

  return (
    <ALoading size="large" spinning={loading}>
      {psiScores && (
        <>
          <ASectionEditable title="PSI">
            <ScoresRow>
              <ScoresColumn>
                <Card>
                  <Statistic
                    precision={2}
                    prefix={<Icon type="heart" />}
                    title="Average PSI"
                    value={avgPSI}
                  />
                </Card>
              </ScoresColumn>

              {showNewPSI && (
                <div style={{ flex: 1 }}>
                  <ATable
                    columns={columns}
                    dataSource={psiScores}
                    rowKey="date"
                  />
                </div>
              )}
            </ScoresRow>
          </ASectionEditable>
          <ASectionEditable title="PSI History">
            <Container>
              {showNewPSI ? (
                <>
                  <ChartContainer>
                    <RowContainer>
                      <ASelect
                        customWidth={{ fixed: '300px' }}
                        label="Categories"
                        onChange={(value) => {
                          setSelectedCategory(
                            scoresByCategory.find(
                              (category) => category.name === value
                            )
                          );
                          if (!value) {
                            setSelectedSymptom(undefined);
                          }
                        }}
                        selectOptions={[
                          { label: 'All', value: undefined },
                          ...scoresByCategory?.map((category) => ({
                            label: category.label,
                            value: category.name,
                          })),
                        ]}
                        style={{ paddingRight: '5px' }}
                        value={selectedCategory?.label || 'All'}
                      />
                      <ASelect
                        customWidth={{ fixed: '350px' }}
                        disabled={selectedCategory === undefined}
                        label="Symptoms"
                        onChange={(value) => {
                          setSelectedSymptom(
                            scoresBySymptom.find(
                              (symptom) => symptom.name === value
                            )
                          );
                        }}
                        selectOptions={[
                          { label: 'All', value: undefined },
                          ...getSymptomList().map((symptom) => ({
                            label: symptom.label,
                            value: symptom.name,
                          })),
                        ]}
                        style={{ paddingRight: '20px' }}
                        value={selectedSymptom?.label || 'All'}
                      />
                    </RowContainer>

                    <div style={{ flex: 1 }}>
                      <VictoryChart
                        containerComponent={
                          <VictoryZoomContainer
                            responsive={true}
                            zoomDimension="x"
                          />
                        }
                        domainPadding={20}
                      >
                        <VictoryLine
                          animate={{
                            duration: 2000,
                            onLoad: { duration: 1000 },
                          }}
                          data={getChartData()}
                          domain={{ y: [0, 100] }}
                          interpolation="monotoneX"
                          scale={{ x: 'time' }}
                          sortKey="date"
                          style={{
                            data: {
                              stroke: theme.color.parsleyGreen,
                              strokeWidth: 1,
                            },
                            labels: { fontSize: 12 },
                            parent: { border: '1px solid #ccc' },
                          }}
                          x="date"
                          y="score"
                        />
                        <VictoryScatter
                          data={getChartData()}
                          events={[
                            {
                              eventHandlers: {
                                onClick: () => {
                                  return [
                                    {
                                      mutation: (props) => {
                                        setSelectedDatum(
                                          props.datum as CategoryScore
                                        );
                                      },
                                      target: 'labels',
                                    },
                                  ];
                                },
                              },
                              target: 'data',
                            },
                          ]}
                          labelComponent={
                            <VictoryTooltip
                              cornerRadius={8}
                              flyoutPadding={{
                                bottom: 0,
                                left: 7,
                                right: 7,
                                top: 0,
                              }}
                              flyoutStyle={{
                                fill: '#64bcae',
                                fillOpacity: 0.7,
                                strokeWidth: 0,
                              }}
                              pointerLength={15}
                              style={{
                                fill: '#fff',
                                fontSize: 10,
                                fontWeight: 500,
                                textAnchor: 'middle',
                              }}
                            />
                          }
                          labels={({ datum }) =>
                            `Submitted: ${DateTime.fromMillis(
                              datum.date
                            ).toFormat('MM/dd/yyyy')}
                        Score: ${datum.score.toFixed(0)}
                        `
                          }
                          style={{
                            data: {
                              fill: 'transparent',
                              stroke: '#2b445c',
                              strokeWidth: 1,
                            },
                          }}
                          x="date"
                          y="score"
                        />
                        <VictoryAxis
                          fixLabelOverlap
                          label="Time"
                          scale={{ x: 'time' }}
                          style={{
                            axisLabel: { fontSize: 10, padding: 20 },
                            grid: { stroke: '#818e99', strokeWidth: 0.5 },
                            tickLabels: { fontSize: 8, padding: 5 },
                          }}
                          tickFormat={(t) =>
                            DateTime.fromMillis(t).toFormat('MM/dd/yy')
                          }
                        />
                        <VictoryAxis
                          dependentAxis
                          fixLabelOverlap
                          label={toTitleCase(getChartLabel())}
                          style={{
                            axisLabel: { fontSize: 10, padding: 20 },
                            grid: { stroke: '#818e99', strokeWidth: 0.5 },
                            tickLabels: { fontSize: 8, padding: 5 },
                          }}
                        />
                      </VictoryChart>
                    </div>
                  </ChartContainer>
                  <ColumnContainer>
                    <h3>{getTableTitle()}</h3>
                    <ATable
                      columns={symptomColumns}
                      dataSource={_.uniqWith(
                        getTableSymptoms(),
                        _.isEqual
                      ).sort((a, b) => {
                        if (a && b) {
                          return b?.score - a?.score;
                        }
                        return 0;
                      })}
                      rowKey="label"
                    />
                  </ColumnContainer>
                </>
              ) : (
                <>
                  <div style={{ flex: 1 }}>
                    <VictoryChart
                      containerComponent={<VictoryVoronoiContainer />}
                      domainPadding={20}
                    >
                      <VictoryLine
                        animate={{
                          duration: 2000,
                          onLoad: { duration: 1000 },
                        }}
                        data={psiCollection}
                        domain={{ y: [0, 100] }}
                        interpolation="monotoneX"
                        scale={{ x: 'time' }}
                        sortKey="date"
                        style={{
                          data: { stroke: theme.color.parsleyGreen },
                          labels: { fontSize: 12 },
                          parent: { border: '1px solid #ccc' },
                        }}
                        x="date"
                        y="score"
                      />
                      <VictoryScatter
                        data={psiCollection}
                        labelComponent={<VictoryTooltip />}
                        labels={({ datum }) => datum.score}
                        x="date"
                        y="score"
                      />
                      <VictoryAxis
                        fixLabelOverlap
                        label="Time"
                        labelComponent={<VictoryTooltip />}
                        scale={{ x: 'time' }}
                        style={{
                          axisLabel: { fontSize: 10, padding: 20 },
                          tickLabels: { fontSize: 8, padding: 5 },
                        }}
                        tickFormat={(t) =>
                          DateTime.fromMillis(t).toFormat('MMMM')
                        }
                      />
                      <VictoryAxis
                        dependentAxis
                        fixLabelOverlap
                        label="PSI"
                        style={{
                          axisLabel: { fontSize: 10, padding: 20 },
                          tickLabels: { fontSize: 8, padding: 5 },
                        }}
                      />
                    </VictoryChart>
                  </div>
                  <ColumnContainer>
                    <ATable
                      columns={columns}
                      dataSource={psiScores}
                      rowKey="date"
                    />
                    <LinkContainer>
                      <SurveyTitle>
                        Help us improve the PSI dashboard
                      </SurveyTitle>
                      <p>
                        Take a minute to{' '}
                        <LinkExternal
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf8gXLsGI3p9xgVB7np0QI-l_43cwZGsoou1-J9-QaDlD5q6g/viewform?usp=sf_link"
                          rel="external"
                          target="_blank"
                        >
                          complete the survey
                        </LinkExternal>{' '}
                        so we can improve your experience.
                      </p>
                    </LinkContainer>
                  </ColumnContainer>
                </>
              )}
            </Container>
          </ASectionEditable>
          {showNewPSI && (
            <LinkContainer>
              <SurveyTitle>Help us improve the PSI dashboard</SurveyTitle>
              <p>
                Take a minute to{' '}
                <LinkExternal
                  href="https://docs.google.com/forms/d/e/1FAIpQLSf8gXLsGI3p9xgVB7np0QI-l_43cwZGsoou1-J9-QaDlD5q6g/viewform?usp=sf_link"
                  rel="external"
                  target="_blank"
                >
                  complete the survey
                </LinkExternal>{' '}
                so we can improve your experience.
              </p>
            </LinkContainer>
          )}
        </>
      )}
    </ALoading>
  );
};

// Styled components ////////////////////////////////
const Container = styled.div`
  display: flex;
`;

const ColumnContainer = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const LinkContainer = styled.div`
  background: #f8f6f2;
  display: flex;
  flex-direction: column;
  height: 93px;
  padding: 20px;
`;

const SurveyTitle = styled.p`
  font-weight: bold;
`;

const LinkExternal = styled.a`
  text-decoration: underline;
`;

const ChartContainer = styled.div`
  flex: 1;
  flex-direction: column;
  margin-right: 16px;
`;

const RowContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
`;

const ScoresRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ScoresColumn = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  min-width: 600px;
  padding-right: 20px;
`;

// Helpers ////////////////////////////////

export { MemberStatisticsPage };
