import { useQuery } from '@apollo/react-hooks';
import { Collapse, Input, Skeleton, Tooltip } from 'antd';
import partition from 'lodash/partition';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import React, { FC, ReactNode, useState } from 'react';
import styled from 'styled-components/macro';

import { AFlexbox } from 'app/components/atoms/AFlexbox/AFlexbox';
import { AIconClickable } from 'app/components/atoms/AIconClickable/AIconClickable';
import { AIconInline } from 'app/components/atoms/AIconInline/AIconInline';
import { ATable } from 'app/components/atoms/ATable/ATable';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { ATextClickable } from 'app/components/atoms/ATextClickable/ATextClickable';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { theme } from 'app/styles/theme';
import { PapiProductType } from 'app/types/generated/globalTypes';
import {
  ProductsList_Query,
  ProductsList_Query_products,
  ProductsList_Query_products_plans,
  ProductsList_Query_products_plans_joinCentersAllowed,
} from 'app/types/generated/ProductsList_Query';
import {
  getCenterDisplayName,
  getCenterDisplayNameWithIcon,
} from 'app/utils/center';
import { getPlanPaymentRate } from 'app/utils/plan';
import { compareLabel } from 'app/utils/sort';
import { isEveryWordIncluded } from 'app/utils/string';

import { PRODUCTS_LIST_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  onPlanClick?: (plan: ProductsList_Query_products_plans) => void;
  onProductDetailsClick?: (product: ProductsList_Query_products) => void;
  toolbarExtension?: ReactNode;
}

const MAX_CENTERS_SHOWN = 3;
const planTableColumns = [
  {
    key: 'displayName',
    render: (plan: ProductsList_Query_products_plans) => (
      <>
        {plan.deprecatedAt && <DeactivatedPlanIcon type="stop" />}
        {plan.displayName}
      </>
    ),
    title: 'Plan',
  },
  {
    key: 'paymentRateInCents',
    render: (plan: ProductsList_Query_products_plans) =>
      plan.paymentRateInCents ? getPlanPaymentRate(plan) : '$0',
    title: 'Billing',
  },
  {
    dataIndex: 'joinCentersAllowed',
    render: (
      centers: ProductsList_Query_products_plans_joinCentersAllowed[]
    ) => {
      const centersSorted = centers
        .map((center) => ({
          ...center,
          label: getCenterDisplayName(center),
        }))
        .sort(compareLabel);
      return (
        <AFlexbox>
          {centersSorted.slice(0, MAX_CENTERS_SHOWN).map((center, i) => (
            <Center key={center.id}>
              {getCenterDisplayNameWithIcon(center)}
              {i < MAX_CENTERS_SHOWN - 1 && i !== centers.length - 1 && ', '}
            </Center>
          ))}
          {centers.length > MAX_CENTERS_SHOWN && (
            <ATextLight lighter>
              {` +${centers.length - MAX_CENTERS_SHOWN} more`}
            </ATextLight>
          )}
        </AFlexbox>
      );
    },
    title: 'Centers',
  },
];

/** List of Parsley products. Clicking a product will show a table of associated plans */
const ProductsList: FC<Props> = ({
  onPlanClick,
  onProductDetailsClick,
  toolbarExtension,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [expandedProducts, setExpandedProducts] = useState<PapiProductType[]>(
    []
  );
  const [showDeactivated, setShowDeactivated] = useState(false);
  const { data, loading } = useQuery<ProductsList_Query>(PRODUCTS_LIST_QUERY);

  const Toolbar: ReactNode = (
    <ToolbarStyles>
      <InputSearchStyled
        onChange={(e) => {
          if (searchInput === '') {
            setExpandedProducts([]); // Reset expanded products on new search
          }
          setSearchInput(e.currentTarget.value);
          setShowDeactivated(!!e.currentTarget.value); // Include deactivated products in search
        }}
        placeholder="Search products & plans"
        value={searchInput}
      />

      <Tooltip
        title={`${showDeactivated ? 'Hide' : 'Show'} deactivated products`}
      >
        <ShowDeactivatedIcon
          data-testid="ProductsList_showDeactivated"
          onClick={() => setShowDeactivated((prev) => !prev)}
          primary={showDeactivated}
          type={showDeactivated ? 'eye' : 'eye-invisible'}
        />
      </Tooltip>
      <ToolbarExtension>{toolbarExtension}</ToolbarExtension>
    </ToolbarStyles>
  );

  if (loading || !data) {
    return (
      <>
        {Toolbar}
        <CollapseStyled activeKey={[]} bordered={false}>
          {range(0, 10).map((i) => (
            <CollapsePanelStyled
              header={
                <ProductHeader>
                  <SkeletonStyled
                    active
                    paragraph={{
                      rows: 1,
                      width: i % 3 === 0 ? '30%' : i % 2 === 0 ? '40%' : '50%',
                    }}
                    title={false}
                  />
                  <PlanCountSkeleton
                    active
                    paragraph={{ rows: 1, width: '100%' }}
                    title={false}
                  />
                </ProductHeader>
              }
              key={`ProductsList_loadingPanel_${i}`}
            />
          ))}
        </CollapseStyled>
      </>
    );
  }

  const filteredProducts = searchInput
    ? data.products.filter(
        (product) =>
          isEveryWordIncluded(searchInput, product.displayName) ||
          product.plans.some((plan) =>
            isEveryWordIncluded(searchInput, plan.displayName)
          )
      )
    : data.products;
  const [activeProducts, deactivatedProducts] = partition(
    filteredProducts,
    (product: ProductsList_Query_products) => !product.deprecatedAt
  );

  return (
    <>
      {Toolbar}
      <CollapseStyled
        activeKey={
          searchInput
            ? filteredProducts.map((product) => product.type)
            : expandedProducts
        }
        bordered={false}
        onChange={(expandedProductTypes) =>
          setExpandedProducts(expandedProductTypes as PapiProductType[])
        }
      >
        {sortBy(
          [...activeProducts, ...(showDeactivated ? deactivatedProducts : [])],
          'displayName'
        ).map((product) => (
          <CollapsePanelStyled
            header={
              <ProductHeader>
                <div>
                  {product.deprecatedAt ? (
                    <ATextLight lighter>{product.displayName}</ATextLight>
                  ) : (
                    product.displayName
                  )}
                </div>

                <div>
                  <ATextLight lighter>
                    {product.deprecatedAt ? (
                      <>
                        Deactivated
                        <DeactivatedProductIcon
                          color={theme.color.textLighter}
                          type="stop"
                        />
                      </>
                    ) : (
                      `Plan count: ${product.plans.length}`
                    )}
                  </ATextLight>
                  {onProductDetailsClick && (
                    <ProductDetailsText
                      onClick={(e) => {
                        e.stopPropagation();
                        onProductDetailsClick(product);
                      }}
                    >
                      <ATextBold>Details</ATextBold>
                    </ProductDetailsText>
                  )}
                </div>
              </ProductHeader>
            }
            key={product.type}
            searching={!!searchInput}
          >
            <PlansTable>
              <ATable
                columns={planTableColumns}
                dataSource={[
                  ...sortBy(
                    product.plans.filter((plan) => !plan.deprecatedAt),
                    'paymentRateInCents'
                  ).reverse(),
                  ...sortBy(
                    product.plans.filter((plan) => plan.deprecatedAt),
                    'paymentRateInCents'
                  ).reverse(),
                ].filter((plan) =>
                  searchInput
                    ? isEveryWordIncluded(searchInput, product.displayName) ||
                      isEveryWordIncluded(searchInput, plan.displayName)
                    : true
                )}
                onRow={(plan) => ({
                  onClick: () => onPlanClick?.(plan),
                })}
                pagination={false}
                rowKey="id"
              />
            </PlansTable>
          </CollapsePanelStyled>
        ))}
      </CollapseStyled>
    </>
  );
};

// Styled components ////////////////////////////////
const Center = styled(AFlexbox)`
  &&& {
    margin-right: ${theme.space.xs};
  }
`;

const CollapseStyled = styled(Collapse)`
  &&& {
    background: ${theme.color.background};
    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }
`;
const CollapsePanelStyled = styled(Collapse.Panel)<{ searching?: boolean }>`
  &&& {
    background: white;
    border: 0;
    border-radius: ${theme.space.s};
    margin-bottom: ${theme.space.m};

    :hover {
      box-shadow: ${theme.layer.boxShadow.bottom};
    }
    .ant-collapse-header {
      cursor: ${({ searching }) => (searching ? 'default' : 'pointer')};
    }
    .ant-collapse-header svg {
      fill: ${theme.color.primary};
    }
  }
`;

const DeactivatedPlanIcon = styled(AIconInline)`
  &&& {
    bottom: 1px;
    margin-right: ${theme.space.s};
  }
`;
const DeactivatedProductIcon = styled(AIconInline)`
  &&& {
    bottom: 1px;
    margin-left: ${theme.space.s};
  }
`;

const InputSearchStyled = styled(Input.Search)`
  &&& {
    margin: 0 ${theme.space.m} ${theme.space.s} 0;
    width: 250px;
  }
`;

const PlansTable = styled.div`
  &&& {
    tbody tr {
      cursor: pointer;
    }
  }
`;

const ProductDetailsText = styled(ATextClickable)`
  &&& {
    margin-left: ${theme.space.m};
  }
`;

const ProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.space.s} ${theme.space.m};
`;

const ShowDeactivatedIcon = styled(AIconClickable)`
  &&& {
    cursor: pointer;
    font-size: ${theme.font.size.xl};
    margin-right: ${theme.space.m};
    top: ${theme.space.s};
  }
`;

const SkeletonStyled = styled(Skeleton)`
  padding: ${theme.space.xs} 0;
`;
const PlanCountSkeleton = styled(SkeletonStyled)`
  &&& {
    width: 20%;
  }
`;

const ToolbarExtension = styled.div`
  margin-bottom: ${theme.space.m};
`;
const ToolbarStyles = styled.div`
  align-items: flex-start;
  display: flex;
`;

export { ProductsList };
