import Icon, { IconProps } from 'antd/lib/icon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props extends IconProps {
  color?: string;
  marginPosition?: 'left' | 'right';
  marginSize?: keyof typeof theme.space;
}

/** Inline Antd icon */
const AIconInline: FC<Props> = ({
  color,
  marginPosition,
  marginSize,
  ...props
}) => {
  return (
    <IconStyled
      color={color}
      data-marginposition={marginPosition}
      data-marginsize={marginSize}
      {...props}
    />
  );
};

// Styled components ////////////////////////////////
const IconStyled = styled(Icon)<{
  color: Props['color'];
  'data-marginposition': Props['marginPosition'];
  'data-marginsize': Props['marginSize'];
}>`
  &&& {
    color: ${({ color }) => color};
    margin-left: ${(props) =>
      props['data-marginposition'] === 'left'
        ? theme.space[props['data-marginsize'] || 'xs']
        : 0};
    margin-right: ${(props) =>
      props['data-marginposition'] === 'right'
        ? theme.space[props['data-marginsize'] || 'xs']
        : 0};
    position: relative;
    svg path {
      fill: ${({ color }) => color};
    }
  }
`;

export { AIconInline };
