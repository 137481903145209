import gql from 'graphql-tag';

export const MEMBER_INSURANCE_CARD_QUERY = gql`
  query MemberInsuranceCard_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      insuranceGroupID
      insuranceMemberID
      insurancePayer
    }
  }
`;

export const MEMBER_INSURANCE_CARD_MUTATION = gql`
  mutation MemberInsuranceCard_Mutation($input: PapiUpdatePersonInput!) {
    updatePapiPerson(input: $input) {
      id
      insuranceGroupID
      insuranceMemberID
      insurancePayer
    }
  }
`;
