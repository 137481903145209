import { Context, createContext } from 'react';

export interface IBreadcrumb {
  hide?: boolean; // Filters breadcrumb out
  label: string;
  private?: boolean; // Blocks out text in LogRocket
  url?: string;
}

export interface IBreadcrumbOptions {
  [path: string]: IBreadcrumb;
}

export interface INavContext {
  setBreadcrumbOptions: (breadcrumbOptions: IBreadcrumbOptions) => void;
}

let NavContext: Context<INavContext>;

const createNavContext = (initialValue?: INavContext): Context<INavContext> => {
  NavContext = createContext<INavContext>(
    initialValue || {
      setBreadcrumbOptions: () => {},
    }
  );
  return NavContext;
};

export { createNavContext, NavContext };
