import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

import { AHelpTooltip } from 'app/components/atoms/AHelpTooltip/AHelpTooltip';
import { ATextError } from 'app/components/atoms/ATextError/ATextError';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  black?: boolean;
  help?: ReactNode;
  htmlFor?: string;
  inline?: boolean;
  required?: boolean;
}

/** General label for information, form fields, etc */
const ALabel: FC<Props> = ({
  black,
  children,
  help,
  htmlFor,
  inline,
  required,
}) => {
  return inline ? (
    <span>
      <Styles black={black}>
        {required && <ATextError>* </ATextError>}
        <label htmlFor={htmlFor}>{children}</label>
        {help && <AHelpTooltip tooltip={help} />}{' '}
      </Styles>
    </span>
  ) : (
    <div>
      <Styles black={black}>
        {required && <ATextError>* </ATextError>}
        <label htmlFor={htmlFor}>{children}</label>
        {help && <AHelpTooltip tooltip={help} />}
      </Styles>
    </div>
  );
};

// Styled components ////////////////////////////////
const Styles = styled.span<{ black?: boolean }>`
  color: ${({ black }) => (black ? theme.color.text : theme.color.textLighter)};
  font-size: ${theme.font.size.base};
  font-weight: ${theme.font.weight.base};
`;

export { ALabel };
