import gql from 'graphql-tag';

export const FIREBASE_PROVIDER_DELETE_QUERY = gql`
  query DeletePapiPersonFirebaseProvider_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      currentPlan {
        id
      }
      email
      firstName
      lastName
      middleName
    }
  }
`;

export const FIREBASE_PROVIDER_DELETE_MUTATION = gql`
  mutation DeletePapiPersonFirebaseProvider_Mutation($personID: ID!) {
    deletePapiPersonFirebaseProvider(personID: $personID)
  }
`;
