import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import {
  AForm,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { MemberPaymentCard } from 'app/components/organisms/MemberPaymentCard/MemberPaymentCard';
import { theme } from 'app/styles/theme';
import { PaymentCardSetDefault_Fragment_card } from 'app/types/generated/PaymentCardSetDefault_Fragment_card';
import {
  PaymentCardSetDefault_Mutation,
  PaymentCardSetDefault_MutationVariables,
} from 'app/types/generated/PaymentCardSetDefault_Mutation';
import { displayErrors } from 'app/utils/app';
import { isValidPaymentCard } from 'app/utils/member';
import { STATUS_MESSAGE } from 'constants/message';

import {
  PAYMENT_CARD_SET_DEFAULT_MUTATION,
  PAYMENT_CARD_SET_DEFAULT_REFETCH,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  card: PaymentCardSetDefault_Fragment_card;
}

/** Form to set a member's card as their default payment method */
const PaymentCardSetDefault: FC<Props> = ({ card }) => {
  const [setDefaultPaymentCard, { loading: mutationLoading }] = useMutation<
    PaymentCardSetDefault_Mutation,
    PaymentCardSetDefault_MutationVariables
  >(PAYMENT_CARD_SET_DEFAULT_MUTATION, {
    refetchQueries: [
      {
        query: PAYMENT_CARD_SET_DEFAULT_REFETCH,
        variables: { personID: card.personID },
      },
    ],
  });

  const onSave = async (
    inputIgnore: any,
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      const result = await setDefaultPaymentCard({
        variables: {
          paymentCardID: card.id,
        },
      });
      if (result?.data) {
        message.success(STATUS_MESSAGE.paymentCardSetDefault.success);
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.paymentCardSetDefault.error.general);
      shouldFinish(false);
    }
  };

  const isValid = isValidPaymentCard(card);
  return (
    <AForm
      disableOpen={
        card.deleted === false
          ? {
              message:
                STATUS_MESSAGE.paymentCardSetDefault.error.alreadyDefaultCard,
            }
          : !isValid
          ? { message: STATUS_MESSAGE.paymentCardSetDefault.error.invalidCard }
          : undefined
      }
      onSave={onSave}
      openAs="modal"
      openText="Set as default card"
      saving={mutationLoading}
      title="Set default card"
    >
      {() => (
        <>
          <MemberPaymentCard card={card} defaultStyle editable={false} />
          <Description>
            This card will be set as the default payment method, and it will be
            charged automatically for membership fees.
          </Description>
          <Description>
            The current default payment card will be set to inactive.
          </Description>
        </>
      )}
    </AForm>
  );
};

// Styled components ////////////////////////////////
const Description = styled.div`
  margin-top: ${theme.space.m};
  padding: 0 ${theme.space.s};
`;

export { PaymentCardSetDefault };
