import gql from 'graphql-tag';

import { ProviderName_Fragment } from 'app/fragments/ProviderName';

export const VISIT_DETAILS_MEMBER_VISITS_QUERY = gql`
  query VisitDetailsMemberVisits_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      visits: scheduledAppointments {
        categoryDetails {
          name
        }
        id
        provider {
          id
          type
          ...ProviderName_Fragment
        }
        startTime
        status
      }
    }
  }
  ${ProviderName_Fragment}
`;
