import gql from 'graphql-tag';

/** Get fields to display a member's name using formatMemberName */
export const MemberName_Fragment = gql`
  fragment MemberName_Fragment on PapiPerson {
    id
    firstName
    lastName
    middleName
  }
`;
