import gql from 'graphql-tag';

import { MemberPlanCard_Fragment_planPurchase } from 'app/components/organisms/MemberPlanCard/fragment';
import { MemberStatusTags_Fragment_member } from 'app/components/organisms/MemberStatusTags/fragment';

export const PLAN_PURCHASE_END_OR_CANCEL_MUTATION = gql`
  mutation PlanPurchaseEndOrCancel_Mutation($input: PapiCancelPurchaseInput!) {
    cancelPurchase(input: $input) {
      id
    }
  }
`;

export const PLAN_PURCHASE_END_OR_CANCEL_REFETCH = gql`
  query PlanPurchaseEndOrCancel_Refetch($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      allPlans {
        edges {
          node {
            ...MemberPlanCard_Fragment_planPurchase
          }
        }
      }
      ...MemberStatusTags_Fragment_member
    }
  }
  ${MemberPlanCard_Fragment_planPurchase}
  ${MemberStatusTags_Fragment_member}
`;
