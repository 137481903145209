import { useQuery } from '@apollo/react-hooks';
import React, { FC } from 'react';

import {
  ASelectField,
  ASelectFieldProps,
} from 'app/components/deprecated/ASelectField/ASelectField';
import { ASelectCenterField_Query } from 'app/types/generated/ASelectCenterField_Query';
import { getCenterDisplayName } from 'app/utils/center';
import { compareLabel } from 'app/utils/sort';
import { US_STATES } from 'constants/form';

import { A_SELECT_CENTER_FIELD_QUERY } from './query';

// Types & constants ////////////////////////////////
type Props = Omit<ASelectFieldProps, 'initialValue' | 'selectOptions'> & {
  initialValue?: string | string[];
};

/** Deprecated: Antd form field, being deprecated to use react-hook-form */
const ASelectCenterField: FC<Props> = ({ initialValue, ...props }) => {
  const { data, loading } = useQuery<ASelectCenterField_Query>(
    A_SELECT_CENTER_FIELD_QUERY
  );

  const centers = data?.centers || [];

  return (
    <ASelectField
      filterOption={(input, option) =>
        option.props.title
          ? option.props.title.toLowerCase().includes(input.toLowerCase())
          : false
      }
      initialValue={initialValue && sortCenterIDsByName(initialValue, centers)}
      loading={loading || props.loading}
      options={{
        getValueFromEvent: (centerIDs?: string | string[]) =>
          centerIDs && sortCenterIDsByName(centerIDs, centers),
      }}
      placeholder="Select or search"
      selectOptions={centers
        .map((center) => ({
          label: getCenterDisplayName(center),
          title: `${getCenterDisplayName(center)} ${
            center.isVirtual
              ? US_STATES.find((state) => state.value === center.state)!.label
              : center.city
          }`,
          value: center.id,
        }))
        .sort(compareLabel)}
      showSearch
      {...props}
    />
  );
};

// Helpers ////////////////////////////////
export const sortCenterIDsByName = (
  centerIDs: string | string[],
  centers: Array<{ abbreviatedName: string; id: string; isVirtual: boolean }>
): string | string[] =>
  typeof centerIDs === 'string'
    ? centerIDs
    : // TODO sort in person centers above virtual centers & move to util
      centerIDs
        .map((id) => centers.find((center) => center.id === id) || { id })
        .map((center) => ({
          id: center.id,
          label:
            'abbreviatedName' in center ? getCenterDisplayName(center) : '',
        }))
        .sort(compareLabel)
        .map((center) => center.id);

export { ASelectCenterField };
