import gql from 'graphql-tag';

import { CouponsTable_Fragment_coupon } from 'app/components/organisms/CouponsTable/fragment';
import { CouponForm_Fragment_plansQuery } from 'app/fragments/CouponForm';

export const COUPON_CREATE_QUERY = gql`
  query CouponCreate_Query {
    coupons: listCoupons(onlyRedeemable: false) {
      edges {
        node {
          slug
        }
      }
    }
    ...CouponForm_Fragment_plansQuery
  }
  ${CouponForm_Fragment_plansQuery}
`;

export const COUPON_CREATE_MUTATION = gql`
  mutation CouponCreate_Mutation($input: PapiCreateCouponInput!) {
    createCoupon(input: $input) {
      ...CouponsTable_Fragment_coupon
    }
  }
  ${CouponsTable_Fragment_coupon}
`;
