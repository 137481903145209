import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { MutationUpdaterFn } from 'apollo-client';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { ACheckbox } from 'app/components/atoms/ACheckbox/ACheckbox';
import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { AFormSaveButtonGroup } from 'app/components/atoms/AFormSaveButtonGroup/AFormSaveButtonGroup';
import { AInput } from 'app/components/atoms/AInput/AInput';
import { AnEmploymentTypeCheckbox } from 'app/components/atoms/AnEmploymentTypeCheckbox/AnEmploymentTypeCheckbox';
import { ASelectAcuityInstance } from 'app/components/atoms/ASelectAcuityInstance/ASelectAcuityInstance';
import {
  ASelectProviderCenter,
  IASelectProviderCenterValue,
} from 'app/components/atoms/ASelectProviderCenter/ASelectProviderCenter';
import {
  ASelectProviderProduct,
  IASelectProviderProductValue,
} from 'app/components/atoms/ASelectProviderProduct/ASelectProviderProduct';
import { ASelectProviderRole } from 'app/components/atoms/ASelectProviderRole/ASelectProviderRole';
import { ASelectProviderSuffix } from 'app/components/atoms/ASelectProviderSuffix/ASelectProviderSuffix';
import { ASelectSex } from 'app/components/atoms/ASelectSex/ASelectSex';
import { UPDATE_PROVIDER_IS_MEDICARE_OPTED_OUT_MUTATION } from 'app/components/organisms/ProviderUpdate/query';
import { FormFieldOptions } from 'app/types/form';
import {
  PapiAcuityInstance,
  PapiAddProviderInput,
  PapiEmploymentType,
  PapiProfessionalSuffix,
  PapiProviderType,
  PapiSex,
} from 'app/types/generated/globalTypes';
import {
  ProviderCreate_Mutation,
  ProviderCreate_MutationVariables,
} from 'app/types/generated/ProviderCreate_Mutation';
import {
  UpdateProviderIsMedicareOptedOut,
  UpdateProviderIsMedicareOptedOutVariables,
} from 'app/types/generated/UpdateProviderIsMedicareOptedOut';
import { displayErrors } from 'app/utils/app';
import { AppRoute } from 'constants/app';
import { FORM_VALIDATION } from 'constants/form';
import { STATUS_MESSAGE } from 'constants/message';

import { PROVIDER_CREATE_MUTATION } from './query';

// Types & constants ////////////////////////////////
interface Props {
  mutationCacheUpdater?: MutationUpdaterFn<ProviderCreate_Mutation>;
  onCancel?: () => void;
}

/** Form to create a new provider */
const ProviderCreate: FC<Props> = ({ mutationCacheUpdater, onCancel }) => {
  const formHook = useForm<FieldValueTypes>();
  const history = useHistory();
  const emr688MemberAgeRestriction = true;
  let providerType = '';
  if (emr688MemberAgeRestriction) {
    providerType = formHook.watch('type');
  }

  const [createProvider, { loading }] = useMutation<
    ProviderCreate_Mutation,
    ProviderCreate_MutationVariables
  >(PROVIDER_CREATE_MUTATION, { update: mutationCacheUpdater });
  const [
    updateProviderIsMedicareOptedOut,
    { loading: updateProviderIsMedicareOptedOutLoading },
  ] = useMutation<
    UpdateProviderIsMedicareOptedOut,
    UpdateProviderIsMedicareOptedOutVariables
  >(UPDATE_PROVIDER_IS_MEDICARE_OPTED_OUT_MUTATION);

  const onSave = async (values: FieldValueTypes): Promise<void> => {
    try {
      const { isMedicareOptedOut, ...input } = values;
      const result = await createProvider({
        variables: {
          input: {
            ...input,
            centers: input.centers?.map(
              (center: IASelectProviderCenterValue) => ({
                centerID: center.id,
                takingNewPatients: center.takingNewMembers,
              })
            ),
            products:
              input.products && input.products?.length > 0
                ? input.products?.map(
                    ({ type }: IASelectProviderProductValue) => type
                  )
                : [],
          },
        },
      });

      if (result?.data) {
        if (
          emr688MemberAgeRestriction &&
          providerType === PapiProviderType.DOCTOR
        ) {
          await updateProviderIsMedicareOptedOut({
            variables: {
              id: result?.data.addProvider.id,
              value: Boolean(isMedicareOptedOut),
            },
          });
        }

        message.success(STATUS_MESSAGE.providerCreate.success);
        // FYI this mutation result may not have the correct centers on the provider
        history.push(`${AppRoute.Providers}/${result.data.addProvider.id}`);
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.providerCreate.error.general);
    }
  };

  return (
    <div>
      {/* Name */}
      <AFormFieldRow>
        <AInput
          customWidth={{ percent: 60 }}
          formHook={formHook}
          {...fields.firstName}
        />
        <AInput
          customWidth={{ percent: 30 }}
          formHook={formHook}
          {...fields.middleName}
        />
      </AFormFieldRow>
      <AFormFieldRow>
        <AInput
          customWidth={{ percent: 60 }}
          formHook={formHook}
          {...fields.lastName}
        />
        <ASelectProviderSuffix
          customWidth={{ percent: 30 }}
          formHook={formHook}
          {...fields.professionalSuffix}
        />
      </AFormFieldRow>

      {/* Biological sex & role */}
      <AFormFieldRow>
        <ASelectSex
          customWidth={{ percent: 30 }}
          formHook={formHook}
          {...fields.biologicalSex}
        />
        <ASelectProviderRole
          customWidth={{ percent: 50 }}
          formHook={formHook}
          {...fields.type}
        />
      </AFormFieldRow>

      {/* Centers */}
      <AFormFieldRow>
        <ASelectProviderCenter
          customWidth={{ percent: 100 }}
          formHook={formHook}
          required
          {...fields.centers}
        />
      </AFormFieldRow>

      {/* Centers */}
      <AFormFieldRow>
        <ASelectProviderProduct
          customWidth={{ percent: 100 }}
          formHook={formHook}
          {...fields.products}
        />
      </AFormFieldRow>

      {/* Email */}
      <AFormFieldRow>
        <AInput
          customWidth={{ percent: 90 }}
          formHook={formHook}
          {...fields.email}
        />
      </AFormFieldRow>

      {/* 3rd party */}
      <AFormFieldRow>
        <ASelectAcuityInstance
          customWidth={{ percent: 35 }}
          formHook={formHook}
          {...fields.acuityInstance}
        />
        <AInput
          customWidth={{ percent: 50 }}
          formHook={formHook}
          {...fields.acuityID}
        />
      </AFormFieldRow>
      <AFormFieldRow>
        <AInput
          customWidth={{ percent: 35 }}
          formHook={formHook}
          {...fields.helpScoutID}
        />
        <AInput
          customWidth={{ percent: 65 }}
          formHook={formHook}
          {...fields.sanityID}
        />
      </AFormFieldRow>
      {emr688MemberAgeRestriction && providerType === PapiProviderType.DOCTOR && (
        <AFormFieldRow>
          <ACheckbox formHook={formHook} {...fields.isMedicareOptedOut} />
        </AFormFieldRow>
      )}
      <AFormFieldRow>
        <AnEmploymentTypeCheckbox
          formHook={formHook}
          {...fields.employmentType}
          defaultChecked={false}
          initialValue={PapiEmploymentType.FULL_TIME}
        />
      </AFormFieldRow>

      <AFormSaveButtonGroup
        onCancel={onCancel}
        onSave={formHook.handleSubmit(onSave)}
        saveText="Add"
        saving={loading || updateProviderIsMedicareOptedOutLoading}
        savingText="Adding"
      />
    </div>
  );
};

/** Form field info */
interface FieldValueTypes {
  acuityID?: string | null;
  acuityInstance?: PapiAcuityInstance;
  biologicalSex: PapiSex;
  centers: IASelectProviderCenterValue[];
  email: string;
  employmentType: PapiEmploymentType;
  firstName: string;
  helpScoutID?: string | null;
  isMedicareOptedOut?: boolean | null;
  lastName: string;
  middleName?: string | null;
  products: IASelectProviderProductValue[];
  professionalSuffix?: PapiProfessionalSuffix | null;
  sanityID?: string | null;
  type: PapiProviderType;
}

interface PapiAddProviderInputTypes extends PapiAddProviderInput {
  isMedicareOptedOut?: boolean | null;
}

const fields: Pick<
  FormFieldOptions<PapiAddProviderInputTypes>,
  keyof FieldValueTypes
> = {
  acuityID: {
    label: 'Acuity ID',
    name: 'acuityID',
  },
  acuityInstance: {
    label: 'Acuity center',
    name: 'acuityInstance',
  },
  biologicalSex: {
    label: 'Sex',
    name: 'biologicalSex',
    rules: FORM_VALIDATION.required,
  },
  centers: {
    label: 'Centers',
    name: 'centers',
    // This field is required by setting the required prop in JSX
  },
  email: {
    label: 'Email',
    name: 'email',
    rules: { ...FORM_VALIDATION.email, ...FORM_VALIDATION.required },
  },
  employmentType: {
    label: '1099 Contractor',
    name: 'employmentType',
  },
  firstName: {
    label: 'First name',
    name: 'firstName',
    rules: FORM_VALIDATION.required,
  },
  helpScoutID: {
    label: 'HelpScout ID',
    name: 'helpScoutID',
  },
  isMedicareOptedOut: {
    label: 'Is Medicare Opted Out',
    labelInfo:
      'Please check the box if the Clinician is already opted out for Medicare and is allowed to see Medicare patients. Otherwise, leave it unchecked.',
    name: 'isMedicareOptedOut',
  },
  lastName: {
    label: 'Last name',
    name: 'lastName',
    rules: FORM_VALIDATION.required,
  },
  middleName: {
    label: 'Middle name',
    name: 'middleName',
  },
  products: {
    label: 'Products',
    name: 'products',
  },
  professionalSuffix: {
    label: 'Suffix',
    name: 'professionalSuffix',
  },
  sanityID: {
    label: 'Sanity ID',
    name: 'sanityID',
  },
  type: {
    label: 'Role',
    name: 'type',
    rules: FORM_VALIDATION.required,
  },
};

export { ProviderCreate, fields };
