import { Layout } from 'antd';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ADrawerContainer } from 'app/components/atoms/ADrawerContainer/ADrawerContainer';
import { AppHeader } from 'app/components/organisms/AppHeader/AppHeader';
import { AppNavbar } from 'app/components/organisms/AppNavbar/AppNavbar';
import { LogoutReminder } from 'app/components/organisms/LogoutReminder/LogoutReminder';
import { createLayoutContext } from 'app/contexts/LayoutContext';
import { theme } from 'app/styles/theme';
import { trackNavigation } from 'app/utils/analytics';
import { AUTO_LOGOUT_MINUTES, ROUTES } from 'constants/app';

// Types & constants ////////////////////////////////
interface Props {
  children: ReactNode;
  /** @param logoutReminderTime popup after X minutes. Default = AUTO_LOGOUT_MINUTES */
  logoutReminderTime?: number;
}

/** Layout of the entire app: header, navbar, content */
const AppLayout: FC<Props> = ({
  children,
  logoutReminderTime = AUTO_LOGOUT_MINUTES,
}) => {
  const LayoutContext = createLayoutContext();
  const [isDrawerOpen, setDrawerOpen] = useState<boolean | undefined>();
  const [showLogoutReminder, setShowLogoutReminder] = useState(false);

  const location = useLocation();
  useEffect(() => {
    // Set to undefined so animations don't occur when there's no drawer
    setDrawerOpen((prevIsDrawerOpen) => (prevIsDrawerOpen ? false : undefined));
    trackNavigation();
  }, [location]);

  useEffect(() => {
    // Restart timer on route change and when popup appears/closes
    const logoutReminderTimer = setTimeout(
      () => setShowLogoutReminder(true),
      logoutReminderTime * 60 * 1000
    );
    return () => {
      clearTimeout(logoutReminderTimer);
    };
    /***
     * IMPORTANT: location and showLogoutReminder must be in the dependency array.
     * "location" resets the timer when the user changes routes, which is what we
     * count as "activity" atm. "showLogoutReminder" resets the timer when the
     * reminder is shown/closed, so that the reminder shows up more than once
     */
  }, [location, logoutReminderTime, showLogoutReminder]);

  const [isNavbarCollapsed, setNavbarCollapsed] = useState(true);
  const toggleNavbar = (): void => setNavbarCollapsed((prev) => !prev);

  const [contentWidth, setContentWidth] = useState(
    window.innerWidth - theme.layout.navbarSizeCollapsed
  );
  useEffect(() => {
    setContentWidth(
      window.innerWidth -
        (isDrawerOpen ? theme.layout.drawerSizeDefault : 0) -
        (isNavbarCollapsed
          ? theme.layout.navbarSizeCollapsed
          : theme.layout.navbarSize)
    );
  }, [isDrawerOpen, isNavbarCollapsed]);

  // Use ROUTES to hideAppHeader.
  const hideAppHeader =
    ROUTES.find((route) =>
      matchPath(location.pathname, { exact: route.exact, path: route.path })
    )?.hideAppHeader || false;

  return (
    <Layout>
      <LogoutReminder
        onConfirmActive={() => setShowLogoutReminder(false)}
        show={showLogoutReminder}
      />
      <AppNavbar
        collapsed={isNavbarCollapsed}
        expandable={false}
        toggleCollapsed={toggleNavbar}
      />

      <Layout>
        {!hideAppHeader && <AppHeader navbarCollapsed={isNavbarCollapsed} />}
        <LayoutContext.Provider
          value={useMemo(
            () => ({ contentWidth, isDrawerOpen, setDrawerOpen }),
            [contentWidth, isDrawerOpen]
          )}
        >
          <Layout.Content>
            <Content
              hideAppHeader={hideAppHeader}
              navbarcollapsed={isNavbarCollapsed}
              showDrawer={isDrawerOpen}
            >
              {children}
            </Content>
          </Layout.Content>
        </LayoutContext.Provider>
      </Layout>
    </Layout>
  );
};

// Styled components ////////////////////////////////
const Content = styled(ADrawerContainer)<{
  hideAppHeader: boolean;
  navbarcollapsed: boolean;
}>`
  &&& {
    background: ${theme.color.background};
    min-height: 100vh;
    padding-left: ${({ navbarcollapsed }) =>
      navbarcollapsed
        ? theme.layout.navbarSizeCollapsed
        : theme.layout.navbarSize}px;
    padding-top: ${({ hideAppHeader }) =>
      hideAppHeader ? 0 : theme.layout.headerSize};
    position: relative;
    ${theme.layout.breakpointMixin.phoneOnly} {
      padding-left: 0;
    }
  }
`;

export { AppLayout };
