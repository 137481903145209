import { Skeleton } from 'antd';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

const SkeletonStyled = styled(Skeleton)`
  &&& {
    margin: ${theme.space.s} 0 ${theme.space.l} 0;
  }
`;

export default SkeletonStyled;
