import { times } from 'lodash';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import {
  CalendarCellHeaderCSS,
  CalendarColumnCSS,
  CalendarGridCSS,
  CalendarHeaderCSS,
} from 'app/styles/calendar';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  date: DateTime;
  numberOfDays: number;
}

/** Calendar header displays days in a horizontal bar */
const CalendarDays: FC<Props> = ({ date, numberOfDays }) => {
  return (
    <Header>
      {times(numberOfDays).map((i) => (
        <Column key={`CalendarDays_${i}`}>
          <Day isToday={isToday(date, i)}>
            {isToday(date, i)
              ? 'TODAY, '
              : isTomorrow(date, i)
              ? 'TOMORROW, '
              : ''}
            {date.plus({ days: i }).toLocaleString({ weekday: 'short' })}
            &nbsp;
            <ATextBold boldness="bold">
              {date.plus({ days: i }).toLocaleString({ day: 'numeric' })}
            </ATextBold>
          </Day>
        </Column>
      ))}
    </Header>
  );
};

// Styled components ////////////////////////////////
const Column = styled.div`
  ${CalendarColumnCSS}
  border-right: 1px solid ${theme.color.calendar.border};
  padding-top: 10px;
`;

const Day = styled.div<{ isToday: boolean }>`
  ${CalendarCellHeaderCSS}
  background-color: ${({ isToday }) =>
    isToday ? theme.color.jade : 'inherit'};
  border-top: 0;
  color: ${({ isToday }) => (isToday ? 'white' : theme.color.calendar.text)}
`;

const Header = styled.div`
  ${CalendarGridCSS}
  ${CalendarHeaderCSS}
`;

// Helpers ////////////////////////////////
const isToday = (date: DateTime, days: number): boolean => {
  return (
    date.plus({ days }).toLocaleString(DateTime.DATE_SHORT) ===
    DateTime.local().toLocaleString(DateTime.DATE_SHORT)
  );
};

const isTomorrow = (date: DateTime, days: number): boolean => {
  return (
    date.plus({ days }).toLocaleString(DateTime.DATE_SHORT) ===
    DateTime.local().plus({ days: 1 }).toLocaleString(DateTime.DATE_SHORT)
  );
};

export { CalendarDays };
