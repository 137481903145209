import gql from 'graphql-tag';

import { ProviderBasics_Fragment_provider } from './fragment';

export const PROVIDER_BASICS_QUERY = gql`
  query ProviderBasics_Query($providerID: ID!) {
    centers: listCenters {
      id
      name
    }
    payers: listPayers {
      id
      payerName
    }
    provider: getProvider(id: $providerID) {
      assignedCareManager {
        ...ProviderBasics_Fragment_provider
      }
      ...ProviderBasics_Fragment_provider
      maxHours: attributeValue(type: MAX_PATIENT_FACING_HOURS)
      daysPerWeek: attributeValue(type: WORK_DAYS_PER_WEEK)
    }
  }
  ${ProviderBasics_Fragment_provider}
`;
