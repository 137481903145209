import gql from 'graphql-tag';

export const MemberEmergencyContact_Fragment_member = gql`
  fragment MemberEmergencyContact_Fragment_member on PapiPersonEmergencyContact {
    relationship
    firstName
    lastName
    phoneNumber
    email
  }
`;
