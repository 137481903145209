import { useAuth } from '@parsleyhealth/front-end-utils';
import { Input, message } from 'antd';
import React, { FC, useState, useCallback, useContext } from 'react';
import styled from 'styled-components/macro';

import { AButton } from 'app/components/atoms/AButton/AButton';
import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { theme } from 'app/styles/theme';
import { trackEvent } from 'app/utils/analytics';
import { DEMO_USER_EMAIL } from 'constants/env';
import { STATUS_MESSAGE } from 'constants/message';
import { googleSignInImg } from 'static/images/icons/';
// Types & constants ////////////////////////////////
interface Props {
  onLogin?: () => void;
}

const LoginPage: FC<Props> = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const [signInMode, setSignInMode] = useState<'sso' | 'email'>('sso');
  const { firebaseUser, login, loginSSO, sessionExpired } = useAuth();
  const featureFlags = useContext(FeatureFlagContext);
  const permitPasswordAuth = featureFlags.variation('dr-p-pass-login');

  const logIn = async (): Promise<any> => {
    setLoggingIn(true);
    try {
      await login(email, password);
      if (email !== DEMO_USER_EMAIL) {
        window.heap.identify(email);
      }
      trackEvent('Logged In');
      onLogin?.();
    } catch (error) {
      setPassword('');
      setLoggingIn(false);
      message.error(STATUS_MESSAGE.login.error.general);
      console.error({ loginErrors: [error] });
    }
  };

  const handleSSOLogin = useCallback(async () => {
    try {
      await loginSSO();
      trackEvent('Logged In');
      onLogin?.();
      if (firebaseUser?.email) {
        window.heap.identify(firebaseUser?.email);
      }
    } catch (error) {
      setLoggingIn(false);
      message.error(STATUS_MESSAGE.login.error.general);
      console.error({ loginErrors: [error] });
    }
  }, [firebaseUser, loginSSO, onLogin]);

  return (
    <Styles data-testid="LoginPage">
      {sessionExpired ? (
        <SessionExpired>
          You've been logged out because your session was not in use. Please log
          in below to continue.
        </SessionExpired>
      ) : null}
      <h3>Dr. Parsley</h3>
      <p>
        Please click below to log in with your Parsley Health google account.
      </p>
      {signInMode === 'sso' ? (
        <>
          <GoogleSignInWrapper onClick={handleSSOLogin}>
            <img alt="Sign in with Google" src={googleSignInImg} />
          </GoogleSignInWrapper>
          {permitPasswordAuth ? (
            <Underlined onClick={() => setSignInMode('email')}>
              Use email & password
            </Underlined>
          ) : null}
        </>
      ) : (
        <>
          <InputContainer>
            <Input
              addonBefore="Email"
              data-testid="LoginPage_emailInput"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </InputContainer>
          <InputContainer>
            <Input
              addonBefore="Password"
              data-testid="LoginPage_passwordInput"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              onPressEnter={logIn}
              type="password"
              value={password}
            />
          </InputContainer>
          <AButton loading={loggingIn} onClick={logIn} type="primary">
            Log in
          </AButton>
        </>
      )}
    </Styles>
  );
};

// Styled components ////////////////////////////////
const InputContainer = styled.div`
  margin-bottom: ${theme.space.m};
  max-width: 350px;
  width: 50%;
`;

const GoogleSignInWrapper = styled.div`
  margin-bottom: 40px;
  img {
    cursor: pointer;
    display: block;
    width: 191px;
  }
`;

const SessionExpired = styled.div`
  background: ${theme.color.lightYellow};
  margin-bottom: 30px;
  padding: 15px;
`;

const Underlined = styled.div`
  font-size: 14px;
  margin-top: 40px;
  text-decoration: underline;
`;

const Styles = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: center;
`;

export { LoginPage };
