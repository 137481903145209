import React, { FC, Fragment } from 'react';

import {
  DocumentReviewByDateGroupType,
  DocumentReviewType,
} from 'app/components/arabella/utils/types';
import { APrepCard } from 'app/components/atoms/APrepCard/APrepCard';
import { APrepCardGroupHeader } from 'app/components/atoms/APrepCardGroupHeader/APrepCardGroupHeader';
import { APrepCardGroupsList } from 'app/components/atoms/APrepCardGroupsList/APrepCardGroupsList';
import { APrepCardRow } from 'app/components/atoms/APrepCardRow/APrepCardRow';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
type CardClickFunction = (id: string) => void;

interface Props {
  docsByDateGroup?: DocumentReviewByDateGroupType[];
  isSaved?: boolean;
  onCardClick: CardClickFunction;
  selectedDocId?: string;
}

const MappedDocumentReviewCard: React.FC<{
  doc: DocumentReviewType;
  onCardClick: CardClickFunction;
  selectedDocId?: string;
}> = ({ doc, onCardClick, selectedDocId }) => {
  const handleCardClick = (): void => {
    onCardClick(doc.id);
  };

  return (
    <APrepCard
      completed={doc.saved}
      onClick={handleCardClick}
      selected={selectedDocId === doc.id}
      title={doc.member?.fullName ?? <ATextLight lighter>No member</ATextLight>}
    >
      <APrepCardRow iconType={doc.uploadTypeIcon} text={doc.uploadTypeLabel} />
      {doc.member?.provider ? (
        <APrepCardRow iconType="heart" text={doc.member.provider.fullName} />
      ) : (
        <APrepCardRow
          iconColor={theme.color.crimson}
          iconType="exclamation-circle"
          text="No Provider Assigned"
        />
      )}
    </APrepCard>
  );
};

/** List of document review cards by group */
const DocumentReviewCardGroups: FC<Props> = ({
  docsByDateGroup = [],
  selectedDocId,
  onCardClick,
}) => (
  <APrepCardGroupsList
    items={docsByDateGroup.map((dateGroup, index) => (
      <Fragment key={index}>
        <APrepCardGroupHeader
          subtitle={`${dateGroup.docs.length} items,  ${dateGroup.date}`}
          title={dateGroup.dateGroup}
        />

        {dateGroup.docs.map((doc) => (
          <MappedDocumentReviewCard
            doc={doc}
            key={doc.id}
            onCardClick={onCardClick}
            selectedDocId={selectedDocId}
          />
        ))}
      </Fragment>
    ))}
  />
);

export { DocumentReviewCardGroups };
