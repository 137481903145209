import React, { FC } from 'react';
import styled from 'styled-components';

// Types & constants ////////////////////////////////
interface Props {
  color?: string;
  height?: string;
  width?: string;
  zIndex?: string;
}

/** Icon representing whether a visit takes place in a physical center. Looks like a map pin */
const AIconLocation: FC<Props> = ({
  color = 'black',
  height = '16',
  width = '16',
  zIndex = 'initial',
}) => {
  return (
    <Svg
      aria-label="icon: physical-location"
      fill={color}
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      zIndex={zIndex}
    >
      <path
        clipRule="evenodd"
        d="M8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6C16 7.86658 14.7215 9.43455 12.9924 9.87593C12.9974 9.91658 13 9.95798 13 10V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17L11 10C11 9.95799 11.0026 9.91658 11.0076 9.87593C9.27853 9.43455 8 7.86658 8 6ZM12 4C10.8954 4 10 4.89543 10 6C10 7.10457 10.8954 8 12 8C13.1046 8 14 7.10457 14 6C14 4.89543 13.1046 4 12 4Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M15.9772 14.5523C16.2184 14.0555 16.8167 13.8483 17.3135 14.0896C18.7256 14.7753 20 15.9217 20 17.5C20 19.0493 18.77 20.1828 17.397 20.8693C15.9641 21.5857 14.0546 22 12 22C9.94542 22 8.03591 21.5857 6.60304 20.8693C5.22998 20.1828 4 19.0493 4 17.5C4 15.9709 5.19939 14.8466 6.5455 14.1598C7.03745 13.9088 7.63973 14.1041 7.89075 14.596C8.14176 15.088 7.94645 15.6902 7.4545 15.9413C6.32624 16.5169 6 17.1218 6 17.5C6 17.8837 6.33702 18.5002 7.49747 19.0804C8.5981 19.6308 10.1886 20 12 20C13.8114 20 15.4019 19.6308 16.5025 19.0804C17.663 18.5002 18 17.8837 18 17.5C18 17.1084 17.647 16.4749 16.4398 15.8887C15.943 15.6474 15.7359 15.0491 15.9772 14.5523Z"
        fill={color}
      />
    </Svg>
  );
};

const Svg = styled.svg<{ zIndex: string }>`
  z-index: ${({ zIndex }) => zIndex};
`;

export { AIconLocation };
