import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

export const ATextClickable = styled.span`
  color: ${theme.color.primary};
  cursor: pointer;
  transition-duration: ${theme.animation.transitionDuration};
  transition-property: color;
  transition-timing-function: ${theme.animation.transitionTiming};
  :hover {
    color: ${theme.color.primaryLight};
  }
  * {
    color: ${theme.color.primary};
    cursor: pointer;
    transition-duration: ${theme.animation.transitionDuration};
    transition-property: color;
    transition-timing-function: ${theme.animation.transitionTiming};
    :hover {
      color: ${theme.color.primaryLight};
    }
  }
`;
