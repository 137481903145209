import { useMutation } from '@apollo/react-hooks';
import { Modal, message } from 'antd';
import React, { FC, useCallback } from 'react';
import styled from 'styled-components/macro';

import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { APadding } from 'app/components/atoms/APadding/APadding';
import { theme } from 'app/styles/theme';
import {
  ProviderDeleteAdmin_Mutation,
  ProviderDeleteAdmin_MutationVariables,
} from 'app/types/generated/ProviderDeleteAdmin_Mutation';
import { STATUS_MESSAGE } from 'constants/message';

import { PROVIDER_DELETE_ADMIN_MUTATION } from './query';
//test
// Types & constants ////////////////////////////////
interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  provider?: any;
}

export interface ProviderDeleteMutationdeleteProvider {
  id: string;
}

export interface ProviderDeleteFromAdminMutation {
  deleteProvider: ProviderDeleteMutationdeleteProvider;
}

export interface ProviderDeleteFromAdminMutationVariables {
  providerID: string;
}

const ProviderDeleteModalFromAdmin: FC<Props> = ({
  isVisible,
  onClose,
  onSuccess,
  provider,
}) => {
  const [deleteProvider, { loading: mutationLoading }] = useMutation<
    ProviderDeleteAdmin_Mutation,
    ProviderDeleteAdmin_MutationVariables
  >(PROVIDER_DELETE_ADMIN_MUTATION);

  const onDelete = useCallback(async () => {
    if (!provider) {
      return;
    }
    try {
      const result = await deleteProvider({
        variables: { ID: provider.id },
      });
      if (result?.data?.deleteProvider) {
        message.success(STATUS_MESSAGE.providerDelete.success);
        return onSuccess();
      } else {
        message.error(STATUS_MESSAGE.providerDelete.error.general);
        return onClose();
      }
    } catch (err) {
      message.error(STATUS_MESSAGE.providerDelete.error.general);
      return onClose();
    }
  }, [provider, deleteProvider, onSuccess, onClose]);

  return !isVisible ? null : (
    <Modal
      confirmLoading={mutationLoading}
      destroyOnClose
      okText="Delete"
      okType="danger"
      onCancel={onClose}
      onOk={onDelete}
      visible
    >
      {mutationLoading ? (
        <ALoadingStyled centered />
      ) : (
        <APadding type="modal">
          <Title>Are you sure you want to delete this provider?</Title>
        </APadding>
      )}
    </Modal>
  );
};

// Styled components ///////////////////////////////
const ALoadingStyled = styled(ALoading)`
  &&& {
    padding: ${theme.space.xxl} 0 ${theme.space.xxxl} 0;
  }
`;

const Title = styled.h2`
  text-align: center;
`;

export { ProviderDeleteModalFromAdmin };
