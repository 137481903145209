import round from 'lodash/round';
import { DateTime } from 'luxon';

import {
  PlanPurchaseCreate_Query_coupons_edges_node,
  PlanPurchaseCreate_Query_member,
  PlanPurchaseCreate_Query_plans_edges_node,
} from 'app/types/generated/PlanPurchaseCreate_Query';
import { getMemberAge, isValidPaymentCard } from 'app/utils/member';

type Member = PlanPurchaseCreate_Query_member;
type Plan = PlanPurchaseCreate_Query_plans_edges_node;

export const getEligiblePlansForPurchase = (
  member: Pick<Member, 'center' | 'dateOfBirth' | 'paymentCards'>,
  plans: Plan[]
): Plan[] => {
  if (!member.center || !member.center.id) {
    return [];
  }

  const age = member.dateOfBirth && getMemberAge(member.dateOfBirth);
  const validPaymentCards = member.paymentCards.edges.filter(({ node }) =>
    isValidPaymentCard(node)
  );

  return plans.filter(
    (plan) =>
      (typeof plan.minPersonAgeYears === 'number' && plan.minPersonAgeYears > 0
        ? typeof age === 'number' && age >= plan.minPersonAgeYears
        : true) &&
      (typeof plan.maxPersonAgeYears === 'number'
        ? typeof age === 'number' && age <= plan.maxPersonAgeYears
        : true) &&
      (plan.paymentRateInCents > 0 ? validPaymentCards.length > 0 : true) &&
      !plan.deprecatedAt
  );
};

export const getPlanPurchaseAmountDue = (
  plan: Pick<PlanPurchaseCreate_Query_plans_edges_node, 'paymentRateInCents'>,
  coupon?: Pick<PlanPurchaseCreate_Query_coupons_edges_node, 'discount'> | null
): string => {
  const preamble = 'Amount due on first invoice will be $';
  if (!coupon) {
    return `${preamble}${plan.paymentRateInCents / 100}.`;
  }

  const discountedPrice = round(
    'amountOffCents' in coupon.discount
      ? (plan.paymentRateInCents - coupon.discount.amountOffCents) / 100
      : (plan.paymentRateInCents * (1 - coupon.discount.percentOff / 100)) /
          100,
    2
  );
  if (discountedPrice < 0) {
    return `${preamble}0. Remaining credit will be void, and future invoices will be fully priced.`;
  }

  return `${preamble}${
    discountedPrice % 1 ? discountedPrice.toFixed(2) : discountedPrice
  }.`;
};

export const getPlanPurchaseSummary = (
  plan: Pick<
    PlanPurchaseCreate_Query_plans_edges_node,
    'monthsDuration' | 'product'
  >,
  startTime: DateTime,
  includeStartTime?: boolean,
  willRenew?: boolean
): string => {
  return (
    [
      plan.product.displayName,
      ...(includeStartTime
        ? ['will start', startTime.toLocaleString(DateTime.DATE_SHORT)]
        : ['will begin,']),
      ...(plan.monthsDuration
        ? [
            'and',
            willRenew ? 'renew' : 'end',
            'after',
            plan.monthsDuration,
            `month${plan.monthsDuration === 1 ? '' : 's'} on`,
            startTime
              .plus({ months: plan.monthsDuration })
              .toLocaleString(DateTime.DATE_SHORT),
          ]
        : ['and renew automatically']),
    ].join(' ') + '.'
  );
};

export const getPlanPurchaseStartTimeAPIInput = (
  startDate: DateTime,
  timezone: string
): string | undefined => {
  const startTimeDT = DateTime.fromObject({
    day: startDate.day,
    month: startDate.month,
    year: startDate.year,
    zone: timezone,
  }).startOf('day');

  // If selected start day = today at the the center location, send an undefined
  // startTime (DAPI will set startTime = now if startTime is undefined)
  return startTimeDT.hasSame(DateTime.fromObject({ zone: timezone }), 'day')
    ? undefined
    : startTimeDT.toUTC().toISO();
};
