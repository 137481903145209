/* eslint-disable */

const space = {
  xxs: '0.125rem',
  xs: '0.25rem',
  s: '0.5rem',
  sm: '0.75rem',
  m: '1rem',
  l: '1.5rem',
  xl: '2rem',
  xxl: '2.5rem',
  xxxl: '3rem',
};

export const spaceTheme = { ...space };
