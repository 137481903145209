import { useQuery } from '@apollo/react-hooks';
import { Dropdown, Menu } from 'antd';
import React, { FC, useContext } from 'react';
import styled from 'styled-components/macro';

import { ADropdownMenuItem } from 'app/components/atoms/ADropdownMenuItem/ADropdownMenuItem';
import { AIconClickable } from 'app/components/atoms/AIconClickable/AIconClickable';
import { ASectionEditable } from 'app/components/atoms/ASectionEditable/ASectionEditable';
import { EditInsuranceForm } from 'app/components/organisms/EditInsuranceForm/EditInsuranceForm';
import { GET_INSURANCE_SUBSCRIBER_BY_PERSON_ID } from 'app/components/organisms/EditInsuranceForm/Query';
import { EnterprisePlanCreate } from 'app/components/organisms/EnterprisePlanCreate/EnterprisePlanCreate';
import { MEMBER_NEW_INSURANCE_CARD_QUERY } from 'app/components/organisms/InsuranceCard/query';
import { MemberBasics } from 'app/components/organisms/MemberBasics/MemberBasics';
import { MemberEmergencyContact } from 'app/components/organisms/MemberEmergencyContact/MemberEmergencyContact';
import { MemberMembership } from 'app/components/organisms/MemberMembership/MemberMembership';
import { MemberPayment } from 'app/components/organisms/MemberPayment/MemberPayment';
import { MemberProviders } from 'app/components/organisms/MemberProviders/MemberProviders';
import { MemberThirdPartyIDs } from 'app/components/organisms/MemberThirdPartyIDs/MemberThirdPartyIDs';
import { MemberThirdPartyIDsUpdate } from 'app/components/organisms/MemberThirdPartyIDsUpdate/MemberThirdPartyIDsUpdate';
import { MemberUpdate } from 'app/components/organisms/MemberUpdate/MemberUpdate';
import { MemberUpdateEmergencyContact } from 'app/components/organisms/MemberUpdateEmergencyContact/MemberUpdateEmergencyContact';
import { PaymentCardAdd } from 'app/components/organisms/PaymentCardAdd/PaymentCardAdd';
import { PlanPurchaseCreate } from 'app/components/organisms/PlanPurchaseCreate/PlanPurchaseCreate';
import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { theme } from 'app/styles/theme';
import {
  getInsuranceSubscriberByPersonId,
  getInsuranceSubscriberByPersonIdVariables,
} from 'app/types/generated/getInsuranceSubscriberByPersonId';
import {
  MemberNewInsuranceCard_Query,
  MemberNewInsuranceCard_QueryVariables,
} from 'app/types/generated/MemberNewInsuranceCard_Query';

// Types & constants ////////////////////////////////
interface Props {
  personID: string;
}

/** Shows member's account information: basics, membership, and payment */
const MemberAccountPage: FC<Props> = ({ personID }) => {
  const featureFlags = useContext(FeatureFlagContext);
  const enabledPanelManagement = featureFlags.variation(
    'dr-p-panel-management'
  );
  const enableEmergencyContacts = featureFlags.variation(
    'dr-p-emergency-contact-information'
  );

  const { refetch } = useQuery<
    MemberNewInsuranceCard_Query,
    MemberNewInsuranceCard_QueryVariables
  >(MEMBER_NEW_INSURANCE_CARD_QUERY, { variables: { personId: personID } });

  const insuranceSubscriberByPersondId = useQuery<
    getInsuranceSubscriberByPersonId,
    getInsuranceSubscriberByPersonIdVariables
  >(GET_INSURANCE_SUBSCRIBER_BY_PERSON_ID, {
    variables: {
      active: true,
      personId: personID,
    },
  });

  const previousInsurance =
    insuranceSubscriberByPersondId.data?.getInsuranceSubscriberByPersonId;

  return (
    <>
      <ASectionEditable editable title="Basics">
        {({ editing, toggleEditing }) =>
          editing ? (
            <SectionForm>
              <MemberUpdate
                onClose={toggleEditing}
                onSave={toggleEditing}
                personID={personID}
              />
            </SectionForm>
          ) : (
            <SectionContent>
              <MemberBasics personID={personID} />
            </SectionContent>
          )
        }
      </ASectionEditable>
      {enableEmergencyContacts && (
        <ASectionEditable editable title="Emergency Contact Information">
          {({ editing, toggleEditing }) =>
            editing ? (
              <SectionForm>
                <MemberUpdateEmergencyContact
                  onCancel={toggleEditing}
                  onSave={toggleEditing}
                  personID={personID}
                />
              </SectionForm>
            ) : (
              <SectionContent>
                <MemberEmergencyContact personID={personID} />
              </SectionContent>
            )
          }
        </ASectionEditable>
      )}

      {enabledPanelManagement && (
        <ASectionEditable title="Care Team">
          <MemberProviders personID={personID} />
        </ASectionEditable>
      )}

      <ASectionEditable editable title="Third Party Services">
        {({ editing, toggleEditing }) =>
          editing ? (
            <SectionForm>
              <MemberThirdPartyIDsUpdate
                onClose={toggleEditing}
                onSave={toggleEditing}
                personID={personID}
              />
            </SectionForm>
          ) : (
            <SectionContent>
              <MemberThirdPartyIDs personID={personID} />
            </SectionContent>
          )
        }
      </ASectionEditable>

      <ASectionEditable
        // actions={<PlanPurchaseCreate icon personID={personID} />}
        actions={
          <Dropdown
            overlay={
              <Menu>
                <ADropdownMenuItem>
                  <PlanPurchaseCreate personID={personID} />
                </ADropdownMenuItem>
                <ADropdownMenuItem>
                  <EnterprisePlanCreate personID={personID} />
                </ADropdownMenuItem>
              </Menu>
            }
            placement="topCenter"
            trigger={['click']}
          >
            <DropdownIcon data-testid="ACard_dropdown" primary type="more" />
          </Dropdown>
        }
        title="Membership"
      >
        <SectionContent>
          <MemberMembership personID={personID} />
        </SectionContent>
      </ASectionEditable>

      <ASectionEditable
        actions={
          <Dropdown
            overlay={
              <Menu>
                {!previousInsurance && (
                  <ADropdownMenuItem>
                    <EditInsuranceForm
                      onSuccess={() => refetch()}
                      personID={personID}
                    />
                  </ADropdownMenuItem>
                )}
                <ADropdownMenuItem>
                  <PaymentCardAdd personID={personID} />
                </ADropdownMenuItem>
              </Menu>
            }
            placement="topCenter"
            trigger={['click']}
          >
            <DropdownIcon data-testid="ACard_dropdown" primary type="more" />
          </Dropdown>
        }
        title="Payment & Insurance"
      >
        <SectionContent>
          <MemberPayment personID={personID} />
        </SectionContent>
      </ASectionEditable>
    </>
  );
};

// Styled components ////////////////////////////////
const SectionContent = styled.div`
  min-width: 300px;
  width: 50%;
  ${theme.layout.breakpointMixin.tabletPortraitDown} {
    width: 100%;
  }
`;

const SectionForm = styled.div`
  width: 80%;
  ${theme.layout.breakpointMixin.tabletPortraitDown} {
    width: 100%;
  }
`;

const DropdownIcon = styled(AIconClickable)`
  &&& {
    float: right;
    font-size: ${theme.font.size.l};
  }
`;

export { MemberAccountPage };
