import gql from 'graphql-tag';

import { MemberBasics_Fragment_member } from 'app/components/organisms/MemberBasics/fragment';
import { MemberHeader_Fragment_member } from 'app/components/organisms/MemberHeader/fragment';

import { MemberUpdate_Fragment_member } from './fragment';

export const MEMBER_UPDATE_QUERY = gql`
  query MemberUpdate_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      ...MemberUpdate_Fragment_member
      enrolledBy
    }
  }
  ${MemberUpdate_Fragment_member}
`;

export const MEMBER_UPDATE_MUTATION = gql`
  mutation MemberUpdate_Mutation($input: PapiUpdatePersonInput!) {
    updatePapiPerson(input: $input) {
      id
      role
      ...MemberBasics_Fragment_member
      ...MemberHeader_Fragment_member
      ...MemberUpdate_Fragment_member
    }
  }
  ${MemberBasics_Fragment_member}
  ${MemberHeader_Fragment_member}
  ${MemberUpdate_Fragment_member}
`;
