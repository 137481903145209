import { Tag } from 'antd';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

export const ATag = styled(Tag)`
  &&& {
    font-size: ${theme.font.size.xs};
    margin: 0;
    vertical-align: top;
    .ant-tag {
      cursor: default;
      font-family: ${theme.font.family.base};
    }
  }
`;
