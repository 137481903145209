import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { AFormSaveButtonGroup } from 'app/components/atoms/AFormSaveButtonGroup/AFormSaveButtonGroup';
import { ASelectAvailableProvider } from 'app/components/atoms/ASelectProvider/ASelectProvider';
import { MEMBER_VISITS_QUERY } from 'app/components/pages/MemberVisitsPage/MemberVisitsPage';
import { theme } from 'app/styles/theme';
import {
  AssignProvider_Mutation,
  AssignProvider_MutationVariables,
} from 'app/types/generated/AssignProvider_Mutation';
import { PapiUpdatePersonInput } from 'app/types/generated/globalTypes';
import {
  MemberProviders_Query_member_assignedCoach,
  MemberProviders_Query_member_assignedDoctor,
} from 'app/types/generated/MemberProviders_Query';
import { toTitleCase } from 'app/utils/string';
import { STATUS_MESSAGE } from 'constants/message';
import { PROVIDER_TYPE } from 'constants/provider';

import { MemberProvidersProps } from './MemberProviders.story';
import { ASSIGN_PROVIDER_MUTATION } from './query';

enum AssignProviderStep {
  SelectProvider,
  ConfirmSelection,
}

const AssignProviderModal: FC<{
  member: MemberProvidersProps;
  onCancel: () => void;
  onComplete: () => void;
  provider:
    | MemberProviders_Query_member_assignedCoach
    | MemberProviders_Query_member_assignedDoctor
    | null;
  providerType: PROVIDER_TYPE;
  visible: boolean;
}> = ({ member, onCancel, onComplete, provider, providerType, visible }) => {
  const providerFriendlyType =
    providerType === 'clinician'
      ? 'clinician'
      : providerType === 'health-coach'
      ? 'health coach'
      : 'member advocate';
  const memberFirstName = member.firstName;

  const [selectedProviderID, setSelectedProviderID] = useState<
    string | undefined
  >(provider?.id);

  const [selectedProviderName, setSelectedProviderName] = useState<
    string | undefined
  >(provider?.displayName);

  useEffect(() => {
    if (visible) {
      setSelectProviderRefresh(Date.now());
    }
  }, [visible]);

  useEffect(() => {
    if (provider) {
      setSelectedProviderName(provider.displayName);
      setSelectedProviderID(provider.id);
    } else {
      setSelectedProviderName(undefined);
      setSelectedProviderID(undefined);
    }
  }, [provider]);

  const resetAndClose = useCallback(() => {
    setCurrentStep(AssignProviderStep.SelectProvider);
    setSelectedProviderID(provider?.id);
    setSelectedProviderName(provider?.displayName);
    onCancel();
  }, [onCancel, provider]);

  const [currentStep, setCurrentStep] = useState<AssignProviderStep>(
    AssignProviderStep.SelectProvider
  );
  const [selectProviderRefresh, setSelectProviderRefresh] = useState<
    number | undefined
  >();

  const [assignProvider, { error, loading }] = useMutation<
    AssignProvider_Mutation,
    AssignProvider_MutationVariables
  >(ASSIGN_PROVIDER_MUTATION);

  const okButtonText = useMemo(() => {
    if (currentStep === AssignProviderStep.SelectProvider) {
      return provider ? 'Reassign' : 'Assign';
    }
    return 'Confirm';
  }, [currentStep, provider]);

  const modalTitleText = useMemo(() => {
    if (currentStep === AssignProviderStep.SelectProvider) {
      return `Editing Assigned ${toTitleCase(providerFriendlyType)}`;
    }
    return 'Confirming assignment';
  }, [currentStep, providerFriendlyType]);

  const isNextDisabled =
    (selectedProviderID && provider?.id !== selectedProviderID) ||
    currentStep === AssignProviderStep.ConfirmSelection
      ? false
      : true;

  const onNext = useCallback(async () => {
    if (currentStep === AssignProviderStep.SelectProvider) {
      setCurrentStep(AssignProviderStep.ConfirmSelection);
      return;
    }
    const input: PapiUpdatePersonInput = { id: member.id };
    if (providerType === 'clinician') {
      input.assignedDoctorID = selectedProviderID;
    } else if (providerType === 'health-coach') {
      input.assignedCoachID = selectedProviderID;
    }
    try {
      await assignProvider({
        refetchQueries: [
          {
            query: MEMBER_VISITS_QUERY,
            variables: { personID: member.id },
          },
        ],
        variables: { input },
      });

      if (error) {
        message.error(STATUS_MESSAGE.assignProvider.error.general);
        return;
      }
      message.success(STATUS_MESSAGE.assignProvider.success);
      setCurrentStep(AssignProviderStep.SelectProvider);
      onComplete();
    } catch (_) {
      message.error(STATUS_MESSAGE.assignProvider.error.general);
    }
  }, [
    assignProvider,
    currentStep,
    error,
    member.id,
    onComplete,
    providerType,
    selectedProviderID,
  ]);

  return (
    <Modal
      footer={
        <>
          <AFormSaveButtonGroup
            onCancel={() => {
              resetAndClose();
            }}
            onSave={onNext}
            saveDisabled={isNextDisabled}
            saveText={okButtonText}
            saving={loading}
          />
        </>
      }
      onCancel={resetAndClose}
      title={modalTitleText}
      visible={visible}
    >
      {currentStep === AssignProviderStep.SelectProvider ? (
        <>
          <Notice>
            Available providers are pre-filtered by member's center
          </Notice>
          <ASelectAvailableProvider
            allowClear={false}
            memberId={member.id}
            onSelectProvider={(selectedProvider) => {
              setSelectedProviderID(selectedProvider?.id);
              setSelectedProviderName(selectedProvider?.displayName);
            }}
            providerType={providerType}
            value={selectedProviderName}
            refresh={selectProviderRefresh}
          />
        </>
      ) : (
        <>
          <div>
            {selectedProviderName} will become {memberFirstName}'s assigned{' '}
            {providerFriendlyType}. Then two things will happen:
          </div>
          <ul>
            <PaddedLi>
              {memberFirstName} will be asked to schedule their next{' '}
              {providerFriendlyType} visit with {selectedProviderName}.
            </PaddedLi>
            <PaddedLi>
              All {providerFriendlyType} messaging from {memberFirstName} will
              be routed to {selectedProviderName}
            </PaddedLi>
          </ul>
        </>
      )}
    </Modal>
  );
};

const Notice = styled.div`
  color: ${theme.color.middleGrey};
  font-size: ${theme.font.size.s};
  margin-bottom: ${theme.space.s};
`;

const PaddedLi = styled.li`
  padding-top: 8px;
`;

export { AssignProviderModal };
