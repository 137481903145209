import gql from 'graphql-tag';

import { PaymentCardDelete_Fragment_card } from 'app/components/organisms/PaymentCardDelete/fragment';
import { PaymentCardSetDefault_Fragment_card } from 'app/components/organisms/PaymentCardSetDefault/fragment';

export const MemberPaymentCard_Fragment_card = gql`
  fragment MemberPaymentCard_Fragment_card on PapiPaymentCard {
    id
    deleted
    expiryMonth
    expiryYear
    last4Digits
    name
    ...PaymentCardDelete_Fragment_card
    ...PaymentCardSetDefault_Fragment_card
  }
  ${PaymentCardDelete_Fragment_card}
  ${PaymentCardSetDefault_Fragment_card}
`;
