import capitalize from 'lodash/capitalize';
import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components/macro';

import {
  helpscoutIcon,
  hintIcon,
  hubspotIcon,
  mdhqIcon,
  stripeIcon,
} from 'static/images/icons';

// Types & constants ////////////////////////////////
type ThirdParty = 'helpscout' | 'hint' | 'hubspot' | 'mdhq' | 'stripe';
interface Props extends ImgHTMLAttributes<any> {
  service: ThirdParty;
}

/** Icon for third-party services */
const AIconThirdParty: FC<Props> = ({ alt, service }) => {
  return (
    <ImgStyled
      alt={alt || capitalize(service)}
      service={service}
      src={
        service === 'helpscout'
          ? helpscoutIcon
          : service === 'hubspot'
          ? hubspotIcon
          : service === 'mdhq'
          ? mdhqIcon
          : service === 'stripe'
          ? stripeIcon
          : service === 'hint'
          ? hintIcon
          : undefined
      }
    />
  );
};

// Styled components ////////////////////////////////
const ImgStyled = styled.img<{ service: ThirdParty }>`
  border-radius: ${({ service }) => (service === 'stripe' ? 50 : 0)}%;
  cursor: ${({ service }) => (service === 'hint' ? 'default' : 'pointer')};
  height: 1rem;
  position: relative;
  top: 2px;
  transform: scale(${({ service }) => (service === 'stripe' ? 1.25 : 1)});
  vertical-align: baseline;
  width: 1rem;
`;

export { AIconThirdParty };
