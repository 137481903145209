import { useQuery } from '@apollo/react-hooks';
import partition from 'lodash/partition';
import React, { FC } from 'react';

import {
  ASelectField,
  ASelectFieldProps,
} from 'app/components/deprecated/ASelectField/ASelectField';
import {
  ASelectPlanField_Query,
  ASelectPlanField_Query_plans_edges_node,
} from 'app/types/generated/ASelectPlanField_Query';
import { compareLabel } from 'app/utils/sort';
import { isEveryWordIncluded } from 'app/utils/string';

import { A_SELECT_PLAN_FIELD_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props extends Omit<ASelectFieldProps, 'selectOptions'> {
  /** @param enabledFilter function that returns true to enable plan selection & false to disable selection */
  enabledFilter?: (plan: ASelectPlanField_Query_plans_edges_node) => boolean;
  initialValue?: string | null;
}

/** Deprecated: Antd form field, being deprecated to use react-hook-form */
const ASelectPlanField: FC<Props> = ({
  enabledFilter,
  label = 'Plan',
  ...props
}) => {
  const { data, loading } = useQuery<ASelectPlanField_Query>(
    A_SELECT_PLAN_FIELD_QUERY
  );

  const plans = data?.plans?.edges.map(({ node }) => node) || [];
  const [enabled, disabled] = partition(
    plans,
    enabledFilter ? enabledFilter : () => true
  );

  return (
    <ASelectField
      filterOption={(input, option) =>
        option.props.title // title = PapiPlan.displayName
          ? isEveryWordIncluded(input, option.props.title)
          : false
      }
      label={label}
      loading={loading}
      placeholder="Select or search"
      selectOptions={[
        ...enabled
          .map((plan) => ({
            label: plan.displayName,
            title: plan.displayName,
            value: plan.id,
          }))
          .sort(compareLabel),
        ...disabled
          .map((plan) => ({
            disabled: true,
            label: plan.displayName,
            title: plan.displayName,
            value: plan.id,
          }))
          .sort(compareLabel),
      ]}
      showSearch
      {...props}
    />
  );
};

export { ASelectPlanField };
