import { LDClient } from 'launchdarkly-js-client-sdk';
import { Context, createContext } from 'react';

export type IFeatureFlagContext = LDClient;

/**
 * Basic Launch Darkly feature flag usage:
 * const featureFlags = useContext(FeatureFlagContext);
 * if (!featureFlags.variation('$FLAG-NAME', $DEFAULT-VALUE)) {
 *   return STATUS_MESSAGE.warning.underDevelopment;
 * }
 * Then do stuff that only shows if flag returns true for the user.
 * In your tests & stories, you'll need to mockFeatureFlagContext()
 */

const FeatureFlagContext: Context<IFeatureFlagContext> = createContext<
  IFeatureFlagContext
>({} as LDClient);

export { FeatureFlagContext };
