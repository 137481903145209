import styled from 'styled-components/macro';

/**
 * A div with display:flex, center-aligned items, and row wrapping
 */
export const AFlexbox = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;
