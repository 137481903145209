import { DateTime, Interval } from 'luxon';
import React, { ReactNode, memo } from 'react';
import styled from 'styled-components/macro';

import { CalendarCellCSS } from 'app/styles/calendar';
import { theme } from 'app/styles/theme';
import { CellInfo } from 'app/types/calendar';

// Types & constants ////////////////////////////
interface Props {
  children?: (options: { isHourStart: boolean; start: DateTime }) => ReactNode;
  getDateRangeForVisualGrid: (cell: CellInfo) => Interval[];
  hoursColumn?: boolean;
  timeIndex: number;
}

/** Calendar cells that make up the actual calendar grid */
const CalendarCell = memo(
  ({ children, getDateRangeForVisualGrid, hoursColumn, timeIndex }: Props) => {
    /** takes cell info along with original date passed from the calendar
      and returns date range. time index represents the horizontal row */
    const [{ start }] = getDateRangeForVisualGrid({
      endX: 0,
      endY: timeIndex + 1,
      hoursColumn,
      spanX: 1,
      spanY: 1,
      startX: 0,
      startY: timeIndex,
    });

    const isHourStart = start.get('minute') === 0;

    return (
      <Styles
        data-testid={isHourStart && 'CalendarCell_isHourStart'}
        id={`CalendarCell_${timeIndex}`}
        isHourStart={isHourStart}
      >
        {children && children({ isHourStart, start })}
      </Styles>
    );
  }
);

// Styled components ////////////////////////////
const Styles = styled.div<{ isHourStart: boolean }>`
  ${CalendarCellCSS};
  border-right: 1px solid ${theme.color.calendar.border};
  border-top-color: ${theme.color.calendar.border};
  border-top-style: ${({ isHourStart }) => (isHourStart ? 'solid' : 'dashed')};
  border-top-width: 1px;
`;

export { CalendarCell };
