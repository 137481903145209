import React, { FC } from 'react';

import { ASelect, ASelectProps } from 'app/components/atoms/ASelect/ASelect';
import { PapiAcuityInstance } from 'app/types/generated/globalTypes';
import { compareLabel } from 'app/utils/sort';

// Types & constants ////////////////////////////////
type Props = Omit<ASelectProps, 'selectOptions'>;

/** Select Acuity instance: NYC, LA, or SF */
const ASelectAcuityInstance: FC<Props> = (props) => {
  return (
    <ASelect
      selectOptions={Object.values(PapiAcuityInstance)
        .map((instance) => ({
          label: instance.replace('ACUITY_', ''),
          value: instance,
        }))
        .sort(compareLabel)}
      {...props}
    />
  );
};

export { ASelectAcuityInstance };
