import { Form, Select } from 'antd';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/lib/form/Form';
import { SelectProps } from 'antd/lib/select';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { FIELD_VALIDATION } from 'constants/form';

// Types & constants ////////////////////////////////
export interface ISelectFieldOption {
  disabled?: boolean;
  label: ReactNode;
  title?: string;
  value: string | number;
}

export interface ASelectFieldProps extends SelectProps, AFormFieldWrapperProps {
  antdForm: WrappedFormUtils;
  customRender?: (selectionOption: ISelectFieldOption) => ReactNode;
  initialValue?: string | number | string[] | number[] | null;
  label?: string;
  labelInfo?: string;
  name: string;
  options?: GetFieldDecoratorOptions;
  required?: boolean;
  selectOptions: ISelectFieldOption[];
}

/** Deprecated: Antd form field, being deprecated to use react-hook-form */
const ASelectField: FC<ASelectFieldProps> = ({
  antdForm,
  customRender,
  customWidth,
  initialValue,
  label,
  labelInfo,
  name,
  options,
  required,
  selectOptions,
  show,
  ...props
}) => {
  return (
    <AFormFieldWrapper antd customWidth={customWidth} show={show}>
      <FormItemStyled
        colon={false}
        label={
          label && (
            <ALabel black help={labelInfo} inline>
              {label}
            </ALabel>
          )
        }
      >
        {antdForm.getFieldDecorator(name, {
          ...options,
          initialValue,
          rules: [
            ...(options?.rules || []),
            ...(required ? [FIELD_VALIDATION.required] : []),
          ],
          validateTrigger: ['onBlur', 'onChange'],
        })(
          <Select
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            placeholder="Select"
            {...props}
          >
            {selectOptions.map(
              customRender ||
                (({ disabled, label, title, value }) => (
                  <SelectOptionStyled
                    disabled={disabled}
                    key={value}
                    title={title}
                    value={value}
                  >
                    {label}
                  </SelectOptionStyled>
                ))
            )}
          </Select>
        )}
      </FormItemStyled>
    </AFormFieldWrapper>
  );
};

// Styled components ////////////////////////////////
const FormItemStyled = styled(Form.Item)`
  &&& {
    .ant-select {
      width: 100%;
    }
    .ant-select-remove-icon {
      vertical-align: text-bottom;
    }
  }
`;

const SelectOptionStyled = styled(Select.Option)`
  &&& {
    width: 100%;
  }
`;

export { ASelectField };
