import { Skeleton } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { ACard } from 'app/components/atoms/ACard/ACard';
import { PaymentCardDelete } from 'app/components/organisms/PaymentCardDelete/PaymentCardDelete';
import { PaymentCardSetDefault } from 'app/components/organisms/PaymentCardSetDefault/PaymentCardSetDefault';
import { theme } from 'app/styles/theme';
import { MemberPaymentCard_Fragment_card } from 'app/types/generated/MemberPaymentCard_Fragment_card';
import { isValidPaymentCard } from 'app/utils/member';

// Types & constants ////////////////////////////////
interface Props {
  card?: MemberPaymentCard_Fragment_card;
  defaultStyle?: boolean;
  editable?: boolean;
  loading?: boolean;
}

/** Displays member's payment card */
const MemberPaymentCard: FC<Props> = ({
  card,
  defaultStyle,
  editable = true,
  loading,
}) => {
  if (loading || !card) {
    return (
      <Styles>
        <ACard>
          <Skeleton
            active
            paragraph={{ rows: 2, width: ['50%', '100%'] }}
            title={false}
          />
        </ACard>
      </Styles>
    );
  }

  const { deleted, expiryMonth, expiryYear, last4Digits, name } = card;
  const isValid = isValidPaymentCard(card);

  return (
    <Styles inactive={!defaultStyle && deleted} valid={isValid}>
      <ACard
        dropdownItems={
          editable && deleted // deleted = card is not the current payment method
            ? [
                <PaymentCardSetDefault card={card} />,
                <PaymentCardDelete card={card} />,
              ]
            : undefined
        }
        error={!isValid}
        title={name}
      >
        <Numbers>
          <div>
            Exp. {expiryMonth}/{expiryYear}
          </div>
          <div>****{last4Digits}</div>
        </Numbers>
      </ACard>
    </Styles>
  );
};

// Styled components ////////////////////////////////
const Numbers = styled.div`
  display: flex;
  font-size: ${theme.font.size.s};
  justify-content: space-between;
`;

const Styles = styled.div<{
  inactive?: boolean;
  valid?: boolean;
}>`
  width: 425px;
  * {
    color: ${({ inactive, valid }) =>
      inactive
        ? theme.color.textLighter
        : valid === false && theme.color.error};
  }
`;

export { MemberPaymentCard };
