import { useLazyQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import { ApolloError } from 'apollo-client';
import React, { FC } from 'react';

import {
  MemberPasswordReset_Query,
  MemberPasswordReset_QueryVariables,
} from 'app/types/generated/MemberPasswordReset_Query';
import { displayErrors } from 'app/utils/app';
import { STATUS_MESSAGE } from 'constants/message';

import { MEMBER_PASSWORD_RESET_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  email: string;
  setLoading?: (loading: boolean) => void;
}

/** Click to send member a password reset email */
const MemberPasswordReset: FC<Props> = ({ email, setLoading }) => {
  const onCompleted = (data: MemberPasswordReset_Query): void => {
    setLoading?.(false);
    if (data.resetPassword) {
      message.success(STATUS_MESSAGE.memberPasswordReset.success);
    } else {
      message.error(STATUS_MESSAGE.memberPasswordReset.error.general);
    }
  };

  const onError = (err?: ApolloError): void => {
    setLoading?.(false);
    if (err) {
      displayErrors(err, STATUS_MESSAGE.memberPasswordReset.error.general);
    } else {
      message.error(STATUS_MESSAGE.memberPasswordReset.error.general);
    }
  };

  const [sendPasswordReset, { called, data, refetch }] = useLazyQuery<
    MemberPasswordReset_Query,
    MemberPasswordReset_QueryVariables
  >(MEMBER_PASSWORD_RESET_QUERY, {
    onCompleted,
    onError,
  });

  const onClick = async (): Promise<void> => {
    setLoading?.(true);
    /**
     * useLazyQuery() can only query once, and has to be refetched thereafter.
     * onCompleted isn't called on refetch, so we implement it here ourselves.
     * Behavior here is weird because sendPasswordReset behaves like a mutation
     * to the user, but it's a query in our system
     */
    if (!called) {
      sendPasswordReset({ variables: { email } });
    } else {
      try {
        await refetch({ email });
        if (data) {
          onCompleted(data);
        } else {
          onError();
        }
      } catch (err) {
        onError(err);
      }
    }
  };

  return <div onClick={onClick}>Send password reset</div>;
};

export { MemberPasswordReset };
