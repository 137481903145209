import { useQuery } from '@apollo/react-hooks';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AIconInline } from 'app/components/atoms/AIconInline/AIconInline';
import { AIconLocation } from 'app/components/atoms/AIconLocation/AIconLocation';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { ASectionEditable } from 'app/components/atoms/ASectionEditable/ASectionEditable';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { MemberHeader } from 'app/components/organisms/MemberHeader/MemberHeader';
import { VisitDetailsMemberVisits } from 'app/components/organisms/VisitDetailsMemberVisits/VisitDetailsMemberVisits';
import { GET_VISIT } from 'app/components/pages/CalendarPage/query';
import { theme } from 'app/styles/theme';
import { getVisit } from 'app/types/generated/getVisit';
import { PapiAppointmentFormat } from 'app/types/generated/globalTypes';
import { STATUS_MESSAGE } from 'constants/message';

// Types & constants ////////////////////////////////
interface Props {
  visitId: string;
}

/** Details about a visit and its relevant member */
const VisitDetails: FC<Props> = ({ visitId }) => {
  const { data, loading } = useQuery<getVisit>(GET_VISIT, {
    fetchPolicy: 'network-only',
    variables: {
      id: visitId,
    },
  });
  const {
    categoryDetails,
    center,
    endTime,
    format,
    isComplimentary,
    location,
    member,
    metadata,
    startTime,
  } = data?.getAppointment ?? {};

  const personID = member?.id || null;
  const isVirtual = format === PapiAppointmentFormat.VIRTUAL;
  const startDT = DateTime.fromISO(startTime!);
  const endDT = DateTime.fromISO(endTime!);
  const visitLength = endDT.diff(startDT, 'minutes').as('minutes');
  const parsedPhone =
    member?.phoneNumber && parsePhoneNumberFromString(member.phoneNumber);

  if (loading) {
    return (
      <VisitDetailsContainer>
        <ALoading centered />
      </VisitDetailsContainer>
    );
  }

  return (
    <VisitDetailsContainer>
      {personID && (
        <HeaderContainer>
          <MemberHeader
            excludeLinks
            hideAdminOptions
            personID={personID}
            shortForm
          />
        </HeaderContainer>
      )}

      <ASectionEditable padding="l" title="Visit detail">
        <>
          <ASectionBtmMargin margin="s">
            <VisitTypeContainer>
              <VisitTypeTag color={categoryDetails?.color || theme.color.red}>
                {categoryDetails?.name || 'Unknown'}
              </VisitTypeTag>
              {isComplimentary && (
                <VisitComplimentary>Complimentary</VisitComplimentary>
              )}
            </VisitTypeContainer>
          </ASectionBtmMargin>
          <div>{startDT.toFormat('cccc, MMMM d')}</div>
          <div>
            {`${startDT.toFormat('h:mma').toLowerCase()} - ${endDT
              .toFormat('h:mma')
              .toLowerCase()} ${endDT.toFormat('ZZZZ')}`}
          </div>
          <div>{`${visitLength} minutes`}</div>
        </>
      </ASectionEditable>

      <ASectionEditable padding="l" title="Location">
        {isVirtual ? (
          <>
            <LocationHeader>
              <AIconInline
                marginPosition="right"
                marginSize="s"
                type="customer-service"
              />
              <ATextBold>Online</ATextBold>
            </LocationHeader>
            <div>
              {location ? (
                <a href={location} rel="noopener noreferrer" target="_blank">
                  Click to open Zoom video meeting
                </a>
              ) : (
                <ATextLight lighter>Missing meeting URL</ATextLight>
              )}
            </div>
          </>
        ) : (
          <>
            <LocationHeader>
              <AIconLocation />
              <LocationTitle>In person</LocationTitle>
            </LocationHeader>
            <div>{center?.name}</div>
          </>
        )}
      </ASectionEditable>

      <ASectionEditable padding="l" title="Note">
        <>{metadata || <ATextLight lighter>–</ATextLight>}</>
      </ASectionEditable>

      <ASectionEditable padding="l" title="Member basics">
        {!member ? (
          <ATextLight>
            Member info is missing. {STATUS_MESSAGE.bugReport}
          </ATextLight>
        ) : (
          <>
            <div>
              <ATextBold>
                {member?.currentPlan?.extensionOf?.plan?.product?.displayName ||
                  member?.currentPlan?.plan?.product?.displayName}
              </ATextBold>
            </div>

            <ASectionBtmMargin>
              {member?.joinedPracticeDate && (
                <div>{`Member since: ${DateTime.fromISO(
                  member?.joinedPracticeDate
                ).toLocaleString(DateTime.DATE_SHORT)}`}</div>
              )}
              {member?.currentPlan?.nextRenewalDate && (
                <div>{`Next renewal date: ${DateTime.fromISO(
                  member?.currentPlan?.nextRenewalDate || ''
                ).toLocaleString(DateTime.DATE_SHORT)}`}</div>
              )}
            </ASectionBtmMargin>

            <div>
              {member?.email && <div>{member?.email}</div>}
              {parsedPhone ? (
                parsedPhone.countryCallingCode === '1' ? (
                  parsedPhone.formatNational()
                ) : (
                  parsedPhone.formatInternational()
                )
              ) : (
                <ATextLight>No phone</ATextLight>
              )}
            </div>
          </>
        )}
      </ASectionEditable>

      {member?.id && (
        <ASectionEditable padding="l" title="Visits">
          <VisitDetailsMemberVisits memberId={member.id} visitId={visitId} />
        </ASectionEditable>
      )}
    </VisitDetailsContainer>
  );
};

// Styled components ////////////////////////////////
const HeaderContainer = styled.div`
  margin-bottom: -${theme.space.m};
`;

const LocationHeader = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${theme.space.xs};
`;
const LocationTitle = styled(ATextBold)`
  &&& {
    display: inline-block;
    margin-left: ${theme.space.s};
  }
`;

const VisitDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${theme.layout.drawerSizeDefault}px;
`;

const VisitTypeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const VisitComplimentary = styled(ATextLight)`
  font-size: ${theme.font.size.s};
`;
const VisitTypeTag = styled.span<{
  color: string;
}>`
  background-color: ${({ color }) => color};
  border-radius: ${theme.space.xs};
  color: ${theme.color.white};
  font-weight: ${theme.font.weight.medium};
  padding: ${theme.space.xs} ${theme.space.sm};
`;

export { VisitDetails };
