import { Button, Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AIconInline } from 'app/components/atoms/AIconInline/AIconInline';
import { ATextClickable } from 'app/components/atoms/ATextClickable/ATextClickable';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';

// Types & constants ////////////////////////////////
interface Props extends Omit<ButtonProps, 'disabled'> {
  /** @param buttonBorder renders as clickable text instead of button if false */
  buttonBorder?: boolean;
  disabled?:
    | boolean
    | {
        message: string;
        tooltipPlacement?: TooltipPlacement;
      };
  /** @param iconType Antd icon type */
  iconType?: string;
}

/** General button or clickable text that functions as a button */
const AButton: FC<Props> = ({
  buttonBorder = true,
  children,
  disabled,
  iconType,
  onClick,
  ...props
}) => {
  if (disabled) {
    const disabledButton = buttonBorder ? (
      <Button disabled icon={iconType} {...props}>
        {children}
      </Button>
    ) : (
      <DisabledText lighter>
        {iconType && <NonButtonIcon marginPosition="right" type={iconType} />}
        {children}
      </DisabledText>
    );

    return typeof disabled === 'object' ? (
      <Tooltip placement={disabled.tooltipPlacement} title={disabled.message}>
        {disabledButton}
      </Tooltip>
    ) : (
      disabledButton
    );
  }

  return buttonBorder ? (
    <Button icon={iconType} onClick={onClick} {...props}>
      {children}
    </Button>
  ) : (
    <ATextClickable onClick={onClick}>
      {iconType && <NonButtonIcon marginPosition="right" type={iconType} />}
      {children}
    </ATextClickable>
  );
};

// Styled components ////////////////////////////////
const DisabledText = styled(ATextLight)`
  &&& {
    cursor: not-allowed;
  }
`;

const NonButtonIcon = styled(AIconInline)`
  &&& {
    bottom: 1px;
  }
`;

export { AButton };
