import gql from 'graphql-tag';

export const PROVIDER_PANEL_QUERY = gql`
  query ProviderPanel_Query($providerID: ID!) {
    provider: getProvider(id: $providerID) {
      assignedCareManager {
        id
      }
      id
      targetPanelSize
      currentPanelSize
    }
  }
`;

export const PROVIDER_PANEL_MUTATION = gql`
  mutation ProviderPanel_Mutation($input: PapiUpdateProviderInput!) {
    updateProvider(input: $input) {
      id
      targetPanelSize
    }
  }
`;
