import gql from 'graphql-tag';

export const CREATE_APPOINTMENT_MUTATION = gql`
  mutation CreateAppointment_Mutation($input: PapiCreateAppointmentInput!) {
    createAppointment(input: $input) {
      id
    }
  }
`;

export const VISIT_CARD_LIST_APPOINTMENT_TYPES_QUERY = gql`
  query VisitCardListAppointmentTypesQuery($productType: PapiProductType!) {
    listAppointmentTypes(productType: $productType) {
      name: shortName
      duration
      group
      typeID: id
    }
  }
`;

export const TRANSFER_CARE_MUTATION = gql`
  mutation TransferCare_Mutation($input: PapiTransferCareInput!) {
    transferCare(input: $input) {
      id
    }
  }
`;

export const LIST_PROVIDERS_BY_TYPE_QUERY = gql`
  query ListProviders_Query($providerType: PapiProviderType!) {
    listProvidersByType(providerType: $providerType) {
      id
      firstName
      lastName
      type
    }
  }
`;
