import gql from 'graphql-tag';

export const ASelectCouponField_Fragment_coupon = gql`
  fragment ASelectCouponField_Fragment_coupon on PapiCoupon {
    centers {
      id
    }
    discount {
      ... on PapiFlatDiscount {
        amountOffCents
      }
      ... on PapiPercentageDiscount {
        percentOff
      }
    }
    duration {
      ... on PapiCouponApplies {
        apply
      }
      ... on PapiCouponRepeats {
        months
      }
    }
    plans {
      id
    }
    products {
      type
    }
    slug
  }
`;
