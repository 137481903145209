import { Button, Icon, Spin, Typography } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components/macro';

import { AIconLabeled } from 'app/components/atoms/AIconLabeled/AIconLabeled';
import { theme } from 'app/styles/theme';

const { Paragraph, Text, Title } = Typography;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Types & constants ////////////////////////////////
const PADDING_VERTICAL = 20;
const PADDING_HORIZONTAL = 40;
const MAX_ZOOM = 3.0;
const MIN_ZOOM = 0.5;

interface Props {
  onLoadError?: (error: any) => void;
  onLoadSuccess?: (success: any) => void;
  onReload?: () => void;
  url: string;
}

/** Rendering pdf with zoom, rotate etc. functionality */
const PdfViewer: FC<Props> = ({
  onLoadError,
  onLoadSuccess,
  onReload,
  url,
}) => {
  const pdfContainerRef = useRef(null);
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rotate, setRotate] = useState<number>(0);
  const [scale, setScale] = useState<number>(1.0);
  const [containerSize, setContainerSize] = useState<{ width: number }>({
    width: 0,
  });
  const [isError, setIsError] = useState<boolean>(false);

  const isPreviousPageDisabled = pageNumber <= 1;
  const isNextPageDisabled = pageNumber >= numPages;

  useEffect(() => {
    const handleResize = (): void => {
      const containerNode: any = pdfContainerRef.current;
      if (containerNode) {
        const { width } = containerNode.getBoundingClientRect();

        setContainerSize({ width: width - (PADDING_VERTICAL + 4) * 2 });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages, ...rest }): void => {
    setNumPages(numPages);
    setLoaded(true);
    if (onLoadSuccess) onLoadSuccess({ numPages, ...rest });
  };

  const onDocumentLoadError = (error): void => {
    setIsError(true);
    if (onLoadError) onLoadError(error);
  };

  const handleRotate = (): void => {
    const rotateBy = rotate === 0 ? 270 : rotate - 90;

    setRotate(rotateBy);
  };

  const handleZoomIn = (): void => {
    if (scale === MAX_ZOOM) return;

    const zoomBy = scale + 0.5;

    setScale(zoomBy);
  };

  const handleZoomOut = (): void => {
    if (scale === MIN_ZOOM) return;

    const zoomBy = scale - 0.5;

    setScale(zoomBy);
  };

  const handlePreviousPage = (): void => {
    if (isPreviousPageDisabled) return;

    const previousPage = pageNumber - 1;

    setPageNumber(previousPage);
  };

  const handleNextPage = (): void => {
    if (isNextPageDisabled) return;

    const nextPage = pageNumber + 1;

    setPageNumber(nextPage);
  };

  const handlePdfReload = (): void => {
    setIsError(false);
    if (onReload) onReload();
  };

  return (
    <StyledContainer ref={pdfContainerRef}>
      {!isError && (
        <StyledPdfContainer style={{ width: `${containerSize.width}px` }}>
          <StyledDocument
            file={url}
            loading=""
            onLoadError={onDocumentLoadError}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <StyledPage
              key={containerSize.width}
              loading=""
              pageNumber={pageNumber}
              renderMode="canvas"
              rotate={rotate}
              scale={scale}
              width={containerSize.width}
            />
          </StyledDocument>
        </StyledPdfContainer>
      )}

      {!isLoaded && !isError && (
        <StyledLoading>
          <Spin />
        </StyledLoading>
      )}

      {isLoaded && (
        <>
          <SideNavBar>
            <StyledActionButton
              disabled={scale === MAX_ZOOM}
              onClick={handleZoomIn}
            >
              <Icon type="zoom-in" />
            </StyledActionButton>

            <StyledActionButton
              disabled={scale === MIN_ZOOM}
              onClick={handleZoomOut}
            >
              <Icon type="zoom-out" />
            </StyledActionButton>

            <StyledActionButton onClick={handleRotate}>
              <Icon type="undo" />
            </StyledActionButton>
          </SideNavBar>

          <BottomNavBar>
            <Text>
              Page {pageNumber} of {numPages}
            </Text>
            <StyledPageIcons
              disabled={isPreviousPageDisabled}
              onClick={handlePreviousPage}
              type="left"
            />
            <StyledPageIcons
              disabled={isNextPageDisabled}
              onClick={handleNextPage}
              type="right"
            />
          </BottomNavBar>
        </>
      )}

      {isError && (
        <StyledErrorContainer>
          <Title level={4}>Unable to load PDF file</Title>
          <Button icon="reload" onClick={handlePdfReload}>
            Reload
          </Button>
          <Paragraph>or</Paragraph>
          <a download href={url} rel="noopener noreferrer" target="_blank">
            <AIconLabeled label="Try to download PDF" type="file-pdf" />
          </a>
        </StyledErrorContainer>
      )}
    </StyledContainer>
  );
};

// Styled components ////////////////////////////////
const StyledContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const StyledPdfContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding: ${PADDING_VERTICAL}px ${PADDING_HORIZONTAL};
  width: 100%;
  z-index: 1;
`;

const StyledDocument = styled(Document)`
  height: 100%;
  width: 100%;
`;

const StyledPage = styled(Page)`
  height: 100%;
  width: 100%;
`;

const BottomNavBar = styled.div`
  align-items: center;
  background-color: ${theme.color.heavyMetal};
  bottom: 0;
  color: ${theme.color.cloud};
  display: flex;
  flex-direction: row;
  height: ${PADDING_VERTICAL}px;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 10;

  & > span {
    color: ${theme.color.cloud};
  }
`;

const SideNavBar = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: ${PADDING_HORIZONTAL}px;
  z-index: 10;
`;

const StyledPageIcons = styled(Icon)<{ disabled?: boolean }>`
  &&& {
    margin-left: ${theme.space.xs};
    ${({ disabled }) => {
      return disabled
        ? `cursor: default; opacity: 0.5`
        : 'cursor: pointer; opacity: 1;';
    }};
  }
`;

const StyledActionButton = styled.div<{ disabled?: boolean }>`
  align-items: center;
  border: 1px solid ${theme.color.border};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: ${PADDING_HORIZONTAL}px;
  justify-content: center;
  margin-bottom: ${theme.space.sm};
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  width: ${PADDING_HORIZONTAL}px;
`;

const StyledLoading = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
`;

const StyledErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  h4 {
    margin: ${theme.space.sm} 0;
  }

  p {
    margin: ${theme.space.s} 0;
  }
`;

export { PdfViewer };
