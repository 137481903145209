import gql from 'graphql-tag';

import { MemberLinks_Fragment_member } from './fragment';

export const MEMBER_LINKS_QUERY = gql`
  query MemberLinks_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      ...MemberLinks_Fragment_member
    }
  }
  ${MemberLinks_Fragment_member}
`;
