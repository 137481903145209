import { useQuery } from '@apollo/react-hooks';
import partition from 'lodash/partition';
import React, { FC } from 'react';

import { ASelect, ASelectProps } from 'app/components/atoms/ASelect/ASelect';
import {
  ASelectCenter_Query,
  ASelectCenter_Query_centers,
} from 'app/types/generated/ASelectCenter_Query';
import { getCenterDisplayName, getCenterSearchIndex } from 'app/utils/center';
import { compareLabel } from 'app/utils/sort';

import { A_SELECT_CENTER_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props extends Omit<ASelectProps, 'selectOptions'> {
  filter?: (center: ASelectCenter_Query_centers) => boolean;
}

/** Select center field that can be used alone or with react-hook-form */
const ASelectCenter: FC<Props> = ({ filter, loading, ...props }) => {
  const { data, loading: centersLoading } = useQuery<ASelectCenter_Query>(
    A_SELECT_CENTER_QUERY
  );

  const centers = data?.centers || [];
  const filteredCenters = filter ? centers.filter(filter) : centers;

  const selectOptions = filteredCenters.map((center) => ({
    ...center,
    label: getCenterDisplayName(center),
    searchIndex: getCenterSearchIndex(center),
    value: center.id,
  }));
  const [inPersonCenters, virtualCenters] = partition(
    selectOptions,
    (center) => !center.isVirtual
  );

  return (
    <ASelect
      loading={loading || centersLoading}
      searchable
      selectOptions={[
        ...inPersonCenters.sort(compareLabel),
        ...virtualCenters.sort(compareLabel),
      ]}
      {...props}
    />
  );
};

export { ASelectCenter };
