import { DateTime } from 'luxon';

import {
  ArabellaMemberType,
  DocumentReviewType,
} from 'app/components/arabella/utils/types';
import { DocumentReviewList_Query_GetDocs_docs } from 'app/types/generated/DocumentReviewList_Query_GetDocs';
import { DocumentReviewList_Query_Persons_persons } from 'app/types/generated/DocumentReviewList_Query_Persons';
import { PapiInboxItemTriageStatus } from 'app/types/generated/globalTypes';
import { DOCUMENT_SOURCE_ATTRS } from 'constants/documentReview';

const formatDate = (s: string | null): string =>
  s ? DateTime.fromISO(s).toLocaleString(DateTime.DATE_SHORT) : '';

export const mapDocToDocReviewType = (
  doc: DocumentReviewList_Query_GetDocs_docs
): DocumentReviewType => {
  const isSaved = [
    PapiInboxItemTriageStatus.DONE,
    PapiInboxItemTriageStatus.TRASHED,
    PapiInboxItemTriageStatus.ARCHIVED,
  ].includes(doc.triageStatus);

  const newDoc: DocumentReviewType = {
    docType: doc.category,
    id: doc.id,
    pdfUrl: doc.url,
    saved: isSaved,
    uploadTypeIcon: DOCUMENT_SOURCE_ATTRS[doc.sourceCategory].icon,
    uploadTypeLabel: DOCUMENT_SOURCE_ATTRS[doc.sourceCategory].label,
  };

  if (doc.person) newDoc.member = mapPersonToMember(doc.person);
  return newDoc;
};

export const mapPersonToMember = (
  person: DocumentReviewList_Query_Persons_persons
): ArabellaMemberType => {
  const fullName =
    person.firstName +
    ' ' +
    (person.middleName ? person.middleName + ' ' : '') +
    person.lastName;

  const member: ArabellaMemberType = {
    birthDay: formatDate(person.dateOfBirth) || '',
    center: person.center ? person.center.abbreviatedName : '',
    dateJoined: formatDate(person.membershipTermsAcceptedAt) || '',
    fullName,
    id: person.id,
    mdhqID: person.mdhqID,
    product: person.currentPlan?.plan?.product.displayName ?? '',
  };

  if (person.mostRecentDoctor) {
    member.provider = {
      fullName: person.mostRecentDoctor.displayName,
      id: person.mostRecentDoctor.id,
      role: person.mostRecentDoctor.type,
    };
  }

  return member;
};
