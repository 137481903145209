import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { ADateField } from 'app/components/deprecated/ADateField/ADateField';
import { AForm, TFormFieldInfo } from 'app/components/deprecated/AForm/AForm';
import { AInputField } from 'app/components/deprecated/AInputField/AInputField';
import { APhoneField } from 'app/components/deprecated/APhoneField/APhoneField';
import { ASelectCenterField } from 'app/components/deprecated/ASelectCenterField/ASelectCenterField';
import { ASelectField } from 'app/components/deprecated/ASelectField/ASelectField';
import {
  PapiCreatePersonInput,
  PapiRole,
} from 'app/types/generated/globalTypes';
import {
  MemberCreate_Mutation,
  MemberCreate_MutationVariables,
} from 'app/types/generated/MemberCreate_Mutation';
import { displayErrors } from 'app/utils/app';
import { digitsOnly } from 'app/utils/string';
import { AppRoute } from 'constants/app';
import { SEXES } from 'constants/form';
import { STATUS_MESSAGE } from 'constants/message';

import { MEMBER_CREATE_MUTATION } from './query';

/** Form to create a member */
const MemberCreate: FC = () => {
  const history = useHistory();
  const [createMember, { loading: mutationLoading }] = useMutation<
    MemberCreate_Mutation,
    MemberCreate_MutationVariables
  >(MEMBER_CREATE_MUTATION);

  const onSave = async (input: PapiCreatePersonInput): Promise<void> => {
    try {
      const result = await createMember({
        variables: {
          input: {
            ...input,
            dateOfBirth: DateTime.fromJSDate(
              ((input.dateOfBirth! as unknown) as Moment).toDate()
            ).toISODate(),
            phoneNumber: `+${digitsOnly(input.phoneNumber)}`,
            role: PapiRole.PARSLEY_USER,
            sendPasswordReset: true,
          },
        },
      });
      if (result?.data) {
        message.success(STATUS_MESSAGE.memberCreate.success);
        history.push(`${AppRoute.Members}/${result.data.createPapiPerson.id}`);
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.memberCreate.error.general);
    }
  };

  return (
    <AForm
      button={{ icon: 'plus', type: 'primary' }}
      onSave={onSave}
      openAs="modal"
      openText="Add member"
      saveText="Add"
      saving={mutationLoading}
      savingText="Adding"
      title="New member"
    >
      {(form) => (
        <>
          {/* Center */}
          <AFormFieldRow>
            <ASelectCenterField
              antdForm={form}
              customWidth={{ percent: 40 }}
              label={fields.centerID.label}
              name={fields.centerID.name}
              required={fields.centerID.required}
            />
          </AFormFieldRow>

          {/* Name */}
          <AFormFieldRow>
            <AInputField
              antdForm={form}
              data-private
              disableAutoComplete
              label={fields.firstName.label}
              name={fields.firstName.name}
              required={fields.firstName.required}
            />
            <AInputField
              antdForm={form}
              customWidth={{ percent: 20 }}
              data-private
              disableAutoComplete
              label={fields.middleName.label}
              name={fields.middleName.name}
              required={fields.middleName.required}
            />
            <AInputField
              antdForm={form}
              data-private
              disableAutoComplete
              label={fields.lastName.label}
              name={fields.lastName.name}
              required={fields.lastName.required}
            />
          </AFormFieldRow>

          {/* DOB & biologicalSex */}
          <AFormFieldRow>
            <ADateField
              antdForm={form}
              label={fields.dateOfBirth.label}
              name={fields.dateOfBirth.name}
              required={fields.dateOfBirth.required}
            />
            <ASelectField
              antdForm={form}
              customWidth={{ percent: 30 }}
              label={fields.biologicalSex.label}
              name={fields.biologicalSex.name}
              required={fields.biologicalSex.required}
              selectOptions={SEXES}
            />
          </AFormFieldRow>

          {/* Contact info */}
          <AFormFieldRow>
            <APhoneField
              antdForm={form}
              data-private
              label={fields.phoneNumber.label}
              name={fields.phoneNumber.name}
              required={fields.phoneNumber.required}
            />
            <AInputField
              antdForm={form}
              customWidth={{ percent: 20 }}
              disableAutoComplete
              label={fields.addressPostalCode.label}
              maxLength={5}
              minLength={5}
              name={fields.addressPostalCode.name}
              required={fields.addressPostalCode.required}
            />
          </AFormFieldRow>
          <AFormFieldRow>
            <AInputField
              antdForm={form}
              customWidth={{ percent: 70 }}
              data-private
              disableAutoComplete
              email
              label={fields.email.label}
              name={fields.email.name}
              required={fields.email.required}
            />
          </AFormFieldRow>
          <AFormFieldRow>
            <AInputField
              antdForm={form}
              data-private
              disableAutoComplete
              label={fields.enrolledBy.label}
              name={fields.enrolledBy.name}
            />
          </AFormFieldRow>
        </>
      )}
    </AForm>
  );
};

/** Form field info */
const fields: Pick<
  TFormFieldInfo<PapiCreatePersonInput>,
  | 'addressPostalCode'
  | 'biologicalSex'
  | 'centerID'
  | 'dateOfBirth'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'phoneNumber'
  | 'enrolledBy'
> = {
  addressPostalCode: {
    label: 'Zipcode',
    name: 'addressPostalCode',
    required: true,
  },
  biologicalSex: {
    label: 'Sex',
    name: 'biologicalSex',
    required: true,
  },
  centerID: {
    label: 'Center',
    name: 'centerID',
    required: true,
  },
  dateOfBirth: {
    label: 'Date of birth',
    name: 'dateOfBirth',
    required: true,
  },
  email: {
    label: 'Email',
    name: 'email',
    required: true,
  },
  enrolledBy: {
    label: 'Enrolled by',
    name: 'enrolledBy',
  },
  firstName: {
    label: 'First name',
    name: 'firstName',
    required: true,
  },
  lastName: {
    label: 'Last name',
    name: 'lastName',
    required: true,
  },
  middleName: {
    label: 'Middle name',
    name: 'middleName',
    required: false,
  },
  phoneNumber: {
    label: 'Phone',
    name: 'phoneNumber',
    required: true,
  },
};

export { MemberCreate, fields };
