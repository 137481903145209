import { Button } from 'antd';
import React, { FC } from 'react';
import { FormContextValues, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { AIconInline } from 'app/components/atoms/AIconInline/AIconInline';
import { AIconLabeled } from 'app/components/atoms/AIconLabeled/AIconLabeled';
import { AInput } from 'app/components/atoms/AInput/AInput';
import { ASelect } from 'app/components/atoms/ASelect/ASelect';
import { ASelectDocumentType } from 'app/components/atoms/ASelectDocumentType/ASelectDocumentType';
import { theme } from 'app/styles/theme';
import { colorTheme } from 'app/styles/theme/color';
import {
  DocumentReviewList_Query_GetDocs_docs,
  DocumentReviewList_Query_GetDocs_docs_person,
  DocumentReviewList_Query_GetDocs_providers,
} from 'app/types/generated/DocumentReviewList_Query_GetDocs';
import {
  PapiInboxItemCategory,
  PapiProviderType,
} from 'app/types/generated/globalTypes';
import {
  DOCUMENT_ACTION_FIELDS,
  NO_MEMBER_ACTION_ATTRS,
  NO_MEMBER_ACTION_VALUES,
  NoMemberAction,
} from 'constants/documentReview';
import { STATUS_MESSAGE } from 'constants/message';

// Types & constants ////////////////////////////////
interface FormValues {
  docTitle: string;
  docType: PapiInboxItemCategory | 'trash';
  noMemberAction: NoMemberAction;
  providerID?: string;
  task: 'create' | 'dont-create';
}

export interface DocumentReviewToolbarProps {
  isSaved: boolean;
  isSaving: boolean;
  member?: DocumentReviewList_Query_GetDocs_docs_person;
  onDocSave: (data: DocumentReviewSaveData) => void;
  providers: DocumentReviewList_Query_GetDocs_providers[];
  selectedDoc: DocumentReviewList_Query_GetDocs_docs;
}

export type DocumentReviewToolbarPropsHook = DocumentReviewToolbarProps & {
  formHook: FormContextValues<FormValues>;
};

export interface DocumentReviewSaveData {
  createTask: boolean;
  docTitle: string;
  docType?: PapiInboxItemCategory;
  providerID?: string;
  trash: boolean;
}

const DOCUMENT_TYPES_WITH_TASK = [
  PapiInboxItemCategory.LAB_RESULTS,
  PapiInboxItemCategory.MEDICAL_HISTORY,
  PapiInboxItemCategory.ADMINISTRATIVE,
];

const TASK_SELECT_OPTIONS = [
  {
    label: <AIconLabeled label="Create review task" type="plus-circle" />,
    value: 'create',
  },
  {
    label: <AIconLabeled label="Don't create a review task" type="stop" />,
    value: 'dont-create',
  },
];

const NO_MEMBER_ACTION_OPTIONS = NO_MEMBER_ACTION_VALUES.map((value) => ({
  label: (
    <AIconLabeled
      label={NO_MEMBER_ACTION_ATTRS[value].label}
      type={NO_MEMBER_ACTION_ATTRS[value].icon}
    />
  ),
  value,
}));

/** Toolbar for document review. Can be used to assign to member/create review task  */
const DocumentReviewToolbar: FC<DocumentReviewToolbarProps> = (props) => {
  const { member, onDocSave } = props;

  const ComponentClass = member
    ? MemberDocumentTypesSection
    : NoMemberDocumentTypesSection;

  const formHook = useForm<FormValues>();

  const onSubmit = (values: FormValues): void => {
    const docType =
      values.docType in PapiInboxItemCategory
        ? (values.docType as PapiInboxItemCategory)
        : undefined;

    const data: DocumentReviewSaveData = {
      createTask: values.task === 'create',
      docTitle: values.docTitle,
      docType,
      providerID: values.providerID,
      trash: member
        ? values.docType === 'trash'
        : values.noMemberAction === 'trash',
    };
    onDocSave(data);
  };

  return (
    <StyledForm onSubmit={formHook.handleSubmit(onSubmit)}>
      <ComponentClass {...props} formHook={formHook} />
    </StyledForm>
  );
};

// Styled components ////////////////////////////////
const StyledSelect = styled(ASelect)`
  &&& {
    margin-right: ${theme.space.m};
    width: 240px;
  }
`;

const StyledForm = styled.form`
  .ant-form-item {
    margin-bottom: ${theme.space.m};
    display: flex;
    flex-direction: row;
  }

  .ant-form-item-label {
    width: 160px;
    height: 32px;
    label {
      line-height: 32px;
    }
  }

  .ant-form-item-control-wrapper {
    width: 100%;
  }
`;

const StyledSaveSection = styled.div`
  border-top: 1px solid ${colorTheme.border};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -${theme.space.m};
  padding: ${theme.space.m} 0 0 0;
`;

const SaveActions = styled.div`
  display: flex;
  flex-direction: row;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  margin-right: ${theme.space.s};
  min-width: 130px;
  padding-top: ${theme.space.s};
`;

const ScheduleIcon = styled(AIconInline)`
  &&& {
    margin-right: ${theme.space.s};
    padding-top: ${theme.space.s};
  }
`;

const ReviewTaskLabel = styled.div`
  margin: 0 ${theme.space.m} 0 ${theme.space.xs};
  padding-top: ${theme.space.s};
`;

// Helpers ////////////////////////////////
const careManagerFilter = (
  provider: DocumentReviewList_Query_GetDocs_providers
): boolean => provider.type === PapiProviderType.CARE_MANAGER;
const doctorFilter = (
  provider: DocumentReviewList_Query_GetDocs_providers
): boolean => provider.type === PapiProviderType.DOCTOR;

const getDefaultProvider = (
  providers: DocumentReviewList_Query_GetDocs_providers[],
  docType: PapiInboxItemCategory,
  member: DocumentReviewList_Query_GetDocs_docs_person | undefined
): string | undefined => {
  const filteredProviders = providers.filter(
    docType === PapiInboxItemCategory.ADMINISTRATIVE
      ? careManagerFilter
      : doctorFilter
  );
  const defaultProviderID = filteredProviders.length
    ? filteredProviders[0].id
    : undefined;
  const memberProvider = member?.mostRecentDoctor?.id;

  return memberProvider &&
    filteredProviders.map((p) => p.id).includes(memberProvider)
    ? memberProvider
    : defaultProviderID;
};

const MemberDocumentTypesSection: FC<DocumentReviewToolbarPropsHook> = (
  props
) => {
  const { formHook, isSaved, isSaving } = props;
  const disabled = isSaving || isSaved;
  return (
    <>
      <FormItem>
        <Label>
          <AIconLabeled
            label={DOCUMENT_ACTION_FIELDS.docType.label}
            type={DOCUMENT_ACTION_FIELDS.docType.icon}
          />
        </Label>
        <ASelectDocumentType
          {...DOCUMENT_ACTION_FIELDS.docType.componentProps}
          defaultValue={
            props.selectedDoc.category || PapiInboxItemCategory.LAB_RESULTS
          }
          disabled={disabled}
          formHook={formHook}
          withTrash
        />
      </FormItem>

      <FormItem>
        <Label>
          <AIconLabeled
            label={DOCUMENT_ACTION_FIELDS.docTitle.label}
            type={DOCUMENT_ACTION_FIELDS.docTitle.icon}
          />
        </Label>
        <AInput
          {...DOCUMENT_ACTION_FIELDS.docTitle.componentProps}
          disabled={disabled}
          formHook={formHook}
        />
      </FormItem>

      <SaveSection {...props} />
    </>
  );
};

const NoMemberDocumentTypesSection: FC<DocumentReviewToolbarPropsHook> = (
  props
) => {
  const { formHook, isSaved, isSaving, selectedDoc } = props;
  const noMemberAction = formHook.watch('noMemberAction');

  return (
    <>
      <FormItem>
        <Label>
          <AIconLabeled
            label={DOCUMENT_ACTION_FIELDS.noMemberAction.label}
            type={DOCUMENT_ACTION_FIELDS.noMemberAction.icon}
          />
        </Label>
        <StyledSelect
          {...DOCUMENT_ACTION_FIELDS.noMemberAction.componentProps}
          disabled={isSaving || isSaved}
          formHook={formHook}
          selectOptions={NO_MEMBER_ACTION_OPTIONS}
        />
      </FormItem>

      {noMemberAction === 'archive' && (
        <FormItem>
          <Label>
            <AIconLabeled
              label={DOCUMENT_ACTION_FIELDS.docType.label}
              type={DOCUMENT_ACTION_FIELDS.docType.icon}
            />
          </Label>
          <ASelectDocumentType
            {...DOCUMENT_ACTION_FIELDS.docType.componentProps}
            defaultValue={
              selectedDoc.category || PapiInboxItemCategory.LAB_RESULTS
            }
            disabled={isSaving || isSaved}
            formHook={formHook}
          />
        </FormItem>
      )}

      <FormItem>
        <Label>
          <AIconLabeled
            label={DOCUMENT_ACTION_FIELDS.docTitle.label}
            type={DOCUMENT_ACTION_FIELDS.docTitle.icon}
          />
        </Label>
        <AInput
          {...DOCUMENT_ACTION_FIELDS.docTitle.componentProps}
          disabled={isSaving || isSaved}
          formHook={formHook}
        />
      </FormItem>

      <SaveSection {...props} />
    </>
  );
};

const SaveSection: FC<DocumentReviewToolbarPropsHook> = ({
  formHook,
  isSaved,
  isSaving,
  member,
  providers,
}) => {
  const watch = formHook.watch(['task', 'docType', 'noMemberAction']);
  const docType = watch.docType || PapiInboxItemCategory.LAB_RESULTS;
  const trash = watch.noMemberAction === 'trash';
  const providerIcon =
    docType === PapiInboxItemCategory.ADMINISTRATIVE ? 'team' : 'heart';
  const taskAvailable =
    docType !== 'trash' && DOCUMENT_TYPES_WITH_TASK.includes(docType);

  const providerOptions = providers.map((provider) => ({
    label: <AIconLabeled label={provider.displayName} type={providerIcon} />,
    value: provider.id,
  }));

  return (
    <StyledSaveSection>
      <SaveActions>
        {taskAvailable && member && (
          <>
            <ScheduleIcon type={DOCUMENT_ACTION_FIELDS.task.icon} />
            <StyledSelect
              {...DOCUMENT_ACTION_FIELDS.task.componentProps}
              disabled={isSaving || isSaved}
              formHook={formHook}
              selectOptions={TASK_SELECT_OPTIONS}
            />
            {watch.task === 'create' && (
              <>
                <ReviewTaskLabel>
                  {DOCUMENT_ACTION_FIELDS.providerID.label}
                </ReviewTaskLabel>
                <StyledSelect
                  {...DOCUMENT_ACTION_FIELDS.providerID.componentProps}
                  defaultValue={
                    docType !== 'trash'
                      ? getDefaultProvider(providers, docType, member)
                      : undefined
                  }
                  disabled={isSaving || isSaved}
                  formHook={formHook}
                  selectOptions={providerOptions}
                />
              </>
            )}
          </>
        )}
      </SaveActions>
      {isSaved ? (
        <AIconLabeled
          label={
            trash
              ? STATUS_MESSAGE.documentReview.success.trashed
              : STATUS_MESSAGE.documentReview.success.saved
          }
          type="file-sync"
        />
      ) : (
        <Button
          htmlType="submit"
          icon="download"
          loading={isSaving}
          type="primary"
        >
          Save
        </Button>
      )}
    </StyledSaveSection>
  );
};

export { DocumentReviewToolbar };
