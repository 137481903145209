import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import { MutationUpdaterFn } from 'apollo-client';
import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';
import {
  MdhqUrl_Query,
  MdhqUrl_QueryVariables,
} from 'app/types/generated/MdhqUrl_Query';
import { MemberLinks_Fragment_member } from 'app/types/generated/MemberLinks_Fragment_member';
import {
  NoteCreate_Mutation_createEncounterNote,
  NoteCreate_MutationVariables,
} from 'app/types/generated/NoteCreate_Mutation';
import { displayErrors } from 'app/utils/app';
import { STATUS_MESSAGE } from 'constants/message';

import { NOTE_CREATE_MUTATION, MDHQ_URL_QUERY } from './query';

const Checkmark: React.FC<{}> = () => (
  <Icon>
    <svg
      fill="none"
      height="11"
      viewBox="0 0 15 11"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M4.50016 8.50033L1.5835 5.58366C1.26133 5.26149 0.738996 5.26149 0.416829 5.58366C0.0946632 5.90583 0.0946635 6.42816 0.41683 6.75033L4.50016 10.8337L13.9168 1.41699C14.239 1.09483 14.239 0.572491 13.9168 0.250326C13.5947 -0.0718405 13.0723 -0.0718408 12.7502 0.250325L4.50016 8.50033Z"
        fill="#4F7F71"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
);

// Types & constants ////////////////////////////////
interface Props {
  appointmentID: string;
  encounterID: string | null;
  member?: MemberLinks_Fragment_member;
  mutationCacheUpdater?: MutationUpdaterFn<
    NoteCreate_Mutation_createEncounterNote
  >;
  noteCreated: boolean;
}

interface PageParams {
  personID: string;
}

/** Form to create a coupon */
const NoteCreate: FC<Props> = ({
  appointmentID,
  encounterID,
  mutationCacheUpdater,
  noteCreated,
}) => {
  const [createNote, { loading: mutationLoading }] = useMutation<
    NoteCreate_Mutation_createEncounterNote,
    NoteCreate_MutationVariables
  >(NOTE_CREATE_MUTATION, { update: mutationCacheUpdater });
  const match = useRouteMatch<PageParams>();

  const personID = match.params.personID;

  const mdhqUrl = useQuery<MdhqUrl_Query, MdhqUrl_QueryVariables>(
    MDHQ_URL_QUERY,
    { variables: { personID } }
  );

  const onClick = async (): Promise<void> => {
    try {
      const response = await createNote({
        variables: {
          appointmentID: appointmentID,
        },
      });
      if (response?.data) {
        message.success(STATUS_MESSAGE.noteCreate.success);
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.noteCreate.error.general);
    }
  };

  const url = mdhqUrl?.data?.getPerson?.mdhqPatientURL;
  const newUrl = url?.replace('demo.php', 'soap.php');
  const finalUrl = `${newUrl}&soap_id=${encounterID}`;

  return noteCreated ? (
    <NoteCreated href={finalUrl} target="_blank">
      <Checkmark />
      Note Created
    </NoteCreated>
  ) : (
    <CreateButton
      disabled={mutationLoading}
      onClick={onClick}
      size="small"
      type="primary"
    >
      Create Note
    </CreateButton>
  );
};

// Styled components ///////////////////////////////
const Icon = styled.div`
  margin-right: 5px;
`;

const CreateButton = styled(Button)`
  &&& {
    background: #${theme.color.aquaGreen};
    border: 0;
    color: #${theme.color.white};
    margin-left: 5px;
  }
`;

const NoteCreated = styled.a`
  &&& {
    align-items: center;
    align-self: baseline;
    color: #${theme.color.aquaGreen};
    display: flex;
    height: 24px;
    margin-left: 10px;
    white-space: nowrap;
  }
`;

export { NoteCreate };
