import gql from 'graphql-tag';

export const MEMBER_THIRD_PARTY_IDS_UPDATE_QUERY = gql`
  query MemberThirdPartyIDsUpdate_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      helpScoutID
    }
  }
`;

export const MEMBER_THIRD_PARTY_IDS_UPDATE_MUTATION = gql`
  mutation MemberThirdPartyIDsUpdate_Mutation($input: PapiUpdatePersonInput!) {
    updatePapiPerson(input: $input) {
      id
      helpScoutID
    }
  }
`;
