import { useQuery } from '@apollo/react-hooks';
import React, { FC } from 'react';

import {
  ASelectField,
  ASelectFieldProps,
} from 'app/components/deprecated/ASelectField/ASelectField';
import {
  GetEnterprisePartners_Query,
  GetEnterprisePartners_QueryVariables,
} from 'app/types/generated/GetEnterprisePartners_Query';
import { PartnerSearchType } from 'app/types/generated/globalTypes';

import { GET_ENTERPRISE_PARTNERS_QUERY } from './query';

type Props = Omit<ASelectFieldProps, 'initialValue' | 'selectOptions'> & {
  initialValue?: string | string[];
};

export const ASelectPartnerField: FC<Props> = ({ ...props }) => {
  const { data, loading } = useQuery<
    GetEnterprisePartners_Query,
    GetEnterprisePartners_QueryVariables
  >(GET_ENTERPRISE_PARTNERS_QUERY, {
    variables: {
      filters: {
        pagination: {},
        searchString: '',
        type: PartnerSearchType.PARTNER,
      },
    },
  });

  const partners = data?.getPartners?.result || [];

  return (
    <ASelectField
      initialValue={null}
      loading={loading}
      placeholder="Select or search"
      selectOptions={partners.map((partner) => ({
        label: partner.displayName!,
        title: partner.displayName!,
        value: partner.displayName!.toLowerCase().replace(' ', '-'),
      }))}
      {...props}
    />
  );
};
