import { AlignItemsProperty } from 'csstype';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

/** Wrapper to convert row items to column items at phone breakpoint */
export const AFlexRowToColumn = styled.div<{
  align?: AlignItemsProperty;
}>`
  align-items: ${({ align }) => align || 'center'};
  display: flex;
  flex-wrap: wrap;
  ${theme.layout.breakpointMixin.phoneOnly} {
    align-items: baseline;
    flex-direction: column;
  }
`;
