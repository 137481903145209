import gql from 'graphql-tag';

export const MEMBER_STATISTICS_PAGE_QUERY = gql`
  query MemberStatisticsPage_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      psiScores {
        total
        score
        date
        categories {
          label
          name
          score
          symptoms {
            label
            name
            score
          }
        }
      }
    }
  }
`;
