import { DatePicker } from 'antd';
import { Interval, DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { AIconCaret } from 'app/components/atoms/AIconCaret/AIconCaret';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  interval: Interval;
  onIntervalChange: (interval: Interval) => void;
}

/** Accepts a date interval to start, then pages based on interval size.
 * Reports back any changes via callback. */
const CalendarControls: FC<Props> = ({ interval, onIntervalChange }) => {
  const [weekPickerOpen, setWeekPickerOpen] = useState(false);

  const onForward = (): void => {
    // Don't use interval.length because it rounds incorrectly
    const days = Math.ceil(interval.end.diff(interval.start, 'days').days);
    const forwardInterval = Interval.fromDateTimes(
      interval.start.plus({ days }),
      interval.end.plus({ days })
    );
    onIntervalChange(forwardInterval);
  };

  const onBackward = (): void => {
    const days = Math.ceil(interval.end.diff(interval.start, 'days').days);
    const backwardInterval = Interval.fromDateTimes(
      interval.start.minus({ days }),
      interval.end.minus({ days })
    );
    onIntervalChange(backwardInterval);
  };

  const onWeekChange = (moment: Moment | null): void => {
    if (!moment) {
      return;
    }
    const date = DateTime.fromJSDate(moment.toDate()).setZone('local');
    const interval = Interval.fromDateTimes(
      date.startOf('week').minus({ days: 1 }),
      date.endOf('week').minus({ days: 1 })
    );
    onIntervalChange(interval);
    setWeekPickerOpen(false);
  };

  return (
    <CalendarControlsContainer>
      <BackIconContainer>
        <AIconCaret onClick={onBackward} position="left" />
      </BackIconContainer>
      <AIconCaret onClick={onForward} position="right" />

      <DateText>
        Visits {interval.start.toFormat('MMM d')}{' '}
        {interval.length('days') > 1 && ` – ${interval.end.toFormat('MMM d')}`}
      </DateText>

      <AIconCaret
        onClick={() => setWeekPickerOpen((prev) => !prev)}
        position={weekPickerOpen ? 'right' : 'down'}
      />
      <WeekPickerContainer>
        <DatePicker.WeekPicker
          onChange={onWeekChange}
          open={weekPickerOpen}
          value={moment(interval.start.toISO())}
        />
      </WeekPickerContainer>
    </CalendarControlsContainer>
  );
};

// Styled components ////////////////////////////////
const BackIconContainer = styled.div`
  display: flex;
  margin-right: ${theme.space.s};
`;

const CalendarControlsContainer = styled.div`
  align-items: center;
  display: flex;
`;

const DateText = styled.div`
  font-size: ${theme.font.size.xxl};
  font-weight: ${theme.font.weight.medium};
  margin: 0 ${theme.space.m};
`;

const WeekPickerContainer = styled.div`
  margin-left: ${theme.space.m};
  visibility: hidden;
  width: 0;
`;

export { CalendarControls };
