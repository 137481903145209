import gql from 'graphql-tag';

export const MEMBER_SEARCH_QUERY = gql`
  query MembersSearch_Query($search: String!) {
    searchPersons(search: $search) {
      id
      biologicalSex
      center {
        id
        abbreviatedName
        isVirtual
      }
      dateOfBirth
      email
      firstName
      lastName
      middleName
    }
  }
`;
