import { useQuery } from '@apollo/react-hooks';
import { Card } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { theme } from 'app/styles/theme';
import {
  ProviderProducts_Query,
  ProviderProducts_QueryVariables,
} from 'app/types/generated/ProviderProducts_Query';

import { PROVIDER_PRODUCTS_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  padding?: keyof typeof theme.space;
  providerID: string;
}

/** Displays provider's products */
const ProviderProducts: FC<Props> = ({ padding, providerID }) => {
  const { data, loading } = useQuery<
    ProviderProducts_Query,
    ProviderProducts_QueryVariables
  >(PROVIDER_PRODUCTS_QUERY, { variables: { providerID } });

  if (loading || !data || !data.provider) {
    return <ALoading />;
  }

  const {
    provider: { products },
  } = data;

  return (
    <>
      <Container padding={padding}>
        {products.map((p) => (
          <CardStyled key={p.displayName}>{p.displayName}</CardStyled>
        ))}
      </Container>
    </>
  );
};

// Styled components ////////////////////////////////

const Container = styled.div<{
  padding?: Props['padding'];
}>`
  display: flex;
  flex-wrap: wrap;
  ${({ padding }) => padding && `padding: ${theme.space[padding]}`}
`;

const CardStyled = styled(Card)`
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-sizing: border-box;
  flex: 0 0 300px;
  font-weight: 500;
  height: 84px;
  margin: 0 ${theme.space.l} ${theme.space.l} 0;
`;

export { ProviderProducts };
