import { useLazyQuery } from '@apollo/react-hooks';
import { Tooltip } from 'antd';
import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AIconBio } from 'app/components/atoms/AIconBio/AIconBio';
import { AIconThirdParty } from 'app/components/atoms/AIconThirdParty/AIconThirdParty';
import { AProfileLinkClipboardCopy } from 'app/components/atoms/AProfileLinkClipboardCopy/AProfileLinkClipboardCopy';
import { theme } from 'app/styles/theme';
import { MemberLinks_Fragment_member } from 'app/types/generated/MemberLinks_Fragment_member';
import {
  MemberLinks_Query,
  MemberLinks_QueryVariables,
} from 'app/types/generated/MemberLinks_Query';
import { AppRoute } from 'constants/app';
import {
  HELPSCOUT_CONVERSATIONS_URL,
  HUBSPOT_PERSON_URL,
  STRIPE_CUSTOMER_URL,
} from 'constants/url';

import { MEMBER_LINKS_QUERY } from './query';

// Types & constants ////////////////////////////////
export interface MemberLinksProps {
  excludedLinks?: Array<'dr' | 'helpscout' | 'hubspot' | 'mdhq' | 'stripe'>;
  member?: MemberLinks_Fragment_member;
  memberID?: string;
}

/** Links to a member's external services and Dr. Parsley profile */
const MemberLinks: FC<MemberLinksProps> = ({
  excludedLinks,
  member,
  memberID,
}) => {
  const [getLinks, { data, loading: loadingLinks }] = useLazyQuery<
    MemberLinks_Query,
    MemberLinks_QueryVariables
  >(MEMBER_LINKS_QUERY);

  useEffect(() => {
    if (!member && memberID) {
      getLinks({
        variables: {
          personID: memberID,
        },
      });
    }
  }, [getLinks, member, memberID]);

  if (loadingLinks) {
    return <div>Loading...</div>;
  }

  const memberData = member || data?.member;

  if (!memberData) {
    return null;
  }

  const displayLinks = {
    drParsley: !excludedLinks?.includes('dr'),
    helpscout: memberData.helpScoutID && !excludedLinks?.includes('helpscout'),
    hubspot: memberData.hubspotID && !excludedLinks?.includes('hubspot'),
    mdhq: memberData.mdhqPatientURL && !excludedLinks?.includes('mdhq'),
    stripe: memberData.stripeCustomerID && !excludedLinks?.includes('stripe'),
  };

  return (
    <Links>
      {displayLinks.mdhq && (
        <Tooltip title="Open MDHQ">
          <LinkExternal
            href={memberData.mdhqPatientURL!}
            rel="noopener noreferrer"
            target="_blank"
          >
            <AIconThirdParty service="mdhq" />
          </LinkExternal>
        </Tooltip>
      )}
      {displayLinks.stripe && (
        <Tooltip title="Open Stripe">
          <LinkExternal
            href={`${STRIPE_CUSTOMER_URL}${memberData.stripeCustomerID}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <AIconThirdParty service="stripe" />
          </LinkExternal>
        </Tooltip>
      )}
      {displayLinks.helpscout && (
        <Tooltip title="Open HelpScout">
          <LinkExternal
            href={HELPSCOUT_CONVERSATIONS_URL(memberData.helpScoutID!)}
            rel="noopener noreferrer"
            target="_blank"
          >
            <AIconThirdParty service="helpscout" />
          </LinkExternal>
        </Tooltip>
      )}
      {displayLinks.hubspot && (
        <Tooltip title="Open HubSpot">
          <LinkExternal
            href={`${HUBSPOT_PERSON_URL}${memberData.hubspotID}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <AIconThirdParty service="hubspot" />
          </LinkExternal>
        </Tooltip>
      )}
      {displayLinks.drParsley && (
        <Tooltip title="Go to profile">
          <Link
            rel="noopener noreferrer"
            target="_blank"
            to={`${AppRoute.Members}/${memberData.id}`}
          >
            <ProfileLinkContainer data-testid="MemberLinks_profileLink">
              <AIconBio />
            </ProfileLinkContainer>
          </Link>
        </Tooltip>
      )}
      <Tooltip title="Copy profile link">
        <span>
          <AProfileLinkClipboardCopy
            personID={memberData.id}
            showText={false}
          />
        </span>
      </Tooltip>
    </Links>
  );
};

// Styled components ////////////////////////////////
const Links = styled.div`
  align-items: center;
  display: flex;
  margin-left: ${theme.space.xs};
`;

const LinkExternal = styled.a`
  margin-right: ${theme.space.m};
`;

const ProfileLinkContainer = styled.div`
  bottom: ${theme.space.xxs};
  margin-right: ${theme.space.m};
  position: relative;
`;

export { MemberLinks };
