import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';

import { AErrorScreen } from 'app/components/atoms/AErrorScreen/AErrorScreen';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { AppLayout } from 'app/components/organisms/AppLayout/AppLayout';
import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { createViewerContext } from 'app/contexts/ViewerContext';
import { App_Query } from 'app/types/generated/App_Query';
import { trackEvent } from 'app/utils/analytics';
import { shouldShowRoute } from 'app/utils/app';
import { AppRoute, ROUTES } from 'constants/app';
import { STRIPE_API_KEY } from 'constants/env';

/** App routing */
const App: FC = () => {
  const ViewerContext = createViewerContext();
  const featureFlags = useContext(FeatureFlagContext);
  const { data } = useQuery<App_Query>(APP_QUERY);

  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    trackEvent('Session Started');
    if (window.Stripe) {
      setStripe(window.Stripe(STRIPE_API_KEY));
    } else {
      document
        .querySelector('#stripe-js')!
        .addEventListener('load', () =>
          setStripe(window.Stripe(STRIPE_API_KEY))
        );
    }
  }, []);

  return data?.viewer ? (
    <ViewerContext.Provider value={data.viewer}>
      <StripeProvider stripe={stripe}>
        <AppLayout>
          <Switch>
            <Redirect exact from={AppRoute.Basename} to={AppRoute.Visits} />
            {ROUTES.map(
              (route) =>
                shouldShowRoute(route, data.viewer, featureFlags) && (
                  <Route
                    component={route.component}
                    exact={route.exact}
                    key={`Route_${route.path}`}
                    path={route.path}
                  />
                )
            )}
            <Route component={AErrorScreen} />
          </Switch>
        </AppLayout>
      </StripeProvider>
    </ViewerContext.Provider>
  ) : (
    <ALoading fullscreen />
  );
};

// GraphQL ////////////////////////////////
const APP_QUERY = gql`
  query App_Query {
    viewer: currentPapiPerson {
      id
      center {
        id
        abbreviatedName
        isVirtual
        timezone
      }
      email
      role
    }
  }
`;

export { App };
