import gql from 'graphql-tag';

import { CalendarCard_Fragment_visit } from 'app/components/organisms/CalendarCard/fragment';
import { MemberLinks_Fragment_member } from 'app/components/organisms/MemberLinks/fragment';
import { VisitDetails_Fragment_visit } from 'app/components/organisms/VisitDetails/fragment';

export const CALENDAR_PAGE_QUERY = gql`
  query CalendarPage_Visits_Query(
    $providerID: ID!
    $maxTime: String!
    $minTime: String!
    $centerID: ID
    $productType: String
    $format: String
  ) {
    provider: getProvider(id: $providerID) {
      id
      timezone
      visits: appointmentsForCalendar(
        maxTime: $maxTime
        minTime: $minTime
        centerID: $centerID
        productType: $productType
        format: $format
      ) {
        id
        endTime
        format
        startTime
        displayColor
        displayName
        patientID
        patientName
        productAbbreviation
      }
    }
  }
`;

export const CALENDAR_PAGE_QUERY_PROVIDER_VISITS = gql`
  query CalendarPage_Query_providerVisits(
    $providerID: ID!
    $maxTime: String!
    $minTime: String!
  ) {
    provider: getProvider(id: $providerID) {
      id
      visits: appointments(maxTime: $maxTime, minTime: $minTime) {
        id
        centerID
        format
        # TODO Remove person once Visit <> PlanPurchase is implemented
        member: person {
          id
          currentPlan {
            id
            plan {
              id
              product {
                type
              }
            }
          }
          ...MemberLinks_Fragment_member
        }
        status
        ...CalendarCard_Fragment_visit
        ...VisitDetails_Fragment_visit
      }
      timezone
    }
  }
  ${CalendarCard_Fragment_visit}
  ${MemberLinks_Fragment_member}
  ${VisitDetails_Fragment_visit}
`;

export const GET_VISIT = gql`
  query getVisit($id: ID!) {
    getAppointment(id: $id) {
      id
      categoryDetails {
        category
        color
        name
      }
      center {
        id
        name
      }
      endTime
      format
      isComplimentary
      location
      member: person {
        id
        currentPlan {
          id
          extensionOf {
            id
            plan {
              id
              product {
                displayName
              }
            }
          }
          nextRenewalDate
          plan {
            id
            product {
              displayName
            }
          }
        }
        email
        joinedPracticeDate
        phoneNumber
      }
      metadata
      startTime
    }
  }
`;
