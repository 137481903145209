import { useQuery } from '@apollo/react-hooks';
import { Tabs } from 'antd';
import capitalize from 'lodash/capitalize';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { APadding } from 'app/components/atoms/APadding/APadding';
import { MemberHeader } from 'app/components/organisms/MemberHeader/MemberHeader';
import { MemberInvoicesTable } from 'app/components/organisms/MemberInvoicesTable/MemberInvoicesTable';
import { MemberAccountPage } from 'app/components/pages/MemberAccountPage/MemberAccountPage';
import { MemberStatisticsPage } from 'app/components/pages/MemberStatisticsPage/MemberStatisticsPage';
import { MemberVisitsPage } from 'app/components/pages/MemberVisitsPage/MemberVisitsPage';
import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { NavContext } from 'app/contexts/NavContext';
import { theme } from 'app/styles/theme';
import {
  MemberPage_Query,
  MemberPage_QueryVariables,
} from 'app/types/generated/MemberPage_Query';
import { formatMemberName } from 'app/utils/member';

import { MEMBER_PAGE_QUERY } from './query';

// Types & constants ////////////////////////////////
interface PageParams {
  personID: string;
}

/** Member page with header & tabs for subpages */
const MemberPage: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<PageParams>();
  const featureFlags = useContext(FeatureFlagContext);

  const showDataTab = featureFlags.variation('dr-p-data-flag');
  const [pageLoading, setPageLoading] = useState(false);

  const personID = match.params.personID;
  const { data } = useQuery<MemberPage_Query, MemberPage_QueryVariables>(
    MEMBER_PAGE_QUERY,
    { variables: { personID: match.params.personID } }
  );

  const tabRegex = new RegExp(`/${personID}/(\\w*)`);
  const tabRegexMatch = location.pathname.match(tabRegex);
  const activeTab = tabRegexMatch?.[1];

  const nav = useContext(NavContext);
  useEffect(() => {
    if (data?.member) {
      nav.setBreadcrumbOptions({
        [personID]: {
          label: formatMemberName(data.member, true),
          private: true,
        },
        ...(activeTab
          ? { [activeTab]: { hide: true, label: capitalize(activeTab) } }
          : {}),
      });
      return () => nav.setBreadcrumbOptions({});
    }
  }, [activeTab, data, personID, nav]);

  return (
    <ALoading size="large" spinning={pageLoading}>
      <MemberHeader
        excludedLinks={['dr']}
        personID={personID}
        setLoading={setPageLoading}
      />
      <TabsStyled
        activeKey={activeTab || 'account'}
        onChange={(tabKey) =>
          history.push(
            `${location.pathname.split(personID)[0]}${personID}/${tabKey}`
          )
        }
      >
        <Tabs.TabPane key="account" tab="Account">
          <APadding type="page">
            <AccountBody>
              <MemberAccountPage personID={personID} />
            </AccountBody>
          </APadding>
        </Tabs.TabPane>
        <Tabs.TabPane key="visits" tab="Visits">
          <APadding type="page">
            <MemberVisitsPage personID={personID} />
          </APadding>
        </Tabs.TabPane>
        <Tabs.TabPane key="invoices" tab="Invoices">
          <APadding type="page">
            <MemberInvoicesTable personID={personID} />
          </APadding>
        </Tabs.TabPane>

        {showDataTab && (
          <Tabs.TabPane key="stats" tab={<TabTitle>Data</TabTitle>}>
            <APadding type="page">
              <AccountBody>
                <MemberStatisticsPage personID={personID} />
              </AccountBody>
            </APadding>
          </Tabs.TabPane>
        )}
      </TabsStyled>
    </ALoading>
  );
};

// Styled components ////////////////////////////////
const AccountBody = styled.div`
  background: white;
  padding-top: ${theme.space.s};
  width: 100%;
`;

const TabTitle = styled.p`
  margin: 0;
  padding: 0;
`;

const TabsStyled = styled(Tabs)`
  &&& {
    margin-top: -${theme.space.m};
    .ant-tabs-bar {
      background: white;
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
`;

export { MemberPage };
