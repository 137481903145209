import { Empty } from 'antd';
import React, { FC, ReactNodeArray } from 'react';

// Types & constants ////////////////////////////////
interface Props {
  items: ReactNodeArray;
  noItemsLabel?: string;
}

/** Wrapper for list of PrepCard groups displaying Empty status if no items provided */
const APrepCardGroupsList: FC<Props> = ({
  items,
  noItemsLabel = 'No items',
}) => (
  <div>
    {items.length > 0 ? (
      items
    ) : (
      <Empty description={noItemsLabel} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    )}
  </div>
);

export { APrepCardGroupsList };
