import { startCase, toLower } from 'lodash';
import capitalize from 'lodash/capitalize';

import {
  PapiAppointmentCategory,
  PapiAppointmentFormat,
  PapiAppointmentGroup,
} from 'app/types/generated/globalTypes';
import { VisitCardListAppointmentTypesQuery_listAppointmentTypes } from 'app/types/generated/VisitCardListAppointmentTypesQuery';

export const formatVisitFormat = (visitFormat: PapiAppointmentFormat): string =>
  visitFormat === PapiAppointmentFormat.VIRTUAL
    ? 'Online' // To conform with branding guidelines
    : capitalize(visitFormat.replace(/_/g, ' '));

/** This can be replaced once we have a query to get visit category details */
export const formatVisitAppointment = (
  appointment: PapiAppointmentCategory | PapiAppointmentGroup
): string => {
  return appointment === PapiAppointmentCategory.NON_STANDARD
    ? 'Non-standard'
    : appointment.split('_').map(capitalize).join(' ');
};

export const formatVisitAppointmentTypes = (
  appointmentTypes: VisitCardListAppointmentTypesQuery_listAppointmentTypes
): string => {
  return `${startCase(toLower(appointmentTypes.name))} (${
    appointmentTypes.duration
  } min)`;
};
