import { useQuery } from '@apollo/react-hooks';
import { Checkbox, Select, Switch } from 'antd';
import { SelectProps } from 'antd/lib/select';
import fromPairs from 'lodash/fromPairs';
import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FieldError,
  FormContextValues,
  ValidationOptions,
} from 'react-hook-form';
import styled from 'styled-components/macro';

import { AFlexbox } from 'app/components/atoms/AFlexbox/AFlexbox';
import { AFormFieldError } from 'app/components/atoms/AFormFieldError/AFormFieldError';
import {
  AFormFieldWrapper,
  AFormFieldWrapperProps,
} from 'app/components/atoms/AFormFieldWrapper/AFormFieldWrapper';
import { AIconProviderNewMembers } from 'app/components/atoms/AIconProviderNewMembers/AIconProviderNewMembers';
import { ALabel } from 'app/components/atoms/ALabel/ALabel';
import { theme } from 'app/styles/theme';
import {
  ASelectProviderCenter_Query,
  ASelectProviderCenter_Query_centers,
} from 'app/types/generated/ASelectProviderCenter_Query';
import { getCenterDisplayName, getCenterSearchIndex } from 'app/utils/center';
import { compareLabel } from 'app/utils/sort';
import { FORM_VALIDATION } from 'constants/form';

import { A_SELECT_PROVIDER_CENTER_QUERY } from './query';

// Types & constants ////////////////////////////////
export interface IASelectProviderCenterValue {
  id: string;
  label?: string;
  takingNewMembers: boolean;
}

export interface ASelectProviderCenterProps
  extends Omit<SelectProps<string[]>, 'defaultValue' | 'value'>,
    AFormFieldWrapperProps {
  defaultValue?: IASelectProviderCenterValue[];
  formHook?: FormContextValues<any>;
  label?: string;
  labelInfo?: string;
  name?: string;
  required?: boolean;
  /** @param rules with react-hook-form only */
  rules?: ValidationOptions;
}

/** Select field for a provider's centers. Includes new member availability toggles */
const ASelectProviderCenter: FC<ASelectProviderCenterProps> = ({
  customWidth,
  defaultValue,
  formHook,
  label,
  labelInfo,
  name,
  required,
  rules,
  show,
  ...props
}) => {
  const [selectedCenters, setSelectedCenters] = useState<
    IASelectProviderCenterValue[]
  >(defaultValue || []);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const errorMessage =
    name &&
    formHook?.errors[name] &&
    (formHook?.errors[name] as FieldError).message;

  const { data, loading } = useQuery<ASelectProviderCenter_Query>(
    A_SELECT_PROVIDER_CENTER_QUERY
  );
  const centerById = useMemo(
    () =>
      fromPairs(
        (data?.centers || []).map((center) => [
          center.id,
          { ...center, label: getCenterDisplayName(center) },
        ])
      ),
    [data]
  );

  useEffect(() => {
    // Register form field once
    if (formHook && name) {
      formHook.register(
        { name },
        { ...(required ? FORM_VALIDATION.requiredArray : {}), ...rules }
      );
      return () => formHook.unregister(name);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Add center labels to value in state once api call finishes
  useEffect(() => {
    setSelectedCenters((currSelectedCenters) =>
      currSelectedCenters.map((center) => ({
        ...center,
        label: centerById[center.id]?.label || '???',
      }))
    );
  }, [centerById]);

  // Update form value if selected centers change
  useEffect(() => {
    if (!formHook || !name) {
      return;
    }
    const valueInForm = formHook.getValues()[name];
    const valueInState = selectedCenters;
    formHook.setValue(name, selectedCenters);
    // Don't validate before user interacts with field, i.e. while we're setting up states
    if (valueInForm && valueInForm.length !== valueInState.length) {
      formHook.triggerValidation(name);
    }
  }, [selectedCenters]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AFormFieldWrapper customWidth={customWidth} show={show}>
      {label && (
        <ALabel black help={labelInfo} htmlFor={name} required={required}>
          {label}
        </ALabel>
      )}

      <SelectWrapper error={!!errorMessage}>
        <Select<string[]> // The select field's value is an array of selected center ids
          autoClearSearchValue
          dropdownClassName="ASelectProviderCenter"
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          id={name}
          loading={loading || props.loading}
          menuItemSelectedIcon={<></>}
          mode="multiple"
          onBlur={() => {
            setDropdownOpen(false);
            if (formHook && name) {
              formHook.triggerValidation(name);
            }
          }}
          onFocus={() => setDropdownOpen(true)}
          open={dropdownOpen}
          optionFilterProp="title"
          optionLabelProp="tag"
          placeholder="Select"
          showSearch
          value={selectedCenters.sort(compareLabel).map((center) => center.id)}
          {...props}
        >
          {Object.values(centerById)
            .sort(compareLabel)
            .map((center) =>
              renderSelectOption(center, selectedCenters, setSelectedCenters)
            )}
        </Select>
      </SelectWrapper>

      <AFormFieldError show={!!errorMessage}>{errorMessage}</AFormFieldError>
    </AFormFieldWrapper>
  );
};

const renderSelectOption = (
  center: ASelectProviderCenter_Query_centers & { label: string },
  selectedCenters: IASelectProviderCenterValue[],
  setSelectedCenters: Dispatch<SetStateAction<IASelectProviderCenterValue[]>>
): ReactNode => {
  const { id, label } = center;
  const selectedCenter = selectedCenters.find((center) => center.id === id);

  return (
    <SelectOptionStyled
      data-testid="center-select"
      key={id}
      // @ts-ignore Arbitrary props allowed on <Select.Option> so that <Select optionLabelProp> works, but this isn't reflected in Antd typedefs
      tag={
        <SelectTag>
          {label}
          {selectedCenter?.takingNewMembers === false && (
            <AIconProviderNewMembers margin="left" takingNewMembers={false} />
          )}
        </SelectTag>
      }
      title={getCenterSearchIndex(center)} // Used for searching
    >
      <OptionContainer>
        <CheckboxStyled
          checked={!!selectedCenter}
          onClick={() =>
            setSelectedCenters((currSelectedCenters) =>
              currSelectedCenters.find((center) => center.id === id)
                ? currSelectedCenters.filter((center) => center.id !== id)
                : [
                    ...currSelectedCenters,
                    { id, key: id, label, takingNewMembers: false },
                  ]
            )
          }
        >
          <CenterLabel>{label}</CenterLabel>
        </CheckboxStyled>
        {selectedCenter && (
          <MembersIconContainer>
            <AIconProviderNewMembers
              color={theme.color.textLight}
              takingNewMembers
            />
          </MembersIconContainer>
        )}
        <Switch
          checked={selectedCenter?.takingNewMembers}
          onClick={(takingNewMembers) =>
            setSelectedCenters((currSelectedCenters) => [
              ...currSelectedCenters.filter((center) => center.id !== id),
              { id, key: id, label, takingNewMembers },
            ])
          }
          size="small"
        />
      </OptionContainer>
    </SelectOptionStyled>
  );
};

// Styled components ////////////////////////////////
const CenterLabel = styled.div`
  bottom: 1px;
  position: relative;
`;

const CheckboxStyled = styled(Checkbox)`
  &&& {
    flex-grow: 1;
  }
`;

const MembersIconContainer = styled.div`
  bottom: ${theme.space.xs};
  margin-right: ${theme.space.xs};
  position: relative;
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectOptionStyled = styled(Select.Option)`
  &&& {
    width: 100%;
  }
`;

const SelectTag = styled(AFlexbox)`
  &&& {
    i {
      top: 1px;
    }
  }
`;

const SelectWrapper = styled.div<{ error: boolean }>`
  div[role='combobox']:not([disabled]) {
    border-color: ${({ error }) =>
      error ? theme.color.error : theme.color.border};
  }

  div[role='combobox']:not([disabled]):active,
  div[role='combobox']:not([disabled]):focus,
  div[role='combobox']:not([disabled]):hover {
    border-color: ${({ error }) =>
      error ? theme.color.error : theme.color.primary} !important;
    box-shadow: ${({ error }) =>
      error
        ? `${theme.layer.boxShadow.all(theme.color.error + '40')}`
        : ''} !important;
  }

  .ant-select {
    width: 100%;
  }
  .ant-select-selection__choice__remove {
    display: none;
  }
  .ant-select-selection--multiple .ant-select-selection__choice {
    padding: 0 ${theme.space.s};
  }
`;

export { ASelectProviderCenter };
