import gql from 'graphql-tag';

export const MemberLinks_Fragment_member = gql`
  fragment MemberLinks_Fragment_member on PapiPerson {
    id
    center {
      id
      mdhqHost
    }
    helpScoutID
    hubspotID
    mdhqID
    mdhqPatientURL
    stripeCustomerID
  }
`;
