import { useQuery } from '@apollo/react-hooks';
import React, { FC, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import AIconDoctor from 'app/components/atoms/AIconDoctor/AIconDoctor';
import AIconHealthCoach from 'app/components/atoms/AIconHealthCoach/AIconHealthCoach';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { getCareManagerLabel } from 'app/components/atoms/ASelectCareManager/ASelectCareManager';
import { MemberAdvocateIcon } from 'app/components/atoms/MemberAdvocateIcon/MemberAdvocateIcon';
import { ProviderPageCard } from 'app/components/atoms/ProviderPageCard/ProviderPageCard';
import {
  memberProviders,
  MemberProvidersProps,
} from 'app/components/organisms/MemberProviders/MemberProviders.story';
import { ProviderDeleteModal } from 'app/components/organisms/ProviderDeleteModal/ProviderDeleteModal';
import { FeatureFlagContext } from 'app/contexts/FeatureFlagContext';
import { theme } from 'app/styles/theme';
import {
  MemberProviders_Query,
  MemberProviders_QueryVariables,
  MemberProviders_Query_member,
  MemberProviders_Query_member_assignedDoctor,
} from 'app/types/generated/MemberProviders_Query';
import { PROVIDER_TYPE } from 'constants/provider';

import { AssignProviderModal } from './AssignProviderModal';
import { MEMBER_PROVIDERS_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  padding?: keyof typeof theme.space;
  personID: string;
}

/** Displays provider's products */
const MemberProviders: FC<Props> = ({ padding, personID }) => {
  const featureFlags = useContext(FeatureFlagContext);
  const { data, loading, refetch } = useQuery<
    MemberProviders_Query,
    MemberProviders_QueryVariables
  >(MEMBER_PROVIDERS_QUERY, { variables: { id: personID } });

  if (loading || !data || !data.member) {
    return <ALoading />;
  }
  const showMemberAdvocateCard = featureFlags.variation(
    'dr-p-show-member-advocate-card'
  );

  return (
    <>
      <Container padding={padding}>
        <ProviderCard
          member={data.member}
          onProviderUpdated={() => {
            refetch();
          }}
          providerType="clinician"
        />

        <ProviderCard
          member={data.member}
          onProviderUpdated={refetch}
          providerType="health-coach"
        />
        {showMemberAdvocateCard && (
          <ProviderCard
            member={data.member}
            onProviderUpdated={() => {
              refetch();
            }}
            providerType="member-advocate"
          />
        )}
      </Container>
    </>
  );
};

enum ModalKey {
  AssignProvider,
  RemoveProvider,
}

const ProviderCard: FC<{
  member: MemberProviders_Query_member | MemberProvidersProps;
  onProviderUpdated: () => void;
  providerType: PROVIDER_TYPE;
}> = ({ member, onProviderUpdated, providerType }) => {
  const memberAdvocate = memberProviders.assignedMemberAdvocate;

  const [showModal, setShowModal] = useState<ModalKey | null>(null);
  const provider = useMemo(() => {
    switch (providerType) {
      case 'clinician':
        return member.assignedDoctor;
      case 'member-advocate':
        return memberAdvocate;
      default:
        return member.assignedCoach;
    }
  }, [
    member.assignedCoach,
    member.assignedDoctor,
    memberAdvocate,
    providerType,
  ]);

  const providerIcon = useMemo(() => {
    if (providerType === 'member-advocate') {
      return <MemberAdvocateIcon />;
    }
    if (provider && provider.sanityProfile && provider.sanityProfile.imageSrc) {
      return <img alt="Provider" src={provider.sanityProfile.imageSrc} />;
    }

    return providerType === 'clinician' ? (
      <AIconDoctor />
    ) : (
      <AIconHealthCoach />
    );
  }, [provider, providerType]);

  const hasCareManager =
    providerType === 'clinician' &&
    !!(provider as MemberProviders_Query_member_assignedDoctor)
      ?.assignedCareManager;
  const careManagerName =
    hasCareManager &&
    getCareManagerLabel({
      ...(provider as MemberProviders_Query_member_assignedDoctor)
        ?.assignedCareManager,
      professionalSuffix: '',
    });

  return (
    <>
      <ProviderPageCard
        hasCareManager={hasCareManager}
        isMemberAdvocate={providerType === 'member-advocate'}
        onAssignProviderClick={() => setShowModal(ModalKey.AssignProvider)}
        onRemoveProviderClick={() => setShowModal(ModalKey.RemoveProvider)}
        pod={provider?.pod!}
        providerDisplayName={provider?.displayName}
        providerIcon={providerIcon}
        subTitle={<>Care Manager: {careManagerName}</>}
      />
      <AssignProviderModal
        member={member}
        onCancel={() => setShowModal(null)}
        onComplete={() => {
          onProviderUpdated();
          setShowModal(null);
        }}
        provider={provider || null}
        providerType={providerType}
        visible={showModal === ModalKey.AssignProvider}
      />
      {provider && (
        <ProviderDeleteModal
          isVisible={showModal === ModalKey.RemoveProvider}
          onClose={() => setShowModal(null)}
          onSuccess={() => {
            onProviderUpdated && onProviderUpdated();
            setShowModal(null);
          }}
          personFirstName={member.firstName}
          personID={member.id}
          provider={provider}
          providerType={providerType}
        />
      )}
    </>
  );
};

// Styled components ////////////////////////////////

const Container = styled.div<{
  padding?: Props['padding'];
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  ${({ padding }) => padding && `padding: ${theme.space[padding]}`}
`;

export { MemberProviders };
