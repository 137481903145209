import gql from 'graphql-tag';

export const ProviderBasics_Fragment_provider = gql`
  fragment ProviderBasics_Fragment_provider on PapiProvider {
    id
    acuityID
    acuityInstance
    biologicalSex
    sanityProfile {
      imageSrc
    }
    centers {
      center {
        id
        abbreviatedName
        isVirtual
      }
      takingNewPatients
    }
    payerCredentials {
      payerID
      centerID
    }
    products {
      displayName
      type
    }
    email
    firstName
    helpScoutID
    lastName
    middleName
    professionalSuffix
    sanityID
    timezone
    type
    employmentType
    pod {
      id
      name
    }
  }
`;
