import gql from 'graphql-tag';

import {
  DocumentReviewList_Fragment_doc,
  DocumentReviewList_Fragment_person,
} from './fragment';

export const DOCUMENT_REVIEW_LIST_QUERY_GET_DOCS = gql`
  query DocumentReviewList_Query_GetDocs(
    $centerIDs: [ID!]
    $personID: ID
    $sourceCategory: PapiInboxItemSourceCategory
    $triageStatuses: [PapiInboxItemTriageStatus!]
  ) {
    docs: listInboxItems(
      centerIDs: $centerIDs
      personID: $personID
      sourceCategory: $sourceCategory
      triageStatuses: $triageStatuses
    ) @client {
      category
      createdAt
      documentName
      id
      isUploadedToMDHQ
      person {
        center {
          abbreviatedName
          id
          name
        }
        currentPlan {
          plan {
            displayName
            product {
              displayName
            }
          }
        }
        dateOfBirth
        firstName
        id
        lastName
        mdhqID
        membershipTermsAcceptedAt
        middleName
        mostRecentDoctor {
          displayName
          id
          type
        }
      }
      source
      sourceCategory
      triagedAt
      triagedByID
      triageStatus
      url
    }
    providers: listProviders {
      displayName
      firstName
      id
      lastName
      middleName
      type
    }
  }
`;

export const DOCUMENT_REVIEW_LIST_QUERY_PERSONS = gql`
  query DocumentReviewList_Query_Persons($search: String!) {
    persons: searchPersons(search: $search) {
      ...DocumentReviewList_Fragment_person
    }
  }
  ${DocumentReviewList_Fragment_person}
`;

export const DOCUMENT_REVIEW_LIST_MUTATION_CATEGORIZE_DOC = gql`
  mutation DocumentReviewList_Mutation_CategorizeDoc(
    $input: PapiCategorizeInboxItemInput!
  ) {
    categorizeDoc: categorizeInboxItem(input: $input) @client {
      ...DocumentReviewList_Fragment_doc
    }
  }
  ${DocumentReviewList_Fragment_doc}
  ${DocumentReviewList_Fragment_person}
`;

export const DOCUMENT_REVIEW_LIST_MUTATION_TRASH_DOC = gql`
  mutation DocumentReviewList_Mutation_TrashDoc(
    $input: PapiTrashInboxItemInput!
  ) {
    trashDoc: trashInboxItem(input: $input) @client {
      ...DocumentReviewList_Fragment_doc
    }
  }
  ${DocumentReviewList_Fragment_doc}
  ${DocumentReviewList_Fragment_person}
`;

export const DOCUMENT_REVIEW_LIST_MUTATION_ARCHIVE_DOC = gql`
  mutation DocumentReviewList_Mutation_ArchiveDoc(
    $input: PapiArchiveInboxItemInput!
  ) {
    archiveDoc: archiveInboxItem(input: $input) @client {
      ...DocumentReviewList_Fragment_doc
    }
  }
  ${DocumentReviewList_Fragment_doc}
  ${DocumentReviewList_Fragment_person}
`;
