import compact from 'lodash/compact';
import upperFirst from 'lodash/upperFirst';
import { DateTime } from 'luxon';

import { MemberName_Fragment } from 'app/types/generated/MemberName_Fragment';
import { MemberPaymentCard_Fragment_card } from 'app/types/generated/MemberPaymentCard_Fragment_card';

export const formatMemberName = (
  member: Omit<MemberName_Fragment, '__typename' | 'id' | 'middleName'> & {
    middleName?: MemberName_Fragment['middleName'];
  },
  shortened = false
): string => {
  const { firstName, lastName, middleName } = member;
  const middleInitial =
    middleName && middleName.replace(/[^a-zA-Z]/g, '').slice(0, 1);

  return compact([
    shortened ? firstName[0] + '.' : firstName,
    middleInitial && middleInitial + '.',
    lastName,
  ])
    .map(upperFirst)
    .join(' ');
};

export const getMemberAge = (dob: string): number =>
  DateTime.utc().diff(DateTime.fromISO(dob, { zone: 'utc' }), [
    'years',
    'months',
  ]).years;

export const isValidPaymentCard = (
  card: Pick<MemberPaymentCard_Fragment_card, 'expiryMonth' | 'expiryYear'>
): boolean =>
  DateTime.fromObject({ month: card.expiryMonth, year: card.expiryYear }).endOf(
    'month'
  ) > DateTime.local();
