import React, { FC } from 'react';

import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  color?: string;
  height?: string;
  width?: string;
}

/** Icon for a member's bio/profile */
const AIconBio: FC<Props> = ({
  color = theme.color.jade,
  height = theme.space.l,
  width = theme.space.l,
}) => {
  return (
    <svg
      aria-label="icon: bio"
      fill={color}
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M13 8C13 7.44772 13.4477 7 14 7H22C22.5523 7 23 7.44772 23 8C23 8.55228 22.5523 9 22 9H14C13.4477 9 13 8.55228 13 8ZM13 12C13 11.4477 13.4477 11 14 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H14C13.4477 13 13 12.5523 13 12ZM18.4443 15C17.8921 15 17.4443 15.4477 17.4443 16C17.4443 16.5523 17.8921 17 18.4443 17H21.9999C22.5522 17 22.9999 16.5523 22.9999 16C22.9999 15.4477 22.5522 15 21.9999 15H18.4443Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9 9C9 10.1046 8.10457 11 7 11C5.89543 11 5 10.1046 5 9C5 7.89543 5.89543 7 7 7C8.10457 7 9 7.89543 9 9ZM7 13C9.20914 13 11 11.2091 11 9C11 6.79086 9.20914 5 7 5C4.79086 5 3 6.79086 3 9C3 11.2091 4.79086 13 7 13ZM5.6887 14C3.37565 14 1.30889 15.6033 1.00809 17.8684C0.935381 18.4158 1.32026 18.9186 1.86774 18.9913C2.41522 19.064 2.91798 18.6791 2.99068 18.1316C3.14295 16.985 4.24771 16 5.6887 16H8.2777C9.75659 16 10.8565 16.99 11.0081 18.1316C11.0808 18.6791 11.5835 19.064 12.131 18.9913C12.6785 18.9186 13.0634 18.4158 12.9907 17.8684C12.6892 15.5982 10.6176 14 8.2777 14H5.6887Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export { AIconBio };
