import { useMutation, useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import compact from 'lodash/compact';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { ADateField } from 'app/components/deprecated/ADateField/ADateField';
import {
  AForm,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { ARadioField } from 'app/components/deprecated/ARadioField/ARadioField';
import { ASelectCouponField } from 'app/components/deprecated/ASelectCouponField/ASelectCouponField';
import { ASelectField } from 'app/components/deprecated/ASelectField/ASelectField';
import { theme } from 'app/styles/theme';
import { PlanPurchaseEnrollFromWaitlist_Fragment_planPurchase } from 'app/types/generated/PlanPurchaseEnrollFromWaitlist_Fragment_planPurchase';
import {
  PlanPurchaseEnrollFromWaitlist_Mutation,
  PlanPurchaseEnrollFromWaitlist_MutationVariables,
} from 'app/types/generated/PlanPurchaseEnrollFromWaitlist_Mutation';
import {
  PlanPurchaseEnrollFromWaitlist_Query,
  PlanPurchaseEnrollFromWaitlist_QueryVariables,
} from 'app/types/generated/PlanPurchaseEnrollFromWaitlist_Query';
import { displayErrors } from 'app/utils/app';
import { disablePastStartDates } from 'app/utils/datetime';
import { isValidPaymentCard } from 'app/utils/member';
import { getPlanPaymentOptions } from 'app/utils/plan';
import {
  getPlanPurchaseAmountDue,
  getPlanPurchaseStartTimeAPIInput,
  getPlanPurchaseSummary,
} from 'app/utils/planPurchase';
import { STATUS_MESSAGE } from 'constants/message';

import {
  PLAN_PURCHASE_ENROLL_FROM_WAITLIST_MUTATION,
  PLAN_PURCHASE_ENROLL_FROM_WAITLIST_QUERY,
  PLAN_PURCHASE_ENROLL_FROM_WAITLIST_REFETCH,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  waitlistPlanPurchase: PlanPurchaseEnrollFromWaitlist_Fragment_planPurchase;
}

/** Form to create a new care plan purchase for a member from a waitlist enrollment */
const PlanPurchaseEnrollFromWaitlist: FC<Props> = ({
  waitlistPlanPurchase,
}) => {
  const { data, loading: queryLoading } = useQuery<
    PlanPurchaseEnrollFromWaitlist_Query,
    PlanPurchaseEnrollFromWaitlist_QueryVariables
  >(PLAN_PURCHASE_ENROLL_FROM_WAITLIST_QUERY, {
    variables: { personID: waitlistPlanPurchase.member.id },
  });
  const [enrollFromWaitlist, { loading: mutationLoading }] = useMutation<
    PlanPurchaseEnrollFromWaitlist_Mutation,
    PlanPurchaseEnrollFromWaitlist_MutationVariables
  >(PLAN_PURCHASE_ENROLL_FROM_WAITLIST_MUTATION, {
    refetchQueries: [
      {
        query: PLAN_PURCHASE_ENROLL_FROM_WAITLIST_REFETCH,
        variables: { personID: waitlistPlanPurchase.member.id },
      },
    ],
  });

  if (!waitlistPlanPurchase.plan.product.type.includes('WAITLIST')) {
    return null;
  }

  const member = data?.member;
  const defaultEnrollmentPlan = waitlistPlanPurchase.plan.defaultFollowOnPlan;

  const onSave = async (
    input: { [K in keyof typeof fields]: any },
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      if (!data || !data.member || !data.member.center) {
        throw new Error(STATUS_MESSAGE.waitlistEnroll.error.noCenter);
      }

      const result = await enrollFromWaitlist({
        variables: {
          input: {
            addDefaultFollowOn: input.addFollowOn,
            couponSlug: input.couponSlug,
            newPlanID: input.planID,
            purchaseID: waitlistPlanPurchase.id,
            startTime: getPlanPurchaseStartTimeAPIInput(
              DateTime.fromJSDate((input.startTime as Moment).toDate()),
              data.member.center.timezone
            ),
          },
        },
      });
      if (result?.data) {
        message.success(STATUS_MESSAGE.waitlistEnroll.success);
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.waitlistEnroll.error.general);
      shouldFinish(false);
    }
  };

  return (
    <AForm
      disableOpen={
        !member
          ? { message: <ALoading color="white" size="small" /> }
          : member.paymentCards.edges.filter(({ node }) =>
              isValidPaymentCard(node)
            ).length < 1
          ? { message: STATUS_MESSAGE.waitlistEnroll.error.noValidPayment }
          : !member.center
          ? { message: STATUS_MESSAGE.waitlistEnroll.error.noCenter }
          : !defaultEnrollmentPlan
          ? {
              message: STATUS_MESSAGE.waitlistEnroll.error.noWaitlistFollowOn,
            }
          : undefined
      }
      loading={queryLoading || !data || !data.member || !data.plans}
      onSave={onSave}
      openAs="modal"
      openText="Enroll from waitlist"
      saveText="Enroll"
      saving={mutationLoading}
      savingText="Enrolling"
      title={`Enroll ${
        waitlistPlanPurchase.plan.defaultFollowOnPlan
          ? `in ${waitlistPlanPurchase.plan.defaultFollowOnPlan.product.displayName}`
          : 'from waitlist'
      }`}
    >
      {(form) => {
        const coupons = data?.coupons?.edges || [];
        const plans = data?.plans?.edges || [];

        // Center & default enrollment plan are checked for in Form.disableOpen
        if (!member || !member.center || !defaultEnrollmentPlan) {
          return <ALoading centered />;
        }

        const eligiblePlans = plans
          .filter(
            ({ node }) =>
              node.product.type === defaultEnrollmentPlan.product.type &&
              node.paymentRateInCents > 0
          )
          .map(({ node }) => node);
        const initialStartTime = DateTime.fromObject({
          zone: member.center.timezone,
        });

        const selectedPlan = eligiblePlans.find(
          (plan) =>
            plan.id ===
            (form.isFieldTouched(fields.planID.name)
              ? form.getFieldValue(fields.planID.name)
              : defaultEnrollmentPlan.id)
        );
        const selectedStartTime = form.isFieldTouched(fields.startTime.name)
          ? DateTime.fromJSDate(
              (form.getFieldValue(fields.startTime.name) as Moment).toDate()
            )
          : initialStartTime;
        const selectedCoupon = form.isFieldTouched(fields.couponSlug.name)
          ? coupons
              .map(({ node }) => node)
              .find(
                (coupon) =>
                  coupon.slug === form.getFieldValue(fields.couponSlug.name)
              )
          : waitlistPlanPurchase.coupon;

        const addFollowOn = form.isFieldTouched(fields.addFollowOn.name)
          ? form.getFieldValue(fields.addFollowOn.name)
          : selectedPlan?.defaultFollowOnPlan;

        return (
          <>
            {/* Product */}
            <AFormFieldRow>
              <ASelectField
                antdForm={form}
                customWidth={{ percent: 50 }}
                initialValue={defaultEnrollmentPlan.product.type}
                label={fields.product.label}
                name={fields.product.name}
                required
                selectOptions={[
                  {
                    label: defaultEnrollmentPlan.product.displayName,
                    value: defaultEnrollmentPlan.product.type,
                  },
                ]}
              />
            </AFormFieldRow>

            {/* Date */}
            <AFormFieldRow>
              <ADateField
                antdForm={form}
                disableDate={{
                  filter: disablePastStartDates(member.center.timezone),
                }}
                initialValue={initialStartTime.toISODate()}
                label={fields.startTime.label}
                name={fields.startTime.name}
                required
              />
            </AFormFieldRow>

            {/* Payment plan */}
            <AFormFieldRow>
              <ARadioField
                antdForm={form}
                initialValue={defaultEnrollmentPlan.id}
                label={fields.planID.label}
                name={fields.planID.name}
                radioOptions={getPlanPaymentOptions(
                  defaultEnrollmentPlan.product.type,
                  eligiblePlans
                )}
                required
                vertical
              />
            </AFormFieldRow>

            {/* Coupon */}
            <AFormFieldRow>
              <ASelectCouponField
                antdForm={form}
                coupons={coupons.map(({ node }) => node)}
                couponsToAlwaysAllow={compact([waitlistPlanPurchase.coupon])}
                customWidth={{ percent: 80 }}
                filterValues={{
                  centerID: member.center.id,
                  planID: selectedPlan?.id,
                  product: defaultEnrollmentPlan.product.type,
                }}
                initialValue={
                  waitlistPlanPurchase.coupon
                    ? waitlistPlanPurchase.coupon.slug
                    : undefined
                }
                label={fields.couponSlug.label}
                name={fields.couponSlug.name}
              />
            </AFormFieldRow>

            {/* Follow-on */}
            {selectedPlan?.defaultFollowOnPlan && (
              <AFormFieldRow>
                <ARadioField
                  antdForm={form}
                  initialValue={true}
                  label={fields.addFollowOn.label}
                  name={fields.addFollowOn.name}
                  radioOptions={[
                    { label: 'Add default follow-on', value: true },
                    { label: 'No follow-on', value: false },
                  ]}
                  required
                  vertical
                />
              </AFormFieldRow>
            )}

            {selectedPlan && (
              <Summary>
                <SummaryTitle>Summary</SummaryTitle>
                <ATextLight>
                  {getPlanPurchaseSummary(
                    selectedPlan,
                    selectedStartTime,
                    true
                  )}{' '}
                  {selectedCoupon && (
                    <>
                      {getPlanPurchaseAmountDue(selectedPlan, selectedCoupon)}
                      <br />
                    </>
                  )}
                  {addFollowOn &&
                    'Then ' +
                      getPlanPurchaseSummary(
                        plans.find(
                          ({ node }) =>
                            node.id === selectedPlan.defaultFollowOnPlan!.id
                        )!.node,
                        selectedStartTime.plus({
                          months: selectedPlan.monthsDuration!,
                        })
                      )}
                </ATextLight>
              </Summary>
            )}
          </>
        );
      }}
    </AForm>
  );
};

// Styled components ////////////////////////////////
const Summary = styled.div`
  margin-right: ${theme.space.s};
  width: 100%;
`;

const SummaryTitle = styled.div`
  font-weight: ${theme.font.weight.medium};
`;

/** Form field info */
const fields = {
  addFollowOn: {
    label: 'Follow-on plan',
    name: 'addFollowOn',
  },
  couponSlug: {
    label: 'Coupon code',
    name: 'couponSlug',
  },
  planID: {
    label: 'Payment',
    name: 'planID',
  },
  product: {
    label: 'Product',
    name: 'product',
  },
  startTime: {
    label: 'Start date',
    name: 'startTime',
  },
};

export { PlanPurchaseEnrollFromWaitlist, fields };
