import { useQuery } from '@apollo/react-hooks';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { FC } from 'react';

import { AFlexDivider } from 'app/components/atoms/AFlexDivider/AFlexDivider';
import { AFlexRowToColumn } from 'app/components/atoms/AFlexRowToColumn/AFlexRowToColumn';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import {
  GetMemberPersonEmergencyContact_Query,
  GetMemberPersonEmergencyContact_QueryVariables,
} from 'app/types/generated/GetMemberPersonEmergencyContact_Query';
import { RELATIONSHIPS } from 'constants/form';

// eslint-disable-next-line no-restricted-imports
import SkeletonStyled from '../../atoms/SkeletonStyled/SkeletonStyled';
import { MEMBER_GET_EMERGENCY_CONTACT } from './query';

interface Props {
  personID: string;
}

/** Show basic member info, e.g. name, demographic data, contact info */
const MemberEmergencyContact: FC<Props> = ({ personID }) => {
  const { data, error, loading } = useQuery<
    GetMemberPersonEmergencyContact_Query,
    GetMemberPersonEmergencyContact_QueryVariables
  >(MEMBER_GET_EMERGENCY_CONTACT, { variables: { personID } });

  if (loading) {
    return (
      <SkeletonStyled
        active
        paragraph={{
          rows: 4,
          width: ['30%', '60%', '60%', '30%'],
        }}
        title={false}
      />
    );
  }

  const info = data?.getPersonEmergencyContact;
  if (!info || info === null) {
    return <ATextLight>No emergency contact information</ATextLight>;
  }

  if (error || !personID) {
    return <ATextLight>Error loading emergency contact information</ATextLight>;
  }

  const { email, firstName, lastName, phoneNumber, relationship } = info;
  const parsedPhone = phoneNumber && parsePhoneNumberFromString(phoneNumber);
  const relationshipLabel = RELATIONSHIPS.find((r) => r.value === relationship)
    ?.label;

  return (
    <>
      <div data-private>
        {firstName}&nbsp;
        {lastName}
      </div>
      {relationship && (
        <AFlexRowToColumn>
          <div>{relationshipLabel}</div>
        </AFlexRowToColumn>
      )}
      <AFlexRowToColumn data-private>
        <div>
          {parsedPhone ? (
            parsedPhone.countryCallingCode === '1' ? (
              parsedPhone.formatNational()
            ) : (
              parsedPhone.formatInternational()
            )
          ) : (
            <ATextLight>No phone</ATextLight>
          )}
        </div>
        <AFlexDivider type="vertical" />
        <div data-private>{email}</div>
      </AFlexRowToColumn>
    </>
  );
};

export { MemberEmergencyContact };
