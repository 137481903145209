import React, { FC } from 'react';

import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  color?: string;
  height?: string;
  width?: string;
}

/** Icon representing an edit */
const AIconLink: FC<Props> = ({
  color = theme.color.jade,
  width = '16px',
  height = '16px',
}) => {
  return (
    <svg
      aria-label="icon: link"
      fill="none"
      height={height}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M10.6066 3.8788C10.2161 3.48827 9.58291 3.48827 9.19239 3.8788L8.48528 4.5859C8.09476 4.97643 7.46159 4.97643 7.07107 4.5859C6.68054 4.19538 6.68054 3.56221 7.07107 3.17169L7.77817 2.46458C8.94975 1.29301 10.8492 1.29301 12.0208 2.46458L13.435 3.8788C14.6066 5.05037 14.6066 6.94986 13.435 8.12144L12.7279 8.82854C12.3374 9.21907 11.7042 9.21907 11.3137 8.82854C10.9232 8.43802 10.9232 7.80485 11.3137 7.41433L12.0208 6.70722C12.4113 6.3167 12.4113 5.68353 12.0208 5.29301L10.6066 3.8788ZM4.94975 12.3641C5.34027 12.7546 5.97344 12.7546 6.36396 12.3641L7.07107 11.657C7.46159 11.2664 8.09476 11.2664 8.48528 11.657C8.87581 12.0475 8.8758 12.6807 8.48528 13.0712L7.77817 13.7783C6.6066 14.9499 4.70711 14.9499 3.53553 13.7783L2.12132 12.3641C0.949747 11.1925 0.949747 9.29301 2.12132 8.12144L2.82843 7.41433C3.21895 7.0238 3.85212 7.0238 4.24264 7.41433C4.63316 7.80485 4.63316 8.43802 4.24264 8.82854L3.53553 9.53565C3.14501 9.92617 3.14501 10.5593 3.53553 10.9499L4.94975 12.3641ZM10.6066 6.70722C10.9971 6.3167 10.9971 5.68353 10.6066 5.29301C10.2161 4.90248 9.58291 4.90248 9.19239 5.29301L4.94975 9.53565C4.55922 9.92617 4.55922 10.5593 4.94975 10.9499C5.34027 11.3404 5.97344 11.3404 6.36396 10.9499L10.6066 6.70722Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export { AIconLink };
