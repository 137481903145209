import gql from 'graphql-tag';

export const A_SELECT_INSURANCE_PAYER_CENTERS_QUERY = gql`
  query ASelectProviderInsuranceCredentials_Query(
    $filterAcceptedPayers: Boolean!
  ) {
    centers: listCenters {
      id
      name
    }
    payers: listPayers {
      id
      payerName
    }
    payer_centers: listPayerCenters(
      filterAcceptedPayers: $filterAcceptedPayers
    ) {
      insurancePayerID
      centerID
    }
  }
`;
