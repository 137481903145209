import styled, { Keyframes, keyframes } from 'styled-components/macro';

import { theme } from 'app/styles/theme';

const showDrawerKeyframes = (
  drawerSizePx: number = theme.layout.drawerSizeDefault
): Keyframes => keyframes`
  0% { padding-right: 0; }
  100% { padding-right: ${drawerSizePx}px; }
`;
const hideDrawerKeyframes = (
  drawerSizePx: number = theme.layout.drawerSizeDefault
): Keyframes => keyframes`
  0% { padding-right: ${drawerSizePx}px; }
  100% { padding-right: 0; }
`;

/**
 * Use to wrap anything that will contain a drawer that squishes main content
 * @param drawerSizePx defaults to theme.layout.drawerSizeDefault
 * @param showDrawer set to undefined so that no animation occurs on load
 */
export const ADrawerContainer = styled.div<{
  drawerSizePx?: number;
  showDrawer?: boolean;
}>`
  animation-duration: ${theme.animation.transitionDuration};
  animation-fill-mode: forwards;
  animation-name: ${({ drawerSizePx, showDrawer }) =>
    showDrawer === true
      ? showDrawerKeyframes(drawerSizePx)
      : showDrawer === false
      ? hideDrawerKeyframes(drawerSizePx)
      : ''};
  animation-timing-function: ${theme.animation.transitionTiming};
`;
