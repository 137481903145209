/* eslint-disable */

const breakpoint = {
  phone: 599, // px
  tabletPortrait: 600,
  tabletLandscape: 900,
  desktop: 1200,
  bigDesktop: 1800,
};

const breakpointMixin = {
  phoneOnly: `@media (max-width: ${breakpoint.phone}px)`,
  tabletPortraitDown: `@media (max-width: ${breakpoint.tabletLandscape - 1}px)`,
  tabletPortraitUp: `@media (min-width: ${breakpoint.tabletPortrait}px)`,
  tabletLandscapeDown: `@media (max-width: ${breakpoint.desktop - 1}px)`,
  tabletLandscapeUp: `@media (min-width: ${breakpoint.tabletLandscape}px)`,
  desktopUp: `@media (min-width: ${breakpoint.desktop}px)`,
  bigDesktopUp: `@media (min-width: ${breakpoint.bigDesktop}px)`,
};

const drawerSizeDefault = 375; // Minimum phone width, px
const headerSize = '3.5rem';
const navbarSize = 200; // Defined in Antd, px
const navbarSizeCollapsed = 80; // Defined in Antd, px

/** Values in px */
const calendar = {
  cellHeight: 30,
  cellMinWidth: 171,
  cellWidth: 250,
  headerHeight: 61.75,
  timeColumnWidth: 57,
};

export const layoutTheme = {
  breakpoint,
  breakpointMixin,
  calendar,
  drawerSizeDefault,
  headerSize,
  navbarSize,
  navbarSizeCollapsed,
};
