import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  subtitle?: string;
  title: string;
}

/** Header + bottom border used above a group of cards as a way to separate multiple groups */
const APrepCardGroupHeader: FC<Props> = ({ subtitle, title }) => (
  <StyledGroupHeader>
    <div>
      <ATextBold boldness="semibold">{title}</ATextBold>
    </div>
    <div>{subtitle}</div>
  </StyledGroupHeader>
);

// Styled components ////////////////////////////////
const StyledGroupHeader = styled.div`
  border-bottom: 1px solid ${theme.color.textLighter};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.space.sm};
`;

export { APrepCardGroupHeader };
