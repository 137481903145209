import gql from 'graphql-tag';

import { ProvidersTable_Fragment_provider } from 'app/components/organisms/ProvidersTable/fragment';

export const PROVIDER_CREATE_MUTATION = gql`
  mutation ProviderCreate_Mutation($input: PapiAddProviderInput!) {
    addProvider(input: $input) {
      ...ProvidersTable_Fragment_provider
    }
  }
  ${ProvidersTable_Fragment_provider}
`;
