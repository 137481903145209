import { useMutation, useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import {
  AForm,
  TFormFieldInfo,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { ASelectField } from 'app/components/deprecated/ASelectField/ASelectField';
import { theme } from 'app/styles/theme';
import {
  PapiExtendPurchaseInput,
  PapiProductType,
} from 'app/types/generated/globalTypes';
import { PlanPurchaseExtend_Fragment_extension } from 'app/types/generated/PlanPurchaseExtend_Fragment_extension';
import { PlanPurchaseExtend_Fragment_planPurchase } from 'app/types/generated/PlanPurchaseExtend_Fragment_planPurchase';
import {
  PlanPurchaseExtend_Mutation,
  PlanPurchaseExtend_MutationVariables,
} from 'app/types/generated/PlanPurchaseExtend_Mutation';
import { PlanPurchaseExtend_Query } from 'app/types/generated/PlanPurchaseExtend_Query';
import { displayErrors } from 'app/utils/app';
import { getPlanPurchaseStatus, isCurrentPlan } from 'app/utils/plan';
import { compareLabel } from 'app/utils/sort';
import { STATUS_MESSAGE } from 'constants/message';

import {
  PLAN_PURCHASE_EXTEND_MUTATION,
  PLAN_PURCHASE_EXTEND_QUERY,
  PLAN_PURCHASE_EXTEND_REFETCH,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  extensions?: PlanPurchaseExtend_Fragment_extension[];
  planPurchase: PlanPurchaseExtend_Fragment_planPurchase;
}

/** Form to extend a member's care plan by X number of months */
const PlanPurchaseExtend: FC<Props> = ({ extensions, planPurchase }) => {
  const { data, loading: queryLoading } = useQuery<PlanPurchaseExtend_Query>(
    PLAN_PURCHASE_EXTEND_QUERY
  );
  const [extendPlanPurchase, { loading: mutationLoading }] = useMutation<
    PlanPurchaseExtend_Mutation,
    PlanPurchaseExtend_MutationVariables
  >(PLAN_PURCHASE_EXTEND_MUTATION, {
    refetchQueries: [
      {
        query: PLAN_PURCHASE_EXTEND_REFETCH,
        variables: { personID: planPurchase.member.id },
      },
    ],
  });

  const newExtensionStart = planPurchase.endDateIncludingExtensions;
  const extensionsSortedAsc = sortBy(extensions, (extension) =>
    DateTime.fromISO(extension.startTime)
  );

  const onSave = async (
    input: PapiExtendPurchaseInput,
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      const result = await extendPlanPurchase({
        variables: {
          input: {
            centerID: planPurchase.center.id,
            currentPurchaseID:
              extensionsSortedAsc && extensionsSortedAsc.length > 0
                ? extensionsSortedAsc[extensionsSortedAsc.length - 1].id
                : planPurchase.id,
            personID: planPurchase.member.id,
            planID: input.planID,
          },
        },
      });
      if (result?.data) {
        message.success(STATUS_MESSAGE.planPurchaseExtend.success);
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.planPurchaseExtend.error.general);
      shouldFinish(false);
    }
  };

  return (
    <AForm
      disableOpen={
        !isCurrentPlan(planPurchase)
          ? { message: STATUS_MESSAGE.planPurchaseExtend.error.notCurrentPlan }
          : !newExtensionStart
          ? { message: STATUS_MESSAGE.planPurchaseExtend.error.noEndTime }
          : getPlanPurchaseStatus(planPurchase) === 'Paused'
          ? { message: STATUS_MESSAGE.planPurchaseExtend.error.paused }
          : undefined
      }
      loading={queryLoading || !data || !data.plans}
      onSave={onSave}
      openAs="modal"
      openText="Extend care plan"
      saving={mutationLoading}
      title={`Extend ${planPurchase.plan.product.displayName}`}
    >
      {(form) => {
        const plans = data?.plans?.edges || [];
        return (
          <>
            <AFormFieldRow>
              <ASelectField
                antdForm={form}
                customWidth={{ percent: 30 }}
                label={fields.planID.label}
                name={fields.planID.name}
                required
                selectOptions={plans
                  .filter(
                    (plan) =>
                      plan.node.product.type === PapiProductType.EXTENSION
                  )
                  .map((plan) => ({
                    label: `${plan.node.monthsDuration} month${
                      plan.node.monthsDuration! === 1 ? '' : 's'
                    }`,
                    value: plan.node.id,
                  }))
                  .sort(compareLabel)}
              />
            </AFormFieldRow>

            {form.getFieldValue(fields.planID.name) && newExtensionStart && (
              <Description>
                {`The extension will be effective ${DateTime.fromISO(
                  newExtensionStart
                )
                  .setZone(planPurchase.center.timezone)
                  .toLocaleString(DateTime.DATE_SHORT)} – ${DateTime.fromISO(
                  newExtensionStart
                )
                  .plus({
                    months: plans.find(
                      ({ node }) =>
                        node.id === form.getFieldValue(fields.planID.name)
                    )!.node.monthsDuration!,
                  })
                  .setZone(planPurchase.center.timezone)
                  .toLocaleString(DateTime.DATE_SHORT)}`}
                .
              </Description>
            )}
          </>
        );
      }}
    </AForm>
  );
};

// Styled components ////////////////////////////////
const Description = styled.div`
  margin: -${theme.space.s} ${theme.space.s} 0 ${theme.space.xs};
  position: absolute;
  width: 100%;
`;

/** Form field info */
const fields: Pick<TFormFieldInfo<PapiExtendPurchaseInput>, 'planID'> = {
  planID: {
    label: 'Extension',
    name: 'planID',
  },
};

export { PlanPurchaseExtend, fields };
