import { useMutation } from '@apollo/react-hooks';
import { Icon, Modal } from 'antd';
import capitalize from 'lodash/capitalize';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { ASectionBtmMargin } from 'app/components/atoms/ASectionBtmMargin/ASectionBtmMargin';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { theme } from 'app/styles/theme';
import {
  MemberThirdPartyResync_Mutation,
  MemberThirdPartyResync_MutationVariables,
} from 'app/types/generated/MemberThirdPartyResync_Mutation';
import { displayErrors } from 'app/utils/app';
import { STATUS_MESSAGE } from 'constants/message';

import { MEMBER_THIRD_PARTY_RESYNC_MUTATION } from './query';

// Types & constants ////////////////////////////////
interface Props {
  personID: string;
  setLoading?: (loading: boolean) => void;
}

const FAILURE_STRING = 'FAILURE:';

/** Link to resync member's third party services. Displays results on completion */
const MemberThirdPartyResync: FC<Props> = ({ personID, setLoading }) => {
  const [showResults, setShowResults] = useState(false);
  const [resync, { data }] = useMutation<
    MemberThirdPartyResync_Mutation,
    MemberThirdPartyResync_MutationVariables
  >(MEMBER_THIRD_PARTY_RESYNC_MUTATION);

  const onClick = async (): Promise<void> => {
    setLoading?.(true);
    try {
      await resync({ variables: { personID } });
      setLoading?.(false);
      setShowResults(true);
    } catch (err) {
      setLoading?.(false);
      displayErrors(err, STATUS_MESSAGE.memberThirdPartyResync.error.general);
    }
  };

  return (
    <>
      <div onClick={onClick}>Resync third party services</div>

      <Modal
        footer={null}
        onCancel={() => setShowResults(false)}
        title="Resync results"
        visible={!!data && showResults}
      >
        {data?.rectifyThirdParties
          ? Object.keys(data.rectifyThirdParties)
              .sort()
              .map((key) => {
                const result = data.rectifyThirdParties![key];
                return key.includes('Response') ? (
                  <ASectionBtmMarginStyled
                    error={result.includes(FAILURE_STRING)}
                  >
                    {result.includes(FAILURE_STRING) && (
                      <ErrorIcon
                        data-testid="MemberThirdPartyResync_resultError"
                        type="warning"
                      />
                    )}
                    <ATextBold>
                      {capitalize(key.replace('Response', ''))}
                    </ATextBold>
                    : {result.replace(FAILURE_STRING, '').trim()}
                  </ASectionBtmMarginStyled>
                ) : null;
              })
          : STATUS_MESSAGE.memberThirdPartyResync.error.noDataReturned}
      </Modal>
    </>
  );
};

// Styled components ////////////////////////////////
const ASectionBtmMarginStyled = styled(ASectionBtmMargin)<{ error: boolean }>`
  &&& {
    color: ${({ error }) => (error ? theme.color.error : 'inherit')};
  }
`;

const ErrorIcon = styled(Icon)`
  &&& {
    bottom: ${theme.space.xxs};
    margin-right: ${theme.space.xs};
    position: relative;
    vertical-align: baseline;
  }
`;

export { MemberThirdPartyResync };
