import gql from 'graphql-tag';

export const MEMBER_DELETE_QUERY = gql`
  query MemberDelete_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      currentPlan {
        id
      }
      email
      firstName
      lastName
      middleName
    }
  }
`;

export const MEMBER_DELETE_MUTATION = gql`
  mutation MemberDelete_Mutation($personID: ID!) {
    deletePapiPerson(personID: $personID) {
      firebaseResponse
      helpScoutResponse
      hubspotResponse
      mdhqResponse
      personResponse
    }
  }
`;
