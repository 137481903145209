import React, { FC } from 'react';

import { ASelect, ASelectProps } from 'app/components/atoms/ASelect/ASelect';
import { PapiProfessionalSuffix } from 'app/types/generated/globalTypes';
import { formatProfessionalSuffix } from 'app/utils/provider';
import { compareLabel } from 'app/utils/sort';

// Types & constants ////////////////////////////////
type Props = Omit<ASelectProps, 'selectOptions'>;

/** Select provider professional suffix (MD, PhD, etc) */
const ASelectProviderSuffix: FC<Props> = (props) => {
  return (
    <ASelect
      searchable
      selectOptions={Object.values(PapiProfessionalSuffix)
        .map((suffix) => ({
          label: formatProfessionalSuffix(suffix),
          value: suffix,
        }))
        .sort(compareLabel)}
      {...props}
    />
  );
};

export { ASelectProviderSuffix };
