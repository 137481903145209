import { DateTime } from 'luxon';

import { CalendarPage_Query_providerVisits_provider_visits_member_currentPlan_plan } from 'app/types/generated/CalendarPage_Query_providerVisits';
import {
  PapiBillingPeriod,
  PapiCouponApplication,
  PapiProductType,
  PapiInsuranceType,
  PapiConsumerType,
} from 'app/types/generated/globalTypes';
import { MemberVisitsTable_Query_member_visits_planPurchase_plan } from 'app/types/generated/MemberVisitsTable_Query';
import { PlanPurchaseCreate_Query_plans_edges_node } from 'app/types/generated/PlanPurchaseCreate_Query';
import { ProductsList_Query_products_plans } from 'app/types/generated/ProductsList_Query';
import { TYPENAMES } from 'app/types/papi';

import { MOCK_CENTERS } from './center';

type MockPlan = CalendarPage_Query_providerVisits_provider_visits_member_currentPlan_plan &
  MemberVisitsTable_Query_member_visits_planPurchase_plan &
  PlanPurchaseCreate_Query_plans_edges_node &
  ProductsList_Query_products_plans & {
    defaultFollowOnPlan: MockPlan | null;
  } & {
    insuranceType: PapiInsuranceType;
  };

// Products ////////////////////////////////
const products = [
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'ASE',
    deprecatedAt: null,
    description: 'The Assessment Care plan',
    displayName: 'Assessment',
    renewalPeriod: PapiBillingPeriod.HALF_YEAR,
    shopSummary:
      'For people looking for their next 360º evaluation and an updated personalized plan for living healthier. Includes 1 doctor and 1 health coach visit.',
    type: PapiProductType.ASSESSMENT,
  },
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'ASE',
    deprecatedAt: null,
    description: 'Waitlist for Assessment',
    displayName: 'Waitlist Assessment',
    renewalPeriod: PapiBillingPeriod.DAY,
    shopSummary: null,
    type: PapiProductType.WAITLIST_ASSESSMENT,
  },
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'ASE',
    deprecatedAt: null,
    description: 'A virtual plan for Assessment',
    displayName: 'Virtual Assessment',
    renewalPeriod: PapiBillingPeriod.HALF_YEAR,
    shopSummary:
      'For people looking for their next 360º evaluation and an updated personalized plan for living healthier. Includes 1 doctor and 1 health coach visit.',
    type: PapiProductType.VIRTUAL_ASSESSMENT,
  },
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'ASE',
    deprecatedAt: null,
    description: 'Waitlist for Virtual Assessment',
    displayName: 'Waitlist for Virtual Assessment',
    renewalPeriod: PapiBillingPeriod.DAY,
    shopSummary: null,
    type: PapiProductType.WAITLIST_VIRTUAL_ASSESSMENT,
  },
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'CC',
    deprecatedAt: null,
    description: 'The first year of Complete Care',
    displayName: 'Complete Care Y1',
    renewalPeriod: PapiBillingPeriod.YEAR,
    shopSummary:
      'Our most popular plan is perfect for anyone looking to address ongoing symptoms or make changes for peak performance. Includes 5 doctor and 5 health coaching visits, as well as unlimited messaging.',
    type: PapiProductType.COMPLETE_CARE_Y1,
  },
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'CC',
    deprecatedAt: null,
    description: 'Waitlist Complete Care',
    displayName: 'Waitlist Complete Care',
    renewalPeriod: PapiBillingPeriod.DAY,
    shopSummary: null,
    type: PapiProductType.WAITLIST_COMPLETE_CARE,
  },
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'CC',
    deprecatedAt: null,
    description: 'The second+ year of Complete Care',
    displayName: 'Complete Care Y2+',
    renewalPeriod: PapiBillingPeriod.YEAR,
    shopSummary:
      'Our most popular plan is perfect for anyone looking to address ongoing symptoms or make changes for peak performance. Includes 5 doctor and 5 health coaching visits, as well as unlimited messaging.',
    type: PapiProductType.COMPLETE_CARE_Y2_PLUS,
  },
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'CC',
    deprecatedAt: null,
    description: 'A slightly pared back second year of Complete Care',
    displayName: 'Complete Care Renewal',
    renewalPeriod: PapiBillingPeriod.YEAR,
    shopSummary:
      'For people looking for continuous whole-body care to address ongoing symptoms or make changes for peak performance. Includes 4 doctor and 4 health coach visits, as well as unlimited messaging.',
    type: PapiProductType.COMPLETE_CARE_RENEWAL,
  },
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'EC',
    deprecatedAt: '2020-01-23T16:45:00.000-05:00',
    description: 'The Essential Care plan',
    displayName: 'Essential Care',
    renewalPeriod: PapiBillingPeriod.YEAR,
    shopSummary:
      'For people who want a holistic approach, smarter diagnostics and a partner in health. Includes an advanced specialty test, 2 doctor and 2 health coach visits, as well as unlimited messaging.',
    type: PapiProductType.ESSENTIAL_CARE,
  },
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'EXT',
    deprecatedAt: null,
    description: 'Extension for any plan type',
    displayName: 'Extension',
    renewalPeriod: PapiBillingPeriod.DAY,
    shopSummary: null,
    type: PapiProductType.EXTENSION,
  },
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'FM',
    deprecatedAt: null,
    description: 'The Founding Membership Care Plan',
    displayName: 'Founding Membership',
    renewalPeriod: PapiBillingPeriod.YEAR,
    shopSummary: null,
    type: PapiProductType.FOUNDING_MEMBERSHIP,
  },
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'PED',
    deprecatedAt: null,
    description:
      'The first year of the Pediatrics Care plan, for members under the age of 18',
    displayName: 'Pediatrics',
    renewalPeriod: PapiBillingPeriod.YEAR,
    shopSummary: null,
    type: PapiProductType.PEDIATRICS,
  },
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'PED',
    deprecatedAt: null,
    description:
      'The second, or more, year of the Pediatrics Care plan, for members under the age of 18',
    displayName: 'Pediatrics Renewal',
    renewalPeriod: PapiBillingPeriod.YEAR,
    shopSummary: null,
    type: PapiProductType.PEDIATRICS_RENEWAL,
  },
  {
    __typename: TYPENAMES.PapiProduct,
    abbreviation: 'PED',
    deprecatedAt: null,
    description: 'Waitlist for Pediatrics',
    displayName: 'Waitlist Pediatrics',
    renewalPeriod: PapiBillingPeriod.DAY,
    shopSummary: null,
    type: PapiProductType.WAITLIST_PEDIATRICS,
  },
];

// Plans ////////////////////////////////
const MOCK_ASSESSMENT: MockPlan = {
  __typename: TYPENAMES.PapiPlan,
  annualCostInCents: 100000,
  billingPeriod: PapiBillingPeriod.HALF_YEAR,
  commitmentLength: null,
  consumerType: PapiConsumerType.DIRECT_CONSUMER,
  defaultFollowOnPlan: null,
  deprecatedAt: null,
  displayName: 'Assessment - Half Yearly Billing',
  id: '97c6c993-34b6-4be2-987d-3c667b9d7118',
  insuranceType: PapiInsuranceType.CASH_PAY,
  joinCentersAllowed: MOCK_CENTERS.slice(0, 3),
  maxPersonAgeYears: null,
  minPersonAgeYears: 18,
  monthsDuration: 6,
  numberOfBillingCycles: 1,
  paymentRateInCents: 50000,
  product: products.find(
    (product) => product.type === PapiProductType.ASSESSMENT
  )!,
};
const MOCK_ASSESSMENT_VIRTUAL: MockPlan = {
  ...MOCK_ASSESSMENT,
  displayName: 'Virtual Assessment',
  id: 'cc1f1524-76ed-477e-ab1d-cd8aa2b17f3b',
  insuranceType: PapiInsuranceType.CASH_PAY,
  joinCentersAllowed: [],
  product: products.find(
    (product) => product.type === PapiProductType.VIRTUAL_ASSESSMENT
  )!,
};
const MOCK_ASSESSMENT_VIRTUAL_WAITLIST: MockPlan = {
  __typename: TYPENAMES.PapiPlan,
  annualCostInCents: 0,
  billingPeriod: PapiBillingPeriod.HALF_YEAR,
  commitmentLength: null,
  consumerType: PapiConsumerType.DIRECT_CONSUMER,
  defaultFollowOnPlan: MOCK_ASSESSMENT_VIRTUAL,
  deprecatedAt: null,
  displayName: 'Waitlist Virtual Assessment',
  id: 'fb7f3f5c-af5e-44b0-8e64-b9e00399b7dc',
  insuranceType: PapiInsuranceType.CASH_PAY,
  joinCentersAllowed: MOCK_CENTERS,
  maxPersonAgeYears: null,
  minPersonAgeYears: 18,
  monthsDuration: null,
  numberOfBillingCycles: null,
  paymentRateInCents: 0,
  product: products.find(
    (product) => product.type === PapiProductType.WAITLIST_VIRTUAL_ASSESSMENT
  )!,
};

const MOCK_CCY2_ANNUAL: MockPlan = {
  __typename: TYPENAMES.PapiPlan,
  annualCostInCents: 160000,
  billingPeriod: PapiBillingPeriod.YEAR,
  commitmentLength: 12,
  consumerType: PapiConsumerType.DIRECT_CONSUMER,
  defaultFollowOnPlan: null,
  deprecatedAt: null,
  displayName: 'Complete Care Y2+ - Annual Billing',
  id: '9995991f-351e-40fe-b501-a8fe7625afe5',
  insuranceType: PapiInsuranceType.CASH_PAY,
  joinCentersAllowed: MOCK_CENTERS.slice(0, 3),
  maxPersonAgeYears: null,
  minPersonAgeYears: 18,
  monthsDuration: null,
  numberOfBillingCycles: null,
  paymentRateInCents: 160000,
  product: products.find(
    (product) => product.type === PapiProductType.COMPLETE_CARE_Y2_PLUS
  )!,
};
const MOCK_CCY2_MONTHLY: MockPlan = {
  ...MOCK_CCY2_ANNUAL,
  annualCostInCents: 180000,
  billingPeriod: PapiBillingPeriod.MONTH,
  displayName: 'Complete Care Y2+ - Monthly Billing',
  id: '618a9311-347c-460e-9ac1-5db38f279d53',
  paymentRateInCents: 15000,
};

const MOCK_CC_ANNUAL: MockPlan = {
  __typename: TYPENAMES.PapiPlan,
  annualCostInCents: 160000,
  billingPeriod: PapiBillingPeriod.YEAR,
  commitmentLength: 12,
  consumerType: PapiConsumerType.DIRECT_CONSUMER,
  defaultFollowOnPlan: MOCK_CCY2_ANNUAL,
  deprecatedAt: null,
  displayName: 'Complete Care Y1 - Annual Billing',
  id: '2d760d57-fe7c-4b4c-bbd2-a951c5ca0199',
  insuranceType: PapiInsuranceType.CASH_PAY,
  joinCentersAllowed: MOCK_CENTERS.slice(0, 3),
  maxPersonAgeYears: null,
  minPersonAgeYears: 18,
  monthsDuration: 12,
  numberOfBillingCycles: 1,
  paymentRateInCents: 160000,
  product: products.find(
    (product) => product.type === PapiProductType.COMPLETE_CARE_Y1
  )!,
};
const MOCK_CC_MONTHLY: MockPlan = {
  ...MOCK_CC_ANNUAL,
  annualCostInCents: 180000,
  billingPeriod: PapiBillingPeriod.MONTH,
  defaultFollowOnPlan: MOCK_CCY2_MONTHLY,
  displayName: 'Complete Care Y1 - Monthly Billing',
  id: '56265c6e-2265-46b2-bb8f-d286cc62b6c0',
  numberOfBillingCycles: 12,
  paymentRateInCents: 15000,
};

const MOCK_RENEWAL_ANNUAL: MockPlan = {
  __typename: TYPENAMES.PapiPlan,
  annualCostInCents: 122000,
  billingPeriod: PapiBillingPeriod.YEAR,
  commitmentLength: 12,
  consumerType: PapiConsumerType.DIRECT_CONSUMER,
  defaultFollowOnPlan: null,
  deprecatedAt: null,
  displayName: 'Complete Care Renewal - Annual Billing',
  id: 'ed4c3e1a-2edc-453e-863c-bad215a079cd',
  insuranceType: PapiInsuranceType.CASH_PAY,
  joinCentersAllowed: MOCK_CENTERS.slice(0, 3),
  maxPersonAgeYears: null,
  minPersonAgeYears: 18,
  monthsDuration: null,
  numberOfBillingCycles: null,
  paymentRateInCents: 122000,
  product: products.find(
    (product) => product.type === PapiProductType.COMPLETE_CARE_RENEWAL
  )!,
};
const MOCK_ESSENTIAL_ANNUAL: MockPlan = {
  __typename: TYPENAMES.PapiPlan,
  annualCostInCents: 85000,
  billingPeriod: PapiBillingPeriod.YEAR,
  commitmentLength: 12,
  consumerType: PapiConsumerType.DIRECT_CONSUMER,
  defaultFollowOnPlan: null,
  deprecatedAt: '2020-01-23T16:45:00.000-05:00',
  displayName: 'Essential Care - Annual Billing',
  id: '49f3ce6f-1a24-47ea-8639-f5b89500eb2c',
  insuranceType: PapiInsuranceType.CASH_PAY,
  joinCentersAllowed: MOCK_CENTERS.slice(0, 3),
  maxPersonAgeYears: null,
  minPersonAgeYears: 18,
  monthsDuration: null,
  numberOfBillingCycles: null,
  paymentRateInCents: 85000,
  product: products.find(
    (product) => product.type === PapiProductType.ESSENTIAL_CARE
  )!,
};
const MOCK_FOUNDING_ANNUAL: MockPlan = {
  __typename: TYPENAMES.PapiPlan,
  annualCostInCents: 100000,
  billingPeriod: PapiBillingPeriod.YEAR,
  commitmentLength: 12,
  consumerType: PapiConsumerType.DIRECT_CONSUMER,
  defaultFollowOnPlan: null,
  deprecatedAt: null,
  displayName: 'Founding Membership - Annual Billing',
  id: 'be5a8200-0fce-46e4-913e-80fcff25a0db',
  insuranceType: PapiInsuranceType.CASH_PAY,
  joinCentersAllowed: MOCK_CENTERS.slice(0, 3),
  maxPersonAgeYears: null,
  minPersonAgeYears: 0,
  monthsDuration: null,
  numberOfBillingCycles: null,
  paymentRateInCents: 100000,
  product: products.find(
    (product) => product.type === PapiProductType.FOUNDING_MEMBERSHIP
  )!,
};

const MOCK_PEDS_RENEW_ANNUAL: MockPlan = {
  __typename: TYPENAMES.PapiPlan,
  annualCostInCents: 140000,
  billingPeriod: PapiBillingPeriod.YEAR,
  commitmentLength: 12,
  consumerType: PapiConsumerType.DIRECT_CONSUMER,
  defaultFollowOnPlan: null,
  deprecatedAt: null,
  displayName: 'Pediatrics Renewal - Annual Billing',
  id: '9b505938-0649-4fb1-969d-11848259d23b',
  insuranceType: PapiInsuranceType.CASH_PAY,
  joinCentersAllowed: [MOCK_CENTERS[0]],
  maxPersonAgeYears: 17,
  minPersonAgeYears: 0,
  monthsDuration: null,
  numberOfBillingCycles: null,
  paymentRateInCents: 140000,
  product: products.find(
    (product) => product.type === PapiProductType.PEDIATRICS_RENEWAL
  )!,
};
const MOCK_PEDS_RENEW_MONTHLY: MockPlan = {
  ...MOCK_PEDS_RENEW_ANNUAL,
  annualCostInCents: 154800,
  billingPeriod: PapiBillingPeriod.MONTH,
  displayName: 'Pediatrics Renewal - Monthly Billing',
  id: '9a113949-d6ad-4f24-bd8b-cad7cfcd879e',
  numberOfBillingCycles: 12,
  paymentRateInCents: 12900,
};
const MOCK_PEDS_ANNUAL: MockPlan = {
  __typename: TYPENAMES.PapiPlan,
  annualCostInCents: 140000,
  billingPeriod: PapiBillingPeriod.YEAR,
  commitmentLength: 12,
  consumerType: PapiConsumerType.DIRECT_CONSUMER,
  defaultFollowOnPlan: MOCK_PEDS_RENEW_ANNUAL,
  deprecatedAt: null,
  displayName: 'Pediatrics - Annual Billing',
  id: 'bc258189-5124-4c9d-b19f-41b7fc2e45f2',
  insuranceType: PapiInsuranceType.CASH_PAY,
  joinCentersAllowed: [MOCK_CENTERS[0]],
  maxPersonAgeYears: 17,
  minPersonAgeYears: 0,
  monthsDuration: 12,
  numberOfBillingCycles: 1,
  paymentRateInCents: 140000,
  product: products.find(
    (product) => product.type === PapiProductType.PEDIATRICS
  )!,
};
const MOCK_PEDS_MONTHLY: MockPlan = {
  ...MOCK_PEDS_ANNUAL,
  annualCostInCents: 154800,
  billingPeriod: PapiBillingPeriod.MONTH,
  defaultFollowOnPlan: MOCK_PEDS_RENEW_MONTHLY,
  displayName: 'Pediatrics - Monthly Billing',
  id: '7318f135-9beb-4e2c-b691-eb72e8e91f07',
  numberOfBillingCycles: 12,
  paymentRateInCents: 12900,
};

const MOCK_EXTENSION = (numMonths: number): MockPlan => ({
  __typename: TYPENAMES.PapiPlan,
  annualCostInCents: 0,
  billingPeriod: PapiBillingPeriod.MONTH,
  commitmentLength: null,
  consumerType: PapiConsumerType.DIRECT_CONSUMER,
  defaultFollowOnPlan: null,
  deprecatedAt: null,
  displayName: `Free Extension - ${numMonths} months - No Charge`,
  id: `0259b9a4-af80-4355-91a5-811d8482c79${numMonths}`,
  insuranceType: PapiInsuranceType.CASH_PAY,
  joinCentersAllowed: MOCK_CENTERS.slice(0, 3),
  maxPersonAgeYears: null,
  minPersonAgeYears: 0,
  monthsDuration: numMonths,
  numberOfBillingCycles: numMonths,
  paymentRateInCents: 0,
  product: products.find(
    (product) => product.type === PapiProductType.EXTENSION
  )!,
});

export const MOCK_PLANS: MockPlan[] = [
  {
    ...MOCK_ASSESSMENT,
    annualCostInCents: 0,
    commitmentLength: null,
    displayName: 'Assessment - Complimentary',
    id: 'ef0e14de-6a74-497f-a9a5-b24805ea8668',
    paymentRateInCents: 0,
  },
  MOCK_ASSESSMENT,
  {
    ...MOCK_ASSESSMENT,
    annualCostInCents: 0,
    commitmentLength: null,
    defaultFollowOnPlan: MOCK_ASSESSMENT,
    displayName: 'Waitlist for Assessment - No Charge',
    id: '460473a7-c059-4b2e-abee-5faec33c838d',
    monthsDuration: null,
    numberOfBillingCycles: null,
    paymentRateInCents: 0,
    product: products.find(
      (product) => product.type === PapiProductType.WAITLIST_ASSESSMENT
    )!,
  },
  MOCK_ASSESSMENT_VIRTUAL,
  MOCK_ASSESSMENT_VIRTUAL_WAITLIST,
  MOCK_CC_ANNUAL,
  MOCK_CC_MONTHLY,
  {
    ...MOCK_CC_ANNUAL,
    annualCostInCents: 0,
    commitmentLength: null,
    displayName: 'Complete Care Y1 - Complimentary',
    id: '3c6274da-1e63-422f-97c4-b181357b22ee',
    paymentRateInCents: 0,
  },
  {
    ...MOCK_CC_ANNUAL,
    annualCostInCents: 0,
    commitmentLength: null,
    defaultFollowOnPlan: MOCK_CC_ANNUAL,
    displayName: 'Waitlist for Complete Care Annual - No Charge',
    id: '44bcc284-362b-422c-a18d-c74c083dbd21',
    monthsDuration: null,
    numberOfBillingCycles: null,
    paymentRateInCents: 0,
    product: products.find(
      (product) => product.type === PapiProductType.WAITLIST_COMPLETE_CARE
    )!,
  },
  {
    ...MOCK_CC_ANNUAL,
    annualCostInCents: 0,
    billingPeriod: PapiBillingPeriod.MONTH,
    commitmentLength: null,
    defaultFollowOnPlan: MOCK_CC_MONTHLY,
    displayName: 'Waitlist for Complete Care Monthly - No Charge',
    id: '083f9d98-1973-4f35-bf33-92589629060b',
    monthsDuration: null,
    numberOfBillingCycles: null,
    paymentRateInCents: 0,
    product: products.find(
      (product) => product.type === PapiProductType.WAITLIST_COMPLETE_CARE
    )!,
  },
  MOCK_CCY2_ANNUAL,
  MOCK_CCY2_MONTHLY,
  {
    ...MOCK_CCY2_ANNUAL,
    annualCostInCents: 0,
    commitmentLength: null,
    displayName: 'Complete Care Y2+ - Complimentary',
    id: '7c0a6649-a1e4-4f88-b5ce-b7c5c6cbc5c4',
    paymentRateInCents: 0,
  },
  MOCK_RENEWAL_ANNUAL,
  {
    ...MOCK_RENEWAL_ANNUAL,
    annualCostInCents: 132000,
    billingPeriod: PapiBillingPeriod.MONTH,
    displayName: 'Complete Care Renewal - Monthly Billing',
    id: '1a57b36b-0248-402b-b7f6-b99352528fb4',
    numberOfBillingCycles: 12,
    paymentRateInCents: 11000,
  },
  {
    ...MOCK_RENEWAL_ANNUAL,
    annualCostInCents: 0,
    commitmentLength: null,
    displayName: 'Complete Care Renewal - Complimentary',
    id: '1c992626-e9b6-45a4-a3c7-ae24381d4c7e',
    paymentRateInCents: 0,
  },
  MOCK_ESSENTIAL_ANNUAL,
  {
    ...MOCK_ESSENTIAL_ANNUAL,
    annualCostInCents: 94800,
    billingPeriod: PapiBillingPeriod.MONTH,
    displayName: 'Essential Care - Monthly Billing',
    id: 'a5961a9e-a5b4-41c7-9aea-555d935c8a21',
    numberOfBillingCycles: 12,
    paymentRateInCents: 7900,
  },
  MOCK_FOUNDING_ANNUAL,
  MOCK_PEDS_ANNUAL,
  MOCK_PEDS_MONTHLY,
  {
    ...MOCK_PEDS_ANNUAL,
    annualCostInCents: 0,
    commitmentLength: null,
    defaultFollowOnPlan: MOCK_PEDS_ANNUAL,
    displayName: 'Waitlist for Pediatrics Annual - No Charge',
    id: '686fbab5-8c5f-4116-ae21-a21b75d74598',
    monthsDuration: null,
    numberOfBillingCycles: null,
    paymentRateInCents: 0,
    product: products.find(
      (product) => product.type === PapiProductType.WAITLIST_PEDIATRICS
    )!,
  },
  {
    ...MOCK_PEDS_MONTHLY,
    annualCostInCents: 0,
    commitmentLength: null,
    defaultFollowOnPlan: MOCK_PEDS_MONTHLY,
    displayName: 'Waitlist for Pediatrics Monthly - No Charge',
    id: '65a414c3-507f-4f74-8b97-0bc777099c6f',
    monthsDuration: null,
    numberOfBillingCycles: null,
    paymentRateInCents: 0,
    product: products.find(
      (product) => product.type === PapiProductType.WAITLIST_PEDIATRICS
    )!,
  },
  MOCK_PEDS_RENEW_ANNUAL,
  MOCK_PEDS_RENEW_MONTHLY,
  MOCK_EXTENSION(1),
  MOCK_EXTENSION(2),
  MOCK_EXTENSION(3),
];

export const MOCK_PLANS_CONNECTION = {
  __typename: TYPENAMES.PapiPlanConnection,
  edges: MOCK_PLANS.map((plan) => ({
    __typename: TYPENAMES.PapiPlanEdge,
    node: plan,
  })),
};

/** Products with plans array */
export const MOCK_PRODUCTS = products.map((product) => ({
  ...product,
  plans: MOCK_PLANS.filter((plan) => plan.product.type === product.type),
}));

// Coupons ////////////////////////////////
export const MOCK_COUPONS = [
  {
    __typename: TYPENAMES.PapiCoupon,
    centers: [],
    description: 'Facebook general promo',
    discount: {
      __typename: TYPENAMES.PapiFlatDiscount,
      amountOffCents: 5000,
    },
    duration: {
      __typename: TYPENAMES.PapiCouponApplies,
      apply: PapiCouponApplication.ONCE,
    },
    maxRedemptions: null,
    plans: [],
    products: [],
    redeemBy: null,
    slug: 'BEWELLPARSLEY',
  },
  {
    __typename: TYPENAMES.PapiCoupon,
    centers: [],
    description: 'Gluten-free podcast',
    discount: {
      __typename: TYPENAMES.PapiFlatDiscount,
      amountOffCents: 15000,
    },
    duration: {
      __typename: TYPENAMES.PapiCouponApplies,
      apply: PapiCouponApplication.ONCE,
    },
    maxRedemptions: null,
    plans: [],
    products: [],
    redeemBy: null,
    slug: 'GLUTENFREE150',
  },
  {
    __typename: TYPENAMES.PapiCoupon,
    centers: [],
    description: 'Wired biohack article targeting',
    discount: {
      __typename: TYPENAMES.PapiFlatDiscount,
      amountOffCents: 15000,
    },
    duration: {
      __typename: TYPENAMES.PapiCouponApplies,
      apply: PapiCouponApplication.ONCE,
    },
    maxRedemptions: null,
    plans: [],
    products: [],
    redeemBy: null,
    slug: 'BIOHACK150',
  },
  {
    __typename: TYPENAMES.PapiCoupon,
    centers: [],
    description: '$200 off for ModelFit community',
    discount: {
      __typename: TYPENAMES.PapiFlatDiscount,
      amountOffCents: 20000,
    },
    duration: {
      __typename: TYPENAMES.PapiCouponApplies,
      apply: PapiCouponApplication.ONCE,
    },
    maxRedemptions: null,
    plans: [],
    products: [],
    redeemBy: DateTime.fromObject({ zone: 'America/Los_Angeles' })
      .minus({ months: 2 })
      .endOf('month')
      .toISO(),
    slug: 'HEALTHY200',
  },
  {
    __typename: TYPENAMES.PapiCoupon,
    centers: [],
    description: 'Holistic RX partnership promo',
    discount: {
      __typename: TYPENAMES.PapiPercentageDiscount,
      percentOff: 18.75,
    },
    duration: {
      __typename: TYPENAMES.PapiCouponApplies,
      apply: PapiCouponApplication.FOREVER,
    },
    maxRedemptions: null,
    plans: [],
    products: [],
    redeemBy: DateTime.fromObject({ zone: 'America/Los_Angeles' })
      .minus({ years: 1 })
      .endOf('month')
      .toISO(),
    slug: 'HOLISTICRXPH',
  },
  {
    __typename: TYPENAMES.PapiCoupon,
    centers: [],
    description: 'Fast Company promotion',
    discount: {
      __typename: TYPENAMES.PapiPercentageDiscount,
      percentOff: 12.5,
    },
    duration: {
      __typename: TYPENAMES.PapiCouponApplies,
      apply: PapiCouponApplication.FOREVER,
    },
    maxRedemptions: 100,
    plans: [],
    products: [],
    redeemBy: DateTime.fromObject({ zone: 'America/Los_Angeles' })
      .plus({ days: 20 })
      .endOf('month')
      .toISO(),
    slug: 'FAST200',
  },
  {
    __typename: TYPENAMES.PapiCoupon,
    centers: [MOCK_CENTERS[0]],
    description: 'Legacy coupon - FOUNDER300',
    discount: {
      __typename: TYPENAMES.PapiPercentageDiscount,
      percentOff: 18.75,
    },
    duration: {
      __typename: TYPENAMES.PapiCouponApplies,
      apply: PapiCouponApplication.FOREVER,
    },
    maxRedemptions: null,
    plans: [],
    products: [
      {
        __typename: TYPENAMES.PapiProduct,
        displayName: 'Complete Care Y1',
        type: PapiProductType.COMPLETE_CARE_Y1,
      },
      {
        __typename: TYPENAMES.PapiProduct,
        displayName: 'Waitlist Complete Care',
        type: PapiProductType.WAITLIST_COMPLETE_CARE,
      },
      {
        __typename: TYPENAMES.PapiProduct,
        displayName: 'Complete Care Y2+',
        type: PapiProductType.COMPLETE_CARE_Y2_PLUS,
      },
    ],
    redeemBy: DateTime.fromObject({ zone: 'America/Los_Angeles' })
      .minus({ days: 15, months: 1 })
      .endOf('month')
      .toISO(),
    slug: 'FOUNDER300',
  },
  {
    __typename: TYPENAMES.PapiCoupon,
    centers: [],
    description: 'Intro email promo',
    discount: {
      __typename: TYPENAMES.PapiFlatDiscount,
      amountOffCents: 5000,
    },
    duration: {
      __typename: TYPENAMES.PapiCouponApplies,
      apply: PapiCouponApplication.ONCE,
    },
    maxRedemptions: null,
    plans: [],
    products: [
      {
        __typename: TYPENAMES.PapiProduct,
        displayName: 'Complete Care Y1',
        type: PapiProductType.COMPLETE_CARE_Y1,
      },
      {
        __typename: TYPENAMES.PapiProduct,
        displayName: 'Waitlist Complete Care',
        type: PapiProductType.WAITLIST_COMPLETE_CARE,
      },
    ],
    redeemBy: null,
    slug: 'PARSLEY50CC',
  },
  {
    __typename: TYPENAMES.PapiCoupon,
    centers: [],
    description: 'Pediatrics yearly plan coupon',
    discount: {
      __typename: TYPENAMES.PapiFlatDiscount,
      amountOffCents: 40000,
    },
    duration: {
      __typename: TYPENAMES.PapiCouponApplies,
      apply: PapiCouponApplication.ONCE,
    },
    maxRedemptions: null,
    plans: MOCK_PLANS.filter(
      (plan) =>
        plan.product.type === PapiProductType.PEDIATRICS &&
        plan.billingPeriod === PapiBillingPeriod.YEAR
    ),
    products: [
      products.find((product) => product.type === PapiProductType.PEDIATRICS)!,
    ],
    redeemBy: null,
    slug: 'PEDSYEAR',
  },
  {
    __typename: TYPENAMES.PapiCoupon,
    centers: [],
    description: 'Pediatrics monthly plan coupon',
    discount: {
      __typename: TYPENAMES.PapiPercentageDiscount,
      percentOff: 18.75,
    },
    duration: {
      __typename: TYPENAMES.PapiCouponRepeats,
      months: 12,
    },
    maxRedemptions: null,
    plans: MOCK_PLANS.filter(
      (plan) =>
        plan.product.type === PapiProductType.PEDIATRICS &&
        plan.billingPeriod === PapiBillingPeriod.MONTH
    ),
    products: [
      products.find((product) => product.type === PapiProductType.PEDIATRICS)!,
    ],
    redeemBy: null,
    slug: 'PEDSMONTH',
  },
];

export const MOCK_COUPONS_CONNECTION = {
  __typename: TYPENAMES.PapiCouponConnection,
  edges: MOCK_COUPONS.map((coupon) => ({
    __typename: TYPENAMES.PapiCouponEdge,
    node: coupon,
  })),
};
