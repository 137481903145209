import gql from 'graphql-tag';

export const A_SELECT_PLAN_FIELD_QUERY = gql`
  query ASelectPlanField_Query {
    plans: listAllPlans {
      edges {
        node {
          id
          displayName
        }
      }
    }
  }
`;
