import { useQuery } from '@apollo/react-hooks';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { ATextBold } from 'app/components/atoms/ATextBold/ATextBold';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { theme } from 'app/styles/theme';
import {
  VisitDetailsMemberVisits_Query,
  VisitDetailsMemberVisits_QueryVariables,
  VisitDetailsMemberVisits_Query_member_visits,
} from 'app/types/generated/VisitDetailsMemberVisits_Query';
import { timeDiffText } from 'app/utils/datetime';
import { formatProviderName } from 'app/utils/provider';
import { VISIT_STATUSES_SCHEDULED } from 'constants/visit';

import { VISIT_DETAILS_MEMBER_VISITS_QUERY } from './query';

// Types & constants ////////////////////////////////
interface Props {
  memberId: string;
  visitId: string;
}

interface VisitProps {
  currentVisit: VisitDetailsMemberVisits_Query_member_visits;
  visit: VisitDetailsMemberVisits_Query_member_visits;
}

const MemberVisit: FC<VisitProps> = ({ currentVisit, visit }) => {
  const startDateTime = DateTime.fromISO(visit.startTime);
  const currentVisitDateTime = DateTime.fromISO(currentVisit.startTime);
  return (
    <Visit>
      <VisitHeader>
        <VisitHeaderText>
          {currentVisitDateTime > startDateTime ? 'Previous' : 'Upcoming'}
        </VisitHeaderText>
        <VisitHeaderDate>
          {`${capitalize(
            timeDiffText(startDateTime)
          )}, ${startDateTime.toFormat('M/d/yyyy')}`}
        </VisitHeaderDate>
      </VisitHeader>
      <div>{visit.categoryDetails?.name}</div>
      <div>{visit.provider && formatProviderName(visit.provider, true)}</div>
    </Visit>
  );
};

/** no upcoming visits a cta to schedule. note: right now placeholder */
const ScheduleNow: FC = () => {
  return (
    <Visit>
      <VisitHeader>
        <VisitHeaderText>Upcoming</VisitHeaderText>
        <VisitHeaderDate>None scheduled</VisitHeaderDate>
      </VisitHeader>
    </Visit>
  );
};

/** show the previous and next visits given a current visit. */
const VisitDetailsMemberVisits: FC<Props> = ({ memberId, visitId }) => {
  const { data, loading } = useQuery<
    VisitDetailsMemberVisits_Query,
    VisitDetailsMemberVisits_QueryVariables
  >(VISIT_DETAILS_MEMBER_VISITS_QUERY, {
    variables: { personID: memberId },
  });

  const visits = (data?.member?.visits || []).filter((visit) =>
    VISIT_STATUSES_SCHEDULED.includes(visit.status)
  );

  /** sort by startingAt desc */
  const sortedVisits = visits.sort(
    (a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
  );
  const currentIndex = sortedVisits.findIndex((visit) => visit.id === visitId);
  const currentVisit = sortedVisits[currentIndex];

  const nextVisit =
    currentIndex > 0 ? sortedVisits[currentIndex - 1] : undefined;

  const previousVisit =
    sortedVisits.length === currentIndex - 1
      ? undefined
      : sortedVisits[currentIndex + 1];

  if (loading) {
    return <ALoading />;
  }

  if (!nextVisit && !previousVisit) {
    return <ATextLight>No visit history</ATextLight>;
  }

  return (
    <>
      {nextVisit ? (
        <MemberVisit currentVisit={currentVisit} visit={nextVisit} />
      ) : (
        <ScheduleNow />
      )}
      {previousVisit && (
        <MemberVisit currentVisit={currentVisit} visit={previousVisit} />
      )}
    </>
  );
};

// Styled components ////////////////////////////////
const VisitHeader = styled.div`
  display: flex;
  flex-direction: row;
`;
const VisitHeaderDate = styled(ATextLight)`
  font-size: ${theme.font.size.s};
`;
const VisitHeaderText = styled(ATextBold)`
  flex: 1;
`;
const Visit = styled.div`
  :not(:last-child) {
    margin-bottom: ${theme.space.m};
  }
`;

export { VisitDetailsMemberVisits };
