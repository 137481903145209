import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { ATextError } from 'app/components/atoms/ATextError/ATextError';
import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  show: boolean;
}

/** Form field error explanation that slides in from the top */
const AFormFieldError: FC<Props> = ({ children, show }) => (
  <Styles>
    <Message show={show}>
      <ATextError>{show && children}</ATextError>
    </Message>
  </Styles>
);

// Styled components ////////////////////////////////
const fadeInFromTop = keyframes`
  0% {
    bottom: ${theme.space.xs};
    opacity: 0.5;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
`;

const Message = styled.div<{ show: boolean }>`
  animation-duration: ${theme.animation.transitionDuration};
  animation-fill-mode: forwards;
  animation-name: ${({ show }) => (show ? fadeInFromTop : '')};
  animation-timing-function: ${theme.animation.transitionTiming};
  position: relative;
`;

const Styles = styled.div`
  font-size: ${theme.font.size.s};
  height: ${theme.font.lineHeight.base}rem;
  overflow: scroll;
  white-space: nowrap;
`;

export { AFormFieldError };
