import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

export const ATextBold = styled.span<{
  boldness?: keyof typeof theme.font.weight;
}>`
  font-weight: ${({ boldness }) => theme.font.weight[boldness || 'medium']};
  * {
    font-weight: ${({ boldness }) => theme.font.weight[boldness || 'medium']};
  }
`;
