import gql from 'graphql-tag';

import { ASelectCouponField_Fragment_coupon } from 'app/components/deprecated/ASelectCouponField/fragment';

export const PLAN_PURCHASE_CREATE_QUERY = gql`
  query EnterprisePlanPurchaseCreate_Query($personID: ID!) {
    coupons: listCoupons(onlyRedeemable: true) {
      edges {
        node {
          ...ASelectCouponField_Fragment_coupon
        }
      }
    }
    member: getPerson(id: $personID) {
      id
      allPlans {
        edges {
          node {
            id
            center {
              id
            }
            coupon {
              ...ASelectCouponField_Fragment_coupon
            }
            endTime
            plan {
              id
              product {
                type
              }
            }
            startTime
          }
        }
      }
      center {
        id
        timezone
      }
      dateOfBirth
      paymentCards {
        edges {
          node {
            id
            deleted
            expiryMonth
            expiryYear
          }
        }
      }
    }
    plans: listAllPlans {
      edges {
        node {
          id
          annualCostInCents
          billingPeriod
          consumerType
          defaultFollowOnPlan {
            id
            product {
              displayName
              type
            }
          }
          deprecatedAt
          displayName
          insuranceType
          maxPersonAgeYears
          minPersonAgeYears
          monthsDuration
          numberOfBillingCycles
          paymentRateInCents
          product {
            displayName
            type
          }
        }
      }
    }
  }
  ${ASelectCouponField_Fragment_coupon}
`;

export const GET_ENTERPRISE_PARTNER_PLANS = gql`
  query GetEnterprisePartnerPlans_Query($slug: String!) {
    getPartnerPlans(slug: $slug) {
      partnerID
      planID
      isDefault
    }
  }
`;

export const ENTERPRISE_PLAN_CREATE_MUTATION = gql`
  mutation EnterprisePlanCreate_Mutation($input: PapiMakeNewB2BPurchaseInput!) {
    makeNewB2BPurchase(input: $input) {
      planPurchase {
        id
      }
    }
  }
`;
