import gql from 'graphql-tag';

export const ProviderPage_Fragment_provider = gql`
  fragment ProviderPage_Fragment_provider on PapiProvider {
    id
    displayName
    type
    pod {
      name
      id
    }
  }
`;
