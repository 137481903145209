import { Tooltip } from 'antd';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'app/styles/theme';

// Types & constants ////////////////////////////////
interface Props {
  tooltip: ReactNode;
}

/** Help icon (question mark in a circle) that displays tooltip on hover over */
const AHelpTooltip: FC<Props> = ({ children, tooltip }) => (
  <Tooltip title={tooltip}>
    {children || (
      <Help>
        <svg
          fill="none"
          height="14"
          viewBox="0 0 14 14"
          width="14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7 11.0625C6.65469 11.0625 6.375 10.7828 6.375 10.4375C6.375 10.0922 6.65469 9.8125 7 9.8125C7.34531 9.8125 7.625 10.0922 7.625 10.4375C7.625 10.7828 7.34531 11.0625 7 11.0625ZM7.98281 7.63281C7.84131 7.68746 7.71955 7.78347 7.63343 7.90834C7.54731 8.03321 7.50081 8.18113 7.5 8.33281V8.6875C7.5 8.75625 7.44375 8.8125 7.375 8.8125H6.625C6.55625 8.8125 6.5 8.75625 6.5 8.6875V8.35156C6.5 7.99062 6.60469 7.63437 6.81094 7.3375C7.0125 7.04687 7.29375 6.825 7.625 6.69844C8.15625 6.49375 8.5 6.04844 8.5 5.5625C8.5 4.87344 7.82656 4.3125 7 4.3125C6.17344 4.3125 5.5 4.87344 5.5 5.5625V5.68125C5.5 5.75 5.44375 5.80625 5.375 5.80625H4.625C4.55625 5.80625 4.5 5.75 4.5 5.68125V5.5625C4.5 4.94844 4.76875 4.375 5.25625 3.94844C5.725 3.5375 6.34375 3.3125 7 3.3125C7.65625 3.3125 8.275 3.53906 8.74375 3.94844C9.23125 4.375 9.5 4.94844 9.5 5.5625C9.5 6.46562 8.90469 7.27812 7.98281 7.63281Z"
            fill={theme.color.silverChalice}
          />
        </svg>
      </Help>
    )}
  </Tooltip>
);

// Styled components ////////////////////////////////
const Help = styled.span`
  &&& {
    bottom: ${theme.space.xxs};
    margin-left: ${theme.space.s};
    position: relative;
  }
`;

export { AHelpTooltip };
