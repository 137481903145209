import gql from 'graphql-tag';

export const typeDefs = gql`
  extend type Query {
    mockedGetAppointmentsByStatus(
      minTime: String!
      maxTime: String!
      centerIds: [ID!]
      statuses: [PapiAppointmentStatus!]!
      questionnairesStatuses: [PapiQuestionnaireStatus!]!
      categories: [PapiAppointmentCategory!]!
      providerID: ID
      orderBy: String
    ): [PapiAppointment!]!
  }
`;
