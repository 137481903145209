import gql from 'graphql-tag';

export const PlanPurchasePause_Fragment_planPurchase = gql`
  fragment PlanPurchasePause_Fragment_planPurchase on PapiPlanPurchase {
    id
    center {
      id
      timezone
    }
    endDateIncludingExtensions
    endTime
    member: person {
      id
    }
    latestPause {
      endDate
      startDate
    }
    plan {
      billingPeriod
      product {
        displayName
        type
      }
    }
    startTime
    purchaseState
  }
`;
