import gql from 'graphql-tag';

export const PAYMENT_CARD_SET_DEFAULT_MUTATION = gql`
  mutation PaymentCardSetDefault_Mutation($paymentCardID: ID!) {
    setDefaultPaymentCard(paymentCardID: $paymentCardID) {
      id
      deleted
    }
  }
`;

export const PAYMENT_CARD_SET_DEFAULT_REFETCH = gql`
  query PaymentCardSetDefault_Refetch($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      paymentCards {
        edges {
          node {
            id
            deleted
          }
        }
      }
      stripeCustomerID
    }
  }
`;
